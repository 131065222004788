import axios from "axios";
import { ChatItem } from "../model/history";

class TestChatController {
  constructor(
    apiUrl,
    supportedLanguages,
    i18nContext,
    available_platform,
    test_chat_platform = false,
  ) {
    this.chatItemsByPlatform = null;
    this.availablePlatform = available_platform;
    this.supportedLanguages = supportedLanguages;
    this.chatLanguage = "ja";
    this.TryItNowApi = apiUrl.TryItNow;
    this.hasContext = false;
    this.i18n = i18nContext;
    this.defaultPlatform = test_chat_platform ? "web_chat_v2" : "skype";
    this.parameterObject = {};
    this.outputContexts = [];
    this.requestErrorMsg = null;
  }

  /**
   * Selected Chat language
   */
  selectedChatLanguage(lang) {
    if (!lang) {
      return;
    }
    this.chatLanguage = lang;
  }

  /**
   * Detect Intent
   */
  async detectIntent(userSayDetailObject, csrfToken = null) {
    this.chatItemsByPlatform = null;
    let sendOptions = {};
    Object.assign(
      sendOptions,
      {},
      {
        headers: { "X-CSRFToken": csrfToken },
      }
    );

    const { text, language, event } = userSayDetailObject;

    const {
      data: { parameter_dict, chat_items_by_platform_dict, output_contexts, no_set_fallback_intent },
    } = await axios.post(
      this.TryItNowApi,
      {
        user_say: text,
        language,
        event,
      },
      sendOptions
    );

    this.parameterObject = parameter_dict;
    this.outputContexts = output_contexts;
    this.chatItemsByPlatform = Object.entries(
      chat_items_by_platform_dict
    ).reduce((output, [key, chatItems]) => {
      output[key] = chatItems.map((chatItem) => ChatItem.fromData(chatItem));
      return output;
    }, {});
    this.requestUserSay = userSayDetailObject.text;
    this.requestErrorMsg = no_set_fallback_intent;

    // TODO handle context
    if (this.chatItemsByPlatform.outputContexts) {
      this.hasContext = true;
    }
  }

  /**
   * Delete all contexts session
   */
  deleteAllContextsSession(csrfToken = null) {
    let sendOptions = {};
    let options = {};
    Object.assign(sendOptions, options, {
      headers: { "X-CSRFToken": csrfToken },
    });
    return new Promise((resolve, reject) => {
      axios
        .delete(this.TryItNowApi, sendOptions)
        .then((instance) => {
          if (instance.status === 200) {
            this.hasContext = false;
            this.outputContexts = [];
          }
          resolve();
        })
        .catch((error) => {
          reject(error.response.data[0]);
        });
    });
  }

  /**
   * Set the Chat language depends on UI/selected language
   */
  setChatLanguage() {
    let language = this.chatLanguage;
    if (window.requestContext.language) {
      // the selected language: e.g. intent, keyword, scenario-editor
      language = window.requestContext.language;
    } else if (this.i18n.context.locale) {
      // UI language: e.g. scenario-index, webhook, validation
      language = this.i18n.context.locale;
    }
    this.selectedChatLanguage(language);
  }

  /**
   * Keyboard up/down and left/right arrow scroll
   */
  activateKeyboardScroll() {
    const scrollIntentCategory = document.getElementById(
      "scrollY_IntentCategory"
    );
    if (scrollIntentCategory) {
      scrollIntentCategory.focus();
    }
    const scrollKeywordCategory = document.getElementById(
      "scrollY_KeywordCategory"
    );
    if (scrollKeywordCategory) {
      scrollKeywordCategory.focus();
    }
  }
}

export { TestChatController };
