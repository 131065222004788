import axios from "axios"

/**
 *  obot-speechの接続情報を取得
 * @returns {Promise<AxiosResponse<any>>}
*/
export const loadSpeechInfo = async () => {
  let endpoint = '/api/agent/speech-info/';
  return await axios.get(endpoint);
}
