<template>
  <div class="answer-sticker">
    <div class="mb-1 p-1 text-secondary"><small>{{$t('answer.lineCopyright')}}</small></div>
    <div class="p-1">
      <span class="btn pointer-cursor mb-1" :class="tabClass('11537')" @click="tabActivate('11537')" title="Brown, Cony & Sally">
        Brown & Cony
      </span>
      <span class="btn pointer-cursor mb-1" :class="tabClass('11538')" @click="tabActivate('11538')" title="CHOCO & Friends">
        CHOCO & Friends
      </span>
      <span class="btn pointer-cursor mb-1" :class="tabClass('11539')" @click="tabActivate('11539')" title="UNIVERSTAR BT21">
        UNIVERSTAR
      </span>
    </div>
    <div class="row mb-1" v-if="responseMessagePackageId==='11537'">
      <div class="col-md-3 pointer-cursor"
           v-for="sticker in package1"
           :class="{selected_sticker: responseMessageStickerId === sticker.value}"
           @click="selectSticker(sticker.value)">
        <b-img
            :src="sticker.image"
            v-bind="imgProps"
            rounded="circle"
            thumbnail></b-img>
        <div class="text-center">{{sticker.value}}</div>
      </div>
    </div>
    <div class="row mb-1" v-if="responseMessagePackageId==='11538'">
      <div class="col-md-3 pointer-cursor"
           v-for="sticker in package2"
           :class="{selected_sticker: responseMessageStickerId === sticker.value}"
           @click="selectSticker(sticker.value)">
        <b-img
          :src="sticker.image"
          v-bind="imgProps"
          rounded="circle"
          thumbnail></b-img>
        <div class="text-center">{{sticker.value}}</div>
      </div>
    </div>
    <div class="row mb-1" v-if="responseMessagePackageId==='11539'">
      <div class="col-md-3 pointer-cursor"
           v-for="sticker in package3"
           :class="{selected_sticker: responseMessageStickerId === sticker.value}"
           @click="selectSticker(sticker.value)">
        <b-img
          :src="sticker.image"
          v-bind="imgProps"
          rounded="circle"
          thumbnail></b-img>
        <div class="text-center">{{sticker.value}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { StickerData } from '../../model/intent'
  import {package1, package2, package3} from "../../util/line_sticker";

  export default {
    data() {
      return {
        answerSticker: null,
        responseMessagePackageId: null,
        responseMessageStickerId: null,
        package1: package1,
        package2: package2,
        package3: package3,
        imgProps: { width: 100, height: 100, class: 'm-1' }
      }
    },
    validations() {
      return {
        responseMessagePackageId: {
          required,
        },
        responseMessageStickerId: {
          required,
        }
      }
    },
    computed: {
      tabClass () {
        return (index) => {
          return {
            "btn-primary": this.responseMessagePackageId === index,
            "btn-outline-primary": this.responseMessagePackageId !== index
          }
        }
      }
    },
    methods:{
      tabActivate(index) {
        this.responseMessagePackageId = index
        this.responseMessageStickerId = null
        this.validationCheck()
      },
      selectSticker(value){
        this.responseMessageStickerId = value
        this.validationCheck()
      },
      inputValue(){
        this.answerSticker = new StickerData(
          this.responseMessagePackageId,
          this.responseMessageStickerId
        )
        return this.answerSticker
      },
      setValue(selectedResponseMessage){
        this.responseMessagePackageId = selectedResponseMessage.data.packageId
        this.responseMessageStickerId = selectedResponseMessage.data.stickerId
      },
      initializeValue(){
        this.answerSticker=null
        this.responseMessagePackageId = "11537"
        this.responseMessageStickerId = null
      },
      validationCheck(){
        const validation = this.$v
        validation.$touch() // Initialize the fields
        this.$emit('is-valid', !validation.$invalid) // Declare to the parent
      }
    }
  }
</script>
<style scoped>
  .selected_sticker{
    background-color: #ccccff;
  }
</style>

