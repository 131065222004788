import { Word } from '../model/intent'
import { KeywordCategory, Keyword } from '../model/keyword'
import { RestApi } from '../util/rest-api'
import { colorFromString } from '../util/word-color'
import { SYSTEM_ENTITY } from '../util/system-entity'

class WordsEditor {
  constructor(apiUrl, language, words=[]) {
    this.language = language
    this.words = words
    this.viewWords = words.map(word => word.clone())
    this.keywordCategoryApi = new RestApi(apiUrl.KeywordCategory, KeywordCategory)
    this.keywordApi = new RestApi(apiUrl.Keyword, Keyword)
    this.allKeywordApi = new RestApi(apiUrl.AllKeyword, Keyword)
    this.itemCategory = {value: '', text: ''}
    this.itemKeyword = {value: '', text: ''}
    this.itemIntentCategory = {value: '', text: ''}
    this.keywordCategories = [{
      value: null,
      text: "---"
    }]
    this.intentCategories = [{
      value: null,
      text: "---"
    }]
    this.keywords = [{
      value: null,
      text: "---"
    }]
    this.allKeywords=[]
    this.selectedCategory = null  // 選択中のキーワードカテゴリ
    this.selectedKeyword = null
    this.selectedWordInList = null // 一覧で選択中のWord
    this.categoryPathIndex = null
    this.queryFromWhichModal = null
    this.systemEntity = false
  }

  async ready() {
    return new Promise(resolve => {
      let promises = []
      promises.push(this.loadKeywordCategories())
      promises.push(this.loadAllKeywords())

      Promise.all(promises).then(value => {
        resolve()
      })
    })
  }

  /**
   * キーワード設定済みWord
   */
  keywordWords() {
    return this.words.filter(word => word.meta != null)
  }

  /**
   * キーワードカテゴリ一覧取得
   */
  loadKeywordCategories() {
    return new Promise(resolve => {
      this.keywordCategoryApi.list()
        .then((instances) => {
          // this.keywordCategories = []
          this.categoryPathIndex = this.createCategoryPathIndex(instances)
          this.keywordCategories = this.createCategoriesData(instances)
          resolve()
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  /**
   * カテゴリデータ
   */
  createCategoriesData(categories, intent=false) {
    // ドロップダウンのカテゴリツリー生成
    let resultCategories = [{
      value: null,
      text: "---"
    }]
    if(!intent){
      resultCategories.push({value:'System_Entity', text:'@System_Entity'})
    }
    let flat = KeywordCategory.categoriesAsFlat(categories)
    for (const [nestedLevel, category] of flat) {
      let indent = ""
      for (let i = 0; i < nestedLevel; i++) {
        indent += " » "
      }
      resultCategories.push({
        value: category,
        text: indent + category.name
      })
    }
    return resultCategories
  }

  resolveCategoryPath(category, categoryIdToCategory) {
    if (category.parentId == null) {
      return [category]
    } else {
      const parentCategory = categoryIdToCategory[category.parentId]
      return this.resolveCategoryPath(parentCategory, categoryIdToCategory).concat([category])
    }
  }

  /**
   * カテゴリ名のインデックス生成
   */
  createCategoryPathIndex(categories) {
    let flat = KeywordCategory.categoriesAsFlat(categories)
    // categoryId -> categoryを作成
    let categoryIdToCategory = {}
    for (const [nestedLevel, category] of flat) {
      categoryIdToCategory[category.id] = category
    }
    // category -> カテゴリツリーのパス生成
    let categoryIdToPath = {}
    for (const [nestedLevel, category] of flat) {
      const categoryPath = this.resolveCategoryPath(category, categoryIdToCategory)
      categoryIdToPath[category.id] = categoryPath.map((c) => {return c.name}).join("_")
    }
    return categoryIdToPath
  }
  /**
  * すべてのキーワードを読み込む
  */
  loadAllKeywords(){
    this.allKeywordApi.list({params: {}})
      .then((instances) => {
        this.allKeywords = instances
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * キーワード一覧取得
   */
  loadKeywords() {
    if (this.selectedCategory == null) {
      this.keywords = this.createKeywordsData([])
      return
    }
    return new Promise((resolve) => {
      this.keywordApi.list({
        params: {
          category_id: this.selectedCategory.id
       }
      })
      .then((instances) => {
        this.keywords = this.createKeywordsData(instances)
        resolve()
      })
      .catch((error) => {
        console.log(error)
      })
    })
  }

  /**
   * キーワードデータ
   */
  createKeywordsData(keywords) {
    // ドロップダウンのキーワードデータ生成
    let result = [{
      value: null,
      text: "---"
    }]
    for (const keyword of keywords) {
      result.push({
        value: keyword,
        text: keyword.name
      })
    }
    return result
  }
  /**
   * Load System Entity
   */
  loadSystemEntity(){
    let result = [{
      value: null,
      text: "---"
    }]
    for(const entity of SYSTEM_ENTITY){
      let keywordModel = new Keyword()
      keywordModel.name = entity
      result.push({
        value: keywordModel,
        text: keywordModel.name
      })
    }
    result.shift()
    return result
  }

  /**
   * カテゴリ選択
   */
  selectKeywordCategory(category) {
    // 選択中なら何もしない
    if (this.selectedCategory == category) {
      return
    }
    // カテゴリを選択
    this.selectedCategory = category
    this.selectedKeyword = null
    // 選択したカテゴリのキーワード一覧を読み込む
    if(this.systemEntity){
      this.keywords = this.loadSystemEntity()
    }else{
      this.loadKeywords()
    }
  }

  /**
   * キーワード選択
   */
  selectKeyword(keyword) {
    this.selectedKeyword = keyword
  }

  get text() {
    return this.words.map(w => w.text).join('')
  }

  set text(inputText) {
    this.words = [
      new Word(inputText)
    ]
  }

  /**
   * 指定した範囲のWordsを作成
   */
  split(start, end) {
    let cursorPosition = 0  // テキスト位置(0起点)
    let startWord = null
    let startWordIndex = 0  // Wordの添字(開始)
    let endWord = null
    let endWordIndex = 0  // Wordの添字(終了)
    let temporaryWords = this.words.map(word => word.clone())
    // 開始位置のWordを探す
    for (const word of temporaryWords) {
      // 見つかった場合
      if (cursorPosition + word.text.length > start) {
        // Wordの途中が開始位置の場合
        const diffCount = start - cursorPosition
        if (diffCount > 0) {
          // 分割する
          const originText = word.text
          // 開始位置より前まで縮める
          word.text = originText.slice(0, diffCount)
          // 残りは新しいWordオブジェクトとする
          startWord = new Word(originText.slice(diffCount))
          // words配列に追加
          temporaryWords.splice(startWordIndex + 1, 0, startWord)
          // 位置を増加
          startWordIndex++
        } else {
          startWord = word
        }
        // 見つかったのでループを抜ける
        break
      }
      cursorPosition += word.text.length
      startWordIndex++
    }

    // 終了位置のWordを探す
    cursorPosition = 0
    for (const word of temporaryWords) {
      // 見つかった場合
      if (cursorPosition + word.text.length > end) {
        // Wordの途中が終了位置の場合
        const diffCount = end - cursorPosition
        if (diffCount > 0) {
          // 分割する
          const originText = word.text
          // 終了位置より前まで縮める
          word.text = originText.slice(0, diffCount)
          // 残りは新しいWordオブジェクトとする
          endWord = new Word(originText.slice(diffCount))
          // words配列に追加
          temporaryWords.splice(endWordIndex + 1, 0, endWord)
        } else {
          endWord = word
          // 位置をずらす
          endWordIndex--
        }
        // 見つかったのでループを抜ける
        break
      }
      cursorPosition += word.text.length
      endWordIndex++
    }
    if (endWordIndex >= temporaryWords.length) {
      endWordIndex = temporaryWords.length - 1
    }

    // 開始位置から終了位置までのwordの結合と再配置
    let newWords = []
    let newWordIndex = 0
    let joinWords = []
    let newWord = null
    for (const word of temporaryWords) {
      if (newWordIndex >= startWordIndex && newWordIndex <= endWordIndex) {
        // 範囲内はjoinWordsに保持
        joinWords.push(word)
        if (newWordIndex == endWordIndex) {
          // 範囲内の最後の要素の場合はjoinしてnewWordsに追加
          newWord = new Word(
            joinWords.map(w => w.text).join('')
          )
          newWords.push(newWord)
        }
      } else {
        newWords.push(word)
      }
      newWordIndex++
    }
    this.viewWords = newWords
    return newWord
  }

  /**
   * 指定したテキスト位置のWordを返す
   */
  wordByPosition(position) {
    let cursorPosition = 0  // テキスト位置(0起点)
    for (const word of this.words) {
      // 見つかった場合
      if (cursorPosition + word.text.length < position) {
        return word
      }
    }
  }

  /**
   * wordにキーワードを設定
   */
  setKeywordToWord(systemEntity=false, name=null) {
    if (this.selectedKeyword==null) {
      return
    }
    this.words = this.viewWords
    for (const word of this.words) {
      if (word.selected) {
        // カテゴリ名を含むキーワード名
        // カテゴリ名やキーワード名を変更した場合、インテントの紐づけ名も変更が必要になる
        if (!systemEntity) {
          const fullName = this.categoryPathIndex[this.selectedKeyword.categoryId] + "_" + this.selectedKeyword.name
          word.meta = "@" + fullName
          // alias名を複数対応する
          word.alias = this.resolveDuplicatedAlias(fullName, word, this.words)
        }
        else{
          word.meta = "@sys."+ name
          word.alias = this.resolveDuplicatedAlias(name, word, this.words)
        }
        word.color = colorFromString(word.meta)
        word.entitySelected = true
        word.selected = false
        // 一覧での選択を解除
        this.selectedWordInList = null
        break
      }
    }
  }

  /**
   * 重複Alias名を避ける名前を返す
   */
  resolveDuplicatedAlias(fullName, targetWord, words) {
    let number = 1
    let checkName = fullName
    while (true) {
      let exists = false
      for (const word of words) {
        // 自身のWordは比較しない(編集用)
        if (word == targetWord) {
          continue
        }
        if (word.alias==checkName) {
          // 既存wordsに一致するものがある場合
          exists = true
          break
        }
      }
      if (exists) {
        // 一致する名前がある場合はfullName + numberの名前でチェックする
        number++
        checkName = fullName + number
      } else {
        // 一致する名前がなければfullNameを使う
        return checkName
      }
    }
  }

  /**
   * 一覧のWordを選択
   */
  selectWordInList(word) {
    this.selectedWordInList = word
  }
}

export {
  WordsEditor
}
