export const AgentOperatorAvailableConditionTemplates = Object.freeze([
  {
    "name": "history.OperatorAvailableConditionTemplateNames.HolidayUnavailable",
    "data": {
      "value": "now",
      "value_type": "date",
      "check_target": {
        "type": "context",
        "value": "holidays"
      },
      "check_type": "in",
      "return_value": false
    }
  },
  {
    "name": "history.OperatorAvailableConditionTemplateNames.WeekendUnavailable",
    "data": {
      "value": "now",
      "value_type": "weekday",
      "check_target": {
        "type": "context",
        "value": "workdays"
      },
      "check_type": "not_in",
      "return_value": false
    }
  },
  {
    "name": "history.OperatorAvailableConditionTemplateNames.WorkdayWorktime",
    "data": {
      "value": "now",
      "value_type": "weekday",
      "check_target": {
        "type": "context",
        "value": "workdays"
      },
      "check_type": "in",
      "ands": [
        {
          "value": "now",
          "value_type": "time",
          "check_target": {
            "type": "value",
            "value_type": "time",
            "value": "09:00:00"
          },
          "check_type": ">=",
          "ands": [
            {
              "value": "now",
              "value_type": "time",
              "check_target": {
                "type": "value",
                "value_type": "time",
                "value": "18:00:00"
              },
              "check_type": "<"
            }
          ]
        }
      ]
    }
  }
])
