class ExternalService {
  constructor(id=null, gcp_project_id=null, use_in_cms=false) {
    this.id = id
    this.gcp_project_id = gcp_project_id
    this.use_in_cms = use_in_cms
  }

  toData() {
    return {
      id: this.id,
      gcp_project_id: this.gcp_project_id,
      use_in_cms: this.use_in_cms
    }
  }
}

ExternalService.fromData = function fromData(data) {
  return new ExternalService(
    data.id || null,
    data.gcp_project_id || null,
    data.use_in_cms || false,
  )
}


export {
  ExternalService
}
