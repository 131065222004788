<template>
<div class="keyword-value">
  <!--キーワードバリューリストの最後の項目をダブルクリックすると-->
  <!--ポップアップしたダイアログの文字がドラッグの状態になるのでb-modalの位置を変更-->
  <b-modal
    :title="modalTitle"
    ref="keywordValueModal"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-esc
    no-close-on-backdrop
    @shown="modalFocus"
    @hidden="modalHidden">
    <form @submit.prevent="">
      <div>
        {{ $t('keywordValue.title') }}:
      </div>
      <b-form-input
        type="text"
        ref="focusInput"
        v-model="modalKeywordValue"
        :maxlength="max"
        :state="isValidModalKeywordValue">
      </b-form-input>
      <div>
        {{ $t('keywordValue.synonym') }}: ({{ $t('keywordValue.inputWithLineBreakSeparator') }})
      </div>
      <b-form-textarea
        :place-holder="$t('keywordValue.inputWithLineBreakSeparator')"
        :rows="10"
        v-model="modalKeywordSynonym"
        :state="isValidModalKeywordSynonym"
        :maxlength="maxTextArea">
      </b-form-textarea>
    </form>
    <modal-footer
      slot="modal-footer"
      @ok="editOk"
      :ok-disabled="!(isValidModalKeywordValue && isValidModalKeywordSynonym)"
      @cancel="editCancel">
    </modal-footer>
  </b-modal>

  <delete-confirm-modal
    :title="$t('keywordValue.deleteTitle')"
    ref="deleteValueConfirmModal"
    :bodyMessage="$t('keywordValue.deleteConfirmMessage', {keywordValue: this.selectedKeywordValue && this.selectedKeywordValue.value})"
    @keydown.native.enter="pressEnterKey"
    @ok="deleteValue"
    @cancel="deleteCancel"
    @hidden="modalHidden">
  </delete-confirm-modal>

  <b-modal
    :title="$t('keywordValue.registered')"
    ref="duplicate"
    header-class="bg-danger text-light"
    body-class="text-danger"
    :ok-only="true"
    @ok="showKeywordValueModal"
    no-close-on-esc
    no-close-on-backdrop>
    <div>
      <p>{{ this.i18n.t('keywordValue.duplicated') }}</p>
    </div>
  </b-modal>

  <table class="mb-0">
    <tr class="pointer-cursor" v-b-toggle.keywordvalue_collapse @click = "folded = !folded">
      <th class="column-key">
        <div class="column-caption font-weight-bold my-1 p-1">
          {{ $t('keywordValue.title') }}
        </div>
      </th>
      <th class="column-value">
        <div class="column-caption font-weight-bold my-1 p-1">
          {{ $t('keywordValue.synonym') }}
          <span class="float-right pt-1 pr-1" v-show="folded"><i class="fas fa-caret-down"></i></span>
          <span class="float-right pt-1 pr-1" v-show="!folded"><i class="fas fa-caret-up"></i></span>
        </div>
      </th>
    </tr>
  </table>
  <b-collapse visible id="keywordvalue_collapse">
    <table class="mb-1">
      <tr>
        <th class="column-key"></th>
        <th class="column-value"></th>
      </tr>
      <tr>
        <td colspan="2" v-if="userType!='viewer'">
          <div class="mb-1">
            <button
              class="btn btn-success button-text mr-1"
              @click="addValue"
              v-bind:disabled="!selectedKeyword">
              <i class="fa fa-plus">
              </i>
              <span>{{ $t('buttons.add') }}</span>
            </button>

            <button
              class="btn btn-success button-text mr-1"
              @click="edit"
              v-bind:disabled="!selectedKeywordValue">
              <i class="fa fa-edit"></i>
              <span>{{ $t('buttons.edit') }}</span>
            </button>

            <button
              class="btn btn-success button-text mr-1"
              @click="deleteValueConfirm"
              v-bind:disabled="!selectedKeywordValue">
              <i class="fas fa-trash-alt"></i>
              <span>{{ $t('buttons.delete') }}</span>
            </button>
            <!-- Open Search Modal -->
            <button class="btn btn-success button-text"
                    :title="$t('keywordValue.searchKeywordValue')"
                     @click="showSearchModal">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </td>
      </tr>
    </table>
    <div class="scroll-value" tabindex="-1" ref="focusOn" id="scrollY_KeywordValue" @keyup.delete="userType != 'viewer' && selectedKeywordValue ? deleteValueConfirm() : null">
      <table class="mb-1">
        <tr
          class="keyword-value-item"
          v-for="(keywordValue, index) in keywordValues"
          :key="index"
          :class="{selected: selected(keywordValue)}"
          @click="clickSelectedKeywordValue(keywordValue)"
          @dblclick="userType == 'viewer' ? null : edit()"
          @keyup.delete="userType == 'viewer' ? null : deleteValueConfirm()"
          tabindex="-1">
          <td>
            {{ keywordValue.value }}
          </td>
          <td>
            {{ keywordValue.synonymAsText() }}
          </td>
        </tr>
      </table>
    </div>
  </b-collapse>

  <b-modal
    :title="$t('keywordValue.searchKeywordValue')"
    ref="searchKeywordModal"
    header-class="bg-info text-light"
    body-class="text-info"
    ok-title="Close"
    ok-variant="secondary"
    ok-only no-close-on-esc no-close-on-backdrop
    @shown="focusThis($refs.focusOnThis)"
  >
    <form onsubmit="return false;" @keyup.enter="onSubmitSearch">
      <!-- Using components -->
      <b-input-group>
        <b-form-input
        type="text"
        ref="focusOnThis"
        v-model="modalSearchInputText"></b-form-input>
        <b-input-group-append>
          <b-button variant="primary"
                    :disabled="!modalSearchInputText"
                    @click="onSubmitSearch">
            <span><i class="fas fa-search"></i></span>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </form>
    <div class="keyword-search-result mt-3"
         v-if="searchKeywordValuesResult.length > 0">
      <b-list-group>
        <b-list-group-item v-for="(keywordValue, index) in searchKeywordValuesResult"
                           :key="index"
                           class="flex-column align-items-start text-dark">
          <div class="d-flex w-100 justify-content-between">
            <h6 class="bg-info text-light mb-1 p-1">{{keywordValue.title}}</h6>
            <small>{{keywordValue.value}}</small>
          </div>
          <span>{{keywordValue.synonym}}</span>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-modal>
</div>
</template>

<script>
  import { KeywordValue } from '../model/keyword'
  import {I18n} from "../util/i18n";
  import { illegalExp } from '../util/illegalRegex'
  import { str_z2h } from '../util/string-z2h'

  export default {
    props: ['selectedKeyword', 'keywordValues', 'selectedKeywordValue','userType', 'searchKeywordValuesResult'],
    data() {
      return {
        modalTitle: "",
        modalKeyword: null,
        modalKeywordValue: '',
        modalKeywordKeywordId: null,
        modalKeywordLanguageCode: null,
        modalKeywordSynonym: '',
        i18n: new I18n(window.i18nContext),
        max:50,
        maxTextArea:512,
        tempIndex:0,
        changeIndexFlag:false,
        arrowKeyFlag:0,
        folded:false,
        modalPopupCheckFlag:false,
        isModalOK:false,
        scrollY:0,          //縦のスクロールバー位置
        scrollY_Calc_Num:25, //矢印でアイテム選択中、アイテムがスクロールで隠れないようにスクロール速度調整のための数値（計算用）
        modalSearchInputText: ""
      }
    },
    computed: {
      isValidModalKeywordValue() {
        let state = false
        if(this.modalKeywordValue){
          if (!this.modalKeywordValue.trim()) {
            return true
          }
          const pattern = new RegExp(/[.*+?^${}()|[\]\\]/g, 'm')
          const patternMatched = this.modalKeywordValue.match(pattern)
          state = (patternMatched == null)
        }
        return state
      },
      isValidModalKeywordSynonym() {
        if (!this.modalKeywordSynonym.trim()) {
          return true
        }

        let pattern = new RegExp('^.*[\\^\\(\\)\\[\\]\\\\<>:"{}\\|_].*$', 'm')
        return this.modalKeywordSynonym.match(pattern) == null
      }
    },
    methods: {
      selected(keywordValue){
        return keywordValue == this.selectedKeywordValue
      },
      clickSelectedKeywordValue(keywordValue) {
        for(let i = 0; i < this.keywordValues.length; i++){
          if(this.keywordValues[i].id == keywordValue.id){
            this.scrollY = i*this.scrollY_Calc_Num
          }
        }
        this.changeIndexFlag = true
        this.arrowKeyFlag = 3
        this.modalPopupCheckFlag = false
        this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
        this.$emit('arrowKey-flag',this.arrowKeyFlag)
        this.$emit('select-keyword-value', keywordValue)
      },
      onArrowUp(keywordValue){
        for(let i = 0; i < this.keywordValues.length; i++){
          if(this.keywordValues[i].id == keywordValue.id){
            if(this.changeIndexFlag == true){
              this.tempIndex = i -1
              this.changeIndexFlag = false
              if(this.tempIndex < 0){return}
            }
            else{
              if(this.tempIndex <= 0){
                return
              }else{
                this.tempIndex = this.tempIndex -1
              }
            }
          }
        }
        this.scrollY = this.scrollY - this.scrollY_Calc_Num
        document.getElementById("scrollY_KeywordValue").scrollTop = this.scrollY
        keywordValue = this.keywordValues[this.tempIndex]
        this.$emit('select-keyword-value', keywordValue)
      },
      onArrowDown(keywordValue){
        for(let i = 0; i < this.keywordValues.length; i++){
          if(this.keywordValues[i].id == keywordValue.id){
            if(this.changeIndexFlag == true){
              this.tempIndex = i +1
              this.changeIndexFlag = false
              if(this.tempIndex > this.keywordValues.length-1){return}
            }
            else{
              if(this.tempIndex >= this.keywordValues.length-1){
                return
              }else{
                this.tempIndex = this.tempIndex+1
              }
            }
          }
        }
        document.getElementById("scrollY_KeywordValue").scrollTop = this.scrollY
        this.scrollY = this.scrollY + this.scrollY_Calc_Num
        keywordValue = this.keywordValues[this.tempIndex]
        this.$emit('select-keyword-value', keywordValue)
      },
      onArrowRight(keywordValue){
        this.tempIndex = 0
        this.scrollY = 0
        this.$emit('select-keyword-value', keywordValue)
        this.$refs.focusOn.focus()
      },
      addValue() {
        this.modalPopupCheckFlag = true
        this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
        this.modalTitle = this.i18n.t('keywordValue.add')
        this.modalKeyword = new KeywordValue()
        this.modalKeywordValue = ''
        this.modalKeywordSynonym = ''
        this.modalKeywordKeywordId = this.selectedKeyword.id
        this.modalKeywordLanguageCode = window.requestContext.language
        this.$refs.keywordValueModal.show()
      },
      deleteValueConfirm() {
      if(this.selectedKeywordValue==null){
        return
      }
      this.modalPopupCheckFlag = true
      this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
      this.$refs.deleteValueConfirmModal.show()
    },
      pressEnterKey(){
      this.deleteValue();
    },
      deleteValue() {
        if(this.isModalOK)
          return null
        this.modalKeyword = this.selectedKeywordValue
        this.$emit('delete-keyword-value', this.modalKeyword)
        this.isModalOK = true
      },
      edit() {
        // キーワード値編集
        this.modalPopupCheckFlag = true
        this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
        this.modalTitle = this.i18n.t('keywordValue.editSynonym')
        this.modalKeyword = this.selectedKeywordValue
        this.modalKeywordValue = this.modalKeyword.value
        this.modalKeywordSynonym = this.modalKeyword.synonymAsText()
        this.modalKeywordKeywordId = this.modalKeyword.keywordId
        this.modalKeywordLanguageCode = this.modalKeyword.language
        this.$refs.keywordValueModal.show()
      },
      editOk() {
        if (this.modalKeywordValue == '') {
          return
        }
        if (this.isDuplicate(this.modalKeywordValue, this.modalKeyword.id)){
          this.$refs.duplicate.show()
          return
        }
        // モーダルの値をキーワード値に反映
        this.modalKeyword.value = this.modalKeywordValue.trim()
        this.modalKeyword.value = this.modalKeyword.value.replace(illegalExp, "")
        this.modalKeyword.setSynonymFromText(this.modalKeywordSynonym.replace(illegalExp, ""))
        // キーワード値と一致するsynonymがない場合、先頭にキーワード値のsynonymを追加
        if(this.modalKeyword.synonym.indexOf(this.modalKeywordValue) < 0){
          this.modalKeyword.synonym.unshift(this.modalKeywordValue.replace(illegalExp, ""))
        }
        this.modalKeyword.keywordId = this.modalKeywordKeywordId
        this.modalKeyword.language = this.modalKeywordLanguageCode
        this.modalPopupCheckFlag = false
        this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
        // イベント通知
        this.$emit('edit-keyword-value', this.modalKeyword)
        this.isModalOK = true
        this.$refs.keywordValueModal.hide()
      },
      modalFocus(){
      this.$refs.focusInput.focus()
      },
      isDuplicate(keywordValue, id){
        let duplicate=false
        for (let Value of this.keywordValues){
          if((Value.id != id) && (Value.value === keywordValue)){
            duplicate = true
          }
        }
        return duplicate
      },
      showKeywordValueModal(){
        this.$refs.keywordValueModal.show()
      },
      editCancel(){
      this.modalPopupCheckFlag = false
      this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
      this.$refs.keywordValueModal.hide()
      },
      deleteCancel(){
        this.modalPopupCheckFlag = false
        this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
        this.$refs.deleteValueConfirmModal.hide()
      },
      modalHidden(){
        this.$refs.focusOn.focus()
        if(!this.isModalOK){
          if(this.selectedKeywordValue)
            this.clickSelectedKeywordValue(this.selectedKeywordValue)
          else{
            this.arrowKeyFlag = 2
            this.$emit('arrowKey-flag',this.arrowKeyFlag)
          }
        }
        this.isModalOK = false
      },
      showSearchModal() {
        this.modalTitle = 'Search Keywords'
        this.modalSearchInputText = ""
        this.searchKeywordValuesResult.length = 0
        this.$refs.searchKeywordModal.show()
      },
      onSubmitSearch() {
        const searchedString = str_z2h(this.modalSearchInputText.replace(/\s+/g,' ').trim().toLowerCase())
        this.$emit('search-keyword', searchedString)
      },
      focusThis(element) {
        element.focus()
      }
    }
  }
</script>
