import Vue from 'vue'
import { UrlBuilder } from './util/url-builder'
import { API_URL } from './resource/urls'
import { SpeechContextController } from "./controller/speech-context-controller"

import SpeechContext from "./components/SpeechContextManagement.vue"
import ErrorModal from "./components/ErrorModal.vue"
import ProgressModal from "./components/ProgressModal.vue"


export const appSpeechContext = (i18n) => {
  const requestContext = Object.freeze(window.requestContext || {})
  const controller = new SpeechContextController(
    (new UrlBuilder(API_URL)).build(),
    requestContext.userType,
    requestContext.supportedLanguages,
    requestContext.language,
    i18n
  )

  Vue.component('speech-context-management', SpeechContext)
  Vue.component('error-modal', ErrorModal)
  Vue.component('progress-modal', ProgressModal)

  new Vue({
    el: '#app-speech-context',
    data: {
      controller: controller
    },
    i18n: i18n
  })
}
