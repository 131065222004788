<template>
  <div>
    <div class="row ml-1 mr-1 border-bottom" :class="{'analytics-total-row': isTotal}">
      <div class="col border-left p-2" v-for="column in columns" :key="column.key">

        <template v-if="column.key==='date'">
          <span v-if="isTotal">
            {{ $t('analytics.total') }}
          </span>
          <span v-else>
            <a :href="getHistoryByDate(date)">{{ date }}</a>
          </span>
        </template>

        <span v-else-if="['answered_rate', 'unanswered_rate'].indexOf(column.key) !== -1">
          <a v-if="column.key==='unanswered_rate' && !isTotal" :href="getHistoryByConversationType(date, 1)">
            {{ fixedFloatNumber(record[column.key]) }}%
          </a>
          <template v-else>
            {{ fixedFloatNumber(record[column.key]) }}%
          </template>
        </span>

        <span v-else-if="column.platform">
          <template v-if="isTotal">
            {{ record.platform_data[column.key] }}
          </template>
          <a v-else :href="getHistoryByPlatform(date, column.key)">
            {{ record.platform_data[column.key] }}
          </a>
        </span>

        <span v-else-if="column.key==='actions'">
          <b-button size="sm" variant="primary" v-b-toggle="collapseId" @click="collapseDisplay = !collapseDisplay">
            <span v-show="!collapseDisplay"><i class="fas fa-chevron-down"></i></span>
            <span v-show="collapseDisplay"><i class="fas fa-chevron-up"></i></span>
          </b-button>
        </span>

        <span v-else>
          {{ record[column.key] }}
        </span>
      </div>
    </div>
    <b-collapse class="p-1" :id="collapseId" v-if="isSP">
      <b-card header-bg-variant="light" :header="$t('analytics.usersDetail')" class="text-center">
        <b-card-text>
          <b-list-group>
            <b-list-group-item v-for="platform in platforms" :key="platform.key">
              {{ platform.label }}:
              <template v-if="isTotal">
                {{ record.platform_data[platform.key] }}
              </template>
              <a v-else :href="getHistoryByPlatform(date, platform.key)">
                {{ record.platform_data[platform.key] }}
              </a>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
    export default {
        props: ['record', 'isTotal', 'columns', 'redirectUri', 'date', 'isSP', 'platforms'],
        data() {
          return {
              collapseDisplay: false
          }
        },
        computed: {
            collapseId() {
                return `collapse-${this.isTotal ? 'total' : this.date}`
            },
            getHistoryByDate() {
              return (date) => `${ this.redirectUri }?from_date=${ date }&to_date=${ date }`
            },
            getHistoryByConversationType() {
              return (date, conversation_type) => {
                return `${this.getHistoryByDate(date)}&conversation_type=${conversation_type}`
              }
            },
            getHistoryByPlatform() {
              return (date, platform) => {
                return `${this.getHistoryByDate(date)}&platform=${ platform === 'api_service' ? 'api' : platform }`
              }
            },
            fixedFloatNumber() {
              return (number) => {
                const percentage = number*100
                return percentage ? percentage.toFixed(0) : percentage
              }
            }
        }
    }
</script>

