<template>
  <b-card no-body>
    <b-tabs pills card v-model="tabIndex" @input="languageChanged">
      <b-tab class="analytics-card-body sp-wide" title-item-class="sp-hidden" v-for="(item, index) in languageItems" :key="index" :title="$t(languageString(item.lang))">
        <div class="row analytics-details-container">
          <div class="col-md-3" v-if="!!item.record.commonly_used_intent">
            <div class="m-1 p-1 sp-wide">
              {{$t('analytics.frequently_asked_questions')}} :
              <b-list-group>
                <b-list-group-item v-for="(intent, index2) in commonlyUsedIntentFilter(item.record.commonly_used_intent)" :key="index2">
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="lef pointer-cursor text-primary mb-1"
                        @click="goIntentPage(intent.display_name, intent.language_code)">
                      <span class="text-dark">{{index2+1}}. </span>
                      {{intent.display_name}}
                    </h6>
                    <small><b-badge variant="success" pill>{{intent.intent_name_count}}</b-badge></small>
                  </div>
                  <p class="mb-1">» {{intent.user_says}}</p>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
          <div :class="analyticsCardClass">
            <analytics-details
              :selected-language="selectedLanguage"
              :redirect-uri="redirectUri"
              :details="details"
              :isSP="isSP"
              :selected_month="selected_month"
              :isTotalTab="item.lang === 'total'"
              :all_platforms="all_platforms"
              @detail_tab_select="(index) => $emit('detail_tab_select', index)"
            >
            </analytics-details>
          </div>
        </div>
      </b-tab>
      <template slot="tabs">
        <b-nav-item
         class="sp-shown"
        >
          <b-dropdown
            variant="primary"
            :text="$t(languageString(selectedLanguage))"
            style="z-index: 1021"
          >
            <b-dropdown-item
              v-for="(item, index) in languageItems"
              :key="index"
              @click="changeTabIndex(index)"
            >
              {{ $t(languageString(item.lang)) }}
            </b-dropdown-item>
          </b-dropdown>
        </b-nav-item>
        <b-nav-item
          href="#"
          class="align-items-center d-flex"
          @click="shareLink"
          :title="$t('analytics.linkShare')"
        >
          <i class="fas fa-share-square"></i>
        </b-nav-item>
      </template>
    </b-tabs>
  </b-card>
</template>

<script>
  import { LanguageParser } from '../util/languageParser'
  import copyToClipboard from '../util/copy-url'
  import getParamFromUrl from "../util/get-param-from-url"
    export default {
      props: ['analyticsContext', 'languageRecords', 'selectedLanguage', 'redirectUri', 'details', 'isSP', 'selected_month', 'all_platforms'],
      data() {
        return {
          tabIndex: 0
        }
      },
      mounted() {
          this.$nextTick(_ => {
              const targetLang = getParamFromUrl('lang')
              const langIndex = this.languageItems.findIndex(item => item.lang === targetLang)
              if (langIndex !== -1) {
                  this.tabIndex = langIndex
                  this.languageChanged()
              }
          })
      },
      methods: {
        commonlyUsedIntentFilter (intents) {
          if (!intents) {
            return []
          }
          return intents.filter(intent => intent.user_says !== '')
        },
        fixedFloatNumber(number){
          const percentage = number*100
          return percentage ? percentage.toFixed(2) : percentage
        },
        languageChanged() {
          this.$emit('change-language', this.languageItems[this.tabIndex].lang)
        },
        goIntentPage(intent_name, language_code){
          let url = '/agent/' + language_code + '/intent?intent_name=' + intent_name
          window.open(url, "_blank")
        },
        shareLink() {
            const link = this.sharedLink(this.languageItems[this.tabIndex].lang)
            copyToClipboard(link)
            alert(this.$t('redirector.UrlCopied'))
        },
        changeTabIndex(index) {
            this.tabIndex = index
            this.languageChanged()
        }
      },
      computed: {
        code2lang () {
          return (code) => {
            return LanguageParser.code2lang(code)
          }
        },
        languageItems() {
          return Object.keys(this.languageRecords).map(key => { return {'lang': key, 'record': this.languageRecords[key]} }
          )
        },
        languageString() {
          return (code) => {
            const langCode = this.code2lang(code)
            if (langCode) {
              return `languageSelector.${langCode}`
            } else if (code === 'total') {
              return 'analytics.all_language'
            }
          }
        },
        analyticsCardClass() {
          return {
            'col-md-9': this.tabIndex !== 0,
            'col-md-12': this.tabIndex === 0
          }
        },
        sharedLink() {
            return (lang) => {
                // シェア用なリンクを生成する
                return `${window.location.protocol}//${window.location.host}${window.location.pathname}${this.sharedSearch(lang)}`
            }
        },
        sharedSearch() {
            return (lang) => {
                // Linkから月情報を取得
                let month = getParamFromUrl('month')
                const monthPath = !!month ? `month=${month}` : ''
                const langPath = `${!!month ? '&' : ''}lang=${lang}`
                return !(!monthPath && !langPath) && '?' + monthPath + langPath
            }
        }
      }
    }
</script>
