class RedirectorAccessLog {
  constructor (id=null, session_id="", short_id="", created_at="", original_url="") {
    this.id = id
    this.session_id = session_id
    this.short_id = short_id
    this.created_at = created_at
    this.original_url = original_url
  }

  toData() {
    return {
      id: this.id,
      session_id: this.session_id,
      short_id: this.short_id,
      created_at: this.created_at,
      original_url: this.original_url
    }
  }
}

RedirectorAccessLog.fromData = function fromData(data) {
  return new RedirectorAccessLog(
    data.id || null,
    data.session_id || "",
    data.short_id || "",
    data.created_at || "",
    data.original_url || ""
  )
}

export {
  RedirectorAccessLog
}
