import {I18n} from "../util/i18n"

class RedirectorLogController {

  constructor(userType, i18nContext, logs) {
    this.i18n = new I18n(i18nContext)
    this.userType = userType
    this.logs = logs
    this.total = logs.length
  }
}

export {
  RedirectorLogController
}
