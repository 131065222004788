<template>
  <div class="information-area">
    <div class="title">
      {{ $t("history.detail") }}
    </div>
    <div v-if="selectedSessionInfo" class="pl-2">
      <div>
        <div class="wrap-overflow">
          Session ID: {{ selectedSessionInfo.sessionId }}
        </div>
        <div class="session-id">
          {{ $t("history.startTime") }}:
          {{ getTimeFromDate(new Date(selectedSessionInfo.startTime)) }}
        </div>
        <div class="session-id">
          {{ $t("history.endTime") }}:
          <!-- endTimeはDateではなくstringなので注意 -->
          {{ getTimeFromDate(new Date(selectedSessionInfo.endTime)) }}
        </div>
        <div class="session-id">
          {{ $t("history.messageCount") }}: {{ selectedSessionInfo.count }}
        </div>
        <div class="session-id" v-if="matchedUserMeta">
          <p v-if="matchedUserMeta.fullName">
            {{ $t("userMeta.fullName") }}: {{ matchedUserMeta.fullName }}
          </p>
          <p v-if="matchedUserMeta.email">
            {{ $t("userMeta.email") }}: {{ matchedUserMeta.email }}
          </p>
          <p v-if="matchedUserMeta.userId">
            {{ $t("userMeta.userId") }}: {{ matchedUserMeta.userId }}
          </p>
          <div v-if="matchedUserMeta.profileUrl">
            <a
              :href="matchedUserMeta.profileUrl"
              class="btn btn-sm btn-secondary"
              target="_blank"
            >
              <i class="fas fa-external-link-alt"></i>
              {{ $t("userMeta.openProfile") }}
            </a>
          </div>
          <div v-else>
            <b-button class="btn btn-sm" @click="goUserMeta">
              {{ $t("userMeta.userMeta") }}
            </b-button>
          </div>
        </div>
        <div class="session-id">
          <b-button
            variant="outline-primary"
            :title="$t('buttons.copyTo')"
            @click="copySessionURL(selectedSessionInfo.sessionId)"
          >
            <i class="far fa-copy"></i>
            {{ $t("history.copyConUrlBtn") }}
          </b-button>
        </div>
        <div
          class="session-id"
          v-if="
            isPushMessageSupportedByPlatform(selectedSessionInfo.platform) &&
            userType !== 'viewer'
          "
        >
          <b-button variant="info" @click="openPushMessageModal">
            {{ $t("history.sendMessageToUser") }}
          </b-button>
        </div>

        <div
          class="session-id"
          v-if="
            isOperatorSupportedByPlatform(selectedSessionInfo.platform) &&
            userType !== 'viewer' &&
            allow_operator_handling
          "
        >
          <b-button
            variant="primary"
            @click="disableOperatorSupport"
            v-if="isOperatorSupportEnable"
          >
            {{ $t("history.operatorModeDisable") }}
          </b-button>
          <b-button variant="primary" @click="enableOperatorSupport" v-else>
            {{ $t("history.operatorModeEnable") }}
          </b-button>
        </div>

        <b-button
          v-if="operatorHandlingSessionUrl"
          class="mt-2"
          :href="operatorHandlingSessionUrl"
          target="_blank"
        >
          {{ $t("history.openOperatorSession") }}
        </b-button>
      </div>
      <send-message-to-user
        v-if="isPushMessageSupportedByPlatform(selectedSessionInfo.platform)"
        ref="sendMessageToUserModal"
        :platform="selectedSessionInfo.platform"
        :sessionId="selectedSessionInfo.sessionId"
        :ui-language-code="uiLanguageCode"
        :current-chat-language-code="currentChatLanguageCode"
        @send-push-message="sendPushMessage"
        @on-call-translate="$emit('on-call-translate', $event)"
      >
      </send-message-to-user>
    </div>
  </div>
</template>

<script>
import { operatorStatus, operatorStatusReason } from "../util/operatorStatus";
export default {
  props: [
    "selectedSessionInfo",
    "userMetaManager",
    "userType",
    "operatorSession",
    "allow_operator_handling",
    "uiLanguageCode",
    "currentChatLanguageCode",
  ],
  data() {
    return {
      title: "",
      message: "",
    };
  },
  computed: {
    matchedUserMeta() {
      if (!this.userMetaManager) {
        return null;
      }
      return this.userMetaManager.getMatchedUserMeta(
        this.selectedSessionInfo.sessionId,
        this.selectedSessionInfo.platform
      );
    },
    /** 渡されたプラットフォームがプッシュメッセージ送信可能なプラットフォームか判定 */
    isPushMessageSupportedByPlatform() {
      return (platform) => {
        return [
          "line",
          "slack",
          "web_chat",
          "web_chat_v2",
          "facebook",
          "ms_teams",
        ].includes(platform);
      };
    },
    /** 渡されたプラットフォームがオペレーター対応をサポートしているか判定 */
    isOperatorSupportedByPlatform() {
      return (platform) => {
        return ["line", "slack", "web_chat_v2"].includes(platform);
      };
    },
    isOperatorSupportEnable() {
      let selectedOperatorSession = this.operatorSession.filter(
        (session) =>
          session.session_id === this.selectedSessionInfo.sessionId &&
          [
            operatorStatusReason.STARTED_BY_USER,
            operatorStatusReason.STARTED_BY_OPERATOR,
          ].includes(session.status_reason)
      );

      return (
        selectedOperatorSession.length > 0 &&
        selectedOperatorSession[0].status === operatorStatus.ACTIVE
      );
    },
    operatorHandlingSessionUrl() {
      // 最新のオペレーター対応セッションをとりたいので一時的に配列を逆順（id降順）にしてからfindする
      const operatorHandlingSession = this.operatorSession
        .slice()
        .reverse()
        .find((s) => s.session_id === this.selectedSessionInfo.sessionId);
      if (!operatorHandlingSession) {
        return "";
      }
      return `/history/operator-sessions?ohsid=${operatorHandlingSession.id}`;
    },
  },
  methods: {
    goUserMeta() {
      window.location.href = "/user_meta";
    },
    getTimeFromDate(date) {
      return `${date.getHours()}:${date.getMinutes()}`;
    },
    copySessionURL(sId) {
      this.$emit("copy-session-url", sId);
    },
    openPushMessageModal() {
      this.$refs.sendMessageToUserModal.showSendMessageForm();
    },
    sendPushMessage(responseMessage) {
      this.$emit("send-push-message", responseMessage);
    },
    disableOperatorSupport() {
      this.$emit("operator-support-update", operatorStatus.INACTIVE);
    },
    enableOperatorSupport() {
      this.$emit("operator-support-update", operatorStatus.ACTIVE);
    },
    applyTranslationResult(params) {
      this.$refs.sendMessageToUserModal.applyTranslationResult(params);
    },
  },
};
</script>

<style scoped>
.wrap-overflow {
  overflow-wrap: break-word;
}
</style>
