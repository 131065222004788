<template>
  <div>
    <div class="title">
      <span>{{ $t('history.selectDate') }}</span><span
        class="float-right pointer-cursor pr-2"
        @click="foldPane">
        <span v-show="isFolded">
          <i class="fas fa-plus-circle"></i>
        </span>
        <span v-show="!isFolded">
          <i class="fas fa-minus-circle"></i>
        </span>
      </span>
    </div>
    <div :class="{hide: isFolded}">
      <div class="calendar-area">
        <v-calendar
          ref="calendar"
          :from-page="page"
          :attributes="attrs"
          :formats="formats"
          :max-date="maxDate"
          @update:fromPage="selectMonth"
          @dayclick="selectDate">
        </v-calendar>
      </div>
      <div class="calendar-buttons">
        <b-btn
          variant="outline-primary"
          @click="selectPreviousDate">
          <
        </b-btn>
        <b-btn
          :disabled="isNextDisabled"
          variant="outline-primary"
          @click="selectNextDate">
          >
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'selectedDate','allDate', 'insertDate'
  ],
  data() {
    return {
      attrs: [
        {
          highlight: {
            backgroundColor: '#ff8080',
          },
          dates: this.selectedDate,
        },
        {
          dot: {},
          dates: this.allDate
        }
      ],
      formats: {
        title: 'YYYY M'
      },
      isFolded: false,
      clickedDate: null,
      page:{month: this.insertDate.month, year: this.insertDate.year},
      maxDate: new Date(),
      moveFlag: false // カレンダー日付移動ボタンを押したかのチェックフラグ
    }
  },
  mounted() {
    this.maxDate.setHours(0,0,0,0)
    if(this.moveFlag === false){
      return this.selectLinkedInsertDate()
    }
  },
  computed:{
    isNextDisabled(){
      return this.selectedDate >= this.maxDate
    }
  },
  methods: {
    selectMonth(date){
      this.$emit('month-selected', date.year, date.month)
    },
    selectLinkedInsertDate(){ // 会話履歴画面でリンクを押して飛ばしたデータの日付をカレンダーに表示
      this.$emit('date-selected', this.insertDate.year, this.insertDate.month, this.insertDate.day)
      this.attrs[0].dates = new Date(this.insertDate.year, this.insertDate.month -1, this.insertDate.day)
    },
    selectDate(e) {
      if(this.maxDate < e.date){
        return
      }
      if(this.clickedDate !== e.id){ // ダブルクリックすると、2回呼び出しが行うことを防ぐための条件
        this.clickedDate = e.id
        let year = e.year
        let month = e.month
        let day = e.day
        this.$emit('date-selected', year, month, day)
        // Date型は月だけ0-indexedなのでマイナス1しておく
        this.attrs[0].dates = new Date(year, month - 1, day)
        // Fetch chat-log-date if other month date selected
        if(!(this.selectedDate.getFullYear() === year && (this.selectedDate.getMonth()+1) === month)){
          this.$emit('month-selected', year, month)
        }
      }
    },
    foldPane() {
      this.isFolded = !this.isFolded
    },
    selectPreviousDate() {
      this.moveFlag = true
      let year = this.attrs[0].dates.getFullYear()
      let month = this.attrs[0].dates.getMonth()
      let currentMonth = month
      let day = this.attrs[0].dates.getDate()
      this.attrs[0].dates = new Date(year, month, day - 1)
      year = this.attrs[0].dates.getFullYear()
      month = this.attrs[0].dates.getMonth()
      day = this.attrs[0].dates.getDate()
      this.$emit('date-selected', year, month + 1, day)
      if (month != currentMonth) {
        // 月が変わったら表示中の月を変更
        this.$refs.calendar.$children[0].movePrevMonth()
      }
    },
    selectNextDate() {
      if(this.isNextDisabled){
        return
      }
      this.moveFlag = true
      let year = this.attrs[0].dates.getFullYear()
      let month = this.attrs[0].dates.getMonth()
      let currentMonth = month
      let day = this.attrs[0].dates.getDate()
      this.attrs[0].dates = new Date(year, month, day + 1)
      year = this.attrs[0].dates.getFullYear()
      month = this.attrs[0].dates.getMonth()
      day = this.attrs[0].dates.getDate()
      this.$emit('date-selected', year, month + 1, day)
      if (month != currentMonth) {
        // 月が変わったら表示中の月を変更
        this.$refs.calendar.$children[0].moveNextMonth()
      }
    }
  }
}

</script>
