import { shapes, elementTools } from "@joint/plus";
import { START_NODE_ID, NODE_TYPE_QUESTION, NODE_TYPE_ANSWER } from "./const";

var app = app || {};


app.Factory = {
  /**
   * 回答のノードを生成
   */
  createAnswer: function (
    id,
    intentId,
    answers,
    origin = { x: 100, y: 350 },
    vm = null,
    language = null
  ) {
    const node = new shapes.standard.Rectangle({
      id,
      intentId,
      vm,
      type: NODE_TYPE_ANSWER,
      ports: {
        groups: {
          in: {
            position: "left",
            attrs: {
              circle: {
                magnet: "passive",
              },
            },
          },
          out: {
            position: "right",
            attrs: {
              circle: {
                magnet: true,
                stroke: "none",
                fill: "#AAD6EC",
                r: 15,
              },
            },
          },
        },
        items: [{ group: "out" }],
      },
      inPorts: [{ id: "in", label: "In" }],
    });
    let answerData = [];
    answers.forEach((a, idx) => {
      let lineBreakRemovedA = a.replace(/(\r\n|\n|\r)/gm, "");
      answerData.push({
        id: intentId + "-" + idx,
        text:
          lineBreakRemovedA.length > 30
            ? lineBreakRemovedA.slice(0, 30) + "..."
            : lineBreakRemovedA,
        language: language,
      });
    });
    node.resize(360, 30);
    let text = answers[0].split("\n").join("");
    if (text && text.length > 30) {
      text = text.slice(0, 30) + "…";
    }
    node.attr({
      body: {
        fill: "#78BBE6",
        strokeWidth: 0,
        rx: 10,
        ry: 10,
      },
      label: {
        text,
        language: language,
        fill: "white",
        fontSize: 14,
      },
    });
    return node;
  },

  /**
   * 質問のノードを生成
   */
  createQuestion: function (
    id,
    intentId,
    texts,
    origin = { x: 100, y: 350 },
    vm = null,
    language = null
  ) {
    const node = new shapes.standard.Rectangle({
      id,
      intentId,
      vm,
      type: NODE_TYPE_QUESTION,
      ports: {
        groups: {
          in: {
            position: "left",
            attrs: {
              circle: {
                magnet: "passive",
                stroke: "white",
                fill: "#edabab",
                r: 15,
              },
            },
          },
        },
        items: [{ group: "in" }],
      },
    });
    let textData = [];
    texts.forEach((a, idx) => {
      let lineBreakRemovedA = a.replace(/(\r\n|\n|\r)/gm, "");
      textData.push({
        id: intentId + "-" + idx,
        text:
          lineBreakRemovedA.length > 30
            ? lineBreakRemovedA.slice(0, 30) + "..."
            : lineBreakRemovedA,
        language: language,
      });
    });

    let text = texts[0];
    if (text) {
      text = text.length > 30 ? text.slice(0, 30) + "…" : text;
    }
    node.resize(360, 30);
    node.attr({
      body: {
        fill: "#e67777",
        strokeWidth: 0,
        rx: 10,
        ry: 10,
      },
      label: {
        text,
        language: language,
        fill: "white",
        fontSize: 14,
      },
    });

    return node;
  },

  createStartNode() {
    var rect = new shapes.standard.Rectangle({
      id: START_NODE_ID,
    });
    rect.position(10, 10);
    rect.resize(150, 50);
    rect.attr({
      body: {
        fill: "#F99F48",
        strokeWidth: 0,
      },
      label: {
        text: "Start",
        fill: "white",
        fontSize: 25,
      },
    });
    return rect;
  },

  createLink: function (fromNodeId, toNodeId) {
    // const customLink = new dia.Link({
    const customLink = new shapes.standard.Link({
      source: { id: fromNodeId, port: "out" },
      target: { id: toNodeId, port: "in" },
      connector: { name: "rounded" },
      router: {
        name: "manhattan",
      },
      attrs: {
        ".marker-target": {
          d: "M 10 0 L 0 5 L 10 10 z",
          fill: "#6a6c8a",
          stroke: "#6a6c8a",
        },
        ".marker-source": {
          display: "none",
        },
        ".connection": {
          stroke: "#6a6c8a",
          strokeWidth: 3,
        },
      },
    });

    return customLink;
  },

  /**
   * 選択時ツールに追加ボタンを作成
   */
  createAddButton: function (int, color, type) {
    var addButton = new elementTools.Button({
      x: int,
      y: "130%",
      markup: [
        {
          tagName: "circle",
          selector: "button",
          attributes: {
            r: 10,
            stroke: "white",
            fill: color,
            cursor: "pointer",
          },
        },
        {
          tagName: "path",
          selector: "icon",
          attributes: {
            d: "M -7 0 7 0 M 0 7 0 -7",
            fill: "none",
            stroke: "white",
            "stroke-width": 3,
            "pointer-events": "none",
          },
        },
      ],
    });

    if (type == NODE_TYPE_QUESTION) {
      addButton.options.action = function () {
        this.model.attributes.vm.$refs.intentDetail.addResponse();
      };
    } else {
      // 回答ノードにボタンを追加
      addButton.options.action = function () {
        // 回答ノードから新しい質問を生やす場合のフラグ
        this.model.attributes.vm.controller.isAddingNewIntent = true;
        this.model.attributes.vm.$refs.intentDetail.addUserSay();
      };
    }
    return addButton;
  },

  /**
   * 選択時ツール用に削除ボタンを作成
   */
  createDeleteButton: (xPercentage, vm, deleteNodeType) => {
    const deleteButton = new elementTools.Button({
      x: xPercentage,
      y: "-30%",
      // ノード削除ボタンが押されたら、ノード削除確認用モーダルを表示するようにする
      action: () => vm.showDeleteNodeModal(deleteNodeType),
      markup: [
        {
          tagName: "circle",
          selector: "button",
          attributes: {
            r: 10,
            stroke: "white",
            fill: "black",
            cursor: "pointer",
          },
        },
        {
          tagName: "path",
          selector: "icon",
          attributes: {
            d: "M -5 -5 5 5 M -5 5 5 -5",
            fill: "none",
            stroke: "white",
            "stroke-width": 3,
            "pointer-events": "none",
          },
        },
      ],
    });
    return deleteButton;
  },
};

export default app;
