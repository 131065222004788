<template>
  <div id="log" class="log-area p-0">
    <!-- operator mode用の表示 -->
    <span>
      <div
        v-if="allow_operator_handling && isOperatorSupportEnable"
        class="sticky-top operator-mode"
      >
        {{ $t("history.operatorModeProgress") }}
      </div>

      <div
        v-else-if="allow_operator_handling && isOperatorSupportCalling"
        class="sticky-top operator-mode"
      >
        {{ $t("history.operatorModeWaiting") }}
      </div>
    </span>

    <!-- クライアントIDnよるフィルタが許可されている場合にクライアントIDを選択するためのUIを表示 -->
    <div
      v-if="clientIdFilterEnabled && $parent.controller.selectedSessionId"
      class="client-id-selector"
    >
      <b-form-select
        :disabled="!clientIds || clientIds.length <= 0"
        :options="clientIds"
        v-model="selectingClientId"
      >
        <template #first>
          <b-form-select-option :value="null">
            {{ $t("general.all") }} - {{ $t("history.questions") }}:
            {{ logs.length }}
          </b-form-select-option>
        </template>
      </b-form-select>
    </div>

    <!-- 以前のログを読み込むUI -->
    <div class="operator-mode bg-color" v-if="existsLogs">
      <div class="text-center">
        <div v-if="$parent.controller.isScrollTop" class="text-primary">
          {{ $t("history.loading") }}
          <b-spinner
            variant="primary"
            type="grow"
            style="width: 0.5rem; height: 0.5rem"
          />
          <b-spinner
            variant="warning"
            type="grow"
            style="width: 0.5rem; height: 0.5rem"
          />
          <b-spinner
            variant="success"
            type="grow"
            style="width: 0.5rem; height: 0.5rem"
          />
        </div>

        <button
          v-else
          class="btn btn-xs btn-light"
          @click="loadOldMessages"
          :disabled="!$parent.controller.existsPreviousLog"
        >
          {{ $t("history.readOldLog") }}
        </button>
      </div>
    </div>

    <!-- 会話読み込み中アニメーションUI -->
    <div class="d-flex h-100" v-if="$parent.controller.isLoadingConversation">
      <div class="align-self-center mx-auto">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Loading..."
          variant="primary"
        />
      </div>
    </div>

    <!-- 会話ログ表示領域 -->
    <div class="log" v-else>
      <div
        v-for="(chatItem, idx) in clientIdFilteredLogs"
        class="message-area"
        :key="idx"
        :id="setTargetLogId(chatItem)"
      >
        <!-- 会話の日毎の区切りUI -->
        <div
          v-if="isFirstLogOfDay(chatItem, clientIdFilteredLogs[idx - 1])"
          class="log-header-line"
        >
          <h6 class="text-center">
            <span class="shadow-sm rounded">
              {{ chatItem.insertDateTime | moment("YYYY-MM-DD dddd") }}
            </span>
          </h6>
        </div>

        <!-- <template v-if="clientIdBorderTopShown(log, idx)">
          <div class="client-id-border__top">ClientID: {{ log.clientId }}</div>
        </template> -->

        <!-- 質問領域の表示 -->
        <div
          class="message-box right"
          v-if="
            ['usersay', 'location', 'file_usersay'].includes(chatItem.logType)
          "
        >
          <div class="text-right">
            <div v-if="chatItem.logType === 'usersay'">
              <chat-item-user-say
                :chat-item="chatItem"
                :client-id-filter-enabled="clientIdFilterEnabled"
                :is-translating="isTranslating"
                :translation-object="translationObject"
                :u-i-language="ui_language"
              />
            </div>
            <div v-else-if="chatItem.logType === 'location'">
              <chat-item-location
                :chat-item="chatItem"
                :client-id-filter-enabled="clientIdFilterEnabled"
              />
            </div>
            <div v-else-if="chatItem.logType === 'file_usersay'">
              <chat-item-file-user-say
                :chat-item="chatItem"
                :client-id-filter-enabled="clientIdFilterEnabled"
              />
            </div>
          </div>
        </div>

        <div class="message-box left">
          <div>
            <!-- オペレーターモード関連情報表示 -->
            <div
              v-if="chatItem.intentName === operatorDisplayName.START"
              class="message-bg-orange mb-1"
            >
              {{ $t("history.operatorModeStart") }}
            </div>
            <div
              v-else-if="chatItem.intentName === operatorDisplayName.CALLING"
              class="message-bg-orange mb-1"
            >
              {{ $t("history.operatorModeCall") }}
            </div>
            <div
              v-else-if="chatItem.intentName === operatorDisplayName.FINISH"
              class="message-bg-orange mb-1"
            >
              {{ $t("history.operatorModeEnd") }}
            </div>

            <!-- テキストやカードなどの回答表示領域 -->
            <div v-else-if="chatItem.logType === 'text'">
              <chat-item-text
                :chat-item="chatItem"
                :redirector-access-logs="
                  getRedirectorLogsForChatItem(chatItem, idx)
                "
                :translation-object="translationObject"
              />
            </div>
            <div v-else-if="chatItem.logType === 'quick_replies'">
              <chat-item-quick-replies
                :chat-item="chatItem"
                :redirector-access-logs="
                  getRedirectorLogsForChatItem(chatItem, idx)
                "
                :translation-object="translationObject"
              />
            </div>
            <div v-else-if="chatItem.logType === 'card'">
              <chat-item-card
                :chat-item="chatItem"
                :redirector-access-logs="
                  getRedirectorLogsForChatItem(chatItem, idx)
                "
                :translation-object="translationObject"
              />
            </div>
            <div v-else-if="chatItem.logType === 'image'">
              <chat-item-image
                :chat-item="chatItem"
                :redirector-access-logs="
                  getRedirectorLogsForChatItem(chatItem, idx)
                "
              />
            </div>
            <div v-else-if="chatItem.logType === 'audio'">
              <chat-item-audio
                :chat-item="chatItem"
                :redirector-access-logs="
                  getRedirectorLogsForChatItem(chatItem, idx)
                "
              />
            </div>
            <div v-else-if="chatItem.logType === 'video'">
              <chat-item-video
                :chat-item="chatItem"
                :redirector-access-logs="
                  getRedirectorLogsForChatItem(chatItem, idx)
                "
              />
            </div>

            <div
              class="text-secondary text-size-sm-left"
              v-if="
                chatItem.fulfillmentText !== 'No message response' &&
                !debug &&
                ui_language !== chatItem.languageCode
              "
            >
              <div v-if="isTranslating" title="Translating...">
                <b-spinner
                  style="width: 0.5rem; height: 0.5rem"
                  v-for="variant in variants"
                  :variant="variant"
                  :key="variant"
                  type="grow"
                />
              </div>
            </div>
          </div>
        </div>

        <template v-if="clientIdBorderBottomShown(chatItem, idx)">
          <div class="client-id-border__bottom">
            ClientID: {{ chatItem.clientId }}
          </div>
        </template>
      </div>
    </div>

    <!-- 新しいメッセージ読み込みUI -->
    <div class="operator-mode bg-color" v-if="existsLogs">
      <div class="text-center">
        <!-- 以下は3色のローディングアニメーションを実現するために3つスピナーを設置している -->
        <div v-if="$parent.controller.isScrollBottom" class="text-primary">
          <span>
            {{ $t("history.loading") }}
          </span>
          <b-spinner
            variant="primary"
            type="grow"
            style="width: 0.5rem; height: 0.5rem"
          />
          <b-spinner
            variant="warning"
            type="grow"
            style="width: 0.5rem; height: 0.5rem"
          />
          <b-spinner
            variant="success"
            type="grow"
            style="width: 0.5rem; height: 0.5rem"
          />
        </div>

        <!-- isScrollBottomがなにか確認 -->
        <button v-else class="btn btn-xs btn-light" @click="loadNewMessages">
          {{ $t("history.readNewLog") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  operatorStatus,
  operatorStatusReason,
  operatorDisplayName,
} from "../util/operatorStatus";

export default {
  props: [
    "logs",
    "selectedPlatform",
    "ui_language",
    "debug",
    "translationObject",
    "redirectorAccessLogs",
    "isTranslating",
    "operatorSession",
    "sessionId",
    "logId",
    "allow_operator_handling",
    "clientIdFilterEnabled",
    "selectedClientId",
  ],
  data() {
    return {
      variants: ["primary", "danger", "success"],
      operatorDisplayName,
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      // Set moment.js localization to format date
      if (this.ui_language) {
        this.$moment.locale(this.ui_language.toLowerCase());
      }
    });
  },
  computed: {
    existsLogs() {
      return this.logs.length > 0;
    },
    clientIds() {
      // 全履歴から、ClientIDと該当ClientIDの質問数を取得し、ClientIDのドロップダウンで選択肢と使う
      const clientIdMap = {};
      if (this.logs.length > 0) {
        for (let log of this.logs) {
          const clientId = log.clientId;
          if (!(clientId in clientIdMap)) {
            clientIdMap[clientId] = { clientId: clientId, count: 0 };
          }
          const clientIdObj = clientIdMap[clientId];
          clientIdObj.count += 1;
        }
      }
      const clientIds = Object.values(clientIdMap)
        .sort((a, b) => {
          if (a.count > b.count) {
            return -1;
          } else if (a.count < b.count) {
            return 1;
          }
          return 0;
        })
        .map((obj) => ({
          text: `Client ID: ${obj.clientId ?? ""} - ${this.$t(
            "history.questions"
          )}: ${obj.count}`,
          value: obj.clientId,
        }));
      return clientIds;
    },
    clientIdFilteredLogs() {
      if (this.selectedClientId === null) {
        return this.logs;
      }
      return this.logs.filter((l) => l.clientId === this.selectedClientId);
    },
    isOperatorSupportEnable() {
      if (["line", "slack", "web_chat_v2"].includes(this.selectedPlatform)) {
        let selectedOperatorSession = this.operatorSession.filter(
          (session) =>
            session.session_id === this.sessionId &&
            [
              operatorStatusReason.STARTED_BY_USER,
              operatorStatusReason.STARTED_BY_OPERATOR,
            ].includes(session.status_reason)
        );
        if (
          selectedOperatorSession.length > 0 &&
          selectedOperatorSession[0].status === operatorStatus.ACTIVE
        ) {
          return true;
        }
      }
      return false;
    },
    isOperatorSupportCalling() {
      if (["line", "slack", "web_chat_v2"].includes(this.selectedPlatform)) {
        let selectedOperatorSession = this.operatorSession.filter(
          (session) =>
            session.session_id === this.sessionId &&
            [
              operatorStatusReason.STARTED_BY_USER,
              operatorStatusReason.STARTED_BY_OPERATOR,
            ].includes(session.status_reason)
        );
        if (
          selectedOperatorSession.length > 0 &&
          selectedOperatorSession[0].status === operatorStatus.CALLING
        ) {
          return true;
        }
      }
      return false;
    },
    /**
     * スクロールの宛先のログに特別なIDを付与する
     */
    setTargetLogId() {
      return (log) => {
        if (log.logId === this.logId) {
          return "target_log";
        }
      };
    },
    selectingClientId: {
      get() {
        return this.selectedClientId;
      },
      set(val) {
        this.$emit("select-client-id", val);
      },
    },
    // clientIdBorderTopShown() {
    //   // 一番目の履歴か、前の履歴とClientIDが違う時、ClientIDのボーダーライン（上半）を表示
    //   return (log, idx) => {
    //     if (!this.clientIdFilterEnabled || this.selectedClientId !== null) {
    //       return false;
    //     }
    //     return (
    //       idx === 0 ||
    //       (idx > 0 &&
    //         this.clientIdFilteredLogs[idx - 1].clientId !== log.clientId)
    //     );
    //   };
    // },
    clientIdBorderBottomShown() {
      // 最後の履歴か、次の履歴とClientIDが違う時、ClientIDのボーダーライン（下半）を表示
      return (log, idx) => {
        if (!this.clientIdFilterEnabled || this.selectedClientId !== null) {
          return false;
        }
        const filteredLogs = this.clientIdFilteredLogs;
        return (
          idx === filteredLogs.length - 1 ||
          (idx < filteredLogs.length - 1 &&
            filteredLogs[idx + 1].clientId !== log.clientId)
        );
      };
    },
  },
  methods: {
    /**
     * 古いログを読み込むボタンから過去の日付の履歴を読み込み
     */
    loadOldMessages() {
      this.$nextTick(async () => {
        if (this.$parent.controller.existsPreviousLog) {
          try {
            await this.$parent.controller.getConversationByDateAndSession(
              this.$parent.controller.deepCloneSelectedDateUp,
              "up"
            );

            // このファイルの一番外側の要素
            const logElement = document.getElementById("log");
            logElement.scrollTop = 0;

            if (!this.$parent.controller.existsPreviousLog) {
              this.$parent.showAlert(this.$t("history.noOldLogDismiss"));
            }
          } catch {
            this.$parent.controller.isScrollTop = false;
          }
        } else {
          this.$parent.showAlert(this.$t("history.noOldLogDismiss"));
        }
      });
    },
    /**
     * 新しいログを読み込むボタンから最近の日付の履歴を読み込み
     */
    async loadNewMessages() {
      this.$nextTick(async () => {
        if (this.$parent.controller.existsNextLog) {
          try {
            await this.$parent.controller.getConversationByDateAndSession(
              this.$parent.controller.deepCloneSelectedDateDown,
              "down"
            );

            // スクロール位置の調整
            const logElement = document.getElementById("log");
            logElement.scrollTop = logElement.scrollHeight;

            if (!this.$parent.controller.existsNextLog) {
              this.$parent.showAlert(this.$t("history.noNewLogDismiss"));
            }
          } catch {
            this.$parent.controller.isScrollBottom = false;
          }
        } else {
          this.$parent.showAlert(this.$t("history.noNewLogDismiss"));
        }
      });
    },
    scrollIntoTargetLog() {
      if (document.getElementById("target_log") !== null) {
        document.getElementById("target_log").scrollIntoView();
      }
    },
    /**
     * 一つ前のログと日時が違うかをチェック
     */
    isFirstLogOfDay(log, previousLog) {
      if (!(log && previousLog)) {
        return true;
      }
      return (
        log.insertDateTime.getDate() !== previousLog.insertDateTime.getDate()
      );
    },
    getRedirectorLogsForChatItem(chatItem, chatItemIdx) {
      return this.redirectorAccessLogs.filter((accessLog) => {
        // usersayにはredirectorは使用しないので除外
        if (
          ["usersay", "file_usersay", "location"].includes(chatItem.logType)
        ) {
          return false;
        }

        // session idが一致しないものを除去
        if (accessLog.session_id !== this.sessionId) {
          return false;
        }

        const accessLogCreatedAt = new Date(accessLog.created_at);

        if (!this.clientIdFilteredLogs[chatItemIdx + 1]) {
          // 次のアイテムがない時、そのchatItem以降のredirectorLogが全て対象
          return accessLogCreatedAt >= chatItem.insertDateTime;
        } else {
          // 次のアイテムがある時、そのchatItem以降かつ次のchatItemの時刻前のアクセスログを返す
          return (
            accessLogCreatedAt >= chatItem.insertDateTime &&
            accessLogCreatedAt <
              this.clientIdFilteredLogs.find(
                (log, idx) =>
                  !["usersay", "file_usersay", "location"].includes(
                    log.logType
                  ) && idx > chatItemIdx
              ).insertDateTime
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.text-size-sm-right {
  text-align: right;
  font-size: x-small;
}
.text-size-sm-left {
  font-size: x-small;
}
.gmap-canvas {
  height: auto;
  width: 100%;
}
.gmap-message {
  background-color: #007bff;
  max-width: 100%;
  padding: 0.2rem;
  color: white;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.log-header-line {
  display: block;
  margin: 0px 20px 20px 10px;
}
.log-header-line h6 {
  position: relative;
  font-weight: bold;
  border-bottom: 1px solid #dddddd;
}
.log-header-line h6 span {
  position: relative;
  background-color: #ffffff;
  top: 10px;
  padding: 5px 10px;
}
#target_log.message-row {
  background-color: #ffffcc;
}
.client-id-selector {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 5px;
  background: white;
}
.client-id-border__top,
.client-id-border__bottom {
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  color: #6a6b8a;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  margin-top: 5px;
  margin-bottom: 5px;
}
.client-id-border__top {
  border-top: 1px solid #dddddd;
}
.client-id-border__bottom {
  border-bottom: 1px solid #dddddd;
}
</style>
