<template>
  <div v-if="!isViewer">
    <!-- Show all History -->
    <div class="row">
      <div class="col-md-4">
        <span>{{ $t('validation.selectValidationLanguage') }}</span>
        <div class="input-group">
          <b-form-select
            :state=customQuestionLanguageState
            :options="controller.languages"
            @change="onSelectLanguage"
            v-model="controller.selectedLanguage">
          </b-form-select>
          <div class="input-group-append">
            <button
              class="btn btn-info btn-sm"
              @click="saveIntentCheckHistory"
              :disabled="!controller.selectedLanguage">
              <i class="fas fa-robot"></i>
              {{ $t('validation.validate') }}
            </button>
          </div>
        </div>
      </div>
      <!-- START Filter through language, status -->
      <div class="col-md-4">
        <span>{{ $t('validation.search') }}</span>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <label class="input-group-text">{{ $t('validation.language') }}</label>
          </div>
          <b-form-select
            :options="controller.languages"
            v-model="controller.selectedLanguageFilter">
          </b-form-select>
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <label class="input-group-text">{{ $t('validation.status') }}</label>
          </div>
          <b-form-select
            :options="controller.historyStatusOptions"
            v-model="controller.selectedHistoryStatusFilter">
          </b-form-select>
        </div>
        <button
          class="btn btn-info float-right"
          @click="doFilterValidationList">
          <i class="fas fa-search"></i>
          {{ $t('validation.filter') }}
        </button>
      </div>
      <!-- END Filter through language, status -->
      <div class="col-md-4 text-right">
        <div class="btn-group mt-4">
          <button type="button"
                  class="btn btn-info"
                  @click="showTestModal">
            <i class="fas fa-clipboard-check"></i>
            {{ $t('validation.addManualQuestion') }}
          </button>
          <button
            class="btn btn-info"
            @click="compareHistory"
            :disabled="!controller.intentCheckHistoryData">
            <i class="fab fa-cloudsmith"></i>
            {{ $t('validation.compare') }}
          </button>
        </div>
      </div>
    </div>
      <!-- Column -->
    <div class="row ml-1 mr-1 mt-2">
      <h5>{{ $t('validation.validationHistory') }}</h5>
        <div class="table-responsive">
            <table class="table table-hover text-center">
              <thead class="table-info">
                <tr>
                  <th>#</th>
                  <th>{{ $t('validation.date') }}</th>
                  <th>{{ $t('validation.language') }}</th>
                  <th>{{ $t('validation.status') }}</th>
                  <th>{{ $t('validation.result') }}</th>
                  <th>{{$t('validation.action')}}</th>
                </tr>
              </thead>
              <tbody>
                  <tr v-if="!controller.intentCheckHistoryLoader"
                      v-for="(item, index) in controller.intentCheckHistoryData"
                      :key="index" :title="$t('validation.goToDetailedValidationResultPage')">
                    <td @click="details(item.id)" class="pointer-cursor">{{controller.indexCounter + (index + 1)}}</td>
                    <td @click="details(item.id)" class="pointer-cursor">{{item.name}}</td>
                    <td @click="details(item.id)" class="pointer-cursor">{{item.language}}</td>
                    <td @click="details(item.id)" class="pointer-cursor">
                      {{item.status}}
                      <span v-if="item.status === 'In progress...'">( {{item.complete}}/{{item.total}} )</span>
                    </td>
                    <td @click="details(item.id)" class="pointer-cursor">
                      <div class="btn-group">
                        <b-button variant="light">{{item.success}} <b-badge variant="success"><i class="fas fa-check"></i></b-badge></b-button>
                        <b-button variant="light">{{item.failure}} <b-badge variant="danger"><i class="fas fa-times"></i></b-badge></b-button>
                      </div>
                    </td>
                    <td >
                      <button v-b-tooltip.hover
                        :title="$t('buttons.delete')"
                        class="btn btn-danger mr-1"
                        @click="deleteHistoryConfirm(item)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                      <button v-b-tooltip.hover
                        :title="$t('validation.csvExport')"
                        class="btn btn-info mr-1"
                        :placement="'bottom'"
                        @click="csvDownload(item.id)">
                        <i class="fas fa-file-csv"></i>
                      </button>
                      <button v-b-tooltip.hover
                        :title="$t('validation.excelExport')"
                        class="btn btn-primary"
                        @click="excelDownload(item.id)">
                        <i class="fas fa-file-excel"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="controller.intentCheckHistoryLoader">
                    <td colspan="6">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </td>
                  </tr>
                  <tr v-if="!controller.intentCheckHistoryLoader && controller.intentCheckHistoryData.length === 0">
                    <td colspan="6">
                      <span class="text-danger">{{ $t('validation.noRecordFound') }}</span>
                    </td>
                  </tr>
              </tbody>
            </table>
            <div class="row mt-1">
              <div class="col">
                <pagination
                  :params="controller.params"
                  :num_page="controller.num_page"
                  :callback="getIntentCheckHistory"
                />
              </div>
            </div>
      </div>
      <test-chat
        v-if="controller.testChatController"
        :controller="controller.testChatController"
      >
      </test-chat>
    </div>
    <b-modal
      :title="$t('validation.compare')"
      ref="compareHistoryModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
      @ok="compareOk">
      <div>
        <div>{{ $t('validation.validationHistory') + ' 1' }}</div>
        <b-form-select
         v-model="currentHistory"
         @input="omitSelectedHistory">
          <optgroup v-for="(options, name) in controller.histories" :label="name">
            <option v-for="option in options" :value="option.value">
              {{ option.text }}
            </option>
          </optgroup>
        </b-form-select>
        <div>{{ $t('validation.validationHistory') + ' 2' }}</div>
        <b-form-select
         v-model="prevHistory"
         :disabled="!currentHistory">
          <optgroup v-for="(options, name) in controller.preHistories" :label="name">
            <option v-for="option in options" v-if="option" :value="option.value">
              {{ option.text }}
            </option>
          </optgroup>
        </b-form-select>
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="compareOk"
        :okDisabled="!(currentHistory && prevHistory)"
        @cancel="$refs.compareHistoryModal.hide()">
      </modal-footer>
    </b-modal>
    <delete-confirm-modal
      ref="deleteHistoryModal"
      :title="$t('validation.deleteHistory')"
      :bodyMessage="$t('validation.deleteConfirmMessage')"
      @keydown.native.enter="deleteHistory"
      @ok="deleteHistory">
    </delete-confirm-modal>
    <error-modal
      ref="errorModal"
      :message="message">
    </error-modal>
    <progress-modal
      ref="progressModal"
      :message="message">
    </progress-modal>
    <!-- START Test Modal-->
    <b-form ref="testForm"
            @submit="handleSubmitTestForm">
      <b-modal ref="test-modal"
               header-bg-variant="info"
               header-text-variant="white"
               :hide-footer="!controller.selectedLanguage"
               :title="$t('validation.customQuestionTitle')"
               scrollable>
        <div class="text-center" v-if="customQuestionLoader && controller.selectedLanguage">
          <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="alert alert-danger" v-if="!controller.selectedLanguage">
          <i class="fas fa-exclamation-triangle"></i>
          {{$t('validation.customQuestionSelectLanguage')}}
        </div>
        <div class="alert alert-warning" v-if="testForm.length === 0  && controller.selectedLanguage">
          <i class="fas fa-exclamation-circle"></i>
          {{$t('validation.customQuestionAlertMessage')}}
        </div>
        <div v-for="(formValue, formIndex) in $v.testForm.$each.$iter"
             v-if="!customQuestionLoader && controller.selectedLanguage"
             :key="formIndex">
          <b-form-group :label="$t('validation.customQuestion') +' '+ countQuestions(formIndex)">
            <b-input-group>
              <b-form-textarea
              id="question-2"
              v-model.trim="formValue.question.$model"
              :placeholder="$t('validation.enterQuestion')"
              :state="!formValue.question.$error"
              rows="3"
              required>
              </b-form-textarea>
              <b-input-group-append>
                <b-button variant="danger"
                          :disabled="isDisabledTestFormQuestionBtn"
                          @click="removeQuestionInput(formIndex)">
                  <i class="fas fa-minus"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <div slot="modal-footer" class="w-100">
          <b-button variant="success"
                    @click="addQuestionInput">
            <i class="fas fa-plus"></i>
          </b-button>
          <b-button variant="info"
                    class="float-right"
                    type="submit"
                    :disabled="$v.testForm.$error">
            OK
          </b-button>
        </div>
      </b-modal><!-- /.END Test Modal-->
    </b-form>
  </div>
</template>

<script>
  import { required, minLength } from 'vuelidate/lib/validators'
  import csrfToken from '../util/csrf-token'
  import {IntentCheckHistory, IntentCheckQuestion} from "../model/intent-check"
  import {I18n} from '../util/i18n'

  export default {
    props: ['controller'],
    data() {
      return {
        message: '',
        model: new IntentCheckHistory(),
        i18n: new I18n(window.i18nContext),
        currentHistory:null,
        prevHistory:null,
        customQuestionLanguageState: null,
        testForm: [],
        intentCheckQuestionModel: new IntentCheckQuestion(),
        customQuestionLoader: true
      }
    },
    validations: {
      testForm: {
        $each: {
          question: {
            required,
            minLength: minLength(2)
          }
        }
      }
    },
    computed:{
      isViewer() {
        return this.controller.isViewer()
      },
      isDisabledTestFormQuestionBtn() {
        const testQuestions = this.intentCheckQuestionModel.questions.filter(model => {
          return !!model.question
        })
        const lastQuestionExist = (testQuestions.length <= 0)
        const listQuestions = (parseInt(this.testForm.length) === 1)
        return lastQuestionExist && listQuestions
      }
    },
    methods:{
      details(id){
        window.location.href= this.controller.makeDetailUrl(id)
      },
      saveIntentCheckHistory(){
        this.model = new IntentCheckHistory()
        this.model.language = this.controller.selectedLanguage.language_code
        this.model.status = "In progress..."
        this.model.name = this.getFormattedTime()
        let result, message, promise
        [result, message, promise] = this.controller.saveIntentCheckHistory(
          this.model,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result) {
          this.showError(message)
        } else {
          this.showProgress(message)
          let self = this
          promise.then(this.hideProgress)
            .catch(function (error) {
              self.showError(error.response.data[0])
              self.hideProgress()
            })
        }
      },
      getFormattedTime() {
        let today = new Date();
        let y = today.getFullYear();
        // JavaScript months are 0-based.
        let m = today.getMonth() + 1;
        let d = today.getDate();
        let h = today.getHours();
        let mi = today.getMinutes();
        return y + "-" + m + "-" + d + " " + h + ":" + mi
      },
      deleteHistoryConfirm(data){
        this.model = data
        this.$refs.deleteHistoryModal.show()
      },
      deleteHistory(){
        let result, message, promise
        [result, message, promise] = this.controller.deleteIntentCheckHistory(
          this.model,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result) {
          this.showError(message)
        } else {
          this.showProgress(message)
          let self = this
          promise.then(this.hideProgress)
            .catch(function (error) {
              this.showError(error.response.data[0])
              self.hideProgress()
            })
        }
      },
      excelDownload(id){
        window.location.href = this.controller.makeDownloadUrl(id, 'excel')
      },
      csvDownload(id){
        window.location.href = this.controller.makeDownloadUrl(id, 'csv')
      },
      compareHistory(){
        this.currentHistory = null
        this.prevHistory = null
        this.$refs.compareHistoryModal.show()
      },
      omitSelectedHistory(data){
        if(data){
          this.prevHistory = null
          this.controller.omitSelectedHistory(data)
        }
        else{
          this.controller.preHistories = {
            default: [{
              value: null,
              text: "---"
            }]
          }
        }
      },
      compareOk(){
        this.controller.compareOk(this.currentHistory, this.prevHistory)
        this.$refs.compareHistoryModal.hide()
      },
      showProgress(message) {
        this.message = message
        this.$refs.progressModal.show()
      },
      hideProgress() {
        this.$refs.progressModal.hide()
      },
      showError(message) {
        this.message = message
        this.$refs.errorModal.show()
      },
      doFilterValidationList() {
        this.controller.params.page = 1
        this.getIntentCheckHistory()
      },
      showTestModal() {
        if(!this.controller.selectedLanguage){
          this.customQuestionLanguageState = false
        } else {
          this.customQuestionLanguageState = null
          // previous custom questions
          this.customQuestionLoader = true
          this.controller.onShowTestForm(this.controller.selectedLanguage).then(data => {
            this.customQuestionLoader = false
            if(data.length > 0){
              this.intentCheckQuestionModel.questions.splice(0, 1)
              data.map(test => {
                this.intentCheckQuestionModel.questions.push({question: test.question})
              })
            }
          })
          this.initializeTestForm()
          this.$v.$touch()
        }
        this.$refs['test-modal'].show()
      },
      addQuestionInput() {
        this.intentCheckQuestionModel.questions.push({question: ''})
        this.testForm = this.intentCheckQuestionModel.questions
        this.$v.$touch()
      },
      removeQuestionInput(inputElementID) {
        this.intentCheckQuestionModel.questions.splice(inputElementID, 1)
        this.testForm = this.intentCheckQuestionModel.questions
        this.$v.$touch()
      },
      handleSubmitTestForm(e) {
        e.preventDefault() // Prevent default form submission
        if(!this.controller.selectedLanguage) {
          return false
        }
        this.intentCheckQuestionModel.language = this.controller.selectedLanguage.language_code
        this.controller.onSubmitTestForm(this.intentCheckQuestionModel, csrfToken.getCsrfTokenFromCookie(document.cookie))
        this.$refs['test-modal'].hide()
      },
      initializeTestForm(){
        this.intentCheckQuestionModel.questions = [{question: ''}]
        this.testForm = this.intentCheckQuestionModel.questions
      },
      countQuestions(current) {
        return parseInt(current) + 1
      },
      onSelectLanguage(){
        this.customQuestionLanguageState = null
      },
      getIntentCheckHistory() {
        this.controller.intentCheckHistoryLoader = true
        this.controller.loadIntentCheckHistory()
      }
    },
    mounted() {
      this.getIntentCheckHistory()
    }
  }
</script>
