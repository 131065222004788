<template>
  <!-- 回答の補足情報表示用コンポネント -->
  <!-- オペレーターモード中は回答の詳細は表示しない -->
  <div v-if="!isResponseInOperatorMode">
    <div class="text-left text-secondary insert-date-time">
      <!-- 該当インテントへのリンク表示 -->
      <a
        v-if="chatItem.isLastItem"
        class="mr-1"
        :href="`/agent/${chatItem.languageCode}/intent?intent_name=${chatItem.intentName}`"
        target="_blank"
        v-b-tooltip.bottom
        :title="$t('question.moveToIntent')"
      >
        <i class="fas fa-link"></i>
      </a>

      <a
        v-if="chatItem.calledIntentName"
        class="text-warning mr-1"
        :href="`/agent/${chatItem.languageCode}/intent?intent_name=${chatItem.calledIntentName}`"
        target="_blank"
        v-b-tooltip.bottom
        :title="answerFromCalledIntentTitle"
      >
        <i class="fas fa-info"></i>
      </a>

      <!-- 回答送信時刻表示-->
      <span
        class="pointer-cursor"
        v-b-tooltip.hover.bottom.html
        :title="getFullTimeText(chatItem.insertDateTime)"
      >
        {{ getTimeFromDate(chatItem.insertDateTime) }}
      </span>

      <!-- TODO webhook利用メッセージであることの表示 -->
      <span v-if="isWebhookResponse(chatItem)" class="float-right">
        ({{ $t("history.responseViaWebhook") }})
      </span>

      <!-- pushメッセージであることの表示 -->
      <span
        v-else-if="chatItem.action === 'obotai.push'"
        class="push-icon pointer-cursor"
        v-b-tooltip.bottom
        :title="$t('history.responseViaPushAPI')"
      >
        <i class="fa fa-cogs"></i>
      </span>

      <!-- webhookを利用した回答であることの表示 -->
      <span v-else-if="chatItem.isWebhookResponse">
        {{ $t("history.webhookActionWithoutResponse") }}
      </span>
    </div>

    <!-- リダイレクターURL情報表示 -->
    <div
      v-for="(
        redirectorAccessLog, redirectorAccessLogIdx
      ) in redirectorAccessLogs"
      :key="redirectorAccessLogIdx"
      class="mb-1"
    >
      <span class="text-secondary insert-date-time">
        {{ getTimeFromDate(new Date(redirectorAccessLog.created_at)) }}
      </span>
      <a
        :href="makeRedirectUrl(redirectorAccessLog.short_id)"
        target="_blank"
        :id="`log_${redirectorAccessLogIdx}`"
      >
        {{ redirectorHost }}/{{ redirectorAccessLog.short_id }}
      </a>
      <span>{{ $t("history.isClick") }}</span>
      <b-popover
        :target="`log_${redirectorAccessLogIdx}`"
        :title="redirectTitle(redirectorAccessLog.short_id)"
        triggers="hover"
        placement="top"
      >
        <div class="text-center">
          <strong>{{ $t("history.originalUrl") }}</strong>
          <p>
            <a :href="redirectorAccessLog.original_url" target="_blank">{{
              redirectorAccessLog.original_url
            }}</a>
          </p>
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
import { operatorDisplayName } from "../../util/operatorStatus";

export default {
  props: ["chatItem", "redirectorAccessLogs"],
  data() {
    return {
      operatorDisplayName,
      redirectorHost:
        (window.requestContext && window.requestContext.redirectorHost) ||
        "https://r.obotai.com",
    };
  },
  computed: {
    getFullTimeText() {
      return (time) => {
        return this.getTimeFromDate(time).replace("T", " ");
      };
    },
    /**
     * TODO
     * webhookを経由した回答であるか
     */
    isWebhookResponse() {
      return (chatItem) => {
        return false;
        // return !!log.responseJSON.webhookStatus;
      };
    },
    /**
     * オペレーターモード中の質問への回答であるかのフラグ
     */
    isResponseInOperatorMode() {
      return this.chatItem.intentName === operatorDisplayName.OPERATOR_MODE;
    },
    makeRedirectUrl() {
      return (short_id) => {
        return window.location.origin + "/redirector/access_log/" + short_id;
      };
    },
    redirectTitle() {
      return (shortID) => {
        return `${this.redirectorHost}/${shortID}`;
      };
    },
    answerFromCalledIntentTitle() {
      return this.$t("history.AnswerFromOtherCalledIntent") + `: ${this.chatItem.calledIntentName}`
    }
  },
  methods: {
    /**
     * Date型を受け取り、整形済みの文字列を返す
     * TODO start,endはデフォルト以外の用途がなければ削除
     */
    getTimeFromDate(date) {
      return `${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;
    },
  },
};
</script>
