<template>
  <div v-if="getIconHTML(platform)" :title="platform" v-b-tooltip.hover>
    <span v-html="getIconHTML(platform)" />
  </div>
</template>

<script>
import PlatformDataResource from '../resource/platforms'

export default {
  props: ['platform'],
  methods: {
    getIconHTML(platformName) {
      if (!platformName) {
        return ''
      }

      const platformResource = new PlatformDataResource()
      const platformIcons = platformResource.PlatformIcons
      for (const key in platformIcons) {
        if (platformIcons.hasOwnProperty(key)) {
          const platformInfo = platformIcons[key]
          if (platformInfo.name === platformName) {
            return `<i class="fa-lg icon-size ${platformInfo.faIcon}"></i>`
          }
        }
      }

      return ''
    },
  },
}
</script>
