<template>
  <!-- ファイル質問表示用コンポネント -->
  <div>
    <div class="mt-1">
      <b-img
        v-show="isImage(chatItem.url)"
        class="pointer-cursor"
        thumbnail
        fluid
        :src="chatItem.url"
        alt="Uploaded Image"
        @click="openFile(chatItem.url)"
      />
      <video
        v-show="isVideo(chatItem.url)"
        class="pointer-cursor"
        :src="chatItem.url"
        width="300"
        height="300"
        controls
      />
      <div
        v-show="isfile(chatItem.url)"
        class="text-info pointer-cursor"
        @click="openFile(chatItem.url)"
      >
        <i class="far fa-file" style="width: 120px; height: 120px"></i>
      </div>
    </div>

    <!-- 質問送信時刻表示 -->
    <div class="text-right text-secondary">
      <span
        class="pointer-cursor"
        v-b-tooltip.hover.bottom.html
        :title="messageTooltipRight(chatItem)"
      >
        {{ getTimeFromDate(chatItem.insertDateTime) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["chatItem", "clientIdFilterEnabled"],
  computed: {
    messageTooltipRight() {
      // 右側メッセージ（質問）のツールチップで、ClientIDも見れるように
      return (chatItem) => {
        let html = "";
        if (this.clientIdFilterEnabled) {
          html += `ClientId: ${chatItem.clientId ?? "-"}</br>`;
        }
        return `${html}${this.getFullTime(this.chatItem.insertDateTime)}`;
      };
    },
    getFullTime() {
      return (time) => {
        return this.getTimeFromDate(time).replace("T", " ");
      };
    },
    isImage() {
      return (path) => {
        const [extension, ...nameParts] = path.split(".").reverse();
        return extension === "jpg" || extension === "jpeg";
      };
    },
    isVideo() {
      return (path) => {
        const [extension, ...nameParts] = path.split(".").reverse();
        return extension === "mp4";
      };
    },
    isfile() {
      return (path) => {
        const [extension, ...nameParts] = path.split(".").reverse();
        return (
          extension !== "mp4" && extension !== "jpeg" && extension !== "jpg"
        );
      };
    },
  },
  methods: {
    /**
     * TODO ??
     */
    getTimeFromDate(date) {
      return `${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;
    },
    openFile(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped>
/* .message-container {
  display: flex;
  flex-direction: column;
} */

.message-bg-blue {
  padding: 5px;
  display: inline-block;
  border-radius: 8px;
}
</style>
