import { RestApi } from '../util/rest-api'
import { SessionUserMeta, userMetaPlatformsData } from "../model/user-meta"
import csrfToken from '../util/csrf-token'
import axios from "axios";
import {VIEW_URL} from '../resource/urls'

class UserMetaManagementController {
  constructor(apiUrl) {
    this.SessionUserMetaAPI = new RestApi(apiUrl.SessionUserMeta, SessionUserMeta)
    this.sessionUserMetaEndpoint = apiUrl.UserMeta
    this.userMetaImportEndpoint = apiUrl.UserMetaImport
    this.allSessionUserMeta = []
    this.allUserMeta = []
    this.userMetaMap = {}
    this.latestLoaded = null
    SessionUserMeta.supportedPlatforms.forEach(platform => {
      this.userMetaMap[platform] = new Map()
    })
  }

  async ready() {
    await this.loadAll()
  }

  async loadAll(params={}) {
    this.allSessionUserMeta = await this.SessionUserMetaAPI.list({params})
  }

  async saveSessionUserMeta(sessionUserMeta, csrfToken=null) {
    await this.SessionUserMetaAPI.save(sessionUserMeta, csrfToken)
    await this.loadAll()
  }

  async destroySessionUserMeta(sessionUserMeta, csrfToken=null) {
    await this.SessionUserMetaAPI.destroy(sessionUserMeta, csrfToken)
    await this.loadAll()
  }

  /**
   * テナント内の全てのユーザーメタを取得する
   */
  async loadAllUserMetaMap() {
    await this.loadAll();
    this.latestLoaded = new Date();
    const newUserMetaMap = {};
    SessionUserMeta.supportedPlatforms.forEach(platform => {
      newUserMetaMap[platform] = new Map()
    })

    this.allSessionUserMeta.forEach(userMeta => {
      userMetaPlatformsData.forEach(platformData => {
        if (userMeta[platformData.fieldName]) {
          newUserMetaMap[platformData.name].set(userMeta[platformData.fieldName], userMeta.id)
        }
      })
    })
    this.userMetaMap = newUserMetaMap;
  }

  async requestForSessionUserMeta(data) {
    const token = csrfToken.getCsrfTokenFromCookie(document.cookie)
    let sendOptions = {}
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        data[key] = Array.from(data[key])
      }
    }
    Object.assign(sendOptions, {
      headers: {'X-CSRFToken': token}
    })
    return new Promise((resolve => {
      axios.post(this.sessionUserMetaEndpoint, data, sendOptions).then(data => {
        resolve(data.data.map(SessionUserMeta.fromData))
      })
    }))
  }

  getMatchedUserMeta(sessionId, platform) {
    const combinedSessionPlatforms = SessionUserMeta.combinedSessionPlatforms
    if (this.userMetaMap && platform in this.userMetaMap) {
      let userId = null
      if (combinedSessionPlatforms.indexOf(platform) !== -1) {
        const splits = sessionId.split('_')
        if (splits.length > 0) {
          userId = splits[splits.length - 1]
        }
      } else {
        userId = sessionId
      }
      if (userId && this.userMetaMap[platform].has(userId)) {
          if (this.allUserMeta.length > 0) {
            return this.allUserMeta.find(userMeta =>
              userMeta.id === this.userMetaMap[platform].get(userId)
            )
          } else if (this.allSessionUserMeta.length > 0) {
            return this.allSessionUserMeta.find(userMeta =>
              userMeta.id === this.userMetaMap[platform].get(userId)
            )
          }
      }
    }
    return null
  }
  uploadFile(form) {
    let sendOptions = {}
    let options={}
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken.getCsrfTokenFromCookie(document.cookie)}
    })
    return new Promise((resolve, reject) => {
      axios.post(
        this.userMetaImportEndpoint,
        form,
        sendOptions
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
  fileExport(type){
    window.location.href = VIEW_URL.UserMetaTemplate+'?export_type='+type
  }

}
export {
  UserMetaManagementController
}
