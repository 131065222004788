/**
 * Operator support related const
 */
const operatorStatus = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 2,
  CALLING: 3
})

const operatorStatusReason = Object.freeze({
  STARTED_BY_USER: 1,
  STARTED_BY_OPERATOR: 2,
  FINISHED_BY_USER: 3,
  FINISHED_BY_OPERATOR: 4,
  FINISHED_BY_TIMEOUT: 5
})

const lastActionStatus = Object.freeze({
  USER: 1,
  OPERATOR: 2
})

const operatorDisplayName = Object.freeze({
  START: '_OPERATOR_MODE_STATUS_UPDATE:START',
  CALLING: '_OPERATOR_MODE_STATUS_UPDATE:CALLING',
  FINISH: '_OPERATOR_MODE_STATUS_UPDATE:FINISH',
  OPERATOR_MODE: '_QUESTION_IN_OPERATOR_MODE',
  UNKNOWN: '_OPERATOR_MODE_STATUS_UPDATE:UNKNOWN'
})

export {
  operatorStatus,
  operatorStatusReason,
  lastActionStatus,
  operatorDisplayName
}
