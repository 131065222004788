import { I18n } from '../util/i18n'
import PlatformDataResource from "../resource/platforms"

class AnalyticsController {
  constructor(language, supportedLanguages, i18nContext, analyticsContext) {
    this.language = language
    this.supportedLanguages = supportedLanguages  // 切替可能な言語一覧
    this.i18n = new I18n(i18nContext)
    this.analyticsContext = analyticsContext
    this.details = analyticsContext.details
    this.languageRecords = analyticsContext.language_records
    this.redirectUri = analyticsContext.redirect_uri
    this.selectedLanguage = Object.keys(this.languageRecords)[0]
    // フィードバックがオブジェクトで来るので、配列に変換
    // 配列の最初がtotal, それ以降は順不同で言語別のアイテムを格納
    let feedback = [{language: 'total', items: analyticsContext.feedback.total}]
    Object.keys(analyticsContext.feedback)
      .filter(key => key != 'total')
      .map(key => {
        feedback.push({
          language: key,
          items: analyticsContext.feedback[key]
        })
      })
    this.feedback = feedback
    const allLanguages = Object.keys(this.details['total'])
    const languages = allLanguages.filter(lang => lang !== 'total')
    this.chartsData = {
      allDays: analyticsContext.allDays,
      allLanguages: allLanguages,
      dailyChartData: this.getDailyChartData(allLanguages, this.details),
      sortedByLanguage: {
        languages: languages,
        sessions: this.getValueArray('session_total', languages, this.details),
        queries: this.getValueArray('query_total', languages, this.details)
      },
      platformsTotal: this.details.total.total.platform_data
    }
    this.all_month = analyticsContext.all_month
    const platformResource = new PlatformDataResource()
    this.all_platforms = platformResource.AnalyticsPlatformColumns.filter(x => analyticsContext.all_platforms[x.key]===x.key)
    this.active_tab = parseInt(analyticsContext.active_tab)
    this.selected_month = analyticsContext.selected_month
  }

  ready() {
  }

  changeLanguage(lang) {
    this.selectedLanguage = lang
  }

  getDailyChartData(allLanguages, resources) {
    const result = {}
    allLanguages.forEach(language => {
      result[language] = {}
      result[language].queries = this.getDailyValueArray('query_total', language, resources)
      result[language].sessions = this.getDailyValueArray('session_total', language, resources)
      result[language].unanswered = this.getDailyValueArray('unanswered_rate', language, resources)
    })
    return result
  }

  getDailyValueArray(fetchKey, language, resources) {
    return Object.keys(resources.monthly).map(date => resources.monthly[date][language][fetchKey])
  }

  getValueArray(fetchKey, languages, resources) {
    return Object.keys(resources.total).filter(lang => languages.indexOf(lang) > -1).map(
      lang => resources.total[lang][fetchKey]
    )
  }

  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }
}

export {
  AnalyticsController
}
