import Vue from "vue";
import {UrlBuilder} from "./util/url-builder";
import {API_URL} from "./resource/urls";
import ScenarioIndexController from './controller/scenario-index-controller'

import Synchronize from './components/Synchronize.vue'
import ScenarioIndex from "./components/ScenarioIndex.vue"
import CreateScenarioModal from "./components/CreateScenarioModal.vue"
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import SuccessModal from "./components/SuccessModal.vue"
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue"
import ModalFooter from "./components/ModalFooter.vue"
import AddButton from "./components/buttons/AddButton.vue"
import DeleteButton from "./components/buttons/DeleteButton.vue"
import LanguageChangeChatWindow from "./components/LanguageChangeChatWindow.vue"
import ErrorModalFooter from './components/ErrorModalFooter.vue'
import { BasicSelect } from 'vue-search-select'


export const appScenarioIndex = (i18n) => {
  /* コントローラ */
  const controller = new ScenarioIndexController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.userType,
    window.i18nContext,
    window.requestContext.isStaff,
    window.requestContext.agentOptions,
    window.requestContext.auto_input_predict,
    window.requestContext.test_chat_platform,
  )

  Vue.component('scenario-index', ScenarioIndex)
  Vue.component('synchronize', Synchronize)
  Vue.component('create-scenario-modal', CreateScenarioModal)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('success-modal', SuccessModal)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('add-button', AddButton)
  Vue.component('delete-button', DeleteButton)
  Vue.component('language-change-chat-window', LanguageChangeChatWindow)
  Vue.component('error-modal-footer', ErrorModalFooter)
  Vue.component('basic-select', BasicSelect)
  Vue.directive('focus', {
    inserted: function (el) {
      // 要素にフォーカスを当てる
      el.focus()
    }
  })

  new Vue({
    el: '#app-scenario-index',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
