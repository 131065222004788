<template>
  <div>
    <div class="row mt-1 justify-content-between">
      <!-- 転送ボタン。viewerには非表示 -->
      <div class="ml-3">
        <button
          class="btn btn-primary"
          @click="showTransportModal"
          v-if="controller.userType !== 'viewer'"
        >
          <i class="fas fa-robot"></i>
          {{ $t("faq.transport") }}
        </button>
      </div>

      <!-- 言語選択 -->
      <div class="col-md-1">
        <b-dropdown right :text="controller.selectedLanguageLabel">
          <b-dropdown-item
            v-for="language in controller.supportedLanguages"
            :key="language.value"
            :href="`/faq/${language.value}/content`"
          >
            {{ language.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!-- カテゴリ、インテント、QAを横並びにする -->
    <div class="row align-items-start">
      <!-- カテゴリセクション -->
      <faq-category
        :categories="controller.categories"
        :selected-language="controller.selectedLanguage"
        :selected-language-label="controller.selectedLanguageLabel"
        :selected-category="controller.selectedCategory"
        :multi-selected-category-ids="controller.multiSelectedCategoryIds"
        @on-select-category="onSelectCategory"
        @on-multi-select-category="onMultiSelectCategory"
        @on-cancel-multi-select="cancelMultiSelect"
        @create-category="createCategory"
        @update-category="updateCategory"
        @delete-category="deleteCategory"
        @update-multi-category="updateMultiCategory"
        @delete-multi-category="deleteMultiCategory"
      />

      <!-- インテントセクション -->
      <faq-intent
        :intents="controller.displayedIntents"
        :selected-language="controller.selectedLanguage"
        :selected-intent-id="controller.selectedIntentId"
        :multi-selected-intent-ids="controller.multiSelectedIntentIds"
        :categories="controller.categories"
        @on-select-intent="onSelectIntent"
        @on-multi-select-intent="onMultiSelectIntent"
        @on-cancel-multi-select="cancelMultiSelect"
        @update-intent="updateIntent"
        @update-multi-intent="updateMultiIntent"
      />

      <!-- QAセクション -->
      <faq-qa
        :qa="controller.qa"
        :category="controller.getDirectParentCategory()"
        :intent="
          controller.intents.find(
            (intent) => intent.id === controller.selectedIntentId
          )
        "
        :selected-language="controller.selectedLanguage"
        :categories="controller.categories"
        @update-qa="updateQA"
      />
    </div>

    <!-- Modals -->
    <b-modal
      :title="modalTitle"
      ref="transportModal"
      header-class="bg-info text-light"
    >
      <span>{{ $t("faq.transportMessage") }}</span>

      <modal-footer
        slot="modal-footer"
        @ok="startTransport"
        @cancel="$refs.transportModal.hide()"
      ></modal-footer>
    </b-modal>
    <error-modal ref="errorModal" :message="message" />

    <progress-modal ref="progressModal" :message="message" />

    <success-modal
      ref="transportCompletedModal"
      :title="$t('faq.result')"
      :message="$t('faq.completed')"
      @ok="reload()"
    ></success-modal>
  </div>
</template>

<script>
import { I18n } from "../util/i18n";

export default {
  props: ["controller"],
  data() {
    const i18n = new I18n(window.i18nContext);
    return {
      message: "",
      modalTitle: "",
    };
  },
  methods: {
    showError(message) {
      this.message = message;
      this.$refs.errorModal.show();
    },
    showProgress(message) {
      this.message = message;
      this.$refs.progressModal.show();
    },
    hideProgress() {
      this.$refs.progressModal.hide();
    },
    reload() {
      window.location.reload(true);
    },
    /**
     * 転送処理を行う
     */
    showTransportModal() {
      this.modalTitle = this.$t("faq.transport");
      this.$refs.transportModal.show();
    },
    async startTransport() {
      this.$refs.transportModal.hide();
      this.showProgress(this.$t("faq.transport") + "...");
      try {
        const result = await this.controller.transportFAQ();
        if (result) {
          this.hideProgress();
          this.$refs.transportCompletedModal.show();
        }
      } catch (error) {
        this.showError(error.response.data);
        this.hideProgress();
      }
    },
    /**
     * カテゴリ選択時処理
     */
    onSelectCategory(categoryId) {
      this.controller.onSelectCategory(categoryId);
    },
    /**
     * カテゴリ複数選択時処理
     */
    onMultiSelectCategory(categoryId) {
      this.controller.onMultiSelectCategory(categoryId);
    },
    /**
     * インテント選択時処理
     */
    onSelectIntent(intentId) {
      this.controller.onSelectIntent(intentId);
    },
    /**
     * インテント複数選択時処理
     */
    onMultiSelectIntent(intentId) {
      this.controller.onMultiSelectIntent(intentId);
    },
    /**
     * カテゴリ追加処理
     * 完了後コールバックでモーダルを閉じる
     */
    async createCategory({ category, closeModal }) {
      try {
        await this.controller.createCategory(category);
      } catch (error) {
        this.showError(error.response.data);
      }

      closeModal();
    },
    /**
     * カテゴリ更新処理
     * 完了後コールバックでモーダルを閉じる
     */
    async updateCategory({ category, closeModal }) {
      try {
        await this.controller.updateCategory(category);
      } catch (error) {
        this.showError(error.response.data);
      }

      closeModal();
    },
    /**
     * カテゴリ削除処理
     */
    async deleteCategory(categoryIds) {
      try {
        await this.controller.deleteCategory(categoryIds);
      } catch (error) {
        this.showError(error.response.data);
      }
    },
    /**
     * カテゴリ複数更新処理
     */
    async updateMultiCategory(param) {
      try {
        await this.controller.updateMultiCategory(param);
      } catch (error) {
        this.showError(error.response.data);
      }
    },
    /**
     * カテゴリ複数削除処理
     */
    async deleteMultiCategory(param) {
      try {
        this.controller.deleteMultiCategory(param);
      } catch (error) {
        this.showError(error.response.data);
      }
    },
    /**
     * 複数選択解除処理
     */
    cancelMultiSelect() {
      this.controller.cancelMultiSelect();
    },
    /**
     * インテント更新処理
     */
    async updateIntent({ intent, closeModal }) {
      try {
        await this.controller.updateIntent(intent);
      } catch (error) {
        this.showError(error.response.data);
      }
      closeModal();
    },
    /**
     * インテント一括更新処理
     */
    async updateMultiIntent(param) {
      try {
        await this.controller.updateMultiIntent(param);
      } catch (error) {
        this.showError(error.response.data);
      }
    },
    async updateQA(qa) {
      try {
        await this.controller.updateQA(qa);
      } catch (error) {
        this.showError(error.response.data);
      }
    },
  },
};
</script>

<style scoped>
.selected-language {
  border: 3px solid #b9bbbe;
  color: #fff;
}
.table td {
  min-width: 120px;
}
</style>
