<template>
  <div class="answer-alexaList">
    <div>{{ $t('answer.content') }}({{ $t('answer.typeText') }}):</div>
    <b-form-textarea
      class="mb-1"
      v-model="text_speech"
      :placeholder="$t('answer.alexaCardOutputSpeech')"
      rows="2"
      max-rows="3"
      @input="validationCheck"
      :state="!$v.text_speech.$error"
    ></b-form-textarea>
    <b-form-invalid-feedback v-if="$v.text_speech.$error">
      {{ $t('webhook.required') }}
    </b-form-invalid-feedback>
    <div>
      <div class="input-group">
        <b-form-group>
          <b-form-radio-group
            class="mt-1"
            v-model="list_type"
            :options="typeList"
            ></b-form-radio-group>
        </b-form-group>
      <div class="input-group-append mt-2">
        <span v-b-tooltip.hover.html.up="$t('answer.alexaListTemplateInfo')">
          <i class="fas fa-info-circle"></i></span></div>
      </div>
      <b-form-input class="mb-1" :placeholder="$t('answer.enterListTitle')" v-model="list_title"></b-form-input>
      <b-form-input class="mb-1" :placeholder="$t('answer.alexaCardBackgroundDescription')" v-model="background_description"></b-form-input>
      <div class="input-group mb-1">
        <b-form-input :placeholder="$t('answer.alexaCardBackgroundURL')" v-model="background_imageUrl" type="url"
                      @input="validationCheck" :state="!$v.background_imageUrl.$error" ref="backgroundImageUrl"></b-form-input>
        <div class="input-group-append">
          <a class="btn btn-info" target="_blank"
             :class="{ disabled: !background_imageUrl || $v.background_imageUrl.$error }"
             :href="background_imageUrl"
             :title="$t('answer.preView')">
            <span class="fas fa-eye mt-1"/>
          </a>
          <a class="btn btn-primary text-white" v-b-tooltip.hover
             :title="$t('answer.browseImage')"
             @click="loadBackgroundImageUrlFiles">
            <i class="far fa-images"></i>
          </a>
        </div>
        <b-form-invalid-feedback v-if="$v.background_imageUrl.$error">
          {{ $t('answer.invalidURL') }}
        </b-form-invalid-feedback>
      </div>
      <file-contents ref="backgroundImageUrlFiles"
                     :img-url-el="$refs.backgroundImageUrl"
                     @img-url-model="background_imageUrl = $event"></file-contents>
      <div class="scroll">
        <b-card class="mb-1">
          <b-tabs v-model="indexTab">
            <b-tab title="item" v-for="(tab,index) in alexaListTab" :key="index">
              <b-form-input class="mt-2 mb-1" :placeholder="$t('answer.alexaCardBackgroundDescription')" v-model="tab.image_description"></b-form-input>
              <div class="input-group mb-1">
                <b-form-input :placeholder="$t('answer.alexaCardBackgroundURL')"
                              v-model="tab.image_url" type="url" ref="tabImageUrl"
                              @input="validationCheck" :state="!$v.alexaListTab.$each.$iter[index].image_url.$error"></b-form-input>
                <div class="input-group-append">
                  <a class="btn btn-info" target="_blank"
                     :class="{ disabled: !tab.image_url || $v.alexaListTab.$each.$iter[index].image_url.$error }"
                     :href="tab.image_url"
                     :title="$t('answer.preView')">
                    <span class="fas fa-eye mt-1"/>
                  </a>
                  <a class="btn btn-primary text-white" v-b-tooltip.hover
                     :title="$t('answer.browseImage')"
                     @click="loadTabImageUrlFiles(index)">
                    <i class="far fa-images"></i>
                  </a>
                </div>
                <b-form-invalid-feedback v-if="$v.alexaListTab.$each.$iter[index].image_url.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
              </div>
              <file-contents ref="tabImageUrlFiles"
                             :img-url-el="$refs.tabImageUrl"
                             @img-url-model="tab.image_url = $event"></file-contents>
              <div v-for="(content, indexContent) in tab.replies">
                <div class="card-button-title mb-1 mr-1" style="width: 60%">
                  <b-form-input :placeholder="$t('answer.alexaCardTextContent')"
                                v-model="content.text"
                                @input="validationCheck" :state="!$v.alexaListTab.$each.$iter[index].replies.$each.$iter[indexContent].text.$error"></b-form-input>
                  <b-form-invalid-feedback v-if="$v.alexaListTab.$each.$iter[index].replies.$each.$iter[indexContent].text.$error">
                    {{ $t('webhook.required') }}
                  </b-form-invalid-feedback>
                </div>
                <div>
                  <button
                    class="btn btn-danger mb-1"
                    :disabled="tab.replies.length === 1"
                    @click.prevent="deleteContentText(indexContent)"
                    style="width: 45px; height: 37px;">
                    <i class="fa fa-minus"></i>
                  </button>
                  <button
                    v-if="indexContent === 0"
                    class="btn btn-success mb-1"
                    @click.prevent="addContentText(index)"
                    style="width: 45px; height: 37px;">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>

            </b-tab>
            <b-nav-item slot="tabs" @click="newTab(indexTab)" href="#">
            +
            </b-nav-item>

          </b-tabs>
        </b-card>
      </div>
    </div>
    <div>
      <b-btn size="sm" variant="danger" class="float-right mb-1" @click="closeTab" :disabled="alexaListTab.length == 1">
                Close tab
      </b-btn>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import { required, url } from 'vuelidate/lib/validators'
import {AlexaListData,AlexaListTab,ReplyText} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  data() {
    return {
      answerAlexaList: '',
      text_speech: '',
      list_type:'ListTemplate1',
      list_title:'',
      background_description: '',
      background_imageUrl: '',
      alexaListTab:[new AlexaListTab('','',[new ReplyText()])],
      typeList:[
        { value: 'ListTemplate1', text: 'List1(縦並び)'},
        { value: 'ListTemplate2', text: 'List2(横並び)'}
      ],
      indexTab: 0
    }
  },
  validations: {
    text_speech:{
      required
    },
    background_imageUrl:{
      url
    },
    alexaListTab:{
      $each: {
        image_url:{
          url
        },
        replies:{
          $each:{
            text:{
              required
            }
          }
        }
      }
    }
  },
  methods: {
    addContentText(index){
      if(this.alexaListTab[index].replies.length < 3){
        this.alexaListTab[index].replies.push(new ReplyText())
        this.validationCheck()
      }
    },
    deleteContentText(indexContent){
      this.alexaListTab[this.indexTab].replies.splice(indexContent, 1)
      this.validationCheck()
    },
    closeTab () {
      this.alexaListTab.splice(this.indexTab, 1)
      this.validationCheck()
    },
    newTab (indexTab) {
      if(indexTab < 9){
        let tab = new AlexaListTab('','',[new ReplyText()])
        this.alexaListTab.push(tab)
        this.validationCheck()
        this.$nextTick(() => {
          this.indexTab = this.alexaListTab.length - 1
        })
      }
    },
    setValue(selectedResponseMessage){
      this.text_speech = selectedResponseMessage.data.text_speech
      this.list_type = selectedResponseMessage.data.list_type
      this.list_title = selectedResponseMessage.data.list_title
      this.background_description = selectedResponseMessage.data.background_description
      this.background_imageUrl = selectedResponseMessage.data.background_imageUrl
      this.alexaListTab = selectedResponseMessage.data.list_tab
    },
    inputValue(){
      this.answerAlexaList = new AlexaListData(
        this.text_speech,
        this.list_type,
        this.list_title,
        this.background_description,
        this.background_imageUrl,
        this.alexaListTab
      )
      for(let tab of this.alexaListTab){
        tab.image_description = tab.image_description.replace(illegalExp, "").trim()
        tab.image_url = tab.image_url.replace(illegalExp, "").trim()
        for(let content of tab.replies){
          content.text = content.text.replace(illegalExp, "").trim()
        }
      }
      return this.answerAlexaList
    },
    initializeValue(){
      this.answerAlexaList=null,
      this.text_speech = '',
      this.list_type = 'ListTemplate1',
      this.list_title = '',
      this.background_description = '',
      this.background_imageUrl = '',
      this.alexaListTab=[new AlexaListTab('','',[new ReplyText()])]
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadBackgroundImageUrlFiles() {
      this.$refs.backgroundImageUrlFiles.loadImages()
    },
    loadTabImageUrlFiles(index) {
      this.$refs.tabImageUrlFiles[index].loadImages()
    }
  }
}
</script>
