import { I18n } from "../util/i18n";
import { QA } from "../model/faq";
import { RPC } from "../util/rpc";
import csrfToken from "../util/csrf-token";
import { cloneDeep } from "lodash";

/**
 * FAQコンテンツ管理画面のコントローラ
 */
class FAQContentManagementController {
  constructor(
    apiUrl,
    i18nContext,
    supportedLanguages,
    categories,
    intents,
    selectedLanguage,
    selectedLanguageLabel
  ) {
    this.i18n = new I18n(i18nContext);

    // API呼び出しクラスのインスタンス化
    this.transportFAQApi = new RPC(apiUrl.transportFAQ);
    this.getQAApi = new RPC(apiUrl.getQA, QA);
    this.updateQAApi = new RPC(apiUrl.updateQA, QA);
    this.createQACategoryApi = new RPC(apiUrl.createQACategory);
    this.updateQACategoryApi = new RPC(apiUrl.updateQACategory);
    this.deleteQACategoryApi = new RPC(apiUrl.deleteQACategory);
    this.updateQAIntentApi = new RPC(apiUrl.updateQAIntent);

    // サポートされている言語一覧
    this.supportedLanguages = supportedLanguages;

    // 選択中の言語コード
    this.selectedLanguage = selectedLanguage;
    // 選択中の言語の翻訳済みラベル
    this.selectedLanguageLabel = selectedLanguageLabel;

    // カテゴリ一覧
    this.categories = categories;

    // カテゴリの並び替え
    this.sortCategories();

    // 選択中のカテゴリ
    this.selectedCategory = null;

    // 複数選択モードで選択中のカテゴリID
    this.multiSelectedCategoryIds = [];

    // 全インテント
    this.intents = intents;

    // 選択中のインテントID
    this.selectedIntentId = null;

    // 複数選択モードでの選択中のインテント
    this.multiSelectedIntentIds = [];

    // 選択中のカテゴリのインテント（＝表示中のインテント）
    this.displayedIntents = cloneDeep(intents);

    // QA（初期は非表示）
    this.qa = null;
  }

  ready() {}

  // 転送処理
  async transportFAQ() {
    return await this.transportFAQApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie)
    );
  }

  /**
   * カテゴリ選択時処理
   * 表示中インテント絞り込み、選択中インテントの更新
   */
  onSelectCategory(categoryId) {
    // 選択中のカテゴリを更新
    this.selectedCategory = this.categories.find(
      (category) => category.id === categoryId
    );

    // 表示中のインテントを更新
    // カテゴリがnull=すべての場合は全インテントが表示対象
    // 親カテゴリが選ばれた場合は子カテゴリのインテントをすべて表示対象とすることに注意
    this.displayedIntents = this.getDisplayedIntents();

    // 選択中のインテントを更新
    // 常に一番上のインテントを選択する
    this.selectedIntentId = this.displayedIntents[0]?.id ?? null;

    // QA取得処理を行う
    if (this.selectedIntentId) {
      this.onSelectIntent(this.selectedIntentId);
    } else {
      this.qa = null;
    }
  }

  /**
   * 複数選択モードでのカテゴリクリック時処理
   */
  onMultiSelectCategory(categoryId) {
    if (this.multiSelectedCategoryIds.includes(categoryId)) {
      // 削除
      this.multiSelectedCategoryIds = this.multiSelectedCategoryIds.filter(
        (id) => id !== categoryId
      );
    } else {
      // 追加
      this.multiSelectedCategoryIds.push(categoryId);
    }
  }

  /**
   * 複数選択モードでのインテントクリック時処理
   */
  onMultiSelectIntent(intentId) {
    if (this.multiSelectedIntentIds.includes(intentId)) {
      // 削除
      this.multiSelectedIntentIds = this.multiSelectedIntentIds.filter(
        (id) => id !== intentId
      );
    } else {
      // 追加
      this.multiSelectedIntentIds.push(intentId);
    }
  }

  /**
   * インテント選択時処理
   * 選択中インテントの更新、QAの取得
   */
  async onSelectIntent(intentId) {
    // 選択中インテントを更新
    this.selectedIntentId = intentId;

    // QAの取得API呼び出し
    try {
      const qa = await this.getQAApi.call(
        csrfToken.getCsrfTokenFromCookie(document.cookie),
        {
          intent_id: this.selectedIntentId,
          language_code: this.selectedLanguage,
        }
      );

      this.qa = qa;
    } catch (e) {
      this.qa = null;
    }
  }

  /**
   * カテゴリ作成API呼び出し
   */
  async createCategory({
    parentCategory,
    /** 共通のカテゴリ名 */
    categoryName,
    /** 選択中言語のカテゴリ名 */
    categoryNameLanguage,
    /** カテゴリ優先度 */
    priority,
    /** 有効・無効の設定 */
    active,
    /** デフォルトの設定 */
    isDefault,
  }) {
    // カテゴリ作成API呼び出し
    const category = await this.createQACategoryApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        parent: parentCategory.value,
        name: categoryName,
        display_names: {
          [this.selectedLanguage]: categoryNameLanguage,
        },
        priority,
        default: isDefault,
        active,
      }
    );

    // カテゴリ一覧の更新
    this.categories.push(category);

    // デフォルトカテゴリがTrueの場合は他のデフォルトカテゴリをFalseにする
    if (isDefault) {
      this.categories = this.categories.map((c) => {
        if (c.id !== category.id && c.default) {
          return {
            ...c,
            default: false,
          };
        }

        return c;
      });
    }

    // カテゴリの並び替え
    this.sortCategories();
  }

  /**
   * カテゴリ作成API呼び出し
   */
  async updateCategory({
    parentCategory,
    /** 共通のカテゴリ名 */
    categoryName,
    /** 選択中言語のカテゴリ名 */
    categoryNameLanguage,
    /** カテゴリ優先度 */
    priority,
    /** 有効・無効の設定 */
    active,
    /** デフォルトの設定 */
    isDefault,
  }) {
    // カテゴリ更新API呼び出し
    const categories = await this.updateQACategoryApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        categories: [
          {
            id: this.selectedCategory.id,
            parent: parentCategory.value,
            name: categoryName,
            // display_namesは対象言語以外のキーはそのままコピーすること
            display_names: {
              ...this.selectedCategory.display_names,
              [this.selectedLanguage]: categoryNameLanguage,
            },
            priority,
            default: isDefault,
            active,
          },
        ],
      }
    );

    // カテゴリ一覧の更新
    this.categories = this.categories.map((category) => {
      // 更新対象のカテゴリを更新
      if (category.id === this.selectedCategory.id) {
        return categories[0];
      }

      // デフォルトカテゴリをTrueにした場合はもともとのデフォルトカテゴリをFalseにする
      if (category.default && isDefault) {
        return {
          ...category,
          default: false,
        };
      }

      // その他のカテゴリはそのまま
      return category;
    });

    // 選択中カテゴリのデータを更新
    this.selectedCategory = categories[0];

    // カテゴリの並び替え
    this.sortCategories();
  }

  /**
   * カテゴリ削除API呼び出し
   */
  async deleteCategory(categoryIds) {
    await this.deleteQACategoryApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        ids: categoryIds,
      }
    );

    // 表示の更新
    this.categories = this.categories.filter(
      (category) => !categoryIds.includes(category.id)
    );

    const defaultCategory = this.categories.find(
      (category) => category.default
    );
    this.intents = this.intents.map((intent) => {
      if (categoryIds.includes(intent.category)) {
        intent.category = defaultCategory.id;
      }
      return intent;
    });
    this.displayedIntents = cloneDeep(this.intents);

    // カテゴリ選択を解除
    this.selectedCategory = null;

    // カテゴリの並び替え
    this.sortCategories();
  }

  /**
   * カテゴリの並び替え
   * full_nameは > 区切りで親カテゴリの構造を表しているためそのまま利用する
   */
  sortCategories() {
    this.categories = this.categories.sort((a, b) => {
      // full_nameでソート
      if (a.full_name < b.full_name) {
        return -1;
      }
      if (a.full_name > b.full_name) {
        return 1;
      }

      return 0;
    });
  }

  /**
   * 一括選択キャンセル時、選択をリセット
   */
  cancelMultiSelect() {
    this.multiSelectedCategoryIds = [];
    this.multiSelectedIntentIds = [];
  }

  /**
   * カテゴリ一括更新
   */
  async updateMultiCategory({ operation, categoryId, active }) {
    // deep copyしてから更新に利用
    const categories = cloneDeep(
      // 選択中のカテゴリのみ更新対象
      this.categories.filter((category) =>
        this.multiSelectedCategoryIds.includes(category.id)
      )
    );

    if (operation === "CHANGE_CATEGORY") {
      // カテゴリ更新処理

      // 選択された親カテゴリをセット
      categories.forEach((c) => {
        c.parent = categoryId;
      });
    } else if (operation === "CHANGE_STATE") {
      // 有効・無効の更新処理
      categories.forEach((c) => {
        c.active = active;
      });
    }

    // 一括更新API呼び出し
    const updatedCategories = await this.updateQACategoryApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        categories,
      }
    );

    // 更新後カテゴリを反映
    const newCategories = cloneDeep(this.categories).map((category) => {
      const updatedCategory = updatedCategories.find(
        (updatedCategory) => updatedCategory.id === category.id
      );

      if (updatedCategory) {
        return updatedCategory;
      }
      return category;
    });
    this.categories = newCategories;

    // カテゴリの並び替え
    this.sortCategories();
  }

  /**
   * カテゴリ一括削除処理
   */
  async deleteMultiCategory(categoryIds) {
    // 一括削除API呼び出し
    await this.deleteQACategoryApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        ids: categoryIds,
      }
    );

    // 表示の更新
    this.categories = this.categories.filter(
      (category) => !categoryIds.includes(category.id)
    );

    // 削除されたカテゴリのインテントをデフォルトカテゴリに移動するためにデフォルトカテゴリを取得
    const defaultCategory = this.categories.find(
      (category) => category.default
    );
    // 削除されたカテゴリに属するインテントをデフォルトカテゴリに移動
    this.intents = this.intents.map((intent) => {
      if (categoryIds.includes(intent.category)) {
        intent.category = defaultCategory.id;
      }

      return intent;
    });

    // カテゴリ選択がすべてになるのであわせてインテントも更新
    this.displayedIntents = cloneDeep(this.intents);

    // カテゴリ選択を解除
    this.selectedCategory = null;

    // カテゴリの並び替え
    this.sortCategories();
  }

  /**
   * インテント更新
   */
  async updateIntent({ category, intentName, active, frequently_asked }) {
    // インテント更新API呼び出し
    const intents = await this.updateQAIntentApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        intents: [
          {
            id: this.selectedIntentId,
            category: category.value,
            name: intentName,
            active,
            frequently_asked,
          },
        ],
      }
    );

    // インテント一覧の更新
    this.intents = this.intents.map((intent) => {
      if (intent.id === this.selectedIntentId) {
        return intents[0];
      }
      return intent;
    });

    // 表示中のインテント一覧の更新
    this.onSelectCategory(category.value);
  }

  /**
   * インテント一括更新
   */
  async updateMultiIntent({ operation, categoryId, active, frequently_asked }) {
    const targetIntents = cloneDeep(
      // 選択中のインテントのみ更新対象
      this.intents.filter((intent) =>
        this.multiSelectedIntentIds.includes(intent.id)
      )
    );

    if (operation === "CHANGE_CATEGORY") {
      targetIntents.forEach((intent) => {
        intent.category = categoryId;
      });
    } else if (operation === "CHANGE_STATE") {
      targetIntents.forEach((intent) => {
        intent.active = active;
      });
    } else if (operation === "CHANGE_FREQUENTLY_ASKED") {
      targetIntents.forEach((intent) => {
        intent.frequently_asked = frequently_asked;
      });
    }

    const newIntents = await this.updateQAIntentApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        intents: targetIntents,
      }
    );

    // インテント一覧の更新
    this.intents = this.intents.map((intent) => {
      const updateIntent = newIntents.find(
        (newIntent) => newIntent.id === intent.id
      );

      if (updateIntent) {
        return updateIntent;
      }

      return intent;
    });

    // 表示中のインテント一覧の更新
    this.onSelectCategory(this.selectedCategory);
  }

  /**
   * QA更新
   */
  async updateQA({ active }) {
    // QA更新API呼び出し
    const qa = await this.updateQAApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        id: this.qa.id,
        language_code: this.selectedLanguage,
        active,
      }
    );

    // QAの更新
    this.qa = qa;

    // インテント、表示用インテントの更新
    this.intents = this.intents.map((intent) => {
      if (intent.id === this.selectedIntentId) {
        intent.qa_active[this.selectedLanguage] = this.qa.active;
      }

      return intent;
    });

    this.displayedIntents = this.getDisplayedIntents();
  }

  /**
   * 現在選択中のインテントの直接の親を取得
   */
  getDirectParentCategory() {
    if (!this.selectedIntentId) {
      return null;
    }

    const directParentCategoryId = this.intents.find(
      (intent) => intent.id === this.selectedIntentId
    )?.category;

    if (!directParentCategoryId) {
      throw new Error("intent not found");
    }

    return this.categories.find(
      (category) => category.id === directParentCategoryId
    );
  }

  /**
   * 表示対象のインテント一覧を取得
   */
  getDisplayedIntents() {
    return this.selectedCategory
      ? this.intents.filter((intent) => {
          const category = this.categories.find(
            (category) => category.id === intent.category
          );

          if (!category) {
            throw new Error("category not found");
          }

          // full_nameだけでマッチングするとtest, test2などのカテゴリがあるときにうまくマッチしないため子階層以下をを絞り込んで利用する
          const categories = this.categories.filter(
            (category) =>
              category.id === this.selectedCategory.id ||
              category.full_name.startsWith(
                `${this.selectedCategory.full_name} > `
              )
          );

          return categories
            .map((c) => c.full_name)
            .includes(category.full_name);
        })
      : this.intents;
  }
}

export { FAQContentManagementController };
