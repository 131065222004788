<template>
  <div>
    <a :href="url">
      <button
        v-b-popover.hover.top="buttonText"
        class="btn btn-info">
        <i class="fas fa-long-arrow-alt-left"></i>
      </button>
    </a>
  </div>
</template>

<script>
export default {
  props: [
    'url',
    'buttonText',
  ]
}
</script>

