<template>
  <div>
    <div
      class="column-caption my-1 p-1 font-weight-bold pointer-cursor"
      @click="toggleCollapse">
      <span class="float-none pl-1">
        <span v-show="isOpen">
          <i class="fas fa-caret-down"></i>
        </span>
        <span v-show="!isOpen">
          <i class="fas fa-caret-up"></i>
        </span>
      </span>
      <span class="pl-1">{{ $t('scenario.title') }}</span>
      <b-button
        class="btn btn-success btn-xs float-right"
        @click="toggleOrder"
        @click.prevent="toggleCollapse">
        <span>{{ orderButtonText }}</span>
      </b-button>
    </div>
    <b-collapse
      id="scenario-collapse"
      v-model="isOpen"
      visible>
      <div
        v-if="userType != 'viewer'"
        class="row mb-1">
        <div class="col-auto mr-auto">
          <button
            class="btn btn-success"
            @click="addScenario">
            <i class="fa fa-plus"></i>
            {{ $t('buttons.add') }}
          </button>

          <button
            class="btn btn-success"
            @click="editScenario"
            v-bind:disabled="!selectedScenario">
            <i class="fa fa-edit"></i>
            {{ $t('buttons.edit') }}
          </button>

          <button
            class="btn btn-success"
            @click="showDeleteScenarioModal"
            :disabled="!selectedScenario">
            <i class="fa fa-minus"></i>
            {{ $t('buttons.delete') }}
          </button>
        </div>
      </div>
      <ul
        class="scroll marginleft"
        tabindex="-1"
        ref="focusOn">
        <li class="category-item">
          <div
            class="label my-1 p-1"
            :class="{selected: isAllScenarioSelected}"
            @click="selectAllScenario">
            {{ $t('category.all') }}
          </div>
        </li>
        <scenario-item
          class="category-item"
          v-for="(scenario, index) in scenarios"
          :key="index"
          :scenario="scenario"
          :selectedScenario="selectedScenario"
          :currentDisplayedScenario="currentDisplayedScenario"
          :userType="userType"
          @select-scenario="selectScenario"
          @edit-scenario="editScenario"
          @delete-scenario="showDeleteScenarioModal">
        </scenario-item>
      </ul>
    </b-collapse>

    <b-modal
      :title="modalTitle"
      ref="scenarioModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
      @shown="focusModal"
      @ok="editOk"
      :ok-disabled="!isValidModalScenarioName"
      @cancel="editCancel">
      <div>{{ $t('scenario.name') }}</div>
      <form @submit.prevent="editOk">
        <b-form-input
          type="text"
          ref="scenarioNameInput"
          v-model="modalScenarioName"
          :maxlength="maxScenarioNameLength"
          :state="modalScenarioName ? isValidModalScenarioName : null"/>
      </form>
    </b-modal>

    <b-modal
      :title="$t('scenario.delete')"
      ref="deleteScenarioModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
      @keydown.native.enter="deleteScenarioOk"
      @ok="deleteScenarioOk"
      @cancel="deleteCancel">
      <div>
        {{ $t('scenario.deleteConfirmMessage', { scenario: this.selectedScenario && this.selectedScenario.name }) }}
      </div>
    </b-modal>
  </div>
</template>


<script>
export default {
  props: [
    'i18n',
    'scenarios',
    'contexts',
    'isOrderDesc',
    'userType',
    'selectedScenario',
    'isAllScenarioSelected',
    'currentDisplayedScenario',
    'scenarioModel'
  ],
  data() {
    return {
      isOpen: true,
      orderButtonTextOptions: [this.i18n.t('category.desc'), this.i18n.t('category.asc')],
      orderButtonText: this.i18n.t('category.desc'),
      modalTitle: '',
      modalScenario: null,
      modalScenarioName: '',
      maxScenarioNameLength: 250,
      errorMessage:null
    }
  },
  computed: {
    isValidModalScenarioName() {
      return this.isValidScenarioName(this.modalScenarioName)
    }
  },
  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen
    },
    toggleOrder(){
      let index = this.isOrderDesc ? 1 : 0
      this.orderButtonText = this.orderButtonTextOptions[index]
      let orderOption = this.isOrderDesc ? '-name' : 'name'
      this.$emit('toggle-scenario-order', orderOption)
    },
    selectScenario(scenario) {
      this.$emit('select-scenario', scenario)
    },
    selectAllScenario() {
      this.$emit('select-scenario', null)
    },
    addScenario() {
      this.isModalShown = true
      this.$emit('toggel-modal-shown', this.isModalShown)
      this.modalTitle = this.i18n.t('scenario.add')
      this.modalScenario = new this.scenarioModel()
      this.modalScenarioName = ''
      this.$refs.scenarioModal.show()
    },
    editScenario(scenario) {
      this.isModalShown = true
      this.$emit('toggle-modal-shown', this.isModalShown)
      this.modalTitle = this.i18n.t('scenario.edit')
      this.modalScenario = this.selectedScenario
      this.modalScenarioName = this.modalScenario.name
      this.$refs.scenarioModal.show()
    },
    editOk() {
      this.modalScenarioName = this.modalScenarioName.trim()
      if (!this.isValidScenarioName(this.modalScenarioName)) {
        return
      }
      if (!this.isValidScenario({'id':this.modalScenario.id || null, 'name':this.modalScenarioName})) {
        return
      }
      this.modalScenario.name = this.modalScenarioName


      this.isModalShown = false
      this.$emit('toggle-modal-shown', this.isModalShown)
      this.$emit('save-scenario', this.modalScenario)
    },
    isValidScenario(scenario) {
      if(this.contexts.some(ct=>ct.name===scenario.name&&ct.scenario.name!==scenario.name&&ct.scenario.id===scenario.id)){
        this.errorMessage = this.i18n.t('scenario.conflictContext')
        this.$emit('show-error', this.errorMessage)
        return false
      }
      else if(this.scenarios.some(sn=>sn.name===scenario.name&&sn.id!==scenario.id)) {
        this.errorMessage = this.i18n.t('scenario.duplicateScenario')
        this.$emit('show-error', this.errorMessage)
        return false
      }
      return true
    },
    editCancel(){
      this.isModalShown = false
      this.$emit('toggel-modal-shown',this.isModalShown)
      this.$refs.scenarioModal.hide()
    },
    showDeleteScenarioModal() {
      if (this.selectedScenario == null) {
        // すべてを選択時
        return
      }
      this.isModalShown = true
      this.$emit('toggle-modal-shown', this.isModalShown)
      this.$refs.deleteScenarioModal.show()
    },
    deleteScenarioOk() {
      this.modalScenario = this.selectedScenario
      this.$emit('delete-scenario', this.modalScenario)
    },
    deleteCancel() {
      this.isModalShown = false
      this.$emit('toggle-modal-shown', this.isModalShown)
      this.$refs.deleteScenarioModal.hide()
    },
    isValidScenarioName(value) {
      return value.trim().match(/^[a-zA-Z0-9-_]+$/) != null
    },
    focusModal() {
      this.$refs.scenarioNameInput.focus()
    },
  }
}
</script>

