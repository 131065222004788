<template>
  <div>
    <form>
      <b-form-group class="mb-1">
        <b-form-checkbox
          v-for="option in searchOptions"
          v-model="selectedSearchOptions"
          :key="option.value"
          :value="option.value"
          name="search-intent"
          inline>
          {{ option.text }}
        </b-form-checkbox>
      </b-form-group>
      <button type="button" class="btn btn-sm btn-primary mb-2"
              @click="submitSearchOptions">
        <i class="fas fa-search"></i> {{ $t('history.search') }}
      </button>
    </form>
    <div class="text-center" v-if="searchStatus && listIntents.length === 0">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="card" v-if="listIntents.length > 0">
      <div class="card-header">
        {{ $t('intent.searchResult') }}:
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="listIntent in listIntents">
            <a v-if="listIntent.type === 'intent'"
               v-bind:href="goToIntentDetail(listIntent.path)" target="_blank">
              {{listIntent.name}}
            </a>
            <a v-if="listIntent.type === 'scenario'"
               v-bind:href="goToScenarioEditor(listIntent.path)" target="_blank">
              {{listIntent.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'IntentSearch',
    props:['controller'],
    data() {
      return {
        selectedSearchOptions: [],
        searchOptions: [
          { text: this.$t('intent.feedback'), value: 'feedback_active' },
          { text: this.$t('intent.feedbackResponse'), value: 'feedback_response' },
          { text: this.$t('intent.webhook'), value: 'webhook' },
          { text: this.$t('intent.events'), value: 'event' },
          { text: this.$t('intent.welcome'), value: 'welcome' },
          { text: this.$t('intent.fallback'), value: 'fallback' },
          { text: this.$t('scenario.title'), value: 'scenario' }
        ],
        listIntents: [],
        searchStatus: false
      }
    },
    methods: {
      submitSearchOptions(event) {
        event.preventDefault()
        this.listIntents = []
        this.searchStatus = true
        const searchBy = this.selectedSearchOptions.join(",")
        this.controller.searchIntent(searchBy).then(response => {
          this.searchStatus = false
          this.listIntents = response
        }).catch(error => {
          this.searchStatus = false
          console.log(error)
        })
      },
      goToIntentDetail(intentName){
        let url = "#"
        if(intentName) {
          const currentUrl = location.href
          const regex = RegExp('agent.+')
          const languageCode = this.controller.getLanguageCode()
          const destination = `agent/${languageCode}/intent?intent_name=${intentName}`
          url = currentUrl.match(regex) ? currentUrl.replace(regex, destination) : destination
        }
        return url
      },
      goToScenarioEditor(scenarioId) {
        let url = "#"
        if(scenarioId) {
          const currentUrl = location.href
          const regex = RegExp('agent.+')
          const languageCode = this.controller.getLanguageCode()
          const destination = `agent/scenario-editor/${scenarioId}/${languageCode}`
          url = currentUrl.match(regex) ? currentUrl.replace(regex, destination) : destination
        }
        return url
      }
    }
  };
</script>

<style scoped>

</style>
