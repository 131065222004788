<template>
<div class="mode-change-intent">
  <a :href="supportedLanguages[language].intentUrl">
    <button
      :title="$t('keyword.goToIntentPage')"
      class="btn btn-success">
      <i class="fas fa-exchange-alt"></i>
      {{ $t('modeChange.questionAndAnswer') }}
    </button>
  </a>
</div>
</template>

<script>
export default {
  props: ['supportedLanguages', 'language']
}
</script>
