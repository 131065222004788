<template>
  <span :class="className">
    {{ content }}
    <span @click="removeMe">
      <i class="fas fa-times pointer-cursor" ></i>
    </span>
  </span>
</template>

<script>
  export default {
      props: ['colorClass', 'content'],
      computed: {
          className() {
              return `mr-2 px-2 py-1 badge ${this.colorClass ? `badge-${this.colorClass}` : 'badge-secondary'}`
          }
      },
      methods: {
          removeMe() {
              this.$emit('remove-me')
          }
      }
  }
</script>

<style scoped>

</style>
