<template>
    <div class="answer-video">
      <div>
          {{ $t('answer.content') }}(Video):
      </div>
      <div class="input-group mb-2">
        <b-form-input
          placeholder="Enter Video URL"
          v-model="responseMessageVideoUrl"
          type="url"
          @input="validationCheck"
          :state="!$v.responseMessageVideoUrl.$error"
          maxlength="1000"
        >
        </b-form-input>
        <div class="input-group-append">
          <a
            class="btn btn-info"
            :class="{ disabled: $v.responseMessageVideoUrl.$error }"
            :href="responseMessageVideoUrl" target="_blank"
            :title="$t('answer.preView')"
          ><span class="fas fa-eye mt-1"/>
          </a>
        </div>
        <b-form-invalid-feedback v-if="$v.responseMessageVideoUrl.$error">
          {{ $t('webhook.required') }} &
          {{ $t('answer.invalidURL') }}
        </b-form-invalid-feedback>
      </div>
      <div class="input-group mb-2">
        <b-form-input
          placeholder="Enter Preview Image URL"
          v-model="responseMessageVideoPreviewImageUrl"
          type="url"
          @input="validationCheck"
          :state="!$v.responseMessageVideoPreviewImageUrl.$error"
          maxlength="1000"
          ref="responseMessageVideoPreviewImageUrl"
        >
        </b-form-input>
        <div class="input-group-append">
          <a
            class="btn btn-info"
            :class="{ disabled: !responseMessageVideoPreviewImageUrl || $v.responseMessageVideoPreviewImageUrl.$error }"
            :href="responseMessageVideoPreviewImageUrl" target="_blank"
            :title="$t('answer.preView')"
          ><span class="fas fa-eye mt-1"/>
          </a>
          <a class="btn btn-primary text-white" v-b-tooltip.hover
             :title="$t('answer.browseImage')"
             @click="loadVideoPreviewImageUrlFiles">
            <i class="far fa-images"></i>
          </a>
        </div>
        <b-form-invalid-feedback v-if="$v.responseMessageVideoPreviewImageUrl.$error">
          {{ $t('answer.invalidURL') }}
        </b-form-invalid-feedback>
      </div>
      <file-contents ref="videoPreviewImageUrlFiles"
                     :img-url-el="$refs.responseMessageVideoPreviewImageUrl"
                     @img-url-model="responseMessageVideoPreviewImageUrl = $event"></file-contents>
    </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, requiredIf, url} from 'vuelidate/lib/validators'
import {VideoData} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  data() {
    return {
      answerVideo:null,
      responseMessageVideoUrl:'',
      responseMessageVideoPreviewImageUrl:'',
      isLineSelected: false
    }
  },
  validations() {
    return {
      responseMessageVideoUrl: {
        required,
        url
      },
      responseMessageVideoPreviewImageUrl: {
        required: requiredIf(()=>this.isLineSelected && !this.isYoutubeUrl()),
        url
      }
    }
  },
  methods:{
    isYoutubeUrl(){
      const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)+/g
      return pattern.test(this.responseMessageVideoUrl)
    },
    inputValue(){
      this.answerImage = new VideoData(
        this.responseMessageVideoUrl.replace(illegalExp, "").trim(),
        this.responseMessageVideoPreviewImageUrl.replace(illegalExp, "").trim())
      return this.answerImage
    },
    setValue(selectedResponseMessage){
      this.responseMessageVideoUrl = selectedResponseMessage.data.videoUrl
      this.responseMessageVideoPreviewImageUrl = selectedResponseMessage.data.imagePreviewUrl
    },
    initializeValue(){
      this.answerImage=null,
      this.responseMessageVideoUrl='',
      this.responseMessageVideoPreviewImageUrl=''
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadVideoPreviewImageUrlFiles() {
      this.$refs.videoPreviewImageUrlFiles.loadImages()
    }
  }
}
</script>

