<template>
  <div class="answer-cardWechat">
    <div>{{ $t('answer.cardButtons') }}</div>
        <draggable v-model="responseMessageWechatButtonText">
          <div v-for="(reply, index) in responseMessageWechatButtonText">
            <div class="mb-2">
              <div
                class="input-group-vertical"
                @keypress.enter.prevent="addWechatButton">
                <b-form-input v-model="reply.title" class="mb-1"
                              :placeholder="$t('answer.enterCardTitle')"
                              @input="validationCheck"
                              :state="!$v.responseMessageWechatButtonText.$each[index].title.$error"/>
                <b-form-invalid-feedback v-if="$v.responseMessageWechatButtonText.$each[index].title.$error">
                  {{ $t('webhook.required') }}
                </b-form-invalid-feedback>
                <b-form-input v-model="reply.description" :placeholder="$t('answer.enterCardSubtitle')" class="mb-1"/>
                <b-form-input v-model="reply.url" class="mb-1"
                              :placeholder="$t('answer.enterURL')"
                              @input="validationCheck"
                              :state="!$v.responseMessageWechatButtonText.$each[index].url.$error"/>
                <b-form-invalid-feedback v-if="$v.responseMessageWechatButtonText.$each[index].url.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
                <div class="input-group mb-1">
                  <b-form-input v-model="reply.picurl"
                                :placeholder="$t('answer.enterImageURL')"
                                @input="validationCheck"
                                :state="!$v.responseMessageWechatButtonText.$each[index].picurl.$error"
                                ref="replyPicUrl"/>
                  <div class="input-group-append">
                    <a
                      class="btn btn-info"
                      :class="{ disabled: !reply.picurl || $v.responseMessageWechatButtonText.$each[index].picurl.$error }"
                      :href="reply.picurl" target="_blank"
                      :title="$t('answer.preView')"
                    ><span class="fas fa-eye mt-1"/>
                    </a>
                    <a class="btn btn-primary text-white" v-b-tooltip.hover
                       :title="$t('answer.browseImage')"
                       @click="loadReplyPicUrlFiles(index)">
                      <i class="far fa-images"></i>
                    </a>
                  </div>
                  <b-form-invalid-feedback v-if="$v.responseMessageWechatButtonText.$each[index].picurl.$error">
                    {{ $t('answer.invalidURL') }}
                  </b-form-invalid-feedback>
                </div>
                <file-contents ref="replyPicUrlFiles"
                               :img-url-el="$refs.replyPicUrl"
                               @img-url-model="reply.picurl = $event"></file-contents>
              </div>
            </div>
          </div>
        </draggable>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, url} from 'vuelidate/lib/validators'
import {CardWechatData,WechatButtonText} from '../../model/intent'
import draggable from 'vuedraggable'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    draggable, FileContents
  },
  data() {
    return {
      answerCardWechat:null,
      responseMessageWechatButtonText:[new WechatButtonText()]
    }
  },
  validations() {
    return {
      responseMessageWechatButtonText: {
        $each: {
          title: {
            required
          },
          url: {
            url
          },
          picurl: {
            url
          }
        }
      }
    }
  },
  methods:{
    inputValue(){
      this.answerCardWechat = new CardWechatData(this.responseMessageWechatButtonText)
      for(let cardWechat of this.answerCardWechat.cardbuttons){
        cardWechat.description = cardWechat.description.replace(illegalExp, "").trim()
        cardWechat.title = cardWechat.title.replace(illegalExp, "").trim()
        cardWechat.picurl = cardWechat.picurl.replace(illegalExp, "").trim()
        cardWechat.url = cardWechat.url.replace(illegalExp, "").trim()
      }
      return this.answerCardWechat
    },
    setValue(selectedResponseMessage){
      this.responseMessageWechatButtonText = selectedResponseMessage.data.cardbuttons
    },
    initializeValue(){
      this.answerCardWechat=null,
      this.responseMessageWechatButtonText=[new WechatButtonText()]
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadReplyPicUrlFiles(index) {
      this.$refs.replyPicUrlFiles[index].loadImages()
    }
  }
}
</script>

