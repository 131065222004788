<template>
  <div id="intent-context" class="intent-context col-md-5">
    <div class="column-caption my-1 p-1 font-weight-bold">
      <span>{{ $t('intentDetail.title') }}</span>
    </div>
    <intent-context-detail
      id="input-context"
      :title="$t('scenario.inputIntent')"
      :intents="inputIntents"/>
    <intent-context-detail
      id="output-context"
      :title="$t('scenario.outputIntent')"
      :intents="outputIntents"/>
  </div>
</template>


<script>
export default {
  props: [
    'inputIntents',
    'outputIntents',
  ],
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
