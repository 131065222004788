/*
 * ファイルからインポート画面
 */
import Vue from 'vue'
import { UrlBuilder } from './util/url-builder'
import { API_URL } from './resource/urls'
import { ImportFromFileController } from './controller/import-from-file'

import ErrorModal from './components/ErrorModal.vue'
import ProgressModal from './components/ProgressModal.vue'
import ImportFromFile from './components/ImportFromFile.vue'
import ErrorModalFooter from './components/ErrorModalFooter.vue'


export const appImportFromFile = (i18n) => {
  /* コントローラ */
  const controller = new ImportFromFileController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.language,
    window.requestContext.supportedLanguages,
    window.i18nContext,
    window.requestContext.agentName
  )
  
  /* コンポーネント登録 */
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('import-from-file', ImportFromFile)
  Vue.component('error-modal-footer', ErrorModalFooter)

  new Vue({
    el: '#app-import-from-file',
    components: {
    },
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
