<template>
  <!-- カード形式の回答表示用コンポネント -->
  <div class="mb-1">
    <div class="message-bg-grey">
      <div class="card-header">
        <div>
          {{ chatItem.title }}

          <!-- 翻訳表示領域 -->
          <div v-if="translationObject" class="p-1 text-success text-size-sm-right">
            {{ translationObject[chatItem.title] ?? "" }}
          </div>
        </div>

        <div class="card-subtitle">
          {{ chatItem.subtitle }}

          <!-- 翻訳表示領域 -->
          <div v-if="translationObject" class="p-1 text-success text-size-sm-right">
            {{ translationObject[chatItem.subtitle] ?? "" }}
          </div>
        </div>
      </div>

      <b-img
        v-if="chatItem.imageUrl"
        thumbnail
        fluid
        :src="chatItem.imageUrl"
        alt="Image"
      />

      <div class="card-button-container">
        <div v-for="(button, idx) in chatItem.buttons" :key="idx" class="ml-2">
          <div class="d-flex align-items-center">
            <div>
              {{ button.text }}

              <!-- 翻訳表示領域 -->
              <div v-if="translationObject" class="p-1 text-success text-size-sm-right">
                {{ translationObject[button.text] ?? "" }}
              </div>
            </div>
            <a
              v-if="button.url"
              :href="button.url"
              target="_blank"
              class="text-dark"
            >
              <i class="fas fa-external-link-alt ml-3"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <chat-item-bottom-detail
      :chat-item="chatItem"
      :redirector-access-logs="redirectorAccessLogs"
    />
  </div>
</template>

<script>
export default {
  props: ["chatItem", "redirectorAccessLogs", "translationObject"],
};
</script>
