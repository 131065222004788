<template>
  <!--翻訳やvariantの設定をいちいちしないための追加ボタンコンポネント-->
  <b-btn
    variant="success"
    @click="$emit('click')">
    <i class="fas fa-plus"></i>
    <span>{{ buttonTitle || $t('buttons.add') }}</span>
  </b-btn>
</template>

<script>
export default {
  props: ['buttonTitle'],
}
</script>
