import Vue from 'vue'
import { UrlBuilder } from './util/url-builder'
import { API_URL } from './resource/urls'
import SyncLogController from './controller/sync-log-controller'

import SyncLog from './components/SyncLog.vue'
import Pagination from './components/Pagination.vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'


export const appSyncLog = (i18n) => {
  Vue.use(VueMoment, {
    moment,
  })

  /* コントローラ */
  const controller = new SyncLogController(
    (new UrlBuilder(API_URL)).build(),
    window.i18nContext
  )

  Vue.component('sync-log', SyncLog)
  Vue.component('pagination', Pagination)

  new Vue({
    el: '#app-sync-log',
    i18n: i18n,
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    }
  })
}
