<template>
  <!--翻訳やvariantの設定をいちいちしないための削除ボタンコンポネント-->
  <b-btn
    variant="danger"
    @click="$emit('click')">
    <i class="fas fa-trash-alt"></i>
    <span>{{ buttonTitle || $t('buttons.delete') }}</span>
  </b-btn>
</template>

<script>
export default {
  props: ['buttonTitle'],
}
</script>
