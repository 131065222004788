<template>
  <div :id="editorId" style="width: 100%; height: 300px;"></div>
</template>

<script>
import * as ace from 'ace-builds/src-noconflict/ace'

export default {
  props: ['editorId', 'content', 'lang', 'required', 'autoHeight', 'readOnly'],
  data () {
    return {
      editor: Object,
      beforeContent: ''
    }
  },
  watch: {
    'content' (value) {
    	if (this.beforeContent !== value) {
      	this.editor.setValue(value, 1)
      }
    },
    'required' (value) {
      if (!value) {
        this.editor.setStyle('editor-danger')
      }
      else {
        this.editor.unsetStyle('editor-danger')
      }
    }
  },
  mounted () {
  	const lang = this.lang || 'text'

    // editorIdの指定は必須
		this.editor = ace.edit(this.editorId)
    this.editor.setValue(this.content || '', 1)
    if(this.autoHeight){
      this.editor.setOptions({
        minLines: 20,
        maxLines: Infinity
      })
    }
    this.editor.setReadOnly(this.readOnly)
    this.editor.getSession().setMode(`ace/mode/${lang}`)
    this.editor.getSession().setOptions({tabSize: 2})
    this.editor.on('change', () => {
    	this.beforeContent = this.editor.getValue()
      this.$emit('change-content', this.editor.getValue())
    })
  }
}
</script>
