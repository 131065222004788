import Vue from 'vue'
import {AgentSwitchController} from "./controller/agent-switch-controller.js"
import AgentSwitch from './components/AgentSwitch.vue'


export const appAgent = (i18n) => {
  const controller = new AgentSwitchController(
    window.requestContext.language,
    window.i18nContext,
    window.agentsContext
  )

  Vue.component('agent-switch', AgentSwitch)

  new Vue({
    el: '#app-agent',
    component: "agent-switch",
    i18n: i18n,
    data: {
      controller: controller
    }
  })
}
