import axios from 'axios'

class FileUpload {
  constructor(endpoint, model) {
    this.endpoint = endpoint
    this.model = model
  }

  /**
   * data is FormData
   */
  submit(data, csrfToken, options={}) {
    let sendOptions = {}
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    return new Promise((resolve, reject) => {
      axios.post(
        this.endpoint,
        data,
        sendOptions
      )
      .then((response) => {
        resolve(this.model.fromData(response.data))
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    })
  }

  retrieve(instance, options={}) {
    return new Promise((resolve, reject) => {
      axios.get(
        this.endpoint + `${instance.id}/`,
        options
      )
      .then((response) => {
        resolve(this.model.fromData(response.data))
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    })
  }
}

export {
  FileUpload
}
