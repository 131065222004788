/**
 * 翻訳文字列を返すクラス
 * vue-i18nのconfig相当を受け取る
 */
class I18n {
  constructor(i18nContext) {
    this.context = i18nContext
    this.messages = i18nContext.messages[i18nContext.locale]
  }

  t(messageKey) {
    return messageKey.split('.').reduce(
      (obj, index) => obj[index],
      this.messages
    )
  }
}

export {
  I18n
}
