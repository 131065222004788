import { ui } from "@joint/plus";
import { maxZoomScale, minZoomScale } from "./const";

/**
 * 画面右下の小窓を生成
 */
function initializeNavigator(paperScroller) {
  const nav = new ui.Navigator({
    paperScroller: paperScroller,
    width: 300,
    height: 200,
    padding: 20,
    zoomOptions: { max: maxZoomScale, min: minZoomScale },
  });
  nav.$el.appendTo("#diagram-navigator");
  nav.render();
}

export { initializeNavigator };
