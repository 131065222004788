<template>
<div>
  <!--キーワードリストの最後の項目をダブルクリックすると-->
  <!--ポップアップしたダイアログの文字がドラッグの状態になるのでb-modalの位置を変更-->
  <b-modal
    :title="modalTitle"
    ref="keywordModal"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-esc
    no-close-on-backdrop
    @shown="modalFocus"
    @hidden="modalHidden">
    <form @submit.prevent="editKeywordSubmit">
      <div>
        {{ $t('category.name') }}:
      </div>
      <basic-select
          :options="modalParentCategories"
          :selected-option="itemCategory"
          :state="!!modalParentCategoryId"
          @select="selectKeywordCategory"
          placeholder="Select Category">
      </basic-select>
      <div>
        {{ $t('keyword.keywordName') }}: ({{ $t('general.useAlphabetOrNumber') }})
      </div>
      <form @keydown.enter.prevent="editKeywordSubmit">
        <b-form-input
          type="text"
          ref="focusInput"
          v-model="modalKeywordName"
          :maxlength="max"
          :state="modalKeywordName ? isValidModalKeywordName : null">
        </b-form-input>
      </form>
    </form>
    <modal-footer
      slot="modal-footer"
      :okDisabled="!(modalParentCategoryId && isValidModalKeywordName)"
      @ok="editOk"
      @cancel="editCancel">
    </modal-footer>
  </b-modal>

  <delete-confirm-modal
    :title="$t('keyword.deleteTitle')"
    ref="deleteKeydowdConfirmModal"
    @keydown.native.enter="pressEnterKey"
    @ok="deleteKeyword"
    @cancel="deleteCancel"
    :bodyMessage="$t('keyword.deleteConfirmMessage', {keyword: this.selectedKeyword && this.selectedKeyword.name})"
    :bodySubMessage="[$t('category.deleteOtherLanguagesWarning')]"
    @hidden="modalHidden">
  </delete-confirm-modal>

  <b-modal
    :title="$t('keyword.registered')"
    ref="duplicate"
    header-class="bg-danger text-light"
    body-class="text-danger"
    :ok-only="true"
    @keypress.native.enter="showLastDisplayedModal"
    @ok="showLastDisplayedModal"
    no-close-on-esc
    no-close-on-backdrop>
    <div>
      <p>{{ this.i18n.t('keyword.duplicated') }}</p>
    </div>
  </b-modal>

  <div class="column-caption my-1 p-1 font-weight-bold pointer-cursor" v-b-toggle.keyword_collapse @click = "folded = !folded">
    <span class="float-none pl-1" v-show="folded"><i class="fas fa-caret-down"></i></span>
    <span class="float-none pl-1" v-show="!folded"><i class="fas fa-caret-up"></i></span>
    <span class="pl-1">{{ $t('keyword.title') }}</span>
    <b-button
      v-if="desc"
      class="btn btn-success btn-xs float-right" v-b-toggle.keyword_collapse
      :aria-expanded="true"
      @click="ordering"
      @click.prevent="folded = !folded">{{$t('keyword.desc')}}</b-button>
    <b-button
      v-else
      class="btn btn-success btn-xs float-right" v-b-toggle.keyword_collapse
      :aria-expanded="true"
      @click="ordering"
      @click.prevent="folded = !folded">{{$t('keyword.asc')}}</b-button>
    <div style="clear: both"></div>
  </div>
  <b-collapse visible id="keyword_collapse">
    <div class="row mb-1" v-if="userType != 'viewer'">
      <div class="col-auto mr-auto">
        <button
          class="btn btn-success button-text mr-1"
          @click="addKeyword">
          <i class="fa fa-plus"></i>
          <span>{{ $t('buttons.add') }}</span>
        </button>

        <button
          class="btn btn-success button-text mr-1"
          @click="editKeyword"
          v-bind:disabled="!selectedKeyword">
          <i class="fa fa-edit"></i>
          <span>{{ $t('buttons.edit') }}</span>
        </button>

        <button
          class="btn btn-success button-text"
          @click="deleteKeywordConfirm"
          v-bind:disabled="!selectedKeyword">
          <i class="fas fa-trash-alt"></i>
          <span>{{ $t('buttons.delete') }}</span>
        </button>
      </div>
      <div class="col-auto">
        <b-navbar-nav v-show="selectedCategory">
          <b-nav-item-dropdown class="m-auto" right>
            <template slot="button-content">
              <i class="fas fa-ellipsis-v dropdown-icon mr-3"></i>
            </template>
            <b-dropdown-item
              :title="$t('keyword.selectedKeywordDataImport')"
              href="#"
              @click="$emit('import-json')"
              v-bind:disabled="!selectedCategory">
              {{ $t('intent.import')}}
            </b-dropdown-item>
            <b-dropdown-item
              :title="$t('keyword.selectedKeywordDataExport')"
              href="#"
              @click="$emit('download-json', selectedCategory.id)"
              v-bind:disabled="!selectedCategory">
              {{ $t('intent.export') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>
    <b-form-input
      type="text"
      :placeholder="$t('keyword.search')"
      v-model="searchWord"
      :formatter="format">
    </b-form-input>
    <ul class="keyword scroll-keyword" tabindex="-1" ref="focusOn" id="scrollY_Keyword" @keyup.delete="pressDeleteKey">
      <keyword-item
        class="keyword-item"
        v-for="(keyword, index) in allKeywords"
        :key="index"
        :index="index"
        :keyword="keyword"
        :selectedKeyword="selectedKeyword"
        :searchWord="searchWord"
        :keywordLength="keywordLength"
        :userType="userType"
        @select-keyword="selected"
        @edit-keyword="editKeyword"
        @delete-keyword="deleteKeywordConfirm"
        @show-intent-list-modal="showIntentListInUseModal">
      </keyword-item>
    </ul>
  </b-collapse>
</div>
</template>
<script>
import { Keyword } from '../model/keyword'
import KeywordItem from './KeywordItem.vue'
import {I18n} from "../util/i18n"
import { BasicSelect } from 'vue-search-select'
import { illegalExp } from '../util/illegalRegex'

export default {
  props: [
    'categoryJoin',
    'keywords',
    'allKeyword',
    'selectedKeywordValue',
    'selectedKeyword',
    'selectedCategory',
    'categoryModel',
    'categories',
    'desc',
    'userType'
  ],
  data() {
    return {
      modalTitle: "",
      modalKeyword: null,
      modalKeywordName: '',
      modalKeywordCategoryId: null,
      modalParentCategoryId: null,
      modalParentCategories: [],
      modalErrorMessage: '',
      i18n: new I18n(window.i18nContext),
      searchWord: '',
      keywordLength:[],
      max:30,
      tempIndex:0,
      changeIndexFlag:false,
      arrowKeyFlag:0,
      itemCategory: {value:'', text:''},
      folded:false,
      isModalShown:false,
      isModalOK:false,
      modalPopupCheckFlag:false,
      scrollY:0,            //縦のスクロールバー位置
      scrollY_Calc_Num1:34, //矢印でアイテム選択中、アイテムがスクロールで隠れないようにスクロール速度調整のための数値（計算用）
      scrollY_Calc_Num2:35,
      scrollY_Calc_Num3:36
    }
  },
  components: {
    KeywordItem, BasicSelect
  },
  computed:{
    allKeywords() {
      this.keywordLength=[]
      for(let keyword of this.keywords){
        this.keywordLength.push({
          length:(keyword.name+this.categoryJoin[keyword.categoryId]).length,
        })
      }
      return this.keywords
    },
    isValidModalKeywordName() {
      return this.isValidKeywordName(this.modalKeywordName)
    }
  },
  methods: {
    selectKeywordCategory(category){
      this.itemCategory = category
      if(category){
        this.modalParentCategoryId = category.value
      }
    },
    selected(keyword) {
      for(let i = 0; i < this.allKeywords.length; i++){
        if(this.allKeywords[i].id == keyword.id){
          this.scrollY = i*this.scrollY_Calc_Num1
        }
      }
      this.changeIndexFlag = true
      this.arrowKeyFlag = 2
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$emit('arrowKey-flag',this.arrowKeyFlag)
      this.$emit('select-keyword',keyword)
      return keyword == this.selectedKeyword
    },
    onArrowUp(keyword){
      for(let i = 0; i < this.allKeywords.length; i++){
        if(this.allKeywords[i].id == keyword.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i -1
            this.changeIndexFlag = false
          if(this.tempIndex < 0){return}
          }
          else{
            if(this.tempIndex <= 0){
              return
            }else{
              this.tempIndex = this.tempIndex -1
          }
        }
        }
      }
      this.scrollY = this.scrollY - this.scrollY_Calc_Num3
      document.getElementById("scrollY_Keyword").scrollTop = this.scrollY
      keyword = this.keywords[this.tempIndex]
      this.$emit('select-keyword',keyword)
    },
    onArrowDown(keyword){
      for(let i = 0; i < this.allKeywords.length; i++){
        if(this.allKeywords[i].id == keyword.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i +1
            this.changeIndexFlag = false
          if(this.tempIndex > this.keywords.length-1){return}
          }
          else{
            if(this.tempIndex >= this.keywords.length-1){
              return
            }else{
              this.tempIndex = this.tempIndex+1
            }
          }
        }
      }
      document.getElementById("scrollY_Keyword").scrollTop = this.scrollY
      this.scrollY = this.scrollY + this.scrollY_Calc_Num2
      keyword = this.keywords[this.tempIndex]
      this.$emit('select-keyword',keyword)
    },
    onArrowRight(keyword){
      this.tempIndex = 0
      this.scrollY = 0
      document.getElementById("scrollY_Keyword").scrollTop = this.scrollY
      this.$emit('select-keyword',keyword)
      this.$refs.focusOn.focus()
    },
    onArrowLeft(keyword){
      for(let i = 0; i < this.allKeywords.length; i++){
        if(this.allKeywords[i].id == keyword.id){
          this.tempIndex = i
        }
      }
      this.$emit('select-keyword',keyword)
      this.$refs.focusOn.focus()
    },
    ordering(){
      let orderOption = this.desc ? '-name' : 'name'
      this.$emit('keyword-order', orderOption)
    },
    createCategoriesData() {
      // ドロップダウンのカテゴリツリー生成
      let categories = [{
        value: null,
        text: "---"
      }]
      let flat = this.categoryModel.categoriesAsFlat(this.categories)
      for (const [nestedLevel, category] of flat) {
        let indent = ""
        for (let i = 0; i < nestedLevel; i++) {
          indent += " » "
        }
        categories.push({
          value: category.id,
          text: indent + category.name
        })
      }
      categories.shift()
      return categories
    },
    addKeyword() {
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.modalTitle = this.i18n.t('keyword.add')
      this.modalKeyword = new Keyword()
      this.modalKeywordName = ''
      this.modalKeywordCategoryId = this.selectedCategory == null ? null : this.selectedCategory.id
      this.modalParentCategories = this.createCategoriesData()
      this.modalParentCategoryId = this.modalKeywordCategoryId
      let text=''
      for(let categories of this.modalParentCategories){
        if(this.modalParentCategoryId == categories.value){
          text = categories.text
        }
      }
      this.itemCategory ={value:this.modalParentCategoryId, text:text}
      this.$refs.keywordModal.show()
    },
    deleteKeywordConfirm() {
      if(this.selectedKeyword==null){
        return
      }
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.deleteKeydowdConfirmModal.show()
    },
    pressDeleteKey(){
      return this.userType&&this.selectedKeyword ? this.deleteKeywordConfirm() : null
    },
    pressEnterKey(){
      this.$refs.deleteKeydowdConfirmModal.hide()
    },
    deleteKeyword() {
      if(this.isModalOK)
        return null
      this.modalKeyword = this.selectedKeywordValue
      this.$emit('delete-keyword-value', this.modalKeyword)
      this.modalKeyword = this.selectedKeyword
      this.$emit('delete-keyword', this.modalKeyword)
      this.isModalOK = true
    },
    editKeyword() {
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.modalTitle = this.i18n.t('keyword.editTitle')
      this.modalKeyword = this.selectedKeyword
      this.modalKeywordName = this.modalKeyword.name
      this.modalKeywordCategoryId = this.modalKeyword.categoryId
      this.modalParentCategories = this.createCategoriesData()
      this.modalParentCategoryId = this.selectedCategory.id
      let text=''
      for(let categories of this.modalParentCategories){
        if(this.modalParentCategoryId == categories.value){
          text = categories.text
          break
        }
      }
      this.itemCategory ={value:this.modalParentCategoryId, text:text}
      this.$refs.keywordModal.show()
    },
    editKeywordSubmit(){
      if(this.modalParentCategoryId && this.isValidModalKeywordName){
        return this.editOk()
      }
      return false
    },
    editOk() {
      /**
       * modalからのenterでのsubmitはボタンの無効化で弾けないので、validationを行う
       * invalidな時、modal上ではformが赤くなっているので、警告表示はそちらでOKとする
       */
      if (!this.isValidKeywordName(this.modalKeywordName)) {
        return
      }
      // 重複チェックのため、先にmodalKeywordに情報を入れる
      let beforeModalKeywordName = this.modalKeyword.name //DBでは保存されないが重複になった名前が画面リストに表示される。重複になったら画面表示は変更しないようにするたの変数
      this.modalKeyword.name = this.modalKeywordName.trim()
      this.modalKeyword.name = this.modalKeyword.name.replace(illegalExp, "")
      this.modalKeyword.categoryId = this.modalParentCategoryId
      if (this.isDuplicate(this.modalKeyword,beforeModalKeywordName)) {
        this.$refs.duplicate.show()
        return
      }
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$emit('save-keyword', this.modalKeyword)
      this.isModalOK = true
      this.$refs.keywordModal.hide()
    },
    isDuplicate(modalKeyword,beforeModalKeywordName) {
      let keywords = this.allKeyword.filter(allKeyword => allKeyword.categoryId == modalKeyword.categoryId)
      let sameNameKeywords
      if (modalKeyword.id) {
        // 編集の場合、編集中のkeyword以外に同名のものがあれば重複とみなす。
        sameNameKeywords = keywords.filter(keyword => keyword.name == modalKeyword.name && keyword.id != modalKeyword.id)
        if((sameNameKeywords.length >= 1) == true){
          this.modalKeyword.name = beforeModalKeywordName
          return true
        }
      } else {
        // 新規追加の場合
        sameNameKeywords = keywords.filter(keyword => keyword.name == modalKeyword.name)
      }
      return sameNameKeywords.length >= 1
    },
    format(value, event) {
      return value.toLowerCase()
    },
    showLastDisplayedModal() {
      if (this.modalTitle === this.i18n.t('keyword.add')) {
        this.addKeyword()
      } else if (this.modalTitle === this.i18n.t('keyword.editTitle')) {
        this.editKeyword()
      }
      this.$refs.duplicate.hide()
      this.modalFocus()
    },
    isValidKeywordName(keywordName) {
      return keywordName.trim().match(/^[a-zA-Z0-9-]+[a-zA-Z0-9]$/) != null
    },
    modalFocus(){
      if(this.modalParentCategoryId == null){
        return
      }
      else{
        this.$refs.focusInput.focus()
      }
    },
    editCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.keywordModal.hide()
    },
    deleteCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.deleteKeydowdConfirmModal.hide()
    },
    modalHidden(){
      this.$refs.focusOn.focus()
      if(!this.isModalOK){
        if(this.selectedKeyword)
          this.selected(this.selectedKeyword)
        else{
          this.arrowKeyFlag = 1
          this.$emit('arrowKey-flag',this.arrowKeyFlag)
        }
      }
      this.isModalOK = false
    },
    showIntentListInUseModal(keyword){
      this.$emit('show-intent-list-modal', keyword)
    }
  }
}
</script>
