<template>
  <span class="badge" :class="badgeColor">
    <span v-html="iconTag" />
    <small> {{ iconLabel }} </small>
  </span>
</template>

<script>
import { operatorHandlingSessionStatusData } from '../../resource/operator-handling-session-status'
import { operatorStatus } from '../../util/operatorStatus'

export default {
  name: 'OperatorHandlingSessionStatusIcon',
  props: ['platform', 'status'],
  computed: {
    /** オペレーター対応セッションのステータスに対応するアイコンの情報を取得 */
    iconInfo() {
      if (!this.status) {
        return ''
      }

      const operatorHandlingSessionStatusDataItem = Object.values(
        operatorHandlingSessionStatusData
      ).find((data) => data.value === this.status)

      return operatorHandlingSessionStatusDataItem
    },
    /**
     * タグごと渡さないとアイコンがリアクティブに切り替わらないためcomputedにしておく。
     */
    iconTag() {
      return `<i class="icon-size ${this.iconInfo.faIcon}"></i>`
    },
    /**
     * 対応状態に応じて色を設定。
     */
    badgeColor() {
      if(this.status === operatorStatus.ACTIVE) {
        // 対応中
        return 'badge-warning'
      }
      if(this.status === operatorStatus.INACTIVE) {
        // 対応済み
        return 'badge-success'
      }
      if(this.status === operatorStatus.CALLING) {
        // 呼び出し中(未対応)
        return 'badge-danger'
      }
      return ''
    },
    iconLabel() {
      const value = this.iconInfo.value
      if (value === operatorStatus.CALLING) {
        return this.$t('history.Calling')
      } else if (value === operatorStatus.ACTIVE) {
        return this.$t('history.OperatoSessionActive')
      } else if (value === operatorStatus.INACTIVE) {
        return this.$t('history.Finished')
      }
    }
  },
}
</script>
