<template>
  <div>
    <b-dropdown
      variant="primary"
      class="mr-1"
      :text="
        selectedLanguage
        ? languageLabels[selectedLanguage]
        : $t('scenario.selectLanguage')
      "
    >
      <b-dropdown-item
        v-for="(supportedLanguage, idx) in supportedLanguages"
        v-show="supportedLanguage.code != currentLanguage"
        :key="idx"
        :disabled="supportedLanguage.code === selectedLanguage"
        @click="changeLanguage(supportedLanguage.code)"
      >
        {{ languageLabels[supportedLanguage.code] }}
      </b-dropdown-item>
    </b-dropdown>
    <b-btn
      :title="$t('question.copyQuestions')"
      variant="success"
      :disabled="!selectedLanguage"
      size="lg"
      @click="copyUserSays">
      <i class="fas fa-copy"></i>
    </b-btn>
    <ul
      v-if="selectedLanguage && selectedLanguageIntentDetail"
      class="scroll">
      <li
        v-for="(userSay, index) in selectedLanguageIntentDetail.data.userSays"
        :key=index
        class="label my-1 p-1">
        {{ userSay.text }}
      </li>
    </ul>
  </div>
</template>


<script>
import { LANGUAGE_LABELS } from '../resource/language';

export default {
  props: [
    'supportedLanguages',
    'currentLanguage',
    'selectedLanguageIntentDetail',
  ],
  data() {
    return {
      selectedLanguage: null,
      languageLabels: LANGUAGE_LABELS,
    }
  },
  methods: {
    changeLanguage(language_code) {
      this.selectLanguage(language_code)
      this.$emit('changed-compared-language', language_code)
    },
    selectLanguage(language_code) {
      this.selectedLanguage = language_code
    },
    copyUserSays() {
      this.$emit('copy-user-says', this.selectedLanguageIntentDetail)
    },
  },
}
</script>
