export default function copyToClipboard(copyText) {
  var copyFrom = document.createElement("textarea");
  copyFrom.textContent = copyText;

  var bodyElm = document.getElementsByTagName("body")[0];
  bodyElm.appendChild(copyFrom)

  copyFrom.select();
  document.execCommand('copy');
  bodyElm.removeChild(copyFrom);
}

