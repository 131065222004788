<template>
  <div class="answer-basicCard">
    <div class="mb-1 scroll">
      <b-card>
        <b-tabs v-model="indexTab">
          <b-tab  :title=tab.tabTitle v-for="(tab,index) in basicCardData.tab" :key="index">
            <div class="mt-1">{{ $t('answer.content') }}</div>
            <div v-for="(replyText,indexText) in tab.tabBasicCardText">
              <div>
                <div class="input-group mb-1 mt-1">
                  <b-form-input v-model="replyText.imageUrl"
                                :placeholder="$t('answer.enterImageURL')"
                                @input="validationCheck"
                                :state="!$v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].imageUrl.$error"
                                ref="replyTextImageUrl" />
                  <div class="input-group-append">
                    <a
                      class="btn btn-info"
                      :class="{ disabled: !replyText.imageUrl || $v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].imageUrl.$error }"
                      :href="replyText.imageUrl" target="_blank"
                      :title="$t('answer.preView')"
                    ><span class="fas fa-eye mt-1"/>
                    </a>
                    <a class="btn btn-primary text-white" v-b-tooltip.hover
                       :title="$t('answer.browseImage')"
                       @click="loadReplyTextImageUrlFiles(index)">
                      <i class="far fa-images"></i>
                    </a>
                  </div>
                  <b-form-invalid-feedback v-if="$v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].imageUrl.$error">
                    {{ $t('answer.invalidURL') }}
                  </b-form-invalid-feedback>
                </div>
                <file-contents ref="replyTextImageUrlFiles"
                               :img-url-el="$refs.replyTextImageUrl"
                               @img-url-model="replyText.imageUrl = $event"></file-contents>
                <b-form-input v-model="replyText.title" class="mb-1"
                              @input="validationCheck"
                              :placeholder="$t('answer.enterCardTitle')"
                              :state="!$v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].title.$error"/>
                <b-form-invalid-feedback v-if="$v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].title.$error">
                  {{ $t('answer.maxTxtLen', {counter: $v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].title.$params.maxLength.max}) }}
                </b-form-invalid-feedback>
                <b-form-textarea
                    class="mb-1"
                    v-model="replyText.description"
                    @input="validationCheck"
                    :placeholder="$t('answer.enterCardSubtitle')"
                    :rows="2"
                    :max-rows="6"
                    :state="!$v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].description.$error"/>
                <b-form-invalid-feedback v-if="$v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].description.$error">
                  {{ $t('answer.maxTxtLen', {counter: $v.basicCardData.tab.$each[index].tabBasicCardText.$each[indexText].description.$params.maxLength.max}) }}
                </b-form-invalid-feedback>
              </div>
            </div>
            <div>{{ $t('answer.cardButtons') }}</div>
            <div v-for="(replyBtn, indexBtn) in tab.tabBasicCardButton">
              <div>
                <div @keypress.enter.prevent="addBasicCardButton">
                  <div class="card-button-title mb-1 mr-1">
                    <b-form-select
                      v-model="replyBtn.action"
                      @input="validationCheck"
                      :options="actionOptions">
                    </b-form-select>
                  </div>
                  <div>
                    <button
                      class="btn btn-danger"
                      style="width: 45px; height: 37px;"
                      :disabled="tab.tabBasicCardButton.length == 1"
                      @click.prevent="deleteBasicCardButtons(indexBtn)">
                      <i class="fa fa-minus"></i>
                    </button>
                      <button
                        v-if="indexBtn == 0"
                        class="btn btn-success"
                        style="width: 45px; height: 37px;"
                        @click.prevent="addBasicCardButton(indexTab)">
                        <i class="fa fa-plus"></i>
                      </button>
                  </div>
                    <div class="mb-1">
                      <b-form-input
                        v-model="replyBtn.label"
                        @input="validationCheck"
                        :placeholder="$t('answer.cardButtonTitle')"
                        :state="!$v.basicCardData.tab.$each[index].tabBasicCardButton.$each[indexBtn].label.$error"/>
                      <b-form-invalid-feedback v-if="$v.basicCardData.tab.$each[index].tabBasicCardButton.$each[indexBtn].label.$error">
                        {{ $t('answer.maxTxtLen', {counter: $v.basicCardData.tab.$each[index].tabBasicCardButton.$each[indexBtn].label.$params.maxLength.max}) }}
                      </b-form-invalid-feedback>
                    </div>
                    <div class="mb-1">
                      <b-form-input
                        v-if="replyBtn.action == 'webLinkUrl'"
                        v-model="replyBtn.text"
                        @input="validationCheck"
                        :placeholder="$t('answer.simpleBasicCardLink')"
                        :state="!$v.basicCardData.tab.$each[index].tabBasicCardButton.$each[indexBtn].text.$error"
                      />
                      <b-form-invalid-feedback v-if="$v.basicCardData.tab.$each[index].tabBasicCardButton.$each[indexBtn].text.$error && replyBtn.action == 'webLinkUrl'">
                        {{ $t('answer.invalidURL') }}
                      </b-form-invalid-feedback>
                      <b-form-input
                        v-if="replyBtn.action == 'osLink'"
                        v-model="replyBtn.text"
                        :placeholder="$t('answer.simpleBasicCardLink')"/>
                      <b-form-input
                        v-if="replyBtn.action == 'messageText'"
                        v-model="replyBtn.text"
                        :placeholder="$t('answer.simpleBasicCardMessageText')"/>
                      <b-form-input
                        v-if="replyBtn.action == 'phoneNumber'"
                        v-model="replyBtn.text"
                        @input="validationCheck"
                        :placeholder="$t('answer.simpleBasicCardPhoneNumber')"
                        :state="!$v.basicCardData.tab.$each[index].tabBasicCardButton.$each[indexBtn].text.$error"
                      />
                      <b-form-invalid-feedback v-if="$v.basicCardData.tab.$each[index].tabBasicCardButton.$each[indexBtn].text.$error && replyBtn.action == 'phoneNumber'">
                        {{ $t('answer.mustBeNumber') }}
                      </b-form-invalid-feedback>
                    </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-nav-item slot="tabs" @click="newTab(indexTab)" href="#">
            +
          </b-nav-item>
        </b-tabs>
        </b-card>
    </div>
    <div>
      <b-btn size="sm" variant="danger" class="float-right mb-1" @click="closeTab" :disabled="basicCardData.tab.length == 1">
                Close tab
      </b-btn>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {maxLength, url} from 'vuelidate/lib/validators'
import {BasicCardData,Tab,BasicCardButton,BasicCardText,ResponseMessage} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  props:['selectedResponseMessage'],
  data() {
    return {
      answerBasicCard:null,
      responseMessageBasicCardText:[],
      responseMessageBasicCardButton:[],
      tabBasicCardText:[],
      tabBasicCardButton:[],
      maxTitle:20,
      maxDescription:76,
      maxLabel:14,
      actionOptions:[
        { value: 'webLinkUrl', text: 'Web_Link'},
        { value: 'osLink', text: 'OS_Link'},
        { value: 'messageText', text: 'Message_Text'},
        { value: 'phoneNumber', text: 'Phone_Number'},
      ],
      basicCardData:new BasicCardData([new Tab('Card',[new BasicCardText()], [new BasicCardButton()])]),
      indexTab:0
    }
  },
  validations() {
    return {
      basicCardData: {
        tab: {
          $each: {
            tabBasicCardText: {
              $each: {
                imageUrl: {
                  url
                },
                title: {
                  maxLength: maxLength(this.maxTitle)
                },
                description: {
                  maxLength: maxLength(this.maxDescription)
                }
              }
            },
            tabBasicCardButton: {
              $each: {
                label: {
                  maxLength: maxLength(this.maxLabel)
                },
                text: {
                  input(value, tabBasicCardButton) {
                    let rule = true
                    if(value) {
                      switch(tabBasicCardButton.action){
                        case this.actionOptions[0].value: //webLinkUrl
                          rule = (value.trim().match(/^https:\/\/|http:\/\//g) !== null)
                          break
                        case this.actionOptions[3].value: //phoneNumber
                          rule = (value.trim().match(/^[0-9]+/g) !== null)
                          break
                      }
                    }
                    return rule
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  methods:{
    closeTab () {
      this.basicCardData.tab.splice(this.indexTab, 1)
      this.validationCheck()
    },
    newTab (indexTab) {
      if(indexTab < 9){
        let tab = new Tab('Card',[new BasicCardText()], [new BasicCardButton()])
        this.basicCardData.tab.push(tab)
        this.$nextTick(() => {
          this.indexTab = this.basicCardData.tab.length - 1
        })
      }
    },
    addBasicCardButton(indexTab){
      if(this.basicCardData.tab[indexTab].tabBasicCardButton.length < 3){
        this.basicCardData.tab[indexTab].tabBasicCardButton.push(new BasicCardButton())
      }
    },
    deleteBasicCardButtons(indexBtn){
      this.basicCardData.tab[this.indexTab].tabBasicCardButton.splice(indexBtn, 1)
      this.validationCheck()
    },
    inputValue(){
      for(let tab of this.basicCardData.tab){
        for(let cardText of tab.tabBasicCardText){
          cardText.description = cardText.description.replace(illegalExp, "").trim()
          cardText.imageUrl = cardText.imageUrl.replace(illegalExp, "").trim()
          cardText.title = cardText.title.replace(illegalExp, "").trim()
        }
        for(let button of tab.tabBasicCardButton){
          button.label = button.label.replace(illegalExp, "").trim()
          button.text = button.text.replace(illegalExp, "").trim()
        }
      }
      return this.basicCardData
    },
    setValue(selectedResponseMessage){
      this.basicCardData = new BasicCardData([new Tab('Card',[new BasicCardText()], [new BasicCardButton()])])
      this.basicCardData.tab.splice(0,1)
      for(let i = 0; i < selectedResponseMessage.data.tab.length; i++){
        this.basicCardData.tab.push(selectedResponseMessage.data.tab[i])
      }
    },
    initializeValue(){
      this.basicCardData = new BasicCardData([new Tab('Card',[new BasicCardText()], [new BasicCardButton()])]),
      this.responseMessageBasicCardText=[]
      this.responseMessageBasicCardButton = []
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadReplyTextImageUrlFiles(index) {
      this.$refs.replyTextImageUrlFiles[index].loadImages()
    }
  }
}
</script>
