let system_entities=[
  'date-time',
  'date',
  'date-period',
  'time',
  'time-period',
  'number',
  'cardinal',
  'ordinal',
  'number-integer',
  'number-sequence',
  'flight-number',
  'unit-area',
  'unit-currency',
  'unit-length',
  'unit-speed',
  'unit-volume',
  'unit-weight',
  'unit-information',
  'percentage',
  'temperature',
  'duration',
  'age',
  'currency-name',
  'unit-area-name',
  'unit-length-name',
  'unit-speed-name',
  'unit-volume-name',
  'unit-weight-name',
  'unit-information-name',
  'address',
  'street-address',
  'zip-code',
  'geo-capital',
  'geo-country',
  'geo-city',
  'geo-state',
  'geo-city-us',
  'geo-state-us',
  'geo-county-us',
  'geo-city-gb',
  'geo-state-gb',
  'geo-county-gb',
  'place-attraction-us',
  'place-attraction-gb',
  'place-attraction',
  'airport',
  'location',
  'email',
  'phone-number',
  'given-name',
  'last-name',
  'music-artist',
  'music-genre',
  'color',
  'language',
  'any',
  'url'
]
const SYSTEM_ENTITY = system_entities.sort()
export {
  SYSTEM_ENTITY
}
