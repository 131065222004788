<template>
    <div class="answer-commerceCard">
      <div class="mb-1 scroll">
        <b-card>
          <b-tabs  v-model="indexTab">
            <b-tab  :title=tab.tabTitle v-for="(tab,index) in commerceCardData.commerceCardTab" :key="index">
              <div class="mt-1">{{ $t('answer.content') }}</div>
              <div v-for="(replyText,indexText) in tab.tabCommerceCardText">
                <div class="input-group mb-1 mt-1">
                  <b-form-input v-model="replyText.thumbnail_imageUrl"
                                @input="validationCheck"
                                :placeholder="$t('answer.commerceCardThumbnailURL')"
                                :state="!$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].thumbnail_imageUrl.$error"
                                ref="replyTextThumbnailImageUrl" />
                  <div class="input-group-append">
                    <a
                      class="btn btn-info"
                      :class="{ disabled: $v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].thumbnail_imageUrl.$error }"
                      :href="replyText.thumbnail_imageUrl" target="_blank"
                      :title="$t('answer.preView')"
                    ><span class="fas fa-eye mt-1"/>
                    </a>
                    <a class="btn btn-primary text-white" v-b-tooltip.hover
                       :title="$t('answer.browseImage')"
                       @click="loadReplyTextThumbnailImageUrlFiles(index)">
                      <i class="far fa-images"></i>
                    </a>
                  </div>
                  <b-form-invalid-feedback v-if="$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].thumbnail_imageUrl.$error">
                    {{ $t('webhook.required') }} &
                    {{ $t('answer.invalidURL') }}
                  </b-form-invalid-feedback>
                </div>
                <file-contents ref="replyTextThumbnailImageUrlFiles"
                               :img-url-el="$refs.replyTextThumbnailImageUrl"
                               @img-url-model="replyText.thumbnail_imageUrl = $event"></file-contents>
                <b-form-input v-model="replyText.thumbnail_imageLink" class="mb-1"
                              @input="validationCheck"
                              :placeholder="$t('answer.commerceCardThumbnailLink')"
                              :state="!$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].thumbnail_imageLink.$error" />
                <b-form-invalid-feedback v-if="$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].thumbnail_imageLink.$error">
                  {{ $t('webhook.required') }} &
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
                <b-form-input v-model="replyText.profile_imageUrl" class="mb-1 mt-1"
                              @input="validationCheck"
                              :placeholder="$t('answer.commerceCardProfileURL')"
                              :state="!$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].profile_imageUrl.$error"/>
                <b-form-invalid-feedback v-if="$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].profile_imageUrl.$error">
                  {{ $t('webhook.required') }} &
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
                <b-form-input v-model="replyText.profile_nickName" :placeholder="$t('answer.commerceCardProfileNickname')" class="mb-1" />
                <b-input-group class="mb-1">
                  <b-form-input v-model="replyText.price" type="number" style="width: 60px"
                                @input="validationCheck"
                                :placeholder="$t('answer.commerceCardPrice')"
                                :state="!$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].price.$error"/>
                  <b-form-input v-model="replyText.discount" type="number" :placeholder="$t('answer.commerceCardDiscount')" class="ml-1" style="width: 60px"/>
                  <b-form-select v-model="replyText.currency" :options="currencyOptions" :disabled="true" class="ml-1"/>
                  <b-form-invalid-feedback v-if="$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].price.$error">
                    {{ $t('answer.mustBeNumber') }}
                  </b-form-invalid-feedback>
                </b-input-group>
                <b-form-textarea
                    class="mb-1"
                    v-model="replyText.description"
                    @input="validationCheck"
                    :placeholder="$t('answer.commerceCardDescription')"
                    :rows="2"
                    :max-rows="6"
                    :state="!$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].description.$error"
                />
                <b-form-invalid-feedback v-if="$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].description.$error">
                  {{ $t('webhook.required') }} &
                  {{ $t('answer.maxTxtLen', {counter: $v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardText.$each[indexText].description.$params.maxLength.max}) }}
                </b-form-invalid-feedback>
              </div>

              <div>{{ $t('answer.cardButtons') }}</div>
              <div v-for="(replyBtn, indexBtn) in tab.tabCommerceCardButton">
                <div>
                  <div @keypress.enter.prevent="addBasicCardButton">
                    <div class="card-button-title mb-1 mr-1">
                        <b-form-select  v-model="replyBtn.action" :options="actionOptions" @input="validationCheck"></b-form-select>
                    </div>
                    <div>
                    <button
                      class="btn btn-danger"
                      style="width: 45px; height: 37px;"
                      :disabled="tab.tabCommerceCardButton.length == 1"
                      @click.prevent="deleteCommerceCardButtons(indexBtn)">
                      <i class="fa fa-minus"></i>
                    </button>
                    <button
                      v-if="indexBtn == 0"
                      class="btn btn-success"
                      style="width: 45px; height: 37px;"
                      @click.prevent="addCommerceCardButton(indexTab)">
                      <i class="fa fa-plus"></i>
                    </button>
                    </div>
                    <div class="mb-1">
                      <b-form-input v-model="replyBtn.label"
                                    @input="validationCheck"
                                    :placeholder="$t('answer.cardButtonTitle')"
                                    :state="!$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardButton.$each[indexBtn].label.$error"/>
                      <b-form-invalid-feedback v-if="$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardButton.$each[indexBtn].label.$error">
                        {{ $t('webhook.required') }} &
                        {{ $t('answer.maxTxtLen', {counter: $v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardButton.$each[indexBtn].label.$params.maxLength.max}) }}
                      </b-form-invalid-feedback>
                    </div>
                    <div class="mb-1">
                      <b-form-input v-if="replyBtn.action == 'webLinkUrl'" v-model="replyBtn.text"
                                    @input="validationCheck" :placeholder="$t('answer.simpleBasicCardLink')"
                                    :state="!$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardButton.$each[indexBtn].text.$error"/>
                      <b-form-invalid-feedback v-if="$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardButton.$each[indexBtn].text.$error && replyBtn.action == 'webLinkUrl'">
                        {{ $t('webhook.required') }} &
                        {{ $t('answer.invalidURL') }}
                      </b-form-invalid-feedback>
                      <b-form-input v-if="replyBtn.action == 'osLink'" v-model="replyBtn.text" @input="validationCheck" :placeholder="$t('answer.simpleBasicCardLink')"/>
                      <b-form-input v-if="replyBtn.action == 'phoneNumber'" v-model="replyBtn.text"
                                    @input="validationCheck" :placeholder="$t('answer.simpleBasicCardPhoneNumber')"
                                    :state="!$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardButton.$each[indexBtn].text.$error"/>
                      <b-form-invalid-feedback v-if="$v.commerceCardData.commerceCardTab.$each[index].tabCommerceCardButton.$each[indexBtn].text.$error && replyBtn.action == 'phoneNumber'">
                        {{ $t('webhook.required') }} &
                        {{ $t('answer.mustBeNumber') }}
                      </b-form-invalid-feedback>
                      <b-form-input v-if="replyBtn.action == 'share'" :disabled="true" :placeholder="$t('answer.commerceCardShare')"/>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-nav-item slot="tabs" @click="newTab(indexTab)" href="#">
            +
          </b-nav-item>
          </b-tabs>
        </b-card>
      </div>
      <div>
      <b-btn size="sm" variant="danger" class="float-right mb-1" @click="closeTab" :disabled="commerceCardData.commerceCardTab.length == 1">
                Close tab
      </b-btn>
    </div>
    </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, maxLength, url, numeric, requiredIf} from 'vuelidate/lib/validators'
import {CommerceCardData,CommerceCardTab,CommerceCardButton,CommerceCardText} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  data() {
    return {
      tabCommerceCardText:[],
      tabCommerceCardButton:[],
      currencyOptions:[{value: 'won', text: 'WON'}], //現在は韓国のwonのみ、追加予定
      actionOptions:[
        { value: 'webLinkUrl', text: 'Web_Link'},
        { value: 'osLink', text: 'OS_Link'},
        { value: 'phoneNumber', text: 'Phone_Number'},
        { value: 'share', text: 'Share'}
      ],
      commerceCardData:new CommerceCardData([new CommerceCardTab('Card',[new CommerceCardText()], [new CommerceCardButton()])]),
      indexTab:0,
      maxDescription:76,
      maxLabel:14,
    }
  },
  validations() {
    return {
      commerceCardData: {
        commerceCardTab: {
          $each: {
            tabCommerceCardText: {
              $each: {
                thumbnail_imageUrl: {
                  required,
                  url
                },
                thumbnail_imageLink: {
                  required,
                  url
                },
                profile_imageUrl: {
                  required,
                  url
                },
                price: {
                  required,
                  numeric
                },
                description: {
                  required,
                  maxLength: maxLength(this.maxDescription)
                }
              }
            },
            tabCommerceCardButton: {
              $each: {
                label: {
                  required,
                  maxLength: maxLength(this.maxLabel)
                },
                text: {
                  required: requiredIf((tabCommerceCardButton) =>
                      // webLinkUrl or phoneNumber is selected
                      (tabCommerceCardButton.action === this.actionOptions[0].value) ||
                      (tabCommerceCardButton.action === this.actionOptions[2].value)),
                  input(value, tabCommerceCardButton) {
                    let rule = true
                    if(value) {
                      switch(tabCommerceCardButton.action){
                        case this.actionOptions[0].value: //webLinkUrl
                          rule = (value.trim().match(/^https:\/\/|http:\/\//g) !== null)
                          break
                        case this.actionOptions[2].value: //phoneNumber
                          rule = (value.trim().match(/^[0-9]+/g) !== null)
                          break
                      }
                    }
                    return rule
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  methods:{
    closeTab () {
      this.commerceCardData.commerceCardTab.splice(this.indexTab, 1)
      this.validationCheck()
    },
    newTab (indexTab) {
      if(indexTab < 9){
        let tab = new CommerceCardTab('Card',[new CommerceCardText()], [new CommerceCardButton()])
        this.commerceCardData.commerceCardTab.push(tab)
        this.validationCheck()
        this.$nextTick(() => {
          this.indexTab = this.commerceCardData.commerceCardTab.length - 1
        })
      }
    },
    addCommerceCardButton(indexTab){
      if(this.commerceCardData.commerceCardTab[indexTab].tabCommerceCardButton.length < 3){
        this.commerceCardData.commerceCardTab[indexTab].tabCommerceCardButton.push(new CommerceCardButton())
        this.validationCheck()
      }
    },
    deleteCommerceCardButtons(indexBtn){
      this.commerceCardData.commerceCardTab[this.indexTab].tabCommerceCardButton.splice(indexBtn, 1)
      this.validationCheck()
    },
    inputValue(){
      for(let tab of this.commerceCardData.commerceCardTab){
        for(let cardText of tab.tabCommerceCardText){
          cardText.description = cardText.description.replace(illegalExp, "").trim()
          cardText.profile_imageUrl = cardText.profile_imageUrl.replace(illegalExp, "").trim()
          cardText.profile_nickName = cardText.profile_nickName.replace(illegalExp, "").trim()
          cardText.thumbnail_imageLink = cardText.thumbnail_imageLink.replace(illegalExp, "").trim()
          cardText.thumbnail_imageUrl = cardText.thumbnail_imageUrl.replace(illegalExp, "").trim()
        }
        for(let button of tab.tabCommerceCardButton){
          button.label = button.label.replace(illegalExp, "").trim()
          button.text = button.text.replace(illegalExp, "").trim()
        }
      }
      return this.commerceCardData
    },
    setValue(selectedResponseMessage){
      this.indexTab = 0
      this.commerceCardData = new CommerceCardData([new CommerceCardTab('Card',[new CommerceCardText()], [new CommerceCardButton()])])
      this.commerceCardData.commerceCardTab.splice(0,1)
      for(let i = 0; i < selectedResponseMessage.data.commerceCardTab.length; i++){
        this.commerceCardData.commerceCardTab.push(selectedResponseMessage.data.commerceCardTab[i])
      }
    },
    initializeValue(){
      this.indexTab = 0
      this.commerceCardData = new CommerceCardData([new CommerceCardTab('Card',[new CommerceCardText()], [new CommerceCardButton()])])
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadReplyTextThumbnailImageUrlFiles(index) {
      this.$refs.replyTextThumbnailImageUrlFiles[index].loadImages()
    }
  }
}
</script>
