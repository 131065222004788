import axios from 'axios'
import {I18n} from "../util/i18n"

class IntentSearchController {

  constructor(apiUrl, i18nContext, requestContext) {
    this.apiUrl = apiUrl
    this.i18n = new I18n(i18nContext)
    this.language = requestContext.languageCode
  }

  searchIntent(query) {
    return new Promise((resolve, reject) => {
      axios.get(this.apiUrl.IntentSearchList, {
        params: {
          search: query
        }
      }).then(response => {
        if("data" in response) {
          resolve(response.data)
        }
      }).catch(error => reject(error))
    })
  }

  /**
   * Detect locale language
   * @returns {*}
   */
  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }
}

export {
  IntentSearchController
}
