const languageMap = {
    'en': 'english',
    'ja': 'japanese',
    'ko': 'korean',
    'zh_cn': 'chineseSimplified',
    'zh_tw': 'chineseTraditional',
    'th': 'thai',
    'ru': 'russian',
    'vi': 'vietnamese',
    'id': 'indonesian',
    'uk': 'ukrainian',
    'ne': 'nepali',
    'bn': 'bengali',
    'fr': 'french',
    'es': 'spanish',
    'mn': 'mongolian',
    'my': 'burmese',
    'pt': 'portuguese',
    'pt_br': 'portugueseBrazil',
    'si': 'sinhala',
}

class LanguageParser {

    static code2lang(code) {
        try {
            return languageMap[code]
        } catch (e) {
            console.error('unknown code')
            return ''
        }
    }

    static lang2code(lang) {
        try {
            return Object.keys(languageMap).find(key => languageMap[key] === lang)
        } catch (e) {
            console.error('unknown language')
            return ''
        }
    }

    static fetchTranslationString(code) {
      const lang  = this.code2lang(code)
      if (lang) {
        return `languageSelector.${lang}`
      } else if (code === 'total') {
        return 'analytics.all_language'
      }
    }

}

export {
  LanguageParser
}
