/*
 * キーワード編集画面
 */
import Vue from 'vue'
import { UrlBuilder } from './util/url-builder'
import { API_URL } from './resource/urls'
import { KeywordEditorController } from './controller/keyword-editor'

import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import CategoryItem from './components/CategoryItem.vue'
import KeywordCategory from './components/KeywordCategory.vue'
import Keyword from './components/Keyword.vue'
import KeywordItem from './components/KeywordItem.vue'
import KeywordValue from './components/KeywordValue.vue'
import KeywordEditor from './components/KeywordEditor.vue'
import Synchronize from './components/Synchronize.vue'
import LanguageChangeKeyword from './components/LanguageChangeKeyword.vue'
import ModeChangeIntent from './components/ModeChangeIntent.vue'
import SuccessModal from './components/SuccessModal.vue'
import ModalFooter from "./components/ModalFooter.vue"
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue"
import Previewer from './components/Previewer.vue'
import PreviewIntent from './components/PreviewIntent.vue'
import PreviewKeyword from './components/PreviewKeyword.vue'
import PreviewKeywordCategoryItem from './components/PreviewKeywordCategoryItem.vue'
import ModalIntentItem from "./components/ModalIntentItem.vue"
import ChatBox from './components/ChatBox.vue'
import LanguageChangeChatWindow from "./components/LanguageChangeChatWindow.vue"
import IntentListInUseModal from "./components/IntentListInUseModal.vue"
import ErrorModalFooter from './components/ErrorModalFooter.vue'
import TestChat from './components/TestChat.vue'


export const appKeyword = (i18n) => {
  /* コントローラ */
  const controller = new KeywordEditorController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.language,
    window.requestContext.supportedLanguages,
    window.requestContext.userType,
    window.i18nContext,
    window.requestContext.excelExportUrl,
    window.requestContext.auto_input_predict,
    window.requestContext.test_chat_platform,
    window.requestContext.available_platform,
  )

  /* コンポーネント登録 */
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('category-item', CategoryItem)
  Vue.component('keyword-category', KeywordCategory )
  Vue.component('keyword', Keyword)
  Vue.component('keyword-item', KeywordItem)
  Vue.component('keyword-value', KeywordValue)
  Vue.component('keyword-editor', KeywordEditor)
  Vue.component('synchronize', Synchronize)
  Vue.component('language-change-keyword', LanguageChangeKeyword)
  Vue.component('mode-change-intent', ModeChangeIntent)
  Vue.component('success-modal', SuccessModal)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('error-modal-footer', ErrorModalFooter)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)
  Vue.component('previewer', Previewer)
  Vue.component('preview-intent', PreviewIntent)
  Vue.component('preview-keyword', PreviewKeyword)
  Vue.component('preview-keyword-category-item', PreviewKeywordCategoryItem)
  Vue.component('modal-intent-item', ModalIntentItem)
  Vue.component('chat-box', ChatBox)
  Vue.component('language-change-chat-window', LanguageChangeChatWindow)
  Vue.component('intent-list-inuse-modal', IntentListInUseModal)
  Vue.component('test-chat', TestChat)

  new Vue({
    el: '#app-keyword',
    components: {
      ProgressModal,
      ErrorModal,
      CategoryItem,
      KeywordCategory,
      Keyword,
      KeywordItem,
      KeywordValue,
      KeywordEditor,
      Synchronize,
      LanguageChangeKeyword,
      ModeChangeIntent,
    },
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
