<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
            <li class="page-item" :class="{disabled:showFirst}">
                <a @click="params.page=1" class="page-link cursor-pointer">
                    First
                </a>
            </li>
            <li class="page-item" :class="{disabled:showFirst}">
                <a @click="params.page--" class="page-link cursor-pointer">
                    Prev
                </a>
            </li>
            <li v-for="num in page_range" class="page-item"
                :class="{active:params.page === num, disabled: !Number.isInteger(num)}">
                <a @click="pageClick(num)" class="page-link cursor-pointer">{{ num }}</a>
            </li>
            <li class="page-item" :class="{disabled:showLast}">
                <a @click="params.page++" class="page-link cursor-pointer">
                    Next
                </a>
            </li>
            <li class="page-item" :class="{disabled:showLast}">
                <a @click="params.page=num_page" class="page-link cursor-pointer">
                    Last
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
            params: {
                type: Object,
                require: true
            },
            num_page: {
                type: Number,
                require: true
            },
            callback: {
                type: Function,
                require: true
            },
            isModal: {
                type: Boolean,
                require: false
            }
        },
        computed: {
            page_range: function () {
              return this.isModal ? this.minPaginate() : this.paginate()
            },
            showLast: function () {
                return this.params.page === this.num_page
            },
            showFirst: function () {
                return this.params.page === 1
            }
        },
        methods: {
            pageClick: function (cur_page) {
                if (cur_page !== this.params.page) {
                    this.params.page = cur_page
                }
            },
            paginate: function() {
                let left = 1,
                    right = this.num_page,
                    ar = []
                if (this.num_page >= 11) {
                    if (this.params.page > 5 && this.params.page < this.num_page - 4) {
                        left = this.params.page - 5
                        right = this.params.page + 4
                    } else {
                        if (this.params.page <= 5) {
                            left = 1
                            right = 10
                        } else {
                            right = this.num_page
                            left = this.num_page - 9
                        }
                    }
                }
                while (left <= right) {
                    ar.push(left)
                    left++
                }
                return ar
            },
            minPaginate: function() {
                let l
                const current = this.params.page,
                    last = this.num_page,
                    width = 1,
                    left = current - width,
                    right = current + width,
                    range = [],
                    rangeWithDots = []
                if(last > 1) {
                  range.push(1)
                }
                for (let i = left; i <= right; i++) {
                    if (i >= left && i < right && i < last && i > 1) {
                        range.push(i);
                    }
                }
                range.push(last);
                for (let i of range) {
                    if (l) {
                        if (i - l === 2) {
                            rangeWithDots.push(l + 1);
                        } else if (i - l !== 1) {
                            rangeWithDots.push('...');
                        }
                    }
                    rangeWithDots.push(i);
                    l = i;
                }
                return rangeWithDots
            }
        },
        watch: {
            params: {
                handler: function () {
                    this.callback()
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
.cursor-pointer{
    cursor: pointer;
}
</style>
