/**
 * Drag a DOMElement
 * @param element
 */
function DragMe(element, self, events) {
  let currentX = 0, currentY = 0, initialX = 0, initialY = 0
  initializeTouch() // activate mousedown or touch event for all devices
  if(element.children.length > 0) {
    /* if present, the header is where you move the DIV from:*/
    const childNode = element.children[0]
    childNode.firstElementChild.onmousedown = dragMouseDown
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    element.onmousedown = dragMouseDown
  }

  function dragMouseDown(e) {
    e = e || window.event
    // get the mouse cursor position at startup:
    initialX = e.clientX
    initialY = e.clientY
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = dragElement
  }

  function dragElement(e) {
    e = e || window.event
    e.preventDefault()
    let top = 0, left = 0
    const initScreenWidth = window.innerWidth || document.body.clientWidth
    const initScreenHeight = window.innerHeight || document.body.clientHeight
    const elWidth = element.offsetWidth || element.clientWidth
    const elHeight = element.offsetHeight || element.clientHeight
    // calculate the screen size:
    const screenHeight = initScreenHeight - elHeight
    const screenWidth = initScreenWidth - elWidth
    // calculate the new cursor position:
    currentX = initialX - e.clientX
    currentY = initialY - e.clientY
    initialX = e.clientX
    initialY = e.clientY
    // set the element's new position:
    if (element.offsetTop < 0) {
      top = 0 + 'px'
    } else if (screenHeight < element.offsetTop) {
      top = screenHeight + 'px'
    } else {
      top = (element.offsetTop - currentY) + 'px'
    }
    if (element.offsetLeft < 0) {
      left = 0 + 'px'
    } else if (screenWidth < element.offsetLeft) {
      left = screenWidth + 'px'
    } else {
      left = (element.offsetLeft - currentX) + 'px'
    }
    element.style.top = top
    element.style.left = left
    if(self != undefined && events != undefined) {
      if(events.ondrag != undefined) {
        self[events.ondrag].call(e)
      }
    }
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null
    document.onmousemove = null
  }

  function touchHandler(event) {
    const touch = event.changedTouches[0]

    const simulatedEvent = document.createEvent('MouseEvent')
    simulatedEvent.initMouseEvent({
        touchstart: 'mousedown',
        touchmove: 'mousemove',
        touchend: 'mouseup'
    }[event.type], true, true, window, 1,
      touch.screenX, touch.screenY,
      touch.clientX, touch.clientY, false, false,
      false, false, 0, null)

    touch.target.dispatchEvent(simulatedEvent)
    if(element.id === 'test-chat-btn' && event.type === 'touchmove') {
      event.preventDefault()
    }
  }

  function initializeTouch() {
    element.addEventListener('touchstart', touchHandler, true)
    element.addEventListener('touchmove', touchHandler, true)
    element.addEventListener('touchend', touchHandler, true)
  }
}

export {
  DragMe
}
