<template>
  <div class="answer-card">
    <div class="mb-1 scroll">
      <b-card>
        <!-- カード追加ボタン -->
        <b-button
          @click="newTab()"
          variant="outline-primary"
          :disabled="carouselCardData.tab.length >= 10"
          class="mb-1"
        >
          <b>+</b>
        </b-button>
        <!-- カード選択ボタン -->
        <b-nav pills class="mb-1">
          <draggable
            v-model="carouselCardData.tab"
            class="d-flex overflow-auto"
            @change="changeTab"
          >
            <b-nav-item
              :active="indexTab === index"
              @click="indexTab = index"
              v-for="(tab, index) in carouselCardData.tab"
              :key="index"
            >
              {{ $t('answer.typeCard') }}&nbsp;{{ index + 1 }}
            </b-nav-item>
          </draggable>
        </b-nav>
        <b-card>
          <!-- カード一枚ずつのデータ -->
          <b-tabs v-model="indexTab" nav-class="d-none">
            <b-tab v-for="(tab, index) in carouselCardData.tab" :key="index">
              <b-btn
                size="sm"
                variant="danger"
                class="float-right mb-1"
                @click="closeTab"
                :disabled="carouselCardData.tab.length === 1"
                :title="$t('answer.removeThisCard')"
              >
                <i class="fas fa-trash-alt"></i>
              </b-btn>
              <div @keypress.enter.prevent="">
                <div class="mt-1">{{ $t('answer.content') }}</div>
                <b-form-input
                  v-model="tab.title"
                  class="mb-1"
                  type="text"
                  :state="!$v.carouselCardData.tab.$each[index].title.$error"
                  :placeholder="$t('answer.enterCarouselTitle')"
                  @input="inputValue"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  v-if="$v.carouselCardData.tab.$each[index].title.$error"
                >
                  {{ $t('webhook.required') }} &
                  {{
                    $t('answer.maxTxtLen', {
                      counter:
                        $v.carouselCardData.tab.$each[index].title.$params
                          .maxLength.max
                    })
                  }}
                </b-form-invalid-feedback>
                <b-form-input
                  v-model="tab.subtitle"
                  class="mb-1"
                  type="text"
                  :placeholder="$t('answer.enterCarouselSubTitle')"
                  @input="inputValue"
                >
                </b-form-input>
              </div>
              <!-- カードボタン領域 -->
              <div>{{ $t('answer.cardButtons') }}</div>
              <draggable
                v-model="tab.cardbuttons"
                handle=".handler"
                @input="inputValue"
              >
                <div
                  v-for="(reply, indexBtn) in tab.cardbuttons"
                  :key="indexBtn"
                >
                  <div class="input-group mb-1">
                    <div class="card-input-div w-75">
                      <div class="card-button-title card-input">
                        <b-form-textarea
                          v-model="reply.text"
                          @input="validationCheck()"
                          maxlength="35"
                          :state="
                            !$v.carouselCardData.tab.$each[index].cardbuttons
                              .$each[indexBtn].text.$error
                          "
                          :placeholder="$t('answer.cardButtonTitle')"
                        />
                        <b-form-invalid-feedback
                          v-if="
                            $v.carouselCardData.tab.$each[index].cardbuttons
                              .$each[indexBtn].text.$error
                          "
                        >
                          {{ $t('webhook.required') }} &
                          {{
                            $t('answer.maxTxtLen', {
                              counter:
                                $v.carouselCardData.tab.$each[index].cardbuttons
                                  .$each[indexBtn].text.$params.maxLength.max
                            })
                          }}
                        </b-form-invalid-feedback>
                      </div>
                      <div class="card-button-postback card-input">
                        <b-form-input
                          v-model="reply.postback"
                          maxlength="1000"
                          :placeholder="$t('answer.cardButtonPostback')"
                        />
                      </div>
                    </div>
                    <div class="input-group-append">
                      <button
                        class="btn btn-danger"
                        :disabled="tab.cardbuttons.length === 1"
                        @click.prevent="deleteReplyCardButtons(indexBtn)"
                      >
                        <i class="fa fa-minus"></i>
                      </button>
                      <button
                        class="handler btn btn-success"
                        @click.stop.prevent=""
                      >
                        <i class="fas fa-arrows-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="mb-2">
                <button
                  class="btn btn-success"
                  @click.prevent="addCardButton(indexTab)"
                  :disabled="tab.cardbuttons.length >= 10"
                >
                  <i class="fa fa-plus"></i>
                </button>
                {{ tab.cardbuttons.length + ' / 10' }}
              </div>
            </b-tab>
          </b-tabs>
        </b-card>

        <b-form-checkbox
          v-model="carouselCardData.isScrollingCarousel"
          :disabled="false"
          class="mt-2"
        >
          {{ $t('intent.useScrollingCarousel') }}
        </b-form-checkbox>
      </b-card>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import {
  LineCarouselData,
  LineCarouselTab,
  CardButtonText
} from '../../model/intent'
import draggable from 'vuedraggable'
import { illegalExp } from '../../util/illegalRegex'
import { cloneDeep } from 'lodash'

export default {
  components: {
    draggable
  },
  props: [],
  data() {
    return {
      carouselCardData: new LineCarouselData(
        [new LineCarouselTab('', '', [new CardButtonText()])],
        false
      ),
      indexTab: 0
    }
  },
  validations() {
    return {
      carouselCardData: {
        tab: {
          $each: {
            title: {
              required,
              maxLength: maxLength(40)
            },
            cardbuttons: {
              $each: {
                text: {
                  required,
                  maxLength: maxLength(35)
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    changeTab(event) {
      this.indexTab = event.moved.newIndex
    },
    closeTab() {
      this.carouselCardData.tab.splice(this.indexTab, 1)
      this.validationCheck()
    },
    newTab() {
      if (this.carouselCardData.tab.length < 10) {
        let tab = new LineCarouselTab('', '', [new CardButtonText()])
        this.carouselCardData.tab.push(tab)
      }
      this.validationCheck()
    },
    addCardButton(indexTab) {
      if (this.carouselCardData.tab[indexTab].cardbuttons.length < 10) {
        this.carouselCardData.tab[indexTab].cardbuttons.push(
          new CardButtonText()
        )
      }
      this.validationCheck()
    },
    deleteReplyCardButtons(index) {
      this.carouselCardData.tab[this.indexTab].cardbuttons.splice(index, 1)
      this.validationCheck()
    },
    inputValue() {
      for (let tab of this.carouselCardData.tab) {
        tab.title = tab.title.replace(illegalExp, '').trim()
        tab.subtitle = tab.subtitle.replace(illegalExp, '').trim()
        for (let button of tab.cardbuttons) {
          button.text = button.text.replace(illegalExp, '').trim()
          button.postback = button.postback.replace(illegalExp, '').trim()
        }
      }
      this.validationCheck()
      return this.carouselCardData
    },
    setValue(selectedResponseMessage) {
      selectedResponseMessage = cloneDeep(selectedResponseMessage)
      this.carouselCardData = new LineCarouselData(
        [new LineCarouselTab('', '', [new CardButtonText()])],
        selectedResponseMessage.data.isScrollingCarousel
      )
      this.carouselCardData.tab.splice(0, 1)
      for (let i = 0; i < selectedResponseMessage.data.tab.length; i++) {
        this.carouselCardData.tab.push(selectedResponseMessage.data.tab[i])
      }
    },
    initializeValue() {
      this.carouselCardData = new LineCarouselData([
        new LineCarouselTab('', '', [new CardButtonText()])
      ])
      this.responseMessageCardTitle = []
      this.responseMessageCardButtonText = []
    },
    /**
     * 回答内容が正しいかどうかを親コンポネントに渡す。
     * 回答の内容が変更されるたびに送信される。
     */
    validationCheck() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    }
  }
}
</script>
