<template>
  <div class="col-md-4">
    <div
      class="p-1 d-flex align-items-center"
      v-b-toggle.sp_detail_collapse
      @click="folded = !folded"
    >
      <span v-show="folded"><i class="mr-2 fas fa-caret-down"></i></span>
      <span v-show="!folded"><i class="mr-2 fas fa-caret-up"></i></span>
      <platform-icon :platform="session?.platform" />
      <small class="ml-1 font-weight-bold">{{ userMeta?.fullName || session.sessionId.slice(-8) }}</small>
    </div>
    <b-collapse v-if="session" id="sp_detail_collapse" class="border">
      <!-- ユーザー情報 -->
      <div>
        <div class="d-flex align-items-center mb-1 py-1 section-label">
          <span class="ml-2 section-label-title">
            <i class="far fa-address-card mr-1"></i>
            {{ $t('history.UserInformation') }}
          </span>
        </div>
        <div class="pb-2 px-2 overflow-auto text-nowrap">
          <!-- セッションID -->
          <div class="d-flex align-items-center">
            <span class="mr-1 section-btn-text">{{ $t('history.SessionID') }}:</span>
            <b-badge
              pill
              variant="info"
              class="badge-width"
              style="font-size: 85%"
            >{{ session.sessionId }}</b-badge>
          </div>
          <!-- ユーザーメタ -->
          <div v-if="userMeta">
            <div
              v-for="(field, fidx) in userMetaFields" :key="fidx"
              v-if="userMeta[field.key]"
              class="mt-1 d-flex align-items-center"
            >
              <span class="mr-1 section-btn-text">{{ field.label }}:</span>
              <b-badge
                pill
                variant="info"
                class="badge-width"
                style="font-size: 85%"
              >{{ userMeta[field.key] }}</b-badge>
            </div>
          </div>
        </div>
      </div>
      <!-- ブックマーク -->
      <div class="mt-1">
        <div class="d-flex align-items-center justify-content-between py-1 section-label">
          <span class="ml-2 section-label-title">
            <i class="far fa-bookmark mr-1"></i>
            {{ $t('history.Bookmark') }}
          </span>
          <b-button
            variant="primary"
            size="sm"
            class="d-flex align-itmes-center mr-2 p-1"
            @click="$emit('on-click-toggle-bookmark')"
            v-b-tooltip.hover
            :title="bookmarkBtnText"
          >
            <i class="fas fa-bookmark mr-1 section-btn-icon"></i>
            <span class="section-btn-text">{{ bookmarkBtnText }}</span>
          </b-button>
        </div>
        <b-badge
          pill
          :variant="isBookmarkSetting? 'success': 'info'"
          class="mt-1 ml-2" style="font-size: 85%"
        >{{ isBookmarkSetting? $t('history.Bookmarked'): $t('history.NotBookmarked') }}</b-badge>
      </div>
      <!-- 対応ステータス -->
      <div class="mt-1">
        <div class="d-flex align-items-center justify-content-between py-1 section-label">
          <span class="ml-2 section-label-title">
            <i class="fas fa-headset mr-1"></i>
            {{ $t('history.OperatorStatus') }}
          </span>
          <!-- オペレーター対応ステータス変更用ショートカットボタン -->
          <b-button
            variant="primary"
            size="sm"
            class="d-flex align-itmes-center mr-2 p-1"
            :disabled="session.status === operatorStatus.INACTIVE"
            v-b-modal="'operator-status-change-confirm-modal'"
            v-b-tooltip.hover
            :title="$t('history.ChangeTheStatusOfOperatorSession')"
          >
            <i :class="`${ operatorHandlingSessionStatusIcon } mr-1 section-btn-icon`"></i>
            <span class="section-btn-text">{{ $t('history.Change') }}</span>
          </b-button>
        </div>
        <b-badge
          pill
          :variant="operatorHandlingSessionStatusBadgeColor"
          class="mt-1 ml-2"
          style="font-size: 85%"
        >{{ operatorHandlingSessionStatusText }}</b-badge>
      </div>
      <!-- 担当者情報 -->
      <div class="mt-1">
        <div class="d-flex align-items-center justify-content-between py-1 section-label">
          <span class="ml-2 section-label-title">
            <i class="far fa-user mr-1"></i>
            {{ $t('history.Operator') }}
          </span>
          <b-button
            variant="primary"
            size="sm"
            class="d-flex align-itmes-center mr-2 p-1"
            v-b-modal="'operator-setting-modal'"
            v-b-tooltip.hover
            :title="$t('history.ChangeOperator')"
          >
            <i class="fas fa-user-edit mr-1 section-btn-icon"></i>
            <span class="section-btn-text">{{ $t('history.Change') }}</span>
          </b-button>
        </div>
        <b-badge
          pill
          variant="info"
          class="ml-2"
          style="font-size: 85%"
        >{{ operatorName || $t('history.NotSet') }}</b-badge>
      </div>
      <!-- リンク -->
      <div class="mt-1">
        <div class="d-flex align-items-center justify-content-between py-1 section-label">
          <span class="ml-2 section-label-title">
            <i class="fas fa-link mr-1"></i>
            {{ $t('history.Link') }}
          </span>
          <b-button
            variant="primary"
            size="sm"
            class="d-flex align-itmes-center mr-2 p-1"
            @click="copyLink"
            v-b-tooltip.hover
            :title="$t('history.CopyTheLinkOfThisOperatorSession')"
          >
            <i class="fas fa-copy mr-1 section-btn-icon"></i>
            <span class="section-btn-text">{{ $t('history.CopyURL') }}</span>
          </b-button>
        </div>
        <div class="pb-1 px-2 overflow-auto text-nowrap">
          <b-link disabled style="font-size: 75%">{{ linkUrl }}</b-link>
        </div>
      </div>

      <!-- ノート -->
      <div class="mt-1">
        <div class="d-flex align-items-center justify-content-between py-1 section-label">
          <span class="ml-2 section-label-title">
            <i class="far fa-sticky-note mr-1"></i>
            {{ $t('history.Note') }}
          </span>
          <b-button
            v-b-modal="'edit-note-modal'"
            variant="primary"
            size="sm"
            class="d-flex align-itmes-center mr-2 p-1"
          >
            <i class="fas fa-edit mr-1 section-btn-icon"></i>
            <span class="section-btn-text">{{ $t('history.Edit') }}</span>
          </b-button>
        </div>
        <div class="p-2">
          <b-card style="min-height: 50px">
            <b-card-text class="ml-2" v-if="session.note">{{ session.note }}</b-card-text>
            <b-card-text class="ml-2" v-else>{{ $t('history.ThereIsNoNote') }}</b-card-text>
          </b-card>
        </div>
      </div>

      <!-- 履歴 -->
      <div>
        <div class="d-flex align-items-center py-1 section-label">
          <span class="ml-2 section-label-title">
            <i class="fas fa-history mr-1"></i>
            {{ $t('history.History') }}
          </span>
        </div>
        <div
          v-if="selectedOperatorHandlingSessionEventLogs && selectedOperatorHandlingSessionEventLogs.length > 0"
          class="mx-2 mt-2"
        >
          <b-table-simple
            small
            bordered
            class="mb-2"
            style="font-size: 0.8rem"
          >
            <b-thead>
              <b-tr>
                <b-th class="text-center">{{ $t('history.Date') }}</b-th>
                <b-th class="text-center">{{ $t('history.Event') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(eventLog, eidx) in selectedOperatorHandlingSessionEventLogs" :key="eidx">
                <b-td class="text-center">{{ eventLog.createdAt | moment('YYYY-MM-DD HH:mm') }}</b-td>
                <b-td class="text-left">{{ getEventLogContent(eventLog) }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { operatorStatus } from '../../util/operatorStatus'
import { operatorHandlingSessionStatusData } from '../../resource/operator-handling-session-status'
import copyToClipboard from "../../util/copy-url"

export default {
  name: 'DetailSection',
  props: [
    'userMeta',
    'operatorName',
    'session',
    'bookmarks',
    'selectedOperatorHandlingSessionEventLogs',
  ],
  data() {
    return {
      folded: true,
      userMetaFields: [
        { key: 'fullName', label: '名前' },
        {
          key: 'profileUrl',
          label: 'プロフィールURL',
        },
        { key: 'email', label: 'Eメール' },
      ],
      operatorStatus,
    }
  },
  computed: {
    /** ブックマークの状態を取得 */
    isBookmarkSetting() {
      return this.bookmarks.find(b => b.operatorHandlingSessionId === this.session.id) ? true: false
    },
    /** ブックマークボタンのテキストを取得 */
    bookmarkBtnText() {
      return this.bookmarks.find(b => b.operatorHandlingSessionId === this.session.id)
        ? this.$t('history.DeleteBookmark')
        : this.$t('history.Bookmark')
    },
    /** ステータスバッジのテキストを取得 */
    operatorHandlingSessionStatusText() {
      const statusData = Object.values(
        operatorHandlingSessionStatusData
      ).find((data) => data.value === this.session.status)

      if (!statusData) {
        return ""
      }

      const value = statusData.value

      if (value === operatorStatus.CALLING) {
        return this.$t('history.Calling')
      } else if (value === operatorStatus.ACTIVE) {
        return this.$t('history.OperatoSessionActive')
      } else if (value === operatorStatus.INACTIVE) {
        return this.$t('history.Finished')
      }
    },
    /** ステータスバッジの対応状態に応じて色を設定 */
    operatorHandlingSessionStatusBadgeColor() {
      if(this.session.status === operatorStatus.ACTIVE) {
        // 対応中
        return 'warning'
      }
      if(this.session.status === operatorStatus.INACTIVE) {
        // 対応済み
        return 'success'
      }
      if(this.session.status === operatorStatus.CALLING) {
        // 呼び出し中(未対応)
        return 'danger'
      }
      return ''
    },
    linkUrl() {
      let url = location.toString().replace(location.search, '')
      url = `${url}?ohsid=${this.session.id}`
      return url
    },
    /** 現在のstatusの表示用アイコンを取得 */
    operatorHandlingSessionStatusIcon() {
      if(this.session.status === operatorStatus.ACTIVE) {
        return operatorHandlingSessionStatusData.INACTIVE.faIcon
      }
      if(this.session.status === operatorStatus.CALLING) {
        return operatorHandlingSessionStatusData.ACTIVE.faIcon
      }
      if(this.session.status === operatorStatus.INACTIVE) {
        return operatorHandlingSessionStatusData.INACTIVE.faIcon
      }
      return ""
    },
  },
  methods: {
    /** クリップボードにオペレーター対応セッションのパーマリンクをコピーするための確認モーダル */
    copyLink() {
      let url = location.toString().replace(location.search, '')
      url = `${url}?ohsid=${this.session.id}`
      copyToClipboard(url)
      alert(this.$t("history.CopyTheLinkOfThisOperatorSession"))
    },
    /** イベント内容を取得 */
    getEventLogContent(eventLog) {
      if(eventLog.eventType === 1) {
        return this.$t('history.UserCalledOperator')
      }
      else if(eventLog.eventType === 2) {
        if(eventLog.detail.handling_operator_user_id) {
          return `${eventLog.detail.handling_operator_user_name} ${this.$t('history.startedOperatorSession')}`
        }
        else {
          return this.$t('history.startedOperatorSession')
        }
      }
      else if(eventLog.eventType === 3) {
        if(eventLog.detail.is_user) {
          return this.$t('history.UserFinishedOperatorSession')
        } else if(eventLog.detail.handling_operator_user_id) {
          return `${eventLog.detail.handling_operator_user_name}${this.$t('history.finishedOperatorSession')}`
        } else {
          return this.$t('history.finishedOperatorSession')
        }
      }
      else if(eventLog.eventType === 4) {
        const operatorUserName = eventLog.detail.operator_user_name || this.$t('history.NotSet')
        const changeExecuteUserName = eventLog.detail.change_execute_user_name
        if(changeExecuteUserName) {
          return this.$t('history.changedOperator', {
            changeExecuteUserName,
            operatorUserName
          })
        } else {
          return this.$t('history.setOperator', {
            operatorUserName
          })
        }
      }
      else {
        return ""
      }
    }
  }
}
</script>

<style scoped>
  .section-label {
    background-color: #dadfe6;
    border-bottom: #c8cbcf;
  }
  .section-label-title {
    font-size: 0.9rem;
  }
  .section-btn-icon {
    font-size: 0.65rem
  }
  .section-btn-text {
    line-height: 0.75;
    font-size: 0.9rem
  }
  .badge-width {
    width: fit-content;
  }
  .tooltip,
  .tooltip-inner {
    display: none !important; 
  }
</style>
