/**
 * リストからマッチングした対象を全て削除する
 */
const arrayRemoveItems = (list, items, checkKey=null) => {
  const array = list.concat()
  items.forEach(item => {
    let index = array.findIndex(i => checkKey ? i[checkKey] === item[checkKey] : i === item)
    while (index > -1) {
      array.splice(index, 1)
      index = array.findIndex(i => checkKey ? i[checkKey] === item[checkKey] : i === item)
    }
  })
  return array
}

export {
  arrayRemoveItems
}
