<template>
    <div v-if="controller.secretKey" class="page-input-completion">
      <div>
        <b-button variant="primary" class="flex-item-center mb-2" :disabled="isTransferring" @click="startTransfer">
          {{ $t('inputCompletion.transfer')}}
        <b-spinner small label="Spinning" v-show="isTransferring"></b-spinner>
        </b-button>
        <b-alert
          v-if="transportResult.message"
          fade
          :show="3"
          dismissible
          :variant="transportResult.type"
          @dismissed="resetMessage"
          class="stick-to-bottom"
        >
          {{ transportResult.message }}
        </b-alert>
      </div>
      <div class="row ml-1 mr-1">
        <div class="col-2 column border p-2">
          {{ $t('apiService.authenticationKey') }}
        </div>
      </div>
      <div class="row ml-1 mr-1">
        <div class="col-2 border p-2">
          {{ controller.secretKey }}
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t('inputCompletion.inputCompletionUnavailable') }}
    </div>
</template>

<script>
import csrfToken from '../util/csrf-token'
  export default {
    props: ['controller'],
    data () {
      return {
        isTransferring: false,
        transportResult: {
          message: null,
          type: null
        }
      }
    },
    computed: {
      successMessage() {
        return (count) => this.$t('inputCompletion.transferSucceeded') + count
      }
    },
    methods: {
      async startTransfer () {
        this.isTransferring = true
        try {
          const result = await this.controller.startTransfer(csrfToken.getCsrfTokenFromCookie(document.cookie))
          this.transportResult.message = this.successMessage(result.data)
          this.transportResult.type = 'success'
        } catch (e) {
          this.transportResult.message =  e.response.data instanceof Array ? e.response.data[0] : e.response.data.detail
          this.transportResult.type = 'danger'
        }
        this.isTransferring = false
      },
      resetMessage () {
        this.transportResult = {
          message: null,
          type: null
        }
      }
    }
  }
</script>

<style scoped>
  .flex-item-center {
    display: flex;
    align-items: center;
  }
  .stick-to-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
  }
</style>
