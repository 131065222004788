<template>
  <div class="container-fluid">
    <div class="row mb-1">
      <b-button @click="createSessionUserMeta" variant="success">
        {{ $t('buttons.add') }}
      </b-button>
      <b-button @click="openImportModal" variant="primary ml-1">
        <i class="fas fa-file-upload"></i>
        {{ $t('buttons.import') }}
      </b-button>
      <b-button @click="fileExport('CSV')" variant="info ml-1">
        <i class="fas fa-file-csv"></i>
        {{ $t('history.csvBtn') }}
      </b-button>
      <b-button @click="fileExport('Excel')" variant="success ml-1">
        <i class="fas fa-file-excel"></i>
        {{ $t('history.excelBtn') }}
      </b-button>
      <b-button @click="fileExport('template')" variant="secondary ml-1">
        <i class="fas fa-file-download"></i>
        {{ $t('userMeta.templateExport') }}
      </b-button>
      <b-button href="/user_meta/edit-schema" variant="success ml-1">
        {{ $t('userMeta.schemaEdit') }}
      </b-button>
    </div>
    <div class="row">
      <div class="col-md-2 pl-0">
        <input
          type="text"
          class="form-control"
          :placeholder="$t('userMeta.fullName')"
          v-model="nameFilter"
        />
      </div>
      <div class="col-md-2 pl-0">
        <input
          type="text"
          class="form-control"
          :placeholder="$t('userMeta.email')"
          v-model="emailFilter"
        />
      </div>
      <div class="col-md-2 pl-0">
        <input
          type="text"
          class="form-control"
          :placeholder="$t('history.sessionPlaceholder')"
          v-model="sessionFilter"
        />
      </div>
      <div class="col-md-1 pl-0">
        <button @click="search" class="btn btn-success">
          {{ $t('history.search') }}
        </button>
      </div>
      <div class="col-md-5">
        <div class="row justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="session-user-meta-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <div class="row">
      <b-table
        id="session-user-meta-table"
        striped
        responsive
        :items="controller.allSessionUserMeta"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(profileUrl)="row">
          <a
            :href="row.item['profileUrl']"
            v-b-tooltip.left="row.item['profileUrl']"
          >
            {{
              row.item['profileUrl'].length > 30
                ? row.item['profileUrl'].slice(0, 30) + '...'
                : row.item['profileUrl']
            }}
          </a>
        </template>

        <template #cell(session_data)="row">
          <ul class="list-unstyled align-middle mb-0">
            <li
              v-for="field in row.item.validSessionFields"
              v-b-tooltip.left="row.item[field]"
            >
              {{ $t(`userMeta.${field}`) }}:
              {{
                row.item[field].length > 15
                  ? row.item[field].slice(0, 15) + '...'
                  : row.item[field]
              }}
            </li>
            <li
              v-for="dt in row.item.displayedFreeData"
              v-b-tooltip.left="`${dt.name}: ${dt.value}`"
            >
              {{ dt.name }}: {{ dt.value }}
            </li>
          </ul>
        </template>
        <template #cell(actions)="row">
          <b-button
            size="sm"
            class="mr-1"
            @click="editSessionUserMeta(row.item)"
            variant="success"
          >
            {{ $t('buttons.edit') }}
          </b-button>
          <b-button
            size="sm"
            class="mr-1"
            @click="deleteSessionUserMeta(row.item)"
            variant="danger"
          >
            {{ $t('buttons.delete') }}
          </b-button>
        </template>
      </b-table>
    </div>
    <b-modal
      :title="`${$t('userMeta.userMeta')}${$t('buttons.edit')}`"
      ref="sessionUserMetaModal"
      no-close-on-backdrop
      header-bg-variant="success"
      header-text-variant="light"
      @ok="saveSessionUserMeta"
      @cancel="resetSessionUserMeta"
      :ok-disabled="!modalSessionUserMeta.isValid"
      @hidden="modalHidden"
    >
      <template v-for="field in modalSessionUserMeta.formData">
        <div>
          <b-form-group
            label-cols="4"
            label-size="sm"
            :label="$t(`userMeta.${field.fieldName}`)"
            :label-for="fieldInputName(field.fieldName)"
            class="mb-2"
          >
            <b-form-input
              :id="fieldInputName(field.fieldName)"
              v-model="modalSessionUserMeta[field.fieldName]"
              :state="field.validator"
              size="sm"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </template>
      <div>
        <b-form-group
          label-cols="4"
          label-size="sm"
          :label="$t(`userMeta.freeData`)"
          :label-for="fieldInputName('freeData')"
        >
          <b-form-textarea
            :id="fieldInputName('freeData')"
            v-model="modalSessionUserMeta.freeDataText"
            :state="modalSessionUserMeta.isFreeDataValid"
            rows="8"
            max-rows="8"
          >
          </b-form-textarea>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal
      :title="`${$t('userMeta.userMeta')}${$t('buttons.delete')}`"
      ref="destroySessionUserMetaModal"
      no-close-on-backdrop
      header-bg-variant="danger"
      header-text-variant="light"
      :ok-title="$t('buttons.delete')"
      ok-variant="danger"
      @ok="destroySessionUserMeta"
    >
      <div v-if="destroyedSessionUserMeta">
        <template v-for="field in destroyedSessionUserMeta.formData">
          <hr />
          <span
            >{{ $t(`userMeta.${field.fieldName}`) }}:
            {{ destroyedSessionUserMeta[field.fieldName] }}</span
          >
        </template>
      </div>
    </b-modal>
    <!--Import modal start-->
    <b-modal
      :title="$t('userMeta.importFromExcel')"
      ref="ImportUserMetaFromExcelModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <b-form @submit="">
        <b-form-file
          id="file"
          name="file"
          v-model="file"
          ref="file"
          :state="Boolean(file)"
          :placeholder="$t('category.chooseOrDropFileHere')"
          :drop-placeholder="$t('category.dropFileHere')"
          :accept="fileTypes"
        ></b-form-file>
        <b-form-checkbox
          v-model="deleteAllExistingData"
          :disabled="overwriteExistingData"
        >
          {{ $t('userMeta.deleteExistingData') }}
        </b-form-checkbox>
        <b-form-checkbox
          v-model="overwriteExistingData"
          :disabled="deleteAllExistingData"
        >
          {{ $t('userMeta.overwriteExistingData') }}
        </b-form-checkbox>
        <div v-if="overwriteExistingData">
          <div :title="$t('userMeta.matchColumnHelpText')">
            {{ $t('userMeta.matchColumn') }}&nbsp;<i
              class="fas fa-info-circle"
            ></i>
          </div>
          <b-form-select
            v-model="selectedOverwriteField"
            :options="overwriteOptions"
          ></b-form-select>
        </div>
      </b-form>
      <div v-if="file" class="text-dark">
        <div v-if="excelRows === 0">
          {{ $t('userMeta.row', { row: excelRows }) }}
        </div>
        <div v-if="excelRows !== 0">
          {{ $t('userMeta.rows', { row: excelRows }) }}
        </div>
      </div>
      <div slot="modal-footer">
        <b-btn
          variant="primary"
          :disabled="!file || !excelRows"
          @click="submitFile"
        >
          {{ $t('fileManagement.upload') }}
        </b-btn>
        <b-btn variant="secondary" @click="importUserMetaFromExcelCancel">
          {{ $t('buttons.cancel') }}
        </b-btn>
      </div>
    </b-modal>
    <error-modal ref="errorModal" :message="alertMessage"> </error-modal>
    <progress-modal ref="progressModal" :message="message"> </progress-modal>
  </div>
</template>

<script>
import { SessionUserMeta, userMetaPlatformsData } from '../model/user-meta'
import csrfToken from '../util/csrf-token'
import { cloneDeep } from 'lodash'
import XLSX from 'xlsx'

export default {
  props: ['controller'],
  data() {
    return {
      nameFilter: '',
      emailFilter: '',
      sessionFilter: '',
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'fullName',
          label: this.$t('userMeta.fullName'),
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('userMeta.email'),
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'userId',
          label: this.$t('userMeta.userId'),
          tdClass: 'align-middle',
        },
        {
          key: 'profileUrl',
          label: this.$t('userMeta.profileUrl'),
          tdClass: 'align-middle',
        },
        {
          key: 'tel',
          label: 'Tel',
          tdClass: 'align-middle',
        },
        { key: 'session_data', label: 'Session Data', tdClass: 'align-middle' },
        { key: 'actions', label: '', tdClass: 'align-middle' },
      ],
      modalSessionUserMeta: new SessionUserMeta({}),
      destroyedSessionUserMeta: null,
      file: null,
      overwriteExistingData: false,
      deleteAllExistingData: false,
      overwriteOptions: [
        { value: 'full_name', text: this.$t('userMeta.fullName') },
        { value: 'email', text: this.$t('userMeta.email') },
        { value: 'user_id', text: this.$t('userMeta.userId') },
        { value: 'profile_url', text: this.$t('userMeta.profileUrl') },
        { value: 'tel', text: this.$t('userMeta.tel') }
      ].concat(
        userMetaPlatformsData.map((platform) => {
          return {
            value: platform.serverFieldName,
            text: platform.fieldName,
          }
        })
      ),
      selectedOverwriteField: 'full_name',
      alertMessage: null,
      message: null,
      fileTypes: '.xls, .xlsx',
      excelRows: 0,
    }
  },
  computed: {
    fieldInputName() {
      return (field) => {
        return `modal-input-${field}`
      }
    },
    totalRows() {
      return this.controller.allSessionUserMeta.length
    },
  },
  methods: {
    createSessionUserMeta() {
      this.showModal()
    },
    editSessionUserMeta(sessionUserMeta) {
      this.modalSessionUserMeta = cloneDeep(sessionUserMeta)
      this.showModal()
    },
    showModal() {
      this.$refs.sessionUserMetaModal.show()
    },
    saveSessionUserMeta() {
      this.controller.saveSessionUserMeta(
        this.modalSessionUserMeta,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    resetSessionUserMeta() {
      this.modalSessionUserMeta.reset()
    },
    modalHidden() {
      this.modalSessionUserMeta = new SessionUserMeta({})
    },
    deleteSessionUserMeta(sessionUserMeta) {
      this.destroyedSessionUserMeta = sessionUserMeta
      this.showDestroyModal()
    },
    showDestroyModal() {
      this.$refs.destroySessionUserMetaModal.show()
    },
    destroySessionUserMeta() {
      this.controller.destroySessionUserMeta(
        this.destroyedSessionUserMeta,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    showErrorModal(message) {
      this.alertMessage = message
      this.$refs.errorModal.show()
    },
    showProgress(message) {
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    openImportModal() {
      this.file = null
      this.overwriteExistingData = false
      this.deleteAllExistingData = false
      this.selectedOverwriteField = 'full_name'
      this.excelRows = 0
      this.$refs.ImportUserMetaFromExcelModal.show()
    },
    importUserMetaFromExcelCancel() {
      this.file = null
      this.overwriteExistingData = false
      this.deleteAllExistingData = false
      this.selectedOverwriteField = 'full_name'
      this.excelRows = 0
      this.$refs.ImportUserMetaFromExcelModal.hide()
    },
    submitFile() {
      if (this.file) {
        const confirmResult = window.confirm(
          this.$t('userMeta.confirm') +
            '\n' +
            this.$t('userMeta.file') +
            ': ' +
            this.file.name
        )
        if (!confirmResult) {
          return
        }
        this.$refs.ImportUserMetaFromExcelModal.hide()
        this.showProgress(this.$t('general.saving'))
        const form = new FormData()
        form.append('file', this.file)
        if (this.deleteAllExistingData) {
          form.append('is_delete', this.deleteAllExistingData)
        }
        if (this.overwriteExistingData) {
          form.append('is_overwrite', this.overwriteExistingData)
          form.append('match_column', this.selectedOverwriteField)
        }
        this.controller
          .uploadFile(form)
          .then((response) => {
            this.hideProgress()
            this.controller.loadAll()
          })
          .catch((error) => {
            this.hideProgress()
            this.showErrorModal(this.$t('userMeta.uploadFail'))
            console.log(error)
          })
      }
    },
    fileExport(type) {
      const confirmResult = window.confirm(
        this.$t('userMeta.exportConfirm', { type: type })
      )
      if (!confirmResult) {
        return
      }
      this.controller.fileExport(type)
    },
    search() {
      let params = {}
      if (this.nameFilter.length > 0) {
        params['user_name'] = this.nameFilter
      }
      if (this.emailFilter.length > 0) {
        params['user_email'] = this.emailFilter
      }
      if (this.sessionFilter.length > 0) {
        params['session_id'] = this.sessionFilter
      }
      this.controller.loadAll(params)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.controller.ready()
    })
  },
  watch: {
    file: function () {
      // MIME types in JS
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      const excelMimeTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ]
      if (this.file) {
        const fileType = this.file.type
        if (excelMimeTypes.includes(fileType)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const data = e.target.result
            const workbook = XLSX.read(data, {
              type: 'binary',
            })
            let sheet_names = workbook.SheetNames
            if (sheet_names.length) {
              let rows = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[sheet_names[0]]
              ).length
              if (rows > 0) {
                this.excelRows = rows
              } else {
                this.excelRows = 0
              }
            } else {
              this.file = null
              this.showErrorModal(this.$t('userMeta.noSheet'))
            }
          }
          reader.readAsBinaryString(this.file)
        } else {
          this.file = null
          this.showErrorModal(this.$t('fileManagement.invalidFilesAlert'))
        }
      }
    },
  },
}
</script>
