import Vue from "vue";
import { UrlBuilder } from "./util/url-builder";
import { API_URL } from "./resource/urls";
import ScenarioEditorController from './controller/scenario-editor'

import ScenarioList from './components/ScenarioList.vue'
import ScenarioItem from './components/ScenarioItem.vue'
import IntentContext from './components/IntentContext.vue'
import IntetnContextDetail from './components/IntentContextDetail.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import ModeChangeScenario from './components/ModeChangeScenario.vue'
import Synchronize from './components/Synchronize.vue'
import ScenarioEditor from "./components/ScenarioEditor.vue"
import ScenarioDiagram from "./components/ScenarioDiagram.vue"
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue"
import SuccessModal from "./components/SuccessModal.vue"
import IntentDetail from "./components/IntentDetail.vue"
import IntentDetailAnswer from "./components/IntentDetailAnswer.vue"
import UserSaysSearchModal from "./components/UserSaysSearchModal.vue"
import CodeEditor from "./components/CodeEditor.vue"
import CompareResponseMessage from "./components/CompareResponseMessage.vue"
import CompareIntentModal from "./components/CompareIntentModal.vue"
import MultilanguageUserSay from "./components/MultilanguageUserSay.vue"
import UserSaysSearchResult from "./components/UserSaysSearchResult.vue"
import UserSaysSearchResultItem from "./components/UserSaysSearchResultItem.vue"
import LanguageChangeScenario from "./components/LanguageChangeScenario.vue"
import ModalFooter from "./components/ModalFooter.vue"
import AddButton from "./components/buttons/AddButton.vue"
import DeleteButton from "./components/buttons/DeleteButton.vue"
import ChatBox from './components/ChatBox.vue'
import LanguageChangeChatWindow from "./components/LanguageChangeChatWindow.vue"
import SelectPlatformServiceModal from "./components/SelectPlatformServiceModal.vue"
import ErrorModalFooter from './components/ErrorModalFooter.vue'
import TestChat from './components/TestChat.vue'
import FormTags from './components/common/FormTags.vue'
import WizardModal from './components/WizardModal.vue'
import { BasicSelect } from 'vue-search-select'


export const appScenarioEditor = (i18n) => {
  /* コントローラ */
  const controller = new ScenarioEditorController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.userType,
    window.i18nContext,
    window.requestContext.scenarioId,
    window.requestContext.supportedLanguages,
    window.requestContext.language,
    window.requestContext.intentName,
    window.requestContext.available_platform,
    window.requestContext.default_platform,
    window.requestContext.auto_input_predict,
    window.requestContext.test_chat_platform,
  )

  Vue.component('ScenarioList', ScenarioList)
  Vue.component('ScenarioItem', ScenarioItem)
  Vue.component('intent-context', IntentContext)
  Vue.component('intent-context-detail', IntetnContextDetail)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('mode-change-scenario', ModeChangeScenario)
  Vue.component('scenario-editor', ScenarioEditor)
  Vue.component('scenario-diagram', ScenarioDiagram)
  Vue.component('basic-select', BasicSelect)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)
  Vue.component('synchronize', Synchronize)
  Vue.component('success-modal', SuccessModal)
  Vue.component('intent-detail', IntentDetail)
  Vue.component('intent-detail-answer', IntentDetailAnswer)
  Vue.component('user-says-search-modal', UserSaysSearchModal)
  Vue.component('compare-response-message', CompareResponseMessage)
  Vue.component('code-editor', CodeEditor)
  Vue.component('compare-intent-modal', CompareIntentModal)
  Vue.component('multilanguage-user-say', MultilanguageUserSay)
  Vue.component('user-says-search-result', UserSaysSearchResult)
  Vue.component('user-says-search-result-item', UserSaysSearchResultItem)
  Vue.component('language-change-scenario', LanguageChangeScenario)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('add-button', AddButton)
  Vue.component('delete-button', DeleteButton)
  Vue.component('chat-box', ChatBox)
  Vue.component('language-change-chat-window', LanguageChangeChatWindow)
  Vue.component('select-platform-service-modal', SelectPlatformServiceModal)
  Vue.component('error-modal-footer', ErrorModalFooter)
  Vue.component('test-chat', TestChat)
  Vue.component('form-tags', FormTags)
  Vue.component('wizard-modal', WizardModal)
  Vue.directive('focus', {
    inserted: function (el) {
      // 要素にフォーカスを当てる
      el.focus()
    }
  })

  new Vue({
    el: '#app-scenario-editor',
    components: {
      ScenarioList,
      ScenarioItem,
      IntentContext,
      IntetnContextDetail,
      ProgressModal,
      ErrorModal,
      ModeChangeScenario,
      ScenarioEditor,
      ScenarioDiagram,
      BasicSelect,
      DeleteConfirmModal,
      SelectPlatformServiceModal,
      WizardModal
    },
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
