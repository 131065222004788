<template>
  <ul class="list-group">
    <li v-for="(userSay, userSayIndex) in userSays"
        class="list-group-item d-flex justify-content-between align-items-center">
      <user-says-search-result-item
            :selectedOptions="selectedOptions"
            :userSay="userSay"
            :text="userSay.text"
            :searchedString="searchedString"
            :userType="userType"
            @on-user-say-checked="OnCheck">
      </user-says-search-result-item>
    </li>
  </ul>
</template>

<script>

  export default {
    props: [
      'userSays',
      'searchCheckboxOptions',
      'selectedOptions',
      'searchedString',
      'userType',
      'language'
    ],
    data() {
      return {}
    },
    methods: {
      OnCheck(userSayId) {
        this.$emit('on-user-say-checked', userSayId)
      }
    },
  }
</script>
