function colorFromString(text) {
  let value = 0xFFFFFF
  // シフトするビット数は飛ばし飛ばしにしないと似たような色ばかりになる
  const shiftArray = [0, 4, 9, 14, 20]
  let shiftArrayPostion = 0
  for (let position = 0; position < text.length; position++) {
    if (shiftArrayPostion >= shiftArray.length) {
      shiftArrayPostion = 0
    }
    // ビットシフトした値でXORをとる
    value ^= text.charCodeAt(position) << shiftArray[shiftArrayPostion]
    shiftArrayPostion++
  }
  let r = Math.floor(((value & 0xFF0000) >> 16) * 0.9) << 16
  let g = Math.floor(((value & 0x00FF00) >> 8) * 0.9) << 8
  let b = Math.floor((value & 0x0000FF) * 0.9)
  // マスクして16進数に変換、先頭0埋め
  return '#' + ('000000' + (0xFFFFFF & (r | g | b)).toString(16)).slice(-6)
}

export {
  colorFromString
}
