
import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import { RoleManagementController } from './controller/role-management'

import RoleManagement from './components/RoleManagement.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import ModalFooter from './components/ModalFooter.vue'
import draggable from 'vuedraggable'


export const appRoleManagement = (i18n) => {
  /* コントローラ */
  const controller = new RoleManagementController(
    (new UrlBuilder(API_URL)).build(),
    window.i18nContext,
    window.requestContext
  )

  /* コンポーネント登録 */
  Vue.component('role-management', RoleManagement)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('draggable', draggable)

  new Vue({
    el: '#app-role-management',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
