<template>
<div>
  <div
    class="column-caption my-1 p-1 font-weight-bold pointer-cursor"
    v-b-toggle.category_collapse
    @click="folded = !folded">
    <span class="float-none pl-1" v-show="folded"><i class="fas fa-caret-down"></i></span>
    <span class="float-none pl-1" v-show="!folded"><i class="fas fa-caret-up"></i></span>
    <span class="pl-1">{{ $t('category.title') }}</span>
    <b-button
      v-if="categorydesc"
      class="btn btn-success btn-xs float-right" v-b-toggle.category_collapse
      @click="ordering"
      @click.prevent="folded = !folded">{{$t('category.desc')}}</b-button>
    <b-button
      v-else
      class="btn btn-success btn-xs float-right" v-b-toggle.category_collapse
      @click="ordering"
      @click.prevent="folded = !folded">{{$t('category.asc')}}</b-button>
  </div>
  <b-collapse visible id="category_collapse">
    <div class="row mb-1" v-if="userType != 'viewer'">
      <div class="col-auto mr-auto">
        <button
          class="btn btn-success button-text mr-1"
          @click="addCategory">
          <i class="fa fa-plus"></i>
          <span>{{ $t('buttons.add') }}</span>
        </button>

        <button
          class="btn btn-success button-text mr-1"
          @click="editCategory"
          v-bind:disabled="!selectedCategory">
          <i class="fa fa-edit"></i>
          <span>{{ $t('buttons.edit') }}</span>
        </button>

        <button
          class="btn btn-success button-text mr-1"
          @click="deleteCategoryConfirm"
          :disabled="!selectedCategory">
          <i class="fas fa-trash-alt"></i>
          <span>{{ $t('buttons.delete') }}</span>
        </button>
      </div>
      <div class="col-auto">
        <b-navbar-nav class="dropdown-toggle-none">
          <b-nav-item-dropdown class="m-auto" right>
            <template slot="button-content" class="text-danger">
              <i class="fas fa-ellipsis-v dropdown-icon mr-3"></i>
            </template>
            <b-dropdown-item
              :title="$t('category.excelExportIntent')"
              :href="excelExportUrl">
              {{ $t('category.excelExport') }}
            </b-dropdown-item>
            <b-dropdown-item
              :title="$t('category.importIntentsFromExcel')"
              href="#"
              @click="$emit('import-intents-from-excel')">
              {{ $t('category.importIntentsFromExcel') }}
            </b-dropdown-item>
            <b-dropdown-item href="/agent/intent/labels">
              {{ $t('intent.intentLabel') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>
    <b-form-input
      type="text"
      v-model="searchCategoryWord"
      :placeholder="$t('intent.searchCategory')"
      :formatter="format">
    </b-form-input>
    <ul class="marginleft">
      <li class="category-item">
        <div
          class="label my-1 p-1"
          :class="{selected: isAllSelected}"
          @click="selectChild(null)">
          {{ $t('category.all') }}
        </div>
      </li>
    </ul>
    <ul class="scroll-category marginleft" tabindex="-1" ref="focusOn" id="scrollY_IntentCategory" @keyup.delete="userType !='viewer'&& selectedCategory ? deleteCategoryConfirm() : null">
      <li class="category-item" v-for="intentLabel in getIntentLabels">
        <div
          class="label my-1 p-1"
          :class="{selected: intentLabelSelectedClass(intentLabel)}"
          @click="loadIntentsByIntentLabel(intentLabel)">
          <span class="badge"
                :style="setIntentLabelView(intentLabel)">
            {{ intentLabel.name }}
          </span>
        </div>
      </li>
      <category-item
        class="category-item"
        v-for="(category, index) in categories"
        :key="index"
        :category="category"
        :selectedCategory="selectedCategory"
        :currentDisplayedCategory="currentDisplayedCategory"
        :categorydesc="categorydesc"
        :userType="userType"
        :searchCategoryWord="searchCategoryWord"
        @select-category="selectChild"
        @edit-category="editCategory"
        @delete-category="deleteCategoryConfirm"
        @click="$emit('select-category', category)">
      </category-item>
    </ul>
  </b-collapse>

  <b-modal
    :title="$t('category.registered')"
    ref="duplicateModal"
    header-class="bg-danger text-light"
    body-class="text-danger"
    no-close-on-esc
    no-close-on-backdrop
    @keypress.native.enter="duplicateModalHide"
    @ok="duplicateModalHide"
    ok-only>
    <div>
      <p>{{ $t('category.duplicate') }}</p>
    </div>
  </b-modal>

  <b-modal
    :title="modalTitle"
    ref="categoryModal"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-esc
    no-close-on-backdrop
    @shown="modalFocus"
    @hidden="modalHidden">
    <div>
      {{ $t('category.parent') }}:
      <span v-b-tooltip.hover.html.up="$t('category.catInfo')">
        <i class="fas fa-info-circle"></i>
      </span>
    </div>
    <basic-select
        :options="modalParentCategories"
        :selected-option="itemCategory"
        @select="selectIntentCategory"
        placeholder="Select Parent Category">
    </basic-select>
    <div>
      {{ $t('category.name') }}:
    </div>
    <form
      @submit.prevent="editOk">
      <b-form-input
        type="text"
        ref="focusInput"
        v-model="modalCategoryName"
        :maxlength="maxIntentCategoryLength"
        :state="modalCategoryName ? isValidModalIntentCategoryName : null">
      </b-form-input>
    </form>
    <modal-footer
      slot="modal-footer"
      @ok="editOk"
      :okDisabled="!isValidModalIntentCategoryName"
      @cancel="editCancel">
    </modal-footer>
  </b-modal>

  <delete-confirm-modal
    :title="$t('category.delete')"
    ref="deleteCategoryConfirmModal"
    @keydown.native.enter="pressEnterKey"
    @ok="deleteCategory"
    @cancel="deleteCancel"
    @hidden="modalHidden"
    :bodyMessage="$t('category.deleteConfirmMessage', { category: this.selectedCategory && this.selectedCategory.name })"
    :bodySubMessage="[$t('category.deleteCategoryWarning'), $t('category.deleteOtherLanguagesWarning')]">
  </delete-confirm-modal>
</div>
</template>
<script>
import CategoryItem from './CategoryItem.vue'
import { I18n } from "../util/i18n";
import { BasicSelect } from 'vue-search-select'
import { illegalExp } from '../util/illegalRegex'

export default {
  props: [
    'categories',
    'selectedKeywordValue',
    'selectedKeyword',
    'selectedCategory',
    'categoryModel',
    'onlyAlphanumeric',
    'isAllSelected',
    'currentDisplayedCategory',
    'categorydesc',
    'userType',
    'excelExportUrl',
    'canPreview'
  ],
  data() {
    return {
      modalTitle: '',
      modalCategoryName: '',
      modalParentCategoryId: null,
      modalParentCategories: [],
      i18n: new I18n(window.i18nContext),
      maxIntentCategoryLength: 30,
      tempIndex: 0,
      changeIndexFlag: false,
      arrowKeyFlag: 0,
      itemCategory: {value:'', text:''},
      folded:false,
      modalPopupCheckFlag:false,
      isModalOK:false,
      scrollY:0,           //縦のスクロールバー位置
      scrollY_Calc_Num:34, //矢印でアイテム選択中、アイテムがスクロールで隠れないようにスクロール速度調整のための数値（計算用）
      isModalShown: false,
      allCategoriesFlat: [],
      searchCategoryWord: ''
    }
  },
  components: {
    CategoryItem, BasicSelect
  },
  computed: {
    isValidModalIntentCategoryName() {
      return this.isValidIntentCategoryName(this.modalCategoryName)
    },
    getIntentLabels() {
      return this.$parent.controller.listIntentLabels
    }
  },
  mounted(){
    // ページがロード完了したら、クリックイベントを発生させて、矢印を使用可能な状態にする
    this.$nextTick(()=>{
      this.selectChild(this.selectedCategory)
    })
  },
  methods: {
    getAllCategoriesFlat() {
      let resultCategories = []
      let flat = this.categoryModel.categoriesAsFlat(this.categories)
      for(let i = 0; i< flat.length; i++){
        let temp = flat[i]
        resultCategories.push(temp[1])
      }
      return resultCategories
    },
    selectIntentCategory(category){
      this.itemCategory = category
      if(category){
        this.modalParentCategoryId = category.value
      }
    },
    selectChild(category) {
      let allCategory = this.getAllCategoriesFlat()
      if(category){
        for(let i = 0; i < allCategory.length; i++){
          if(allCategory[i].id == category.id){
            this.scrollY = i*this.scrollY_Calc_Num
            if(i===0)
              document.getElementById("scrollY_IntentCategory").scrollTop = 0
          }
        }
      }
      this.changeIndexFlag = true
      this.arrowKeyFlag = 1
      this.isModalShown = false

      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$emit('arrowKey-flag',this.arrowKeyFlag)
      this.$emit('select-category', category)
    },
    onArrowUp(category){
      let allCategory = this.getAllCategoriesFlat()
      for(let i = 0; i < allCategory.length; i++){
        if(allCategory[i].id == category.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i -1
            this.changeIndexFlag = false
            if(this.tempIndex < -1){return}
          }
          else{
            if(this.tempIndex < 0){
              return
            }else{
              this.tempIndex = this.tempIndex -1
            }
          }
        }
      }
      this.scrollY = this.scrollY - this.scrollY_Calc_Num
      document.getElementById("scrollY_IntentCategory").scrollTop = this.scrollY
      // 矢印によって、「すべて」に行く可能にする
      if(this.tempIndex === -1)
        category = null
      else
        category = allCategory[this.tempIndex]
      this.$emit('select-category',category)
    },
    onArrowDown(category){
      let allCategory = this.getAllCategoriesFlat()
      for(let i = 0; i < allCategory.length; i++){
        if(allCategory[i].id == category.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i +1
            this.changeIndexFlag = false
            if(this.tempIndex > allCategory.length-1){return}
          }
          else{
            if(this.tempIndex >= allCategory.length-1){
              return
            }else{
              this.tempIndex = this.tempIndex+1
            }
          }
        }
      }
      document.getElementById("scrollY_IntentCategory").scrollTop = this.scrollY
      this.scrollY = this.scrollY + this.scrollY_Calc_Num
      category = allCategory[this.tempIndex]
      this.$emit('select-category',category)
    },
    onArrowLeft(category){
      let allCategory = this.getAllCategoriesFlat()
      for(let i = 0; i < allCategory.length; i++){
        if(allCategory[i].id == category.id){
          this.tempIndex = i
          this.scrollY = i*this.scrollY_Calc_Num
        }
      }
      document.getElementById("scrollY_IntentCategory").scrollTop = this.scrollY
      this.$emit('select-category',category)
      this.$refs.focusOn.focus()
    },
    ordering(){
      let orderOption = this.categorydesc ? '-name' : 'name'
      this.$emit('intent-category-order', orderOption)
    },
    createCategoriesData(id=0) {
      // ドロップダウンのカテゴリツリー生成
      let categories = [{
        value: null,
        text: "---"
      }]
      let trace=false
      let count = 0
      let flat = this.categoryModel.categoriesAsFlat(this.categories)
      for (const [nestedLevel, category] of flat) {
        let indent = ""
        for (let i = 0; i < nestedLevel; i++) {
          indent += " » "
        }
        if(id==category.id){
          trace=true
          count=nestedLevel
        }
        else{
          if(trace){
            if(count>=nestedLevel){
              categories.push({
                value: category.id,
                text: indent + category.name
              })
              trace=false
            }
          }
          else{
            categories.push({
            value: category.id,
            text: indent + category.name
          })
          }
        }
      }
      return categories
    },
    addCategory() {
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.modalTitle = this.i18n.t('category.add')
      this.modalCategory = new this.categoryModel()
      this.modalCategoryName = ''
      this.modalIntentCategoryId = this.selectedCategory == null ? null : this.selectedCategory.id
      this.modalParentCategories = this.createCategoriesData()
      this.modalParentCategoryId = this.modalIntentCategoryId
      this.itemCategory = this.selectedCategory ? {value:this.selectedCategory, text:this.selectedCategory.name} : {value:'', text:''}
      this.$refs.categoryModal.show()
    },
    deleteCategoryConfirm() {
      if(this.selectedCategory==null){
        return
      }
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.deleteCategoryConfirmModal.show()
    },
    pressEnterKey(){
      this.$refs.deleteCategoryConfirmModal.hide()
    },
    deleteCategory() {
      if(this.isModalOK)
        return null
      this.modalCategory = this.selectedCategory
      this.$emit('delete-category', this.modalCategory)
      this.isModalOK = true
    },
    editCategory() {
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.modalTitle = this.i18n.t('category.edit')
      this.modalCategory = this.selectedCategory
      this.modalCategoryName = this.modalCategory.name
      this.modalParentCategories = this.createCategoriesData(this.modalCategory.id)
      this.modalParentCategoryId = this.modalCategory.parentId
      let text=''
      for(let categories of this.modalParentCategories){
        if(this.modalParentCategoryId == categories.value){
          text = categories.text
          break
        }
      }
      this.itemCategory ={value:this.modalParentCategoryId, text:text}
      this.$refs.categoryModal.show()
    },
    editOk() {
      /**
       * modalからのenterでのsubmitはボタンの無効化で弾けないので、validationを行う
       * invalidな時、modal上ではformが赤くなっているので、警告表示はそちらでOKとする
       */
      if (!this.isValidIntentCategoryName(this.modalCategoryName)) {
        return
      }
      if (this.isDuplicateCategory()) {
        this.$refs.duplicateModal.show()
        return
      }
      this.modalCategory.name = this.modalCategoryName.trim()
      this.modalCategory.name = this.modalCategory.name.replace(illegalExp, "")
      this.modalCategory.parentId = this.modalParentCategoryId
      this.$emit('save-category', this.modalCategory)

      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.isModalOK = true
      this.$refs.categoryModal.hide()
    },
    isValidIntentCategoryName(value) {
      return value.trim().match(/^[^_/]+$/) != null
    },
    isDuplicateCategory() {
      let allCategoriesFlat = this.getAllCategoriesFlat()
      let duplicateCategories = allCategoriesFlat.filter(category => category.parentId == this.modalParentCategoryId && category.name == this.modalCategoryName)
      if (duplicateCategories.length == 0) {
        // 同親カテゴリ、同名のcategoryがない時
        return false
      } else {
        // 同親カテゴリ、同名のcategoryがある時
        if (this.modalCategory.id) {
          // 編集の場合
          return duplicateCategories.some(category => category.id != this.modalCategory.id)
        } else {
          // 新規追加の場合
          return true
        }
      }
    },
    modalFocus(){
      this.$refs.focusInput.focus()
    },
    editCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.categoryModal.hide()
    },
    deleteCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
    },
    modalHidden(){
      if(!this.isModalOK){
        // modalをキーボードで閉じた場合、cancelイベントが発生しないので、hiddenイベントでフォーカスをかける
        this.selectChild(this.selectedCategory)
      }
      this.isModalOK = false
    },
    duplicateModalHide(){
      if (this.modalTitle === this.i18n.t('category.add')) {
        this.addCategory()
      } else if (this.modalTitle === this.i18n.t('category.edit')) {
        this.editCategory()
      }
      this.$refs.duplicateModal.hide()
      this.modalFocus()
    },
    format(value, event) {
      return value.toLowerCase()
    },
    setIntentLabelView(intentLabel) {
      const parentController = this.$parent.controller
      return parentController.setIntentLabelBgColor(intentLabel)
    },
    loadIntentsByIntentLabel(intentLabel) {
      const parentController = this.$parent.controller
      parentController.selectedIntentLabel = intentLabel
      parentController.getIntentsByIntentLabel(intentLabel)
    },
    intentLabelSelectedClass(intentLabel) {
      return this.$parent.controller.selectIntentLabel(intentLabel)
    }
  }
}
</script>
