<template>
  <div class="answer-audio">
    <div>
        {{ $t('answer.callIntentAction') }}:
    </div>
    <div class="input-group mb-2">
      <b-form-input
        :placeholder="$t('answer.callIntentText')"
        v-model="responseMessageText"
        @input="validationCheck"
        maxlength="1000"
        ref="responseMessageTextRef"
      >
      </b-form-input>
    </div>
    <div class="input-group mb-2">
      <b-form-input
        :placeholder="$t('answer.callIntentEvent')"
        v-model="responseMessageEvent"
        @input="validationCheck"
        maxlength="1000"
        ref="responseMessageEventRef"
      >
      </b-form-input>
    </div>
    <div @click="advancedSettingsShown = !advancedSettingsShown" class="pointer-cursor mb-2">
      {{ $t('answer.advancedSettings') }}
      <span class="float-right">
        <span class="float-none pl-1" v-show="!advancedSettingsShown"><i class="fas fa-caret-down"></i></span>
        <span class="float-none pl-1" v-show="advancedSettingsShown"><i class="fas fa-caret-up"></i></span>
      </span>
    </div>
    <b-collapse id="intent-events-block" v-model="advancedSettingsShown">
      <div>
        <div>{{ $t('answer.callIntentData') }}(JSON):</div>
        <code-editor
          editor-id="responseMessageData"
          :content="responseMessageDataJSON"
          @change-content="changeContentCodeEditor"
          class="mb-2" />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { requiredUnless } from 'vuelidate/lib/validators'
import { CallIntentData } from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  name: "AnswerCallIntent",
  data() {
    return {
      answerCallIntent: null,
      responseMessageText: '',
      responseMessageEvent: '',
      responseMessageDataJSON: '{}',
      advancedSettingsShown: false
    }
  },
  validations() {
    const self = this
    return {
      // TODO: Validation
      responseMessageText: {
        required: requiredUnless(function () {
          return this.responseMessageEvent && this.responseMessageEvent !== ''
        })
      },
      responseMessageEvent: {
        required: requiredUnless(function () {
          return this.responseMessageText && this.responseMessageText !== ''
        })
      },
      responseMessageDataJSON: {
        json: this.isJsonFormat
      }
    }
  },
  methods:{
    inputValue(){
      let messageData = this.isJsonFormat() ? JSON.parse(this.responseMessageDataJSON) : {}
      this.answerCallIntent = new CallIntentData(
        this.responseMessageText?.replace(illegalExp, "").trim(),
        this.responseMessageEvent?.replace(illegalExp, "").trim(),
        messageData
      )
      return this.answerCallIntent
    },
    setValue(selectedResponseMessage){
      this.responseMessageText = selectedResponseMessage.data.text
      this.responseMessageEvent = selectedResponseMessage.data.event
      this.responseMessageDataJSON = JSON.stringify(selectedResponseMessage.data.data, null, 2)
    },
    initializeValue(){
      this.answerCallIntent = null
      this.responseMessageText = ''
      this.responseMessageEvent = ''
      this.responseMessageDataJSON = '{}'
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    changeContentCodeEditor(val) {
      this.responseMessageDataJSON = val
      this.validationCheck()
    },
    isJsonFormat() {
      try {
        // オブジェクトに変換できるかどうかを持ってJSON形式チェック
        JSON.parse(this.responseMessageDataJSON)
        const splitJson = this.responseMessageDataJSON.split('')
        // [...]のarray文が変換できるので、除く
        return (splitJson[0] === '{' && splitJson[splitJson.length - 1] === '}')
      } catch (e) {
        return false
      }
    }
  }
}
</script>
