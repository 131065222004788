import { I18n } from "../util/i18n"
import { RestApi } from "../util/rest-api"
import { VIEW_URL } from '../resource/urls'
import { IntentCheckHistoryAdditional, IntentCheckResult } from "../model/intent-check"

class IntentCheckDetailController {
  constructor(apiUrl, userType, history_id, validationContext, i18nContext) {
    this.i18n = new I18n(i18nContext)
    this.userType = userType
    this.historyId = history_id
    this.intentCheckResultApi = new RestApi(apiUrl.intentCheckResult, IntentCheckResult)
    this.validationResult = validationContext.validation_result
    this.total = this.validationResult.length
    this.intentCheckResultSummaryApi = new RestApi(apiUrl.intentCheckHistory, IntentCheckHistoryAdditional)
    this.intentCheckResultSummary = null
    this.progress = true
    this.timer = null
  }

  loadIntentCheckResultSummary() {
    this.intentCheckResultSummaryApi.list()
      .then((instance) => {
        if (instance.length > 0) {
          this.intentCheckResultSummary = instance.filter(data => parseInt(data.id) === parseInt(this.historyId))[0]
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  autoReload() {
    this.progress = this.validationResult.some(rt => rt.result === 'In progress...')
    if (this.progress) {
      this.timer = setInterval(() => { this.reloadValidationResult() }, 10000)
    }
  }

  reloadValidationResult() {
    let options = {
      params: {
        historyId: this.historyId
      }
    }
    this.intentCheckResultApi.list(options)
      .then((instances) => {
        // どれか1つでも進行中のものがあれば、自動読み込みのためにprogressフラグをtrueにする
        this.progress = instances.some(rt => rt.result === 'In progress...')
        if (!this.progress) {
          clearInterval(this.timer)
        }
        this.validationResult = instances
      })
  }

  /**
  * Make download url
  */
  makeDownloadUrl(format) {
    return VIEW_URL.ValidationDownload.replace('<id>', this.historyId).replace('<format>', format)
  }

  isViewer() {
    return this.userType == 'viewer'
  }
}

export {
  IntentCheckDetailController
}
