<template>
  <div class="answer-mediaContent mb-3">
    <div>{{ $t('answer.typeCard') }}</div>
    <div @keypress.enter.prevent="">
      <b-form-radio-group
        v-model="responseMessage.mediaTypeSelected"
        :options="[
          { value: 'noImage', text: $t('answer.noImage') },
          { value: 'largeImage', text: $t('answer.largeImage') },
          { value: 'icon', text: $t('answer.icon') }
        ]"
        @input="validationCheck">
      </b-form-radio-group>
      <div class="input-group mb-1">
        <b-form-input
          :placeholder="$t('answer.enterImageURL')"
          :disabled="this.responseMessage.mediaTypeSelected == 'noImage'"
          v-model="responseMessage.imageUrl"
          type="url"
          @input="validationCheck"
          :state="!$v.responseMessage.imageUrl.$error"
          ref="responseMessageImageUrl"
        >
        </b-form-input>
        <div class="input-group-append">
          <a
            class="btn btn-info"
            :class="{ disabled: responseMessage.mediaTypeSelected == 'noImage' || $v.responseMessage.imageUrl.$error }"
            :href="responseMessage.imageUrl" target="_blank"
            :title="$t('answer.preView')"
          ><span class="fas fa-eye mt-1"/>
          </a>
          <a class="btn btn-primary text-white" v-b-tooltip.hover
             :title="$t('answer.browseImage')"
             :class="{ disabled: responseMessage.mediaTypeSelected === 'noImage' }"
             @click="loadImageUrlFiles">
            <i class="far fa-images"></i>
          </a>
        </div>
        <b-form-invalid-feedback v-if="$v.responseMessage.imageUrl.$error">
          {{ $t('answer.invalidURL') }}
        </b-form-invalid-feedback>
      </div>
      <file-contents ref="imageUrlFiles"
                     :img-url-el="$refs.responseMessageImageUrl"
                     @img-url-model="responseMessage.imageUrl = $event"
                     v-if="responseMessage.mediaTypeSelected !== 'noImage'"></file-contents>
      <b-form-input
        :placeholder="$t('answer.cardButtonTitle')"
        @input="validationCheck"
        v-model="responseMessage.mediaTitle"
        class="mb-1"
        type="text"
        :state="!$v.responseMessage.mediaTitle.$error "
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="$v.responseMessage.mediaTitle.$error">
        {{ $t('webhook.required') }}
      </b-form-invalid-feedback>
      <b-form-input
        :placeholder="$t('answer.typeText')"
        v-model="responseMessage.mediaSubTitle"
        class="mb-1"
        type="text">
      </b-form-input>
      <b-form-input
        :placeholder="$t('answer.enterMediaURL')"
        @input="validationCheck"
        v-model="responseMessage.mediaUrl"
        type="url"
        :state="!$v.responseMessage.mediaUrl.$error"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="$v.responseMessage.mediaUrl.$error">
        {{ $t('webhook.required') }} &
        {{ $t('answer.invalidURL') }}
      </b-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, requiredIf, url} from 'vuelidate/lib/validators'
import {MediaData} from '../../model/intent'
import draggable from 'vuedraggable'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    draggable, FileContents
  },
  data() {
    return {
      responseMessage: {
        imageUrl:'',
        mediaTypeSelected:'',
        mediaTitle:'',
        mediaSubTitle:'',
        mediaUrl:''
      },
      answerMediaContent:null
    }
  },
  validations() {
    return {
      responseMessage: {
        imageUrl: {
          required: requiredIf(() => {
            return this.responseMessage.mediaTypeSelected !== 'noImage'
          }),
          url
        },
        mediaTitle: {
          required
        },
        mediaUrl: {
          required,
          url
        }
      }
    }
  },
  methods:{
    inputValue(){
      this.answerMediaContent = new MediaData(
        this.responseMessage.imageUrl.replace(illegalExp, "").trim(),
        this.responseMessage.mediaTypeSelected,
        this.responseMessage.mediaTitle.replace(illegalExp, "").trim(),
        this.responseMessage.mediaSubTitle.replace(illegalExp, "").trim(),
        this.responseMessage.mediaUrl.replace(illegalExp, "").trim())
      return this.answerMediaContent
    },
    setValue(selectedResponseMessage){
      this.responseMessage.imageUrl = selectedResponseMessage.data.imgurl
      this.responseMessage.mediaTypeSelected = selectedResponseMessage.data.imgtype
      this.responseMessage.mediaTitle = selectedResponseMessage.data.title
      this.responseMessage.mediaSubTitle = selectedResponseMessage.data.text
      this.responseMessage.mediaUrl = selectedResponseMessage.data.mediaurl
    },
    initializeValue(){
      this.responseMessage.imageUrl = ''
      this.responseMessage.mediaTypeSelected = 'noImage'
      this.responseMessage.mediaTitle = ''
      this.responseMessage.mediaSubTitle = ''
      this.responseMessage.mediaUrl = ''
      this.answerMediaContent=null
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadImageUrlFiles() {
      this.$refs.imageUrlFiles.loadImages()
    }
  }
}
</script>
