<template>
  <div class="previewer">
    <div v-if="intentData && keywordData">
      <b-btn
        variant="primary"
        class="mr-1"
        :disabled="showIntentPreview"
        @click="toggleDisplay"
      >
        {{ $t('modeChange.questionAndAnswer') }}
      </b-btn>
      <b-btn
        variant="primary"
        class="mr-1"
        :disabled="showKeywordPreview"
        @click="toggleDisplay"
      >
        {{ $t('keyword.title') }}
      </b-btn>
    </div>
    <div v-if="preIntent && intentData" v-show="showIntentPreview">
      <preview-intent
        ref="previewIntent"
        v-if="preIntent"
        :intentData="intentData"
      >
      </preview-intent>
    </div>
    <div v-if="preKeyword && keywordData" v-show="showKeywordPreview">
      <preview-keyword
        ref="previewKeyword"
        v-if="preKeyword"
        :keywordData="keywordData"
      >
      </preview-keyword>
    </div>
  </div>
</template>

<script>
    export default {
      props: ['intentData', 'keywordData', 'preIntent', 'preKeyword'],
      data() {
        return {
          showIntentPreview: false,
          showKeywordPreview: false
        }
      },
      methods: {
        shown() {
          if (this.preIntent && this.intentData) {
            this.$refs.previewIntent.shown()
          }
          if (this.preKeyword && this.keywordData)
            this.$refs.previewKeyword.shown()
          if (this.preIntent) {
            this.showIntentPreview = true
            this.showKeywordPreview = false
          } else if (this.preKeyword) {
            this.showKeywordPreview = true
            this.showIntentPreview = false
          }
        },
        hidden() {
          this.showIntentPreview = false
          this.showKeywordPreview = false
          if (this.$refs.previewIntent)
            this.$refs.previewIntent.hidden()
          if (this.$refs.previewKeyword)
            this.$refs.previewKeyword.hidden()
        },
        toggleDisplay() {
          this.showIntentPreview = !this.showIntentPreview
          this.showKeywordPreview = !this.showKeywordPreview
        }
      }
    }
</script>
