import {I18n} from "../util/i18n"
import axios from "axios/index";

export default class SystemNotificationController {
  constructor(apiUrl, notifications, i18nContext) {
    this.apiUrl = apiUrl
    this.notifications = notifications
    this.i18n = new I18n(i18nContext)
  }

  hideNotification(csrfToken=null) {
    this.notifications = []
    // sessionに通知を見たというフラグを設定
    let sendOptions = {}
    let options = {}
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    let promise = axios.post(
      this.apiUrl.SystemNotificationSession,
      {},
      sendOptions
    )
  }
}
