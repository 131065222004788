<template>
    <div>
      <div class="row mt-3 mb-1">
        <div class="col-auto mr-auto">
          <h5 class="float-left">{{ $t('syncLog.syncLogList') }}</h5>
        </div>
        <div class="col-auto">
          <button class="btn btn-primary btn-sm" @click="reload()">{{ $t('syncLog.reload') }}</button>
        </div>
      </div>
      <div>
        <transition v-if="controller.isLoading">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-container">
                <b-spinner style="width: 5rem; height: 5rem;" label="Loading..."></b-spinner>
              </div>
            </div>
          </div>
        </transition>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead style="background-color: #d9edf6">
              <tr>
                <th class="cell-width-medium">{{ $t('syncLog.startDate') }}</th>
                <th class="cell-width-medium">{{ $t('syncLog.endDate') }}</th>
                <th class="cell-width-narrow">{{ $t('syncLog.elapsedTime') }}</th>
                <th class="cell-width-large">{{ $t('syncLog.message') }}</th>
                <th class="cell-width-narrow">{{ $t('syncLog.username') }}</th>
                <th class="cell-width-medium">{{ $t('syncLog.dialogFlowAgent') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in controller.syncLogs"
                :class="{'text-danger': item.status === trainStatus.error, 'text-success': item.status === trainStatus.finish,
                        'text-primary': item.status === trainStatus.ready, 'text-warning': item.status === trainStatus.timeout,
                        'text-info': item.status === trainStatus.train}">
                <td class="cell-width-medium">
                  {{ item.created_at | moment("Y-MM-DD HH:mm:ss") }}
                </td>
                <td class="cell-width-medium">
                  {{ item.updated_at | moment("Y-MM-DD HH:mm:ss") }}
                </td>
                <td class="cell-width-narrow">
                  {{ get_elapsed_time(item) }} sec
                </td>
                <td class="cell-width-large">
                  <div v-if="item.message['answer-card']">
                    <div class="row">
                      <div class="col-md-1"><strong>{{ $t('validation.language') }}</strong></div>
                      <div class="col-md-3"><strong>{{ $t('validation.intent') }}</strong></div>
                    </div>
                    <div class="row" v-for="(error, index) in item.message['answer-card']">
                      <div class="col-md-1">
                        <span v-if="'ja' === error.language_code" :title="$t('languageSelector.japanese')">{{ $t('languageSelector.ja') }}</span>
                        <span v-else-if="'en' === error.language_code" :title="$t('languageSelector.english')">{{ $t('languageSelector.en') }}</span>
                        <span v-else-if="'ko' === error.language_code" :title="$t('languageSelector.korean')">{{ $t('languageSelector.ko') }}</span>
                        <span v-else-if="'zh-cn' === error.language_code" :title="$t('languageSelector.chineseSimplified')">{{ $t('languageSelector.zhCn') }}</span>
                        <span v-else-if="'zh-tw' === error.language_code" :title="$t('languageSelector.chineseTraditional')">{{ $t('languageSelector.zhTw') }}</span>
                        <span v-else-if="'th' === error.language_code" :title="$t('languageSelector.thai')">{{ $t('languageSelector.th') }}</span>
                        <span v-else-if="'ru' === error.language_code" :title="$t('languageSelector.russian')">{{ $t('languageSelector.ru') }}</span>
                        <span v-else-if="'vi' === error.language_code" :title="$t('languageSelector.vietnamese')">{{ $t('languageSelector.vi') }}</span>
                        <span v-else-if="'id' === error.language_code" :title="$t('languageSelector.indonesian')">{{ $t('languageSelector.id') }}</span>
                        <span v-else-if="'uk' === error.language_code" :title="$t('languageSelector.ukrainian')">{{ $t('languageSelector.uk') }}</span>
                        <span v-else-if="'ne' === error.language_code" :title="$t('languageSelector.nepali')">{{ $t('languageSelector.ne') }}</span>
                        <span v-else-if="'bn' === error.language_code" :title="$t('languageSelector.bengali')">{{ $t('languageSelector.bn') }}</span>
                        <span v-else-if="'fr' === error.language_code" :title="$t('languageSelector.french')">{{ $t('languageSelector.fr') }}</span>
                        <span v-else-if="'es' === error.language_code" :title="$t('languageSelector.spanish')">{{ $t('languageSelector.es') }}</span>
                        <span v-else-if="'mn' === error.language_code" :title="$t('languageSelector.mongolian')">{{ $t('languageSelector.mn') }}</span>
                        <span v-else-if="'my' === error.language_code" :title="$t('languageSelector.burmese')">{{ $t('languageSelector.my') }}</span>
                        <span v-else-if="'pt' === error.language_code" :title="$t('languageSelector.portuguese')">{{ $t('languageSelector.pt') }}</span>
                        <span v-else-if="'pt-br' === error.language_code" :title="$t('languageSelector.portugueseBrazil')">{{ $t('languageSelector.ptBr') }}</span>
                        <span v-else-if="'si' === error.language_code" :title="$t('languageSelector.sinhala')">{{ $t('languageSelector.si') }}</span>
                      </div>
                      <div class="col-md-3">
                        {{error.intent_name}}
                        <a :href="showSelectedErrorIntent(error)" target="_blank">
                          <i class="fas fa-link" style="font-size: 12px;"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.message['webhook']">
                    <span>
                      {{$t('errors.webhookValidationMessage')}}<br />
                      <a href="/webhook" target="_blank">{{$t('errors.goToWebhookPage')}}</a>
                    </span>
                    <ul class="list-group" v-for="error in item.message['webhook']">
                      <li class="list-group-item">{{error.target_parameter}}</li>
                    </ul>
                  </div>
                  <div v-else>{{ item.message.message }}</div>
                </td>
                <td class="cell-width-narrow text-break">
                  {{ item.user }}
                </td>
                <td class="cell-width-medium text-break">
                  {{ item.dialogflow_project_id }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <pagination
                  :params="controller.params"
                  :num_page="controller.num_page"
                  :callback="loadSyncLogData"
          />
        </div>
      </div>
    </div>
</template>

<script>
  import {I18n} from "../util/i18n"
    export default {
      name: "SyncLog",
      props:['controller'],
      data(){
        return{
          i18n: new I18n(window.i18nContext),
          trainStatus: {
            ready: 1,
            restore: 2,
            train: 3,
            finish: 10,
            busy: 11,
            timeout: 12,
            error: 999
          }
        }
      },
      methods:{
        loadSyncLogData: function (){
          this.controller.loadSyncLog()
        },
        reload(){
          this.controller.params.page = 1
          this.controller.loadSyncLog()
        },
        showSelectedErrorIntent(error){
          let url = '/agent/' + error.language_code + '/intent?intent_name=' + error.intent_category_full_path
          return url
        },
        get_elapsed_time(item){
          let end_time = new Date(item.updated_at)
          let start_time = new Date(item.created_at)
          return Math.abs(Math.round((end_time.getTime() - start_time.getTime())/1000))
        },
        showFeedbackWarning(){
          const destination = location.origin +"/feedback-config"
          return '<a href="'+ destination +'" target="_blank">'+ this.i18n.t("feedback.feedbackSetting") + '</a>'
        }
      }
    }
</script>
<style scoped>
  @media (min-width: 1000px) {
    tbody {
      display:block;
      height:700px;
      overflow-y:auto;
      overflow-x:hidden
    }
    thead, tbody tr {
      display:table;
      width:100%;
      table-layout:fixed;
    }
    .cell-width-narrow {
      width: 6%;
    }
    .cell-width-medium {
      width: 10%;
    }
    .cell-width-large {
      width: 58%;
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 80px;
    margin: 0 auto;
    transition: all .3s ease;
  }
</style>
