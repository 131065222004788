import { I18n } from '../util/i18n'
import { RestApi } from '../util/rest-api'
import { Rule, RuleTarget, RuleAction, RuleOption, RuleOptionProbability, RuleRegion, RuleArea } from '../model/webhook'
import {IntentCategory, Intent} from '../model/intent'
import SynchronizeController from "./synchronize-controller";
import axios from "axios";
import {Dataset} from "../model/intent-check";
import {TestChatController} from "./test-chat-controller";

class WebhookController {
  constructor(apiUrl, language, userType, i18nContext, auto_input_predict, signature_key, test_chat_platform, available_platform) {
    this.signature_key = signature_key
    this.auto_input_predict = auto_input_predict
    this.language = language
    this.userType = userType
    this.apiUrl = apiUrl
    this.i18n = new I18n(i18nContext)
    this.ruleApi = new RestApi(apiUrl.WebhookRule, Rule)
    this.intentCategoryApi = new RestApi(apiUrl.IntentCategory, IntentCategory)
    this.synchronizeController = new SynchronizeController(apiUrl, this.i18n)
    this.intentApi = new RestApi(apiUrl.Intent, Intent)
    this.allIntentApi = new RestApi(apiUrl.AllIntent, Intent)
    this.userSaysSchronizeApi = apiUrl.userSaysSynchronize

    this.rules = []
    this.categories = []
    this.intents = []
    this.intentsWithFullPath = []
    this.categoryPathIndex = null
    this.itemIntent = {value:null, text:''}
    this.itemCategory = {value:null, text:''}
    this.detectIntentResult=null
    this.loadingScreen = false
    this.chatLanguage = null
    this.TryItNowApi = apiUrl.TryItNow
    this.supportedLanguages = null
    this.datasetApi = new RestApi(apiUrl.dataset, Dataset)
    this.hasContext = false
    this.testChatController = null
    this.testChatPlatform = test_chat_platform
    this.availablePlatform = available_platform
  }

  ready() {
    this.loadRules()
    this.loadCategories()
    this.loadDataset()
    this.loadAllIntentWithFullPath().then((intentsWithFullPath) => {
      this.intentsWithFullPath = intentsWithFullPath.filter((intent) => intent);
    });
  }

  /**
   * Use for transfer input prediction
   * @param csrfToken
   * @returns {Promise<any>}
   */

  inputPredictionTransfer(csrfToken=null) {
    const options = {
      headers: {'X-CSRFToken': csrfToken}
    }
    return new Promise((resolve, reject) =>
      axios.post(this.userSaysSchronizeApi, {}, options)
        .then(res => resolve(res)).catch(err => reject(err))
      )
  }

  loadDataset(){
    this.datasetApi.list()
      .then((instance) => {
        this.supportedLanguages = this.supportedLanguagesArray(instance)
        this.chatLanguage = instance[0].language_code
        this.testChatController = new TestChatController(this.apiUrl, this.supportedLanguages, this.i18n, this.availablePlatform, this.testChatPlatform)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  supportedLanguagesArray(instances){
    let result = {}
    for(const instance of instances){
      result[instance.language_code] = {
        code: instance.language_code
      }
    }
    return result
  }

  loadRules() {
    this.ruleApi.list()
    .then((instances) => {
      this.rules = instances
    })
  }
   /**
   * カテゴリ一覧取得
   */
  loadCategories() {
    this.intentCategoryApi.list()
    .then((instances) => {
      this.categoryPathIndex = this.createCategoryPathIndex(instances)
      this.categories = this.createCategoriesData(instances)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  /**
  * インテント一覧取得
  */
  loadIntents(category) {
    this.intentApi.list({
        params: {
          category_id: category.id
       }
    })
    .then((instances) => {
      this.intents = this.createIntentsData(instances)
    })
    .catch((error) => {
      console.log(error)
    })
  }


  createCategoriesData(categories) {
    // ドロップダウンのカテゴリツリー生成
    let resultCategories = []
    let flat = IntentCategory.categoriesAsFlat(categories)
    for (const [nestedLevel, category] of flat) {
      let indent = ""
      for (let i = 0; i < nestedLevel; i++) {
        indent += " » "
      }
      resultCategories.push({
        value: category,
        text: indent + category.name
      })
    }
    return resultCategories
  }

  createIntentsData(intents) {
    // ドロップダウンインテントデータを生成する
    let result = []
    for (const intent of intents) {
      result.push({
        value: intent,
        text: intent.name
      })
    }
    return result
  }


  newRule() {
    const rule = new Rule()
    rule.target = RuleTarget.INTENT
    rule.action = RuleAction.CALL_EXTERNAL_URL
    rule.option = RuleOption.OFF
    rule.optionProbability = '5%'
    rule.optionText = ''
    rule.region = RuleRegion.HOKKAIDO
    return rule
  }

  get ruleTargets() {
    const targets = []
    for (const key of Object.keys(RuleTarget)) {
      targets.push({
        'text': this.i18n.t('webhook.' + RuleTarget[key]),
        'value': RuleTarget[key]
      })
    }
    return targets
  }

  /**
   * targetParameterの入力を無効にするかどうか
   */
  isDisabledTargetParameter(rule) {
    if (rule.target == RuleTarget.ALL) {
      return true
    }
    if (rule.target == RuleTarget.INTENT) {
      return false
    }
    return true
  }

   /**
   * targetParameterのプレースホルダ
   */
  targetParameterPlaceholder(rule) {
    if (rule.target == RuleTarget.ALL) {
      return ''
    }
    if (rule.target == RuleTarget.INTENT) {
      return this.i18n.t('webhook.intentName')
    }
    return ''
  }

  get ruleActions() {
    const actions = []
    for (const key of Object.keys(RuleAction)) {
      actions.push({
        'text': this.i18n.t('webhook.' + RuleAction[key]),
        'value': RuleAction[key]
      })
    }
    return actions
  }

  get ruleOptions() {
    const options = []
    for (const key of Object.keys(RuleOption)) {
      options.push({
        'text': this.i18n.t('webhook.' + RuleOption[key]),
        'value': RuleOption[key]
      })
    }
    return options
  }

  get ruleProbabilities() {
    const probabilities = []
    for (const key of Object.keys(RuleOptionProbability)) {
      probabilities.push({
        'text': RuleOptionProbability[key],
        'value': RuleOptionProbability[key]
      })
    }
    return probabilities
  }

  get ruleRegions() {
    const regions = []
    for (const key of Object.keys(RuleRegion)) {
      regions.push({
        'text': this.i18n.t('domestic_area.' + RuleRegion[key]),
        'value': RuleRegion[key]
      })
    }
    return regions
  }

  get ruleAreas() {
    const areas = []
    for (const key of Object.keys(RuleArea)) {
      areas.push({
        'text': this.i18n.t('domestic_area.' + RuleArea[key]),
        'value': RuleArea[key]
      })
    }
    return areas
  }

  isDisabledActionParameter(rule) {
    if (rule.action == RuleAction.NOTHING) {
      return true
    }
    if (rule.action == RuleAction.CALL_EXTERNAL_URL) {
      return false
    }
    if (rule.action == RuleAction.WEATHER_BY_ZIPCODE) {
      return false
    }
    if (rule.action == RuleAction.WEATHER_BY_CITY_NAME) {
      return false
    }
    if (rule.action == RuleAction.WEATHER_BY_DOMESTIC_AREA) {
      return 'weather'
    }
    if (rule.action == RuleAction.FLIGHT_BY_FLIGHTNUMBER) {
      return false
    }
    if (rule.action == RuleAction.RESTAURANT_BY_ADDRESS) {
      return false
    }
    if (rule.action == RuleAction.RESTAURANT_BY_COORDINATE) {
      return false
    }
    if (rule.action == RuleAction.SESAME_LOCK) {
      return false
    }
    if (rule.action == RuleAction.NATURE_REMO) {
      return false
    }
    if (rule.action == RuleAction.HOROSCOPE_BY_DOB) {
      return 'horoscope'
    }
    if (rule.action === RuleAction.IFTTT_EVENT) {
      return 'ifttt_event'
    }
    return true
  }

  actionParameterPlaceholder(rule) {
    if (rule.action == RuleAction.NOTHING) {
      return ''
    }
    if (rule.action == RuleAction.CALL_EXTERNAL_URL) {
      return 'URL'
    }
    if (rule.action == RuleAction.WEATHER_BY_ZIPCODE) {
      return '000-0000,JP'
    }
    if (rule.action == RuleAction.WEATHER_BY_CITY_NAME) {
      return 'Tokyo,JP'
    }
    if (rule.action == RuleAction.WEATHER_BY_DOMESTIC_AREA) {
      return 'area,code'
    }
    if (rule.action == RuleAction.FLIGHT_BY_FLIGHTNUMBER) {
      return ''
    }
    if (rule.action == RuleAction.RESTAURANT_BY_ADDRESS) {
      return 'gnavi api key&000-0000'
    }
    if (rule.action == RuleAction.RESTAURANT_BY_COORDINATE) {
      return 'gnavi api key'
    }
    if (rule.action == RuleAction.SESAME_LOCK) {
      return 'lock,unlock,device_id,api_key'
    }
    if (rule.action == RuleAction.NATURE_REMO) {
      return 'signal_id,api_key'
    }
    if (rule.action == RuleAction.HOROSCOPE_BY_DOB) {
      return 'option,probability,link,image,text'
    }
    if (rule.action === RuleAction.IFTTT_EVENT) {
      return 'api_key,event,value3'
    }
    return ''
  }

  validateRule(rule) {
    if (!rule) {
      return false
    }
    let result = true
    if (rule.target == RuleTarget.INTENT) {
      result = rule.targetParameter ? true : false
    }
    if (rule.action == RuleAction.CALL_EXTERNAL_URL) {
      result = rule.actionParameter ? true : false
    }
    if (rule.action == RuleAction.WEATHER_BY_ZIPCODE) {
      result = rule.actionParameter ? true : false
    }
    if (rule.action == RuleAction.HOROSCOPE_BY_DOB) {
      result = rule.actionParameter ? true : false
    }
    if (rule.action == RuleAction.WEATHER_BY_DOMESTIC_AREA) {
      result = rule.actionParameter ? true : false
    }
    if (rule.action === RuleAction.IFTTT_EVENT){
      result = rule.actionParameter ? true : false
    }
    return result
  }

  /**
   * Ruleのクリーニング
   */
  cleanRule(rule) {
    if (rule.target == RuleTarget.ALL) {
      rule.targetParameter = null
    }
    if (rule.action == RuleAction.NOTHING) {
      rule.actionParameter = null
    }
    return rule
  }

  saveRule(rule, csrfToken=null) {
    let priority = null
    if (rule.id) {
      priority = rule.priority
    } else {
      let priorities = this.rules.map(
        existRule => existRule.priority)
      priority = Math.min(priorities) - 1  // priority最小値 - 1
    }
    let promise = this.ruleApi.save(
      this.cleanRule(rule),
      csrfToken,
      {
        params: {
          priority: priority
        }
      }
    ).then((instance) => {
      this.loadRules()
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  deleteRule(rule, csrfToken=null) {
    let promise = this.ruleApi.destroy(
      rule,
      csrfToken
    ).then((instance) => {
      this.loadRules()
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  saveRules(rules, csrfToken=null) {
    let promises = []
    for (const rule of rules) {
      let promise = this.ruleApi.save(
        rule, csrfToken
      )
      promises.push(promise)
    }
    Promise.all(promises).then(() => {
      this.loadRules()
    })
  }

  updatePriorities(reorderedRules, csrfToken=null) {
    let newPriority = reorderedRules.length
    for (let rule of reorderedRules) {
      rule.priority = newPriority
      newPriority--
    }
    this.rules = reorderedRules
    this.saveRules(reorderedRules, csrfToken)
  }

  resolveCategoryPath(category, categoryIdToCategory) {
    if (category.parentId == null) {
      return [category]
    } else {
      const parentCategory = categoryIdToCategory[category.parentId]
      return this.resolveCategoryPath(parentCategory, categoryIdToCategory).concat([category])
    }
  }

  /**
   * カテゴリ名のインデックス生成
   */
  createCategoryPathIndex(categories) {
    let flat = IntentCategory.categoriesAsFlat(categories)
    // categoryId -> categoryを作成
    let categoryIdToCategory = {}
    for (const [nestedLevel, category] of flat) {
      categoryIdToCategory[category.id] = category
    }
    // category -> カテゴリツリーのパス生成
    let categoryIdToPath = {}
    for (const [nestedLevel, category] of flat) {
      const categoryPath = this.resolveCategoryPath(category, categoryIdToCategory)
      categoryIdToPath[category.id] = categoryPath.map((c) => {return c.name}).join("_")
    }
    return categoryIdToPath
  }

  isViewer() {
    return this.userType == 'viewer'
  }

  /**
   * synchronizeControllerを呼び出してdialogflowへの同期を開始する
   */
  startSynchronize(csrfToken=null, isCard) {
    let synchResult = this.synchronizeController.synchronize(csrfToken, isCard)
    return synchResult
  }

  /**
   * Detect locale language
   * @returns {*}
   */
  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }

  /**
   * make intent full name by id
   */
  loadAllIntentWithFullPath(){
    return new Promise((resolve, reject) => {
      this.intentCategoryApi.list().then((instance) => {
        const category = this.createCategoryPathIndex(instance)
        this.allIntentApi.list().then((intents) => {
          const listIntent = []
          for(const intent of intents) {
            if (intent) {
              listIntent[intent.id] = category[intent.categoryId] + '_' + intent.name
            }
          }
          resolve(listIntent)
        }).catch(error => {
          // handle error
          reject(error)
          console.log(error)
        })
      }).catch(error => {
        // handle error
        reject(error)
        console.log(error)
      })
    })
  }

  /**
   * Detect Intent for checking agent state
   * @param param
   * @param csrfToken
   * @returns {Promise<unknown>}
   */
  checkAgentState(param, csrfToken='') {
    let sendOptions = {}
    let options = {}
    let params = {
      user_say: param.text,
      language: param.language
    }
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    return new Promise((resolve, reject) => {
      axios.post(
        this.TryItNowApi,
        params,
        sendOptions
      ).then((instance) => {
        let result = false
        const response = JSON.parse(instance.data)
        if("intent" in response) {
          result = true
        }
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export {
  WebhookController
}
