<template>
  <div>
    <b-dropdown
      variant="primary"
      :text="
        selectedLanguage
        ? languageLabels[selectedLanguage]
        : $t('scenario.selectLanguage')
      "
      :disabled="!scenarioId"
    >
      <b-dropdown-item
        v-for="(supportedLanguage, idx) in supportedLanguages"
        :key="idx"
        :href="`${baseUrl}${scenarioId}/${supportedLanguage.code}`"
        :disabled="supportedLanguage.code === selectedLanguage"
      >{{ languageLabels[supportedLanguage.code] }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { LANGUAGE_LABELS } from '../resource/language';

export default {
  props: [
    'supportedLanguages',
    'baseUrl',
    'scenarioId',
    'selectedLanguage',
    'i18n',
  ],
  data() {
    return {
      languageLabels: LANGUAGE_LABELS,
    }
  },
}
</script>
