import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import { DFAgentSwitchController } from './controller/df-agent-switch'

import DFAgentSwitch from './components/DFAgentSwitch.vue'
import ModalFooter from './components/ModalFooter.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'


export const appDfAgentSwitch = (i18n) => {
  /* コントローラ */
  const controller = new DFAgentSwitchController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext,
    window.i18nContext
  )

  /* コンポーネント登録 */
  Vue.component('df-agent-switch', DFAgentSwitch)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)

  new Vue({
    el: '#app-df-agent-switch',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
