import { I18n } from "../util/i18n";
import { RPC } from "../util/rpc";
import csrfToken from "../util/csrf-token";

/**
 * FAQクライアント管理画面のコントローラ
 */
export class FAQClientManagementController {
  constructor(apiUrl, i18nContext, clients, loaders) {
    this.i18n = new I18n(i18nContext);

    // API呼び出しクラスのインスタンス化
    this.createFAQClientApi = new RPC(apiUrl.createFAQClient);
    this.createFAQLoaderApi = new RPC(apiUrl.createFAQLoader);
    this.updateFAQClientApi = new RPC(apiUrl.updateFAQClient);
    this.updateFAQLoaderApi = new RPC(apiUrl.updateFAQLoader);
    this.deleteFAQClientApi = new RPC(apiUrl.deleteFAQClient);
    this.deleteFAQLoaderApi = new RPC(apiUrl.deleteFAQLoader);

    this.loaders = loaders;
    this.clients = clients;

    this.selectedClient = null;
    this.selectedLoader = null;
  }

  ready() {}

  /**
   * クライアント更新API呼び出し
   */
  async updateClient({
    /** */
    name,
    /** */
    data,
    style,
    css_path,
  }) {
    // クライアントの更新API呼び出し
    const client = await this.updateFAQClientApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        ...this.selectedClient,
        name,
        data: JSON.parse(data),
        style,
        css_path,
      }
    );

    // クライアント一覧の更新
    this.clients = this.clients.map((d) => {
      if (d.id === client.id) {
        return client;
      }
      return d;
    });
  }

  /**
   * クライアント更新API呼び出し
   */
  async updateLoader({
    /** */
    name,
    /** */
    data,
    style,
    css_path,
  }) {
    // クライアントの更新API呼び出し
    const loader = await this.updateFAQLoaderApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        ...this.selectedLoader,
        name,
        data: JSON.parse(data),
        style,
        css_path,
      }
    );

    // クライアント一覧の更新
    this.loaders = this.loaders.map((l) => {
      if (l.uid === loader.uid) {
        return loader;
      }
      return l;
    });
  }

  /**
   * クライアント選択処理
   */
  onSelectClient(client) {
    this.selectedClient = client;
  }

  /**
   * クライアント追加処理
   */
  async createClient({ name }) {
    // クライアント追加API呼び出し
    const client = await this.createFAQClientApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        name,
      }
    );

    // クライアント一覧の更新
    this.clients.push(client);
  }

  /**
   * ローダー追加処理
   */
  async createLoader({ name, client }) {
    // クライアント追加API呼び出し
    const loader = await this.createFAQLoaderApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        name,
        client: client.value,
      }
    );

    // クライアント一覧の更新
    this.loaders.push(loader);
  }

  /**
   * クライアント削除処理
   */
  async deleteClient() {
    // クライアント追加API呼び出し
    await this.deleteFAQClientApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        id: this.selectedClient.id,
      }
    );

    // クライアント一覧の更新
    this.clients = this.clients.filter((d) => d.id !== this.selectedClient.id);

    // ローダー一覧の更新
    this.loaders = this.loaders.filter(
      (d) => d.client.id !== this.selectedClient.id
    );

    this.selectedClient = null;
  }

  /**
   * ローダー削除処理
   */
  async deleteLoader() {
    // クライアント追加API呼び出し
    await this.deleteFAQLoaderApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        uid: this.selectedLoader.uid,
      }
    );

    // ローダー一覧の更新
    this.loaders = this.loaders.filter(
      (d) => d.uid !== this.selectedLoader.uid
    );

    this.selectedLoader = null;
  }
}
