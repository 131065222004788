<template>
  <div>
    <div class="d-flex w-100 justify-content-between mb-1">
      <small></small>
      <b-form-checkbox right-align  vertical-align="center" switch v-model="agentsByMessageState" @input="hideAgentsByMessage">
        {{ $t('dashboard.hideAgentsThoseHaveNoMessage') }}
      </b-form-checkbox>
    </div>
    <b-card no-body>
      <b-tabs pills card v-model="tabIndex" lazy>
        <b-tab :title="$t('dashboard.all')" @click="switchTab" active>
          <div class="table-responsive text-center">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="cell-detail-md">Agent\Date</th>
                  <th scope="col" v-for="day in date_range" @click="sortColumnByDate(day, '')">
                    {{ day }}
                    <i :id="'sortIcon-'+day" class="fas fa-sort sort-icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in half_montly_data" :id="tabTitles[tabIndex]+'-'+items.agent_id">
                  <th class="cell-detail-md"><a :href="url + items.agent_id">{{ items.agent_name }}</a></th>
                  <td v-for="item in items.data"
                      :class="{'bg-primary text-white':parseInt(item.answered_rate)>= 90,
                      'bg-warning text-white': parseInt(item.answered_rate)>=60 && parseInt(item.answered_rate)<90,
                      'bg-danger text-white': parseInt(item.answered_rate)<60 && parseInt(item.answered_rate)>=0}">
                    {{ item.answered_rate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('languageSelector.en')" @click="switchTab">
          <div class="table-responsive text-center">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="cell-detail-md">Agent\Date</th>
                  <th scope="col" v-for="day in date_range" @click="sortColumnByDate(day, '_en')">
                    {{ day }}
                    <i :id="'sortIcon-'+day+'_en'" class="fas fa-sort sort-icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in half_montly_data" :id="tabTitles[tabIndex]+'-'+items.agent_id">
                  <th class="cell-detail-md"><a :href="url + items.agent_id">{{ items.agent_name }}</a></th>
                  <td v-for="item in items.data"
                    :class="{'bg-primary text-white':parseInt(item.answered_rate_en)>= 90,
                    'bg-warning text-white': parseInt(item.answered_rate_en)>=60 && parseInt(item.answered_rate_en)<90,
                    'bg-danger text-white': parseInt(item.answered_rate_en)<60 && parseInt(item.answered_rate_en)>=0}">
                    {{ item.answered_rate_en }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('languageSelector.ja')" @click="switchTab">
          <div class="table-responsive text-center">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="cell-detail-md">Agent\Date</th>
                  <th scope="col" v-for="day in date_range" @click="sortColumnByDate(day, '_ja')">
                    {{ day }}
                    <i :id="'sortIcon-'+day+'_ja'" class="fas fa-sort sort-icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in half_montly_data" :id="tabTitles[tabIndex]+'-'+items.agent_id">
                  <th class="cell-detail-md"><a :href="url + items.agent_id">{{ items.agent_name }}</a></th>
                  <td v-for="item in items.data"
                    :class="{'bg-primary text-white':parseInt(item.answered_rate_ja)>= 90,
                    'bg-warning text-white': parseInt(item.answered_rate_ja)>=60 && parseInt(item.answered_rate_ja)<90,
                    'bg-danger text-white': parseInt(item.answered_rate_ja)<60 && parseInt(item.answered_rate_ja)>=0}">
                    {{ item.answered_rate_ja }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('languageSelector.ko')" @click="switchTab">
          <div class="table-responsive text-center">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="cell-detail-md">Agent\Date</th>
                  <th scope="col" v-for="day in date_range" @click="sortColumnByDate(day, '_ko')">
                    {{ day }}
                    <i :id="'sortIcon-'+day+'_ko'" class="fas fa-sort sort-icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in half_montly_data" :id="tabTitles[tabIndex]+'-'+items.agent_id">
                  <th class="cell-detail-md"><a :href="url + items.agent_id">{{ items.agent_name }}</a></th>
                  <td v-for="item in items.data"
                    :class="{'bg-primary text-white':parseInt(item.answered_rate_ko)>= 90,
                    'bg-warning text-white': parseInt(item.answered_rate_ko)>=60 && parseInt(item.answered_rate_ko)<90,
                    'bg-danger text-white': parseInt(item.answered_rate_ko)<60 && parseInt(item.answered_rate_ko)>=0}">
                    {{ item.answered_rate_ko }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('languageSelector.zhCn')" @click="switchTab">
          <div class="table-responsive text-center">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="cell-detail-md">Agent\Date</th>
                  <th scope="col" v-for="day in date_range" @click="sortColumnByDate(day, '_zh_cn')">
                    {{ day }}
                    <i :id="'sortIcon-'+day+'_zh_cn'" class="fas fa-sort sort-icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in half_montly_data" :id="tabTitles[tabIndex]+'-'+items.agent_id">
                  <th class="cell-detail-md"><a :href="url + items.agent_id">{{ items.agent_name }}</a></th>
                  <td v-for="item in items.data"
                    :class="{'bg-primary text-white':parseInt(item.answered_rate_zh_cn)>= 90,
                    'bg-warning text-white': parseInt(item.answered_rate_zh_cn)>=60 && parseInt(item.answered_rate_zh_cn)<90,
                    'bg-danger text-white': parseInt(item.answered_rate_zh_cn)<60 && parseInt(item.answered_rate_zh_cn)>=0}">
                    {{ item.answered_rate_zh_cn}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('languageSelector.zhTw')" @click="switchTab">
          <div class="table-responsive text-center">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="cell-detail-md">Agent\Date</th>
                  <th scope="col" v-for="day in date_range" @click="sortColumnByDate(day, '_zh_tw')">
                    {{ day }}
                    <i :id="'sortIcon-'+day+'_zh_tw'" class="fas fa-sort sort-icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in half_montly_data" :id="tabTitles[tabIndex]+'-'+items.agent_id">
                  <th class="cell-detail-md"><a :href="url + items.agent_id">{{ items.agent_name }}</a></th>
                  <td v-for="item in items.data"
                    :class="{'bg-primary text-white':parseInt(item.answered_rate_zh_tw)>= 90,
                    'bg-warning text-white': parseInt(item.answered_rate_zh_tw)>=60 && parseInt(item.answered_rate_zh_tw)<90,
                    'bg-danger text-white': parseInt(item.answered_rate_zh_tw)<60 && parseInt(item.answered_rate_zh_tw)>=0}">
                    {{ item.answered_rate_zh_tw }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('languageSelector.th')" @click="switchTab">
          <div class="table-responsive text-center">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="cell-detail-md">Agent\Date</th>
                  <th scope="col" v-for="day in date_range" @click="sortColumnByDate(day, '_th')">
                    {{ day }}
                    <i :id="'sortIcon-'+day+'_th'" class="fas fa-sort sort-icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in half_montly_data" :id="tabTitles[tabIndex]+'-'+items.agent_id">
                  <th class="cell-detail-md"><a :href="url + items.agent_id">{{ items.agent_name }}</a></th>
                  <td v-for="item in items.data"
                    :class="{'bg-primary text-white':parseInt(item.answered_rate_th)>= 90,
                    'bg-warning text-white': parseInt(item.answered_rate_th)>=60 && parseInt(item.answered_rate_th)<90,
                    'bg-danger text-white': parseInt(item.answered_rate_th)<60 && parseInt(item.answered_rate_th)>=0}">
                    {{ item.answered_rate_th }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab :title="$t('languageSelector.ru')" @click="switchTab">
          <div class="table-responsive text-center">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th class="cell-detail-md">Agent\Date</th>
                  <th scope="col" v-for="day in date_range" @click="sortColumnByDate(day, '_ru')">
                    {{ day }}
                    <i :id="'sortIcon-'+day+'_ru'" class="fas fa-sort sort-icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="items in half_montly_data" :id="tabTitles[tabIndex]+'-'+items.agent_id">
                  <th class="cell-detail-md"><a :href="url + items.agent_id">{{ items.agent_name }}</a></th>
                  <td v-for="item in items.data"
                    :class="{'bg-primary text-white':parseInt(item.answered_rate_ru)>= 90,
                    'bg-warning text-white': parseInt(item.answered_rate_ru)>=60 && parseInt(item.answered_rate_ru)<90,
                    'bg-danger text-white': parseInt(item.answered_rate_ru)<60 && parseInt(item.answered_rate_ru)>=0}">
                    {{ item.answered_rate_ru }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ['half_montly_data', 'date_range', 'url'],
  data: function() {
    return {
      sortOrder: '',
      tabIndex: 1,
      tabTitles: {
        0: 'answered_rate',
        1: 'answered_rate_en',
        2: 'answered_rate_ja',
        3: 'answered_rate_ko',
        4: 'answered_rate_zh_cn',
        5: 'answered_rate_zh_tw',
        6: 'answered_rate_th',
        7: 'answered_rate_ru'
      },
      agentsByMessageState: false
    }
  },
  methods: {
    sortColumnByDate(date, language){
      const sortIconEl = document.getElementById('sortIcon-'+date+language)
      if(sortIconEl.classList.contains('fa-sort')) {
        this.sortOrder = 'asc'
      } else if(sortIconEl.classList.contains('fa-sort-up')) {
        this.sortOrder = 'desc'
      } else {
        this.sortOrder = 'asc'
      }
      if(this.half_montly_data) {
        this.half_montly_data.sort((x, y) => {
          let comparison = null
          if(date) {
            const selectedX = parseInt(x.data.find((agent) => agent.day === date)['answered_rate'+language])
            const selectedY = parseInt(y.data.find((agent) => agent.day === date)['answered_rate'+language])
            sortIconEl.classList.remove('fa-sort')
            if(this.sortOrder === 'desc') {
              sortIconEl.classList.add('fa-sort-down')
              comparison = (selectedX - selectedY)* -1
            } else {
              sortIconEl.classList.add('fa-sort-up')
              comparison = selectedX - selectedY
            }
          }
          return comparison
        })
      }
    },
    switchTab() {
      // Reset sorted data and icons
      if(this.half_montly_data) {
        this.sortOrder = ''
        this.half_montly_data.sort((x, y) => x.agent_id - y.agent_id)
        const sortIconEl = document.getElementsByClassName('sort-icon')
        for(let i=0; i < sortIconEl.length; i++) {
          sortIconEl[i].classList.remove('fa-sort-down', 'fa-sort-up')
          sortIconEl[i].classList.add('fa-sort')
        }
      }
      // Generate list Agents
      this.$nextTick(() => {
        this.hideAgentsByMessage()
      })
    },
    hideAgentsByMessage() {
      if(this.agentsByMessageState) {
        this.half_montly_data.map(montly => {
          const trEl = document.getElementById(this.tabTitles[this.tabIndex]+'-'+montly.agent_id)
          const rateStatus = montly.data.filter(data => data[this.tabTitles[this.tabIndex]] === '-')
          trEl.style.display = (montly.data.length === rateStatus.length)? 'none' : ''
        })
      } else { // Show all Agents HTML
        this.half_montly_data.map(montly => {
          const trEl = document.getElementById(this.tabTitles[this.tabIndex]+'-'+montly.agent_id)
          trEl.style.display = ''
        })
      }
    }
  }
}
</script>

<style scoped>
  a{
    color: #000;
  }
  @media (min-width: 1000px) {
    tbody {
      display:block;
      height:650px;
      overflow:auto;
    }
    thead, tbody tr {
      display:table;
      width:100%;
      table-layout:fixed;
    }
    .cell-detail-md {
      width: 8%;
    }
  }
</style>
