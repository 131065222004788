<template>
  <div class="answer-listCard">
      <div>{{ $t('answer.content') }}:</div>
      <div>
        <b-form-input v-model="header_title" class="mb-1"
                      @input="validationCheck"
                      :placeholder="$t('answer.enterCardTitle')"
                      :state="!$v.header_title.$error"/>
        <b-form-invalid-feedback v-if="$v.header_title.$error">
          {{ $t('webhook.required') }} &
          {{ $t('answer.maxTxtLen', {counter: $v.header_title.$params.maxLength.max}) }}
        </b-form-invalid-feedback>
        <div class="input-group mb-1">
          <b-form-input v-model="header_imageUrl"
                        @input="validationCheck"
                        :placeholder="$t('answer.enterImageURL')"
                        :state="!$v.header_imageUrl.$error"
                        ref="headerImageUrl" />
          <div class="input-group-append">
            <a
              class="btn btn-info"
              :class="{ disabled: !header_imageUrl || $v.header_imageUrl.$error }"
              :href="header_imageUrl" target="_blank"
              :title="$t('answer.preView')"
            ><span class="fas fa-eye mt-1"/>
            </a>
            <a class="btn btn-primary text-white" v-b-tooltip.hover
               :title="$t('answer.browseImage')"
               @click="loadHeaderImageUrlFiles">
              <i class="far fa-images"></i>
            </a>
          </div>
          <b-form-invalid-feedback v-if="$v.header_imageUrl.$error">
            {{ $t('answer.invalidURL') }}
          </b-form-invalid-feedback>
        </div>
        <file-contents ref="headerImageUrlFiles"
                       :img-url-el="$refs.headerImageUrl"
                       @img-url-model="header_imageUrl = $event"></file-contents>
      </div>

      <div>{{ $t('answer.list') }}
      <button
        class="btn btn-success btn-sm mb-1"
        style="width: 40px;"
        @click.prevent="addListCardText()">
        <i class="fa fa-plus"></i>
      </button>
        ({{ listCardText.length + " / 5" }})
      </div>

      <div class="scroll">
        <div v-for="(replyText, indexText) in listCardText" :key="indexText">
          <b-card class="mb-1">
            <div>
              <div class="card-button-title mb-1 mr-1" style="width: 70%">
                <b-form-input v-model="replyText.item_title"
                              @input="validationCheck"
                              :placeholder="$t('answer.enterListTitle')"
                              :state="!$v.listCardText.$each[indexText].item_title.$error"/>
                <b-form-invalid-feedback v-if="$v.listCardText.$each[indexText].item_title.$error">
                  {{ $t('webhook.required') }} &
                  {{ $t('answer.maxTxtLen', {counter: $v.listCardText.$each[indexText].item_title.$params.maxLength.max}) }}
                </b-form-invalid-feedback>
              </div>
              <div>
                <button
                  class="btn btn-danger"
                  style="width: 45px; height: 37px;"
                  :disabled="listCardText.length == 1"
                  @click.prevent="deleteListCardText(indexText)">
                  <i class="fa fa-minus"></i>
                </button>
              </div>
              <b-form-textarea
                class="mb-1"
                v-model="replyText.item_description"
                :placeholder="$t('answer.listCardDescription')"
                :rows="2"
                :max-rows="6"
                @input="validationCheck"
                :state="!$v.listCardText.$each[indexText].item_description.$error"/>
              <b-form-invalid-feedback v-if="$v.listCardText.$each[indexText].item_description.$error">
                {{ $t('answer.maxTxtLen', {counter: $v.listCardText.$each[indexText].item_description.$params.maxLength.max}) }}
              </b-form-invalid-feedback>
              <div class="input-group mb-1">
                <b-form-input v-model="replyText.item_imageUrl"
                              @input="validationCheck"
                              :placeholder="$t('answer.listCardImageURL')"
                              :state="!$v.listCardText.$each[indexText].item_imageUrl.$error"
                              ref="replyTextItemImageUrl" />
                <div class="input-group-append">
                  <a
                    class="btn btn-info"
                    :class="{ disabled: !replyText.item_imageUrl || $v.listCardText.$each[indexText].item_imageUrl.$error }"
                    :href="replyText.item_imageUrl" target="_blank"
                    :title="$t('answer.preView')"
                  ><span class="fas fa-eye mt-1"/>
                  </a>
                  <a class="btn btn-primary text-white" v-b-tooltip.hover
                     :title="$t('answer.browseImage')"
                     @click="loadReplyTextItemImageUrlFiles(indexText)">
                    <i class="far fa-images"></i>
                  </a>
                </div>
                <b-form-invalid-feedback v-if="$v.listCardText.$each[indexText].item_imageUrl.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
              </div>
              <file-contents ref="replyTextItemImageUrlFiles"
                             :img-url-el="$refs.replyTextItemImageUrl"
                             @img-url-model="replyText.item_imageUrl = $event"></file-contents>
              <b-form-input v-model="replyText.item_link" class="mb-1"
                            @input="validationCheck"
                            :placeholder="$t('answer.listCardImageLink')"
                            :state="!$v.listCardText.$each[indexText].item_link.$error" />
                <b-form-invalid-feedback v-if="$v.listCardText.$each[indexText].item_link.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
            </div>
          </b-card>
        </div>
      </div>

      <div>{{ $t('answer.cardButtons') }}</div>
      <div>
          <b-form-select v-model="listCardButton[0].action" class="mb-2"
                         :options="actionOptions"
                         @input="validationCheck"/>
          <b-form-input v-model="listCardButton[0].label"
                        @input="validationCheck"
                        :placeholder="$t('answer.cardButtonTitle')"
                        :state="!$v.listCardButton.$each[0].label.$error"/>
          <b-form-invalid-feedback v-if="$v.listCardButton.$each[0].label.$error">
            {{ $t('webhook.required') }} &
            {{ $t('answer.maxTxtLen', {counter: $v.listCardButton.$each[0].label.$params.maxLength.max}) }}
          </b-form-invalid-feedback>
          <b-form-input v-if="listCardButton[0].action == 'webLinkUrl'" class="mb-2"
                        v-model="listCardButton[0].text"
                        @input="validationCheck"
                        :placeholder="$t('answer.simpleBasicCardLink')"
                        :state="!$v.listCardButton.$each[0].text.$error"/>
          <b-form-invalid-feedback v-if="$v.listCardButton.$each[0].text.$error && listCardButton[0].action == 'webLinkUrl'">
            {{ $t('webhook.required') }} &
            {{ $t('answer.invalidURL') }}
          </b-form-invalid-feedback>
          <b-form-input v-if="listCardButton[0].action == 'osLink'" class="mb-2"
                        v-model="listCardButton[0].text"
                        @input="validationCheck"
                        :placeholder="$t('answer.simpleBasicCardLink')"/>
          <b-form-input v-if="listCardButton[0].action == 'phoneNumber'" class="mb-2"
                        v-model="listCardButton[0].text"
                        @input="validationCheck"
                        :placeholder="$t('answer.simpleBasicCardPhoneNumber')"
                        :state="!$v.listCardButton.$each[0].text.$error"/>
          <b-form-invalid-feedback v-if="$v.listCardButton.$each[0].text.$error && listCardButton[0].action == 'phoneNumber'">
            {{ $t('webhook.required') }} &
            {{ $t('answer.mustBeNumber') }}
          </b-form-invalid-feedback>
          <b-form-input v-if="listCardButton[0].action == 'share'" :disabled="true" :placeholder="$t('answer.listCardShare')" class="mb-2"/>
      </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, url, maxLength, numeric} from 'vuelidate/lib/validators'
import {ListCardData,ListCardText,ListCardButton} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  data() {
    return {
      answerListCard:'',
      header_title:'',
      header_imageUrl:'',
      listCardText:[new ListCardText()],
      listCardButton:[new ListCardButton()],
      actionOptions:[
        { value: 'webLinkUrl', text: 'Web_Link'},
        { value: 'osLink', text: 'OS_Link'},
        { value: 'phoneNumber', text: 'Phone_Number'},
        { value: 'share', text: 'Share'}
      ],
      maxTitle:20,
      maxDescription:76,
      maxLabel:14,
    }
  },
  validations() {
    return {
      header_title: {
        required,
        maxLength: maxLength(this.maxTitle)
      },
      header_imageUrl: {
        url
      },
      listCardText: {
        $each: {
          item_title: {
            required,
            maxLength: maxLength(this.maxTitle)
          },
          item_description: {
            maxLength: maxLength(this.maxDescription)
          },
          item_imageUrl: {
            url
          },
          item_link: {
            url
          }
        }
      },
      listCardButton: {
        $each: {
          label: {
            required,
            maxLength: maxLength(this.maxLabel)
          },
          text: {
            required,
            inputText: this.listCardButtonTextRule()
          }
        }
      }
    }
  },
  methods:{
    addListCardText(){
      if(this.listCardText.length < 5){
        this.listCardText.push(new ListCardText())
        this.validationCheck()
      }
    },
    deleteListCardText(indexText){
      this.listCardText.splice(indexText, 1)
      this.validationCheck()
    },
    inputValue(){
      this.answerListCard = new ListCardData(
            this.header_title.replace(illegalExp, "").trim(),
            this.header_imageUrl.replace(illegalExp, "").trim(),
            this.listCardText,
            this.listCardButton)
      for(let cardText of this.answerListCard.listCardText){
        cardText.item_description = cardText.item_description.replace(illegalExp, "").trim()
        cardText.item_imageUrl = cardText.item_imageUrl.replace(illegalExp, "").trim()
        cardText.item_link = cardText.item_link.replace(illegalExp, "").trim()
        cardText.item_title = cardText.item_title.replace(illegalExp, "").trim()
      }
      for(let cardButton of this.answerListCard.listCardButton){
        cardButton.label = cardButton.label.replace(illegalExp, "").trim()
        cardButton.text = cardButton.text.replace(illegalExp, "").trim()
      }
      return this.answerListCard
    },
    setValue(selectedResponseMessage){
      this.header_title = selectedResponseMessage.data.header_title
      this.header_imageUrl = selectedResponseMessage.data.header_imageUrl
      this.listCardButton = selectedResponseMessage.data.listCardButton
      this.listCardText = selectedResponseMessage.data.listCardText

    },
    initializeValue(){
      this.answerListCard=null,
      this.header_title='',
      this.header_imageUrl='',
      this.listCardText=[new ListCardText()],
      this.listCardButton=[new ListCardButton()]
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    listCardButtonTextRule() {
      let rule = false
      this.listCardButton.forEach((button) => {
        switch (button.action) {
          case 'webLinkUrl':
            rule = url
            break
          case 'phoneNumber':
            rule = numeric
            break
        }
      })
      return rule
    },
    loadHeaderImageUrlFiles() {
      this.$refs.headerImageUrlFiles.loadImages()
    },
    loadReplyTextItemImageUrlFiles(index) {
      this.$refs.replyTextItemImageUrlFiles[index].loadImages()
    }
  }
}
</script>
