<template>
  <li>
    <div
      class="label my-1 p-1"
      @click="$emit('show-detail', intent)"
    >
      {{ intent.name }}
    </div>
  </li>
</template>

<script>
  export default {
    props: ['intent']
  }
</script>

