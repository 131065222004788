import Vue from 'vue'
import { UrlBuilder } from './util/url-builder'
import { API_URL } from './resource/urls'
import { WebhookLogController } from './controller/webhook-log'

import WebhookLog from './components/WebhookLog.vue'
import HistoryCalendar from './components/HistoryCalendar.vue'
import Pagination from './components/Pagination.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import ChatBox from './components/ChatBox.vue'
import LanguageChangeChatWindow from "./components/LanguageChangeChatWindow.vue"
import CodeEditor from './components/CodeEditor.vue'
import TestChat from './components/TestChat.vue'
import FormTags from './components/common/FormTags.vue'
import VCalendar from 'v-calendar'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'


export const appWebhookLog = (i18n) => {
  Vue.use(VCalendar)
  Vue.use(VueMoment, {
    moment,
  })

  /* コントローラ */
  const controller = new WebhookLogController(
    (new UrlBuilder(API_URL)).build(),
    window.i18nContext,
    window.requestContext,
    window.requestContext.available_platform,
  )

  /* コンポーネント登録 */
  Vue.component('webhook-log', WebhookLog)
  Vue.component('history-calendar', HistoryCalendar)
  Vue.component('pagination', Pagination)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('chat-box', ChatBox)
  Vue.component('language-change-chat-window', LanguageChangeChatWindow)
  Vue.component('code-editor', CodeEditor)
  Vue.component('test-chat', TestChat)
  Vue.component('form-tags', FormTags)

  new Vue({
    el: '#app-webhook-log',
    i18n: i18n,
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    }
  })
}
