<template>
<div>
<div class="row mt-1">
  <synchronize
    class="col-sm-3 mb-1"
    @start-synchronize="startSynchronize"
    :userType="controller.userType">
  </synchronize>
  <language-change-keyword
    class="col-sm-4 mb-1"
    :supported-languages="controller.supportedLanguages"
    :language="controller.language">
  </language-change-keyword>
  <mode-change-intent
    class="col-sm-3 mb-1"
    :supported-languages="controller.supportedLanguages"
    :language="controller.language">
  </mode-change-intent>
</div>

<div
  class="row"
  @keyup.up="onArrowKeyUp($event)"
  @keyup.down="onArrowKeyDown($event)"
  @keyup.right="onArrowKeyRight($event)"
  @keyup.left="onArrowKeyLeft($event)">
  <keyword-category
    class="page-category col-md-3"
    ref="keywordCategory"
    :categories="controller.categories"
    :selected-category="controller.selectedCategory"
    :category-model="controller.categoryModel"
    :isAllSelected="controller.isAllSelected"
    :onlyAlphanumeric="true"
    :currentDisplayedCategory="controller.currentDisplayedCategory"
    :categorydesc="controller.categorydesc"
    :userType = "controller.userType"
    :excelExportUrl="controller.excelExportUrl"
    @keyword-category-order="keywordCategoryOrder"
    @select-category="selectCategory"
    @save-category="saveCategory"
    @delete-category="deleteCategory"
    @arrowKey-flag="setArrowKeyFlag"
    @modalPopupCheck-flag="setModalPopupCheckFlag"
    @prebuilt-preview="prebuiltPreview"
  >
  </keyword-category>

  <keyword
    class="col-md-4"
    ref="keyword"
    :categoryJoin="categoryJoinData"
    :keywords="controller.keywords"
    :allKeyword="controller.allKeyword"
    :selected-category="controller.selectedCategory"
    :selected-keyword="controller.selectedKeyword"
    :category-model="controller.categoryModel"
    :categories="controller.categories"
    :desc="controller.desc"
    :userType = "controller.userType"
    @keyword-order="keywordOrder"
    @download-json="downloadJson"
    @import-json="importJson"
    @select-keyword="selectKeyword"
    @save-keyword="saveKeyword"
    @delete-keyword="deleteKeyword"
    @arrowKey-flag="setArrowKeyFlag"
    @modalPopupCheck-flag="setModalPopupCheckFlag"
    @show-intent-list-modal="showIntentListInUseModal">
  </keyword>
  <keyword-value
    class="col-md-5"
    ref="keywordValue"
    :keywordValues="controller.keywordValues"
    :language="controller.language"
    :selected-keyword="controller.selectedKeyword"
    :selected-keyword-value="controller.selectedKeywordValue"
    :userType = "controller.userType"
    :searchKeywordValuesResult = "controller.searchKeywordValuesResult"
    @select-keyword-value="selectKeywordValue"
    @edit-keyword-value="editKeywordValue"
    @delete-keyword-value="deleteKeywordValue"
    @arrowKey-flag="setArrowKeyFlag"
    @modalPopupCheck-flag="setModalPopupCheckFlag"
    @search-keyword="searchKeyword">
  </keyword-value>

  <test-chat
    :controller="controller.testChatController"
    @chat-box-state="checkChatboxState"
  >
  </test-chat>

  <b-modal
    :title="$t('keyword.importKeyword')"
    ref="importModal"
    header-class="bg-info text-light"
    @ok="importJsonOk"
    :ok-disabled="!importFile"
    no-close-on-esc
    no-close-on-backdrop>
    <div>
      <b-form-file
        v-model="importFile"
        accept=".json"
        :state="Boolean(importFile)"
        :placeholder="$t('fileManagement.chooseFile')">
      </b-form-file>
      <div class="mt-3">
        <span>{{ $t('fileManagement.selectedFile') }}: {{importFile && importFile.name}}</span>
        <b-btn v-b-modal.preview-modal class="show-compare mb-1" style="float:right" @click="importFilePreview" :disabled="!importFile">
          <span>{{ $t('general.preview') }}</span>
        </b-btn>
      </div>
    </div>
  </b-modal>

  <b-modal
    :title="$t('errors.errorTitle')"
    ref="Error"
    header-class="bg-danger text-light"
    body-class="text-danger"
    no-close-on-esc
    no-close-on-backdrop ok-only>
    <div>
      <p>{{errorMsg}}</p>
    </div>
  </b-modal>

  <success-modal
    ref="syncCompletedModal"
    :title="$t('synchronize.result')"
    :message="$t('synchronize.completed')"
    @key-press-enter="closeSyncCompletedModal">
  </success-modal>

  <b-modal
    :title="$t('synchronize.result')"
    ref="importConfirmModal"
    header-class="bg-success text-light"
    body-class="text-success"
    @keydown.native.enter="importComplete"
    no-close-on-esc
    no-close-on-backdrop ok-only>
    <div class="text-center">
      <p>Completed</p>
    </div>
  </b-modal>

  <b-modal
    id="preview-modal"
    title="Preview"
    class="preview-modal"
    size="lg"
    @shown="$refs.prebuiltPreviewKeyword.shown()"
    @hidden="$refs.prebuiltPreviewKeyword.hidden()"
    ok-only
  >
    <previewer
      ref="prebuiltPreviewKeyword"
      :keywordData="keywordData"
      :preKeyword="preKeyword"
    >
    </previewer>
  </b-modal>

  <progress-modal
    ref="progressModal"
    :message="message">
  </progress-modal>
  <error-modal
    ref="errorModal"
    :message="message"
    :isCard="isCard"
    :cardErrorMsg="cardErrorMsg"
    :isBotBroken="isBotBroken"
    :botBrokenMessage="botBrokenMessage"
    @train="startSynchronize">
  </error-modal>
  <intent-list-inuse-modal
    :language="controller.language"
    :selectedIntentCategories="controller.selectedIntentCategories"
    ref="intentListInUseModal">
  </intent-list-inuse-modal>
  <b-alert
    v-if="inputTransportResult.message"
    fade
    :show="3"
    dismissible
    :variant="inputTransportResult.type"
    @dismissed="inputResetMessage"
    class="stick-to-bottom"
  >
    {{ inputTransportResult.message }}
  </b-alert>

</div>
</div>
</template>
<script>
import csrfToken from '../util/csrf-token'
import { KeywordCategory } from '../model/keyword'
import { SynchronizeStatus } from '../model/synchronize'
import {DragMe} from '../util/drag-me'

export default {
  props: ['controller'],
  data() {
    return {
      message: '',
      errorMsg: '',
      arrowKeyFlag:0,
      importFile: null,
      modalPopupCheckFlag:false,
      keywordData: null,
      preKeyword:null,
      isCard:false,
      cardErrorMsg:[],
      isChatboxOpen: false,
      isBotBroken:false,
      botBrokenMessage:'',
      inputTransportResult: {
        message: null,
        type: null
      }
    }
  },
  computed: {
    isSynchronizing: function () {
      return this.controller.synchronizeController.isSynchronizing
    },
    synchronizeStatusMessage: function () {
      return this.controller.synchronizeController.synchronizeStatusMessage
    },
    categoryJoinData: function(){
      return this.createCategoryPathIndex(this.controller.categories)
    },
    inputSuccessMessage() {
      return (count) => this.$t('inputCompletion.transferSucceeded') + count
    }
  },
  updated(){
    if(this.modalPopupCheckFlag !== true){
        this.$nextTick(()=>{
        if(this.arrowKeyFlag === 1)
          this.$refs.keywordCategory.$refs.focusOn.focus()
        else if(this.arrowKeyFlag === 2)
          this.$refs.keyword.$refs.focusOn.focus()
        else if(this.arrowKeyFlag === 3)
          this.$refs.keywordValue.$refs.focusOn.focus()
      })
    }
  },
  methods: {
    async inputPredictionTransfer () {
      try {
        const result = await this.controller.inputPredictionTransfer(csrfToken.getCsrfTokenFromCookie(document.cookie))
        this.inputTransportResult.message = this.inputSuccessMessage(result.data)
        this.inputTransportResult.type = 'success'
      } catch (e) {
        this.inputTransportResult.message =  e.response.data instanceof Array ? e.response.data[0] : e.response.data.detail
        this.inputTransportResult.type = 'danger'
      }
    },
    inputResetMessage () {
      this.inputTransportResult = {
        message: null,
        type: null
      }
    },
    prebuiltPreview(category) {
      try {
        this.keywordData = JSON.stringify(category.value.data)
        this.preKeyword = !!this.keywordData
      } catch (e) {
        this.keywordData = null
        this.preKeyword = null
      }
    },
    // Export keywords
    downloadJson(id){
      window.location.href= this.controller.makeDownloadUrl(id)
    },
    //Import keywords
    importJson(){
      this.$refs.importModal.show()
    },
    importFilePreview(){
      let reader = new FileReader()
      let self = this
      reader.onload = (evt) => {
        self.keywordData = evt.target.result
        self.preKeyword = !!self.keywordData
      }
      reader.readAsBinaryString(this.importFile)
    },
    importJsonOk(){
      let reader = new FileReader()
      let this2 = this
      reader.onload = function(evt){
        let result, message, promise
        [result, message, promise] = this2.controller.importJson(evt.target.result, csrfToken.getCsrfTokenFromCookie(document.cookie))
        if (!result) {
          this2.showError(message)
        } else {
          this2.showProgress(message)
          let this3 = this2
          promise.then(()=>{
            this2.hideProgress()
            this2.$refs.importConfirmModal.show()
          })
            .catch(function (error) {
              this3.errorMsg=error.response.data[0]
              this3.$refs.Error.show()
              this3.hideProgress()
            })
        }
      }
      reader.readAsBinaryString(this.importFile)
    },
    importComplete(){
      this.$refs.importConfirmModal.hide()
    },
    keywordCategoryOrder(name){
      if (name == "-name") {
        this.controller.categorydesc=false
      }
      else {
        this.controller.categorydesc=true
      }
      this.controller.categories.sort(this.controller.dynamicSort(name))
    },
    keywordOrder(name){
      if (name == "-name") {
        this.controller.desc=false
      }
      else {
        this.controller.desc=true
      }
      this.controller.keywords.sort(this.controller.dynamicSort(name))
    },
    // メソッド側でthisをcontrollerにするため、methods経由で呼ぶ
    selectCategory(category) {
      this.controller.selectCategory(category)
    },
    saveCategory(category) {
      let result, message, promise
      [result, message, promise] = this.controller.saveCategory(
        category,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          this.$refs.keywordCategory.selectChild(this.controller.selectedCategory)
        })
          .catch(function (error) {
            self.errorMsg=error.response.data[0]
            self.$refs.Error.show()
            self.hideProgress()
          })
      }
    },
    deleteCategory(category) {
      // カテゴリーの削除を保存
      let result, message, promise
      [result, message, promise] = this.controller.deleteCategory(
        category,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          this.$refs.keywordCategory.selectChild(this.controller.selectedCategory)
        })
        .catch(function (error) {
            self.errorMsg=error.response.data[0]
            self.$refs.Error.show()
            self.hideProgress()
          })
      }
    },
    setArrowKeyFlag(arrowKeyFlag){
      this.arrowKeyFlag = arrowKeyFlag
    },
    setModalPopupCheckFlag(modalPopupCheckFlag){
      this.modalPopupCheckFlag = modalPopupCheckFlag
    },
    onArrowKeyUp(event){
      if(this.isChatboxOpen || this.modalPopupCheckFlag) {
        event.preventDefault()
        return false
      } else {
        if(this.arrowKeyFlag === 1){
          if(this.controller.isAllSelected)
            return null
          this.$refs.keywordCategory.onArrowUp(this.controller.selectedCategory)
        }
        else if(this.arrowKeyFlag === 2){
          this.$refs.keyword.onArrowUp(this.controller.selectedKeyword)
        }
        else if(this.arrowKeyFlag === 3){
          this.$refs.keywordValue.onArrowUp(this.controller.selectedKeywordValue)
        }
      }
    },
    onArrowKeyDown(event){
      if(this.isChatboxOpen || this.modalPopupCheckFlag) {
        event.preventDefault()
        return false
      } else {
        if(this.arrowKeyFlag === 1){
          if(this.controller.isAllSelected){
            if(this.controller.categories.length >= 0)
              this.$refs.keywordCategory.selectChild(this.controller.categories[0])
            else
              return null
          }
          else
            this.$refs.keywordCategory.onArrowDown(this.controller.selectedCategory)
        }
        else if(this.arrowKeyFlag === 2){
          this.$refs.keyword.onArrowDown(this.controller.selectedKeyword)
        }
        else if(this.arrowKeyFlag === 3){
          this.$refs.keywordValue.onArrowDown(this.controller.selectedKeywordValue)
        }
      }
    },
    onArrowKeyRight(event){
      if(this.isChatboxOpen || this.modalPopupCheckFlag) {
        event.preventDefault()
        return false
      } else {
        if(this.arrowKeyFlag === 1){
          if(typeof this.controller.keywords[0] !== 'object'){return}
          this.$refs.keyword.onArrowRight(this.controller.keywords[0])
          this.arrowKeyFlag = 2
        }
        else if(this.arrowKeyFlag === 2){
          if(typeof this.controller.keywordValues[0] !== 'object'){return}
          this.$refs.keywordValue.onArrowRight(this.controller.keywordValues[0])
          this.arrowKeyFlag = 3
        }
      }
    },
    onArrowKeyLeft(event){
      if(this.isChatboxOpen || this.modalPopupCheckFlag) {
        event.preventDefault()
        return false
      } else {
        if(this.arrowKeyFlag === 3){
          this.$refs.keyword.onArrowLeft(this.controller.selectedKeyword)
          this.arrowKeyFlag = 2
        }
        else if(this.arrowKeyFlag === 2){
          this.$refs.keywordCategory.onArrowLeft(this.controller.selectedCategory)
          this.arrowKeyFlag = 1
        }
      }
    },
    resolveCategoryPath(category, categoryIdToCategory) {
      if (category.parentId == null) {
        return [category]
      } else {
        const parentCategory = categoryIdToCategory[category.parentId]
        return this.resolveCategoryPath(parentCategory, categoryIdToCategory).concat([category])
      }
    },
    createCategoryPathIndex(categories) {
      let flat = KeywordCategory.categoriesAsFlat(categories)
      // categoryId -> categoryを作成
      let categoryIdToCategory = {}
      for (const [nestedLevel,category] of flat) {
        categoryIdToCategory[category.id] = category
      }
      // category -> カテゴリツリーのパス生成
      let categoryIdToPath = {}
      for (const [nestedLevel,category] of flat) {
        const categoryPath = this.resolveCategoryPath(category, categoryIdToCategory)
        categoryIdToPath[category.id] = categoryPath.map((c) => {return c.name}).join("_")
      }
      return categoryIdToPath
    },
    selectKeyword(keyword) {
      this.controller.selectKeyword(keyword)
    },
    saveKeyword(keyword) {
      let result, message, promise
      [result, message, promise] = this.controller.saveKeyword(
        keyword,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          this.$refs.keyword.selected(this.controller.selectedKeyword)
        })
          .catch(function (error) {
            self.errorMsg=error.response.data[0]
            self.$refs.Error.show()
            self.hideProgress()
          })
      }
    },
    deleteKeyword(keyword) {
      // キーワード値の削除を保存
      let result, message, promise
      [result, message, promise] = this.controller.deleteKeyword(
        keyword,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          if(this.controller.selectedKeyword&&this.controller.selectedKeyword.categoryId === this.controller.selectedCategory.id)
            this.$refs.keyword.selected(this.controller.selectedKeyword)
          else
            this.$refs.keywordCategory.selectChild(this.controller.selectedCategory)
        })
          .catch(function (error) {
            self.errorMsg=error.response.data[0]
            self.$refs.Error.show()
            self.hideProgress()
          })
      }
    },
    selectKeywordValue(keywordValue) {
      this.controller.selectKeywordValue(keywordValue)
    },
    editKeywordValue(keywordValue) {
      // キーワード値の変更を保存
      let result, message, promise
      [result, message, promise] = this.controller.saveKeywordValue(
        keywordValue,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          this.$refs.keywordValue.clickSelectedKeywordValue(this.controller.selectedKeywordValue)
        })
          .catch(function (error) {
            self.errorMsg=error.response.data['value'][0]
            self.$refs.Error.show()
            self.hideProgress()
          })
      }
    },
    deleteKeywordValue(keywordValue) {
      // キーワード値の削除を保存
      let result, message, promise
      [result, message, promise] = this.controller.deleteKeywordValue(
        keywordValue,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          if(this.controller.selectedKeywordValue)
            this.$refs.keywordValue.clickSelectedKeywordValue(this.controller.selectedKeywordValue)
          else
            this.$refs.keyword.selected(this.controller.selectedKeyword)
        })
          .catch(function (error) {
            self.errorMsg=error.response.data[0]
            self.$refs.Error.show()
            self.hideProgress()
          })
      }
    },
    showProgress(message) {
      this.isCard = false
      this.isBotBroken = false
      this.botBrokenMessage = ''
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    showError(message, isCard=false) {
      if(!isCard){this.message = message}
      this.isCard = isCard
      this.$refs.errorModal.show()
    },
    startSynchronize(isCard=false) {
      this.showProgress("...")
      // 同期を開始したときにpromiseを受け取り、そのpromiseから同期が開始したか、メッセージを受け取る
      let self = this
      this.controller.startSynchronize(
        csrfToken.getCsrfTokenFromCookie(document.cookie),
        isCard
      )
        .then((result) => {
          if (result.wasSuccessful) {
            self.showProgress(result.message)
          } else {
            self.hideProgress()
            self.showError(result.message)
          }
        })
        .catch((error) => {
          self.hideProgress()
          if( 'message' in error.response.data){
            self.showError(error.response.data.message)
          }else{
            this.cardErrorMsg = error.response.data
            self.showError(this.cardErrorMsg, true)
          }
        })
    },
    closeSyncCompletedModal(){
      this.$refs.syncCompletedModal.hide()
    },
    showIntentListInUseModal(keyword) {
      this.controller.showIntentListInUse(keyword)
      this.$refs.intentListInUseModal.show()
    },
    searchKeyword(searchString) {
      this.controller.searchKeywords(searchString)
    },
    checkChatboxState(status) {
      this.isChatboxOpen = status
    },
    checkAgentAlive() {
      const query = {
        'text': '@#$%^aduwenaf--1389371',
        'language':this.controller.getLanguageCode()
      }
      const token = csrfToken.getCsrfTokenFromCookie(document.cookie)
      this.controller.checkAgentState(query, token).then(response => {
        this.isBotBroken = false
        if(!response){
          this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
        }
      }).catch(error => {
        console.log('[intentEditor->checkAgentAlive]: ', error)
        this.isBotBroken = false
        this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
      })
    }
  },
  watch: {
    isSynchronizing: {
      handler: function(isSynchronizing) {
        // 同期終了時にプログレスを閉じる
        if (!isSynchronizing) {
          this.hideProgress()
        }
      }
    },
    synchronizeStatusMessage: {
      handler: function(synchronizeStatusMessage) {
        // ステータスメッセージが変更されたらmessageに同期
        if (synchronizeStatusMessage != null) {
          this.message = synchronizeStatusMessage
          if(SynchronizeStatus.ERROR == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.isBotBroken = true
            this.showError(this.message)
            this.checkAgentAlive()
          }
          else if(SynchronizeStatus.BUSY == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.showError(this.message)
          }
          else if(SynchronizeStatus.FINISH == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.$refs.syncCompletedModal.show()
            if(this.controller.auto_input_predict){
              this.inputPredictionTransfer()
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
  .stick-to-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
  }
</style>
