import { LANGUAGE_LABELS } from "../resource/language"
import { JsonCondition } from "./util"

export class AgentState {
  constructor(
    is_operator_available=true,
    matched_operator_available_condition=null,
    operator_busy_level=0,
    operator_busy_level_updated_at=null
  ) {
    this.isOperatorAvailable = is_operator_available
    this.matchedOperatorAvailableCondition = null
    if (matched_operator_available_condition) {
      this.matchedOperatorAvailableCondition = AgentOperatorAvailableCondition.fromData(matched_operator_available_condition)
    }
    this.operatorBusyLevel = operator_busy_level || 0
    this.operatorBusyLevelUpdatedAt = operator_busy_level_updated_at || null
  }

  toData() {
    return {
      operator_busy_level: this.operator_busy_level
    }
  }
}

AgentState.fromData = function (data) {
  return new AgentState(
    !!data.is_operator_available,
    data.matched_operator_available_condition || null,
    data.operator_busy_level || 0,
    data.operator_busy_level_updated_at || null
  )
}

export class AgentOperatorSessionConfig {
  constructor(notification=null, messages={}, workdays=[1, 2, 3, 4, 5], available_conditions=[]) {
    this.notification = notification
    this._originMessages = messages
    this.messages = AgentOperatorSessionMessages.fromData(messages)
    this._originWorkdays = workdays
    this.workdays = [1, 2, 3, 4, 5]
    if (Array.isArray(workdays)) {
      this.workdays = [...workdays]
    }
    this._originalAvailableConditions = available_conditions
    this.availableConditions = available_conditions.map((condition) => AgentOperatorAvailableCondition.fromData(condition))
  }

  cloneAvailableConditions() {
    return Array.isArray(this._originalAvailableConditions) ? this._originalAvailableConditions.map((condition) => AgentOperatorAvailableCondition.fromData(condition)) : []
  }

  cloneWorkdays() {
    if (Array.isArray(this._originWorkdays)) {
      return [...this._originWorkdays]
    }
    return [1, 2, 3, 4, 5]
  }

  cloneMessages() {
    return AgentOperatorSessionMessages.fromData(this._originMessages)
  }
}

AgentOperatorSessionConfig.fromData = function (data) {
  return new AgentOperatorSessionConfig(
    data?.notification || null,
    data?.messages || {},
    data?.workdays || [1, 2, 3, 4, 5],
    data?.available_conditions || [],
  )
}

export class AgentOperatorAvailableCondition extends JsonCondition {
  // 設定を簡素化するために、継承して一部の項目を複合

  _extractData(data) {
    super._extractData(data)

    this.value = null
    this.targetType = null
    this.targetContext = null
    this.targetValue = null

    if (this._value === 'now') {
      if (this._valueType === 'date') {
        this.value = "now_date"
      } else if (this._valueType === 'time') {
        this.value = "now_time"
      } else if (this._valueType === 'weekday') {
        this.value = "now_weekday"
      }
    }

    if (this._targetType === 'context') {
      if (this._targetContext === 'holidays') {
        this.targetType = 'holidays'
      } else if (this._targetContext === 'workdays') {
        this.targetType = 'workdays'
      }
    } else {
      if (this._targetValueType === 'time') {
        this.targetType = 'time'
      }
      this.targetValue = this._targetValue
    }
  }

  toData() {
    // 複合した項目を分解して保存

    if (this.value === "now_date") {
      this._value = "now"
      this._valueType = "date"
    } else if (this.value === "now_time") {
      this._value = "now"
      this._valueType = "time"
    } else if (this.value === "now_weekday") {
      this._value = "now"
      this._valueType = "weekday"
    }

    if (this.targetType === 'holidays') {
      this._targetType = 'context'
      this._targetContext = 'holidays'
    } else if (this.targetType === 'workdays') {
      this._targetType = 'context'
      this._targetContext = 'workdays'
    } else if (this.targetType === 'time') {
      this._targetType = 'value'
      this._targetValueType = 'time'
      this._targetValue = this.targetValue
    }

    return super.toData()
  }

  getDescText(i18nParse) {
    if (this.always) {
      return i18nParse("general.alwaysCondition")
    }

    let valueDesc = ""
    let targetDesc = ""
    let checkTypeDesc = ""

    if (this.value === "now_date") {
      valueDesc = i18nParse("general.currentDate")
    } else if (this.value === "now_time") {
      valueDesc = i18nParse("general.currentTime")
    } else if (this.value === "now_weekday") {
      valueDesc = i18nParse("general.currentWeekday")
    }

    if (this.targetType === 'holidays') {
      targetDesc = i18nParse("general.holidays")
    } else if (this.targetType === 'workdays') {
      targetDesc = i18nParse("general.workdays")
    } else if (this.targetType === 'time') {
      targetDesc = this.targetValue
    }

    const checkTypeDescMap = {
      "in": i18nParse("general.checkTypes.in"),
      "not_in": i18nParse("general.checkTypes.not_in"),
      "==": i18nParse("general.checkTypes.eq"),
      ">": i18nParse("general.checkTypes.gt"),
      ">=": i18nParse("general.checkTypes.gte"),
      "<": i18nParse("general.checkTypes.lt"),
      "<=": i18nParse("general.checkTypes.lte"),
    }
    checkTypeDesc = checkTypeDescMap[this.checkType] || ""
    return i18nParse("history.OperatorAvailableConditionDesc", { source: valueDesc, target: targetDesc, result: checkTypeDesc })
  }

  getReturnText(i18nParse) {
    let returnValueText = i18nParse("general.available")

    if (this.returnValue === false) {
      returnValueText = i18nParse("general.unavailable")
    }
    return i18nParse("history.OperatorAvailableConditionReturn", { returnValue: returnValueText })
  }
}

AgentOperatorAvailableCondition.fromData = function (data) {
  return new AgentOperatorAvailableCondition(data)
}

export class AgentOperatorSessionMessages {
  constructor(data) {
    this.data = this._fixData(data)

    this.editedLocales = []
  }

  _fixData(data) {
    if (!data) {
      data = {}
    }
    const fixedData = {}
    Object.keys(data).forEach((lang) => {
      if (lang in LANGUAGE_LABELS) {
        fixedData[lang] = this._fixLocaleData(data[lang])
      }
    })
    return fixedData
  }

  _fixLocaleData(localeData=null) {
    if (!localeData) {
      localeData = {}
    }
    return {
      WELCOME_NORMAL: localeData.WELCOME_NORMAL || "",
      WELCOME_BUSY: localeData.WELCOME_BUSY || "",
      WELCOME_VERY_BUSY: localeData.WELCOME_VERY_BUSY || "",
      WELCOME_OFFLINE: localeData.WELCOME_OFFLINE || "",

      CALLING_NORMAL: localeData.CALLING_NORMAL || "",
      CALLING_BUSY: localeData.CALLING_BUSY || "",
      CALLING_VERY_BUSY: localeData.CALLING_VERY_BUSY || "",
      CALLING_OFFLINE: localeData.CALLING_OFFLINE || "",

      UNAVAILABLE: localeData.UNAVAILABLE || "",
    }
  }

  getLocaleData(locale) {
    if (!(locale in this.data)) {
      this.data[locale] = this._fixLocaleData()
    }
    return this.data[locale]
  }

  addEditedLocale(locale) {
    if (!this.editedLocales.includes(locale)) {
      this.editedLocales.push(locale)
    }
  }

  toData() {
    return this._fixData(this.data)
  }
}

AgentOperatorSessionMessages.fromData = function (data) {
  return new AgentOperatorSessionMessages(data)
}


export class AgentHolidays {
  constructor(data=[], include_japanese_holidays=false) {
    this._origin = data
    this.data = []
    if (Array.isArray(data)) {
      this.data = [...data]
    }
    this.includeJapaneseHolidays = include_japanese_holidays
  }

  cloneData() {
    if (Array.isArray(this._origin)) {
      return [...this._origin]
    }
    return []
  }
}

AgentHolidays.fromData = function (data) {
  return new AgentHolidays(
    data?.data,
    data?.include_japanese_holidays || false
  )
}
