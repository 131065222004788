/** PubSubのBroadcast Types */
const BroadcastTypes = Object.freeze({
  TRANSLATION_CHAT_OPERATOR_SEND_MESSAGE: "translation_chat.operator_send_message",
  TRANSLATION_CHAT_USER_SEND_MESSAGE: "translation_chat.user_send_message",
  TYPE_UPDATE_HANDLING_SESSION_STATUS: "translation_chat.update_handling_session_status",
  TYPE_OPERATOR_SETTING_UPDATE: "translation_chat.operator_setting_update",
  TYPE_AGENT_STATE_UPDATE: "agent.state_update",
  TYPE_AGENT_OPERATOR_SESSION_CONFIG_UPDATE: "agent.operator_session_config_update",
  TYPE_AGENT_HOLIDAYS_UPDATE: "agent.holidays_update",
})

export {
  BroadcastTypes
}
