import { I18n } from '../util/i18n'
import { FileUpload } from '../util/file-upload'
import { Importer, ImportStatus } from '../model/importer'

class ImportFromFileController {
  constructor(apiUrl, language, supportedLanguages, i18nContext, agentName) {
    this.apiUrl = apiUrl
    this.fileUploadApi = new FileUpload(this.apiUrl.ImportFromFile, Importer)
    this.language = language
    this.supportedLanguages = supportedLanguages  // 切替可能な言語一覧
    this.i18n = new I18n(i18nContext)
    this.selectedFile = null
    this.isImporting = false
    this.statusCode = null
    this.agentName = agentName
  }

  ready() {
  }

  submitFileFromDialogflow(csrfToken=null) {
    // API呼び出し
    let form = new FormData();
    form.append('file', this.selectedFile);
    let promise = this.fileUploadApi.submit(form, csrfToken)
    .then((importer) => {
      this.isImporting = true
      this.pollingStatus(importer)
    })
    return [true, "Uploading", promise]
  }

  /**
   * ステータスのポーリング
   */
  pollingStatus(importer) {
    // メッセージを更新
    this.statusMessage = importer.description
    this.statusCode = importer.code
    if (importer.code == ImportStatus.FINISH) {
      this.isImporting = false
    } else if (importer.code == ImportStatus.ERROR) {
      this.isImporting = false
    }
    // 同期が完了していたら停止
    if (!this.isImporting) {
      return
    }
    // 同期ステータス取得
    this.fileUploadApi.retrieve(importer)
    .then((newImporter) => {
      // 少し待ってループ
      setTimeout(
        () => {
          this.pollingStatus(newImporter)
        },
        1000
      )
    })
  }
}

export {
  ImportFromFileController
}
