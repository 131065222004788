<template>
  <div>
    <div>
      <!-- データセット一覧 -->
      <b-table :fields="fields" :items="controller.datasets" outlined bordered>
        <template #cell(active)="{ item }">
          {{ item.active ? $t("faq.valid") : $t("faq.invalid") }}
        </template>

        <template #cell(edit)="data">
          <div class="pointer-cursor" @click="openEditModal(data.item)">
            <i class="fa fa-edit"></i>
          </div>
        </template>
      </b-table>
    </div>

    <!-- Modals -->
    <error-modal ref="errorModal" :message="message" />

    <progress-modal ref="progressModal" :message="message" />

    <!-- データセット編集モーダル -->
    <b-modal
      :title="$t('faq.editDataset')"
      ref="datasetModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <h5>
          {{ controller.selectedDataset?.language_label ?? "---" }}
        </h5>

        <div class="mt-3">{{ $t("faq.state") }}:</div>
        <b-form-radio-group
          class="mt-3 ml-1"
          v-slot="{ ariaDescribedby }"
          v-model="modalData.active"
        >
          <b-form-radio :aria-describedby="ariaDescribedby" :value="true">
            {{ $t("faq.valid") }}
          </b-form-radio>
          <b-form-radio :aria-describedby="ariaDescribedby" :value="false">
            {{ $t("faq.invalid") }}
          </b-form-radio>
        </b-form-radio-group>

        <div class="mt-3">{{ $t("faq.priority") }}:</div>
        <b-form-group :invalid-feedback="$t('faq.priorityErrorMessage')">
          <b-form-input
            v-model.number="modalData.priority"
            class="mt-1 ml-1"
            name="priority"
            type="number"
            max="32767"
            placeholder="0~32767"
            :state="isValidModalPriority() ? null : false"
          />
        </b-form-group>
      </div>

      <modal-footer
        slot="modal-footer"
        :okDisabled="!isValidModalPriority()"
        @ok="updateDataset"
        @cancel="$refs.datasetModal.hide()"
      >
      </modal-footer>
    </b-modal>
  </div>
</template>

<script>
import { I18n } from "../util/i18n";

export default {
  props: ["controller"],
  data() {
    const i18n = new I18n(window.i18nContext);
    return {
      message: "",
      fields: [
        { key: "language_label", label: this.$t("faq.language") },
        { key: "active", label: this.$t("faq.state") },
        { key: "priority", label: this.$t("faq.priority") },
        { key: "edit", label: this.$t("faq.edit") },
      ],
      modalData: {
        /** 有効/無効 */
        active: false,
        /** 優先度 */
        priority: 0,
      },
    };
  },
  methods: {
    showError(message) {
      this.message = message;
      this.$refs.errorModal.show();
    },
    showProgress(message) {
      this.message = message;
      this.$refs.progressModal.show();
    },
    hideProgress() {
      this.$refs.progressModal.hide();
    },
    /**
     * カテゴリ更新処理
     * 完了後コールバックでモーダルを閉じる
     */
    async updateDataset() {
      try {
        await this.controller.updateDataset(this.modalData);
      } catch (error) {
        this.showError(error.response.data);
      }

      this.modalData = {
        active: false,
        priority: 0,
      };

      this.$refs.datasetModal.hide();
    },
    /**
     *
     */
    openEditModal(dataset) {
      //
      this.controller.onSelectDataset(dataset);

      this.modalData = {
        active: dataset.active,
        priority: dataset.priority,
      };

      this.$refs.datasetModal.show();
    },
    isValidModalPriority() {
      let isValid = true;
      const priority = this.modalData.priority;
      if (!(typeof priority === "number")) {
        /** 入力されていない */
        isValid = false;
      }
      if (priority !== Math.round(priority)) {
        /** 整数でない */
        isValid = false;
      }
      if (priority < 0 || priority > 32767) {
        /** 範囲外 */
        isValid = false;
      }
      return isValid;
    },
  },
};
</script>

<style scoped>
.selected-language {
  border: 3px solid #b9bbbe;
  color: #fff;
}
.table td {
  min-width: 120px;
}
</style>
