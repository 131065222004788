/*
 * インテント編集画面
 */
import Vue from 'vue'
import { UrlBuilder } from './util/url-builder'
import { API_URL } from './resource/urls'
import { IntentEditorController } from './controller/intent-editor'

import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import CategoryItem from './components/CategoryItem.vue'
import IntentCategory from './components/IntentCategory.vue'
import CodeEditor from './components/CodeEditor.vue'
import IntentEditor from './components/IntentEditor.vue'
import Intent from './components/Intent.vue'
import IntentItem from './components/IntentItem.vue'
import IntentDetail from './components/IntentDetail.vue'
import UserSaysSearchModal from './components/UserSaysSearchModal.vue'
import UserSaysSearchResult from './components/UserSaysSearchResult.vue'
import UserSaysSearchResultItem from './components/UserSaysSearchResultItem.vue'
import IntentDetailAnswer from './components/IntentDetailAnswer.vue'
import CompareIntentModal from './components/CompareIntentModal.vue'
import CompareResponseMessage from './components/CompareResponseMessage.vue'
import MultilanguageUserSay from './components/MultilanguageUserSay.vue'
import Synchronize from './components/Synchronize.vue'
import LanguageChange from './components/LanguageChange.vue'
import ModeChangeKeyword from './components/ModeChangeKeyword.vue'
import DeleteConfirmModal from './components/DeleteConfirmModal.vue'
import SuccessModal from './components/SuccessModal.vue'
import ModalFooter from './components/ModalFooter.vue'
import ErrorModalFooter from './components/ErrorModalFooter.vue'
import FeedbackConfigurationModal from './components/FeedbackConfigurationModal.vue'
import Previewer from './components/Previewer.vue'
import PreviewIntent from './components/PreviewIntent.vue'
import PreviewKeyword from './components/PreviewKeyword.vue'
import PreviewKeywordCategoryItem from './components/PreviewKeywordCategoryItem.vue'
import ModalIntentItem from "./components/ModalIntentItem.vue"
import ChatBox from './components/ChatBox.vue'
import LanguageChangeChatWindow from "./components/LanguageChangeChatWindow.vue"
import SelectPlatformServiceModal from "./components/SelectPlatformServiceModal.vue"
import ExcelPreview from './components/ExcelPreview.vue'
import TestChat from './components/TestChat.vue'
import FormTags from './components/common/FormTags.vue'

import IconFallback from "./components/icons/IconFallback.vue"
import IconWelcome from "./components/icons/IconWelcome.vue"
import IconFeedback from "./components/icons/IconFeedback.vue"
import IconFeedbackBest from "./components/icons/IconFeedbackBest.vue"
import IconFeedbackGood from "./components/icons/IconFeedbackGood.vue"
import IconFeedbackAverage from "./components/icons/IconFeedbackAverage.vue"
import IconFeedbackBad from "./components/icons/IconFeedbackBad.vue"
import IconFeedbackWorst from "./components/icons/IconFeedbackWorst.vue"


export const appIntent = (i18n) => {
  /* コントローラ */
  const controller = new IntentEditorController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.language,
    window.requestContext.supportedLanguages,
    window.requestContext.isStaff,
    window.requestContext.userType,
    window.requestContext.allowWebhook,
    window.i18nContext,
    window.requestContext.excelExportUrl,
    window.requestContext.intent_name,
    window.requestContext.feedback_intent_dict,
    window.requestContext.agentOptions,
    window.requestContext.available_platform,
    window.requestContext.default_platform,
    window.requestContext.auto_input_predict,
    window.requestContext.test_chat_platform,
    window.requestContext.agent_gid
  )

  /* コンポーネント登録 */
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('category-item', CategoryItem)
  Vue.component('intent-category', IntentCategory)
  Vue.component('code-editor', CodeEditor)
  Vue.component('intent-editor', IntentEditor)
  Vue.component('intent', Intent)
  Vue.component('intent-item', IntentItem)
  Vue.component('intent-detail', IntentDetail)
  Vue.component('user-says-search-modal', UserSaysSearchModal)
  Vue.component('user-says-search-result', UserSaysSearchResult)
  Vue.component('user-says-search-result-item', UserSaysSearchResultItem)
  Vue.component('intent-detail-answer', IntentDetailAnswer)
  Vue.component('compare-intent-modal', CompareIntentModal)
  Vue.component('compare-response-message', CompareResponseMessage)
  Vue.component('multilanguage-user-say', MultilanguageUserSay)
  Vue.component('synchronize', Synchronize)
  Vue.component('language-change', LanguageChange)
  Vue.component('mode-change-keyword', ModeChangeKeyword)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)
  Vue.component('success-modal', SuccessModal)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('error-modal-footer', ErrorModalFooter)
  Vue.component('feedback-configuration-modal', FeedbackConfigurationModal)
  Vue.component('previewer', Previewer)
  Vue.component('preview-intent', PreviewIntent)
  Vue.component('preview-keyword', PreviewKeyword)
  Vue.component('preview-keyword-category-item', PreviewKeywordCategoryItem)
  Vue.component('modal-intent-item', ModalIntentItem)
  Vue.component('chat-box', ChatBox)
  Vue.component('language-change-chat-window', LanguageChangeChatWindow)
  Vue.component('select-platform-service-modal', SelectPlatformServiceModal)
  Vue.component('excel-preview', ExcelPreview)
  Vue.component('test-chat', TestChat)
  Vue.component('form-tags', FormTags)

  Vue.component('icon-fallback', IconFallback)
  Vue.component('icon-welcome', IconWelcome)
  Vue.component('icon-feedback', IconFeedback)
  Vue.component('icon-feedback-best', IconFeedbackBest)
  Vue.component('icon-feedback-good', IconFeedbackGood)
  Vue.component('icon-feedback-average', IconFeedbackAverage)
  Vue.component('icon-feedback-bad', IconFeedbackBad)
  Vue.component('icon-feedback-worst', IconFeedbackWorst)

  new Vue({
    el: '#app-intent',
    components: {
      ProgressModal,
      ErrorModal,
      CategoryItem,
      IntentCategory,
      CodeEditor,
      IntentEditor,
      Intent,
      IntentItem,
      IntentDetail,
      UserSaysSearchModal,
      UserSaysSearchResult,
      UserSaysSearchResultItem,
      IntentDetailAnswer,
      CompareIntentModal,
      MultilanguageUserSay,
      Synchronize,
      LanguageChange,
      ModeChangeKeyword,
      DeleteConfirmModal,
      SelectPlatformServiceModal
    },
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
