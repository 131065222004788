/**
 * Dialogflow同期ステータス
 */
class Synchronize {
  constructor(key=null, code=null, description=null) {
    this.key = key
    this.code = code
    this.description = description
  }

  toData() {
    return {
      key: this.key,
      code: this.code,
      description: this.description
    }
  }

  /**
   * rest-api.jsはid必須のためプロパティを用意
   */
  get id() {
    return this.key
  }

  set id(value) {
    this.key = value
  }
}

Synchronize.fromData = function fromData(data) {
  return new Synchronize(
    data.key || null,
    data.code || null,
    data.description || null
  )
}

/**
 * 同期ステータス
 */
const SynchronizeStatus = Object.freeze({
  READY: 1,
  RESTORE: 2,
  TRAIN: 3,
  FINISH: 10,
  BUSY: 11,
  ERROR: 999
})

export {
  Synchronize,
  SynchronizeStatus
}
