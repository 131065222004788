import axios from 'axios'
import {VIEW_URL} from './../resource/urls'
import { I18n } from '../util/i18n'
import copyToClipboard from '../util/copy-url'
import csrfToken from '../util/csrf-token'
import {RestApi} from '../util/rest-api'
import {Intent, IntentCategory} from '../model/intent'
import {Scenario} from '../model/context'

class FileManagementController {
  constructor(apiUrl, fileManagementContext, i18nContext) {
    this.apiUrl = apiUrl
    this.fileUploadUrl = VIEW_URL.FileUpload
    this.allIntentApi = new RestApi(apiUrl.AllIntent, Intent)
    this.intentCategoryApi = new RestApi(apiUrl.IntentCategory, IntentCategory)
    this.language = fileManagementContext.language
    this.supportedLanguages = fileManagementContext.supportedLanguages  // 切替可能な言語一覧
    this.userType = fileManagementContext.userType
    this.i18n = new I18n(i18nContext)
    this.num_page = 0
    this.params = { page: 1}
    this.scenarioApi = new RestApi(apiUrl.Scenario, Scenario)
    this.allIntents = []
  }

  ready() {
  }

  getFileContents() {
    return new Promise((resolve, reject) => {
      axios.get(this.apiUrl.ListFileContents,{
        params: {
          page: this.params.page
        }
      }).then(function(response) {
        // handle success
        resolve(response.data)
      }).catch(function(error) {
        // handle error
        reject(error)
        console.log(error)
      })
    })
  }

  copyURL(url) {
    copyToClipboard(url)

    let message = this.i18n.t('fileManagement.copiedURL')
    alert(message)
  }

  uploadFile(form) {
    let sendOptions = {}
    let options={}
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken.getCsrfTokenFromCookie(document.cookie)}
    })
    return new Promise((resolve, reject) => {
      axios.post(
        this.fileUploadUrl,
        form,
        sendOptions
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }

  deleteFile(form) {
    let sendOptions = {}
    let options={}
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken.getCsrfTokenFromCookie(document.cookie)}
    })
    return new Promise((resolve, reject) => {
      axios.post(
        VIEW_URL.FileRemove.replace('<pk>', form.id),
        form,
        sendOptions
      )
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }

  searchRelatedIntents(content) {
    return new Promise((resolve, reject) => {
      axios.get(this.apiUrl.ListFileContents,{
        params: {
          search: content
        }
      }).then(function(response) {
        // handle success
        resolve(response.data)
      }).catch(function(error) {
        // handle error
        reject(error)
        console.log(error)
      })
    })
  }

  resolveCategoryPath(category, categoryIdToCategory) {
    if (category.parentId == null) {
      return [category]
    } else {
      const parentCategory = categoryIdToCategory[category.parentId]
      return this.resolveCategoryPath(parentCategory, categoryIdToCategory).concat([category])
    }
  }

  /**
   * カテゴリ名のインデックス生成
   */
  createCategoryPathIndex(categories) {
    const flat = IntentCategory.categoriesAsFlat(categories)
    // categoryId -> categoryを作成
    const categoryIdToCategory = {}
    for (const [nestedLevel, category] of flat) {
      categoryIdToCategory[category.id] = category
    }
    // category -> カテゴリツリーのパス生成
    const categoryIdToPath = {}
    for (const [nestedLevel, category] of flat) {
      const categoryPath = this.resolveCategoryPath(category, categoryIdToCategory)
      categoryIdToPath[category.id] = categoryPath.map((c) => {return c.name}).join("_")
    }
    return categoryIdToPath
  }

  /**
   * make intent full name by id
   */
  loadAllIntentWithFullPath(){
    return new Promise((resolve, reject) => {
      this.intentCategoryApi.list().then((instance) => {
        const category = this.createCategoryPathIndex(instance)
        this.allIntentApi.list().then((intents) => {
          this.allIntents = intents
          const listIntent = []
          for(const intent of intents) {
            if (intent) {
              listIntent[intent.id] = category[intent.categoryId] + '_' + intent.name
            }
          }
          resolve(listIntent)
        }).catch(error => {
          // handle error
          reject(error)
          console.log(error)
        })
      }).catch(error => {
        // handle error
        reject(error)
        console.log(error)
      })
    })
  }

  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }

  getScenarioInfo(scenarioId) {
    return new Promise((resolve, reject) => {
      const scenarioModel = new Scenario(scenarioId)
      this.scenarioApi.retrieve(scenarioModel).then(response => {
        resolve(response)
      }).catch(error => {
        // handle error
        reject(error)
        console.log(error)
      })
    })
  }
}

export {
  FileManagementController
}
