<template>
<b-modal
  ref="modal"
  hide-header
  hide-footer
  no-fade
  no-close-on-backdrop
  no-close-on-esc
  centered
  @shown="shown"
  @hidden="$emit('hidden')">
  {{ message }}
  <b-progress :value="10" :max="10" animated></b-progress>
</b-modal>
</template>

<script>
export default {
  props: ['message'],
  methods: {
    show() {
      this.hidden = false
      this.$refs.modal.show()
    },
    shown() {
      // 表示完了前にhide()が呼ばれると閉じられないのでshownイベントで再度呼ぶ
      if (this.hidden) {
        this.$refs.modal.hide()
      }
      this.$emit('shown')
    },
    hide() {
      this.hidden = true
      this.$refs.modal.hide()
    }
  },
  data() {
    return {
      hidden: true
    }
  }
}
</script>
