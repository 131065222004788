<template>
  <div class="answer-customPayload">
    <div>{{ $t('answer.content') }}(JSON):</div>
    <code-editor
      editor-id="responseMessageCustom"
      :content="responseMessage.custom"
      :required="!$v.responseMessage.custom.$error"
      @change-content="changeContentCodeEditor"
      class="mb-2" />
  </div>
</template>

<script>
import {CustomData} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

  export default {
  props: ["googleCheck"],
  data() {
    return {
      responseMessage: {
        custom:'{}'
      },
      answerCustomPayload:null,
      tips: null,
    }
  },
  validations() {
    return {
      responseMessage: {
        custom: {
          json: this.isJsonFormat
        }
      }
    }
  },
  methods:{
    changeContentCodeEditor(value) {
      this.responseMessage.custom = value
      this.validationCheck()
    },
    inputValue(){
      this.answerCustomPayload = new CustomData(this.responseMessage.custom.replace(illegalExp, ""))
      return this.answerCustomPayload
    },
    setValue(selectedResponseMessage){
      this.responseMessage.custom = selectedResponseMessage.data.json
    },
    initializeValue(){
      this.responseMessage.custom='{}',
      this.answerCustomPayload=null
    },
    validationCheck() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    isJsonFormat(){
      let status = false
      try {
        // オブジェクトに変換できるかどうかを持ってJSON形式チェック
        JSON.parse(this.responseMessage.custom)
        const splitJson = this.responseMessage.custom.split('')
        // [...]のarray文が変換できるので、除く
        status = (splitJson[0] === '{' && splitJson[splitJson.length - 1] === '}')
      } catch (e) {
        status = false
      }
      return status
    }
  }
}
</script>
