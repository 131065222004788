import { IntentCategory } from './intent.js'


/**
 * シナリオクラス
 */

class Scenario {
  constructor (id=null, name='', thumbnail='') {
    this.id = id
    this.name = name
    this.thumbnail = thumbnail
  }

  toData() {
    return {
      id: this.id,
      name: this.name,
      thumbnail: this.thumbnail
    }
  }
}

Scenario.fromData = function fromData(data) {
  return new Scenario(
    data.id || null,
    data.name || '',
    data.thumbnail || '',
  )
}

class ScenarioIntentCategory {
  constructor (id=null, scenario=null, intentCategory=null) {
    this.id = id
    this.scenario = scenario
    this.intentCategory = intentCategory
  }

  toData() {
    return {
      id: this.id,
      scenario: this.scenario,
      intent_category: this.intentCategory
    }
  }
}

ScenarioIntentCategory.fromData = function fromData(data) {
  return new ScenarioIntentCategory(
    data.id || null,
    data.scenario ? Scenario.fromData(data.scenario) : null,
    data.intent_category ? IntentCategory.fromData(data.intent_category) : null,
  )
}

export {
  Scenario,
  ScenarioIntentCategory,
}
