import Vue from 'vue'
import { API_URL } from './resource/urls'
import { UrlBuilder } from "./util/url-builder"
import { RedirectorController } from "./controller/redirector"

import Redirector from "./components/Redirector.vue"
import ErrorModal from "./components/ErrorModal.vue"
import HistoryCalendar from './components/HistoryCalendar.vue'
import IntentListInUseModal from './components/IntentListInUseModal.vue'
import VCalendar from 'v-calendar'


export const appRedirector = (i18n) => {
  Vue.use(VCalendar)

  const controller = new RedirectorController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext,
    window.redirectorContext,
    window.i18nContext
  )

  Vue.component('redirector', Redirector)
  Vue.component('error-modal', ErrorModal)
  Vue.component('history-calendar', HistoryCalendar)
  Vue.component('intent-list-inuse-modal', IntentListInUseModal)

  new Vue({
    el: '#app-redirector',
    i18n: i18n,
    data: {
      controller: controller
    }
  })
}
