import {I18n} from '../util/i18n'
import {RestApi} from '../util/rest-api';
import {Role} from '../model/role';

class DashboardRoleController {
  constructor(url, i18nContext, requestContext) {
    this.i18n = new I18n(i18nContext)
    this.userType = requestContext.userType
    this.listAgents = requestContext.listAgents
    this.tenantId = requestContext.tenant_id
    this.roleApi = new RestApi(url.DashboardRole, Role)
  }

  ready() {
  }

  createRole(context, csrfToken) {
    let promise = this.roleApi.save(context, csrfToken,
      {
        params: {
          tenant: this.tenantId
        }
      }
    )
    return [true, this.i18n.t("general.saving"), promise]
  }
}

export {
  DashboardRoleController,
}
