<template>
  <div class="answer-alexaCard">
    <div>{{ $t('answer.content') }}({{ $t('answer.typeText') }}):</div>
    <b-form-textarea
      class="mb-1"
      v-model="text_speech"
      :placeholder="$t('answer.alexaCardOutputSpeech')"
      rows="2"
      max-rows="3"
      @input="validationCheck"
      :state="!$v.text_speech.$error"
    ></b-form-textarea>
    <b-form-invalid-feedback v-if="$v.text_speech.$error">
      {{ $t('webhook.required') }}
    </b-form-invalid-feedback>
    <div>
      <div class="scroll">
        <b-card class="mb-1 mt-1" no-body>
          <b-tabs card>
            <b-tab :title=" $t('answer.alexaCardForDisplay') ">
              <b-form-select
                class="mb-1"
                style="width: 85%"
                v-model="card_type"
                :options="typeTemplates" ></b-form-select>
              <span class="ml-1" v-b-tooltip.hover.html.up="$t('answer.alexaTemplateInfo')">
                <i class="fas fa-info-circle"></i></span>
              <b-form-input
                v-if="card_type === typeTemplates[3].value" :disabled="true"
                class="mb-1" :placeholder="$t('answer.enterCardTitle')" v-model="card_title"></b-form-input>
              <b-form-input
                v-else
                class="mb-1" :placeholder="$t('answer.enterCardTitle')" v-model="card_title"></b-form-input>

              <b-form-input
                class="mb-1"
                :placeholder="$t('answer.alexaCardBackgroundDescription')"
                v-model="background_description"></b-form-input>

              <div class="input-group mb-1">
                <b-form-input
                  :placeholder="$t('answer.alexaCardBackgroundURL')"
                  v-model="background_imageUrl"
                  @input="validationCheck"
                  :state="!$v.background_imageUrl.$error"
                  ref="backgroundImageUrl"></b-form-input>
                <div class="input-group-append">
                  <a
                    class="btn btn-info"
                    :class="{ disabled: !background_imageUrl || $v.background_imageUrl.$error }"
                    :href="background_imageUrl" target="_blank"
                    :title="$t('answer.preView')"
                  ><span class="fas fa-eye mt-1"/>
                  </a>
                  <a class="btn btn-primary text-white" v-b-tooltip.hover
                     :title="$t('answer.browseImage')"
                     @click="loadBackgroundImageUrlFiles">
                    <i class="far fa-images"></i>
                  </a>
                </div>
                <b-form-invalid-feedback v-if="$v.background_imageUrl.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
              </div>
              <file-contents ref="backgroundImageUrlFiles"
                             :img-url-el="$refs.backgroundImageUrl"
                             @img-url-model="background_imageUrl = $event"></file-contents>

              <b-form-input
                v-if="(card_type === typeTemplates[0].value) || (card_type === typeTemplates[3].value)" :disabled="true"
                class="mb-1" :placeholder="$t('answer.alexaCardForegroundDescription')" v-model="foreground_description"></b-form-input>
              <b-form-input
                v-else
                class="mb-1" :placeholder="$t('answer.alexaCardForegroundDescription')" v-model="foreground_description"></b-form-input>

              <div class="input-group mb-1">
                <b-form-input v-model="foreground_imageUrl" ref="foregroundImageUrl"
                              :disabled="(card_type === typeTemplates[0].value) || (card_type === typeTemplates[3].value)"
                              :placeholder="$t('answer.alexaCardForegroundURL')"
                              :state="!$v.foreground_imageUrl.$error"
                              @input="validationCheck"></b-form-input>
                <div class="input-group-append">
                  <a
                    class="btn btn-info"
                    :class="{ disabled: !foreground_imageUrl || $v.foreground_imageUrl.$error }"
                    :href="foreground_imageUrl" target="_blank"
                    :title="$t('answer.preView')"
                  ><span class="fas fa-eye mt-1"/>
                  </a>
                  <a class="btn btn-primary text-white" v-b-tooltip.hover
                     :title="$t('answer.browseImage')"
                     :class="{ disabled: (card_type === typeTemplates[0].value || card_type === typeTemplates[3].value) }"
                     @click="loadForegroundImageUrlFiles">
                    <i class="far fa-images"></i>
                  </a>
                </div>
                <b-form-invalid-feedback v-if="$v.foreground_imageUrl.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
              </div>
              <file-contents ref="foregroundImageUrlFiles"
                             :img-url-el="$refs.foregroundImageUrl"
                             @img-url-model="foreground_imageUrl = $event"
                             v-if="!(card_type === typeTemplates[0].value || card_type === typeTemplates[3].value)"></file-contents>

              <div v-for="(content, index) in text_content">
                <div>
                  <div class="card-button-title mb-1 mr-1" style="width: 60%">
                    <b-form-input
                      v-if="card_type === typeTemplates[4].value" :disabled="true"
                      :placeholder="$t('answer.alexaCardTextContent')" v-model="content.text"></b-form-input>
                    <b-form-input
                      v-else
                      :placeholder="$t('answer.alexaCardTextContent')" v-model="content.text"></b-form-input>
                  </div>
                  <div>
                    <button
                      class="btn btn-danger mb-1"
                      :disabled="text_content.length === 1"
                      @click.prevent="deleteContentText(index)"
                      style="width: 45px; height: 37px;">
                      <i class="fa fa-minus"></i>
                    </button>
                    <button
                      v-if="index === 0"
                      :disabled="card_type === typeTemplates[4].value"
                      class="btn btn-success mb-1"
                      @click.prevent="addContentText"
                      style="width: 45px; height: 37px;">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab :title=" $t('answer.alexaCardForApp') ">
              <b-form-group>
                <b-form-radio-group
                  v-model="mobile_card_type"
                  :options="typeCards"
                  ></b-form-radio-group>
              </b-form-group>

              <b-form-input
                class="mb-1" :placeholder="$t('answer.enterCardTitle')" v-model="mobile_card_title"></b-form-input>
              <b-form-textarea
                class="mb-1"
                v-model="mobile_card_text"
                :placeholder="$t('answer.typeText')"
                rows="2" max-rows="3"
              ></b-form-textarea>
              <div class="input-group mb-1">
                <b-form-input v-model="small_imageUrl" ref="smallImageUrl"
                              :disabled="mobile_card_type === typeCards[0].value"
                              :placeholder="$t('answer.alexaCardSmallImageURL')"
                              @input="validationCheck"
                              :state="!$v.small_imageUrl.$error">
                </b-form-input>
                <div class="input-group-append">
                  <a
                    class="btn btn-info"
                    :class="{ disabled: !small_imageUrl || $v.small_imageUrl.$error }"
                    :href="small_imageUrl" target="_blank"
                    :title="$t('answer.preView')"
                  ><span class="fas fa-eye mt-1"/>
                  </a>
                  <a class="btn btn-primary text-white" v-b-tooltip.hover
                     :title="$t('answer.browseImage')"
                     :class="{ disabled: mobile_card_type === typeCards[0].value }"
                     @click="loadSmallImageUrlFiles">
                    <i class="far fa-images"></i>
                  </a>
                </div>
                <b-form-invalid-feedback v-if="$v.small_imageUrl.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
              </div>
              <file-contents ref="smallImageUrlFiles"
                             :img-url-el="$refs.smallImageUrl"
                             @img-url-model="small_imageUrl = $event"
                             v-if="mobile_card_type !== typeCards[0].value"></file-contents>
              <div class="input-group mb-1">
                <b-form-input v-model="large_imageUrl" ref="largeImageUrl"
                              :disabled="mobile_card_type === typeCards[0].value"
                              :placeholder="$t('answer.alexaCardLargeImageURL')"
                              @input="validationCheck"
                              :state="!$v.large_imageUrl.$error">
                </b-form-input>
                <div class="input-group-append">
                  <a
                    class="btn btn-info"
                    :class="{ disabled: !large_imageUrl || $v.large_imageUrl.$error }"
                    :href="large_imageUrl" target="_blank"
                    :title="$t('answer.preView')"
                  ><span class="fas fa-eye mt-1"/>
                  </a>
                  <a class="btn btn-primary text-white" v-b-tooltip.hover
                     :title="$t('answer.browseImage')"
                     :class="{ disabled: mobile_card_type === typeCards[0].value }"
                     @click="loadLargeImageUrlFiles">
                    <i class="far fa-images"></i>
                  </a>
                </div>
                <b-form-invalid-feedback v-if="$v.large_imageUrl.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
              </div>
              <file-contents ref="largeImageUrlFiles"
                             :img-url-el="$refs.largeImageUrl"
                             @img-url-model="large_imageUrl = $event"
                             v-if="mobile_card_type !== typeCards[0].value"></file-contents>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, url, requiredIf} from 'vuelidate/lib/validators'
import {AlexaCardData,ReplyText,ResponseMessage} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  data() {
    return {
      answerAlexaCard: '',
      text_speech: '',
      mobile_card_type:'Simple',
      mobile_card_title:'',
      mobile_card_text:'',
      small_imageUrl:'',
      large_imageUrl:'',
      card_type: 'BodyTemplate1',
      card_title: '',
      background_description: '',
      background_imageUrl: '',
      foreground_description: '',
      foreground_imageUrl: '',
      text_content: [new ReplyText()],
      typeCards:[
        { value: 'Simple', text: 'SimpleCard'},
        { value: 'Standard', text: 'StandardCard'}
      ],
      typeTemplates: [
          { value: 'BodyTemplate1', text: 'Card1(前景画像表示なし)' },
          { value: 'BodyTemplate2', text: 'Card2(前景画像右表示(EchoShowの場合))' },
          { value: 'BodyTemplate3', text: 'Card3(前景画像左表示(EchoShowの場合))' },
          { value: 'BodyTemplate6', text: 'Card4(タイトル,前景画像表示なし)' },
          { value: 'BodyTemplate7', text: 'Card5(コンテンツ表示なし)' }
        ],
    }
  },
  validations() {
    return {
      text_speech: {
        required
      },
      background_imageUrl: {
        url
      },
      foreground_imageUrl: {
        url
      },
      small_imageUrl: {
        required: requiredIf(() => this.mobile_card_type !== this.typeCards[0].value),
        url
      },
      large_imageUrl: {
        required: requiredIf(() => this.mobile_card_type !== this.typeCards[0].value),
        url
      }
    }
  },
  methods: {
    addContentText(){
      if(this.text_content.length < 3){
        this.text_content.push(new ReplyText())
      }
    },
    deleteContentText(index){
      this.text_content.splice(index, 1)
    },
    setValue(selectedResponseMessage){
      this.text_speech = selectedResponseMessage.data.text_speech
      this.mobile_card_type = selectedResponseMessage.data.mobile_card_type
      this.mobile_card_title = selectedResponseMessage.data.mobile_card_title
      this.mobile_card_text = selectedResponseMessage.data.mobile_card_text
      this.small_imageUrl = selectedResponseMessage.data.small_imageUrl
      this.large_imageUrl = selectedResponseMessage.data.large_imageUrl
      this.card_type = selectedResponseMessage.data.card_type
      this.card_title = selectedResponseMessage.data.card_title
      this.background_description = selectedResponseMessage.data.background_description
      this.background_imageUrl = selectedResponseMessage.data.background_imageUrl
      this.foreground_description = selectedResponseMessage.data.foreground_description
      this.foreground_imageUrl = selectedResponseMessage.data.foreground_imageUrl
      this.text_content = selectedResponseMessage.data.replies
    },
    inputValue(){
      this.answerAlexaCard = new AlexaCardData(
            this.text_speech,
            this.mobile_card_type,
            this.mobile_card_title,
            this.mobile_card_text,
            this.small_imageUrl,
            this.large_imageUrl,
            this.card_type,
            this.card_title,
            this.background_description,
            this.background_imageUrl,
            this.foreground_description,
            this.foreground_imageUrl,
            this.text_content)
      for(let content of this.text_content){
        content.text = content.text.replace(illegalExp, "").trim()
      }
      return this.answerAlexaCard
    },
    initializeValue(){
      this.answerAlexaCard=null
      this.text_speech = ''
      this.mobile_card_type = 'Simple'
      this.mobile_card_title = ''
      this.mobile_card_text = ''
      this.small_imageUrl = ''
      this.large_imageUrl = ''
      this.card_type = 'BodyTemplate1'
      this.card_title = ''
      this.background_description = ''
      this.background_imageUrl = ''
      this.foreground_description = ''
      this.foreground_imageUrl = ''
      this.text_content=[new ReplyText()]
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadBackgroundImageUrlFiles() {
      this.$refs.backgroundImageUrlFiles.loadImages()
    },
    loadForegroundImageUrlFiles() {
      this.$refs.foregroundImageUrlFiles.loadImages()
    },
    loadSmallImageUrlFiles() {
      this.$refs.smallImageUrlFiles.loadImages()
    },
    loadLargeImageUrlFiles() {
      this.$refs.largeImageUrlFiles.loadImages()
    }
  }
}
</script>
