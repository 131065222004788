import {UserSay} from "../model/intent";
import {WordsEditor} from "./word-editor";
import {str_z2h} from "../util/string-z2h";

class IntentDetailFormatter{
  constructor(apiUrl, language, results=[], searchedString=null, wordEditor=new WordsEditor()){
    this.language = language
    this.apiUrl = apiUrl
    this.searchedString = searchedString
    this.results = results
    this.resultsViewer = this.getResultView(results, searchedString)
    this.wordEditor = wordEditor
    this.selectedOptions = []
    this.selectedKeyword = null
    this.selectedKeywordCategory = null
  }

  /**
   *  結果表示用
   */
  getResultView(results, searchedString){
    // resultsに影響がないように、全部新しく生成させる
    let resultsViewer = results.map(rs=>{
      let userSays = rs.intentDetail.data.userSays.filter(us=>!searchedString.some(keyword=>!(str_z2h(us.text.toLowerCase()).includes(keyword)))).map(us=>{
        let userSay = new UserSay(us.id, us.language)
        userSay.data = this.getViewerUserSayData(us, searchedString)
        return userSay
      })
      let viewer = {
        title: rs.title,
        userSays: userSays
      }
      return viewer
    })
    return resultsViewer
  }

  getViewerUserSayData(userSay, searchString) {
    let results = []
    let words = []
    let completeStr = str_z2h(userSay.text.toLowerCase())
    userSay.data.forEach(word=>{
      words.push(word)
    })
    let nodes = []
    let nowIndex = -1
    let nowWordIndex = 0
    // 組み合わせた文字列に対して検索するので、サーチしたwordの長さをカウントして、位置を示す
    let nowLength = 0
    let searchedString = searchString
    // 文字列から合致するところを探して、nodeとして保存する。
    // nodeが持つ情報：{location:nodeが何番目のwordにある； wordIndex:そのwordのtextの何番目の文字に指している}
    for(let i = 0; i < searchedString.length; i++){
      do{
      nowIndex = completeStr.indexOf(searchedString[i], nowIndex+1)
        if(nowIndex > -1){
          let startAt = nowIndex
          let endAt = startAt + searchedString[i].length
          nowWordIndex = 0
          nowLength = 0
          // 合致するところの先頭が見つかるまでスルー
          while(nowWordIndex < words.length && startAt >= nowLength+words[nowWordIndex].text.length){
            nowLength += words[nowWordIndex].text.length
            nowWordIndex++
          }
          if(nowWordIndex < words.length){
            // isStart により、検索語の先頭後尾を識別
            let node = { 'location':nowWordIndex, 'wordIndex':(startAt-nowLength) , 'isStart': true }
            nodes.push(node)
            // また後尾を探して保存
            while(endAt > nowLength+words[nowWordIndex].text.length){
              nowLength += words[nowWordIndex].text.length
              nowWordIndex++
            }
            node = {'location':nowWordIndex, 'wordIndex':(endAt-nowLength), 'isStart': false }
            nodes.push(node)
            nodes.sort(function(a,b){
              if((a['location'] - b['location']) === 0){
                if((a['wordIndex'] < b['wordIndex'])) return -1
                if((a['wordIndex'] > b['wordIndex'])) return 1
              }else{
                if((a['location'] < b['location'])) return -1
                if((a['location'] > b['location'])) return 1
              }
              return 0
            })
          }
          // 次の合致するところを探す
          nowIndex += searchedString[i].length - 1
        }
      }
      while(nowIndex > -1)
     }
    //nowWordIndexをwordsのindexとして使う
    nowWordIndex = 0
    // wordの中に既にspanとしてprintした位置をtempIndexで記憶
    let tempIndex = 0
    // 破線を引くかどうか判断用
    let isSearchedStringInWord = false

    let matchCount = 0
    nodes.forEach((node)=>{
      if (node.isStart) {
        matchCount++
      } else {
        matchCount--
      }
      // 次のnodeがあるwordに至るまで、全部spanとしてprintout
      while(nowWordIndex < node.location){
        let word = words[nowWordIndex]
        let printWord = word.text.slice(tempIndex, word.length)
        let createdWord = this.createWord(printWord, isSearchedStringInWord, word.color, nowWordIndex)
        if(createdWord)
          results.push(createdWord)
        nowWordIndex++
        // 一wordが全部printoutされたら、tempIndexをリセット
        tempIndex = 0
      }
      // nodeがあるwordに至ったら、nodeが指しているところの前の内容をspanとしてprintして、isSearchedStringInWordを反転する
      let word = words[nowWordIndex]
      let printWord = word.text.slice(tempIndex, node.wordIndex)
      let createdWord = this.createWord(printWord, isSearchedStringInWord, word.color, nowWordIndex)
      if(createdWord)
        results.push(createdWord)
      // matchCountがゼロより大きいと、まだendに至ってないnodeが存在すると見なす、下線を続き引く
      isSearchedStringInWord = matchCount > 0
      // wordのどこまでprintしたかtempIndexに残す
      tempIndex = node.wordIndex
    })
    // 残った部分のspanを作成
    do{
      let word = words[nowWordIndex]
      let printWord = word.text.slice(tempIndex, word.length)
      let createdWord = this.createWord(printWord, isSearchedStringInWord, word.color, nowWordIndex)
      if(createdWord)
        results.push(createdWord)
      nowWordIndex++
      tempIndex = 0
    }
    while(nowWordIndex < words.length)

    for(let i = 0 ; i < results.length ; i++){
      // 念のため、制限をかける
      if( !results[i] || results[i] === "")
        continue
      let prev,next = null
      if(i > 0)
        prev = results[i-1]
      if(i < results.length - 1)
        next = results[i+1]
      // indexデータによる、前後のエレメントは同一単語であるかどうか判断する
      results[i].hasPrev = !!(prev&&prev.keywordSelect&&results[i].index===prev.index)
      results[i].hasNext = !!(next&&next.keywordSelect&&results[i].index===next.index)
    }
    return results
  }

  createWord(text, isSearchedStringInWord, color, index){
    if( text==null || text === "" ) return null
    let word = {}
    word.text = text
    word.includingSearchedString = isSearchedStringInWord
    word.keywordSelect = !!color
    word.color = color
    word.index = index
    return word
  }

  /**
   * 一括紐づけ、解除で必要な情報の設定
   */
  setSelectedOptions(selectedOptions){
    this.selectedOptions = selectedOptions
  }

  setSelectedKeywordInfo(keywordCategory,keyword){
    this.selectedKeyword = keyword
    this.selectedKeywordCategory = keywordCategory
  }

  setWordEditor(wordEditor){
    this.wordEditor = wordEditor
  }

  /**
   * 一括紐づけ
   */
  linkKeyword(){
    let promises = []
    this.results.forEach(async (searchResult, index) => {
      let promise = this.linkSearchResult(searchResult)
      promises.push(promise)
    })
    return promises
  }
  linkSearchResult(searchResult) {
    return new Promise(resolve => {
      this.linkUserSays(searchResult)
        .then((result) => {
          // intentDetailのuserSayを更新
          searchResult.intentDetail = result
          // 親コンポネントで保存の処理
          resolve(searchResult.intentDetail)
        })
    })
  }
  linkUserSays(searchResult) {
    return new Promise(resolve => {
      let promises = []
      searchResult.intentDetail.data.userSays.forEach((userSay, index) => {
        let promise = this.linkUserSay(searchResult, userSay)
        promises.push(promise)
      })

      Promise.all(promises)
        .then(newUserSays => {
          searchResult.intentDetail.data.userSays = newUserSays
          resolve(searchResult.intentDetail)
        })
    })
  }
  linkUserSay(searchResult, userSay) {
    return new Promise(resolve => {
      // wordが細切れになっていることがあるため、一度合わせて、検索語があるかを判定する
      if (this.hasSearchedString(userSay)) {
        let promise = this.bindKeyword(userSay)
        promise.then((newUserSay) => {
          resolve(newUserSay)
        })
      } else {
        resolve(userSay)
      }
    })
  }
  hasSearchedString(userSay) {
    let userSayText = null
    for(let i = 0; i < this.searchedString.length; i++){
      userSayText = userSay.data.map(item=>{return item.text}).join('')
    return str_z2h(userSayText.toLowerCase()).includes(this.searchedString[i])
    }
  }
  bindKeyword(userSay) {
    return new Promise(resolve => {
      if (!this.selectedOptions.includes(userSay.id)) {
        // もし対象のuserSayがチェックボックスでチェックされていない場合、処理を行わない
        resolve(userSay)
        return
      }
      // 全単語あわさった状態で開始位置と終了位置を決める
      let wordEditor = new WordsEditor(
        this.apiUrl,
        this.language,
        userSay.data
      )
      wordEditor.selectedCategory = this.selectedKeywordCategory
      wordEditor.selectedKeyword = this.selectedKeyword
      let promise = wordEditor.ready()

      let userSayText = userSay.data.map(item=>{return item.text}).join('')
      promise.then(() => {
        // 1つの質問に複数回検索語が入っている場合に対応する
        for(let i = 0; i < this.searchedString.length; i++){
          let existsSearchedString = true
          let searchStartPosition = 0
          while (existsSearchedString) {
            let startPosition = searchStartPosition + str_z2h(userSayText.slice(searchStartPosition).toLowerCase()).indexOf(this.searchedString[i])
            let endPosition = startPosition + this.searchedString[i].length
            searchStartPosition = endPosition
            this.selectedWord = wordEditor.split(startPosition, endPosition)
            this.selectedWord.selected = true
            wordEditor.setKeywordToWord(this.wordEditor.systemEntity, this.wordEditor.itemKeyword.text)
            this.selectedWord = null
            existsSearchedString = str_z2h(userSayText.slice(searchStartPosition).toLowerCase()).includes(this.searchedString[i])
          }
        }
        userSay.data = wordEditor.words
        resolve(userSay)
      })
    })
  }
  /**
   * 一括解除
   */
  unlinkKeyword(){
    let newIntentDetails = []
    this.results.forEach(async (searchResult, index) => {
      searchResult.intentDetail.data.userSays = this.unlinkUserSays(searchResult)
      newIntentDetails.push(searchResult.intentDetail)
    })
    return newIntentDetails
  }
  unlinkUserSays(searchResult) {
    let newUserSays = []
    searchResult.intentDetail.data.userSays.forEach(async (userSay, index) => {
      let newUserSay = this.unlinkUserSay(searchResult, userSay)
      newUserSays.push(newUserSay)
    })
    return newUserSays
  }
  unlinkUserSay(searchResult, userSay) {
    // チェックされていなければ無視する
    if (this.selectedOptions.indexOf(userSay.id) < 0) {
      return userSay
    }

    while (userSay.data.some(word => {
      return this.searchedString.some(keyword=>str_z2h(word.text.toLowerCase()).includes(keyword)) && (word.meta != null)
    })) {
      userSay = this.unlinkWord(userSay)
    }
    return userSay
  }
  unlinkWord(userSay) {
    let wordEditor = new WordsEditor(
      this.apiUrl,
      this.language,
      userSay.data
    )

    let deletingWord = userSay.data.filter(word => {
      return this.searchedString.some(keyword=>str_z2h(word.text.toLowerCase()).includes(keyword)) && (word.meta != null)
    })[0]
    wordEditor.selectWordInList(deletingWord)
    wordEditor.selectedWordInList.alias = null
    wordEditor.selectedWordInList.meta = null
    wordEditor.selectedWordInList.entitySelected = false
    wordEditor.selectedWordInList.color = null

    for (let i = 1; i < wordEditor.words.length; i++) {
      if (!wordEditor.words[i - 1].alias && !wordEditor.words[i].alias) {
        wordEditor.words[i - 1].text += wordEditor.words[i].text
        wordEditor.words.splice(i, 1);
      }
    }
    wordEditor.selectedWordInList = null

    userSay.data = wordEditor.words
    return userSay
  }
}
export {
  IntentDetailFormatter
}
