import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import { IntentSearchController } from "./controller/intent-search"

import IntentSearch from "./components/IntentSearch.vue"


export const appIntentSearch = (i18n) => {
  const controller = new IntentSearchController(
    (new UrlBuilder(API_URL)).build(),
    window.i18nContext,
    window.requestContext
  )

  Vue.component('intent-search', IntentSearch)

  new Vue({
    el: '#app-intent-search',
    i18n: i18n,
    data: {
      controller: controller
    }
  })
}
