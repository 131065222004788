export const operatorHandlingSessionStatusData = Object.freeze({
  ACTIVE: {
    value: 1, // DB値
    name: 'active', // Integrationでのプラットフォーム名
    faIcon: 'fas fa-headset', // faアイコンのclass
    label: '対応中'
  },
  INACTIVE: {
    value: 2, // DB値
    name: 'inactive', // Integrationでのプラットフォーム名
    faIcon: 'far fa-check-circle', // faアイコンのclass
    label: '対応済'
  },
  CALLING: {
    value: 3, // DB値
    name: 'calling', // Integrationでのプラットフォーム名
    faIcon: 'fas fa-exclamation-circle', // faアイコンのclass
    label: '呼び出し中'
  }
})
