<template>
  <div>
    <b-modal
      :title="$t('history.sendMessageToUser')"
      ref="showSendMessageModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <form @submit.prevent="sendMessageOk">
        <slot name="operator"></slot>
        <div>{{ $t('answer.typeOfAnswer') }}:</div>
        <div @keypress.enter.prevent="">
          <b-form-select
            v-model="modalResponseTypeSelected"
            @input="OnAnswerTypeSelected"
            :options="responseType"
            class="mb-2"
          >
          </b-form-select>
        </div>

        <div v-if="isResponseTypeText" class="d-flex justify-content-between">
          <div :style="{ width: '49%' }">
            <div>{{ $t('languageSelector.fromLanguage') }}:</div>
            <b-form-select v-model="fromLanguage" class="mb-2">
              <option
                v-for="language in languages"
                :value="language.chatLogValue"
              >
                {{ $t(language.text) }}
              </option>
            </b-form-select>
          </div>

          <div :style="{ width: '49%' }">
            <div>{{ $t('languageSelector.toLanguage') }}:</div>
            <b-form-select v-model="toLanguage" class="mb-2">
              <option
                v-for="language in languages"
                :value="language.chatLogValue"
              >
                {{ $t(language.text) }}
              </option>
            </b-form-select>
          </div>
        </div>

        <div v-show="isResponseTypeText">
          <answer-text
            ref="answerText"
            :fromLanguage="fromLanguage"
            @is-valid="isValid"
            @on-call-translate="
              $emit('on-call-translate', {
                fromLanguage,
                toLanguage,
                ...$event
              })
            "
            :googleCheck="checkValid"
            :isForPush="true"
          />
        </div>
        <div v-show="isResponseTypeCard">
          <answer-card ref="answerCard" @is-valid="isValid" />
        </div>
        <div v-show="isResponseTypeImage">
          <answer-image
            ref="answerImage"
            @is-valid="isValid"
            :selectedTargetServices="targetService"
          />
        </div>
        <div v-show="isResponseTypeQuickReplies">
          <answer-quickReplies
            ref="answerQuickReplies"
            @is-valid="isValid"
            :googleCheck="checkValid"
            :selected-target-services="platform"
          />
        </div>
        <div v-show="isResponseTypeSendFile">
          <answer-sendFile
            ref="answerSendFile"
            :sendLanguage="toLanguage"
            @is-valid="isValid"
          />
        </div>
      </form>
      <modal-footer
        slot="modal-footer"
        @ok="sendMessagePreview"
        :okDisabled="required === false"
        @cancel="sendMessageCancel"
      >
      </modal-footer>
    </b-modal>
    <!--Preview modal-->
    <b-modal
      :title="$t('history.sendMessageToUserConfirmTitle')"
      ref="showSendMessagePreviewModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <p>{{ $t('history.sendMessageToUserConfirmDescription') }}</p>
      <div v-if="isResponseTypeText && modalResponseMessage.data">
        <ul>
          <li
            v-for="(reply, idx) in modalResponseMessage.data.replies"
            :key="idx"
            :style="{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }"
          >
            <span>{{ reply.text }}</span>
          </li>
        </ul>
      </div>
      <div v-else-if="isResponseTypeCard && modalResponseMessage.data">
        <div class="card bg-dark text-white">
          <img
            v-if="modalResponseMessage.data.url"
            class="card-img"
            :src="modalResponseMessage.data.url"
            alt="Card image"
          />
          <div class="card-img-overlap">
            <h5 class="card-title">
              <div>{{ modalResponseMessage.data.title }}</div>
            </h5>
            <div class="card-text">
              <div>{{ modalResponseMessage.data.subtitle }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="modalResponseMessage.data.cardbuttons"
          class="message-bg-grey card-button-group"
        >
          <div
            v-for="(button, idx) in modalResponseMessage.data.cardbuttons"
            :key="idx"
            class="pointer-cursor card-button-text"
          >
            <div v-if="button.postback">
              <a :href="button.postback" target="_blank">
                <i class="fas fa-external-link-alt"></i>&nbsp
                {{ button.text }}
              </a>
            </div>
            <div v-else>
              <i class="fas fa-external-link-alt"></i>&nbsp
              {{ button.text }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="isResponseTypeImage && modalResponseMessage.data">
        <b-img
          thumbnail
          fluid
          :src="modalResponseMessage.data.url"
          alt="Image"
        />
      </div>
      <div v-else-if="isResponseTypeQuickReplies && modalResponseMessage.data">
        <b-card class="text-center">
          <template v-slot:header>
            <div>{{ modalResponseMessage.data.title }}</div>
          </template>
          <b-card-text>
            <span
              v-for="(qReplies, idx) in modalResponseMessage.data.replies"
              :key="idx"
              class="quickReplies pointer-cursor"
            >
              {{ qReplies.text }}<br />
            </span>
          </b-card-text>
        </b-card>
      </div>
      <div v-else-if="isResponseTypeSendFile && modalResponseMessage.data">
        <ul>
          <li>
            <span>{{ modalResponseMessage.data.file.name }}</span>
          </li>
        </ul>
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="sendMessageOk"
        @cancel="backToMessageEdit"
      >
      </modal-footer>
    </b-modal>
  </div>
</template>

<script>
import { ResponseMessage } from '../model/intent'
import { ResponseType } from '../resource/responses'
import AnswerText from './IntentDetailAnswerKind/AnswerText.vue'
import AnswerCard from './IntentDetailAnswerKind/AnswerCard.vue'
import AnswerImage from './IntentDetailAnswerKind/AnswerImage.vue'
import AnswerQuickReplies from './IntentDetailAnswerKind/AnswerQuickReplies.vue'
import AnswerSendFile from './IntentDetailAnswerKind/AnswerSendFile.vue'
export default {
  name: 'SendMessageToUser',
  props: ['platform', 'sessionId', 'uiLanguageCode', 'currentChatLanguageCode', 'userMeta'],
  components: {
    'answer-text': AnswerText,
    'answer-card': AnswerCard,
    'answer-image': AnswerImage,
    'answer-quickReplies': AnswerQuickReplies,
    'answer-sendFile': AnswerSendFile
  },
  data() {
    return {
      modalResponseMessage: new ResponseMessage(),
      modalResponseTypeSelected: ResponseType.TEXT,
      responseType: [
        { value: ResponseType.TEXT, text: 'TEXT' },
        { value: ResponseType.CARD, text: 'CARD' },
        { value: ResponseType.IMAGE, text: 'IMAGE' },
        { value: ResponseType.QUICK_REPLIES, text: 'QUICK_REPLIES' },
        { value: ResponseType.SEND_FILE, text: 'SEND_FILE' }
      ],
      required: null,
      targetService: this.platform === 'line' ? [2] : [1],
      // TODO デフォルトはユーザーのUI言語にする
      fromLanguage: ['zh-hans', 'zh-cn'].includes(this.uiLanguageCode)
        ? 'zh-cn'
        : ['zh-tw', 'zh-hant'].includes(this.uiLanguageCode)
        ? 'zh-tw'
        : this.uiLanguageCode,
      // デフォルトはボットユーザーの最後のメッセージの言語とする
      toLanguage: 'en',
      languages: [
        { text: 'languageSelector.japanese', chatLogValue: 'ja' },
        { text: 'languageSelector.english', chatLogValue: 'en' },
        { text: 'languageSelector.korean', chatLogValue: 'ko' },
        {
          text: 'languageSelector.chineseSimplified',
          chatLogValue: 'zh-cn'
        },
        {
          text: 'languageSelector.chineseTraditional',
          chatLogValue: 'zh-tw'
        },
        { text: 'languageSelector.thai', chatLogValue: 'th' },
        { text: 'languageSelector.russian', chatLogValue: 'ru' },
        { text: 'languageSelector.vietnamese', chatLogValue: 'vi' },
        { text: 'languageSelector.indonesian', chatLogValue: 'id' },
        { text: 'languageSelector.ukrainian', chatLogValue: 'uk' },
        { text: 'languageSelector.nepali', chatLogValue: 'ne' },
        { text: 'languageSelector.french', chatLogValue: 'fr'},
        { text: 'languageSelector.bengali', chatLogValue: 'bn' },
        { text: 'languageSelector.spanish', chatLogValue: 'es' },
        { text: 'languageSelector.mongolian', chatLogValue: 'mn'},
        { text: 'languageSelector.burmese', chatLogValue: 'my' },
        { text: 'languageSelector.portuguese', chatLogValue: 'pt' },
        { text: 'languageSelector.portugueseBrazil', chatLogValue: 'pt-br' },
        { text: 'languageSelector.sinhala', chatLogValue: 'si' },
      ]
    }
  },
  mounted: function() {
    this.initializeToLanguage()
  },
  computed: {
    isResponseTypeText() {
      return this.modalResponseTypeSelected === ResponseType.TEXT
    },
    isResponseTypeImage() {
      return this.modalResponseTypeSelected === ResponseType.IMAGE
    },
    isResponseTypeCard() {
      return this.modalResponseTypeSelected === ResponseType.CARD
    },
    isResponseTypeQuickReplies() {
      return this.modalResponseTypeSelected === ResponseType.QUICK_REPLIES
    },
    isResponseTypeSendFile() {
      return this.modalResponseTypeSelected === ResponseType.SEND_FILE
    }
  },
  methods: {
    OnAnswerTypeSelected() {
      this.checkValid()
    },
    showSendMessageForm() {
      this.modalResponseMessage = new ResponseMessage()
      this.modalResponseTypeSelected = ResponseType.TEXT
      this.required = true
      this.$refs.showSendMessageModal.show()
      this.$nextTick(() => {
        this.$refs.answerText.initializeValue()
        this.$refs.answerCard.initializeValue()
        this.$refs.answerImage.initializeValue()
        this.$refs.answerQuickReplies.initializeValue()
        this.$refs.answerSendFile.initializeValue()
        this.checkValid()
      })
    },
    sendMessagePreview() {
      this.modalResponseMessage.type = this.modalResponseTypeSelected
      switch (this.modalResponseTypeSelected) {
        case ResponseType.TEXT:
          this.modalResponseMessage.data = this.$refs.answerText.inputValue()
          break
        case ResponseType.CARD:
          this.modalResponseMessage.data = this.$refs.answerCard.inputValue()
          break
        case ResponseType.QUICK_REPLIES:
          this.modalResponseMessage.data = this.$refs.answerQuickReplies.inputValue()
          break
        case ResponseType.IMAGE:
          this.modalResponseMessage.data = this.$refs.answerImage.inputValue()
          break
        case ResponseType.SEND_FILE:
          let sendFile = this.$refs.answerSendFile.inputValue()
          sendFile.sendLanguage = this.toLanguage
          this.modalResponseMessage.data = sendFile
          break
        default:
          break
      }
      this.modalResponseMessage.id = this.sessionId
      this.modalResponseMessage.targetServices = [this.platform]
      this.$refs.showSendMessageModal.hide()
      this.$refs.showSendMessagePreviewModal.show()
    },
    sendMessageOk() {
      this.$emit('send-push-message', this.modalResponseMessage)
      this.$refs.showSendMessagePreviewModal.hide()
    },
    sendMessageCancel() {
      this.$refs.showSendMessageModal.hide()
    },
    backToMessageEdit() {
      this.$refs.showSendMessageModal.show()
      switch(this.modalResponseTypeSelected) {
        case ResponseType.TEXT:
          this.$nextTick(() => {
            this.$refs.answerText.setValue(this.modalResponseMessage)
          })
          break
        case ResponseType.CARD:
          this.$nextTick(() => {
            this.$refs.answerCard.setValue(this.modalResponseMessage)
          })
          break
        case ResponseType.QUICK_REPLIES:
          this.$nextTick(() => {
            this.$refs.answerQuickReplies.setValue(this.modalResponseMessage)
          })
          break
        case ResponseType.IMAGE:
          this.$nextTick(() => {
            this.$refs.answerImage.setValue(this.modalResponseMessage)
          })
          break
        case ResponseType.SEND_FILE:
          this.$nextTick(() => {
            this.$refs.answerSendFile.setValue(this.modalResponseMessage)
          })
          break
        default:
          break
      }
      this.$refs.showSendMessagePreviewModal.hide()
    },
    isValid(valid) {
      this.required = valid
    },
    checkValid() {
      switch (this.modalResponseTypeSelected) {
        case ResponseType.TEXT:
          if (this.platform === 'line') {
            this.$refs.answerText.isLineSelected = true
          } else {
            this.$refs.answerText.isLineSelected = false
          }
          this.$refs.answerText.validationCheck()
          break
        case ResponseType.CARD:
          this.$refs.answerCard.validationCheck()
          break
        case ResponseType.QUICK_REPLIES:
          this.$refs.answerQuickReplies.validationCheck()
          break
        case ResponseType.IMAGE:
          this.$refs.answerImage.validationCheck()
          break
        case ResponseType.SEND_FILE:
          this.$refs.answerSendFile.validationCheck()
          break
        default:
          break
      }
    },
    applyTranslationResult(params) {
      this.$refs.answerText.applyTranslationResult(params)
    },
    // デフォルトはボットユーザーの最後のメッセージの言語とする
    initializeToLanguage() {
      this.toLanguage = ['zh-cn', 'zh-CN'].includes(
        this.currentChatLanguageCode
      )
        ? 'zh-cn'
        : ['zh-tw', 'zh-TW'].includes(this.currentChatLanguageCode)
        ? 'zh-tw'
        : this.currentChatLanguageCode
    }
  },
  watch: {
    currentChatLanguageCode: function(current, previous) {
      this.initializeToLanguage()
    }
  }
}
</script>
