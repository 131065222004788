<template>
  <section id="scenario-diagram" class="scenario-diagram">
    <div id="paper"></div>
    <div id="diagram-navigator" class="absolute"></div>
  </section>
</template>


<script>
export default {
  props: ['scenarioDiagramId'],
  data() {
    return {}
  },
  methods: {
    /**
     * インテントを選択状態にする
     * @param {number} intentId
     * @param {string} nodeType qad.Question or qad.Answer
     */
    selectIntent(intentId, nodeType) {
      this.$emit('selected-intent', intentId, nodeType)
      this.$emit('load-intent-detail-keyword')
    },
    addUserNode() {
      this.$emit('add-user-node')
    },
    editNode() {
      this.$emit('edit-node')
    },
    onLinkConnected(sourceId, targetId) {
      this.$emit('on-link-connected', sourceId, targetId)
    },
    onLinkRemoved(sourceIntentId, targetIntentId) {
      this.$emit('on-link-removed', sourceIntentId, targetIntentId)
    },
    saveThumbnail(imageData) {
      this.$emit('save-thumbnail', imageData)
    },
    onFinishExportImage() {
      this.$emit('on-finish-export-image')
    },
  },
}
</script>
