<template>
  <div class="user-says-search-result-item">
    <label>
      <input
        type="checkbox"
        :id="'user-say-'+userSay.id"
        :checked="isChecked"
        :value="userSay.id"
        v-if="userType !== 'viewer'"
        @input="$emit('on-user-say-checked', userSay.id)"/>
    </label>
    <label :for="'user-say-'+userSay.id">
      <span v-for="word in userSay.data">
        <span :class="{'keywordSelect':word.keywordSelect,'includingSearchedString':word.includingSearchedString,'ks-hasPrev':word.hasPrev,'ks-hasNext':word.hasNext}"
              :style="{background:word.color}">{{ word.text }}</span>
      </span>
    </label>
  </div>
</template>

<script>
  export default {
    props: [
      'searchedString',
      'selectedOptions',
      'userSay',
      'text',
      'userType'
    ],
    data() {
      return {
        checked: false
      }
    },
    computed: {
      isChecked() {
        return this.selectedOptions.filter(item => {
          return item == this.userSay.id
        }).length >= 1
      }
    }
  }
</script>
