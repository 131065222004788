export default function getParamFromUrl(targetParam) {
  let paramStrings = window.location.search.split('?')
  if (paramStrings.length >= 2) {
      const paramString = paramStrings[1].replace(/&+/g, '&');
      const infos = paramString.split('&').map(param => {
          const keyValue = param.split('=')
          return {
              k: keyValue[0],
              v: keyValue.length > 1 ? keyValue[1] : null
          }
      })
      const info = infos.find(info => info.k === targetParam)
      if (info) {
          return info.v
      }
  }
  return null
}
