import axios from 'axios'
import { ChatLogFeedback } from "../model/feedback"


class FeedbackHistoryController {

  constructor(apiUrl, requestContext) {
    this.apiUrl = apiUrl
    this.paginatedListApi = apiUrl.FeedbackHistories
    this.feedbackHistories = []
    this.params = { page: 1}
    this.num_page = 0
    this.isLoading = true
    this.language = null
    this.feedback_result = null
    this.supported_languages = requestContext.supported_languages
  }

  ready() {
    this.fetchHistoriesData()
  }

  fetchHistoriesData() {
    this.isLoading = true
    let params = '?page=' + this.params.page
    if(!!this.language){
      params += '&language_code=' + this.language
    }
    if(!!this.feedback_result){
      params += '&feedback_result=' + this.feedback_result
    }
    axios.get(this.paginatedListApi + params)
      .then(response => {
        const data = response.data
        this.num_page = data.num_page
        this.feedbackHistories = data.results.map(item => {
          return ChatLogFeedback.fromData(item)
        })
        this.isLoading = false
      })
  }
}

export {
  FeedbackHistoryController
}
