import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import { UserMetaManagementController } from './controller/user-meta-management'

import UserMetaManagement from './components/UserMetaManagement.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'


export const appUserMetaManagement = (i18n) => {
  /* コントローラ */
  const controller = new UserMetaManagementController(
    (new UrlBuilder(API_URL)).build()
  )

  /* コンポーネント登録 */
  Vue.component('user-meta-management', UserMetaManagement)
  Vue.component('error-modal', ErrorModal)
  Vue.component('progress-modal', ProgressModal)

  new Vue({
    el: '#app-user-meta-management',
    i18n: i18n,
    data: {
      controller: controller
    },
  })
}
