class ApiService {
  constructor(gid=null, authentication_key='') {
    this.gid = gid
    this.authentication_key = authentication_key
  }

  toData() {
    return {
      gid: this.gid,
      authentication_key: this.authentication_key
    }
  }
}

ApiService.fromData = function fromData(data) {
  return new ApiService(
    data.gid || null,
    data.authentication_key || ''
  )
}

export {
  ApiService
}
