<template>
  <div>
    <div class="section">
      <h5 class="intent-context-header pointer-cursor"
          @click="toggleCollapse">
        <span class="float-none pl-1">
          <span v-show="isOpen">
            <i class="fas fa-caret-down"></i>
          </span>
          <span v-show="!isOpen">
            <i class="fas fa-caret-up"></i>
          </span>
        </span>
        <span>{{ title }}</span>
      </h5>
      <b-collapse
        id="intent-context-collapse"
        v-model="isOpen"
        visible>
        <div
          class="section-body scroll2"
          tabindex="-1"
          ref="">
          <ul>
            <li
              v-for="(intent, index) in intents"
              :key=index>
              <div
                class="label my-1 p-1"
                tabindex="-1">
                id:{{ intent.id }}<{{ intent.name }}>
              </div>
            </li>
          </ul>
        </div>
      </b-collapse>
    </div>
  </div>

</template>

<script>
export default {
  props: [
    'intents',
    'title',
  ],
  data() {
    return {
      isOpen: true,
    }
  },
  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>
