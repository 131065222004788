<template>
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          {{ $t('userMeta.userMeta') }} {{ $t('userMeta.schemaEdit') }}
        </h5>
        <b-button variant="info" @click="openUserMetaColumnAddModal">
          <i class="fa fa-plus"></i>
          <span>{{ $t('buttons.add') }}</span>
        </b-button>
      </div>

      <!-- List User Meta Schema -->
      <div class="table-responsive mt-2">
        <table class="table table-hover text-center">
          <thead class="table-info">
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{ $t('userMeta.key') }}</th>
              <th scope="col">{{ $t('userMeta.description') }}</th>
              <th scope="col">{{ $t('intent.ordering') }}</th>
              <th scope="col">{{ $t('validation.action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userMetaColumn, index) in controller.listUserMetaColumns" :key="index">
              <th scope="row">{{ (index + 1) }}</th>
              <td>{{ userMetaColumn.key }}</td>
              <td>{{ userMetaColumn.description }}</td>
              <td>{{ userMetaColumn.ordering ? userMetaColumn.ordering : 0 }}</td>
              <td>
                <button v-b-tooltip.hover
                  :title="$t('buttons.edit')"
                  class="btn btn-info"
                  @click="openUserMetaColumnEditModal(userMetaColumn)">
                  <i class="fa fa-edit"></i>
                </button>
                <button v-b-tooltip.hover
                  :title="$t('buttons.delete')"
                  class="btn btn-danger"
                  @click="openUserMetaColumnDeleteModal(userMetaColumn)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
            <tr v-if="controller.userMetaColumnLoader">
              <td colspan="5">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </td>
            </tr>
            <tr v-if="!controller.userMetaColumnLoader && controller.listUserMetaColumns.length === 0">
              <td colspan="5">
                <span class="text-danger">{{ $t('validation.noRecordFound') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Modal User Meta Schema Form -->
      <b-modal
        :title="userMetaColumnFormModalTitle"
        ref="userMetaColumnFormModal"
        header-class="bg-info text-light"
        no-close-on-esc
        no-close-on-backdrop
        @cancel="cancelUserMetaColumnFormModal">
        <div class="userMetaColumnForm">
          <b-form-group :label="$t('userMeta.key')">
            <b-form-input v-model="userMetaColumnForm.columnKey"
                          :state="!$v.userMetaColumnForm.columnKey.$error">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('userMeta.description')">
            <b-form-input v-model="userMetaColumnForm.columnDescription"
                          :state="!$v.userMetaColumnForm.columnDescription.$error">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('intent.ordering')">
            <b-form-input v-model="userMetaColumnForm.columnOrder"
                          :state="!$v.userMetaColumnForm.columnOrder.$error">
            </b-form-input>
          </b-form-group>
        </div>
        <div slot="modal-footer" class="w-100">
          <b-button variant="info"
                    class="float-right"
                    @click="submitUserMetaColumnForm"
                    :disabled="$v.userMetaColumnForm.$error">
            OK
          </b-button>
        </div>
      </b-modal>
      <!-- Additional Modal -->
      <delete-confirm-modal
        ref="deleteUserMetaColumnModal"
        :title="userMetaColumnFormModalTitle"
        :bodyMessage="$t('userMeta.deleteUserMetaColumnConfirmMessage', {user_meta_column: this.userMetaColumnForm && this.userMetaColumnForm.key})"
        @keydown.native.enter="removeUserMetaColumn"
        @ok="removeUserMetaColumn">
      </delete-confirm-modal>
      <error-modal
        ref="errorModal"
        :message="message">
      </error-modal>
      <progress-modal
        ref="progressModal"
        :message="message">
      </progress-modal>
    </div>
  </div>
</template>

<script>
import {required, maxLength, integer} from 'vuelidate/lib/validators'
import { UserMetaColumn } from '../model/user-meta'

export default {
  name: 'UserMetaEditSchema',
  props: ['controller'],
  data() {
    return {
      message: "",
      userMetaColumnFormModalTitle: "",
      userMetaColumnForm: [],
      isEditUserMetaColumnFormModal: false,
    }
  },
  validations: {
    userMetaColumnForm: {
      columnKey: {
        required,
        maxLength: maxLength(100)
      },
      columnDescription: {
        required,
        maxLength: maxLength(100)
      },
      columnOrder: {
        integer
      }
    }
  },
  methods: {
    openUserMetaColumnAddModal() {
      this.$v.userMetaColumnForm.$touch()
      this.isEditUserMetaColumnFormModal = false
      let modalTitle = this.$t('buttons.add') + this.$t('userMeta.userMeta') + this.$t('userMeta.column')
      if(this.controller.getLanguageCode() === 'en') {
        modalTitle = this.$t('buttons.add') +' '+ this.$t('userMeta.userMeta') +' '+ this.$t('userMeta.column')
      }
      this.userMetaColumnFormModalTitle = modalTitle
      this.resetUserMetaColumnForm()
      this.$refs.userMetaColumnFormModal.show()
    },
    openUserMetaColumnEditModal(item) {
      this.$v.userMetaColumnForm.$touch()
      this.isEditUserMetaColumnFormModal = true
      let modalTitle = this.$t('buttons.edit') + this.$t('userMeta.userMeta') + this.$t('userMeta.column')
      if (this.controller.getLanguageCode() === "en") {
        modalTitle = this.$t('buttons.edit') + ' ' + this.$t('userMeta.userMeta') +' '+ this.$t('userMeta.column')
      }
      this.userMetaColumnFormModalTitle = modalTitle
      // Set form values
      this.resetUserMetaColumnForm()
      this.userMetaColumnForm.id = item.id
      this.userMetaColumnForm.columnKey = item.key
      this.userMetaColumnForm.columnDescription = item.description
      this.userMetaColumnForm.columnOrder = item.ordering > 0 ? item.ordering : 0
      this.$refs.userMetaColumnFormModal.show()
    },
    openUserMetaColumnDeleteModal(item) {
      let modalTitle = this.$t('buttons.delete') + this.$t('userMeta.userMeta') + this.$t('userMeta.column')
      if(this.controller.getLanguageCode() === 'en') {
        modalTitle = this.$t('buttons.delete') +' '+ this.$t('userMeta.userMeta') +' '+ this.$t('userMeta.column')
      }
      this.userMetaColumnFormModalTitle = modalTitle
      this.userMetaColumnForm = item
      this.$refs.deleteUserMetaColumnModal.show()
    },
    bindUserMetaColumnFormData(form) {
      const userMetaColumn = new UserMetaColumn()
      userMetaColumn.id = form.hasOwnProperty('id') ? form.id : null
      userMetaColumn.key = form.columnKey
      userMetaColumn.description = form.columnDescription
      userMetaColumn.ordering = form.columnOrder > 0 ? form.columnOrder : 0
      return userMetaColumn
    },
    cancelUserMetaColumnFormModal() {
      this.resetUserMetaColumnForm()
      this.$refs.userMetaColumnFormModal.hide()
    },
    resetUserMetaColumnForm() {
      this.userMetaColumnForm = []
      this.userMetaColumnForm.columnOrder = 0
    },
    submitUserMetaColumnForm() {
      let result, message, promise
      if (this.isEditUserMetaColumnFormModal) {
        [result, message, promise] = this.controller.updateUserMetaColumn(this.bindUserMetaColumnFormData(this.userMetaColumnForm))
      } else {
        [result, message, promise] = this.controller.createUserMetaColumn(this.bindUserMetaColumnFormData(this.userMetaColumnForm))
      }

      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(this.hideProgress)
          .catch(function (error) {
            self.showError(error.response.data[0])
            self.hideProgress()
          })
      }
      this.$refs.userMetaColumnFormModal.hide()
    },
    removeUserMetaColumn() {
      let result, message, promise
      [result, message, promise] = this.controller.deleteUserMetaColumn(this.userMetaColumnForm)
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(this.hideProgress)
          .catch(function (error) {
            this.showError(error.response.data[0])
            self.hideProgress()
          })
      }
    },
    showProgress(message) {
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    showError(message) {
      this.message = message
      this.$refs.errorModal.show()
    }
  }
}
</script>

<style scoped>
</style>
