<template>
  <div>
    <div class="row m-1 p-1">
      <div class="col-md-6 mt-1">
        <h5>{{$t('analytics.analytics')}}
          <div class="month-pick-block">
            <div class="input-group-prepend">
              <button type="button" class="btn btn-light" :disabled="disabled_left_arrow" @click="leftArrow">
                <i class="fas fa-chevron-left"></i>
              </button>
            </div>
            <b-form-select class="custom-select month-pick mr-1" :title="$t('analytics.selectPeriod')"
                           @change="changeMonth"
                           v-model="controller.selected_month"
                           :options="controller.all_month"/>
            <div class="btn btn-info pointer-cursor month-pick-icon">
              <i class="fas fa-calendar-alt"></i>
            </div>
            <div class="input-group-append">
              <button type="button" class="btn btn-light" :disabled="disabled_right_arrow" @click="rightArrow">
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </h5>
      </div>
      <div class="col-md-6 pt-2 text-right">
        <div>
          <b-form-checkbox v-model="welcome_message_status" switch
                           @change="filterByWelcomeMessage">
            {{ $t('history.wlcMessage')}}
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="container-fluid sp-wide">
      <div class="row m-0">
        <div class="m-1 p-1 analytics-tabs">
          <span class="btn pointer-cursor mb-1" :class="tabClass(0)" @click="tabActivate(0)" :title="$t('analytics.charts')">
            <i class="fas fa-chart-line"></i>
            <span class="analytics-tab-description"></span>
          </span>
          <span class="btn pointer-cursor mb-1" :class="tabClass(1)" @click="tabActivate(1)" :title="$t('analytics.detail')">
            <i class="fas fa-list"></i>
            <span class="analytics-tab-description"></span>
          </span>
          <span class="btn pointer-cursor mb-1" :class="tabClass(2)" @click="tabActivate(2)" :title="$t('analytics.feedback')">
            <i class="fas fa-comment"></i>
            <span class="analytics-tab-description"></span>
          </span>
        </div>
        <div class="px-1 analytics-card">
          <charts-viewer
            v-if="activeTab===0"
            :chartsData="controller.chartsData">
          </charts-viewer>
          <div class="m-1 p-1" v-show="activeTab===1">
            <analytics-language
              :analytics-context="controller.analyticsContext"
              :language-records="controller.languageRecords"
              :selected-language="controller.selectedLanguage"
              :redirect-uri="controller.redirectUri"
              :details="controller.details"
              :isSP="isSP"
              :selected_month="controller.selected_month"
              :all_platforms="controller.all_platforms"
              @change-language="(lang) => controller.changeLanguage(lang)"
              @detail_tab_select="(index) => detail_tab = index"
            >
            </analytics-language>
          </div>
          <div class="p-1" v-show="activeTab===2">
            <analytics-feedback
              :items="controller.feedback"
            >
            </analytics-feedback>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import getParamFromUrl from "../util/get-param-from-url"
  export default {
    props: ['controller'],
    data () {
      return {
        activeTab: this.controller.active_tab,
        isSP: window.innerWidth <= 648,
        detail_tab: 0,
        welcome_message_status: false
      }
    },
    mounted() {
        this.$nextTick(_ => {
            window.addEventListener('resize', this.resized)
            const targetLang = getParamFromUrl('lang')
            if (!!targetLang) {
                this.activeTab = 1
                const detailTab = getParamFromUrl('detail_tab')
                if(!!detailTab){
                  this.detail_tab = 1
                }
            }
            this.welcome_message_status = getParamFromUrl('welcome_message')
        })
    },
    computed: {
      tabClass () {
        return (index) => {
          return {
            "btn-primary": this.activeTab === index,
            "btn-outline-primary": this.activeTab !== index
          }
        }
      },
      disabled_left_arrow(){
        if(this.controller.all_month[this.controller.all_month.length-1].value === this.controller.selected_month){
          return true
        }
        return false
      },
      disabled_right_arrow(){
        if(this.controller.all_month[0].value === this.controller.selected_month){
          return true
        }
        return false
      }
    },
    methods: {
      changeMonth(){
        if(this.activeTab === 1){
          if(this.detail_tab === 1){
            window.location.search = '?month='+this.controller.selected_month+'&lang='+this.controller.selectedLanguage+
                  '&detail_tab='+this.detail_tab
          }
          else{
            window.location.search = '?month='+this.controller.selected_month+'&lang='+this.controller.selectedLanguage
          }
        }
        else{
          window.location.search = '?month='+this.controller.selected_month+'&active_tab='+this.activeTab
        }
      },
      tabActivate(index) {
        this.activeTab = index
      },
      resized() {
          this.isSP = window.innerWidth <= 648
      },
      leftArrow(){
        let index = this.controller.all_month.findIndex(x => x.value ===this.controller.selected_month)
        if(index !== -1){
          index += 1
          this.controller.selected_month = this.controller.all_month[index].value
          if(this.activeTab === 1){
            if(this.detail_tab === 1){
              window.location.search = '?month='+this.controller.selected_month+'&lang='+this.controller.selectedLanguage+
                    '&detail_tab='+this.detail_tab
            }
            else{
              window.location.search = '?month='+this.controller.selected_month+'&lang='+this.controller.selectedLanguage
            }
          }
          else{
            window.location.search = '?month='+this.controller.selected_month+'&active_tab='+this.activeTab
          }
        }
      },
      rightArrow(){
        let index = this.controller.all_month.findIndex(x => x.value ===this.controller.selected_month)
        if(index !== -1){
          index -= 1
          this.controller.selected_month = this.controller.all_month[index].value
          if(this.activeTab === 1){
            if(this.detail_tab === 1){
              window.location.search = '?month='+this.controller.selected_month+'&lang='+this.controller.selectedLanguage+
                    '&detail_tab='+this.detail_tab
            }
            else{
              window.location.search = '?month='+this.controller.selected_month+'&lang='+this.controller.selectedLanguage
            }
          }
          else{
            window.location.search = '?month='+this.controller.selected_month+'&active_tab='+this.activeTab
          }
        }
      },
      filterByWelcomeMessage() {
        this.$nextTick(() => {
          const queryString = window.location.search
          if(this.welcome_message_status) {
            if(queryString) {
              if(!getParamFromUrl('welcome_message')) {
                window.location.search += '&welcome_message='+this.welcome_message_status
              }
            } else {
              window.location.search = '?welcome_message='+this.welcome_message_status
            }
          } else {
            const url = new URL(window.location.href)
            url.searchParams.delete('welcome_message')
            window.location.search = url.search
          }
        })
      }
    }
  }
</script>
