/**
 * Google Map API
 */
class GoogleMap {
  constructor(latitude, longitude) {
    this.latitude = latitude
    this.longitude = longitude
  }

  getApiKey() {
    let key = 'AIzaSyA2-UBf6iybT_VH1ITL_wc2o0n_wiVWCj0'
    if(process.env.NODE_ENV === 'development') {
      key = 'AIzaSyCVvcrdQNLgZnJUlDiYUF5mEmIlDGk9zPY'
    }
    return key
  }

  image() {
    const apiKey = this.getApiKey()
    const GmapAPI = "https://maps.googleapis.com/maps/api/staticmap"
    const search = `center=${this.latitude},${this.longitude}`
    const marker = `markers=size:mid%7C${this.latitude},${this.longitude}`
    const size = 'zoom=12&size=200x200&scale=1'
    const queryParams = `${search}&${marker}&${size}`
    return `${GmapAPI}?${queryParams}&key=${apiKey}`
  }

  url() {
    const GmapURL = "https://maps.google.com"
    const queryParams = `q=loc:${this.latitude},${this.longitude}`
    return `${GmapURL}?${queryParams}`
  }
}

export default GoogleMap
