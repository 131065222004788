<template>
    <div class="answer-audio">
      <div>
          {{ $t('answer.content') }}(Audio):
      </div>
      <div class="input-group mb-2">
        <b-form-input
          placeholder="Enter Audio Name"
          v-model="responseMessageAudioName"
          type="url"
          @input="validationCheck"
          maxlength="1000"
          ref="responseMessageAudioNameRef"
        >
        </b-form-input>
      </div>
      <div class="input-group mb-2">
        <b-form-input
          placeholder="Enter Audio URL"
          v-model="responseMessageAudioSrc"
          type="url"
          @input="validationCheck"
          :state="!$v.responseMessageAudioSrc.$error"
          maxlength="1000"
        >
        </b-form-input>
        <div class="input-group-append">
          <a
            class="btn btn-info"
            :class="{ disabled: $v.responseMessageAudioSrc.$error }"
            :href="responseMessageAudioSrc" target="_blank"
            :title="$t('answer.preView')"
          ><span class="fas fa-eye mt-1"/>
          </a>
        </div>
        <b-form-invalid-feedback v-if="$v.responseMessageAudioSrc.$error">
          {{ $t('webhook.required') }} &
          {{ $t('answer.invalidURL') }}
        </b-form-invalid-feedback>
      </div>
    </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'
import { AudioData } from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  data() {
    return {
      answerAudio: null,
      responseMessageAudioSrc: '',
      responseMessageAudioName: ''
    }
  },
  validations() {
    return {
      responseMessageAudioSrc: {
        required,
        url
      }
    }
  },
  methods:{
    inputValue(){
      this.answerAudio = new AudioData(
        this.responseMessageAudioName.replace(illegalExp, "").trim(),
        this.responseMessageAudioSrc.replace(illegalExp, "").trim()
      )
      return this.answerAudio
    },
    setValue(selectedResponseMessage){
      this.responseMessageAudioName = selectedResponseMessage.data.name
      this.responseMessageAudioSrc = selectedResponseMessage.data.audioSrc
    },
    initializeValue(){
      this.answerImage=null
      this.responseMessageAudioName=''
      this.responseMessageAudioSrc=''
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    }
  }
}
</script>
