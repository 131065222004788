<template>
<div class="d-inline-flex history-operator-session-panel" v-if="controller && controller.agentState">
  <div class="px-2 py-1 d-flex align-items-center history-operator-session-panel__status" :class="panelClass" @click.stop="panelClicked">
    <span class="text-light d-inline-flex">
      <template v-if="controller.agentState.isOperatorAvailable">
        <span class="sp-hidden">
        {{ $t('history.OperatorBusyLevel') }}:
        </span>
        <span class="pc-hidden d-inline-flex align-items-center">
          <icon-warning v-if="controller?.agentState?.operatorBusyLevel === 3"></icon-warning>
          <icon-users-cog v-else style="width: 1.1em; height: 1.1em;"></icon-users-cog>
        </span>
        <span class="ml-1 history-operator-session-panel__status-text">{{ displayBusyLevel(controller.agentState.operatorBusyLevel) }}</span>
        <span class="ml-1 sp-hidden" :title="$t('history.OfflineDescription')">
          <icon-warning v-if="controller?.agentState?.operatorBusyLevel === 3"></icon-warning>
        </span>
      </template>
      <template v-else>
        <span class="ml-1 history-operator-session-panel__status-text">
          {{ $t('general.unavailable') }}
        </span>
        <span class="ml-1">
          <icon-warning></icon-warning>
        </span>
      </template>
    </span>

    <b-button class="ml-2 sp-hidden d-inline-flex align-items-center" v-if="controller.agentState.isOperatorAvailable"
      style="padding: 0.35rem 0.5rem;"
      variant="primary" size="sm"
      @click.stop="showOperatorBusyLevelUpdateModal"
    >
      <span style="line-height: 0.7; font-size: 0.95rem;">{{ $t('buttons.change') }}</span>
    </b-button>
  </div>

  <div class="ml-1 d-flex align-items-center sp-hidden" v-if="controller?.agentOperatorSessionConfig">
    <b-button class="d-inline-flex align-items-center p-1" variant="light" @click="operatorSessionConfigModalShown = true">
      <icon-config style="width: 1.25em; height: 1.25em;"></icon-config>
    </b-button>
  </div>

  <!-- 混雑状況 -->
  <b-modal v-model="operatorBusyLevelUpdateModalShown"
    :title="$t('history.ChangeOperatorBusyLevel')"
    header-bg-variant="info"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    :ok-title="$t('buttons.change')"
    @ok.prevent="updateOperatorBusyLevel"
    :cancel-title="$t('buttons.cancel')"
  >
    <b-container fluid>
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-radio-group v-model="selectedOperatorBusyLevel"
              :options="busyLevelSelections"
            >
            </b-form-radio-group>
            <p style="font-size: 0.9rem;" v-if="selectedOperatorBusyLevel === 3" class="mt-2 text-secondary">※ {{ $t('history.OfflineDescription') }}</p>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>

  <!-- オペレーターセッション設定 -->
  <b-modal v-model="operatorSessionConfigModalShown"
    :title="$t('history.OperatorSessionSettings')"
    header-bg-variant="info"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    ok-only
    ok-variant="secondary"
    :ok-title="$t('buttons.cancel')"
  >
    <b-container>
      <!-- 混雑状況 -->
      <b-row class="mb-2">
        <b-col>
          <b-button variant="primary" block @click="showOperatorBusyLevelUpdateModal">{{ $t('history.OperatorBusyLevel') }}</b-button>
        </b-col>
      </b-row>
      <!-- 勤務曜日 -->
      <b-row class="mb-2">
        <b-col>
          <b-button variant="primary" block @click="showWorkdaysModal">{{ $t('general.workdays') }}</b-button>
        </b-col>
      </b-row>
      <!-- 休日 -->
      <b-row class="mb-2">
        <b-col>
          <b-button variant="primary" block @click="showHolidaysModal">{{ $t('general.holidays') }}</b-button>
        </b-col>
      </b-row>
      <!-- 利用可能判定条件 -->
      <b-row class="mb-2">
        <b-col>
          <b-button variant="primary" block @click="showAvailableConditionsModal">{{ $t('history.OperatorAvailableCondition') }}</b-button>
        </b-col>
      </b-row>
      <!-- 応答メッセージ -->
      <b-row class="mb-2">
        <b-col>
          <b-button variant="primary" block @click="showResponseMessagesModal">{{ $t('history.OperatorResponseMessages') }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>

  <!-- 利用可能判定条件 -->
  <b-modal v-model="availableConditionsModalShown"
    :title="$t('history.OperatorAvailableCondition')"
    header-bg-variant="info"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    size="lg"
    :cancel-title="$t('buttons.cancel')"
    @ok.prevent="saveAvailableConditions"
  >
    <b-container fluid v-if="controller?.agentOperatorSessionConfig">
      <b-row>
        <b-col>
          <condition-editor ref="conditionEditorRef"
            :conditions="editingAvailableConditions"
            :templates="availableConditionTemplates"
            :selections="availableConditionSelections"
            :previewAndLabel="$t('history.OperatorAvailableConditionAnd')"
            @addNew="addNewCondition"
            @remove="removeCondition"
          >
            <template #preview-prepend>
              <!-- 指定時間の利用可能状態検証を行うフォーム -->
              <b-container fluid class="mt-2" v-if="editingAvailableConditions?.length > 0">
                <b-row>
                  <b-col cols="12" lg="5">
                    <b-form-datepicker v-model="checkDate" placeholder="YYYY-MM-DD" @input="updateCheckDatetime"></b-form-datepicker>
                  </b-col>
                  <b-col cols="12" lg="5">
                    <b-form-timepicker v-model="checkTime" placeholder="HH:mm" @input="updateCheckDatetime"></b-form-timepicker>
                  </b-col>
                  <b-col cols="12" lg="2">
                    <b-button block variant="primary" :disabled="$v.checkDatetime.$invalid" @click="checkAvailableConditions">{{ $t('buttons.validate') }}</b-button>
                  </b-col>
                </b-row>

                <template v-if="checkResult">
                  <b-row class="mt-2">
                    <b-col>
                      <p>
                        {{ $t('general.result') }}:
                        <span class="text-success" v-if="checkResult.isOperatorAvailable">{{ $t('general.available') }}</span>
                        <span class="text-danger" v-else>{{ $t('general.unavailable') }}</span>
                      </p>
                      <span v-if="checkResult.matchedCondition">{{ $t('history.MatchedOperatorAvailableCondition') }}</span>
                      <span class="text-muted" v-else>{{ $t('history.NoMatchedOperatorAvailableCondition') }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1" v-if="checkResult.matchedCondition">
                    <b-col>
                      <condition-preview
                        :condition="checkResult.matchedCondition"
                        :andLabel="$t('history.OperatorAvailableConditionAnd')"
                      >
                      </condition-preview>
                    </b-col>
                  </b-row>
                </template>
              </b-container>
              <hr />
            </template>
          </condition-editor>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>

  <!-- 利用可能判定条件変更で利用不可になる場合、確認するためのモーダル -->
  <b-modal v-model="availableConditionsConfirmModalShown"
    :title="$t('general.confirm')"
    header-bg-variant="warning"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    :cancel-title="$t('buttons.cancel')"
    @ok.prevent="forceSaveAvailableConditions"
  >
    <p>{{ $t('history.OperatorWillBeUnavailableConfirm') }}</p>
  </b-modal>

  <!-- 利用不可詳細: 利用不可状態下、パネルがクリックされると表示 -->
  <b-modal v-model="unavailableDetailModalShown"
    :title="$t('history.OperatorUnavailableDetail')"
    header-bg-variant="info"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    size="lg"
    ok-only
    ok-variant="secondary"
    :ok-title="$t('buttons.cancel')"
  >
    <b-container fluid v-if="controller?.agentOperatorSessionConfig">
      <template v-if="controller.agentState.matchedOperatorAvailableCondition">
        <b-row>
          <b-col>
            <p>{{ $t('history.MatchedOperatorAvailableCondition') }}</p>
            <condition-preview
              :condition="controller.agentState.matchedOperatorAvailableCondition"
              :andLabel="$t('history.OperatorAvailableConditionAnd')"
            >
            </condition-preview>
          </b-col>
        </b-row>
      </template>
      <b-row v-else>
        <b-col>
          <p>{{ $t('history.NoMatchedOperatorAvailableCondition') }}</p>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <p>{{ $t('history.CurrentOperatorAvailableConditions') }}</p>
          <condition-preview v-for="(condition, idx) in controller.agentOperatorSessionConfig.availableConditions"
            :key="idx"
            :condition="condition"
            :andLabel="$t('history.OperatorAvailableConditionAnd')"
          >
          </condition-preview>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>

  <!-- 勤務曜日 -->
  <b-modal v-model="workdaysModalShown"
    :title="$t('general.workdays')"
    header-bg-variant="info"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    :cancel-title="$t('buttons.cancel')"
    @ok.prevent="saveWorkday"
  >
    <b-container fluid v-if="controller?.agentOperatorSessionConfig">
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-checkbox-group
              stacked size="lg"
              v-model="editingWorkdays"
              :options="workdaySelections"
            >
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>

  <!-- 休日 -->
  <b-modal v-model="holidaysModalShown"
    :title="$t('general.holidays')"
    header-bg-variant="info"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    :ok-disabled="$v.holidaysGroup.$invalid"
    :cancel-title="$t('buttons.cancel')"
    @ok.prevent="saveHolidays"
  >
    <b-container fluid v-if="controller?.agentOperatorSessionConfig">
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-checkbox
              v-model="editingIncludeJapaneseHolidays"
              switch
            >
              {{ $t('history.includeJapaneseHolidays') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <p class="mb-0">{{ $t('history.HolidaysData') }}</p>
          <p class="mb-0 text-muted"><small>{{ $t('history.HolidaysDataDescription') }}</small></p>
          <b-form-group>
            <b-form-textarea
              v-model="editingHolidaysDataStr"
              rows="5"
              placeholder="YYYY-MM-DD"
              @input="editingHolidaysData = editingHolidaysDataStr.split('\n').filter(d => !!d)"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>

  <!-- 応答メッセージ -->
  <b-modal v-model="responseMessagesModalShown"
    :title="$t('history.OperatorResponseMessages')"
    header-bg-variant="info"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    size="lg"
    :cancel-title="$t('buttons.cancel')"
    @ok.prevent="previewResponseMessages"
  >
    <b-container fluid v-if="editingResponseMessages && editingResponseMessagesLocale">
      <!-- 説明 -->
      <b-row class="mb-2">
        <b-col>
          <small class="text-muted">{{ $t('history.OperatorResponseMessagesDescription') }}</small>
        </b-col>
      </b-row>
      <!-- 言語選択 -->
      <b-row class="mb-2" v-if="controller.supportedLanguages.length > 1">
        <b-col class="d-flex">
          <b-dropdown
            class="ml-auto"
            variant="primary"
            :text="editingResponseMessagesLocale.text"
          >
            <b-dropdown-item
              v-for="(locale, idx) in controller.supportedLanguages"
              :key="idx"
              @click="changeEditingResponseMessagesLocale(locale)"
            >{{ locale.text }}</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <template v-if="controller.showOnlyTranslationChat">
        <!-- WELCOME_NORMAL -->
        <b-row class="mb-2">
          <b-col lg="6">
            {{ $t('history.OperatorResponseMessagesLabel.WELCOME_NORMAL') }}
          </b-col>
          <b-col lg="6">
            <b-form-textarea
              v-model="editingResponseMessagesLocaleData.WELCOME_NORMAL"
              rows="3"
              @change="onResponseMessagesLocaleDataChange"
            >
            </b-form-textarea>
          </b-col>
        </b-row>
        <!-- WELCOME_BUSY -->
        <b-row class="mb-2">
          <b-col lg="6">
            {{ $t('history.OperatorResponseMessagesLabel.WELCOME_BUSY') }}
          </b-col>
          <b-col lg="6">
            <b-form-textarea
              v-model="editingResponseMessagesLocaleData.WELCOME_BUSY"
              rows="3"
              @change="onResponseMessagesLocaleDataChange"
            >
            </b-form-textarea>
          </b-col>
        </b-row>
        <!-- WELCOME_VERY_BUSY -->
        <b-row class="mb-2">
          <b-col lg="6">
            {{ $t('history.OperatorResponseMessagesLabel.WELCOME_VERY_BUSY') }}
          </b-col>
          <b-col lg="6">
            <b-form-textarea
              v-model="editingResponseMessagesLocaleData.WELCOME_VERY_BUSY"
              rows="3"
              @change="onResponseMessagesLocaleDataChange"
            >
            </b-form-textarea>
          </b-col>
        </b-row>
        <!-- WELCOME_OFFLINE -->
        <b-row class="mb-2">
          <b-col lg="6">
            {{ $t('history.OperatorResponseMessagesLabel.WELCOME_OFFLINE') }}
          </b-col>
          <b-col lg="6">
            <b-form-textarea
              v-model="editingResponseMessagesLocaleData.WELCOME_OFFLINE"
              rows="3"
              @change="onResponseMessagesLocaleDataChange"
            >
            </b-form-textarea>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col>
            <p class="text-muted"><small>{{ $t('history.WelcomeMessagesDependOnChatbot') }}</small></p>
          </b-col>
        </b-row>
      </template>
      <!-- CALLING_NORMAL -->
      <b-row class="mb-2">
        <b-col lg="6">
          {{ $t('history.OperatorResponseMessagesLabel.CALLING_NORMAL') }}
        </b-col>
        <b-col lg="6">
          <b-form-textarea
            v-model="editingResponseMessagesLocaleData.CALLING_NORMAL"
            rows="3"
            @change="onResponseMessagesLocaleDataChange"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <!-- CALLING_BUSY -->
      <b-row class="mb-2">
        <b-col lg="6">
          {{ $t('history.OperatorResponseMessagesLabel.CALLING_BUSY') }}
        </b-col>
        <b-col lg="6">
          <b-form-textarea
            v-model="editingResponseMessagesLocaleData.CALLING_BUSY"
            rows="3"
            @change="onResponseMessagesLocaleDataChange"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <!-- CALLING_VERY_BUSY -->
      <b-row class="mb-2">
        <b-col lg="6">
          {{ $t('history.OperatorResponseMessagesLabel.CALLING_VERY_BUSY') }}
        </b-col>
        <b-col lg="6">
          <b-form-textarea
            v-model="editingResponseMessagesLocaleData.CALLING_VERY_BUSY"
            rows="3"
            @change="onResponseMessagesLocaleDataChange"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <!-- CALLING_OFFLINE -->
      <b-row class="mb-2">
        <b-col lg="6">
          {{ $t('history.OperatorResponseMessagesLabel.CALLING_OFFLINE') }}
        </b-col>
        <b-col lg="6">
          <b-form-textarea
            v-model="editingResponseMessagesLocaleData.CALLING_OFFLINE"
            rows="3"
            @change="onResponseMessagesLocaleDataChange"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <!-- UNAVAILABLE -->
      <b-row class="mb-2">
        <b-col lg="6">
          {{ $t('history.OperatorResponseMessagesLabel.UNAVAILABLE') }}
        </b-col>
        <b-col lg="6">
          <b-form-textarea
            v-model="editingResponseMessagesLocaleData.UNAVAILABLE"
            rows="3"
            @change="onResponseMessagesLocaleDataChange"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
  </b-modal>

  <b-modal v-model="responseMessagesPreviewModalShown"
    :title="$t('general.preview')"
    header-bg-variant="info"
    header-text-variant="light"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    size="lg"
    :cancel-title="$t('buttons.cancel')"
    @ok.prevent="saveResponseMessages"
  >
    <template v-if="responseMessagesPreviewModalShown && editingResponseMessages">
      <p>{{ $t('history.SaveOperatorResponseMessagesConfirm') }}</p>
      <b-container class="border p-2 mt-2" fluid v-for="(locale, idx) in controller.supportedLanguages" :key="idx">
        <b-row>
          <b-col>
            <h5>{{ locale.text }}</h5>
          </b-col>
        </b-row>
        <b-row v-for="(message, key) in previewResponseMessagesLocaleData(locale.value)" :key="key">
          <b-col>
            <p>
              <small class="text-muted">{{ $t(`history.OperatorResponseMessagesLabel.${key}`) }}</small>
            </p>
            <p>{{ message }}</p>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</div>
</template>

<script>
import { AgentOperatorAvailableCondition } from "../../model/agent"
import { isDateStringList, isDatetimeString } from "../../util/validators"
import { AgentOperatorAvailableConditionTemplates } from "../../resource/templates"

export default {
  name: "OperatorSessionPanel",
  data() {
    return {
      controller: null,

      // オペレーター混雑状況更新関連
      operatorBusyLevelUpdateModalShown: false,
      selectedOperatorBusyLevel: 0,
      busyLevelSelections: [
        { value: 0, text: this.$t('history.Normal') },
        { value: 1, text: this.$t('history.Busy') },
        { value: 2, text: this.$t('history.VeryBusy') },
        { value: 3, text: this.$t('history.Offline') }
      ],

      // オペレーターセッション設定関連
      operatorSessionConfigModalShown: false,

      // 利用可能判定条件関連
      availableConditionsModalShown: false,
      availableConditionsConfirmModalShown: false,
      availableConditionTemplates: AgentOperatorAvailableConditionTemplates,
      editingAvailableConditions: [],
      availableConditionSelections: {
        value: [
          {text: "-----", value: null},
          {text: this.$t('general.currentDate'), value: "now_date"},
          {text: this.$t('general.currentTime'), value: "now_time"},
          {text: this.$t('general.currentWeekday'), value: "now_weekday"},
        ],
        targetType: [
          {text: "-----", value: null},
          {text: this.$t('general.holidays'), value: "holidays"},
          {text: this.$t('general.workdays'), value: "workdays"},
          {text: this.$t('general.time'), value: "time"}
        ],
        listTargetTypes: ["holidays", "workdays"],
        listCheckType: [
          {text: "----", value: null},
          {text: this.$t('general.checkTypes.in'), value: "in"},
          {text: this.$t('general.checkTypes.not_in'), value: "not_in"},
        ],
        valueCheckType: [
          {text: "----", value: null},
          {text: this.$t('general.checkTypes.gt'), value: ">"},
          {text: this.$t('general.checkTypes.gte'), value: ">="},
          {text: this.$t('general.checkTypes.lt'), value: "<"},
          {text: this.$t('general.checkTypes.lte'), value: "<="},
          {text: this.$t('general.checkTypes.eq'), value: "=="},
        ],
        returnValue: [
          {text: this.$t('general.available'), value: null},
          {text: this.$t('general.unavailable'), value: false},
        ]
      },
      checkDate: "",
      checkTime: "",
      checkDatetime: "",
      checkResult: null,
      unavailableDetailModalShown: false,

      // 勤務曜日設定関連
      workdaysModalShown: false,
      editingWorkdays: [],
      workdaySelections: [
        {text: this.$t('general.monday'), value: 1},
        {text: this.$t('general.tuesday'), value: 2},
        {text: this.$t('general.wednesday'), value: 3},
        {text: this.$t('general.thursday'), value: 4},
        {text: this.$t('general.friday'), value: 5},
        {text: this.$t('general.saturday'), value: 6},
        {text: this.$t('general.sunday'), value: 7},
      ],

      // 休日設定関連
      holidaysModalShown: false,
      editingHolidaysData: [],
      editingHolidaysDataStr: "",
      editingIncludeJapaneseHolidays: false,

      // 応答メッセージ設定関連
      responseMessagesModalShown: false,
      responseMessagesPreviewModalShown: false,
      editingResponseMessages: null,
      editingResponseMessagesLocale: null,
      editingResponseMessagesLocaleData: null,
    }
  },
  validations: {
    checkDatetime: {
      isDatetimeString
    },
    editingHolidaysData: {
      isDateStringList
    },
    holidaysGroup: ['editingHolidaysData']
  },
  created() {
    this.controller = this.$root.controller
  },
  computed: {
    displayBusyLevel() {
      return (level) => {
        switch (level) {
          case 0:
            return this.$t('history.Normal')
          case 1:
            return this.$t('history.Busy')
          case 2:
            return this.$t('history.VeryBusy')
          case 3:
            return this.$t('history.Offline')
          default:
            return ''
        }
      }
    },
    panelBgColorClass() {
      return (level) => {
        switch (level) {
          case 0:
            return 'bg-success'
          case 1:
            return 'bg-orange'
          case 2:
            return 'bg-danger'
          default:
            return 'bg-dark'
        }
      }
    },
    panelClass: {
      cache: false,
      get() {
        const classes = {}
        if (this.controller.agentState) {
          if (this.controller.agentState.isOperatorAvailable) {
            classes[this.panelBgColorClass(this.controller.agentState.operatorBusyLevel)] = true
          } else {
            classes['bg-dark'] = true
          }
        }
        return classes
      }
    },
    previewResponseMessagesLocaleData() {
      return (locale) => {
        let localeData = {}
        if (this.editingResponseMessages) {
          localeData = this.editingResponseMessages.getLocaleData(locale)
        }

        if (!this.controller.showOnlyTranslationChat) {
          // WELCOME系のメッセージは、翻訳チャットのみで表示する
          for (const key of Object.keys(localeData)) {
            if (key.indexOf('WELCOME_') !== -1) {
              delete localeData[key]
            }
          }
        }
        return localeData
      }
    }
  },
  methods: {
    panelClicked() {
      // オペレーターが利用不可の場合、利用不可詳細を表示
      if (this.controller.agentState.isOperatorAvailable) {
        this.showOperatorBusyLevelUpdateModal()
      } else {
        this.unavailableDetailModalShown = true
      }
    },
    showOperatorBusyLevelUpdateModal() {
      this.selectedOperatorBusyLevel = this.controller.agentState.operatorBusyLevel
      this.operatorBusyLevelUpdateModalShown = true
    },
    async updateOperatorBusyLevel() {
      await this.controller.updateOperatorBusyLevel(this.selectedOperatorBusyLevel)
      this.operatorBusyLevelUpdateModalShown = false
    },
    showAvailableConditionsModal() {
      this.editingAvailableConditions = this.controller.agentOperatorSessionConfig.cloneAvailableConditions()
      const now = new Date()

      this.checkDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}` // YYYY-MM-DD
      this.checkTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:00`  // HH:mm:00
      this.checkDatetime = `${this.checkDate}T${this.checkTime}`  // %Y-%m-%dT%H:%M:%S
      this.checkResult = null

      this.availableConditionsModalShown = true
    },
    addNewCondition(payload) {
      if (payload?.condition) {
        payload.condition.addAnd(new AgentOperatorAvailableCondition())
      } else {
        this.editingAvailableConditions.push(new AgentOperatorAvailableCondition())
      }
    },
    removeCondition(payload) {
      if (payload?.condition) {
        payload.condition.removeAnd(payload.idx)
      } else {
        this.editingAvailableConditions.splice(payload.idx, 1)
      }
    },
    updateCheckDatetime() {
      this.checkDatetime = `${this.checkDate}T${this.checkTime}`
    },
    async checkAvailableConditions() {
      const responseData = await this.controller.checkAgentOperatorAvailable(
        this.editingAvailableConditions.map(c => c.toData()), // conditions
        this.checkDatetime  // datetimeStr: %Y-%m-%dT%H:%M:%S
      )

      this.checkResult = {
        isOperatorAvailable: responseData.is_operator_available,
        matchedCondition: responseData.matched_condition ? AgentOperatorAvailableCondition.fromData(responseData.matched_condition) : null
      }
    },
    async saveAvailableConditions() {
      try {
        await this.controller.updateOperatorAvailableConditions(this.editingAvailableConditions.map(c => c.toData()))
        this.editingAvailableConditions = []
        this.availableConditionsModalShown = false
      } catch (e) {
        // 変更で利用不可になる場合、確認するためのモーダルを表示
        const response = e.response
        const responseData = response.data

        if ("force" in responseData) {
          this.controller.hideProgress()
          this.availableConditionsConfirmModalShown = true
        }
      }
    },
    async forceSaveAvailableConditions() {
      await this.controller.updateOperatorAvailableConditions(this.editingAvailableConditions.map(c => c.toData()), true)
      this.editingAvailableConditions = []
      this.availableConditionsModalShown = false
      this.availableConditionsConfirmModalShown = false
    },
    showWorkdaysModal() {
      this.editingWorkdays = this.controller.agentOperatorSessionConfig.cloneWorkdays()
      this.workdaysModalShown = true
    },
    async saveWorkday() {
      await this.controller.updateOperatorWorkdays(this.editingWorkdays)
      this.editingWorkdays = []
      this.workdaysModalShown = false
    },
    showHolidaysModal() {
      this.editingHolidaysData = this.controller.agentHolidays.cloneData()
      this.editingHolidaysDataStr = this.editingHolidaysData.join('\n')
      this.editingIncludeJapaneseHolidays = !!this.controller.agentHolidays.includeJapaneseHolidays
      this.holidaysModalShown = true
    },
    async saveHolidays() {
      await this.controller.updateOperatorHolidays({
        data: this.editingHolidaysData,
        includeJapaneseHolidays: this.editingIncludeJapaneseHolidays
      })
      this.editingHolidaysData = []
      this.editingHolidaysDataStr = ""
      this.holidaysModalShown = false
    },
    showResponseMessagesModal() {
      this.editingResponseMessages = this.controller.agentOperatorSessionConfig.cloneMessages()
      if (this.editingResponseMessagesLocale === null) {
        this.editingResponseMessagesLocale = this.controller.supportedLanguages[0]
      }
      this.editingResponseMessagesLocaleData = this.editingResponseMessages.getLocaleData(this.editingResponseMessagesLocale.value)
      this.responseMessagesModalShown = true
    },
    changeEditingResponseMessagesLocale(locale) {
      this.editingResponseMessagesLocale = locale
      this.editingResponseMessagesLocaleData = this.editingResponseMessages.getLocaleData(locale.value)
    },
    onResponseMessagesLocaleDataChange() {
      this.editingResponseMessages.addEditedLocale(this.editingResponseMessagesLocale.value)
    },
    previewResponseMessages() {
      this.responseMessagesPreviewModalShown = true
    },
    async saveResponseMessages() {
      if (this.editingResponseMessages) {
        await this.controller.updateOperatorResponseMessages(this.editingResponseMessages.toData())
        this.editingResponseMessages = null
      }
      this.responseMessagesPreviewModalShown = false
      this.responseMessagesModalShown = false
    }
  }
}
</script>