<template>
  <div class="mt-1 page-category col-md-5">
    <div
      class="column-caption my-1 p-1 font-weight-bold pointer-cursor"
      v-b-toggle.qa_collapse
      @click="folded = !folded"
    >
      <span class="float-none pl-1" v-show="folded"
        ><i class="fas fa-caret-down"></i
      ></span>
      <span class="float-none pl-1" v-show="!folded"
        ><i class="fas fa-caret-up"></i
      ></span>

      <span class="pl-1">QA</span>
    </div>

    <b-collapse visible id="qa_collapse">
      <div v-if="intent && qa" class="mb-1 mx-0">
        <button class="btn btn-success button-text" @click="openEditQAModal">
          <i class="fa fa-edit"></i>
          <span>{{ $t("buttons.edit") }}</span>
        </button>
      </div>

      <div class="scroll-qa">
        <div v-if="category && intent" class="mb-1">
          <span>{{ getIntentFullPath() }}</span>
        </div>

        <div v-if="qa">
          <div v-if="userType != 'viewer'" class="mb-1">
            <h5 :title="qa?.active ? $t('faq.validQA') : $t('faq.invalidQA')">
              <b class="mr-1">{{ $t("faq.state") }}:</b>
              <span v-if="qa?.active">
                {{ $t("faq.valid") }}
              </span>
              <span v-else>
                {{ $t("faq.invalid") }}
              </span>
            </h5>
            <div
              v-if="qa?.active && !(category?.active && intent?.active)"
              class="alert alert-dismissable alert-warning"
            >
              <i class="fas fa-exclamation-circle"></i>
              {{ $t("faq.invalidCategoryOrIntent") }}
            </div>
          </div>

          <h5 class="font-weight-bold">{{ $t("faq.question") }}</h5>
          <span>{{ qa.title }}</span>

          <h5 class="mt-3 font-weight-bold">{{ $t("faq.answer") }}</h5>
          <div
            v-if="answer.type"
            v-for="(answer, answer_idx) in qa.answers"
            class="mb-2 p-2 border text-break"
            :key="answer_idx"
          >
            <div v-if="isMessageTypeText(answer.message_type)">
              <!-- テキスト -->
              <div class="mb-2">
                <span class="font-weight-bold mr-1"
                  >{{ $t("faq.typeOfAnswer") }}:</span
                >
                <span>TEXT</span>
              </div>
              <div>
                <span class="font-weight-bold mr-1"
                  >{{ $t("faq.textFormat") }}:</span
                >
                <span>{{ answer.text_format }}</span>
              </div>
              <div class="font-weight-bold">{{ $t("faq.answerContent") }}</div>
              <span v-if="answer.texts">{{ answer.texts[0] }}</span>
            </div>
            <div v-else-if="isMessageTypeCard(answer.message_type)">
              <!-- カード -->
              <div class="mb-2">
                <span class="font-weight-bold mr-1"
                  >{{ $t("faq.typeOfAnswer") }}:</span
                >
                <span>CARD</span>
              </div>
              <div v-for="(card, card_idx) in answer.cards" :key="card_idx">
                <div>
                  <span class="font-weight-bold mr-1"
                    >{{ $t("faq.cardTitle") }}:</span
                  >
                  <span>{{ card.title }}</span>
                </div>
                <div>
                  <span class="font-weight-bold mr-1"
                    >{{ $t("faq.cardSubTitle") }}:</span
                  >
                  <span>{{ card.subtitle }}</span>
                </div>
                <div v-if="card.src">
                  <span class="font-weight-bold mr-1"
                    >{{ $t("faq.cardImage") }}:</span
                  >
                  <img
                    class="ml-2"
                    style="max-width: 30%"
                    :src="card.src"
                    :alt="card.src"
                  />
                </div>
                <div
                  v-for="(button, button_idx) in card.buttons"
                  :key="button_idx"
                  class="mb-2"
                >
                  <span class="font-weight-bold">{{
                    $t("faq.buttons") + (button_idx + 1)
                  }}</span>
                  <div class="ml-2">
                    <div>
                      <span class="font-weight-bold mr-1"
                        >{{ $t("faq.text") }}:</span
                      >
                      <span>{{ button.btn_text }}</span>
                    </div>
                    <div v-if="button.open_url">
                      <span class="font-weight-bold mr-1">URL:</span>
                      <span class="text-break">{{ button.open_url }}</span>
                    </div>
                    <div v-if="button.post_back">
                      <span class="font-weight-bold mr-1"
                        >{{ $t("faq.postback") }}:</span
                      >
                      <span>{{ button.post_back }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="isMessageTypeImage(answer.message_type)">
              <!-- 画像 -->
              <div class="mb-2">
                <span class="font-weight-bold mr-1"
                  >{{ $t("faq.typeOfAnswer") }}:</span
                >
                <span>IMAGE</span>
              </div>
              <span>{{ $t("faq.notSupportedImage") }}</span>
            </div>
            <div v-else-if="isMessageTypeVideo(answer.message_type)">
              <!-- 動画 -->
              <div class="mb-2">
                <span class="font-weight-bold mr-1"
                  >{{ $t("faq.typeOfAnswer") }}:</span
                >
                <span>VIDEO</span>
              </div>
              <span>{{ $t("faq.notSupportedVideo") }}</span>
            </div>
            <div v-else-if="isMessageTypeAudio(answer.message_type)">
              <!-- 音声 -->
              <div class="mb-2">
                <span class="font-weight-bold mr-1"
                  >{{ $t("faq.typeOfAnswer") }}:</span
                >
                <span>AUDIO</span>
              </div>
              <span>{{ $t("faq.notSupportedAudio") }}</span>
            </div>
            <div v-else-if="isMessageTypeQuickReplies(answer.message_type)">
              <!-- クイックリプライ -->
              <div class="mb-2">
                <span class="font-weight-bold mr-1"
                  >{{ $t("faq.typeOfAnswer") }}:</span
                >
                <span>QUICK_REPLIES</span>
              </div>
              <span>{{ $t("faq.notSupportedQuickReplies") }}</span>
            </div>
          </div>
          <div v-else class="alert alert-dismissable alert-warning">
            {{ $t("faq.noAnswer") }}
          </div>
        </div>
        <div v-else-if="intent && !qa">
          <div class="alert alert-dismissable alert-warning">
            {{ $t("faq.languageNoQA") }}
          </div>
        </div>
        <div v-else class="mt-1 p-2 border text-break">
          {{ $t("faq.waitingSelectIntent") }}
        </div>
      </div>
    </b-collapse>

    <!--Modal end-->
    <error-modal ref="errorModal" :message="message" />
    <progress-modal ref="progressModal" :message="message" />
    <b-modal
      :title="$t('feedback.confirmTitle')"
      ref="confirmFeedbackSetting"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <p>{{ $t("feedback.confirmFeedbackSetting") }}</p>
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="saveFeedback"
        @cancel="$refs.confirmFeedbackSetting.hide()"
      >
      </modal-footer>
    </b-modal>

    <!-- QA編集モーダル -->
    <b-modal
      :title="$t('faq.editQA')"
      ref="editQAModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <div>{{ $t("faq.state") }}:</div>
        <b-form-radio-group v-model="modalData.active">
          <b-form-radio value="true">{{ $t("faq.valid") }}</b-form-radio>
          <b-form-radio value="false">{{ $t("faq.invalid") }}</b-form-radio>
        </b-form-radio-group>
      </div>

      <modal-footer
        slot="modal-footer"
        @ok="saveQA"
        @cancel="$refs.editQAModal.hide()"
      ></modal-footer>
    </b-modal>
  </div>
</template>

<script>
import { I18n } from "../util/i18n";
import { ResponseType } from "../resource/responses";

export default {
  props: ["qa", "category", "intent", "selectedLanguage", "categories"],
  components: {},
  data() {
    const i18n = new I18n(window.i18nContext);
    return {
      message: "",
      folded: false,
      isAllSelected: false,
      modalData: {
        active: false,
      },
    };
  },
  methods: {
    showError(message) {
      this.message = message;
      this.$refs.errorModal.show();
    },
    showProgress(message) {
      this.message = message;
      this.$refs.progressModal.show();
    },
    hideProgress() {
      this.$refs.progressModal.hide();
    },
    getIntentFullPath() {
      let fullpath = "";
      const categoryFullNames = this.category.full_name.split(" > ");
      categoryFullNames.forEach((name, idx) => {
        const c = this.categories.find((c) => c.name === name);
        const displayName = c.display_names?.[this.selectedLanguage];
        if (displayName) {
          fullpath += `${displayName}`;
        } else {
          fullpath += `${name}`;
        }
        if (idx + 1 === categoryFullNames.length) {
          fullpath += ` > ${this.intent.name.split("|")[1]}`;
        } else {
          fullpath += " > ";
        }
      });
      return fullpath;
    },
    openEditQAModal() {
      this.modalData.active = this.qa.active;
      this.$refs.editQAModal.show();
    },
    async saveQA() {
      await this.$emit("update-qa", this.modalData);
      this.$refs.editQAModal.hide();
    },
    format(value, event) {
      return value.toLowerCase();
    },
    isMessageTypeText(messageType) {
      return messageType === ResponseType.TEXT;
    },
    isMessageTypeCard(messageType) {
      return messageType === ResponseType.CARD;
    },
    isMessageTypeImage(messageType) {
      return messageType === ResponseType.IMAGE;
    },
    isMessageTypeVideo(messageType) {
      return messageType === ResponseType.VIDEO;
    },
    isMessageTypeAudio(messageType) {
      return messageType === ResponseType.AUDIO;
    },
    isMessageTypeQuickReplies(messageType) {
      return messageType === ResponseType.QUICK_REPLIES;
    },
  },
};
</script>

<style scoped>
.selected-language {
  border: 3px solid #b9bbbe;
  color: #fff;
}
.table td {
  min-width: 120px;
}
</style>
