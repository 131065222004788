const MessageType = {
  EVENT: "event",
  MESSAGE: "message"
}

const EventType = {
  AUTHED: "authed",
  REJECTED: "rejected"
}

const BroadcastTargetTypes = Object.freeze({
  ALL: 1,
  OTHERS: 2
})

class ObotAIPubSubClient {
  constructor(props = {}) {
    this.serviceAddress = "wss://pubsub.obotai.com"
    this.platform = null
    this.channelId = null
    this.ws = null
    this.doRetry = true
    this.retryTimes = 0
    this.retryTimer = null

    if (props.serviceAddress) {
      this.serviceAddress = props.serviceAddress
    }

    if (props.platform) {
      this.platform = props.platform
    } else {
      throw Error("[platform] is required.")
    }

    this.onStart = null  // websocket通信が設立した時発火されるリスナーメソッド
    this.onAuth = null  // pubsubから認証成功のイベントが来た時発火されるリスナーメソッド
    this.onReceive = null  // 監視しているチャンネルからメッセージが来た時発火されるリスナーメソッド
    this.onClose = null  // websocket通信が閉じた時発火されるリスナーメソッド、 unsubscribeメソッドを呼んだ後のみ発火され、再接続時は発火されない
    this.onError = null  // websocketに何らかのエラーが発生した時発火されるリスナーメソッド

    this._active = false
  }

  /**
   * Websocketの各イベントを聞くリスナーメソッドを予め紐づける
   * @param listeners: 各種リスナーメソッド、受け入れるリスナー種類： start, auth, receive, close, error
   */
  bind(listeners = {}) {
    if (listeners.start && typeof listeners.start === 'function') {
      this.onStart = listeners.start
    } else {
      this.onStart = null
    }
    if (listeners.auth && typeof listeners.auth === 'function') {
      this.onAuth = listeners.auth
    } else {
      this.onAuth = null
    }
    if (listeners.receive && typeof listeners.receive === 'function') {
      this.onReceive = listeners.receive
    } else {
      this.onReceive = null
    }
    if (listeners.close && typeof listeners.close === 'function') {
      this.onClose = listeners.close
    } else {
      this.onClose = null
    }
    if (listeners.error && typeof listeners.error === 'function') {
      this.onError = listeners.error
    } else {
      this.onError = null
    }
  }

  /**
   * 指定したチャンネルからメッセージを監視し始める
   * @param channelId
   */
  subscribe(channelId) {
    if (channelId) {
      this.channelId = channelId
    } else {
      throw Error("[channelId] is required.")
    }
    if (this._active) {
      const self = this
      this.onClose = () => {
        self.subscribe(channelId)
      }
      this.unsubscribe()
    } else {
      this._startConnection()
    }
  }

  /**
   * 監視を終了とする、websocketを切る
   */
  unsubscribe() {
    this._closeConnection()
  }

  _startConnection() {
    this.retryTimes += 1
    this.ws = new WebSocket(`${this.serviceAddress}/sub/${this.platform}/${this.channelId}/`)
    const self = this
    this.ws.onopen = (e) => {
      self._active = true
      self.retryTimes = 0
      if (self.onStart) {
        self.onStart(e)
      }
    }
    this.ws.onmessage = (e) => {
      self._handleMessage(JSON.parse(e.data))
    }
    this.ws.onclose = (e) => {
      self._active = false
      if (self.doRetry) {
        // 再接続の間隔は最大三秒
        let delaySeconds = self.retryTimes * 0.5
        if (delaySeconds > 3) {
          delaySeconds = 3
        }
        window.clearTimeout(self.retryTimer)
        window.setTimeout(() => {
          self._startConnection()
        }, 1000 * delaySeconds)
      } else {
        if (self.onClose) {
          self.onClose(e)
        }
      }
    }
    this.ws.onerror = (e) => {
      if (self.onError) {
        self.onError(e)
      }
    }
  }

  _closeConnection() {
    this.doRetry = false
    this.channelId = null
    if (this.ws && this._active) {
      this.ws.close()
    }
  }

  /**
   * websocketから来た全てのデータを扱う
   * @param data: データタイプも含まれている
   * @private
   */
  _handleMessage(data) {
    const dataType = data.type
    switch (dataType) {
      case MessageType.EVENT:
        this._handleEvent(data.name)
        break
      case MessageType.MESSAGE:
        this._publish(data.data)
        break
      default:
    }
  }

  /**
   * 内部イベントの処理
   * @param name: 処理名
   * @private
   */
  _handleEvent(name) {
    switch (name) {
      case EventType.AUTHED:
        this._doOnAuth()
        break
      case EventType.REJECTED:
        this._closeConnection()
        break
      default:
    }
  }

  _doOnAuth() {
    if(this.onAuth) {
      this.onAuth()
    }
  }

  /**
   * 外部へデータを送信
   * @param data: メッセージデータ
   * @private
   */
  _publish(data) {
    if(this.onReceive) {
      this.onReceive(data)
    }
  }

  broadcast(data, options={}) {
    if (data) {
      const broadcastData = {
        type: "broadcast",
        data: data,
        target: BroadcastTargetTypes.OTHERS
      }
      if (options) {
        if (options.target) {
          broadcastData.target = options.target
        }
      }
      this.ws.send(JSON.stringify(broadcastData))
    }
  }

  static get BroadcastTargetTypes() {
    return BroadcastTargetTypes
  }
}

export {
  ObotAIPubSubClient
}
