/*
 * Chat会話履歴画面
 */
import Vue from "vue";
import { UrlBuilder } from "./util/url-builder";
import { API_URL } from "./resource/urls";
import { OperatorSessionController } from "./controller/operator-session-controller";

import OperatorSession from "./components/operatorSession/OperatorSession.vue";
import OperatorSessionPanel from "./components/operatorSession/OperatorSessionPanel.vue";
import SessionListSection from "./components/operatorSession/SessionListSection.vue";
import PlatformIcon from "./components/PlatformIcon.vue";
import OperatorHandlingSessionStatusIcon from "./components/operatorSession/OperatorHandlingSessionStatusIcon.vue";
import SessionFilterModal from "./components/operatorSession/SessionFilterModal.vue";
import EditNoteModal from "./components/operatorSession/EditNoteModal.vue";
import MessageSection from "./components/operatorSession/MessageSection.vue";
import DetailSection from "./components/operatorSession/DetailSection.vue";
import SPDetailSection from "./components/operatorSession/SPDetailSection.vue";
import SendMessageToUser from "./components/SendMessageToUser.vue";
import Pagination from "./components/Pagination.vue";
import OperatorHandlingSessionStatusChangeModal from "./components/operatorSession/OperatorHandlingSessionStatusChangeModal.vue";
import ModalFooter from "./components/ModalFooter.vue";
import ProgressModal from "./components/ProgressModal.vue";
import ErrorModal from "./components/ErrorModal.vue";
import OperatorSettingModal from "./components/operatorSession/OperatorSettingModal.vue";
import VueMoment from "vue-moment";
import moment from "moment-timezone";


export const appHistoryOperatorSession = (i18n) => {
  Vue.use(VueMoment, {
    moment,
  });
  
  /* コントローラ */
  const controller = new OperatorSessionController(
    new UrlBuilder(API_URL).build(),
    window.i18nContext,
    window.requestContext,
    navigator.userAgent
  );

  /* コンポーネント登録 */
  Vue.component("operator-session", OperatorSession);
  Vue.component("operator-session-panel", OperatorSessionPanel);
  Vue.component("session-list-section", SessionListSection);
  Vue.component(
    "operator-handling-session-status-icon",
    OperatorHandlingSessionStatusIcon
  );
  Vue.component("session-filter-modal", SessionFilterModal);
  Vue.component("edit-note-modal", EditNoteModal);
  Vue.component("platform-icon", PlatformIcon);
  Vue.component("pagination", Pagination);
  Vue.component("message-section", MessageSection);
  Vue.component("detail-section", DetailSection);
  Vue.component("sp-detail-section", SPDetailSection);
  Vue.component("send-message-to-user", SendMessageToUser);
  Vue.component(
    "operator-handling-session-status-change-modal",
    OperatorHandlingSessionStatusChangeModal
  );
  Vue.component("modal-footer", ModalFooter);
  Vue.component("progress-modal", ProgressModal);
  Vue.component("error-modal", ErrorModal);
  Vue.component("operator-setting-modal", OperatorSettingModal);

  new Vue({
    el: "#app-history-operator-session",
    data: {
      controller: controller,
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready();

        if (window.requestContext?.allowOperatorAvailableControl) {
          new Vue({
            render: (h) => h(OperatorSessionPanel),
            data: {
              controller: controller,
            },
            i18n: i18n
          }).$mount("#app-history-operator-session-panel");
        }
      });
    },
    i18n: i18n,
  });
}
