
class Feedback {
  constructor (id=null, isFreetextEnabled=false, freetextIntent=0, data=[]) {
    this.id = id
    this.isFreetextEnabled = isFreetextEnabled
    this.freetextIntent = freetextIntent
    this.data = data
  }

  toData() {
    let feedbackDatas = []
    for(const feedbackData of this.data){
      feedbackDatas.push(feedbackData.toData())
    }
    return {
      id: this.id,
      is_freetext_enabled: this.isFreetextEnabled,
      freetext_intent: this.freetextIntent,
      data: feedbackDatas
    }
  }
}

Feedback.fromData = function fromData(data) {
  let feedbackDatas = []
  for(const feedbackData of data.data){
    feedbackDatas.push(new FeedbackData.fromData(feedbackData))
  }
  return new Feedback(
    data.id || null,
    data.is_freetext_enabled || false,
    data.freetext_intent || 0,
    feedbackDatas
  )
}

class FeedbackData{
  constructor(lang='', title='', placeholder='', buttons=[]){
    this.lang = lang
    this.title = title
    this.placeholder = placeholder
    this.buttons = buttons
  }

  toData() {
    let feedbackButtons = []
    for (const button of this.buttons) {
      feedbackButtons.push(button.toData())
    }
    return {
      lang: this.lang,
      title: this.title,
      placeholder: this.placeholder,
      buttons: feedbackButtons
    }
  }
}

FeedbackData.fromData = function fromData(data) {
  let feedbackButtons = []
  if (data.buttons) {
    for (const button of data.buttons) {
      feedbackButtons.push(new ButtonText.fromData(button))
    }
  }
  return new FeedbackData(
    data.lang || '',
    data.title || '',
    data.placeholder || '',
    feedbackButtons
  )
}

class ButtonText{
  constructor(text='', level='4', intentId=0) {
    this.text = text
    this.level = level
    this.intentId = intentId
  }

  toData(){
    return{
      text:this.text,
      level:this.level,
      intentId: this.intentId
    }
  }
}

ButtonText.fromData = function fromData(data) {
  return new ButtonText(
    data.text || '',
    data.level || '4',
    data.intentId || 0
  )
}

class DummyFeedback{
  constructor(buttons=[]){
    this.buttons = buttons
  }

  toData(){
    let buttons = []
    for(const button of this.buttons){
      buttons.push(button.toData())
    }
    return{
      buttons:buttons
    }
  }
}

DummyFeedback.fromData = function fromData(data) {
  let buttons = []
  if (data.buttons) {
    for (const button of data.buttons) {
      buttons.push(new DummyButton.formData(button))
    }
  }
  return new DummyFeedback(
    buttons
  )
}

class DummyButton{
  constructor(level='4', intentId=0, labels=[]){
    this.level=level
    this.intentId = intentId
    this.labels=labels
  }

  toData(){
    let labels  = []
    for (const label of this.labels) {
      labels.push(label.toData())
    }
    return{
      level:this.level,
      intentId: this.intentId,
      labels: labels
    }
  }
}

DummyButton.fromData = function fromData(data) {
  let labels = []
  if (data.labels) {
    for (const label of data.labels) {
      labels.push(new Label.fromData(label))
    }
  }
  return new DummyButton(
    data.level || '4',
    data.intentId || 0,
    labels
  )
}

class Label{
  constructor(lang='', text=''){
    this.lang=lang
    this.text=text
  }

  toData(){
    return{
      lang: this.lang,
      text: this.text
    }
  }
}

Label.fromData = function fromData(data) {
  return new Label(
    data.lang || '',
    data.text || ''
  )
}

class ChatLogFeedback {
  constructor(response_id=null, display_name='', feedback_result=null, free_text='', insert_date_time=null, language_code='', score='', magnitude='', session_id='', log_id='') {
    this.responseId = response_id
    this.displayName = display_name
    this.feedbackResult = feedback_result
    this.freeText = free_text
    this.datetime = this.datetimeFormat(insert_date_time)
    this.languageCode = language_code
    this.score = score
    this.magnitude = magnitude
    this.sessionId = session_id
    this.logId = log_id
  }

  datetimeFormat(datetime) {
    return datetime.replace(/^(.+)T(.+)\..+$/, '$1 $2')
  }

}

ChatLogFeedback.fromData = function fromData(data) {
  return new ChatLogFeedback(
    data.response_id,
    data.display_name,
    data.feedback_result,
    data.free_text,
    data.insert_date_time,
    data.language_code,
    data.score,
    data.magnitude,
    data.session_id,
    data.log_id
  )
}

export {
  ButtonText,
  FeedbackData,
  Feedback,
  DummyButton,
  Label,
  DummyFeedback,
  ChatLogFeedback
}
