<template>
  <div class="answer-list">
    <div>{{ $t('answer.content') }}:</div>
    <div @keypress.enter.prevent="">
      <b-form-input
        :placeholder="$t('answer.enterListTitle')"
        v-model="responseMessage.listTitle"
        class="mb-1"
        type="text">
      </b-form-input>
    </div>
    <div>{{ $t('answer.cardButtons') }}</div>
    <div class="mb-1 section-body scroll">
      <b-card>
        <b-tabs v-model="indexTab">
          <b-tab v-for="(reply, index) in responseMessage.listButtonText"
                 :title="reply.title || 'new'"
                 :key="index">
            <div class="input-group mb-3">
              <div @keypress.enter.prevent="addListButton" v-bind:class="{'list-div':!!modalSize}">
                <div class="card-button-picurl mb-2">
                  <div class="input-group mb-1">
                    <b-form-input
                      :placeholder="$t('answer.enterImageURL')"
                      v-model="reply.url"
                      type="url"
                      @input="validationCheck"
                      :state="!$v.responseMessage.listButtonText.$each[index].url.$error"
                      ref="replyImageUrl" />
                    <div class="input-group-append">
                      <a class="btn btn-info"
                         :class="{ disabled: !reply.url || $v.responseMessage.listButtonText.$each[index].url.$error }"
                         :href="reply.url" target="_blank"
                         :title="$t('answer.preView')"
                      ><span class="fas fa-eye mt-1"/></a>
                      <a class="btn btn-primary text-white" v-b-tooltip.hover
                         :title="$t('answer.browseImage')"
                         @click="loadReplyImageUrlFiles(index)">
                        <i class="far fa-images"></i>
                      </a>
                    </div>
                    <b-form-invalid-feedback v-if="$v.responseMessage.listButtonText.$each[index].url.$error">
                      {{ $t('answer.invalidURL') }}
                    </b-form-invalid-feedback>
                  </div>
                  <file-contents ref="replyImageUrlFiles"
                                 :img-url-el="$refs.replyImageUrl"
                                 @img-url-model="reply.url = $event"></file-contents>
                  <b-form-input
                    :placeholder="$t('answer.enterImageTitle')"
                    :disabled="reply.url==false"
                    v-model="reply.imgname"
                    type="text" />
                </div>
                <div class="card-button-title">
                  <b-form-input
                    :placeholder="$t('answer.cardButtonTitle')"
                    @input="validationCheck"
                    class="mb-1"
                    v-model="reply.title"
                    :state="!$v.responseMessage.listButtonText.$each[index].title.$error"/>
                    <b-form-invalid-feedback v-if="$v.responseMessage.listButtonText.$each[index].title.$error">
                      {{ $t('webhook.required') }}
                    </b-form-invalid-feedback>
                </div>
                <b-form-textarea
                  v-model="reply.text"
                  :placeholder="$t('answer.typeText')"
                  :rows="3"
                  :max-rows="6" />
              </div>
            </div>
          </b-tab>
          <b-nav-item
            slot="tabs"
            :disabled="this.responseMessage.listButtonText.length == 30"
            @click.prevent="addListButton"
            href="#">
              {{ "+ " + (30-this.responseMessage.listButtonText.length) + " / 30" }}
          </b-nav-item>
        </b-tabs>
      </b-card>
    </div>
    <div class="float-right mb-1">
      <b-btn
        class="btn btn-danger btn-sm"
        :disabled="responseMessage.listButtonText.length == 2"
        @click.prevent="deleteListButtons">
          Close tab
      </b-btn>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, url} from 'vuelidate/lib/validators'
import {ListData, ListButtonText} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  props:['modalSize'],
  data() {
    return {
      responseMessage: {
        listTitle:'',
        listButtonText:[new ListButtonText()]
      },
      answerList:null,
      indexTab:0
    }
  },
  validations: {
    responseMessage: {
      listButtonText: {
        $each: {
          title: {
            required
          },
          url: {
            url
          }
        }
      }
    }
  },
  methods:{
    addListButton() {
      this.responseMessage.listButtonText.push(new ListButtonText())
      // リスト欄が増える度にバリデーションチェック
      this.validationCheck()
    },
    deleteListButtons(index) {
      this.responseMessage.listButtonText.splice(this.indexTab, 1)
      // リスト欄が減る度にバリデーションチェック
      this.validationCheck()
    },
    inputValue() {
      this.answerList = new ListData(
        this.responseMessage.listTitle.replace(illegalExp, "").trim(),
        this.responseMessage.listButtonText)
      for(let listButtonText of this.answerList.cardbuttons){
        listButtonText.imgname = listButtonText.imgname.replace(illegalExp, "").trim()
        listButtonText.text = listButtonText.text.replace(illegalExp, "").trim()
        listButtonText.title = listButtonText.title.replace(illegalExp, "").trim()
        listButtonText.url = listButtonText.url.replace(illegalExp, "").trim()
      }
      return this.answerList
    },
    setValue(selectedResponseMessage) {
      this.responseMessage.listTitle = selectedResponseMessage.data.listtitle
      this.responseMessage.listButtonText = selectedResponseMessage.data.cardbuttons
    },
    initializeValue() {
      this.responseMessage.listTitle = ''
      this.responseMessage.listButtonText = [new ListButtonText()]
      this.answerList = null
    },
    validationCheck() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadReplyImageUrlFiles(index) {
      this.$refs.replyImageUrlFiles[index].loadImages()
    }
  }
}
</script>
