<template>
    <div class="answer-image">
      <div>
          {{ $t('answer.content') }}({{ $t('answer.typeImage') }}):
      </div>
      <div class="input-group mb-2">
        <b-form-input
          :placeholder="$t('answer.enterImageURL')"
          :state="!$v.responseMessageImageUrl.$error"
          v-model="responseMessageImageUrl"
          type="url"
          @input="validationCheck"
          maxlength="1000"
          ref="responseMessageImageUrl"
        >
        </b-form-input>
        <div class="input-group-append">
          <a
            class="btn btn-info"
            :class="{ disabled: $v.responseMessageImageUrl.$error }"
            :href="responseMessageImageUrl"
            target="_blank"
            :title="$t('answer.preView')"
          ><span class="fas fa-eye mt-1"/>
          </a>
          <a class="btn btn-primary text-white" v-b-tooltip.hover
             :title="$t('answer.browseImage')"
             @click="loadImageUrlFiles">
            <i class="far fa-images"></i>
          </a>
        </div>
        <b-form-invalid-feedback v-if="$v.responseMessageImageUrl.$error">
          {{ $t('answer.invalidURL') }}
        </b-form-invalid-feedback>
      </div>
      <file-contents
        ref="imageUrlFiles"
        :img-url-el="$refs.responseMessageImageUrl"
        @img-url-model="setResponseMessageImageUrl($event)"></file-contents>
    </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import { url, required } from 'vuelidate/lib/validators'
import { ImageData } from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  props: {
    selectedTargetServices: Array
  },
  data() {
    return {
      answerImage: null,
      responseMessageImageUrl: '',
      required: null
    }
  },
  validations() {
    return {
      responseMessageImageUrl: {
        url,
        required
      }
    }
  },
  methods:{
    inputValue(){
      this.answerImage = new ImageData(this.responseMessageImageUrl.replace(illegalExp, "").trim())
      return this.answerImage
    },
    setValue(selectedResponseMessage){
      this.responseMessageImageUrl = selectedResponseMessage.data.url
    },
    setResponseMessageImageUrl(url){
      this.responseMessageImageUrl = url
      this.validationCheck()
    },
    initializeValue(){
      this.answerImage=null
      this.responseMessageImageUrl=''
      this.listFileContents = []
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.required = !validation.$invalid
      this.$emit('is-valid', this.required) // Declare to the parent
    },
    loadImageUrlFiles() {
      this.$refs.imageUrlFiles.loadImages()
    }
  }
}
</script>

<style scoped>
  .selected-img {
    border: 1px solid #000000;
  }
</style>
