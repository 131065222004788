import {I18n} from "../util/i18n"
import {RestApi} from "../util/rest-api"
import {Scenario, ScenarioIntentCategory} from '../model/context'
import SynchronizeController from './synchronize-controller'
import {Context, IntentCategory} from "../model/intent"
import {VIEW_URL} from "../resource/urls.js"
import axios from "axios";
import {Dataset} from "../model/intent-check";

export default class ScenarioIndexController {
  constructor(apiUrl, userType, i18nContext, isStaff, agentOptions, auto_input_predict, test_chat_platform) {
    this.auto_input_predict = auto_input_predict
    this.agentOptions = agentOptions
    this.isStaff = isStaff
    this.apiUrl = apiUrl
    this.i18n = new I18n(i18nContext)
    this.userType = userType
    this.scenarioApi = new RestApi(apiUrl.Scenario, Scenario)
    this.contextApi = new RestApi(apiUrl.Context, Context)
    this.scenarioIntentCategoryApi = new RestApi(apiUrl.ScenarioIntentCategory, ScenarioIntentCategory)
    this.intentCategoryApi = new RestApi(apiUrl.IntentCategory, IntentCategory)
    this.userSaysSchronizeApi = apiUrl.userSaysSynchronize

    this.synchronizeController = new SynchronizeController(apiUrl, this.i18n)
    this.scenarios = []
    this.scenarioEditorUrl = VIEW_URL.ScenarioEditor
    this.selectedScenario = null
    this.supportedLanguages = []
    this.datasetApi = new RestApi(apiUrl.dataset, Dataset)
    this.testChatController = null
    this.TryItNowApi = apiUrl.TryItNow
    this.itemAgent = { value: null, text: '' }
    this.testChatPlatform = test_chat_platform
  }

  ready() {
    this.loadDataset()
    this.loadScenarios()
  }

  loadDataset(){
    this.datasetApi.list()
      .then((instance) => {
        this.supportedLanguages = this.supportedLanguagesArray(instance)
        this.chatLanguage = instance[0].language_code
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * Use for transfer input prediction
   * @param csrfToken
   * @returns {Promise<any>}
   */

  inputPredictionTransfer(csrfToken=null) {
    const options = {
      headers: {'X-CSRFToken': csrfToken}
    }
    return new Promise((resolve, reject) =>
      axios.post(this.userSaysSchronizeApi, {}, options)
        .then(res => resolve(res)).catch(err => reject(err))
      )
  }

  supportedLanguagesArray(instances){
    let result = []
    for(const instance of instances){
      result[instance.language_code] = instance.language_code
    }
    return result
  }

  /**
   * agentの全シナリオの読み込み
   */
  loadScenarios() {
    this.scenarioApi.list()
      .then((scenarios) => {
        this.scenarios = scenarios
      })
  }

  /**
   * シナリオの選択
   */
  selectScenario(scenario) {
    this.selectedScenario = scenario
  }

  /**
   * シナリオを保存する
   * TODO 編集の場合はシナリオに紐付いているコンテキスト、インテントコンテキストの名前も変更する
   */
  saveScenario(scenario, csrfToken=null) {
    // 新規作成か編集かを判定する
    if (scenario.id == null) {
      // 新規作成の場合
      return this.createScenario(scenario, csrfToken)
    } else {
      // 編集の場合
      return this.editScenario(scenario, csrfToken)
    }
  }

  /**
   * シナリオ新規作成してdiagramに反映
   * シナリオ作成時、シナリオ名+[シナリオ]という名前のintentCategoryを作成する
   * シナリオとintentCategoryをscenarioIntentCategoryテーブルで紐付けておく
   */
  createScenario(scenario, csrfToken=null) {
    return new Promise((resolve, reject) => {
      // シナリオを保存
      // 最初に重複をチェックする
      if (this.existsDuplication(scenario)) {
        return reject(this.i18n.t('scenario.duplicateName'))
      }
      this.scenarioApi.save(
        new Scenario(null, scenario.name),
        csrfToken
      )
        .then((scenario) => {
          this.scenarios.push(scenario)
          this.selectedScenario = scenario
          resolve()
        })
        .catch((error) => {
          reject(error.response.data[0])
        })
    })
  }

  /**
   * シナリオの編集
   */
  editScenario(scenario, csrfToken=null) {
    return new Promise((resolve, reject) => {
      if (this.existsDuplication(scenario)) {
        return reject(this.i18n.t('scenario.duplicateName'))
      }
      this.scenarioApi.save(
        scenario,
        csrfToken
      )
        .then((instance) => {
          this.selectedScenario = instance
          this.scenarios = this.scenarios.map(s => {
            if (s.id == instance.id) {
              return instance
            }
            return s
          })
          resolve()
        })
        .catch((error) => {
          reject(error.response.data[0])
        })
    })
  }

  /**
   * Copy scenario
   * @param csrfToken
   * copyScenario
   */
  copyScenario(csrfToken=null) {
    return new Promise((resolve, reject) => {
      let sendOptions = {}
      let options = {}
      Object.assign(sendOptions, options, {
        headers: {'X-CSRFToken': csrfToken}
      })
      axios.post(
        this.apiUrl.copyScenario,
        this.selectedScenario,
        sendOptions
      )
        .then(() => {
          this.loadScenarios()
          resolve()
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  /**
   * Copy multiple scenario to target agent
   * @param csrfToken
   * @param scenario_ids
   * @param target_agent
   * @returns {Promise<any>}
   */
  copyMultipleScenario(csrfToken=null, scenario_ids, target_agent) {
    return new Promise((resolve, reject) => {
      let sendOptions = {}
      let options = {}
      let data = {scenario_ids: scenario_ids, target_agent:target_agent}
      Object.assign(sendOptions, options, {
        headers: {'X-CSRFToken': csrfToken}
      })
      axios.post(
        this.apiUrl.copyMultipleScenario,
        data,
        sendOptions
      )
        .then(() => {
          this.loadScenarios()
          resolve()
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
  /**
   * シナリオ名の重複を確認
   */
  existsDuplication(scenario) {
    if (scenario.id == null) {
      // シナリオ新規作成の場合
      let scenarioNames = this.scenarios.map(s => s.name)
      return scenarioNames.includes(scenario.name)
    }  else {
      // シナリオ編集の場合
      // 編集中のシナリオ以外で同名のシナリオがあるか判定
      let sameNameScenarios = this.scenarios.filter(s => s.name == scenario.name && s.id != scenario.id)
      return sameNameScenarios.length == 1
    }
  }

  /**
   * synchronizeControllerを呼び出してdialogflowへの同期を開始する
   */
  startSynchronize(csrfToken=null, isCard) {
    let synchResult = this.synchronizeController.synchronize(csrfToken, isCard)
    return synchResult
  }

  /*
   * シナリオを削除
   */
  deleteScenario(csrfToken=null) {
    let promise = this.scenarioApi.destroy(
      this.selectedScenario,
      csrfToken,
    )
      .then(() => {
        this.scenarios = this.scenarios.filter(scenario => scenario.id != this.selectedScenario.id)
        this.selectedScenario = null
      })

    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * Detect locale language
   * @returns {*}
   */
  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }

  /**
   * Detect Intent for checking agent state
   * @param param
   * @param csrfToken
   * @returns {Promise<unknown>}
   */
  checkAgentState(param, csrfToken='') {
    let sendOptions = {}
    let options = {}
    let params = {
      user_say: param.text,
      language: param.language
    }
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    return new Promise((resolve, reject) => {
      axios.post(
        this.TryItNowApi,
        params,
        sendOptions
      ).then((instance) => {
        let result = false
        const response = JSON.parse(instance.data)
        if("intent" in response) {
          result = true
        }
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
