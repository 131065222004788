<!--
NOTE: なるべく汎用性を注意して拡張
-->
<template>
  <b-card no-body>
    <b-tabs card small :pills="!isAnd">
      <template #tabs-end>
        <b-nav-item role="presentation" @click.prevent="addNew()" href="#"><b :title="$t('buttons.add')">+</b></b-nav-item>
      </template>

      <b-tab :key="-1" v-if="!isAnd" :title="$t('general.preview')">
        <slot name="preview-prepend"></slot>
        <condition-preview
          v-for="(condition, idx) in conditions" :key="`prev-${idx}`"
          :condition="condition"
          :andLabel="previewAndLabel"
        ></condition-preview>
      </b-tab>

      <b-tab v-for="(condition, idx) in conditions" :key="idx" :title="idx + 1">
        <b-container fluid :style="isAnd ? '' : `max-height: 60vh; overflow-y: auto;`">
          <template v-if="!isAnd && templates && templates.length > 0">
            <b-form-row class="mt-1 mb-3">
              <b-col>
                <label>{{ $t('general.applyTemplate') }}</label>
                <b-form-select :value="-1" :options="templateSelections" @change="(value) => templateSelected(value, condition)"></b-form-select>
              </b-col>
            </b-form-row>
          </template>
          <b-form-row class="my-1" v-if="!isAnd">
            <b-col>
              <b-form-checkbox v-model="condition.always"
                switch
              >
                {{ $t('general.alwaysCondition') }}
              </b-form-checkbox>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <label>{{ $t('general.compareSource') }}</label>
              <b-form-select v-model="condition.value" :disabled="condition.always" :options="selections.value"></b-form-select>
            </b-col>
          </b-form-row>

          <b-form-row class="mt-2">
            <b-col>
              <label>{{ $t('general.compareTarget') }}</label>
              <b-form-select v-model="condition.targetType" :disabled="condition.always" :options="selections.targetType" @change="condition.checkType = null"></b-form-select>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-2" v-if="condition.targetType === 'time'">
            <b-col>
              <label>{{ $t('general.specifiedTime') }}</label>
              <b-form-timepicker v-model="condition.targetValue" :disabled="condition.always"></b-form-timepicker>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-2" v-if="condition.targetType">
            <b-col>
              <label>{{ $t('general.compareType') }}</label>
              <b-form-select v-if="selections.listTargetTypes.indexOf(condition.targetType) !== -1" v-model="condition.checkType" :disabled="condition.always" :options="selections.listCheckType"></b-form-select>
              <b-form-select v-else v-model="condition.checkType" :disabled="condition.always" :options="selections.valueCheckType"></b-form-select>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-2">
            <b-col>
              <div>
                <label>{{ $t('general.andConditionDesc') }}</label>
                <b-button class="ml-1" v-if="condition.ands.length === 0" :disabled="condition.always" size="sm" variant="success" @click="andAddNew({condition: condition})">{{ $t('buttons.add') }}</b-button>
              </div>
              <condition-editor
                v-if="condition.ands.length > 0"
                :conditions="condition.ands"
                :parent="condition"
                :isAnd="true"
                :selections="selections"
                @addNew="andAddNew"
                @remove="andRemove"
              >
              </condition-editor>
            </b-col>
          </b-form-row>
          
          <b-form-row class="mt-2" v-if="!isAnd">
            <b-col>
              <label>{{ $t('general.returnValue') }}</label>
              <b-form-select v-model="condition.returnValue" :options="selections.returnValue"></b-form-select>
            </b-col>
          </b-form-row>

          <b-form-row class="mt-2">
            <b-button class="ml-auto" size="sm" variant="danger" @click="remove(idx)">{{ $t('buttons.delete') }}</b-button>
          </b-form-row>
        </b-container>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
export default {
  name: "ConditionEditor",
  props: [
    "conditions", "parent", "isAnd", "selections",
    "previewAndLabel", "templates"
  ],
  data() {
    return {
    }
  },
  computed: {
    templateSelections() {
      if (!this.templates || this.templates.length === 0) {
        return []
      }

      const selections = [
        { text: this.$t('general.applyTemplateDescription'), value: -1 }
      ]
      this.templates.forEach((template, idx) => {
        selections.push({
          value: idx,
          text: this.$t(template.name)
        })
      })
      return selections
    }
  },
  methods: {
    addNew() {
      this.$emit("addNew", {
        condition: this.parent
      })
    },
    remove(idx) {
      this.$emit("remove", {
        condition: this.parent,
        idx: idx
      })
    },
    andAddNew(payload) {
      this.$emit("addNew", payload)
    },
    andRemove(payload) {
      this.$emit("remove", payload)
    },
    templateSelected(value, condition) {
      if (value === -1) {
        return
      }
      const template = this.templates[value]
      if (template.data) {
        condition.applyTemplate(template.data)
      }
    }
  }
}
</script>

<style>

</style>