<template>
  <!-- 対応ステータス変更確認モーダル -->
  <b-modal
    id="operator-status-change-confirm-modal"
    :title="$t('history.ConfirmChangeOfTheStatusOfOperatorSession')"
    header-class="bg-info text-light"
    no-close-on-backdrop
    scrollable
    size="lg"
    style="z-index: 1080;"
    @ok="$emit('on-confirm-operator-handling-session-status-update')"
  >
    <div>
      {{ $t('history.changeOperatorStatus', { nextStatus, currentStatusLabel }) }}
    </div>
  </b-modal>
</template>

<script>
import { operatorStatus } from '../../util/operatorStatus'
import { operatorHandlingSessionStatusData } from '../../resource/operator-handling-session-status'

export default {
  name: 'OperatorHandlingSessionStatusChangeButton',
  props: ['currentOperatorHandlingSessionStatus'],
  data() {
    return {
      operatorHandlingSessionStatusData,
    }
  },
  computed: {
    /** 現在のstatusの次のstatusの表示用テキストを取得 */
    nextStatus() {
      if (this.currentOperatorHandlingSessionStatus === operatorStatus.ACTIVE) {
        return this.$t('history.Finished')
      }
      if (
        this.currentOperatorHandlingSessionStatus === operatorStatus.CALLING
      ) {
        return this.$t('history.OperatoSessionActive')
      }
    },
    /** 現在のstatusの表示用テキストを取得 */
    currentStatusLabel() {
      const value = Object.values(operatorHandlingSessionStatusData).find(
        (data) => data.value === this.currentOperatorHandlingSessionStatus
      ).value

      if (value === operatorStatus.CALLING) {
        return this.$t('history.Calling')
      } else if (value === operatorStatus.ACTIVE) {
        return this.$t('history.OperatoSessionActive')
      }
    },
  },
}
</script>
