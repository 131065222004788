import {I18n} from "../util/i18n"
import axios from 'axios'

export default class SyncLogController {
  constructor(apiUrl, i18nContext) {
    this.syncLogUrl = apiUrl.SyncLog
    this.i18n = new I18n(i18nContext)
    this.syncLogs = null
    this.isLoading = true
    this.num_page = 0
    this.params = { page: 1}
  }

  ready(){
    this.loadSyncLog()
  }
  loadSyncLog(){
    this.isLoading = true
    axios.get(this.syncLogUrl + '?page=' + this.params.page )
      .then(instance => {
        this.isLoading = false
        this.num_page = instance.data.num_page
        this.syncLogs = instance.data.results
      })
      .catch(error => {
        this.isLoading = true
        console.log(error)
      })
  }
}
