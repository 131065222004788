
import { detectStringType } from "../util/detect-string-type"

export class JsonCondition {
  constructor(data={}) {
    this._extractData(data)
  }

  _extractData(data) {
    this.always = !!data.always

    this._value = data.value || null
    this._valueType = data.value_type || null

    this._targetType = null
    this._targetContext = null
    this._targetValueType = null
    this._targetValue = null

    if (data.check_target) {
      const checkTarget = data.check_target
      if (checkTarget.type === 'context') {
        this._targetType = 'context'
        this._targetContext = checkTarget.value
      } else if (checkTarget.type === 'value') {
        this._targetType = 'value'
        this._targetValueType = checkTarget.value_type
        if (!this._targetValueType) {
          this._targetValueType = detectStringType(checkTarget.value)
        }

        if (this._targetValueType === 'number') {
          this._targetValue = parseFloat(checkTarget.value)
        } else {
          this._targetValue = checkTarget.value
        }
      }
    }

    this.checkType = data.check_type || null
    this.returnValue = typeof data.return_value !== 'undefined' ? data.return_value : null

    this.ands = Array.isArray(data.ands) ? data.ands.map((and) => this.constructor.fromData(and)) : []
  }

  applyTemplate(data) {
    this._extractData(data)
  }

  addAnd(andCondition) {
    this.ands.push(andCondition)
  }

  removeAnd(idx) {
    this.ands.splice(idx, 1)
  }

  getDescText() {
    return "You should override this method to show the description of the condition."
  }

  getReturnText() {
    return "You should override this method to show the return value of the condition."
  }

  toData() {
    const checkTarget = {}
    if (this._targetType === 'context') {
      checkTarget.type = 'context'
      checkTarget.value = this._targetContext
    } else if (this._targetType === 'value') {
      checkTarget.type = 'value'
      checkTarget.value_type = this._targetValueType
      checkTarget.value = this._targetValue
    }

    const data = {
      value: this._value,
      value_type: this._valueType,
      check_target: checkTarget,
      check_type: this.checkType,
      ands: this.ands.map((and) => and.toData()),
    }
    if (this.always) {
      data.always = true
    }
    if (this.returnValue !== null) {
      data.return_value = this.returnValue
    }
    return data
  }
}

JsonCondition.fromData = function (data) {
  return new JsonCondition(data)
}
