class WebhookLog {
  constructor(id=null, sessionId=null, action=null, actionParameter=null, statusCode=null, createdAt=null, data=null, duration=0) {
    this.id = id
    this.sessionId = sessionId
    this.action = action
    this.actionParameter = actionParameter
    this.statusCode = statusCode
    this.createdAt = createdAt
    this.data = data
    this.duration = duration
  }

  toData() {
    return {
      id: this.id,
      session_id: this.sessionId,
      action: this.action,
      action_parameter: this.actionParameter,
      status_code: this.statusCode,
      created_at: this.createdAt,
      data: this.data,
      duration: this.duration
    }
  }
}

WebhookLog.fromData = function fromData(data) {
  return new WebhookLog(
    data.id || null,
    data.session_id || null,
    data.action || null,
    data.action_parameter || null,
    data.status_code || null,
    data.created_at || null,
    data.data || null,
    data.duration || 0
  )
}

export {
  WebhookLog
}
