import { I18n } from '../util/i18n'

class DashboardController {
  constructor(i18nContext, allDays, data, url) {
    this.i18n = new I18n(i18nContext)
    this.half_montly_data = data
    this.date_range = allDays
    this.url = url + '?agent_id='
  }

  ready() {

  }
}

export {
  DashboardController
}
