<template>
  <div>
    <div>
      {{ $t('answer.content') }}({{ $t('answer.typeSendFile') }}):
    </div>
    <div class="input-group mb-2">
      <b-form-file
        v-model="responseMessageSendFile"
        :placeholder="$t('answer.choseSendFile')"
        @input="validationCheck"
        :state="!$v.responseMessageSendFile.$error"
      >
      </b-form-file>
      <b-form-invalid-feedback v-if="$v.responseMessageSendFile.$error">
        {{ $t('webhook.required') }} &
        {{
          $t('answer.fileSizeLimit', {
            size: 32,
            unit: 'MB'
          })
        }}
      </b-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { SendFileData } from '../../model/intent'

const fileSize = (file) =>  {
  if (!file) {
    return false
  }
  return (file.size <= 1024 * 1024 * 32)
}

export default {
  data() {
    return {
      answerSendFile: null,
      responseMessageSendFile: null,
      required: null
    }
  },
  validations() {
    return {
      responseMessageSendFile: {
        required,
        fileSize
      }
    }
  },
  methods: {
    inputValue(){
      this.answerSendFile = new SendFileData(this.responseMessageSendFile)
      return this.answerSendFile
    },
    initializeValue(){
      this.responseMessageSendFile = null
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.required = !validation.$invalid
      this.$emit('is-valid', this.required) // Declare to the parent
    },
  }
}
</script>

