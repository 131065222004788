import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import {DashboardRoleManageController} from './controller/dashboard-role-manage'

import DashboardRoleManage from './components/DashboardRoleManage.vue'
import ErrorModal from './components/ErrorModal.vue'
import ProgressModal from './components/ProgressModal.vue'
import ModalFooter from './components/ModalFooter.vue'
import draggable from 'vuedraggable'


export const appDashboardRoleManage = (i18n) => {
  /* コントローラ */
  const controller = new DashboardRoleManageController(
    (new UrlBuilder(API_URL)).build(),
    window.i18nContext,
    window.requestContext
  )

  /* コンポーネント登録 */
  Vue.component('dashboard-role-manage', DashboardRoleManage)
  Vue.component('error-modal', ErrorModal)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('draggable', draggable)

  new Vue({
    el: '#app-dashboard-role-manage',
    i18n: i18n,
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    }
  })
}
