class Role {
  constructor (id=null, name=null, data=[]) {
    this.id = id
    this.name = name
    this.data = data
  }

  toData() {
    let datas = []
    for(const accessPermissionData of this.data){
      datas.push(accessPermissionData.toData())
    }
    return {
      id: this.id,
      name: this.name,
      data: datas
    }
  }
}

Role.fromData = function fromData(data) {
  let datas = []
  for (const accessPermissionData of data.data) {
    datas.push(AccessPermissionData.fromData(accessPermissionData))
  }

  return new Role(
    data.id || null,
    data.name || null,
    datas
  )
}

class AccessPermissionData{
  constructor(agent_id='*', permission='editor'){
    this.agent_id=agent_id
    this.permission=permission
  }
  
  toData(){
    return{
      agent_id: this.agent_id,
      permission: this.permission
    }
  }
}

AccessPermissionData.fromData = function fromData(data) {
  return new AccessPermissionData(
    data.agent_id || '',
    data.permission || ''
  )
}

export {
  Role,
  AccessPermissionData
}
