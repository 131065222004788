<template>
  <div>
    <div>
      <ul>
        <li v-for="data in excelData" @click="selectIntent(data)">
          <div class="label my-1 p-1" :class="{selected: selectedIntent === data}" style="cursor: pointer;">
            {{ getFirstQuestion(data) }}
          </div>
          <b-card v-if="selectedIntent === data" no-body>
            <b-tabs pills card>
              <b-tab v-for="(value, key) in selectedIntent" :title="key" :key="key">
                <div v-for="val in value" class="mt-1">
                  {{ val }}
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props:['excelData'],
    data(){
      return{
        selectedIntent: null,
      }
    },
    computed:{
      getFirstQuestion(){
        return (data) => {
          let firstProp
          for(let key in data){
            if(data.hasOwnProperty(key)){
              firstProp = data[key][0]
              break
            }
          }
          return firstProp
        }
      }
    },
    methods: {
      selectIntent(data){
        this.selectedIntent = data
      }
    }
  }
</script>

<style scoped>
  /* selectedが他のcssとコンフリクトしている */
  .selected {
    border: solid 1px #777;
    background-color: #ccf;
  }
</style>
