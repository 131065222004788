import cookie from 'cookie'


const getIntentIdFromCookie = cookieString => {
  let cookies = cookie.parse(cookieString)
  if(!!cookies.intentId){
    return cookies.intentId
  }
  return null
}

const getKeywordIdFromCookie = cookieString => {
  let cookies = cookie.parse(cookieString)
  if(!!cookies.keywordId){
    return cookies.keywordId
  }
  return null
}

export default {
  getIntentIdFromCookie,
  getKeywordIdFromCookie
}
