import {RestApi} from "../util/rest-api"
import { Role } from '../model/role'
import { I18n } from '../util/i18n'

class RoleManagementController {
  constructor(apiUrl, i18nContext, requestContext) {
    this.i18n = new I18n(i18nContext)
    this.userType = requestContext.userType
    this.agentOptions = requestContext.agentOptions
    this.roleApi = new RestApi(apiUrl.RoleLog, Role)
    this.roles = []
    this.agents_dict = {}
  }
  ready(){
    for(let agent of this.agentOptions){
      this.agents_dict[agent.value] = agent.text
    }
    this.loadUserRole()
  }
  loadUserRole(){
    this.roleApi.list()
      .then(instance => {
        this.roles = instance
      })
  }
  saveRole(csrfToken=null, role){
    let promise = this.roleApi.save(role, csrfToken)
      .then((instance) => {
        if(role.id === null){ // add new role
          this.roles.push(instance)
        } else { // update existing role
          this.loadUserRole()
        }
      })
    return [true, this.i18n.t("general.saving"), promise]
  }
  deleteRole(csrfToken=null, role){
    let promise = this.roleApi.destroy(role, csrfToken)
      .then(() => {
        this.loadUserRole()
      })
    return [true, this.i18n.t("general.saving"), promise]
  }
}

export {
  RoleManagementController
}
