import { Scenario } from './context.js'
import { ResponseType } from '../resource/responses.js'
import PlatformDataResource from '../resource/platforms.js'

/**
 * インテントカテゴリ
 */

class IntentCategory {
  constructor(
    id = null,
    parentId = null,
    name = '',
    intents = null,
    subcategories = null,
    scenarioId = null,
    total_count = null
  ) {
    this.id = id
    this.parentId = parentId
    this.name = name
    this.intents = intents || []
    this.subcategories = subcategories || []
    this.scenarioId = scenarioId
    this.total_count = total_count
  }

  toData() {
    return {
      id: this.id,
      parent: this.parentId,
      name: this.name
    }
  }

  /**
   * フラットな状態のカテゴリ一覧
   */
  static categoriesAsFlat(categories, nestedLevel = 0) {
    let resultCategories = []
    for (const category of categories) {
      resultCategories.push([nestedLevel, category])
      let subcategories = IntentCategory.categoriesAsFlat(
        category.subcategories,
        nestedLevel + 1
      )
      if (subcategories.length > 0) {
        resultCategories = resultCategories.concat(subcategories)
      }
    }
    return resultCategories
  }
}

IntentCategory.fromData = function fromData(data) {
  let intents = []
  if (data.intents) {
    for (const intent_data of data.intents) {
      intents.push(Intent.fromData(intent_data))
    }
  }
  let subcategories = []
  if (data.subcategories) {
    for (const subcategory_data of data.subcategories) {
      subcategories.push(IntentCategory.fromData(subcategory_data))
    }
  }
  let scenarioId = data.scenario_id
  return new IntentCategory(
    data.id || null,
    data.parent || null,
    data.name || '',
    intents,
    subcategories,
    scenarioId,
    data.total_intent_count || null
  )
}

/*
 * インテント
 */
class Intent {
  constructor(
    id = null,
    categoryId = null,
    name = '',
    intentId = '',
    welcomeIntent = false,
    fallbackIntent = false,
    enableWebhook = false,
    isFeedbackActive = false,
    isFeedbackResponse = false,
    scenarioId = null,
    events = null,
    enableWebhookForSlotFilling = false,
    disableRedirector = false,
    intentLabelIds = [],
  ) {
    this.id = id
    this.categoryId = categoryId
    this.name = name
    this.intentId = intentId
    this.welcomeIntent = welcomeIntent
    this.fallbackIntent = fallbackIntent
    this.enableWebhook = enableWebhook
    this.isFeedbackActive = isFeedbackActive
    this.isFeedbackResponse = isFeedbackResponse
    this.scenarioId = scenarioId
    this.events = events ? events.split(',') : []
    this.enableWebhookForSlotFilling = enableWebhookForSlotFilling
    this.disableRedirector = disableRedirector
    this.intentLabelIds = JSON.parse(JSON.stringify(intentLabelIds))
  }

  toData() {
    return {
      id: this.id,
      category_id: this.categoryId,
      name: this.name,
      welcome_intent: this.welcomeIntent,
      fallback_intent: this.fallbackIntent,
      enable_webhook: this.enableWebhook,
      is_feedback_active: this.isFeedbackActive,
      events:
        this.events && this.events.length > 0 ? this.events.join(',') : null,
      enable_webhook_slot_filling: this.enableWebhookForSlotFilling,
      disable_redirector: this.disableRedirector,
      intent_labels: JSON.parse(JSON.stringify(this.intentLabelIds))
    }
  }
}

Intent.fromData = function fromData(data) {
  let scenarioId = data.scenario_id
  let isFeedbackResponse = data.is_feedback_response
  return new Intent(
    data.id || null,
    data.category_id || null,
    data.name || '',
    data.intent_id || '',
    data.welcome_intent || false,
    data.fallback_intent || false,
    data.enable_webhook || false,
    data.is_feedback_active || false,
    isFeedbackResponse || false,
    scenarioId || null,
    data.events || null,
    data.enable_webhook_slot_filling || false,
    data.disable_redirector || false,
    data.intent_labels || [],
  )
}

class Context {
  constructor(id = null, scenario = null, parentContext = null) {
    this.id = id
    this.scenario = scenario
    this.parentContext = parentContext
  }

  toData() {
    return {
      id: this.id,
      scenario: this.scenario,
      parent_context: this.parentContext.id
    }
  }
}

Context.fromData = function fromData(data) {
  return new Context(
    data.id || null,
    data.scenario ? Scenario.fromData(data.scenario) : null,
    data.parent_context ? Context.fromData(data.parent_context) : null
  )
}

class IntentContext {
  constructor(
    id = null,
    intentId = null,
    context = null,
    lifespan = null,
    isInput = true
  ) {
    this.id = id
    this.intentId = intentId
    this.context = context
    this.lifespan = lifespan
    this.isInput = isInput
  }

  toData() {
    return {
      id: this.id,
      intent_id: this.intentId,
      context_id: this.context.id,
      lifespan: this.lifespan,
      is_input: this.isInput
    }
  }

  getContextName() {
    return this.context.name
  }
}

IntentContext.fromData = function fromData(data) {
  return new IntentContext(
    data.id || null,
    data.intent_id || null,
    data.context ? Context.fromData(data.context) : null,
    data.lifespan,
    data.is_input || false
  )
}

/**
 * インテント詳細
 */
class IntentDetail {
  constructor(id = null, intentId = null, data = null) {
    this.id = id
    this.intentId = intentId
    this.data = data || new IntentData()
  }

  toData() {
    return {
      id: this.id,
      intent_id: this.intentId,
      data: this.data.toData()
    }
  }
}

IntentDetail.fromData = function fromData(data) {
  return new IntentDetail(
    data.id || null,
    data.intent_id || null,
    IntentData.fromData(data.data || {})
  )
}

/**
 * IntentDetailKeyword Model
 * */
class IntentDetailKeyword {
  constructor(id = null, intent_detail_id = null, keyword_id = null) {
    this.id = id
    this.intent_detail_id = intent_detail_id
    this.keyword_id = keyword_id
  }

  toData() {
    return {
      id: this.id,
      intent_detail_id: this.intent_detail_id,
      keyword_id: this.keyword_id
    }
  }
}

IntentDetailKeyword.fromData = function fromData(data) {
  return new IntentDetailKeyword(
    data.id || null,
    data.intent_detail_id || null,
    data.keyword_id || null
  )
}

/**
 * イベント
 */
class Event {
  constructor(name = null) {
    this.name = name
  }

  toData() {
    return {
      name: this.name
    }
  }
}

Event.fromData = function fromData(data) {
  return new Event(data.name || null)
}

/**
 * Word
 */
class Word {
  constructor(
    text = '',
    alias = null,
    meta = null,
    userDefined = false,
    entitySelected = false,
    color = null,
    promptRequired = false,
    prompts = []
  ) {
    this.text = text
    this.alias = alias
    this.meta = meta
    this.userDefined = userDefined
    this.entitySelected = entitySelected
    this.selected = false
    this.color = color
    this.promptRequired = promptRequired
    this.prompts = prompts
  }

  toData() {
    let prompts = []
    for (const prompt of this.prompts) {
      prompts.push(prompt)
    }
    return {
      text: this.text,
      alias: this.alias,
      meta: this.meta,
      userDefined: this.userDefined,
      entitySelected: this.entitySelected,
      color: this.color,
      promptRequired: this.promptRequired,
      prompts: prompts
    }
  }

  clone() {
    const newWord = new Word()
    Object.assign(newWord, this)
    return newWord
  }
}

Word.fromData = function fromData(data) {
  let prompts = []
  if (data.prompts) {
    for (const prompt of data.prompts) {
      if (prompt instanceof Object) {
        prompts.push(prompt)
      }
    }
  }

  return new Word(
    data.text || '',
    data.alias || null,
    data.meta || null,
    data.userDefined || false,
    data.entitySelected || false,
    data.color || null,
    data.promptRequired || false,
    prompts
  )
}

/**
 * UserSay
 */
class UserSay {
  constructor(id = null, language = null, data = []) {
    this.id = id
    this.language = language
    this.data = data
  }

  toData() {
    let wordData = []
    for (const word of this.data) {
      wordData.push(word.toData())
    }
    return {
      id: this.id,
      language: this.language,
      data: wordData
    }
  }

  addWord(word) {
    this.data.push(word)
  }

  get text() {
    if (!this.data) {
      return
    }
    return this.data.map(word => word.text).join('')
  }

  /**
   * rappidのdiagramに表示するためのデータ生成
   */
  toUsersayNodeData() {
    return this.data.map(word => word.text)
  }
}

UserSay.fromData = function fromData(data) {
  let wordData = []
  if (data.data) {
    for (const word_data of data.data) {
      wordData.push(Word.fromData(word_data))
    }
  }
  return new UserSay(data.id || null, data.language || null, wordData || [])
}

const platformResource = new PlatformDataResource()
const TargetService = platformResource.TargetService

/**
 * テキストデータ
 */
class TextData {
  constructor(replies = []) {
    this.replies = replies
  }

  toData() {
    let repliesData = []
    for (const reply of this.replies) {
      repliesData.push(reply.toData())
    }
    return {
      replies: repliesData
    }
  }

  /**
   * 一覧表示用のテキスト
   */
  display() {
    if (this.replies.length > 0) {
      return this.replies[0].text
    } else {
      return '<NO_TEXT>'
    }
  }

  typeDisplay() {
    return '<TEXT>'
  }

  /**
   * rappidのdiagramに表示するためのデータ生成
   */
  toNodeData() {
    return this.replies.map(r => r.text)
  }
}

TextData.fromData = function fromData(data) {
  let replies = []
  if (data.replies) {
    for (const replyData of data.replies) {
      replies.push(new ReplyText.fromData(replyData))
    }
  }
  return new TextData(replies)
}

/**
 * Card形式のデータ
 */
class CardButtonText {
  constructor(text = '', postback = '') {
    this.text = text
    this.postback = postback
  }
  toData() {
    return {
      text: this.text,
      postback: this.postback
    }
  }
}

CardButtonText.fromData = function fromData(data) {
  return new CardButtonText(data.text || '', data.postback || '')
}

class CardData {
  constructor(url = '', title = '', subtitle = '', cardbuttons = []) {
    this.url = url
    this.title = title
    this.subtitle = subtitle
    this.cardbuttons = cardbuttons
  }

  toData() {
    let cardButtons = []
    for (const cardButton of this.cardbuttons) {
      cardButtons.push(cardButton.toData())
    }
    return {
      url: this.url,
      title: this.title,
      subtitle: this.subtitle,
      cardbuttons: cardButtons
    }
  }

  display() {
    if (this.title) return this.title
    else return '<NO_CARD_TITLE>'
  }

  typeDisplay() {
    return '<CARD>'
  }

  /**
   * rappidのdiagram表示用
   */
  toNodeData() {
    let nodeData = []
    nodeData.push(this.title)
    nodeData.push(this.subtitle)
    let cardButtonData = this.cardbuttons.map(cb => ' - ' + cb.text)
    nodeData = nodeData.concat(cardButtonData)
    return nodeData
  }
}

CardData.fromData = function fromData(data) {
  let cardReplies = []
  if (data.cardbuttons) {
    for (const cardButton of data.cardbuttons) {
      cardReplies.push(new CardButtonText.fromData(cardButton))
    }
  }
  return new CardData(
    data.url || '',
    data.title || '',
    data.subtitle || '',
    cardReplies
  )
}

/**
 * Wechat用Card形式のデータ
 */
class WechatButtonText {
  constructor(title = '', description = '', url = '', picurl = '') {
    this.title = title
    this.description = description
    this.url = url
    this.picurl = picurl
  }

  toData() {
    return {
      title: this.title,
      description: this.description,
      url: this.url,
      picurl: this.picurl
    }
  }
}

WechatButtonText.fromData = function fromData(data) {
  return new WechatButtonText(
    data.title || '',
    data.description || '',
    data.url || '',
    data.picurl || ''
  )
}

class CardWechatData {
  constructor(cardbuttons = []) {
    this.cardbuttons = cardbuttons
  }

  toData() {
    let cardButtons = []
    for (const cardButton of this.cardbuttons) {
      cardButtons.push(cardButton.toData())
    }
    return {
      cardbuttons: cardButtons
    }
  }

  display() {
    if (this.cardbuttons.length > 0) {
      if (this.cardbuttons[0].title) return this.cardbuttons[0].title
      else return '<NO_CARD_TITLE>'
    } else return '<NO_CARD>'
  }

  typeDisplay() {
    return '<CARD_WECHAT>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    // cardbuttonsは配列になっているが、アイテムはひとつしか作成しない仕様になっている
    return [this.cardbuttons[0].title, this.cardbuttons[0].description]
  }
}

CardWechatData.fromData = function fromData(data) {
  let cardReplies = []
  if (data.cardbuttons) {
    for (const cardButton of data.cardbuttons) {
      cardReplies.push(new WechatButtonText.fromData(cardButton))
    }
  }
  return new CardWechatData(cardReplies)
}

/**
 * Google用Card形式のデータ
 */
class CardGoogleData {
  constructor(
    url = '',
    imgname = '',
    title = '',
    subtitle = '',
    text = '',
    linktitle = '',
    link = ''
  ) {
    this.url = url
    this.imgname = imgname
    this.title = title
    this.subtitle = subtitle
    this.text = text
    this.linktitle = linktitle
    this.link = link
  }

  toData() {
    return {
      url: this.url,
      imgname: this.imgname,
      title: this.title,
      subtitle: this.subtitle,
      text: this.text,
      linktitle: this.linktitle,
      link: this.link
    }
  }

  display() {
    if (this.title) return this.title
    else return '<NO_CARD_TITLE>'
  }

  typeDisplay() {
    return '<CARD_GOOGLE>'
  }
  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return [this.title, this.subtitle, this.text]
  }
}

CardGoogleData.fromData = function fromData(data) {
  return new CardGoogleData(
    data.url || '',
    data.imgname || '',
    data.title || '',
    data.subtitle || '',
    data.text || '',
    data.linktitle || '',
    data.link || ''
  )
}

/**
 * List
 */
class ListButtonText {
  constructor(url = '', imgname = '', title = '', text = '') {
    this.url = url
    this.imgname = imgname
    this.title = title
    this.text = text
  }

  toData() {
    return {
      url: this.url,
      imgname: this.imgname,
      title: this.title,
      text: this.text
    }
  }
}

ListButtonText.fromData = function fromData(data) {
  return new ListButtonText(
    data.url || '',
    data.imgname || '',
    data.title || '',
    data.text || ''
  )
}

class ListData {
  constructor(listtitle = '', cardbuttons = []) {
    this.listtitle = listtitle
    this.cardbuttons = cardbuttons
  }

  toData() {
    let cardButtons = []
    for (const cardButton of this.cardbuttons) {
      cardButtons.push(cardButton.toData())
    }
    return {
      listtitle: this.listtitle,
      cardbuttons: cardButtons
    }
  }

  display() {
    if (this.listtitle) return this.listtitle
    else return '<NO_LIST_TITLE>'
  }

  typeDisplay() {
    return '<LIST>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    let nodeData = [this.listtitle]
    this.cardbuttons.forEach((cb, idx) => {
      nodeData.push(' - ' + cb.title)
      nodeData.push('   - ', cb.text)
    })
    return nodeData
  }
}

ListData.fromData = function fromData(data) {
  let cardReplies = []
  if (data.cardbuttons) {
    for (const cardButton of data.cardbuttons) {
      cardReplies.push(new ListButtonText.fromData(cardButton))
    }
  }
  return new ListData(data.listtitle || '', cardReplies)
}

/**
 * SuggestionChips
 */
class SuggestionChipsData {
  constructor(replies = []) {
    this.replies = replies
  }

  toData() {
    let repliesData = []
    for (const reply of this.replies) {
      repliesData.push(reply.toData())
    }
    return {
      replies: repliesData
    }
  }

  display() {
    if (this.replies[0].text) return this.replies[0].text
    else return '<NO_DISPLAY>'
  }

  typeDisplay() {
    return '<SUGGESTION_CHIPS>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return ['Suggestion Chips'].concat(this.replies.map(r => r.text))
  }
}

SuggestionChipsData.fromData = function fromData(data) {
  let replies = []
  if (data.replies) {
    for (const replyData of data.replies) {
      replies.push(new ReplyText.fromData(replyData))
    }
  }
  return new SuggestionChipsData(replies)
}

/**
 * Google用Carousel式のデータ
 */
class CarouselButtonText {
  constructor(imgurl = '', imgname = '', title = '', text = '') {
    this.imgurl = imgurl
    this.imgname = imgname
    this.title = title
    this.text = text
  }

  toData() {
    return {
      imgurl: this.imgurl,
      imgname: this.imgname,
      title: this.title,
      text: this.text
    }
  }
}

CarouselButtonText.fromData = function fromData(data) {
  return new CarouselButtonText(
    data.imgurl || '',
    data.imgname || '',
    data.title || '',
    data.text || ''
  )
}

class CarouselGoogleData {
  constructor(carouseltitle = '', cardbuttons = []) {
    this.carouseltitle = carouseltitle
    this.cardbuttons = cardbuttons
  }

  toData() {
    let cardButtons = []
    for (const cardButton of this.cardbuttons) {
      cardButtons.push(cardButton.toData())
    }
    return {
      carouseltitle: this.carouseltitle,
      cardbuttons: cardButtons
    }
  }

  display() {
    if (this.carouseltitle) {
      return this.carouseltitle
    } else {
      return '<NO_CAROUSEL>'
    }
  }

  typeDisplay() {
    return '<CAROUSEL_GOOGLE>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    let nodeData = [this.carouseltitle]
    this.cardbuttons.forEach((cb, idx) => {
      nodeData.push(' - ' + cb.title)
      nodeData.push('   - ' + cb.text)
    })
  }
}

CarouselGoogleData.fromData = function fromData(data) {
  let cardReplies = []
  if (data.cardbuttons) {
    for (const cardButton of data.cardbuttons) {
      cardReplies.push(new CarouselButtonText.fromData(cardButton))
    }
  }
  return new CarouselGoogleData(data.carouseltitle || '', cardReplies)
}

/**
 * Browse用Carousel式のデータ
 */
class BrowseCarouselButton {
  constructor(imgurl = '', imgname = '', title = '', url = '', amp = false) {
    this.imgurl = imgurl
    this.imgname = imgname
    this.title = title
    this.url = url
    this.amp = amp
  }

  toData() {
    return {
      imgurl: this.imgurl,
      imgname: this.imgname,
      title: this.title,
      url: this.url,
      amp: this.amp
    }
  }
}

BrowseCarouselButton.fromData = function fromData(data) {
  return new BrowseCarouselButton(
    data.imgurl || '',
    data.imgname || '',
    data.title || '',
    data.url || '',
    data.amp || false
  )
}

class BrowseCarouselData {
  constructor(cardbuttons = []) {
    this.cardbuttons = cardbuttons
  }

  toData() {
    let cardButtons = []
    for (const cardButton of this.cardbuttons) {
      cardButtons.push(cardButton.toData())
    }
    return {
      cardbuttons: cardButtons
    }
  }

  display() {
    if (this.cardbuttons[0].title) {
      return this.cardbuttons[0].title
    } else {
      return '<NO_BROWSE_CAROUSEL>'
    }
  }

  typeDisplay() {
    return '<BROWSE_CAROUSEL>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    let nodeData = this.replies.map(r => r.text)
    this.cardbuttons.forEach(cb => {
      nodeData.push(' - ' + cb.title)
      nodeData.push('   - ' + cb.text)
    })
    return nodeData
  }
}

BrowseCarouselData.fromData = function fromData(data) {
  let cardReplies = []
  if (data.cardbuttons) {
    for (const cardButton of data.cardbuttons) {
      cardReplies.push(new BrowseCarouselButton.fromData(cardButton))
    }
  }
  return new BrowseCarouselData(cardReplies)
}

/**
 * Link
 */
class LinkData {
  constructor(linktitle = '', link = '') {
    this.linktitle = linktitle
    this.link = link
  }

  toData() {
    return {
      linktitle: this.linktitle,
      link: this.link
    }
  }

  display() {
    if (this.linktitle) {
      return this.linktitle
    } else {
      return '<NO_LINK_TITLE>'
    }
  }

  typeDisplay() {
    return '<LINK>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return [this.linktitle, this.link]
  }
}

LinkData.fromData = function fromData(data) {
  return new LinkData(data.linktitle || '', data.link || '')
}

/**
 * Media Content
 */
class MediaData {
  constructor(imgurl = '', imgtype = '', title = '', text = '', mediaurl = '') {
    this.imgurl = imgurl
    this.imgtype = imgtype
    this.title = title
    this.text = text
    this.mediaurl = mediaurl
  }

  toData() {
    return {
      imgurl: this.imgurl,
      imgtype: this.imgtype,
      title: this.title,
      text: this.text,
      mediaurl: this.mediaurl
    }
  }

  display() {
    if (this.title) {
      return this.title
    } else {
      return '<NO_MEDIA_TITLE>'
    }
  }

  typeDisplay() {
    return '<MEDIA_CONTENT>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    let nodeData = this.replies.map(r => r.text)
    nodeData.push(this.title)
    nodeData.push(this.text)
    return nodeData
  }
}

MediaData.fromData = function fromData(data) {
  return new MediaData(
    data.imgurl || '',
    data.imgtype || '',
    data.title || '',
    data.text || '',
    data.mediaurl || ''
  )
}

/**
 * Table Card
 */
class TableHeader {
  constructor(header = '') {
    this.header = header
  }

  toData() {
    return {
      header: this.header
    }
  }
}

TableHeader.fromData = function fromData(data) {
  return new TableHeader(data.header || '')
}

class TableCellText {
  constructor(text = '') {
    this.text = text
  }
  
  toData() {
    return {
      text: this.text
    }
  }
}

TableCellText.fromData = function fromData(data) {
  return new TableCellText(data.text || '')
}

class TableColumn {
  constructor(cells = []) {
    this.cells = cells
  }
  
  toData() {
    let tableCells = []
    for (const tableCell of this.cells) {
      tableCells.push(tableCell.toData())
    }
    return {
      cells: tableCells
    }
  }
}

TableColumn.fromData = function fromData(data) {
  let tableReplies = []
  if (data.cells) {
    for (const tableCell of data.cells) {
      tableReplies.push(new TableCellText.fromData(tableCell))
    }
  }
  return new TableColumn(tableReplies)
}

class TableData {
  constructor(
    imgurl = '',
    imgname = '',
    title = '',
    subtitle = '',
    header = [],
    table = []
  ) {
    this.imgurl = imgurl
    this.imgname = imgname
    this.title = title
    this.subtitle = subtitle
    this.header = header
    this.table = table
  }
  
  toData() {
    let headers = []
    for (const header of this.header) {
      headers.push(header.toData())
    }
    let tables = []
    for (const table of this.table) {
      tables.push(table.toData())
    }
    return {
      imgurl: this.imgurl,
      imgname: this.imgname,
      title: this.title,
      subtitle: this.subtitle,
      header: headers,
      table: tables
    }
  }

  display() {
    if (this.title) {
      return this.title
    } else {
      return '<NO_TABLE_TITLE>'
    }
  }

  typeDisplay() {
    return '<TABLE_CARD>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return this.replies.map(r => r.text)
  }
}

TableData.fromData = function fromData(data) {
  let tableHeaders = []
  if (data.header) {
    for (const Header of data.header) {
      tableHeaders.push(new TableHeader.fromData(Header))
    }
  }
  let tableColumns = []
  if (data.table) {
    for (const tableColumn of data.table) {
      tableColumns.push(new TableColumn.fromData(tableColumn))
    }
  }
  return new TableData(
    data.imgurl || '',
    data.imgname || '',
    data.title || '',
    data.subtitle || '',
    tableHeaders,
    tableColumns
  )
}

/**
 * QuickReplies形式のデータ
 */
class ReplyText {
  constructor(text = '') {
    this.text = text
  }

  toData() {
    return {
      text: this.text
    }
  }
}

ReplyText.fromData = function fromData(data) {
  return new ReplyText(
    data.text || ''
  )
}

class QuickRepliesData {
  constructor(title = '', replies = []) {
    this.title = title
    this.replies = replies
  }

  toData() {
    let repliesData = []
    for (const reply of this.replies) {
      repliesData.push(reply.toData())
    }
    return {
      title: this.title,
      replies: repliesData
    }
  }

  display() {
    if (this.title) return this.title
    else return '<NO_TITLE>'
  }

  typeDisplay() {
    return '<QUICK_REPLIES>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    let nodeData = [this.title]
    let replyData = this.replies.map(r => ' - ' + r.text)
    nodeData = nodeData.concat(replyData)
    return nodeData
  }
}

QuickRepliesData.fromData = function fromData(data) {
  let replies = []
  if (data.replies) {
    for (const replyData of data.replies) {
      replies.push(new ReplyText.fromData(replyData))
    }
  }
  return new QuickRepliesData(data.title || '', replies)
}

/**
 * 画像形式のデータ
 */
class ImageData {
  constructor(url = '') {
    this.url = url
  }

  toData() {
    return {
      url: this.url
    }
  }

  display() {
    if (this.url.length > 15) return this.url.slice(0, 15) + '...'
    else if (this.url.length === 0) return '<NO_IMAGE>'
    else return this.url
  }

  typeDisplay() {
    return '<IMAGE>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return ['画像']
  }
}

ImageData.fromData = function fromData(data) {
  return new ImageData(data.url || '')
}

/**
 * ファイル形式のデータ
 */
class SendFileData {
  constructor(file = null, sendLanguage = null) {
    this.file = file
    this.sendLanguage = sendLanguage
  }

  toData() {
    return {
      file: this.file,
      sendLanguage: this.sendLanguage
    }
  }

  typeDisplay() {
    return '<SEND_FILE>'
  }

  display() {
    return ""
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return ['送信ファイル']
  }
}

SendFileData.fromData = function fromData(data) {
  return new SendFileData(data.file || null, data.sendLanguage || null)
}

/**
 * Video
 */
class VideoData {
  constructor(videoUrl = '', imagePreviewUrl = '') {
    this.videoUrl = videoUrl
    this.imagePreviewUrl = imagePreviewUrl
  }

  toData() {
    return {
      videoUrl: this.videoUrl,
      imagePreviewUrl: this.imagePreviewUrl
    }
  }

  display() {
    if (this.videoUrl.length > 15) return this.videoUrl.slice(0, 15) + '...'
    else if (this.videoUrl.length === 0) return '<NO_VIDEO>'
    else return this.videoUrl
  }

  typeDisplay() {
    return '<VIDEO>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return ['動画']
  }
}

VideoData.fromData = function fromData(data) {
  return new VideoData(data.videoUrl || '', data.imagePreviewUrl || '')
}

/**
 * Audio
 */
class AudioData {
  constructor(name = '', audioSrc = '') {
    this.name = name
    this.audioSrc = audioSrc
  }

  toData() {
    return {
      name: this.name,
      audioSrc: this.audioSrc
    }
  }

  display() {
    if (this.audioSrc.length > 15) return this.audioSrc.slice(0, 15) + '...'
    else if (this.audioSrc.length === 0) return '<NO_AUDIO>'
    else return this.audioSrc
  }

  typeDisplay() {
    return '<AUDIO>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return ['音声']
  }
}

AudioData.fromData = function fromData(data) {
  return new AudioData(data.name || '', data.audioSrc || '')
}

/**
 * CustomPayload形式のデータ
 *
 * JSONデータはテキストのまま保持します。
 */
class CustomData {
  constructor(json = null) {
    this.json = json
  }

  toData() {
    return {
      json: this.json
    }
  }

  display() {
    if (this.json && this.json.length > 15)
      return this.json.slice(0, 15) + '...'
    else if (!this.json) return '<NO_CUSTOM_PAYLOAD>'
    else return this.json
  }

  typeDisplay() {
    return '<CUSTOM_PAYLOAD>'
  }

  contentCheck() {
    // 一覧のところで、間違ったデータを示す
    try {
      JSON.parse(this.json)
      return true
    } catch (e) {
      return false
    }
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return ['カスタムペイロード']
  }
}

CustomData.fromData = function fromData(data) {
  return new CustomData(data.json || null)
}

/**
 * BasicCard
 */
class BasicCardText {
  constructor(title = '', description = '', imageUrl = '') {
    this.title = title
    this.description = description
    this.imageUrl = imageUrl
  }
  
  toData() {
    return {
      title: this.title,
      description: this.description,
      imageUrl: this.imageUrl
    }
  }
}

BasicCardText.fromData = function fromData(data) {
  return new BasicCardText(
    data.title || '',
    data.description || '',
    data.imageUrl || ''
  )
}

class BasicCardButton {
  constructor(label = '', action = 'webLinkUrl', text = '') {
    this.label = label
    this.action = action
    this.text = text
  }

  toData() {
    return {
      label: this.label,
      action: this.action,
      text: this.text
    }
  }
}

BasicCardButton.fromData = function fromData(data) {
  return new BasicCardButton(
    data.label || '',
    data.action || '',
    data.text || ''
  )
}

class Tab {
  constructor(tabTitle = '', tabBasicCardText = [], tabBasicCardButton = []) {
    this.tabTitle = tabTitle
    this.tabBasicCardText = tabBasicCardText
    this.tabBasicCardButton = tabBasicCardButton
  }

  toData() {
    let repliesData = []
    for (const reply of this.tabBasicCardText) {
      repliesData.push(reply.toData())
    }

    let cardButtons = []
    for (const cardButton of this.tabBasicCardButton) {
      cardButtons.push(cardButton.toData())
    }

    return {
      tabTitle: this.tabTitle,
      tabBasicCardText: repliesData,
      tabBasicCardButton: cardButtons
    }
  }
}

Tab.fromData = function fromData(data) {
  let textReplies = []
  if (data.tabBasicCardText) {
    for (const cardText of data.tabBasicCardText) {
      textReplies.push(new BasicCardText.fromData(cardText))
    }
  }

  let cardReplies = []
  if (data.tabBasicCardButton) {
    for (const cardButton of data.tabBasicCardButton) {
      cardReplies.push(new BasicCardButton.fromData(cardButton))
    }
  }
  return new Tab(data.tabTitle || '', textReplies, cardReplies)
}

class BasicCardData {
  constructor(tab = []) {
    this.tab = tab
  }

  toData() {
    let cardTabsData = []
    for (const cardTab of this.tab) {
      cardTabsData.push(cardTab.toData())
    }
    return {
      tab: cardTabsData
    }
  }

  display() {
    if (this.tab[0].tabBasicCardText[0].title) {
      return this.tab[0].tabBasicCardText[0].title
    } else {
      return '<NO_BASIC_CARD>'
    }
  }

  typeDisplay() {
    return '<BASIC_CARD>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    let nodeData = []
    this.tab.forEach(t => {
      nodeData.push(t.tabTitle)
      nodeData.push(t.tabBasicCardText[0].title)
      nodeData.push(t.tabBasicCardText[0].description)
    })
    return nodeData
  }
}

BasicCardData.fromData = function fromData(data) {
  let cardTabs = []
  if (data.tab) {
    for (const tab of data.tab) {
      cardTabs.push(new Tab.fromData(tab))
    }
  }
  return new BasicCardData(cardTabs)
}

/**
 * CommerceCard
 */
class CommerceCardText {
  constructor(
    thumbnail_imageUrl = '',
    thumbnail_imageLink = '',
    profile_imageUrl = '',
    profile_nickName = '',
    price = '',
    currency = 'won',
    discount = '',
    description = ''
  ) {
    this.thumbnail_imageUrl = thumbnail_imageUrl
    this.thumbnail_imageLink = thumbnail_imageLink
    this.profile_imageUrl = profile_imageUrl
    this.profile_nickName = profile_nickName
    this.price = price
    this.currency = currency
    this.discount = discount
    this.description = description
  }

  toData() {
    return {
      thumbnail_imageUrl: this.thumbnail_imageUrl,
      thumbnail_imageLink: this.thumbnail_imageLink,
      profile_imageUrl: this.profile_imageUrl,
      profile_nickName: this.profile_nickName,
      price: this.price,
      currency: this.currency,
      discount: this.discount,
      description: this.description
    }
  }
}

CommerceCardText.fromData = function fromData(data) {
  return new CommerceCardText(
    data.thumbnail_imageUrl || '',
    data.thumbnail_imageLink || '',
    data.profile_imageUrl || '',
    data.profile_nickName || '',
    data.price || '',
    data.currency || '',
    data.discount || '',
    data.description || ''
  )
}

class CommerceCardButton {
  constructor(label = '', action = 'webLinkUrl', text = '') {
    this.label = label
    this.action = action
    this.text = text
  }

  toData() {
    return {
      label: this.label,
      action: this.action,
      text: this.text
    }
  }
}

CommerceCardButton.fromData = function fromData(data) {
  return new CommerceCardButton(
    data.label || '',
    data.action || '',
    data.text || ''
  )
}

class CommerceCardTab {
  constructor(
    tabTitle = '',
    tabCommerceCardText = [],
    tabCommerceCardButton = []
  ) {
    this.tabTitle = tabTitle
    this.tabCommerceCardText = tabCommerceCardText
    this.tabCommerceCardButton = tabCommerceCardButton
  }

  toData() {
    let repliesData = []
    for (const reply of this.tabCommerceCardText) {
      repliesData.push(reply.toData())
    }

    let cardButtons = []
    for (const cardButton of this.tabCommerceCardButton) {
      cardButtons.push(cardButton.toData())
    }

    return {
      tabTitle: this.tabTitle,
      tabCommerceCardText: repliesData,
      tabCommerceCardButton: cardButtons
    }
  }
}

CommerceCardTab.fromData = function fromData(data) {
  let textReplies = []
  if (data.tabCommerceCardText) {
    for (const cardText of data.tabCommerceCardText) {
      textReplies.push(new CommerceCardText.fromData(cardText))
    }
  }

  let cardReplies = []
  if (data.tabCommerceCardButton) {
    for (const cardButton of data.tabCommerceCardButton) {
      cardReplies.push(new CommerceCardButton.fromData(cardButton))
    }
  }
  return new CommerceCardTab(data.tabTitle || '', textReplies, cardReplies)
}

class CommerceCardData {
  constructor(commerceCardTab = []) {
    this.commerceCardTab = commerceCardTab
  }

  toData() {
    let cardTabsData = []
    for (const cardTab of this.commerceCardTab) {
      cardTabsData.push(cardTab.toData())
    }
    return {
      commerceCardTab: cardTabsData
    }
  }

  display() {
    if (this.commerceCardTab[0].tabCommerceCardText[0].description) {
      return this.commerceCardTab[0].tabCommerceCardText[0].description
    } else {
      return '<NO_COMMERCE_CARD>'
    }
  }

  typeDisplay() {
    return '<COMMERCE_CARD>'
  }
}

CommerceCardData.fromData = function fromData(data) {
  let cardTabs = []
  if (data.commerceCardTab) {
    for (const tab of data.commerceCardTab) {
      cardTabs.push(new CommerceCardTab.fromData(tab))
    }
  }
  return new CommerceCardData(cardTabs)
}

/**
 * ListCard
 */
class ListCardText {
  constructor(
    item_title = '',
    item_description = '',
    item_imageUrl = '',
    item_link = ''
  ) {
    this.item_title = item_title
    this.item_description = item_description
    this.item_imageUrl = item_imageUrl
    this.item_link = item_link
  }

  toData() {
    return {
      item_title: this.item_title,
      item_description: this.item_description,
      item_imageUrl: this.item_imageUrl,
      item_link: this.item_link
    }
  }
}

ListCardText.fromData = function fromData(data) {
  return new ListCardText(
    data.item_title || '',
    data.item_description || '',
    data.item_imageUrl || '',
    data.item_link || ''
  )
}

class ListCardButton {
  constructor(label = '', action = 'webLinkUrl', text = '') {
    this.label = label
    this.action = action
    this.text = text
  }

  toData() {
    return {
      label: this.label,
      action: this.action,
      text: this.text
    }
  }
}

ListCardButton.fromData = function fromData(data) {
  return new ListCardButton(
    data.label || '',
    data.action || '',
    data.text || ''
  )
}

class ListCardData {
  constructor(
    header_title = '',
    header_imageUrl = '',
    listCardText = [],
    listCardButton = []
  ) {
    this.header_title = header_title
    this.header_imageUrl = header_imageUrl
    this.listCardText = listCardText
    this.listCardButton = listCardButton
  }

  toData() {
    let repliesData = []
    for (const reply of this.listCardText) {
      repliesData.push(reply.toData())
    }
    let cardButtons = []
    for (const cardButton of this.listCardButton) {
      cardButtons.push(cardButton.toData())
    }
    return {
      header_title: this.header_title,
      header_imageUrl: this.header_imageUrl,
      listCardText: repliesData,
      listCardButton: cardButtons
    }
  }

  display() {
    if (this.header_title) {
      return this.header_title
    } else {
      return '<NO_LIST_CARD>'
    }
  }

  typeDisplay() {
    return '<LIST_CARD>'
  }
}

ListCardData.fromData = function fromData(data) {
  let textRelies = []
  if (data.listCardText) {
    for (const cardText of data.listCardText) {
      textRelies.push(new ListCardText.fromData(cardText))
    }
  }
  let cardReplies = []
  if (data.listCardButton) {
    for (const cardButton of data.listCardButton) {
      cardReplies.push(new ListCardButton.fromData(cardButton))
    }
  }
  return new ListCardData(
    data.header_title || '',
    data.header_imageUrl || '',
    textRelies,
    cardReplies
  )
}

/**
 * QuickSuggestion
 */
class QuickSuggestionText {
  constructor(label = '', messageText = '') {
    this.label = label
    this.messageText = messageText
  }

  toData() {
    return {
      label: this.label,
      messageText: this.messageText
    }
  }
}

QuickSuggestionText.fromData = function fromData(data) {
  return new QuickSuggestionText(data.label || '', data.messageText || '')
}

class QuickSuggestionData {
  constructor(replies = []) {
    this.replies = replies
  }

  toData() {
    let repliesData = []
    for (const reply of this.replies) {
      repliesData.push(reply.toData())
    }
    return {
      replies: repliesData
    }
  }

  display() {
    if (this.replies[0].label) return this.replies[0].label
    else return '<NO_DISPLAY>'
  }

  typeDisplay() {
    return '<QUICK_SUGGESTION>'
  }
}

QuickSuggestionData.fromData = function fromData(data) {
  let replies = []
  if (data.replies) {
    for (const reply of data.replies) {
      replies.push(new QuickSuggestionText.fromData(reply))
    }
  }
  return new QuickSuggestionData(replies)
}

/**
 * AlexaCard
 */
class AlexaCardData {
  constructor(
    text_speech = '',
    mobile_card_type = 'Simple',
    mobile_card_title = '',
    mobile_card_text = '',
    small_imageUrl = '',
    large_imageUrl = '',
    card_type = 'BodyTemplate1',
    card_title = '',
    background_description = '',
    background_imageUrl = '',
    foreground_description = '',
    foreground_imageUrl = '',
    replies = []
  ) {
    this.text_speech = text_speech
    this.mobile_card_type = mobile_card_type
    this.mobile_card_title = mobile_card_title
    this.mobile_card_text = mobile_card_text
    this.small_imageUrl = small_imageUrl
    this.large_imageUrl = large_imageUrl
    this.card_type = card_type
    this.card_title = card_title
    this.background_description = background_description
    this.background_imageUrl = background_imageUrl
    this.foreground_description = foreground_description
    this.foreground_imageUrl = foreground_imageUrl
    this.replies = replies
  }

  toData() {
    let repliesData = []
    for (const reply of this.replies) {
      repliesData.push(reply.toData())
    }
    return {
      text_speech: this.text_speech,
      mobile_card_type: this.mobile_card_type,
      mobile_card_title: this.mobile_card_title,
      mobile_card_text: this.mobile_card_text,
      small_imageUrl: this.small_imageUrl,
      large_imageUrl: this.large_imageUrl,
      card_type: this.card_type,
      card_title: this.card_title,
      background_description: this.background_description,
      background_imageUrl: this.background_imageUrl,
      foreground_description: this.foreground_description,
      foreground_imageUrl: this.foreground_imageUrl,
      replies: repliesData
    }
  }

  display() {
    if (this.text_speech) {
      return this.text_speech
    } else {
      return '<NO_ALEXA_CARD>'
    }
  }

  typeDisplay() {
    return '<ALEXA_CARD>'
  }
}

AlexaCardData.fromData = function fromData(data) {
  let replies = []
  if (data.replies) {
    for (const replyData of data.replies) {
      replies.push(new ReplyText.fromData(replyData))
    }
  }
  return new AlexaCardData(
    data.text_speech || '',
    data.mobile_card_type || '',
    data.mobile_card_title || '',
    data.mobile_card_text || '',
    data.small_imageUrl || '',
    data.large_imageUrl || '',
    data.card_type || '',
    data.card_title || '',
    data.background_description || '',
    data.background_imageUrl || '',
    data.foreground_description || '',
    data.foreground_imageUrl || '',
    replies
  )
}

/**
 * AlexaList
 */
class AlexaListTab {
  constructor(image_description = '', image_url = '', replies = []) {
    this.image_description = image_description
    this.image_url = image_url
    this.replies = replies
  }

  toData() {
    let repliesData = []
    for (const reply of this.replies) {
      repliesData.push(reply.toData())
    }
    return {
      image_description: this.image_description,
      image_url: this.image_url,
      replies: repliesData
    }
  }
}

AlexaListTab.fromData = function fromData(data) {
  let replies = []
  if (data.replies) {
    for (const replyData of data.replies) {
      replies.push(new ReplyText.fromData(replyData))
    }
  }
  return new AlexaListTab(
    data.image_description || '',
    data.image_url || '',
    replies
  )
}

class AlexaListData {
  constructor(
    text_speech = '',
    list_type = 'ListTemplate1',
    list_title = '',
    background_description = '',
    background_imageUrl = '',
    list_tab = []
  ) {
    this.text_speech = text_speech
    this.list_type = list_type
    this.list_title = list_title
    this.background_description = background_description
    this.background_imageUrl = background_imageUrl
    this.list_tab = list_tab
  }

  toData() {
    let listTabsData = []
    for (const listTab of this.list_tab) {
      listTabsData.push(listTab.toData())
    }
    return {
      text_speech: this.text_speech,
      list_type: this.list_type,
      list_title: this.list_title,
      background_description: this.background_description,
      background_imageUrl: this.background_imageUrl,
      list_tab: listTabsData
    }
  }

  display() {
    if (this.text_speech) {
      return this.text_speech
    } else {
      return '<NO_ALEXA_LIST>'
    }
  }

  typeDisplay() {
    return '<ALEXA_LIST>'
  }
}

AlexaListData.fromData = function fromData(data) {
  let listTabs = []
  if (data.list_tab) {
    for (const tab of data.list_tab) {
      listTabs.push(new AlexaListTab.fromData(tab))
    }
  }
  return new AlexaListData(
    data.text_speech || '',
    data.list_type || '',
    data.list_title || '',
    data.background_description || '',
    data.background_imageUrl || '',
    listTabs
  )
}

class LineCarouselTab {
  constructor(title = '', subtitle = '', cardbuttons = []) {
    this.title = title
    this.subtitle = subtitle
    this.cardbuttons = cardbuttons
  }

  toData() {
    let cardButtons = []
    for (const cardButton of this.cardbuttons) {
      cardButtons.push(cardButton.toData())
    }
    return {
      title: this.title,
      subtitle: this.subtitle,
      cardbuttons: cardButtons
    }
  }
}

LineCarouselTab.fromData = function fromData(data) {
  let cardReplies = []
  if (data.cardbuttons) {
    for (const cardButton of data.cardbuttons) {
      cardReplies.push(new CardButtonText.fromData(cardButton))
    }
  }
  return new LineCarouselTab(
    data.title || '',
    data.subtitle || '',
    cardReplies
  )
}

class LineCarouselData {
  constructor(tab = [], isScrollingCarousel = false) {
    this.tab = tab
    this.isScrollingCarousel = isScrollingCarousel
  }

  toData() {
    let cardTabsData = []
    for (const cardTab of this.tab) {
      cardTabsData.push(cardTab.toData())
    }
    return {
      tab: cardTabsData,
      isScrollingCarousel: this.isScrollingCarousel
    }
  }

  display() {
    if (this.tab.length > 0) {
      return this.tab[0].title
    } else {
      return '<NO_CAROUSEL_OPTIONS>'
    }
  }

  typeDisplay() {
    return '<CAROUSEL_OPTIONS>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    let nodeData = []
    if (this.tab.length > 0) {
      nodeData.push(this.tab[0].title)
      nodeData.push(this.tab[0].subtitle)
      let cardButtonData = this.tab[0].cardbuttons.map(cb => ' - ' + cb.text)
      nodeData = nodeData.concat(cardButtonData)
    }
    return nodeData
  }
}

LineCarouselData.fromData = function fromData(data) {
  let cardTabs = []
  if (data.tab) {
    for (const tab of data.tab) {
      cardTabs.push(new LineCarouselTab.fromData(tab))
    }
  }
  return new LineCarouselData(cardTabs, data.isScrollingCarousel)
}

/**
 * Sticker
 */
class StickerData {
  constructor(packageId = null, stickerId = null) {
    this.packageId = packageId
    this.stickerId = stickerId
  }

  toData() {
    return {
      packageId: this.packageId,
      stickerId: this.stickerId
    }
  }

  display() {
    return this.packageId + '_' + this.stickerId
  }

  typeDisplay() {
    return '<STICKER>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return ['Sticker']
  }
}

StickerData.fromData = function fromData(data) {
  return new StickerData(data.packageId || null, data.stickerId || null)
}

/**
 * Call Intent 回答
 */
class CallIntentData {
  constructor(text = '', event = '', data = null) {
    this.text = text
    this.event = event
    this.data = data || {}
  }

  toData() {
    console.log(this.text, this.event, this.data)
    return {
      text: this.text,
      event: this.event,
      data: this.data
    }
  }

  display() {
    if (this.event) {
      return `Event: ${this.event}`
    }
    return `Text: ${this.text}`
  }

  typeDisplay() {
    return '<CALL_INTENT>'
  }

  /**
   * rappidのdiagram用にデータ作成
   */
  toNodeData() {
    return ['Call_Intent']
  }
}

CallIntentData.fromData = function fromData(data) {
  return new CallIntentData(data.text || '', data.event || '', data.data || {})
}

/**
 * 応答
 */
class ResponseMessage {
  constructor(
    id = null,
    language = null,
    type = null,
    data = null,
    textFormat = "plain",
    targetServices = []
  ) {
    this.id = id
    this.language = language
    this.type = type
    this.data = data
    this.textFormat = textFormat
    this.targetServices = targetServices
  }

  toData() {
    return {
      id: this.id,
      language: this.language,
      type: this.type,
      data: this.data.toData(),
      text_format: this.textFormat,
      target_services: this.targetServices
    }
  }

  get isTargetGoogle() {
    return this.targetServices.includes(TargetService.GOOGLE)
  }

  get activeServices() {
    const results = []
    Object.keys(TargetService).forEach(service => {
      if (this.targetServices.includes(TargetService[service])) {
        results.push(service)
      }
    })
    return results
  }

  clone() {
    return ResponseMessage.fromData(this.toData())
  }

  toNodeData() {
    let responseTypes = []
    for (let r in ResponseType) {
      responseTypes.push(ResponseType[r])
    }
    if (responseTypes.includes(this.type)) {
      return this.data.toNodeData()
    } else {
      return ['表示できない回答タイプです']
    }
  }
}

ResponseMessage.fromData = function fromData(data) {
  let responseData
  switch (data.type) {
    case ResponseType.TEXT:
      responseData = TextData.fromData(data.data)
      break
    case ResponseType.CARD:
      responseData = CardData.fromData(data.data)
      break
    case ResponseType.CARD_WECHAT:
      responseData = CardWechatData.fromData(data.data)
      break
    case ResponseType.CARD_GOOGLE:
      responseData = CardGoogleData.fromData(data.data)
      break
    case ResponseType.LIST:
      responseData = ListData.fromData(data.data)
      break
    case ResponseType.SUGGESTION_CHIPS:
      responseData = SuggestionChipsData.fromData(data.data)
      break
    case ResponseType.CAROUSEL_GOOGLE:
      responseData = CarouselGoogleData.fromData(data.data)
      break
    case ResponseType.BROWSE_CAROUSEL:
      responseData = BrowseCarouselData.fromData(data.data)
      break
    case ResponseType.LINK:
      responseData = LinkData.fromData(data.data)
      break
    case ResponseType.MEDIA_CONTENT:
      responseData = MediaData.fromData(data.data)
      break
    case ResponseType.TABLE_CARD:
      responseData = TableData.fromData(data.data)
      break
    case ResponseType.QUICK_REPLIES:
      responseData = QuickRepliesData.fromData(data.data)
      break
    case ResponseType.IMAGE:
      responseData = ImageData.fromData(data.data)
      break
    case ResponseType.SEND_FILE:
      responseData = SendFileData.fromData(data.data)
      break
    case ResponseType.VIDEO:
      responseData = VideoData.fromData(data.data)
      break
    case ResponseType.CUSTOM_PAYLOAD:
      responseData = CustomData.fromData(data.data)
      break
    case ResponseType.BASIC_CARD:
      responseData = BasicCardData.fromData(data.data)
      break
    case ResponseType.COMMERCE_CARD:
      responseData = CommerceCardData.fromData(data.data)
      break
    case ResponseType.LIST_CARD:
      responseData = ListCardData.fromData(data.data)
      break
    case ResponseType.QUICK_SUGGESTION:
      responseData = QuickSuggestionData.fromData(data.data)
      break
    case ResponseType.ALEXA_CARD:
      responseData = AlexaCardData.fromData(data.data)
      break
    case ResponseType.ALEXA_LIST:
      responseData = AlexaListData.fromData(data.data)
      break
    case ResponseType.CAROUSEL_OPTIONS:
      responseData = LineCarouselData.fromData(data.data)
      break
    case ResponseType.AUDIO:
      responseData = AudioData.fromData(data.data)
      break
    case ResponseType.STICKER:
      responseData = StickerData.fromData(data.data)
      break
    case ResponseType.CALL_INTENT:
      responseData = CallIntentData.fromData(data.data)
      break
    default:
      console.log('unsupported type!')
      console.debug(data)
      responseData = null
      break
  }
  return new ResponseMessage(
    data.id,
    data.language,
    data.type, // 0の場合があるので注意
    responseData,
    data.text_format,
    data.target_services
  )
}

/**
 * インテントデータ本体
 * 編集時に使います
 */
class IntentData {
  constructor(
    userSays = null,
    responseMessages = null,
    fallbackIntent = false
  ) {
    this.userSays = userSays || []
    this.responseMessages = responseMessages || []
    this.fallbackIntent = fallbackIntent
  }

  toData() {
    let userSaysData = []
    let responseMessagesData = []
    for (const userSay of this.userSays) {
      userSaysData.push(userSay.toData())
    }
    for (const responseMessage of this.responseMessages) {
      responseMessagesData.push(responseMessage.toData())
    }
    return {
      userSays: userSaysData,
      responseMessages: responseMessagesData
    }
  }

  addUserSay(userSay) {
    this.userSays.push(userSay)
  }

  addResponseMessage(responseMessage) {
    this.responseMessages.push(responseMessage)
  }
}

IntentData.fromData = function fromData(data) {
  let userSays = []
  let responseMessages = []
  if (data.userSays) {
    for (const userSayData of data.userSays) {
      userSays.push(UserSay.fromData(userSayData))
    }
  }
  if (data.responseMessages) {
    for (const responseMessageData of data.responseMessages) {
      responseMessages.push(ResponseMessage.fromData(responseMessageData))
    }
  }
  return new IntentData(userSays || [], responseMessages)
}

/**
 * IntentLabel Model
 * */
class IntentLabel {
  constructor(id = null, name = null, bgcolor = null, ordering = null) {
    this.id = id
    this.name = name
    this.bgcolor = bgcolor
    this.ordering = ordering
  }

  toData() {
    return {
      id: this.id,
      name: this.name,
      bgcolor: this.bgcolor,
      ordering: this.ordering
    }
  }
}

IntentLabel.fromData = function fromData(data) {
  return new IntentLabel(
    data.id || null,
    data.name || null,
    data.bgcolor || null,
    data.ordering || null
  )
}

/**
 * インテグレーションサービスID
 */
const IntegrationService = Object.freeze({
  LINE: 'line',
  FACEBOOK: 'facebook',
  SKYPE: 'skype',
  WECHAT: 'wechat',
  WEBCHAT: 'webchat',
  GOOGLE: 'google',
  KAKAOTALK: 'kakaotalk',
  ALEXA: 'alexa',
})

export {
  IntentCategory,
  Intent,
  Context,
  IntentContext,
  IntentDetail,
  Event,
  Word,
  UserSay,
  ResponseType,
  TargetService,
  TextData,
  ReplyText,
  QuickRepliesData,
  CustomData,
  ResponseMessage,
  IntentData,
  IntegrationService,
  CardButtonText,
  CardData,
  WechatButtonText,
  CardWechatData,
  CardGoogleData,
  ListButtonText,
  ListData,
  SuggestionChipsData,
  CarouselButtonText,
  CarouselGoogleData,
  BrowseCarouselButton,
  BrowseCarouselData,
  LinkData,
  MediaData,
  TableHeader,
  TableCellText,
  TableColumn,
  TableData,
  ImageData,
  SendFileData,
  VideoData,
  BasicCardData,
  Tab,
  BasicCardText,
  BasicCardButton,
  CommerceCardData,
  CommerceCardTab,
  CommerceCardText,
  CommerceCardButton,
  ListCardText,
  ListCardButton,
  ListCardData,
  QuickSuggestionText,
  QuickSuggestionData,
  AlexaCardData,
  AlexaListTab,
  AlexaListData,
  IntentDetailKeyword,
  LineCarouselTab,
  LineCarouselData,
  AudioData,
  StickerData,
  CallIntentData,
  IntentLabel
}
