<template>
    <div v-show="showCompare" class="show-compare" :class="{'form-compare':showCompare}">
      <div class="mb-2">
        <b-btn
          :title="$t('answer.copyAnswers')"
          variant="success"
          class="mr-1"
          :disabled="!selectedLanguage || !selectedComparedMessage"
          @click="copyMessage">
          <i class="fas fa-angle-double-up"></i>
        </b-btn>
        <b-dropdown
          variant="primary"
          :text="
            selectedLanguage
            ? languageLabels[selectedLanguage]
            : $t('scenario.selectLanguage')
          "
        >
          <b-dropdown-item
            v-for="(supportedLanguage, idx) in supportedLanguages"
            v-show="supportedLanguage.code != currentLanguage"
            :key="idx"
            :disabled="supportedLanguage.code === selectedLanguage"
            @click="changeLanguage(supportedLanguage.code)"
          >
            {{ languageLabels[supportedLanguage.code] }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div>
        <b-form-select
          v-model="selectedComparedMessageValue"
          @input="onComparedMessageChanged"
          :disabled="!selectedLanguage || filteredComparedMessages.length <= 1"
          class="mb-2">
          <option v-for="option in filteredComparedMessages" :value="option.value">
              {{ option.text }}
          </option>
        </b-form-select>
      </div>

      <!--TEXT-->
      <div v-if="isResponseTypeText&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.typeText') }}):</div>
        <div v-for="reply in selectedComparedMessage.data.replies">
          <b-form-textarea class="mb-1" disabled :rows="3" :max-rows="6"
            v-model="reply.text"></b-form-textarea>
        </div>
      </div>

      <!--CARD-->
      <div v-if="isResponseTypeCard&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.typeCard') }}):</div>
        <div>
          <b-form-input class="mb-1" disabled type="url"
            v-model="selectedComparedMessage.data.url"></b-form-input>
          <b-form-input class="mb-1" disabled type="text"
            v-model="selectedComparedMessage.data.title"></b-form-input>
          <b-form-input class="mb-1" disabled type="text"
            v-model="selectedComparedMessage.data.subtitle"></b-form-input>
        </div>
        <div>{{ $t('answer.cardButtons') }}</div>
        <div v-for="reply in selectedComparedMessage.data.cardbuttons">
          <div class="input-group mb-1"><div>
            <div class="card-button-title card-input">
              <b-form-input disabled v-model="reply.text"></b-form-input></div>
            <div class="card-button-postback card-input">
              <b-form-input disabled v-model="reply.postback"></b-form-input></div>
            </div></div></div>
      </div>

      <!--CARD_WECHAT-->
      <div v-if="isResponseTypeCardWechat&&selectedComparedMessage">
        <div>{{ $t('answer.cardButtons') }}</div>
          <div v-for="reply in selectedComparedMessage.data.cardbuttons">
            <div class="mb-2">
              <b-form-input v-model="reply.title" disabled class="mb-1"/>
              <b-form-input v-model="reply.description" disabled class="mb-1"/>
              <b-form-input v-model="reply.url" disabled class="mb-1"/>
              <b-form-input v-model="reply.picurl" disabled class="mb-1"/>
            </div></div>
      </div>

      <!--CARD_GOOGLE-->
      <div v-if="isResponseTypeCardGoogle&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.typeCard') }}):</div>
        <div>
          <b-form-input class="mb-1" disabled type="url"
            v-model="selectedComparedMessage.data.url"></b-form-input>
          <b-form-input class="mb-2" disabled type="text"
            v-model="selectedComparedMessage.data.imgname"></b-form-input>
          <b-form-input class="mb-1" disabled type="text"
            v-model="selectedComparedMessage.data.title"></b-form-input>
          <b-form-input class="mb-2" disabled type="text"
            v-model="selectedComparedMessage.data.subtitle"></b-form-input>
          <b-form-textarea class="mb-2" disabled :rows="3" :max-rows="6"
            v-model="selectedComparedMessage.data.text"></b-form-textarea>
          <b-form-input class="mb-1" disabled type="text"
            v-model="selectedComparedMessage.data.linktitle"></b-form-input>
          <b-form-input disabled type="url"
            v-model="selectedComparedMessage.data.link"></b-form-input>
        </div>
      </div>

      <!--LIST-->
      <div v-if="isResponseTypeList&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}:</div>
        <div><b-form-input v-model="selectedComparedMessage.data.listtitle"
            class="mb-1" disabled type="text"></b-form-input></div>
        <div>{{ $t('answer.cardButtons') }}</div>
        <div class="mb-1 section-body scroll">
          <div v-for="reply in selectedComparedMessage.data.cardbuttons">
            <div class="input-group mb-3">
              <div>
                <div class="card-button-picurl mb-2">
                  <b-form-input class="mb-1" disabled v-model="reply.url" disabledtype="url"></b-form-input>
                  <b-form-input disabled v-model="reply.imgname" type="text"></b-form-input>
                </div>
                <div class="card-button-title">
                  <b-form-input class="mb-1" disabled v-model="reply.title"></b-form-input></div>
                <b-form-textarea v-model="reply.text" :rows="3" :max-rows="6" disabled></b-form-textarea>
              </div></div></div></div>
      </div>
      <!--SUGGESTION_CHIPS-->
      <div v-if="isResponseTypeSuggestionChips&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}:</div>
        <div v-for="reply in selectedComparedMessage.data.replies">
          <div><b-form-input class="mb-1" disabled v-model="reply.text"></b-form-input></div></div></div>

      <!--CAROUSEL_GOOGLE-->
      <div v-if="isResponseTypeCarouselGoogle&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}:</div>
        <div>
          <b-form-input v-model="selectedComparedMessage.data.carouseltitle" class="mb-1" disabled type="text"></b-form-input>
        </div>
        <div>{{ $t('answer.cardButtons') }}</div>
        <div class="mb-1 section-body scroll2">
          <div v-for="reply in selectedComparedMessage.data.cardbuttons">
            <div class="input-group mb-4">
              <div @keypress.enter.prevent="addCarouselButton">
                <div class="card-button-picurl mb-2">
                  <b-form-input v-model="reply.picurl" disabled type="url"></b-form-input></div>
                <div class="card-button-title">
                  <b-form-input v-model="reply.picname" disabled/></div>
                <b-form-input v-model="reply.title" disabled/>
                <b-form-textarea v-model="reply.text" :rows="3" :max-rows="6" disabled/>
              </div></div></div></div>
      </div>
      <!--BROWSE_CAROUSEL-->
      <div v-if="isResponseTypeBrowseCarousel&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}:</div>
        <div v-for="res in selectedComparedMessage.data.replies">
          <b-form-input class="mb-1" disabled v-model="res.text"></b-form-input></div>
        <div class="mb-2" style="height:30px">
          count: {{selectedComparedMessage.data.replies.length}}
        </div>
        <div>{{ $t('answer.typeCard') }}</div>
        <div class="mb-1 section-body scroll">
          <div v-for="reply in selectedComparedMessage.data.cardbuttons"
               class="mb-2">
            <div class="input-group">
              <div>
                <b-form-input class="mb-2" v-model="reply.picurl" disabled type="url"></b-form-input>
                <div class="card-button-title">
                  <b-form-input v-model="reply.picname" disabled></b-form-input></div>
                <b-form-input v-model="reply.title" disabled></b-form-input>
                <b-form-textarea v-model="reply.text" :rows="2" :max-rows="4" disabled></b-form-textarea>
                <b-form-input v-model="reply.url" disabled type="url"></b-form-input>
              </div>
            </div>
            <b-form-checkbox disabled v-model="reply.amp">{{ $t('answer.amp') }}</b-form-checkbox>
          </div></div></div>
      <!--LINK-->
      <div v-if="isResponseTypeLink&&selectedComparedMessage">
        <div>{{ $t('answer.cardButtons') }}</div>
        <div>
          <b-form-input class="mb-1" v-model="selectedComparedMessage.data.linktitle" disabled type="text"></b-form-input>
          <b-form-input class="mb-2" v-model="selectedComparedMessage.data.link" disabled type="url"></b-form-input></div>
      </div>

      <!--MEDIA_CONTENT-->
      <div v-if="isResponseTypeMediaContent&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}</div>
        <div v-for="res in selectedComparedMessage.data.replies">
            <b-form-input class="mb-1" disabled v-model="res.text"></b-form-input></div>
        <div class="mb-2" style="height:30px">count: {{selectedComparedMessage.data.replies.length}}</div>
        <div>{{ $t('answer.typeCard') }}</div>
        <div>
          <b-form-radio-group
            disabled
            v-model="selectedComparedMessage.data.imgtype"
            :options="[
              { value: 'noImage', text: $t('answer.noImage') },
              { value: 'largeImage', text: $t('answer.largeImage') },
              { value: 'icon', text: $t('answer.icon') }
              ]"
          ></b-form-radio-group>
          <b-form-input class="mb-2" disabled v-model="selectedComparedMessage.data.imgurl" type="url"></b-form-input>
          <b-form-input v-model="selectedComparedMessage.data.title" class="mb-1" disabled type="text"></b-form-input>
          <b-form-input v-model="selectedComparedMessage.data.text" class="mb-1" disabled type="text"></b-form-input>
          <b-form-input class="mb-2" v-model="selectedComparedMessage.data.mediaurl" disabled type="url"></b-form-input>
        </div>
      </div>

      <!--TABLE_CARD-->
      <div v-if="isResponseTypeTableCard&&selectedComparedMessage">
        <div class="word-editor mb-2">
          <b-card no-body>
            <b-tabs small card
                    :no-fade="true">
              <b-tab :title=" $t('answer.content') ">
                <div v-for="res in selectedComparedMessage.data.replies">
                  <b-form-input class="mb-1" v-model="res.text" disabled></b-form-input>
                </div>
                <div>
                  <b-form-input class="mb-1" v-model="selectedComparedMessage.data.imgurl" disabled type="url"></b-form-input>
                  <b-form-input disabled v-model="selectedComparedMessage.data.imgname" class="mb-2" type="text"></b-form-input>
                  <b-form-input v-model="selectedComparedMessage.data.title" class="mb-1" disabled type="text"></b-form-input>
                  <b-form-input v-model="selectedComparedMessage.data.subtitle" class="mb-1" disabled type="text"></b-form-input>
                </div>
              </b-tab>
              <b-tab
                :disabled="!selectedComparedMessage.data.title"
                :title=" $t('answer.table') ">
                <div>{{ this.selectedComparedMessage.data.table.length }}行{{ this.selectedComparedMessage.data.header.length }}列</div>
                <div>
                  <div class="section-body scroll mb-1">
                    <div class="table-responsive">
                      <table class="table table-hover" style="table-layout:fixed;">
                        <thead><tr>
                          <th v-for="reply in selectedComparedMessage.data.header" style="width:200px;">
                            <b-form-input v-model="reply.header" disabled></b-form-input></th></tr>
                        </thead>
                        <tr v-for="row in selectedComparedMessage.data.table">
                          <td v-for="column in row.cells"><b-form-input v-model="column.text" disabled></b-form-input></td></tr>
                      </table></div></div>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>

      <!--QUICK_REPLIES-->
      <div v-if="isResponseTypeQuickReplies&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.quickRepliesTitle') }}):</div>
        <b-form-textarea v-model="selectedComparedMessage.data.title" class="mb-2" :rows="3" disabled :max-rows="6"></b-form-textarea>
        <div>{{ $t('answer.quickRepliesResponseMessage') }}({{ $t('answer.quickRepliesUpTo') }}):</div>
        <div v-for="reply in selectedComparedMessage.data.replies">
          <b-form-input v-model="reply.text" class="mb-1" disabled></b-form-input></div>
      </div>

      <!--IMAGE-->
      <div v-if="isResponseTypeImage&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.typeImage') }}):</div>
        <b-form-input v-model="selectedComparedMessage.data.url" type="url" disabled class="mb-2"></b-form-input>
      </div>

      <!--VIDEO-->
      <div v-if="isResponseTypeVideo&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}(Video):</div>
        <b-form-input v-model="selectedComparedMessage.data.videoUrl" type="url" disabled class="mb-2"></b-form-input>
        <b-form-input v-model="selectedComparedMessage.data.imagePreviewUrl" type="url" disabled class="mb-2"></b-form-input>
      </div>

      <!--CUSTOM-->
      <div v-if="isResponseTypeCustom&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}(JSON):</div>
        <b-form-textarea v-model="selectedComparedMessage.data.json" class="mb-2" :rows="3" disabled :max-rows="6"></b-form-textarea>
      </div>

      <!--SIMPLE_TEXT-->
      <div v-if="isResponseTypeSimpleText&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.typeText') }}):</div>
        <div>
          <b-form-textarea v-model="selectedComparedMessage.data.replies" :rows="3" :max-rows="6" disabled></b-form-textarea>
        </div>
      </div>
      <!--SIMPLE_IMAGE-->
      <div v-if="isResponseTypeSimpleImage&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.typeImage') }}):</div>
        <b-form-input v-model="selectedComparedMessage.data.imageUrl" type="url" class="mb-1" disabled></b-form-input>
        <b-form-textarea v-model="selectedComparedMessage.data.altText" :rows="3" :max-rows="6" disabled></b-form-textarea>
      </div>
      <!--BASIC_CARD-->
      <div v-if="isResponseTypeBasicCard&&selectedComparedMessage">
        <div class="mb-1 scroll">
          <b-card>
            <b-tabs>
              <b-tab  :title=tab.tabTitle v-for="(tab,index) in selectedComparedMessage.data.tab" :key="index">
                <div class="mt-1">{{ $t('answer.content') }}</div>
                <div v-for="(replyText,indexText) in tab.tabBasicCardText">
                  <div>
                    <b-form-input v-model="replyText.imageUrl" class="mb-1 mt-1" disabled></b-form-input>
                    <b-form-input v-model="replyText.title" class="mb-1" disabled></b-form-input>
                    <b-form-textarea class="mb-1" v-model="replyText.description" :rows="2" :max-rows="6" disabled></b-form-textarea>
                  </div>
                </div>
                <div>{{ $t('answer.cardButtons') }}</div>
                <div v-for="(replyBtn, indexBtn) in tab.tabBasicCardButton">
                  <div>
                      <div class="card-button-title mb-1 mr-1">
                        <b-form-select
                          v-model="replyBtn.action" :options="actionOptions" disabled>
                        </b-form-select>
                      </div>
                        <div class="mb-1">
                          <b-form-input v-model="replyBtn.label" disabled></b-form-input>
                        </div>
                        <div class="mb-1">
                          <b-form-input v-if="replyBtn.action == 'webLinkUrl'" v-model="replyBtn.text" disabled></b-form-input>
                          <b-form-input v-if="replyBtn.action == 'osLink'" v-model="replyBtn.text" disabled></b-form-input>
                          <b-form-input v-if="replyBtn.action == 'messageText'" v-model="replyBtn.text" disabled></b-form-input>
                          <b-form-input v-if="replyBtn.action == 'phoneNumber'" v-model="replyBtn.text" disabled></b-form-input>
                        </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
      <!--COMMERCE_CARD-->
      <div v-if="isResponseTypeCommerceCard&&selectedComparedMessage">
        <div class="mb-1 scroll">
          <b-card>
            <b-tabs>
              <b-tab  :title=tab.tabTitle v-for="(tab,index) in selectedComparedMessage.data.commerceCardTab" :key="index">
                <div class="mt-1">{{ $t('answer.content') }}</div>
                <div v-for="(replyText,indexText) in tab.tabCommerceCardText">
                  <b-form-input v-model="replyText.thumbnail_imageUrl" class="mb-1 mt-1" disabled></b-form-input>
                  <b-form-input v-model="replyText.thumbnail_imageLink" class="mb-1" disabled></b-form-input>
                  <b-form-input v-model="replyText.profile_imageUrl" class="mb-1 mt-1" disabled></b-form-input>
                  <b-form-input v-model="replyText.profile_nickName" class="mb-1" disabled></b-form-input>
                  <b-input-group class="mb-1">
                    <b-form-input v-model="replyText.price" type="number" style="width: 60px" disabled></b-form-input>
                    <b-form-input v-model="replyText.discount" type="number" class="ml-1" style="width: 60px" disabled></b-form-input>
                    <b-form-select v-model="replyText.currency" :options="currencyOptions" class="ml-1" disabled></b-form-select>
                  </b-input-group>
                  <b-form-textarea class="mb-1" v-model="replyText.description" :rows="2" :max-rows="6" disabled></b-form-textarea>
                </div>
                <div>{{ $t('answer.cardButtons') }}</div>
                <div v-for="(replyBtn, indexBtn) in tab.tabCommerceCardButton">
                  <div>
                      <div class="card-button-title mb-1 mr-1">
                          <b-form-select  v-model="replyBtn.action" :options="actionOptions" disabled></b-form-select>
                      </div>
                      <div class="mb-1">
                        <b-form-input v-model="replyBtn.label" disabled></b-form-input>
                      </div>
                      <div class="mb-1">
                        <b-form-input v-if="replyBtn.action == 'share'" v-model="replyBtn.text" :placeholder="$t('answer.commerceCardShare')" disabled></b-form-input>
                        <b-form-input v-else v-model="replyBtn.text" disabled></b-form-input>
                      </div>
                    </div>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
      <!--LIST_CARD-->
      <div v-if="isResponseTypeListCard&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}:</div>
          <div>
            <b-form-input v-model="selectedComparedMessage.data.header_title" class="mb-1" disabled></b-form-input>
            <b-form-input v-model="selectedComparedMessage.data.header_imageUrl" class="mb-1" disabled></b-form-input>
          </div>
        <div>{{ $t('answer.list') }}({{ selectedComparedMessage.data.listCardText.length + " / 5" }})</div>

        <div class="scroll">
          <div v-for="(replyText, indexText) in selectedComparedMessage.data.listCardText" :key="indexText">
            <b-card class="mb-1">
              <div>
                <div class="card-button-title mb-1 mr-1" style="width: 70%">
                  <b-form-input v-model="replyText.item_title" disabled></b-form-input>
                </div>
                <b-form-textarea class="mb-1" v-model="replyText.item_description" :rows="2" :max-rows="6" disabled></b-form-textarea>
                <b-form-input v-model="replyText.item_imageUrl" class="mb-1" disabled></b-form-input>
                <b-form-input v-model="replyText.item_link" class="mb-1" disabled></b-form-input>
              </div>
            </b-card>
          </div>
        </div>

        <div>{{ $t('answer.cardButtons') }}</div>
        <div>
          <b-input-group class="mb-1">
            <b-form-select v-model="selectedComparedMessage.data.listCardButton[0].action" :options="actionOptions" class="mr-1" style="width: 20px" disabled></b-form-select>
            <b-form-input v-model="selectedComparedMessage.data.listCardButton[0].label" style="width: 120px" disabled></b-form-input>
          </b-input-group>
            <b-form-input v-if="selectedComparedMessage.data.listCardButton[0].action == 'share'" v-model="selectedComparedMessage.data.listCardButton[0].text" :placeholder="$t('answer.listCardShare')" class="mb-2" disabled></b-form-input>
            <b-form-input v-else v-model="selectedComparedMessage.data.listCardButton[0].text" class="mb-2" disabled></b-form-input>
        </div>
      </div>
      <!--QUICK_SUGGESTION-->
      <div v-if="isResponseTypeQuickSuggestion&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}:</div>
          <div v-for="(reply, index) in selectedComparedMessage.data.replies">
              <b-input-group>
               <b-form-input v-model="reply.label" class="mb-1 mr-1" style="width: 35px" disabled></b-form-input>
               <b-form-input v-model="reply.messageText" class="mb-1 mr-1" style="width: 120px" disabled></b-form-input>
              </b-input-group>
          </div>
        <div class="mb-2">
          ({{ selectedComparedMessage.data.replies.length + " / 10" }})
        </div>
      </div>
      <!--ALEXA_CARD-->
      <div v-if="isResponseTypeAlexaCard&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.typeText') }}):</div>
        <b-form-textarea
          class="mb-1" v-model="selectedComparedMessage.data.text_speech" :rows="2" :max-rows="3" disabled></b-form-textarea>

        <div>
          <div class="scroll">
            <b-card class="mb-1 mt-1" no-body>
              <b-tabs card>
                <b-tab :title=" $t('answer.alexaCardForDisplay') ">
                  <b-form-select class="mb-1" style="width: 85%" v-model="selectedComparedMessage.data.card_type" :options="typeTemplates" disabled></b-form-select>
                  <span class="ml-1" v-b-tooltip.hover.html.up="$t('answer.alexaTemplateInfo')">
                    <i class="fas fa-info-circle"></i></span>
                  <b-form-input
                    class="mb-1" :placeholder="$t('answer.enterCardTitle')" v-model="selectedComparedMessage.data.card_title" disabled></b-form-input>
                  <b-form-input
                    class="mb-1"
                    :placeholder="$t('answer.alexaCardBackgroundDescription')"
                    v-model="selectedComparedMessage.data.background_description" disabled></b-form-input>
                  <div class="input-group mb-1">
                    <b-form-input
                      :placeholder="$t('answer.alexaCardBackgroundURL')" v-model="selectedComparedMessage.data.background_imageUrl" disabled></b-form-input>
                    <div class="input-group-append">
                      <a class="btn btn-info" :href="selectedComparedMessage.data.background_imageUrl" target="_blank" :title="$t('answer.preView')"><span class="fas fa-eye mt-1"/></a>
                    </div>
                  </div>
                  <b-form-input
                    class="mb-1" :placeholder="$t('answer.alexaCardForegroundDescription')" v-model="selectedComparedMessage.data.foreground_description" disabled></b-form-input>
                  <div class="input-group mb-1">
                    <b-form-input
                      :placeholder="$t('answer.alexaCardForegroundURL')" v-model="selectedComparedMessage.data.foreground_imageUrl" disabled></b-form-input>
                    <div class="input-group-append">
                      <a class="btn btn-info" :href="selectedComparedMessage.data.foreground_imageUrl" target="_blank" :title="$t('answer.preView')"><span class="fas fa-eye mt-1"/></a>
                    </div>
                  </div>
                  <div v-for="(content, index) in selectedComparedMessage.data.replies">
                    <div>
                      <div class="card-button-title mb-1 mr-1" style="width: 60%">
                        <b-form-input
                          :placeholder="$t('answer.alexaCardTextContent')" v-model="content.text" disabled></b-form-input>
                      </div>
                      <div>
                        <button class="btn btn-danger mb-1" style="width: 45px; height: 37px;" disabled><i class="fa fa-minus"></i>
                        </button>
                        <button
                          class="btn btn-success mb-1"
                          style="width: 45px; height: 37px;" disabled>
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab :title=" $t('answer.alexaCardForApp') ">
                  <b-form-group>
                    <b-form-radio-group
                      v-model="selectedComparedMessage.data.mobile_card_type"
                      :options="typeCards" disabled
                      ></b-form-radio-group>
                  </b-form-group>

                  <b-form-input
                    class="mb-1" :placeholder="$t('answer.enterCardTitle')" v-model="selectedComparedMessage.data.mobile_card_title" disabled></b-form-input>
                  <b-form-textarea
                    class="mb-1"
                    v-model="selectedComparedMessage.data.mobile_card_text"
                    :placeholder="$t('answer.typeText')"
                    rows="2" max-rows="3" disabled
                  ></b-form-textarea>
                  <div class="input-group mb-1">
                    <b-form-input
                      :placeholder="$t('answer.alexaCardSmallImageURL')" v-model="selectedComparedMessage.data.small_imageUrl" disabled></b-form-input>
                    <div class="input-group-append">
                      <a
                        class="btn btn-info"
                        :href="selectedComparedMessage.data.small_imageUrl" target="_blank"
                        :title="$t('answer.preView')"
                      ><span class="fas fa-eye mt-1"/>
                      </a>
                    </div>
                  </div>
                  <div class="input-group mb-1">
                    <b-form-input
                        :placeholder="$t('answer.alexaCardLargeImageURL')" v-model="selectedComparedMessage.data.large_imageUrl" disabled></b-form-input>
                    <div class="input-group-append">
                      <a
                        class="btn btn-info"
                        :href="selectedComparedMessage.data.large_imageUrl" target="_blank"
                        :title="$t('answer.preView')"
                      ><span class="fas fa-eye mt-1"/>
                      </a>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
      <!--ALEXA_LIST-->
      <div v-if="isResponseTypeAlexaList&&selectedComparedMessage">
        <div>{{ $t('answer.content') }}({{ $t('answer.typeText') }}):</div>
        <b-form-textarea
          class="mb-1" v-model="selectedComparedMessage.data.text_speech" :rows="2" :max-rows="3" disabled></b-form-textarea>

        <div>
          <div class="input-group">
            <b-form-group>
              <b-form-radio-group
                class="mt-1" v-model="selectedComparedMessage.data.list_type" :options="typeList" disabled></b-form-radio-group>
            </b-form-group>
          <div class="input-group-append mt-2">
            <span v-b-tooltip.hover.html.up="$t('answer.alexaListTemplateInfo')">
              <i class="fas fa-info-circle"></i></span></div>
          </div>
          <b-form-input class="mb-1" :placeholder="$t('answer.enterListTitle')" v-model="selectedComparedMessage.data.list_title" disabled></b-form-input>
          <b-form-input class="mb-1" :placeholder="$t('answer.alexaCardBackgroundDescription')" v-model="selectedComparedMessage.data.background_description" disabled></b-form-input>
          <div class="input-group mb-1">
            <b-form-input :placeholder="$t('answer.alexaCardBackgroundURL')" v-model="selectedComparedMessage.data.background_imageUrl" disabled></b-form-input>
            <div class="input-group-append">
              <a class="btn btn-info" :href="selectedComparedMessage.data.background_imageUrl" target="_blank" :title="$t('answer.preView')"><span class="fas fa-eye mt-1"/></a>
            </div>
          </div>
          <div class="scroll">
            <b-card class="mb-1">
              <b-tabs>
                <b-tab title="item" v-for="(tab,index) in selectedComparedMessage.data.list_tab" :key="index">
                  <b-form-input class="mt-2 mb-1" :placeholder="$t('answer.alexaCardBackgroundDescription')" v-model="tab.image_description" disabled></b-form-input>
                  <div class="input-group mb-1">
                    <b-form-input :placeholder="$t('answer.alexaCardBackgroundURL')" v-model="tab.image_url" disabled></b-form-input>
                    <div class="input-group-append">
                      <a class="btn btn-info" :href="tab.image_url" target="_blank" :title="$t('answer.preView')"><span class="fas fa-eye mt-1"/></a>
                    </div>
                  </div>
                  <div v-for="(content, indexContent) in tab.replies">
                    <div class="card-button-title mb-1 mr-1" style="width: 60%">
                      <b-form-input :placeholder="$t('answer.alexaCardTextContent')" v-model="content.text" disabled></b-form-input>
                    </div>
                    <div>
                      <button class="btn btn-danger mb-1" style="width: 45px; height: 37px;" disabled>
                        <i class="fa fa-minus"></i>
                      </button>
                      <button class="btn btn-success mb-1" style="width: 45px; height: 37px;" disabled>
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </b-tab>
                <b-nav-item slot="tabs" @click="newTab(indexTab)" href="#" disabled>
                +
                </b-nav-item>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
      <!--Line and web_chat_v2 Carousel Option-->
      <div v-if="isResponseTypeCarouselOption&&selectedComparedMessage">
        <div class="mb-1 scroll">
          <b-card>
            <b-tabs>
              <b-tab  :title=tab.tabTitle v-for="(tab,index) in selectedComparedMessage.data.tab" :key="index">
                <div class="mt-1">{{ $t('answer.content') }}</div>
                <b-form-input v-model="tab.title" class="mb-1" disabled></b-form-input>
                <b-form-input v-model="tab.subtitle" class="mb-1" disabled></b-form-input>
                <div>{{ $t('answer.cardButtons') }}</div>
                <div v-for="(replyBtn, indexBtn) in tab.cardbuttons">
                  <div class="card-input-div">
                    <div class="card-button-title card-input">
                      <b-form-input v-model="replyBtn.text" disabled/>
                    </div>
                    <div class="card-button-postback card-input">
                      <b-form-input v-model="replyBtn.postback" disabled/>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
      <!-- Audio -->
      <div v-if="isResponseTypeAudio && selectedComparedMessage">
        <div>
            {{ $t('answer.content') }}(Audio):
        </div>
        <div class="input-group mb-2">
          <b-form-input
            v-model="selectedComparedMessage.data.name"
            disabled
          >
          </b-form-input>
        </div>
        <div class="input-group mb-2">
          <b-form-input
            v-model="selectedComparedMessage.data.audioSrc"
            disabled
          >
          </b-form-input>
          <div class="input-group-append">
            <a
              class="btn btn-info"
              :href="selectedComparedMessage.data.audioSrc" target="_blank"
              :title="$t('answer.preView')"
            ><span class="fas fa-eye mt-1"/>
            </a>
          </div>
        </div>
      </div>
      <!--Sticker message-->
      <div v-if="isResponseTypeSticker && selectedComparedMessage">
        <div class="text-center">
            <div v-if="selectedComparedMessage.data.packageId==='11537'">Brown & Cony</div>
            <div v-if="selectedComparedMessage.data.packageId==='11538'">CHOCO & Friends</div>
            <div v-if="selectedComparedMessage.data.packageId==='11539'">UNIVERSTAR</div>
        </div>
        <div class="mt-2">
          <b-img
            :src="`/static/assets/sticker/`+ selectedComparedMessage.data.packageId + `_` + selectedComparedMessage.data.stickerId +`.jpg`"
            center
            rounded="circle"
            thumbnail fluid></b-img>
          <div class="text-center">{{selectedComparedMessage.data.stickerId}}</div>
        </div>
      </div>
      <!--Call Intent-->
      <div v-if="isResponseTypeCallIntent && selectedComparedMessage">
        <div>{{ $t('answer.callIntentAction') }}:</div>
        <div>
          <div><b-form-input :placeholder="$t('answer.callIntentText')" v-model="selectedComparedMessage.data.text" class="mb-1" disabled></b-form-input></div>
          <div><b-form-input :placeholder="$t('answer.callIntentEvent')" v-model="selectedComparedMessage.data.event" class="mb-1" disabled></b-form-input></div>
        </div>
        <template v-if="Object.keys(selectedComparedMessage.data.data).length > 0">
        <div>{{ $t('answer.callIntentData') }}:</div>
        <div>
          <div><b-form-textarea :placeholder="$t('answer.callIntentData')" :value="JSON.stringify(selectedComparedMessage.data.data)" class="mb-1" disabled></b-form-textarea></div>
        </div>
        </template>
      </div>
    </div>
</template>

<script>
  import { LANGUAGE_LABELS } from "../resource/language";

  export default {
    props: [
      "showCompare",
      "supportedLanguages",
      "currentLanguage",
      "modalResponseTypeSelected",
      "selectedLanguageIntentDetail",
      "isResponseTypeText",
      "isResponseTypeCard",
      "isResponseTypeCardWechat",
      "isResponseTypeCardGoogle",
      "isResponseTypeList",
      "isResponseTypeSuggestionChips",
      "isResponseTypeCarouselGoogle",
      "isResponseTypeBrowseCarousel",
      "isResponseTypeLink",
      "isResponseTypeMediaContent",
      "isResponseTypeTableCard",
      "isResponseTypeQuickReplies",
      "isResponseTypeImage",
      "isResponseTypeVideo",
      "isResponseTypeCustom",
      "isResponseTypeSimpleText",
      "isResponseTypeSimpleImage",
      "isResponseTypeBasicCard",
      "isResponseTypeCommerceCard",
      "isResponseTypeListCard",
      "isResponseTypeQuickSuggestion",
      "isResponseTypeAlexaCard",
      "isResponseTypeAlexaList",
      "isResponseTypeCarouselOption",
      "isResponseTypeAudio",
      "isResponseTypeSticker",
      "isResponseTypeCallIntent"
    ],
    data() {
      const initialMessage = this.$t('intentDetail.comparedMessageInitial');
      const noDataMessage = this.$t('intentDetail.comparedMessageNoData');
      const waitingForSelectionMessage = this.$t('intentDetail.comparedMessageWaitingForSelection');
      return {
        selectedLanguage:null,
        selectedComparedMessage:null,
        selectedComparedMessageValue:null,
        initialMessage:initialMessage,
        noDataMessage:noDataMessage,
        waitingForSelectionMessage:waitingForSelectionMessage,
        filteredComparedMessages:[{value:null, text: initialMessage}],
        actionOptions:[
          { value: 'webLinkUrl', text: 'Web_Link'},
          { value: 'osLink', text: 'OS_Link'},
          { value: 'messageText', text: 'Message_Text'},
          { value: 'phoneNumber', text: 'Phone_Number'},
          { value: 'share', text: 'Share'}
        ],
        currencyOptions:[{value: 'won', text: 'WON'}],
        typeTemplates: [
          { value: 'BodyTemplate1', text: 'Card1(前景画像表示なし)' },
          { value: 'BodyTemplate2', text: 'Card2(前景画像右表示(EchoShowの場合))' },
          { value: 'BodyTemplate3', text: 'Card3(前景画像左表示(EchoShowの場合))' },
          { value: 'BodyTemplate6', text: 'Card4(タイトル,前景画像表示なし)' },
          { value: 'BodyTemplate7', text: 'Card5(コンテンツ表示なし)' }
        ],
        typeCards:[
          { value: 'Simple', text: 'SimpleCard'},
          { value: 'Standard', text: 'StandardCard'}
        ],
        typeList:[
          { value: 'ListTemplate1', text: 'List1(縦並び)'},
          { value: 'ListTemplate2', text: 'List2(横並び)'}
        ],
        languageLabels: LANGUAGE_LABELS,
      }
    },
    watch:{
      modalResponseTypeSelected: function(){
        this.selectedComparedMessage = null
        this.selectedComparedMessageValue = null
        this.loadComparedMessages()
      },
      selectedLanguageIntentDetail: function(){
        this.loadComparedMessages()
      }
    },
    methods:{
      init(message=this.initialMessage){
        this.selectedLanguage = null
        this.selectedComparedMessage = null
        this.selectedComparedMessageValue = null
        this.filteredComparedMessagesInit(message)
      },
      filteredComparedMessagesInit(message){
        this.filteredComparedMessages = [{value:null, text:message}]
      },
      changeLanguage(selectedLanguage){
        this.selectedLanguage = selectedLanguage
        this.$emit("load-compared-messages", this.selectedLanguage)
      },
      onComparedMessageChanged(){
        if(this.selectedComparedMessageValue&&this.selectedLanguageIntentDetail && this.selectedLanguageIntentDetail.data.responseMessages)
          this.selectedComparedMessage = this.selectedLanguageIntentDetail.data.responseMessages.find(
            rm=>rm.id === this.selectedComparedMessageValue
          )
        else{
          this.selectedComparedMessage = null
        }
      },
      loadComparedMessages(){
        if(!this.selectedLanguage){
          this.init()
          return
        }
        if(this.selectedLanguageIntentDetail && this.selectedLanguageIntentDetail.data.responseMessages){
          this.filteredComparedMessagesInit(this.initialMessage)
          this.filteredComparedMessages = this.filteredComparedMessages.concat(
            this.selectedLanguageIntentDetail.data.responseMessages.filter(rm=>
              rm.type === this.modalResponseTypeSelected
            ).map(responseMessage=> {
            return {
              value: responseMessage.id,
              text: responseMessage.data.typeDisplay() + ":" + responseMessage.data.display()
            }
          }))
        }
        if(this.filteredComparedMessages.length > 1){
          this.filteredComparedMessages[0].text = this.waitingForSelectionMessage
          this.selectedComparedMessageValue = this.filteredComparedMessages[0].value
        }
        else{
          this.init(this.noDataMessage)
        }
      },
      copyMessage(){
        this.$emit("copy-message", this.selectedComparedMessage.clone())
      }
    }
  }
</script>
