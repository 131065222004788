<template>
  <!-- テキスト形式の回答表示用コンポネント -->
  <div v-if="chatItem.text" class="mb-1 message-container">
    <div>
      <span class="message-bg-grey" style="white-space: pre-line">{{
        chatItem.text
      }}</span>

      <!-- 翻訳表示領域 -->
      <div v-if="translationObject" class="p-1 text-success text-size-sm-right">
        {{ translationObject[chatItem.text] ?? "" }}
      </div>
    </div>

    <chat-item-bottom-detail
      :chat-item="chatItem"
      :redirector-access-logs="redirectorAccessLogs"
    />
  </div>
</template>

<script>
export default {
  props: ["chatItem", "redirectorAccessLogs", "translationObject"],
};
</script>

<style scoped>
.message-container {
  display: flex;
  flex-direction: column;
}

.message-bg-grey {
  padding: 5px;
  display: inline-block;
  border-radius: 8px;
}
</style>
