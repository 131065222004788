const API_URL = {
  KeywordCategory: '/api/agent/keyword-category/',
  Keyword: '/api/agent/keyword/',
  AllKeyword: '/api/agent/keyword/all/',
  KeywordValue: '/api/agent/keyword-value/',
  IntentCategory: '/api/agent/intent-category/',
  Intent: '/api/agent/intent/',
  IntentsCopyToAgent: '/api/agent/intent/copy-to-agent/',
  DeleteMultiIntent: '/api/agent/delete_multiple_intent/',
  Scenario: '/api/agent/scenario/',
  Context: '/api/agent/context/',
  IntentContext: '/api/agent/intent-context/',
  IntentContexts: '/api/agent/intent-contexts/',
  IntentDetail: '/api/agent/intent-detail/',
  IntentDetails: '/api/agent/intent-details/',
  IntentDetailMultipleDelete: '/api/agent/intent-detail-multiple-delete/',
  IntentDetailSearch: '/api/agent/intent-detail/search/',
  AllIntent: '/api/agent/intent/all/',
  Synchronize: '/api/agent/synchronize/',
  WebhookRule: '/api/agent/webhook/rule/',
  ImportFromFile: '/api/importer/import-from-file/',
  importKeyword: '/api/agent/keyword/import/',
  importIntent: '/api/agent/intent/import/',
  apiService: '/api/agent/api-service/',
  apiServiceDelete: '/api/agent/api-service/delete/',
  ScenarioIntentCategory: '/api/agent/scenario-intent-category/',
  intentCheckHistory: '/api/agent/intent-check/history/',
  intentCheckResult: '/api/agent/intent-check/result/',
  intentCheckQuestion: '/api/agent/intent-check/question/',
  dataset: '/api/agent/dataset/',
  Intents: '/api/agent/intents/',
  ScenarioIntent: '/api/agent/scenario-intent/',
  getRedirectUrl: '/api/agent/redirector/get-redirect-url/',
  allRedirectUrls: '/api/agent/redirector/',
  intentDetailKeyword: '/api/agent/intent-detail-keyword/',
  DeleteScenarioNode: '/api/agent/delete-scenario-node/',
  ScenarioLinkConnected: '/api/agent/scenario-link-connected/',
  SystemNotificationSession: '/api/agent/system-notification-session/',
  TryItNow: '/api/agent/try-it-now/',
  HistoryLog: '/api/agent/history-log',
  ChatLog: '/api/agent/chat-log/',
  ChatLogJSON: '/api/agent/chat-log-json/',
  ChatLogDate: '/api/agent/chat-log-date/',
  selectedPlatform: '/api/agent/selected-platform/',
  KeywordSearch: '/api/agent/keyword/search',
  IdentifyAgentApi: '/api/agent/identify',
  userSaysSynchronize: '/api/agent/user-says-synchronize/',
  IntentContextLifespan: '/api/agent/intent-context/lifespan',
  SyncLog: '/api/agent/sync-log/',
  ImportIntentFromExcelPreview: '/api/agent/import-intent-from-excel/preview/',
  ImportIntentFromExcelSave: '/api/agent/import-intent-from-excel/save/',
  CheckedHistories: 'api/agent/checked-histories',
  SaveCheckedHistories: 'api/agent/save-checked-histories',
  FeedbackConfig: '/api/agent/feedback-config/',
  FeedbackHistories: '/api/agent/feedback-histories',
  SessionUserMeta: '/api/session-user-meta/',
  UserMeta: '/api/user-meta/',
  UserMetaColumn: '/api/user-meta-column/',
  UserMetaImport: '/api/user-meta-import/',
  RoleLog: '/api/agent/role-log/',
  DashboardRole: '/api/agent/dashboard/role/',
  WebhookLog: '/api/agent/webhook/log',
  Translation: '/api/agent/translation/',
  ListFileContents: '/api/upload/images',
  pushMessageUrl: '/api/agent/push-message/',
  ExternalService: '/api/external-service/',
  AccessURL: '/api/external-service-access/',
  copyScenario: '/api/agent/copy-scenario/',
  copyMultipleScenario: '/api/agent/copy-multiple-scenario/',
  availablePlatform: '/api/agent/available-platform/',
  defaultPlatform: '/api/agent/default-platform/',
  IntentSearchList: '/api/agent/intent/search-list',
  keywordBoundCount: '/api/agent/keyword-bound-count/',
  operatorSessionList: '/api/agent/operator-handling-session-list/',
  operatorSessionFetch: '/api/agent/operator-handling-session-fetch/',
  operatorSessionUpdate: '/api/agent/operator-handling-session-update/',
  operatorSessionToggleBookmark:
    '/api/agent/operator-handling-session-toggle-bookmark/',
  operatorSessionNoteUpdate:
    '/api/agent/operator-handling-session-note-update/',
  operatorSessionOperatorSettingUpdate:
    '/api/agent/operator-handling-session-operator-setting-update/',
  operatorList: '/api/agent/operator-list',
  operatorHandlingSessionBookmarkList:
    '/api/agent/operator-handling-session-bookmark-list/',
  operatorHandlingSessionEventList:
    '/api/agent/operator_handling_session_event_list/',
  speechContextFetch: '/api/agent/speech-context-fetch/',
  speechContextUpdate: '/api/agent/speech-context-update/',
  speechContextDelete: '/api/agent/speech-context-delete/',
  speechContextImportFromFile: '/api/agent/speech-context-import-from-file/',
  speechContextImportPreview: '/api/agent/speech-context-import-preview/',
  getRedirectorRelatedIntents: '/api/agent/redirector/related-intents/',
  intentLabel: '/api/agent/intent-label/',
  UpdateLabelMultiIntent: '/api/agent/multiple_intent_update_label/',
  SessionChatLogsByDate: '/api/agent/session-chat-logs-by-date/',
  pubsubServiceInfo: '/api/agent/pubsub-info/',
  // FAQのQAの取得
  transportFAQ: '/api/agent/transport/',
  getQA: '/api/agent/get-qa/',
  updateQA: '/api/agent/update-qa/',
  createQACategory: '/api/agent/create-qa-category/',
  deleteQACategory: '/api/agent/delete-qa-category/',
  listQACategory: '/api/agent/list-qa-category/',
  updateQACategory: '/api/agent/update-qa-category/',
  listQAIntent: '/api/agent/list-qa-intent/',
  updateQAIntent: '/api/agent/update-qa-intent/',
  listFAQDataset: '/api/agent/list-faq-dataset/',
  updateFAQDataset: '/api/agent/update-faq-dataset/',
  createFAQClient: '/api/agent/create-faq-client/',
  listFAQClient: '/api/agent/list-faq-client/',
  updateFAQClient: '/api/agent/update-faq-client/',
  deleteFAQClient: '/api/agent/delete-faq-client/',
  createFAQLoader: '/api/agent/create-faq-loader/',
  listFAQLoader: '/api/agent/list-faq-loader/',
  updateFAQLoader: '/api/agent/update-faq-loader/',
  deleteFAQLoader: '/api/agent/delete-faq-loader/',
  agentState: '/api/agent/state/',
  agentOperatorSessionConfig: '/api/agent/operator-session-config/',
  agentHolidays: '/api/agent/holidays/',
  agentOperatorAvailableCheck: '/api/agent/operator-available-check/',
}

const VIEW_URL = {
  ExportIntent: '/agent/intent/<categoryId>/download',
  ExportKeyword: '/agent/keyword/<categoryId>/download',
  ScenarioEditor: '/agent/scenario-editor/',
  ScenarioIndex: '/agent/scenario-index',
  ValidationDetail: '/validation/<pk>/details',
  ValidationCompare: '/validation/<pk1>/<pk2>/compare',
  ValidationDownload: '/validation/<id>/<format>/download',
  ExportRedirectUrls: '/redirector/export/<mime_type>',
  FileUpload: '/upload_create',
  FileRemove: 'upload_delete/<pk>',
  UserMetaTemplate: 'user_meta/file-export'
}

export { API_URL, VIEW_URL }
