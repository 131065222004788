import { I18n } from '../util/i18n'
import { ExternalService } from '../model/df-agent-switch'
import { RestApi } from '../util/rest-api'
import { cloneDeep } from 'lodash'


class DFAgentSwitchController {
  constructor(apiUrl, requestContext, i18nContext) {
    this.i18n = new I18n(i18nContext)
    this.cms_use_agent = requestContext.cms_use_agent
    this.bot_use_agent = requestContext.bot_use_agent
    this.cms_use_agent_obj = {}
    this.cms_use_agent_obj_copy = {}
    this.bot_use_agent_obj = {}
    this.bot_use_agent_obj_copy = {}
    this.externalServiceAPI = new RestApi(apiUrl.ExternalService, ExternalService)
    this.accessAPI = new RestApi(apiUrl.AccessURL, ExternalService)
    this.externalServiceList = []
  }

  ready() {
    this.loadAllAvailableAgent()
  }

  loadAllAvailableAgent(){
    this.externalServiceList = []
    this.externalServiceAPI.list()
      .then(instances => {
        for(const instance of instances){
          if(instance.use_in_cms && instance.gcp_project_id === this.cms_use_agent){
            this.cms_use_agent_obj = instance
            this.cms_use_agent_obj_copy = cloneDeep(instance)
          }
          if(instance.gcp_project_id === this.bot_use_agent){
            this.bot_use_agent_obj = instance
            this.bot_use_agent_obj_copy = cloneDeep(instance)
          }
          this.externalServiceList.push({value:instance, text:instance.gcp_project_id})
        }
      })
  }
  saveCmsAgentSwitch(csrfToken=null){
    let promise = this.externalServiceAPI.update(this.cms_use_agent_obj, csrfToken)
      .then((instance) => {
        this.cms_use_agent = instance.gcp_project_id
        this.cms_use_agent_obj_copy = instance
      })
    return [true, this.i18n.t("general.saving"), promise]
  }
  saveBotAgentSwitch(csrfToken=null){

    let promise = this.accessAPI.update(
      this.bot_use_agent_obj,
      csrfToken,
      {
            params: {
              gcp_project_id: this.bot_use_agent,
            }
          }
      )
      .then((instance) => {
        this.bot_use_agent = instance.gcp_project_id
        this.bot_use_agent_obj_copy = instance
      })
    return [true, this.i18n.t("general.saving"), promise]
  }
}

export {
  DFAgentSwitchController
}
