<template>
  <div class="answer-browseCarousel">
    <div>{{ $t('answer.typeCard') }}</div>
    <div class="mb-1 scroll">
    <b-card>
      <b-tabs v-model="indexTab">
        <b-tab
          v-for="(reply, index) in responseMessage.browseCarouselButton"
          :title="reply.title || 'new'"
          :key="index">
          <div class="input-group">
            <div @keypress.enter.prevent="addBrowseCarousel">
              <div class="input-group mb-1">
                <b-form-input
                  :placeholder="$t('answer.enterImageURL')"
                  @input="validationCheck"
                  v-model="reply.imgurl"
                  type="url"
                  :state="!$v.responseMessage.browseCarouselButton.$each[index].imgurl.$error"
                  ref="replyImageUrl"
                ></b-form-input>
                <div class="input-group-append">
                  <a
                    class="btn btn-info"
                    :class="{ disabled: $v.responseMessage.browseCarouselButton.$each[index].imgurl.$error }"
                    :href="reply.imgurl" target="_blank"
                    :title="$t('answer.preView')"
                  ><span class="fas fa-eye mt-1"/></a>
                </div>
                <b-form-invalid-feedback v-if="$v.responseMessage.browseCarouselButton.$each[index].imgurl.$error">
                  {{ $t('webhook.required') }} &
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
              </div>
              <file-contents
                :img-url-el="$refs.replyImageUrl"
                @img-url-model="reply.imgurl = $event">
              </file-contents>
              <div class="card-button-title">
                <b-form-input
                  :placeholder="$t('answer.enterImageTitle')"
                  :disabled="reply.imgurl==false"
                  class="mb-2"
                  v-model="reply.imgname"/>
              </div>
              <b-form-input
                :placeholder="$t('answer.enterCarouselTitle')"
                @input="validationCheck"
                class="mb-1"
                v-model="reply.title"
                :state="!$v.responseMessage.browseCarouselButton.$each[index].title.$error"
              />
              <b-form-invalid-feedback v-if="$v.responseMessage.browseCarouselButton.$each[index].title.$error">
                {{ $t('webhook.required') }}
              </b-form-invalid-feedback>
              <b-form-input
                :placeholder="$t('answer.enterURL')"
                @input="validationCheck"
                v-model="reply.url"
                type="url"
                :state="!$v.responseMessage.browseCarouselButton.$each[index].url.$error"
              ></b-form-input>
              <b-form-invalid-feedback v-if="$v.responseMessage.browseCarouselButton.$each[index].url.$error">
                {{ $t('webhook.required') }} &
                {{ $t('answer.invalidURL') }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <b-form-checkbox
            v-model="reply.amp"
            class="mb-2">
            {{ $t('answer.amp') }}
          </b-form-checkbox>
        </b-tab>
        <b-nav-item
          slot="tabs"
          :disabled="this.responseMessage.browseCarouselButton.length == 10"
          @click.prevent="addBrowseCarousel"
          href="#">
            {{ "+ " + (10-this.responseMessage.browseCarouselButton.length) + " / 10" }}
        </b-nav-item>
      </b-tabs>
    </b-card>
    </div>
    <div class="float-right mb-1">
      <b-btn
        class="btn btn-danger btn-sm"
        :disabled="responseMessage.browseCarouselButton.length == 2"
        @click.prevent="deleteBrowseCarousel">
          Close tab
      </b-btn>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, url} from 'vuelidate/lib/validators'
import {BrowseCarouselData, BrowseCarouselButton} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  data() {
    return {
      responseMessage: {
        browseCarouselButton:[new BrowseCarouselButton()]
      },
      answerBrowseCarousel:null,
      indexTab:0
    }
  },
  validations: {
    responseMessage: {
      browseCarouselButton: {
        $each: {
          title: {
            required
          },
          imgurl: {
            required,
            url
          },
          url: {
            required,
            url
          }
        }
      }
    }
  },
  methods:{
    addBrowseCarousel(){
      this.responseMessage.browseCarouselButton.push(new BrowseCarouselButton())
      // カード欄が増える度にバリデーションチェック
      this.validationCheck()
    },
    deleteBrowseCarousel(){
      this.responseMessage.browseCarouselButton.splice(this.indexTab, 1)
      // カード欄が減る度にバリデーションチェック
      this.validationCheck()
    },
    inputValue(){
      this.answerBrowseCarousel = new BrowseCarouselData(this.responseMessage.browseCarouselButton)
      for(let cardbutton of this.answerBrowseCarousel.cardbuttons){
        cardbutton.imgname = cardbutton.imgname.replace(illegalExp, "").trim()
        cardbutton.imgurl = cardbutton.imgurl.replace(illegalExp, "").trim()
        cardbutton.title = cardbutton.title.replace(illegalExp, "").trim()
        cardbutton.url = cardbutton.url.replace(illegalExp, "").trim()
      }
      return this.answerBrowseCarousel
    },
    setValue(selectedResponseMessage){
      this.responseMessage.browseCarouselButton = selectedResponseMessage.data.cardbuttons
    },
    initializeValue(){
      this.responseMessage.browseCarouselButton = [new BrowseCarouselButton()]
      this.answerBrowseCarousel=null
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    }
  }
}
</script>
