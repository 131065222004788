<template>
  <div class="language-filter-area filter-area">
    <div class="select-text">
      {{ $t('history.selectLanguage') }}<span
        class="float-right pr-2 pointer-cursor"
        @click="foldLanguageFilter">
        <span v-show="isFolded">
          <i class="fas fa-plus-circle"></i>
        </span>
        <span v-show="!isFolded">
          <i class="fas fa-minus-circle"></i>
        </span>
      </span>
    </div>
    <div
      class="content"
      :class="{hide: isFolded}">
      <b-form-select
        class="select-language"
        v-model="selectedLang"
        :state="isChangedLanguage">
        <option v-for="language in supported_languages" :value="language.value">{{ $t(language.text) }}</option>
      </b-form-select>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'supported_languages'
  ],
  data() {
    return {
      isFolded: false,
      selectedLang: 'all'
    }
  },
  computed: {
    isChangedLanguage() {
      const lang = this.selectedLang
      return this.languageTypeSelection(lang)
    }
  },
  methods: {
    foldLanguageFilter(){
      this.isFolded = !this.isFolded
    },
    languageTypeSelection(lang){
      this.$emit('language-type-selection', lang)
    }
  }
}
</script>

