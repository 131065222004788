<template>
  <b-modal
    v-if="isCard"
    :title="$t('errors.errorTitle')"
    ref="modal"
    header-class="bg-danger text-light"
    body-class="text-danger"
    scrollable
    no-close-on-backdrop
    centered
    @keypress.native.enter="$emit('key-press-enter')">
    <div class="list-group mt-1">
      <div v-if="cardErrorMsg['answer-card']">
        <span>{{$t('errors.cardError')}}</span>
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th>{{ $t('validation.language') }}</th>
              <th>{{ $t('validation.intent') }}</th>
              <th>{{ $t('validation.error') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(error, index) in cardErrorMsg['answer-card']">
              <td>{{error.language_code_display}}</td>
              <td><a v-bind:href="showSelectedErrorIntent(error)" target="_blank">{{error.intent_name}}</a></td>
              <td v-html="error.message"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr v-if="cardErrorMsg['answer-card'] && cardErrorMsg['webhook']" />
      <div class="text-dark" v-if="cardErrorMsg['webhook']">
        <span>
          {{$t('errors.webhookValidationMessage')}}<br />
          <a href="/webhook" target="_blank">{{$t('errors.goToWebhookPage')}}</a>
        </span>
        <ul class="list-group" v-for="(error, index) in cardErrorMsg['webhook']">
          <li class="list-group-item">{{error.target_parameter}}</li>
        </ul>
      </div>
    </div>
    <error-modal-footer
      slot="modal-footer"
      @ok="hide"
      @skip="trainAgain">
    </error-modal-footer>
  </b-modal>

  <b-modal
    v-else-if="slotOnly"
    :title="$t('errors.errorTitle')"
    ref="modal"
    header-class="bg-danger text-light"
    body-class="text-danger"
    no-close-on-backdrop
    ok-only
    centered
    @ok="$emit('ok')"
  >
    <slot name="body"></slot>
  </b-modal>

  <b-modal
    v-else
    :title="$t('errors.errorTitle')"
    ref="modal"
    header-class="bg-danger text-light"
    body-class="text-danger"
    no-close-on-backdrop
    centered
    @keypress.native.enter="$emit('key-press-enter')">
    <p v-if="!botBrokenMessage">{{ message }}</p>
    <span class="spinner-border spinner-border-sm" v-if="isBotBroken"></span>
    <span class="text-danger" v-if="isBotBroken">checking...</span>
    <p v-if="!isBotBroken">{{botBrokenMessage}}</p>
    <div slot="modal-footer">
      <a href="/sync-log" target="_blank" class="btn btn-success">
        {{$t('syncLog.syncLogList')}}
      </a>
      <b-btn
        variant="primary"
        @click="hide">
        OK
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import {I18n} from "../util/i18n"
export default {
  props: ['message', 'isCard', 'cardErrorMsg', 'isBotBroken', 'botBrokenMessage', 'slotOnly'],
  data(){
    return{
      i18n: new I18n(window.i18nContext)
    }
  },
  methods: {
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
    trainAgain(){
      this.$emit('train', this.isCard)
      this.hide()
    },
    showSelectedErrorIntent(error){
      let intentName = error.intent_category_full_path
      const currentUrl = location.href
      const regex = RegExp('agent.+')
      const destination = `agent/${error.language_code}/intent?intent_name=${intentName}`
      return currentUrl.match(regex) ? currentUrl.replace(regex, destination) : destination
    }
  }
}
</script>
