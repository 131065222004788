<template>
  <div>
    <div class="d-flex justify-content-between">
      <a href="/dashboard/tenant-agent-manage" class="btn btn-info mr-2">
        <i class="fas fa-arrow-left"></i>
        Back
      </a>
      <button title="Add New" class="btn btn-success"
              @click="openAddRoleModal">
        <i class="fas fa-user-tag"></i> Add New Role
      </button>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-hover text-center">
            <thead class="table-info">
              <tr>
                <th>Role Name</th>
                <th>Agent Name (Permission)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="role in controller.listRoles">
                <td>{{ role.name }}</td>
                <td>
                  <span v-for="(agent_and_per, index) in role.data">
                    {{controller.agents_dict[agent_and_per.agent_id]}} ({{agent_and_per.permission}})
                    <span v-if="role.data.length > (index+1)">, </span>
                  </span>
                </td>
                <td>
                  <button
                    title="Edit"
                    class="btn btn-info btn-sm" @click="updateRole(role)">
                    <i class="fas fa-edit"></i>
                  </button> |
                  <button
                    title="Delete"
                    class="btn btn-danger btn-sm" @click="removeRole(role)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- .table-responsive -->
      </div>
    </div>
    <!--Add new role modal-->
    <b-modal
      :title="modalTitle"
      ref="rolemodal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop>
      <div>
        <form @submit.prevent="formRoleSubmit">
          <div>
            Role Name:
          </div>
          <b-form-input
            type="text"
            ref="focusInput"
            v-model="formRole.name"
            :state="!$v.formRole.name.$error">
          </b-form-input>
          <div class="mt-2">Rules: </div>
          <draggable v-model="formRole.data" handle=".handle">
            <div v-for="(row, index) in formRole.data">
              <b-input-group>
                <b-form-select
                  v-model="row.agent_id"
                  :options="agentOptions"
                  class="mb-1 mr-1"
                  :state="!$v.formRole.data.$each[index].agent_id.$error">
                </b-form-select>
                <b-form-select
                  v-model="row.permission"
                  :options="permissions"
                  class="mb-1 mr-1" style="width: 120px"
                  :state="!$v.formRole.data.$each[index].permission.$error">
                </b-form-select>
                <div class="input-group-append">
                  <button
                    class="btn btn-danger"
                    style="height: 38px"
                    :disabled="formRole.data.length === 1"
                    @click.prevent="removeRoleData(index)">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
                <button class="handle btn btn-success ml-1" style="height: 38px" type="button">
                  <i class="fas fa-arrows-alt"></i>
                </button>
              </b-input-group>
            </div>
          </draggable>
        </form>
        <div class="mb-2">
          <button
            class="btn btn-success"
            @click.prevent="addRoleData">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <modal-footer
        slot="modal-footer"
        :okDisabled="false"
        @ok="formRoleSubmit"
        @cancel="formRoleCancel">
      </modal-footer>
    </b-modal><!-- END Add new role modal-->
    <!--Delete role confirmation modal-->
    <b-modal
      title="Delete Role"
      ref="deleteConfirmModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop>
      <div class="text-center">
        Are you sure to delete role "{{delete_role.name}}"?
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="deleteRoleConfirmBtn"
        @cancel="deleteRoleCancelBtn">
      </modal-footer>
    </b-modal><!-- END Delete role confirmation modal-->
    <error-modal ref="errorModal" :message="message"/>
    <progress-modal ref="progressModal" :message="message"/>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import {Role, AccessPermissionData} from '../model/role'
import csrfToken from '../util/csrf-token'

export default {
  props: ['controller'],
  data: function() {
    return {
      agentOptions: this.controller.listAgents,
      message:'',
      modalTitle: '',
      delete_role: {},
      formRole: {
        name: '',
        data: [],
      },
      permissions:[
        {value: 'admin', text: 'Admin'},
        {value: 'editor', text: 'Editor'},
        {value: 'viewer', text: 'Viewer'},
      ]
    }
  },
  validations: {
    formRole: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      data: {
        $each: {
          agent_id: {
            required
          },
          permission: {
            required
          }
        }
      }
    }
  },
  methods: {
    openAddRoleModal() {
      this.modalTitle = "Create Role"
      this.formRole = new Role()
      this.formRole.data = [new AccessPermissionData()]
      this.$refs.rolemodal.show()
    },
    addRoleData() {
      this.formRole.data.push(new AccessPermissionData())
    },
    removeRoleData(index) {
      this.formRole.data.splice(index, 1)
    },
    formRoleSubmit() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      if(!validation.$invalid){
        this.$refs.rolemodal.hide()
        let result, message, promise
        [result, message, promise] = this.controller.createRole(
          this.formRole,
          csrfToken.getCsrfTokenFromCookie(document.cookie))
          if(!result) {
            this.showError(this.i18n.t('feedback.error'))
          } else {
            this.showProgress(message)
            let self = this
            promise.then(response => {
              if(response){
                self.formRole = {}
                self.hideProgress()
                self.controller.getRoleByTenant()
              }
            })
            .catch(function(error){
              self.hideProgress()
              self.formRole = {}
              self.showError(error)
            })
          }
      }
    },
    formRoleCancel() {
      this.formRole = {}
      this.$refs.rolemodal.hide()
    },
    updateRole(role) {
      this.formRole = role
      this.modalTitle = "Update Role"
      this.$refs.rolemodal.show()
    },
    removeRole(role) {
      this.delete_role = role
      this.$refs.deleteConfirmModal.show()
    },
    deleteRoleCancelBtn() {
      this.delete_role = {}
      this.$refs.deleteConfirmModal.hide()
    },
    deleteRoleConfirmBtn() {
      this.$refs.deleteConfirmModal.hide()
      let result, message, promise
      [result, message, promise] = this.controller.destroyRole(this.delete_role,
        csrfToken.getCsrfTokenFromCookie(document.cookie))
      if (!result) {
        this.showError(this.i18n.t('feedback.error'))
      }else{
        this.showProgress(message)
        let self = this
        promise
        .then(()=>{
          self.delete_role = {}
          self.hideProgress()
        })
        .catch(function(error){
          self.hideProgress()
          self.delete_role = {}
          self.showError(error)
        })
      }
    },
    showError(message) {
      this.message = message
      this.$refs.errorModal.show()
    },
    showProgress(message) {
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    }
  }
}
</script>

<style scoped>
</style>
