class KeywordCategory {
  constructor (id=null, parentId=null, name='', keywords=null, subcategories=null, total_count=null) {
    this.id = id
    this.parentId = parentId
    this.name = name
    this.keywords = keywords || []
    this.subcategories = subcategories || []
    this.total_count = total_count
  }

  toData() {
    return {
      id: this.id,
      parent: this.parentId,
      name: this.name
    }
  }

  /*
   * フラットな状態のカテゴリ一覧
   */
  static categoriesAsFlat(categories, nestedLevel=0) {
    let resultCategories = []
    for (const category of categories) {
      resultCategories.push([nestedLevel, category])
      let subcategories = KeywordCategory.categoriesAsFlat(
        category.subcategories, nestedLevel + 1)
      if (subcategories.length > 0) {
        resultCategories = resultCategories.concat(subcategories)
      }
    }
    return resultCategories
  }
}

KeywordCategory.fromData = function fromData(data) {
  let keywords = []
  if (data.keywords) {
    for (const keyword_data of data.keywords) {
      keywords.push(Keyword.fromData(keyword_data))
    }
  }
  let subcategories = []
  if (data.subcategories) {
    for (const subcategory_data of data.subcategories) {
      subcategories.push(KeywordCategory.fromData(subcategory_data))
    }
  }
  return new KeywordCategory(
    data.id || null,
    data.parent || null,
    data.name || '',
    keywords,
    subcategories,
    data.total_keyword_count || null
  )
}

class Keyword {
  constructor (id=null, categoryId=null, name='', keyword_connected_intent = null) {
    this.id = id
    this.categoryId = categoryId
    this.name = name
    this.keyword_connected_intent = keyword_connected_intent
  }

  toData() {
    return {
      id: this.id,
      category_id: this.categoryId,
      name: this.name
    }
  }
}

Keyword.fromData = function fromData(data) {
  return new Keyword(
    data.id || null,
    data.category_id || null,
    data.name || '',
    data.intentdetailkeyword__count || null
  )
}

class KeywordValue {
  constructor (id=null, keywordId=null, value='', synonym=[], language=null) {
    this.id = id
    this.keywordId = keywordId
    this.value = value
    this.synonym = synonym
    this.language = language
  }

  toData() {
    return {
      id: this.id,
      value: this.value,
      synonym: this.synonymAsText(),
      keyword_id: this.keywordId,
      language: this.language
    }
  }

  /*
   * 類義語のテキストをリストに変換
   */
  static synonymTextToList(text) {
    let words = text.split('\n')
    let result = []
    for (const word of words) {
      let stripped = word.replace(/^\s+|\s+$/g, '')
      // 空白除去して空文字列になるものは除外
      if (stripped.length > 0) {
        result.push(stripped)
      }
    }
    return result
  }

  /*
   * 類義語のリストをテキストに変換
   */
  static synonymListToText(lst) {
    return lst.join('\n')
  }

  synonymAsText() {
    return KeywordValue.synonymListToText(this.synonym)
  }

  setSynonymFromText(text) {
    this.synonym = KeywordValue.synonymTextToList(text)
  }
}

KeywordValue.fromData = function fromData(data) {
  return new KeywordValue(
    data.id || null,
    data.keyword_id || null,
    data.value || '',
    KeywordValue.synonymTextToList(data.synonym) || [],
    data.language || null
  )
}

export {
  KeywordCategory,
  Keyword,
  KeywordValue
}
