<template>
  <div>
    <div class="row ml-1 mr-1">
      <div class="col border" style="min-height: 300px">
        <h4>{{$t('dfAgentSwitch.cmsDfAgent')}}</h4>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{$t('dfAgentSwitch.agentName')}}</th>
              <th scope="col">{{$t('dfAgentSwitch.action')}}</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{controller.cms_use_agent}}</td>
            <td>
              <button class="btn btn-primary" @click="showCmsAgentSwitchModal">
                {{$t('dfAgentSwitch.change')}}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col border" style="min-height: 300px">
        <h4>{{$t('dfAgentSwitch.botDfAgent')}}</h4>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{$t('dfAgentSwitch.agentName')}}</th>
              <th scope="col">{{$t('dfAgentSwitch.action')}}</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{controller.bot_use_agent}}</td>
            <td>
              <button class="btn btn-primary" @click="showBotAgentSwitchModal">
                {{$t('dfAgentSwitch.change')}}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-modal
      :title="$t('dfAgentSwitch.switchCmsAgent')"
      ref="cmsAgentSwitchModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop>
      <div>
        <div>
          {{$t('dfAgentSwitch.selectAgent')}}：
        </div>
        <form @submit.prevent="">
          <b-form-select
            v-model="controller.cms_use_agent_obj"
            :options="controller.externalServiceList">
          </b-form-select>
        </form>
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="cmsAgentSwitchOk"
        @cancel="cmsAgentSwitchCancel">
      </modal-footer>
    </b-modal>
    <b-modal
      :title="$t('dfAgentSwitch.switchBotAgent')"
      ref="botAgentSwitchModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop>
      <div>
        <div>
          {{$t('dfAgentSwitch.selectAgent')}}：
        </div>
        <form @submit.prevent="">
          <b-form-select
            v-model="controller.bot_use_agent_obj"
            :options="controller.externalServiceList">
          </b-form-select>
        </form>
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="botAgentSwitchOk"
        @cancel="botAgentSwitchCancel">
      </modal-footer>
    </b-modal>
    <error-modal ref="errorModal" :message="message"/>
    <progress-modal ref="progressModal" :message="message"/>
  </div>
</template>

<script>
  import { cloneDeep } from 'lodash'
  import csrfToken from '../util/csrf-token'
  export default {
    props:['controller'],
    data() {
      return {
        message: ""
      }
    },
    methods:{
      showCmsAgentSwitchModal(){
        this.$refs.cmsAgentSwitchModal.show()
      },
      cmsAgentSwitchOk(){
        this.$refs.cmsAgentSwitchModal.hide()
        let result, message, promise
        [result, message, promise] = this.controller.saveCmsAgentSwitch(
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result) {
          this.controller.cms_use_agent_obj = cloneDeep(this.controller.cms_use_agent_obj_copy)
          this.showError('Error Occurred!')
        } else {
          this.showProgress(message)
          let self = this
          promise.then(()=>{
            this.hideProgress()
          })
            .catch(function(error){
              self.controller.cms_use_agent_obj = cloneDeep(self.controller.cms_use_agent_obj_copy)
              self.showError(error.response.data[0])
              self.hideProgress()
            })
        }

      },
      cmsAgentSwitchCancel(){
        this.controller.cms_use_agent_obj = cloneDeep(this.controller.cms_use_agent_obj_copy)
        this.$refs.cmsAgentSwitchModal.hide()
      },
      showBotAgentSwitchModal(){
        this.$refs.botAgentSwitchModal.show()
      },
      botAgentSwitchOk(){
        this.$refs.botAgentSwitchModal.hide()
        let result, message, promise
        [result, message, promise] = this.controller.saveBotAgentSwitch(
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result) {
          this.controller.bot_use_agent_obj = cloneDeep(this.controller.bot_use_agent_obj_copy)
          this.showError('Error Occurred!')
        } else {
          this.showProgress(message)
          let self = this
          promise.then(()=>{
            this.hideProgress()
          })
            .catch(function(error){
              self.controller.bot_use_agent_obj = cloneDeep(self.controller.bot_use_agent_obj_copy)
              self.showError(error.response.data[0])
              self.hideProgress()
            })
        }
      },
      botAgentSwitchCancel(){
        this.controller.bot_use_agent_obj = cloneDeep(this.controller.bot_use_agent_obj_copy)
        this.$refs.botAgentSwitchModal.hide()
      },
      showError(message) {
        this.message = message
        this.$refs.errorModal.show()
      },
      showProgress(message) {
        this.message = message
        this.$refs.progressModal.show()
      },
      hideProgress() {
        this.$refs.progressModal.hide()
      }
    }
  }
</script>
