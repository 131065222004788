<template>
  <div class="sctx-mdl-detail container-fluid">
    <h5 v-if="title">{{ title }}</h5>
    <div class="d-none d-md-flex row sctx-mdl-detail__header">
      <div class="col-12 col-md-3 sctx-mdl-detail__header-item">
        <span>{{ $t('speechContext.phrase') }}</span>
      </div>
      <div class="col-12 col-md-3 sctx-mdl-detail__header-item">
        <span>{{ $t('speechContext.output') }}</span>
      </div>
      <div class="col-12 col-md-3 sctx-mdl-detail__header-item">
        <span>{{ $t('speechContext.boost') }}</span>
      </div>
      <div class="col-12 col-md-3 sctx-mdl-detail__header-item">
        <span>{{ $t('validation.language') }}</span>
      </div>
    </div>
    <div class="sctx-mdl-detail__content">
      <template v-for="(item, index) in data">
        <div class="row sctx-mdl-detail__content-row" :key="index">
          <div class="col-12 col-md-3 sctx-mdl-detail__content-item">
          <span class="d-inline-block d-md-none">
            {{ $t('speechContext.phrase') }}:
          </span>
          {{ item.phrase }}
          </div>
          <div class="col-12 col-md-3 sctx-mdl-detail__content-item">
            <span class="d-inline-block d-md-none">
              {{ $t('speechContext.output') }}:
            </span>
            {{ item.output }}
          </div>
          <div class="col-12 col-md-3 sctx-mdl-detail__content-item">
            <span class="d-inline-block d-md-none">
              {{ $t('speechContext.boost') }}:
            </span>
            {{ item.boost }}
          </div>
          <div class="col-12 col-md-3 sctx-mdl-detail__content-item">
            <span class="d-inline-block d-md-none">
              {{ $t('validation.language') }}:
            </span>
            {{ item.language_code || '(default)' }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SpeechContextModalDetail",
    props: ['data', 'title']
  }
</script>

<style scoped>

</style>
