import axios from 'axios'
import { SpeechContext } from "../model/speech-context"

class SpeechContextController {
  constructor(apiUrl, userType, supportedLanguages, language, i18n) {
    this.apiUrl = apiUrl
    this.userType = userType
    this.i18n = i18n
    this._currentPage = 1
    this.count = null
    this.pageCount = null
    this.speechContexts = []
    this.supportedLanguages = supportedLanguages
    this.language = language
    this.showErrorModal = null
    this.showProgressModal = null
    this.hideProgressModal = null
    this.showImportConfirmModal = null
    this.showSuccessModal = null
    this.previewData = null
  }

  ready(functions) {
    this.showErrorModal = functions.showErrorModal
    this.showProgressModal = functions.showProgressModal
    this.hideProgressModal = functions.hideProgressModal
    this.showImportConfirmModal = functions.showImportConfirmModal
    this.showSuccessModal = functions.showSuccessModal
    this.list()
  }

  list() {
    if (this.pageCount && this.currentPage > this.pageCount) {
      return
    }
    this.showProgress(this.i18n.t("scenario.loading"))
    axios.get(
      `${this.apiUrl.speechContextFetch}?page=${this.currentPage}&language_code=${this.language}`
    ).then(response => {
      const responseData = response.data
      this.pageCount = responseData.pageCount
      this.count = responseData.count
      this.speechContexts = responseData.data.map(SpeechContext.fromData)
    }).catch(err => {
      const response = err.response
      const responseData = response.data
      this.showError(responseData.message)
    }).finally(() => {
      this.hideProgress()
    })
  }

  saveSpeechContext(ctx, csrfToken = null) {
    this.showProgress(this.i18n.t("general.saving"))
    if (!ctx.languageCode) {
      ctx.languageCode = this.language
    }
    axios.post(
      this.apiUrl.speechContextUpdate,
      ctx.toData(),
      {
        headers: {
          "X-CSRFToken": csrfToken
        }
      }
    ).then(() => {
      this.list()
    }).catch(error => {
      const response_data = error.response.data
      this.hideProgress()
      this.showError(response_data.message)
    })
  }

  deleteSpeechContext(sids, csrfToken = null) {
    this.showProgress(this.i18n.t("general.saving"))
    axios.post(this.apiUrl.speechContextDelete, {
      sids: sids
    }, {
      headers: {
        "X-CSRFToken": csrfToken
      }
    }).then(() => {
      this.list()
    }).catch((error) => {
      const response_data = error.response.data
      this.hideProgress()
      this.showError(response_data.message)
    })
  }

  loadImportFilePreview(file, csrfToken = null) {
    if (!file) {
      this.previewData = null
      return
    }
    this.showProgress(this.i18n.t("scenario.loading"))
    const postForm = new FormData()
    postForm.append("file", file)
    axios.post(this.apiUrl.speechContextImportPreview, postForm, {
      headers: {
        "X-CSRFToken": csrfToken
      }
    }).then(response => {
      const responseData = response.data
      this.previewData = responseData.data_list
    }).catch(error => {
      const responseData = error.response.data
      this.showError(responseData.message)
    }).finally(() => {
      this.hideProgress()
    })
  }

  importSpeechContextFromFile(file, saveMode, csrfToken = null) {
    this.showProgress(this.i18n.t("general.saving"))
    const postForm = new FormData()
    postForm.append("save_mode", saveMode)
    postForm.append("language_code", this.language)
    postForm.append("file", file)
    axios.post(this.apiUrl.speechContextImportFromFile, postForm, {
      headers: {
        "X-CSRFToken": csrfToken
      }
    }).then(response => {
      const responseData = response.data
      this.showSuccess(`${this.i18n.t("speechContext.importSuccessfully")} ${responseData.count}`)
      this.list()
    }).catch(error => {
      const responseData = error.response.data
      this.hideProgress()
      const allowRetry = responseData.allow_retry
      if (allowRetry) {
        this.showImportConfirm(responseData.message, responseData.data_list)
      } else {
        this.showError(responseData.message, responseData.data_list)
      }
    })
  }

  selectLanguage(code) {
    this.language = code
    this.currentPage = 1
  }

  showError(msg, dataList = []) {
    if (this.showErrorModal && typeof this.showErrorModal === 'function') {
      this.showErrorModal(msg, dataList)
    }
  }

  showProgress(msg) {
    if (this.showProgressModal && typeof this.showProgressModal === 'function') {
      this.showProgressModal(msg)
    }
  }

  hideProgress() {
    if (this.hideProgressModal && typeof this.hideProgressModal === 'function') {
      this.hideProgressModal()
    }
  }

  showImportConfirm(msg, dataList = []) {
    if (this.showImportConfirmModal && typeof this.showImportConfirmModal === 'function') {
      this.showImportConfirmModal(msg, dataList)
    }
  }

  showSuccess(msg) {
    if (this.showSuccessModal && typeof this.showSuccessModal === 'function') {
      this.showSuccessModal(msg)
    }
  }

  get currentPage() {
    return this._currentPage
  }

  set currentPage(page) {
    this._currentPage = page
    this.list()
  }
}

export {
  SpeechContextController
}
