/*
 * 入力補完（質問転送）画面
 */
import Vue from 'vue'
import { API_URL } from './resource/urls'
import { UrlBuilder } from "./util/url-builder"
import { InputCompletionController } from './controller/input-completion-controller'

import InputCompletionEditor from './components/InputCompletionEditor.vue'


export const appInputCompletion = (i18n) => {
  /* コントローラ */
  const controller = new InputCompletionController(
    new UrlBuilder(API_URL).build(),
    window.InputCompletionContext
  )

  Vue.component('input-completion-editor', InputCompletionEditor)

  new Vue({
    el: '#app-input-completion',
    i18n: i18n,
    data: {
      controller: controller
    },
  })
}
