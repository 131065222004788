import {I18n} from "../util/i18n"
import { RestApi } from '../util/rest-api'
import { UserMetaColumn } from '../model/user-meta'
import csrfToken from '../util/csrf-token'


class UserMetaEditSchemaController {
  constructor(apiUrl, i18nContext, requestContext) {
    this.apiUrl = apiUrl
    this.i18n = new I18n(i18nContext)
    this.language = requestContext.languageCode
    this.userMetaColumnApi = new RestApi(apiUrl.UserMetaColumn, UserMetaColumn)
    this.listUserMetaColumns = []
    this.userMetaColumnLoader = true
  }

  ready() {
    this.loadUserMetaColumn()
  }

  loadUserMetaColumn() {
    this.userMetaColumnLoader = true
    this.userMetaColumnApi.list().then(instance => {
      this.userMetaColumnLoader = false
      this.listUserMetaColumns = instance
    })
  }

  createUserMetaColumn(form) {
    const promise = this.userMetaColumnApi.save(
      form,
      csrfToken.getCsrfTokenFromCookie(document.cookie)
    ).then(response => {
      if(response) {
        this.loadUserMetaColumn()
      }
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  updateUserMetaColumn(form) {
    const promise = this.userMetaColumnApi.update(
      form,
      csrfToken.getCsrfTokenFromCookie(document.cookie)
    ).then(response => {
      if(response) {
        this.loadUserMetaColumn()
      }
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  deleteUserMetaColumn(form) {
    const promise = this.userMetaColumnApi.destroy(
      form,
      csrfToken.getCsrfTokenFromCookie(document.cookie)
    ).then(() => {
      this.loadUserMetaColumn()
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * Detect locale language
   * @returns {*}
   */
  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }
}

export {
  UserMetaEditSchemaController
}
