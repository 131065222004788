<template>
<li>
  <div
    class="label my-1 p-1"
    :class="{selected: selected()}"
    :style="isVisible"
    @click="$emit('select-keyword', keyword)"
    @dblclick="userType!='viewer' ? $emit('edit-keyword', keyword) : null"
    @keyup.delete="userType!='viewer' ? $emit('delete-keyword', keyword) : null"
    tabindex="-1">
    {{ keyword.name }}
    <b-badge v-if="keyword.keyword_connected_intent < 1" variant="info">
      {{ $t('keyword.keywordNotInUse') }}
    </b-badge>
    <b-button v-if="keyword.keyword_connected_intent >= 1" class="float-right"
              size="xs" variant="info" @click="$emit('show-intent-list-modal', keyword)"
              :title="$t('keyword.intentListInUse')">
        <i class="fas fa-list"></i>
    </b-button>
    <span v-b-tooltip.hover.html.right="'Keyword name exceeds <strong>30</strong> characters'" v-show="keywordLength[index].length>=30">
      <i class="fas fa-exclamation-triangle" style="color:#ff5c09"></i>
    </span>
  </div>
</li>
</template>

<script>
export default {
  props: ['keywordLength','keyword', 'selectedKeyword', 'index', 'searchWord', 'userType'],
  computed: {
    isVisible() {
      if (this.searchWord === '') {
        return ''
      }

      let lowercaseKeyword = this.keyword.name.toLowerCase()
      return lowercaseKeyword.includes(this.searchWord) ? '' : 'display: none'
    },
  },
  methods: {
    selected() {
      return this.selectedKeyword ? this.keyword.id === this.selectedKeyword.id : false
    }
  }
}
</script>
