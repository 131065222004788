<template>
  <div class="mt-1 page-category col-md-4">
    <div
      class="column-caption my-1 p-1 font-weight-bold pointer-cursor"
      v-b-toggle.intent_collapse
      @click="folded = !folded"
    >
      <span class="float-none pl-1" v-show="folded"
        ><i class="fas fa-caret-down"></i
      ></span>
      <span class="float-none pl-1" v-show="!folded"
        ><i class="fas fa-caret-up"></i
      ></span>

      <span class="pl-1">{{ $t("intent.titleShort") }}</span>
    </div>

    <b-collapse visible id="intent_collapse">
      <!-- 追加ボタンなど -->
      <div class="row mb-1" v-if="userType != 'viewer'">
        <div class="col-auto mr-auto">
          <button
            v-show="!isMultiSelectMode"
            class="btn btn-success button-text mr-1"
            @click="startMultiSelectMode"
            :disabled="isMultiSelectMode"
          >
            <i class="fas fa-list-ul"></i>
            <span>{{ $t("faq.bulkOperations") }}</span>
          </button>

          <button
            v-show="isMultiSelectMode"
            class="btn btn-secondary button-text mr-1"
            @click="cancelMultiSelectMode"
          >
            <i class="fas fa-list-ul"></i>
            <span>{{ $t("buttons.cancel") }}</span>
          </button>

          <button
            v-show="isMultiSelectMode && !isSelectedAll()"
            id="button-all-select-intent"
            class="btn btn-success button-text mr-1"
            @click="selectAllIntent()"
          >
            <i class="fas fa-arrows-alt-h"></i>
            <span>{{ $t("buttons.selectAll") }}</span>
          </button>

          <button
            v-show="isMultiSelectMode && isSelectedAll()"
            id="button-all-select-intent"
            class="btn btn-danger button-text mr-1"
            @click="clearAllIntent()"
          >
            <i class="fas fa-arrows-alt-h"></i>
            <span>{{ $t("buttons.clearAll") }}</span>
          </button>

          <button
            class="btn btn-success button-text mr-1"
            @click="
              isMultiSelectMode
                ? openMultiEditIntentModal()
                : openEditIntentModal()
            "
            :disabled="
              isMultiSelectMode
                ? multiSelectedIntentIds.length === 0
                : !selectedIntentId
            "
          >
            <i class="fa fa-edit"></i>
            <span>{{ $t("buttons.edit") }}</span>
          </button>
        </div>
      </div>

      <!-- 検索インプット -->
      <b-form-input
        type="text"
        v-model="searchIntentWord"
        class="mb-1"
        :placeholder="$t('intent.search')"
        :formatter="format"
      />

      <!-- 一括操作中の警告 -->
      <div
        v-if="isMultiSelectMode && isSelectedNotDisplayIntent()"
        class="alert alert-dismissable alert-warning"
      >
        <i class="fas fa-exclamation-circle"></i>
        {{ $t("faq.selectedNotDisplayIntent") }}
      </div>

      <!-- インテント一覧 -->
      <ul
        class="intent scroll-intent pl-0"
        tabindex="-1"
        ref="focusOn"
        id="scrollY_Intent"
      >
        <li
          v-for="intent in intents"
          class="intent-item"
          :key="intent.id"
          :style="{ display: filterIntent(intent) }"
          :title="
            intent.active ? $t('faq.validIntent') : $t('faq.invalidIntent')
          "
        >
          <div
            class="label my-1 p-1"
            :class="{ selected: isItemSelected(intent) }"
            @click="
              isMultiSelectMode
                ? multiSelectIntent(intent.id)
                : selectIntent(intent.id)
            "
          >
            <span
              v-if="
                categories.find((c) => c.id === intent.category)?.active &&
                intent.active &&
                intent.qa_active?.[selectedLanguage]
              "
            >
              <icon-check></icon-check>
            </span>
            <span
              :class="[
                !(
                  categories.find((c) => c.id === intent.category)?.active &&
                  intent.active &&
                  intent.qa_active?.[selectedLanguage]
                ) && 'text-black-50',
                'badge',
              ]"
            >
              {{ intent.name.split("|")[1] }}
            </span>
          </div>
        </li>
      </ul>
    </b-collapse>

    <!--Modal end-->
    <error-modal ref="errorModal" :message="message" />

    <progress-modal ref="progressModal" :message="message" />

    <!-- インテント編集モーダル -->
    <b-modal
      :title="$t('faq.editIntent')"
      ref="editIntentModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <div>{{ $t("faq.category") }}:</div>
        <basic-select
          v-model="modalData.category"
          :options="
            [
              {
                text: '---',
                value: null,
              },
            ].concat(
              categories.map((c) => ({
                text: getFormattedCategoryFullName(c),
                value: c.id,
              }))
            )
          "
          :selected-option="modalData.category"
          :state="!!modalData.category.value"
          :placeholder="$t('faq.selectCategory')"
          @select="onSelectModalCategory"
        />

        <div>{{ $t("faq.state") }}:</div>
        <b-form-radio-group v-model="modalData.active">
          <b-form-radio value="true">{{ $t("faq.valid") }}</b-form-radio>
          <b-form-radio value="false">{{ $t("faq.invalid") }}</b-form-radio>
        </b-form-radio-group>

        <div>{{ $t("faq.frequentlyAskedSettings") }}:</div>
        <b-form-radio-group v-model="modalData.frequently_asked">
          <b-form-radio value="true">{{ $t("faq.setUp") }}</b-form-radio>
          <b-form-radio value="false">{{ $t("faq.noSet") }}</b-form-radio>
        </b-form-radio-group>
      </div>

      <modal-footer
        slot="modal-footer"
        :okDisabled="!modalData.category.value"
        @ok="saveIntent"
        @cancel="$refs.editIntentModal.hide()"
      />
    </b-modal>

    <!-- インテント一括編集モーダル -->
    <b-modal
      :title="$t('faq.multiEditIntent')"
      ref="multiEditIntentModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <div>{{ $t("faq.operation") }}:</div>
        <basic-select
          v-model="multiModalData.operation"
          :options="
            [
              {
                text: $t('faq.selectOperations'),
                value: null,
              },
            ].concat(
              multiModalOperations.map(({ text, value }) => ({
                text,
                value,
              }))
            )
          "
          :selected-option="multiModalData.operation"
          :state="!!multiModalData.operation.value"
          :placeholder="$t('faq.selectOperations')"
          @select="onSelectOperation"
        />

        <div v-if="multiModalData.operation.value === 'CHANGE_CATEGORY'">
          <div>{{ $t("faq.category") }}:</div>
          <basic-select
            v-model="multiModalData.category"
            :options="
              [
                {
                  text: '---',
                  value: null,
                },
              ].concat(
                categories.map((c) => ({
                  text: getFormattedCategoryFullName(c),
                  value: c.id,
                }))
              )
            "
            :selected-option="multiModalData.category"
            :state="!!multiModalData.category.value"
            :placeholder="$t('faq.selectCategory')"
            @select="onSelectMultiModalCategory"
          />
        </div>

        <div v-if="multiModalData.operation.value === 'CHANGE_STATE'">
          <div>{{ $t("faq.state") }}:</div>
          <b-form-radio-group v-model="multiModalData.active">
            <b-form-radio value="true">{{ $t("faq.valid") }}</b-form-radio>
            <b-form-radio value="false">{{ $t("faq.invalid") }}</b-form-radio>
          </b-form-radio-group>
        </div>

        <div
          v-if="multiModalData.operation.value === 'CHANGE_FREQUENTLY_ASKED'"
        >
          <div>{{ $t("faq.frequentlyAskedSettings") }}:</div>
          <b-form-radio-group v-model="multiModalData.frequently_asked">
            <b-form-radio value="true">{{ $t("faq.setUp") }}</b-form-radio>
            <b-form-radio value="false">{{ $t("faq.noSet") }}</b-form-radio>
          </b-form-radio-group>
        </div>
      </div>

      <modal-footer
        slot="modal-footer"
        :okDisabled="
          multiModalData.operation.value === null ||
          (multiModalData.operation.value === 'CHANGE_CATEGORY' &&
            !multiModalData.category.value)
        "
        @ok="saveMultiIntent"
        @cancel="$refs.multiEditIntentModal.hide()"
      >
      </modal-footer>
    </b-modal>
  </div>
</template>

<script>
import { I18n } from "../util/i18n";

export default {
  props: [
    "intents",
    "selectedIntentId",
    "multiSelectedIntentIds",
    "categories",
    "selectedLanguage",
  ],
  components: {},
  data() {
    const i18n = new I18n(window.i18nContext);
    return {
      message: "",
      folded: false,
      isMultiSelectMode: false,
      searchIntentWord: "",
      modalData: {
        category: {
          text: "---",
          value: null,
        },
        active: false,
        frequently_asked: false,
      },
      multiModalData: {
        operation: {
          text: this.$t("faq.selectOperations"),
          value: null,
        },
        category: {
          text: "---",
          value: null,
        },
        active: false,
        frequently_asked: false,
      },
      multiModalOperations: [
        {
          text: this.$t("faq.changeCategory"),
          value: "CHANGE_CATEGORY",
        },
        {
          text: this.$t("faq.changeState"),
          value: "CHANGE_STATE",
        },
        {
          text: this.$t("faq.changeFrequentlyAsked"),
          value: "CHANGE_FREQUENTLY_ASKED",
        },
      ],
    };
  },
  methods: {
    showError(message) {
      this.message = message;
      this.$refs.errorModal.show();
    },
    showProgress(message) {
      this.message = message;
      this.$refs.progressModal.show();
    },
    hideProgress() {
      this.$refs.progressModal.hide();
    },
    startMultiSelectMode() {
      this.isMultiSelectMode = true;
    },
    cancelMultiSelectMode() {
      this.$emit("on-cancel-multi-select");
      this.isMultiSelectMode = false;
    },
    openEditIntentModal() {
      const selectedIntent = this.intents.find(
        (intent) => intent.id === this.selectedIntentId
      );
      const category = this.categories.find(
        (c) => c.id === selectedIntent.category
      );
      this.modalData = {
        category: {
          text: this.getFormattedCategoryFullName(category),
          value: category.id,
        },
        active: selectedIntent.active,
        frequently_asked: selectedIntent.frequently_asked,
      };
      this.$refs.editIntentModal.show();
    },
    openMultiEditIntentModal() {
      this.$refs.multiEditIntentModal.show();
    },
    onSelectModalCategory(category) {
      this.modalData.category = category;
    },
    onSelectOperation(operation) {
      this.multiModalData.operation = operation;
    },
    onSelectMultiModalCategory(category) {
      this.multiModalData.category = category;
    },
    async saveIntent() {
      this.$emit("update-intent", {
        intent: this.modalData,
        closeModal: () => {
          this.modalData = {
            category: {
              text: "---",
              value: null,
            },
            active: false,
            frequently_asked: false,
          };
          this.$refs.editIntentModal.hide();
        },
      });
    },
    async saveMultiIntent() {
      this.$emit("update-multi-intent", {
        operation: this.multiModalData.operation.value,
        categoryId: this.multiModalData.category.value,
        active: this.multiModalData.active,
        frequently_asked: this.multiModalData.frequently_asked,
      });
      this.$refs.multiEditIntentModal.hide();
      this.cancelMultiSelectMode();
    },
    /**
     * 検索処理
     */
    filterIntent(intent) {
      let displayStatus = "";
      if (this.searchIntentWord) {
        // エージェント名部分を除くことに注意
        const lowercaseIntentName = intent.name.toLowerCase().split("|")[1];
        displayStatus = lowercaseIntentName.includes(this.searchIntentWord)
          ? ""
          : "none";
      }
      return displayStatus;
    },
    format(value, event) {
      return value.toLowerCase();
    },
    filteredIntentIds() {
      const intentIds = [];
      this.intents.forEach((intent) => {
        if (this.filterIntent(intent) === "") {
          intentIds.push(intent.id);
        }
      });
      return intentIds;
    },
    /**
     * インテント選択時処理
     */
    selectIntent(intentId) {
      this.$emit("on-select-intent", intentId);
    },
    /**
     * インテント複数選択時処理
     */
    multiSelectIntent(intentId) {
      if (intentId === "null") {
        return;
      }
      this.$emit("on-multi-select-intent", intentId);
    },
    selectAllIntent() {
      this.filteredIntentIds().forEach((id) => {
        if (!this.multiSelectedIntentIds.includes(id)) {
          this.multiSelectIntent(id);
        }
      });
    },
    clearAllIntent() {
      this.multiSelectedIntentIds.forEach((id) => {
        this.multiSelectIntent(id);
      });
    },
    isItemSelected(intent) {
      if (this.isMultiSelectMode) {
        return this.multiSelectedIntentIds.includes(intent.id);
      } else {
        return this.selectedIntentId === intent.id;
      }
    },
    isSelectedAll() {
      const filteredIntentIds = this.filteredIntentIds();
      return (
        filteredIntentIds.length > 0 &&
        filteredIntentIds.every((id) =>
          this.multiSelectedIntentIds.includes(id)
        )
      );
    },
    isSelectedNotDisplayIntent() {
      const filteredIntentIds = this.filteredIntentIds();
      const selectedNotDisplayIntentIds = this.multiSelectedIntentIds.filter(
        (id) => !filteredIntentIds.includes(id)
      );
      return (
        selectedNotDisplayIntentIds.length > 0 && !!selectedNotDisplayIntentIds
      );
    },
    getFormattedCategoryFullName(category) {
      let fullpath = "";
      const categoryFullNames = category.full_name.split(" > ");
      categoryFullNames.forEach((name, idx) => {
        const c = this.categories.find((c) => c.name === name);
        const displayName = c.display_names?.[this.selectedLanguage];
        if (displayName) {
          fullpath += `${displayName}`;
        } else {
          fullpath += `${name}`;
        }
        if (idx + 1 !== categoryFullNames.length) {
          fullpath += " > ";
        }
      });
      return fullpath;
    },
  },
};
</script>

<style scoped>
.selected-language {
  border: 3px solid #b9bbbe;
  color: #fff;
}
.table td {
  min-width: 120px;
}
</style>
