<template>
  <div>
    <!-- Modal -->
    <b-modal
      :title="modalTitle"
      ref="dataModal"
      header-class="bg-info text-light"
      size="lg"
      scrollable
      hide-footer
      body-class="text-info">
      <code-editor
        editor-id="jsonDataWebhook"
        autoHeight="true"
        readOnly="true"
        :content="modalData"
        class="mb-2" />
    </b-modal>

    <!-- Rule Bottun -->
    <div class="row mt-1 mb-1 col-md-12">
      <a
        class="btn btn-success"
        href="/webhook">
        <i class="fas fa-arrow-alt-circle-left"></i>
        {{ $t('webhook.transferRulesTitle') }}
      </a>
    </div>

    <!-- Title -->
    <div class="row">
      <div class="col-md-12">
        <div class="m-1 p-1">
          <h6 class="border-bottom mb-0">{{ $t('webhook.logManagement') }}</h6>
        </div>
      </div>
    </div>
    <!-- Search -->
    <form @submit.prevent="" @keypress.enter.prevent="" class="mb-1 ml-1">
      <div class="row">
        <div class="col-md-3 pt-1">
          <history-calendar :date="controller.date"></history-calendar>
        </div>
        <div class="col-md-3 pt-1">
          <input type="text"
                 class="form-control"
                 :placeholder="$t('history.sessionPlaceholder')"
                 v-model="controller.session_id">
        </div>
        <div class="col-md-3 pt-1">
          <form-tags
            :tags="controller.status_code"
            :rule="/^[0-9]+$/g"
            :duplicated-message="$t('webhook.duplicated')"
            :invalid-message="$t('webhook.statusInvalid')"
            :placeholder="$t('webhook.status_code_placeholder')"
          >
          </form-tags>
        </div>
        <div class="col-md-3 pt-1">
          <button
            @click="search"
            class="btn btn-success">
            {{ $t('history.search') }}
          </button>
        </div>
      </div>
    </form>
    <!-- Column -->
    <div class="row ml-1 mr-1">
      <div class="col-md-1 column border p-2">
        {{ $t('webhook.date') }}
      </div>
      <div class="col-md-2 column border p-2">
        {{ $t('webhook.action') }}
      </div>
      <div class="col-md-2 column border p-2">
        {{ $t('webhook.actionParameter') }}
      </div>
      <div class="col-md-1 column border p-2">
        {{ $t('webhook.status_code') }}
      </div>
      <div class="col-md-2 column border p-2">
        {{ $t('webhook.webhook_response') }}
      </div>
      <div class="col-md-3 column border p-2">
        {{ $t('webhook.session_id') }}
      </div>
      <div class="col-md-1 column border p-2 text-center">
        {{ $t('webhook.latency') }}
      </div>
    </div>
    <!-- Logs -->
    <div v-for="item in controller.logs">
      <div class="row ml-1 mr-1 border-bottom">
        <div class="col-md-1 p-2 border-left">
          {{ item.created_at }}
        </div>
        <div class="col-md-2 p-2 border-left">
          <div v-if="item.action">{{ $t('webhook.' + item.action) }}</div>
          <div v-else>{{ $t('webhook.notSet') }}</div>
        </div>
        <div class="col-md-2 p-2 border-left action-parameter">
          {{ item.param || $t('webhook.notSet') }}
        </div>
        <div class="col-md-1 p-2 border-left">
          {{ item.status_code }}
        </div>
        <div class="col-md-2 p-2 border-left">
          <button class="btn btn-success" @click="showData(item.data)">
            {{ $t('webhook.show_json') }}
          </button>
        </div>
        <div class="col-md-3 p-2 border-left">
          {{ item.session_id }}
        </div>
        <div class="col-md-1 p-2 border-left border-right text-center">
          {{ item.duration }}ms
        </div>
      </div>
    </div>
    <!-- Test Chat -->
    <test-chat
      v-if="controller.testChatController"
      :controller="controller.testChatController"
    ></test-chat>
    <!-- Pagination -->
    <div class="row mt-1">
      <div class="col">
        <pagination
          :params="controller.params"
          :num_page="controller.num_page"
          :callback="loadWebhookLogData"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import csrfToken from '../util/csrf-token'
  import {I18n} from '../util/i18n'
  import {DragMe} from '../util/drag-me'

  export default {
    name: "WebhookLog",
    props: ['controller'],
    data() {
      return {
        message: '',
        modalTitle: '',
        modalData: null,
        showChat:false,
        i18n: new I18n(window.i18nContext),
        isCard:false,
        isDraggingChatButton:false
      }
    },
    computed: {
      isViewer() {
        return this.controller.isViewer()
      }
    },
    methods: {
      search: function(){
        this.controller.params.page = 1
        this.controller.loadWebhookLog()
      },
      loadWebhookLogData: function (){
        this.controller.loadWebhookLog()
      },
      openChatBox(){
        if(!this.isDraggingChatButton){
          this.showChat = !this.showChat
        }
        this.isDraggingChatButton = false
      },
      draggingChatBox(){
        this.isDraggingChatButton = true
      },
      mousedownChatBox(){
        this.isDraggingChatButton = false
      },
      showProgress(message) {
        this.isCard = false
        this.message = message
        this.$refs.progressModal.show()
      },
      hideProgress() {
        this.$refs.progressModal.hide()
      },
      showError(message, isCard=false) {
        if(!isCard){this.message = message}
        this.isCard = isCard
        this.$refs.errorModal.show()
      },
      showData(data) {
        this.modalTitle = this.i18n.t('webhook.webhook_response')
        this.modalData = JSON.stringify(data, null, 2)
        this.$refs.dataModal.show()
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        DragMe(document.getElementById('test-chat-btn'), this, {'ondrag': 'draggingChatBox'})
      })
    }
  }
</script>
