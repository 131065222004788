/**
 * テキストを指定された文字列以内にフォーマットする。
 * 溢れた文は三点リーダーにする。
 */
const truncateText = ({ text, displayLength = 15 }) => {
  if (text.length > displayLength) {
    // 指定文字数より多い場合三点リーダーを付与
    return text.slice(0, displayLength) + '...'
  }
  // それ以外はそのまま返す。
  return text
}

export { truncateText }
