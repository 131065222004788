import { I18n } from "../util/i18n";
import axios from "axios";
import { RestApi } from "../util/rest-api";
import { ChatLog, ChatItem } from "../model/history";
import { UserMetaManagementController } from "./user-meta-management";
import { isArray } from "lodash";

class HistoryManagementController {
  constructor(apiUrl, i18nContext, requestContext) {
    this.apiUrl = apiUrl;

    this.supported_languages = requestContext.supported_languages;
    this.language_dict = Object.assign(
      {},
      ...this.supported_languages.map((x) => ({ [x.value]: x.code }))
    );
    this.supported_platforms = requestContext.all_platforms;
    this.platform_dict = Object.assign(
      {},
      ...this.supported_platforms.map((x) => ({ [x.value]: x.code }))
    );

    this.checkedHistoryAPI = new RestApi(apiUrl.CheckedHistories, ChatLog);
    this.saveCheckedHistoriesAPI = apiUrl.SaveCheckedHistories;

    this.i18n = new I18n(i18nContext);
    this.params = { page: 1 };
    this.logs = [];
    this.language = "all";
    this.platform = requestContext.platform;
    this.conversation_type = requestContext.conversation_type;
    this.sentiment_analysis_status = requestContext.sentiment_analysis_status;
    this.feedback = null;
    this.session_id = "";
    this.client_id = "";
    this.intentName = "";
    this.welcome_message = false;
    this.webhook_message = false;
    this.num_page = 0;
    this.hasLogs = false;
    this.isTranslating = false;
    this.selectedIntentOptions = [];
    /** {id: number, text: string}[] */
    this.queryTextTranslationResults = [];
    /** {id: number, text: string}[] */
    this.responseTranslationResults = [];
    this.tempLogs = []; // チェックリストをIDを保存する形にして、response自体を別途と保存する
    this.date = {
      from_date: requestContext.from_date,
      to_date: requestContext.to_date,
    };
    this.checkedHistories = [];
    this.userMetaManager = requestContext.allowUserMeta
      ? new UserMetaManagementController(apiUrl)
      : null;
    this.debug = requestContext.debug;
    this.ui_language = requestContext.ui_language;
    this.modalExportForm = [];
    this.clientIdFilterEnabled = !!requestContext.enable_client_id_filter;
  }
  ready() {
    this.loadHistoryLog();
    this.loadCheckedHistory();
    if (this.userMetaManager) {
      this.userMetaManager.loadAllUserMetaMap();
    }
  }
  loadHistoryLog() {
    this.logs = [];
    this.hasLogs = false;
    this.isTranslating = true;
    let params = {};
    params["page"] = this.params.page;
    params["language"] = this.language_dict[this.language];
    params["platform"] = this.platform_dict[this.platform];
    if (!this.welcome_message) {
      params["welcome_message"] = "off";
    }
    if (!this.webhook_message) {
      params["webhook_message"] = "off";
    }
    if (!!this.date.from_date) {
      params["start_date"] = this.date.from_date;
    }
    if (!!this.date.to_date) {
      params["end_date"] = this.date.to_date;
    }
    if (this.conversation_type === "input.unknown") {
      params["conversation_type"] = this.conversation_type;
    }
    if (!!this.feedback) {
      params["feedback"] = this.feedback;
    }
    if (!!this.session_id) {
      params["session_id"] = this.session_id;
    }
    if (!!this.client_id) {
      params["client_id"] = this.client_id;
    }
    if (this.intentName) {
      params["intent_name"] = this.intentName;
    }

    axios
      .get(this.apiUrl.HistoryLog, { params })
      .then((instance) => {
        this.num_page = instance.data.num_page;
        this.logs = instance.data.results.reduce((output, result) => {
          if (!isArray(result.all_messages)) {
            output.push(result);
            return output;
          }

          result.all_messages = result.all_messages.map((message) =>
            ChatItem.fromData(message)
          );
          output.push(result);
          return output;
        }, []);
        this.hasLogs = true;

        // translation handling
        if (!this.debug) {
          this.isTranslating = true;

          const targetLogs = this.logs.map((log) => {
            const { id, language_code, query_text, fulfillment_text } = log;
            return {
              id,
              language_code,
              query_text,
              fulfillment_text,
            };
          });

          axios.post(this.apiUrl.Translation, targetLogs).then(({ data }) => {
            this.queryTextTranslationResults = data.query_text;
            this.responseTranslationResults = data.fulfillment_text;
            this.isTranslating = false;
          });
        }
      })
      .catch((error) => console.log(error));
  }

  loadCheckedHistory() {
    this.checkedHistoryAPI
      .list()
      .then((instances) => {
        this.checkedHistories = [];
        this.selectedIntentOptions = [];
        instances.map((instance) => {
          this.checkedHistories.push(instance.id);
          this.selectedIntentOptions.push(instance.id);
          this.appendToTemp(instance.toData());
        });
      })
      .catch((error) => console.log(error));
  }
  downloadLog(name) {
    let url = "/history/export?";
    if (name === "CSV") {
      url += "btn_csv=on";
    } else if (name === "Excel") {
      url += "btn_excel=on";
    }

    if (!!this.welcome_message) {
      url += "&welcome_message=on";
    }
    if (!!this.webhook_message) {
      url += "&webhook_message=on";
    }
    if (!!this.date.from_date) {
      url += "&from_date=" + this.date.from_date;
    }
    if (!!this.date.to_date) {
      url += "&to_date=" + this.date.to_date;
    }
    if (!!this.language) {
      this.language_dict[this.language].forEach((lang) => {
        url += "&languages=" + lang;
      });
    }
    if (!!this.platform) {
      this.platform_dict[this.platform].forEach((plt) => {
        url += "&platform=" + plt;
      });
    }
    if (this.conversation_type === "input.unknown") {
      url += "&conversation_type=" + "1";
    }
    if (!!this.feedback) {
      url += "&feedback=" + this.feedback;
    }
    if (!!this.session_id) {
      url += "&session_id=" + this.session_id;
    }
    if (this.modalExportForm.intentDivision) {
      url += "&intent_division=" + this.modalExportForm.intentDivision;
    }
    if (this.modalExportForm.skipAnswerDetail) {
      url += "&skip_answer_detail=" + this.modalExportForm.skipAnswerDetail;
    }

    window.open(url, "_blank");
  }
  selectedIntentDownloadLog(name, ids) {
    let url = "/history/export?";
    if (name === "CSV") {
      url += "btn_csv=on";
    } else if (name === "Excel") {
      url += "btn_excel=on";
    }
    url += "&ids=" + ids;
    window.open(url, "_blank");
  }
  appendToTemp(log) {
    if (!this.tempLogs.some((tempLog) => tempLog.id === log.id)) {
      this.tempLogs.push(log);
    }
  }
  get selectedLogs() {
    return this.selectedIntentOptions.map((id) =>
      this.tempLogs.find((tempLog) => tempLog.id === id)
    );
  }
  saveAllCheckedHistories(csrfToken = null) {
    const options = { headers: { "X-CSRFToken": csrfToken } };
    const sendOptions = Object.assign({}, options);
    const difference = this.checkedDifference;
    axios
      .post(this.saveCheckedHistoriesAPI, { data: difference }, sendOptions)
      .then(() => {
        this.loadCheckedHistory();
      })
      .catch((err) => console.error(err));
  }
  get selectedListChanged() {
    return (
      this.selectedIntentOptions.length !== this.checkedHistories.length ||
      this.checkedHistories.some(
        (history) => this.selectedIntentOptions.indexOf(history) === -1
      )
    );
  }
  get checkedDifference() {
    const addList = this.getDifference(
      this.selectedIntentOptions,
      this.checkedHistories
    );
    const deleteList = this.getDifference(
      this.checkedHistories,
      this.selectedIntentOptions
    );
    return {
      add_list: addList,
      delete_list: deleteList,
    };
  }
  getDifference(compare = [], compared = []) {
    const results = [];
    compare.forEach((item) => {
      if (compared.indexOf(item) === -1) {
        const log = this.tempLogs.find((log) => log.id === item);
        if (log && results.indexOf(log.response_id) === -1) {
          results.push(log.response_id);
        }
      }
    });
    return results;
  }
}

export { HistoryManagementController };
