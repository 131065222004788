<template>
  <div>
    <b-dropdown
      variant="primary"
      :text="languageLabels[language]"
    >
      <b-dropdown-item
        v-for="(supportedLanguage, idx) in supportedLanguages"
        :key="idx"
        :href="supportedLanguage.keywordUrl"
        :disabled="supportedLanguage.code === language"
      >{{ languageLabels[supportedLanguage.code] }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { LANGUAGE_LABELS } from '../resource/language';

export default {
  props: ['supportedLanguages', 'language'],
  data() {
    return {
      languageLabels: LANGUAGE_LABELS,
    }
  },
}
</script>
  