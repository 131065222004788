<template>
  <b-modal
    id="session-filter-modal"
    :title="$t('history.DisplaySetting')"
    header-class="bg-info text-light"
    lazy
    no-close-on-backdrop
    scrollable
    size="lg"
    @ok="$emit('on-update-session-filter', { sortCondition, filterCondition })"
    @show="setSearchCondition"
  >
    <div>
      <b-form-group
        label-cols="12"
        :label="$t('history.OrderingCondition')"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-4"
      >
        <!-- PCの表示 -->
        <b-form-group class="sp-hidden">
          <div class="text-right pr-2">
            <b-link href="#" style="font-size: 1.0rem" @click="resetSortConditions">{{ $t('history.ApplyDefaultOrder')}}</b-link>
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('history.OrderingCondition')"
          label-for="sort-setting"
          label-cols="4"
          content-cols="7"
          label-align="left"
          class="field sp-hidden"
        >
          <b-form-select
            v-model="sortCondition"
            id="sort-setting"
            :options="sortOptions"
          />
        </b-form-group>

        <!-- SPの表示 -->
        <b-form-group class="field pc-hidden text-right">
          <b-link
            href="#"
            style="font-size: 0.85rem"
            @click="resetSortConditions"
          >{{ $t('history.ApplyDefaultOrder')}}</b-link>
          <b-form-select
            v-model="sortCondition"
            id="sort-setting"
            :options="sortOptions"
          />
        </b-form-group>
      </b-form-group>

      <b-form-group
        label-cols="12"
        :label="$t('history.FilteringCondition')"
        label-size="lg"
        label-class="font-weight-bold pt-0"
      >
        <!-- PCの表示 -->
        <b-form-group
          class="mb-3 field sp-hidden"
          :label="$t('history.OperatorSessionStatus')"
          label-for="operator-status-filter"
          label-cols="4"
          content-cols="7"
          label-align="left"
        >
          <div class="checkbox-area">
            <b-form-checkbox-group
              v-model="filterCondition.selectedOperatorHandlingStatusList"
              :options="operatorStatusOptions"
              value-field="value"
              text-field="text"
              stacked
            />
          </div>
        </b-form-group>

        <b-form-group
          class="mb-4 field sp-hidden"
          :label="$t('history.Platform')"
          label-for="platform-filter"
          label-cols="4"
          content-cols="7"
          label-align="left"
        >
          <div class="checkbox-area">
            <b-form-checkbox-group
              v-model="filterCondition.selectedPlatforms"
              :options="operatorHandlingSessionPlatforms"
              value-field="value"
              text-field="text"
              stacked
            />
          </div>
        </b-form-group>

        <b-form-group
          class="mb-4 field sp-hidden"
          :label="$t('history.Operator')"
          label-for="operator-filter"
          label-cols="4"
          content-cols="7"
          label-align="left"
        >
          <div class="checkbox-area scrollable">
            <b-form-checkbox-group
              v-model="filterCondition.selectedOperators"
              :options="operatorsOptions"
              value-field="id"
              text-field="name"
              stacked
            />
          </div>
        </b-form-group>

        <b-form-group
          class="mb-2 field sp-hidden"
          :label="$t('history.Bookmark')"
          label-for="bookmark-filter"
          label-cols="4"
          content-cols="7"
          label-align="left"
        >
          <div class="checkbox-area">
            <b-form-checkbox-group
              v-model="filterCondition.bookmarkConditions"
              :options="bookmarkConditions"
              value-field="value"
              text-field="text"
              stacked
            />
          </div>
        </b-form-group>

        <b-form-group
          class="mb-2 field sp-hidden"
          :label="$t('history.OperatorSessionID')"
          label-for="operator-handling-session-filter"
          label-cols="4"
          content-cols="7"
          label-align="left"
        >
          <div>
            <b-form-input v-model="filterCondition.operatorHandlingSessionId" />
          </div>
        </b-form-group>

        <!-- SPの表示 -->
        <b-form-group
          class="mb-2 field pc-hidden"
          :label="$t('history.OperatorSessionStatus')"
          label-for="operator-status-filter"
          label-align="left"
        >
          <div class="checkbox-area">
            <b-form-checkbox-group
              v-model="filterCondition.selectedOperatorHandlingStatusList"
              :options="operatorStatusOptions"
              value-field="value"
              text-field="text"
              stacked
            />
          </div>
        </b-form-group>

        <b-form-group
          class="mb-2 field pc-hidden"
          :label="$t('history.Platform')"
          label-for="platform-filter"
          label-align="left"
        >
          <div class="checkbox-area">
            <b-form-checkbox-group
              v-model="filterCondition.selectedPlatforms"
              :options="operatorHandlingSessionPlatforms"
              value-field="value"
              text-field="text"
              stacked
            />
          </div>
        </b-form-group>

        <b-form-group
          class="mb-2 field pc-hidden"
          :label="$t('history.Operator')"
          label-for="operator-filter"
          label-align="left"
        >
          <div class="checkbox-area scrollable">
            <b-form-checkbox-group
              v-model="filterCondition.selectedOperators"
              :options="operatorsOptions"
              value-field="id"
              text-field="name"
              stacked
            />
          </div>
        </b-form-group>

        <b-form-group
          class="mb-2 field pc-hidden"
          :label="$t('history.Bookmark')"
          label-for="bookmark-filter"
          label-align="left"
        >
          <div class="checkbox-area">
            <b-form-checkbox-group
              v-model="filterCondition.bookmarkConditions"
              :options="bookmarkConditions"
              value-field="value"
              text-field="text"
              stacked
            />
          </div>
        </b-form-group>

        <b-form-group
          class="mb-2 field pc-hidden"
          :label="$t('history.OperatorSessionID')"
          label-for="operator-handling-session-filter"
          label-align="left"
        >
          <div>
            <b-form-input v-model="filterCondition.operatorHandlingSessionId" />
          </div>
        </b-form-group>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { operatorStatus } from '../../util/operatorStatus'
import { operatorHandlingSessionPlatform } from '../../resource/operator-handling-session-platform'
export default {
  name: 'SessionFilterModal',
  props: ['sessions', 'operators', 'controller'],
  data() {
    return {
      operatorHandlingSessionPlatforms: Object.values(
        operatorHandlingSessionPlatform
      ),
      // ソート条件のアイテム
      sortCondition: 'messageNeedHandle',
      // フィルター条件の配列
      filterCondition: {
        // オペレーター対応ステータス。複数選択可
        selectedOperatorHandlingStatusList: [
          operatorStatus.ACTIVE,
          operatorStatus.CALLING,
        ],
        // プラットフォーム。複数選択可
        selectedPlatforms: Object.values(operatorHandlingSessionPlatform).map(
          (i) => i.value
        ),
        // 担当者。複数選択可
        selectedOperators: [null, ...this.operators.map((o) => o.id)],
        // ブックマーク状態。ブックマーク済み、未済、両方の3つが選択可能
        bookmarkConditions: [true, false],
        operatorHandlingSessionId: new URLSearchParams(
          window.location.search
        ).get('ohsid'),
      },
      sortOptions: [
        { value: 'messageNeedHandle', text: this.$t('history.OrderByPriority')},
        { value: 'messageTimeDesc', text: this.$t('history.OrderByNewMessage') },
        { value: 'messageTimeAsc', text: this.$t('history.OrderByOldMessage') },
      ],
      operatorStatusOptions: [
        {
          value: operatorStatus.ACTIVE,
          text: this.$t('history.OperatoSessionActive'),
        },
        {
          value: operatorStatus.CALLING,
          text: this.$t('history.Calling'),
        },
        {
          value: operatorStatus.INACTIVE,
          text: this.$t('history.Finished'),
        },
      ],
      /** 担当者IDと担当者無し用の選択肢 */
      operatorsOptions: [{ id: null, name: this.$t('history.Unset') }, ...this.operators],
      bookmarkConditions: [
        { value: true, text: this.$t('history.Bookmarked') },
        { value: false, text: this.$t('history.NotBookmarked') },
      ],
    }
  },
  methods: {
    setSearchCondition() {
      // ソート条件のアイテム
      if(this.controller.sortConditionSaved) {
         this.sortCondition = this.controller.sortConditionSaved
      }
      else {
        this.sortCondition = 'messageNeedHandle'
      }
      if(this.controller.selectedOperatorHandlingStatusList) {
        this.filterCondition.selectedOperatorHandlingStatusList = this.controller.selectedOperatorHandlingStatusList
      }
      else {
        this.filterCondition.selectedOperatorHandlingStatusList = [operatorStatus.ACTIVE, operatorStatus.CALLING]
      }
      if(this.controller.selectedOperators) {
        this.filterCondition.selectedOperators = this.controller.selectedOperators
      }
      else {
        this.filterCondition.selectedOperators = [null, ...this.operators.map((o) => o.id)]
      }
      if(this.controller.selectedPlatforms) {
        this.filterCondition.selectedPlatforms = this.controller.selectedPlatforms
      }
      else {
        this.filterCondition.selectedPlatforms = Object.values(operatorHandlingSessionPlatform).map((i) => i.value)
      }
      if(this.controller.bookmarkConditions) {
        this.filterCondition.bookmarkConditions = this.controller.bookmarkConditions
      }
      else {
        this.filterCondition.bookmarkConditions = [true, false]
      }
      if(this.controller.operatorHandlingSessionIdSaved && this.filterCondition.operatorHandlingSessionId === null) {
        this.filterCondition.operatorHandlingSessionId = this.controller.operatorHandlingSessionIdSaved
      }
      else {
        this.filterCondition.operatorHandlingSessionId = ''
      }
    },
    /** モーダル内の「デフォルトの並び替え条件にする」リンク */
    resetSortConditions() {
      this.sortCondition = 'messageNeedHandle'
    }
  }
}
</script>

<style scoped>
.checkbox-area {
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 0 10px 0 10px;
}

@media only screen and (min-width: 641px) {
  .field {
    margin-left: 0;
    margin-right: 0;
    justify-content: flex-end;
  }
  .scrollable {
    max-height: 10vh;
    overflow-y: scroll;
  }
}
</style>
