<template>
<div>
  <div class="row">
    <div class="col-md-12">
      <div class="m-1 p-1">
        <h6 class="border-bottom pb-1">{{ $t('importFromFile.importFromDialogflow') }}</h6>
        <div class="form-group">
          <b-form-file
            name="file"
            v-model="controller.selectedFile"
            accept=".zip"
            :state="Boolean(controller.selectedFile)"
            :placeholder="$t('importFromFile.chooseFile')">
          </b-form-file>
          <div class="mt-2 mb-2">{{ $t('importFromFile.selectedFile') }}: {{controller.selectedFile && controller.selectedFile.name}}</div>
          <button
            type="submit"
            class="btn btn-success"
            @click="submitFileFromDialogflow">
            {{ $t('importFromFile.upload') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <error-modal
    ref="errorModal"
    :message="message">
  </error-modal>
  <progress-modal
    ref="progressModal"
    :message="message">
  </progress-modal>
</div>
</template>

<script>
import csrfToken from '../util/csrf-token'
import { ImportStatus } from '../model/importer'

export default {
  props: ['controller'],
  data() {
    return {
      message: ''
    }
  },
  computed: {
    isImporting: function () {
      return this.controller.isImporting
    },
    statusMessage: function () {
      return this.controller.statusMessage
    }
  },
  methods: {
    showProgress(message) {
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    showError(message) {
      this.message = message
      this.$refs.errorModal.show()
    },
    submitFileFromDialogflow() {
      if (this.controller.selectedFile == null) {
        this.showError("Select the file to import.")
        return
      }
      const confirmResult = window.confirm(
        "Is it ok?\n" +
        "Agent: '" + this.controller.agentName + "'\n" +
        "file: " + this.controller.selectedFile.name
      )
      if (!confirmResult) {
        return
      }
      let result, message, promise
      [result, message, promise] = this.controller.submitFileFromDialogflow(
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
      }
    }
  },
  watch: {
    isImporting: {
      handler: function(isImporting) {
        // 同期終了時にプログレスを閉じる
        if (!isImporting) {
          this.hideProgress()
        }
      }
    },
    statusMessage: {
      handler: function(statusMessage) {
        // ステータスメッセージが変更されたらmessageに同期
        if (statusMessage != null) {
          this.message = statusMessage
          if(this.controller.statusCode == ImportStatus.ERROR){
            this.hideProgress()
            this.showError(this.message)
          }
        }
      }
    }
  }
}
</script>
