import app from "./factory";
import { dia, ui, format, mvc } from "@joint/plus";
import { PaperInitializer } from "./paper";
import { initializeNavigator } from "./navigator";
import { ResponseType, IntentDetail } from "../../model/intent";
import { layoutElements, findBiggestParent } from "./utils";
import {
  ANSWER_LABEL_WEBHOOK,
  NODE_LABEL_EMPTY,
  NODE_TYPE_ANSWER,
  NODE_TYPE_QUESTION,
} from "./const";

app.AppView = mvc.View.extend({
  el: "#scenario-diagram",

  init() {
    this.vm = this.options.vm;
    this.nodes = [];
    // グラフの初回描画にしか使用しない。それ以降はgraph.getLinks()を使用。
    this.links = [];
    this.graph = new dia.Graph();
    this.paper = null;
    this.paperScroller = null;
    this.selection = null;
    this.startLink = null;
    this.startNode = null;

    // 表示用のpaperの生成
    const paperInitializer = new PaperInitializer(this.graph);
    const paperResult = paperInitializer.initialize();
    this.paper = paperResult[0];

    this.paperScroller = paperResult[1];

    // エレメント選択時のツールの生成
    this.initializeSelection();

    // paperのコールバック関数の設定
    paperInitializer.setPaperCallbacks(this.selection, this.vm, this.nodes);

    // インテントデータからノードデータを生成
    this.renderGraphData();

    // ノードのレイアウトを調整
    layoutElements(this.graph);

    // 右下の全体俯瞰用のウィンドウを生成
    initializeNavigator(this.paperScroller);

    // 表示領域の調整
    this.paper.scale(0.5, 0.5);
    this.paperScroller.centerElement(this.startNode);

    // シナリオの全体の画像をサムネイルとして保存
    this.saveThumbnail();
  },

  /**
   * 要素クリック時に表示されるツールビューを定義
   */
  initializeSelection() {
    this.selection = new ui.Selection({
      paper: this.paper,
    });
  },

  /**
   * 現在のシナリオに関連するノードとリンクの作成
   * ノードを追加時、リンクはないがシナリオのインテントカテゴリに入っているインテントも表示しないといけない
   */
  renderGraphData() {
    // nodeデータをグラフに追加し、nodeのリストをリンク作成のために保持しておく
    this.initializeNodeData();

    // linkの作成
    this.initializeLinkData();

    // グラフに普通のノードを追加し終わった後にstartNodeを描画する
    // そうしないと各ノードの子ノードの数を数えられないため
    this.initializeStartNode();

    // グラフに追加
    this.graph.resetCells(...this.nodes.concat(this.links));
  },

  /**
   * start地点となるノードを図に追加
   */
  initializeStartNode() {
    this.startNode = app.Factory.createStartNode();
    this.nodes.push(this.startNode);

    if (this.nodes.length == 1) {
      // ノードが存在しない場合はスタートのノードは追加しない。
      return;
    }
    // ウィザード機能解除
    this.vm.wizard = false;
    // 一番大きいグループの親ノードを探す
    this.biggestParent = findBiggestParent(this.links, this.nodes);
    if (this.biggestParent) {
      this.startLink = app.Factory.createLink(
        this.startNode.id,
        this.biggestParent.id
      );
      this.links.push(this.startLink);
    }
  },

  /**
   * ノードのデータをインテントのリストから作成してグラフに加える
   */
  initializeNodeData() {
    // デフォルト言語の形を基準として、選択中の言語のインテント詳細をノードに登録する
    for (const intent of this.vm.controller.intents) {
      // 対象のインテントのインテントコンテキストを取得
      const intentContexts = this.vm.controller.intentContexts.filter(
        (intentContext) => intentContext.intentId == intent.id
      );

      // 現在の言語にインテント詳細がない場合のためにダミーのインテント詳細を作成
      const dummyIntentDetail = new IntentDetail();
      dummyIntentDetail.intentId = intent.id;

      // 現在の言語に一致するインテント詳細を取得
      // 1インテント1言語につき1つのインテント詳細しかないため、findで取得
      const allLanguagesIntentDetails = this.vm.controller.intentDetails.filter(
        (intentDetail) => intentDetail.intentId == intent.id
      );
      let currentLanguageIntentDetail = allLanguagesIntentDetails.find(
        (intentDetail) =>
          intentDetail.data.userSays.some(
            (userSay) => userSay.language == this.vm.controller.language
          ) ||
          intentDetail.data.responseMessages.some(
            (responseMessage) =>
              responseMessage.language == this.vm.controller.language
          )
      );
      if (!currentLanguageIntentDetail) {
        currentLanguageIntentDetail = dummyIntentDetail;
        this.vm.controller.intentDetails.push(dummyIntentDetail);
      }

      // 質問ノード自体を描画するかを判定
      const existsQuestion = allLanguagesIntentDetails.some(
        (intentDetail) => intentDetail.data.userSays.length != 0
      );
      // 描画処理
      if (existsQuestion)
        this.createQuestionNode(
          currentLanguageIntentDetail,
          allLanguagesIntentDetails,
          intentContexts
        );

      // 回答ノード自体を描画するかを判定
      const existsAnswer = allLanguagesIntentDetails.some(
        (intentDetail) => intentDetail.data.responseMessages.length != 0
      );
      // 描画処理
      if (existsAnswer)
        this.createAnswerNode(
          currentLanguageIntentDetail,
          allLanguagesIntentDetails,
          intentContexts,
          intent
        );
    }
  },

  /**
   * 質問ノードを作成
   */
  createQuestionNode(currentLanguageIntentDetail, intentContexts) {
    // 質問の取得
    let userSays = currentLanguageIntentDetail.data.userSays.map(
      (userSay) => userSay.text
    );
    // 現在の言語で質問がない場合、他の言語で質問が1つも無い場合はノード自体の生成を行わないようにする
    // 質問が他の言語にあり現在の言語にない場合、ダミーのノードを作成するための質問を1つ設定する。
    // 他の言語で先に質問が作られた場合などはデータがないためemptyのノードを表示
    if (userSays.length == 0) userSays = [NODE_LABEL_EMPTY];

    // 質問ノードの生成
    const userSayNode = app.Factory.createQuestion(
      intentContexts[0].id + "node_q",
      currentLanguageIntentDetail.intentId,
      userSays,
      { x: 100, y: 350 },
      this.vm,
      this.vm.controller.language
    );
    this.nodes.push(userSayNode);
  },

  /**
   * 回答ノードを作成
   */
  createAnswerNode(currentLanguageIntentDetail, intentContexts, intent) {
    let answers = [];
    if (intent.enableWebhook) {
      // ウェブフックがオンになっているインテントは常にWebhook用の回答ノードを描画する。
      answers = [ANSWER_LABEL_WEBHOOK];
    } else {
      // 通常の回答ノードの生成
      answers = this.createAnswerData(currentLanguageIntentDetail);
      if (answers.length == 0) {
        // 回答が他の言語にあり現在の言語にない場合、ダミーのノードを作成するための回答を1つ設定する。
        answers = [NODE_LABEL_EMPTY];
      }
    }

    const answerNode = app.Factory.createAnswer(
      intentContexts[0].id + "node_a",
      currentLanguageIntentDetail.intentId,
      answers,
      { x: 100, y: 350 },
      this.vm,
      this.vm.controller.language
    );
    this.nodes.push(answerNode);
  },
  /**
   * IntentDetailから回答用のデータを作成
   * @return {string[]}
   */
  createAnswerData(targetIntentDetail) {
    const intent = this.vm.controller.intents.find(
      (intent) => intent.id == targetIntentDetail.intentId
    );
    if (intent.enableWebhook) {
      return [ANSWER_LABEL_WEBHOOK];
    }

    if (targetIntentDetail.data.responseMessages.length == 0) {
      // 回答が登録されていないため、空のノード用のメッセージを返す
      return [NODE_LABEL_EMPTY];
    }

    let answers = [];
    let nonCustomPayloadResponseMessages =
      targetIntentDetail.data.responseMessages.filter(
        (r) => r.type != ResponseType.CUSTOM_PAYLOAD
      );
    if (nonCustomPayloadResponseMessages.length == 0) {
      // カスタムペイロード以外の回答がない場合
      // カスタムペイロードを表示するかわりにカスタムペイロードと表示
      answers = ["[CUSTOM_PAYLOAD]"];
    } else {
      // カスタムペイロード以外の回答がある場合
      answers = nonCustomPayloadResponseMessages.slice(-1)[0].toNodeData();
    }
    return answers;
  },

  /**
   * リンクのデータをグラフに追加する
   */
  initializeLinkData() {
    // 選択中の言語の質問しかnodeに表示しない
    // インテント内で繋ぐ
    for (const intent of this.vm.controller.intents) {
      const intentNodes = this.nodes.filter(
        (node) => node.attributes.intentId == intent.id
      );
      const questionNode = intentNodes.find(
        (node) => node.attributes.type == NODE_TYPE_QUESTION
      );
      const answerNode = intentNodes.find(
        (node) => node.attributes.type == NODE_TYPE_ANSWER
      );
      if (answerNode && questionNode) {
        const intentLink = app.Factory.createLink(
          questionNode.id,
          answerNode.id
        );
        this.links.push(intentLink);
      }
    }

    // インテント間でリンクを繋ぐ
    const inputIntentContexts = this.vm.controller.intentContexts.filter(
      (ic) => ic.isInput && ic.context.parentContext
    );
    // ベースコンテキストは考慮しない
    const outputIntentContexts = this.vm.controller.intentContexts.filter(
      (ic) => !ic.isInput && ic.context.parentContext
    );
    for (const inputIntentContext of inputIntentContexts) {
      const outputIntentContext = outputIntentContexts.find(
        (oic) => oic.context.id == inputIntentContext.context.id
      );

      // TODO 本来いらないはずだが、本番データに不要なインプットインテントコンテキストが存在するため一時的にスキップ処理を置いておく
      if (!outputIntentContext) continue;

      const fromAnswerNode = this.nodes.find(
        (node) =>
          node.attributes.intentId == outputIntentContext.intentId &&
          node.attributes.type == NODE_TYPE_ANSWER
      );
      const toQuestionNode = this.nodes.find(
        (node) =>
          node.attributes.intentId == inputIntentContext.intentId &&
          node.attributes.type == NODE_TYPE_QUESTION
      );
      if (!(fromAnswerNode && toQuestionNode)) continue;

      // リンク追加
      const link = app.Factory.createLink(fromAnswerNode.id, toQuestionNode.id);
      this.links.push(link);
    }
  },

  /**
   * 質問ノードを追加
   * @param {*} intentDetail 追加するノードのインテント詳細
   * @param {number} fromIntentId リンクのSourceになる回答ノードのインテントのID。e.g. 28883
   */
  addQuestionNode(intentDetail, fromIntentId = null) {
    // ペーパー上から削除ボタンの表示を削除
    this.paper.removeTools();

    this.selection.collection.reset([]);

    // 追加する位置に既にnodeが存在している場合は位置を調整
    const origin = this.paperScroller.getVisibleArea().center();
    while (this.graph.findModelsFromPoint(origin).length != 0) {
      origin.y += 100;
    }

    // 渡されたインテント詳細に関係するインテントコンテキストを取得
    const intentContext = this.vm.controller.intentContexts.find(
      (intentContext) => intentContext.intentId == intentDetail.intentId
    );
    const questionNode = app.Factory.createQuestion(
      intentContext.id + "node_q",
      intentDetail.intentId,
      [intentDetail.data.userSays[0].text],
      origin,
      this.vm,
      intentDetail.languageCode
    );
    questionNode.addTo(this.graph);
    this.nodes.push(questionNode);

    // リンクの処理を行う
    if (fromIntentId) {
      // fromNodeがある場合は新規作成した質問ノードとつなげる
      const fromAnswerNode = this.nodes.find(
        (node) =>
          node.attributes.intentId == fromIntentId &&
          node.attributes.type == NODE_TYPE_ANSWER
      );
      const link = app.Factory.createLink(fromAnswerNode.id, questionNode.id);
      link.addTo(this.graph);
    } else {
      // fromのインテントが指定されていない場合、スタートのノードとつなげる
      if (!this.startLink) {
        this.startLink = app.Factory.createLink(
          this.startNode.id,
          questionNode.id
        );
        this.startLink.addTo(this.graph);
      }

      const answerNode = this.nodes.find(
        (node) =>
          node.attributes.type == NODE_TYPE_ANSWER &&
          node.attributes.intentId == intentDetail.intentId
      );
      // 回答ノードとの間にリンクがなければ作成
      if (
        answerNode &&
        !this.graph
          .getLinks()
          .find(
            (link) =>
              link.attributes.source.id == questionNode.id &&
              link.attributes.target.id == answerNode.id
          )
      ) {
        const link = app.Factory.createLink(questionNode.id, answerNode.id);
        link.addTo(this.graph);
      }
    }

    layoutElements(this.graph);
  },

  /**
   * 渡されたインテントの、指定されたタイプのノードを削除
   * @param {number} intentId
   * @param {string} deleteNodeType qad.Answer or qad.Questionのいずれか
   */
  deleteNode(intentId, deleteNodeType) {
    const node = this.nodes.find(
      (node) =>
        node.attributes.intentId == intentId &&
        node.attributes.type == deleteNodeType
    );
    node.remove();
    this.nodes = this.nodes.filter((_node) => _node.id != node.id);
    this.paper.removeTools();
    this.selection.collection.reset([]);
  },

  /**
   * 質問ノードの更新
   * 回答のみのノードに質問ノードを追加する場合の処理も行う
   * @param {*} intentDetail
   */
  updateQuestionNode(intentDetail) {
    this.selection.collection.reset([]);

    const node = this.nodes.find(
      (node) =>
        node.attributes.type == NODE_TYPE_QUESTION &&
        node.attributes.intentId == intentDetail.intentId
    );

    if (!node) {
      // 回答ノードのみのインテントに質問ノードを追加する場合。
      return this.addQuestionNode(intentDetail, null);
    }

    // 質問があれば質問を、なければ空のノード用の文言をセット
    const questionText =
      intentDetail.data.userSays.length === 0
        ? NODE_LABEL_EMPTY
        : intentDetail.data.userSays[0].text;
    node.attr("label/text", questionText);

    layoutElements(this.graph);
  },

  /**
   * 回答ノードの更新・作成
   * @param {*} intentDetail
   */
  updateAnswerNode(intentDetail) {
    this.selection.collection.reset([]);

    const node = this.nodes.find(
      (node) =>
        node.attributes.type === NODE_TYPE_ANSWER &&
        node.attributes.intentId == intentDetail.intentId
    );
    if (intentDetail.data.responseMessages.length == 0) {
      // 他の言語で回答が存在しなければノードを削除する
      const existsOtherLanguageAnswer = this.vm.controller.intentDetails.some(
        (_intentDetail) =>
          _intentDetail.id != intentDetail.id &&
          _intentDetail.intentId == intentDetail.intentId &&
          _intentDetail.data.responseMessages.length != 0
      );
      if (!existsOtherLanguageAnswer && node) {
        // 回答ノードを削除
        node.remove();
        // nodesからも取り除いておく
        this.nodes = this.nodes.filter((_node) => _node.id != node.id);
        return;
      }
    }

    const answers = this.createAnswerData(intentDetail);
    if (node) {
      // 更新された回答のノードが存在する場合、表示を更新
      node.attr("label/text", answers[0]);
    } else {
      // 存在しない場合、回答ノードを追加
      this.addAnswerNode(intentDetail, answers);

      layoutElements(this.graph);
    }
  },

  /**
   *
   */
  addAnswerNode(intentDetail, answers) {
    // 追加する位置に既にnodeが存在している場合は位置を調整
    const origin = this.paperScroller.getVisibleArea().center();
    while (this.graph.findModelsFromPoint(origin).length != 0) {
      origin.y += 100;
    }

    const questionNode = this.nodes.find(
      (node) => node.attributes.intentId == intentDetail.intentId
    );

    const intentContext = this.vm.controller.intentContexts.find(
      (intentContext) => intentContext.intentId == intentDetail.intentId
    );
    // 更新された回答のノードが存在しない場合、回答ノードを追加
    const answerNode = app.Factory.createAnswer(
      intentContext.id + "node_a",
      intentDetail.intentId,
      answers,
      origin,
      this.vm,
      this.vm.controller.language
    );
    answerNode.addTo(this.graph);
    this.nodes.push(answerNode);

    const link = app.Factory.createLink(questionNode.id, answerNode.id);
    link.addTo(this.graph);
  },

  /**
   * サムネイル画像として図を保存
   *  すぐに実行すると描画の速度上空白の画像が表示されることがあるため待ってから実行
   */
  saveThumbnail() {
    const self = this;
    setTimeout(() => {
      format.toPNG(
        this.paper,
        (dataURL) => {
          self.vm.$refs.scenarioDiagram.saveThumbnail(dataURL);
        },
        {
          padding: 10,
          useComputedStyles: false,
        }
      );
    }, 2000);
  },

  /**
   * サムネイル画像のエクスポート
   */
  exportImage() {
    const self = this;

    format.toPNG(
      this.paper,
      (dataURL) => {
        const image = new Image();
        image.src = dataURL;

        const w = window.open();
        w.document.write(image.outerHTML);
        // urlがblankだと画像を保存できないので#を設定する
        w.document.location = "#";
        self.vm.$refs.scenarioDiagram.onFinishExportImage();
        // サムネイルを最新にする
        self.vm.$refs.scenarioDiagram.saveThumbnail(dataURL);
      },
      {
        padding: 10,
        useComputedStyles: false,
      }
    );
  },

  /**
   * エディタの再配置ボタンが押された際にVueComponentから呼び出される
   */
  onClickLayoutButton() {
    layoutElements(this.graph);
  },
});

export default app;
