import { Intent } from "./intent"

class IntentCheckResult {
  constructor (category, intent, user_say, response_intent, result) {
    this.category=category
    this.intent=intent
    this.user_say=user_say
    this.response_intent=response_intent
    this.result=result
  }
}

IntentCheckResult.fromData = function fromData(data) {
  return new IntentCheckResult(
    data.category || null,
    data.intent ? Intent.fromData(data.intent) : null,
    data.user_say || null,
    data.response_intent ? Intent.fromData(data.response_intent) : null,
    data.result || null
  )
}

class IntentCheckHistory {
  constructor (id=null, name='', language=null, status=null) {
    this.id = id
    this.name = name
    this.language = language
    this.status = status
  }

  toData() {
    return {
      id: this.id,
      name: this.name,
      language: this.language,
      status: this.status
    }
  }
}

IntentCheckHistory.fromData = function fromData(data) {
  return new IntentCheckHistory(
    data.id || null,
    data.name || '',
    data.language || null,
    data.status || null
  )
}

class IntentCheckHistoryAdditional {
  constructor (id=null, name='', language=null, status=null, success=0, failure=0, complete=0, total=0) {
    this.id = id
    this.name = name
    this.language = language
    this.status = status
    this.success = success
    this.failure = failure
    this.complete = complete
    this.total = total
  }
}

IntentCheckHistoryAdditional.fromData = function fromData(data) {
  return new IntentCheckHistoryAdditional(
    data.id || null,
    data.name || '',
    data.language || null,
    data.status || null,
    data.success || 0,
    data.failure || 0,
    data.complete || 0,
    data.total || 0
  )
}

class Dataset {
  constructor (id=null, language_code=null) {
    this.id = id
    this.language_code = language_code
  }

  toData() {
    return {
      id: this.id,
      language_code: this.language_code
    }
  }
}

Dataset.fromData = function fromData(data) {
  return new Dataset(
    data.id || null,
    data.language_code || null,
  )
}

/**
 * List of questions for validation
 */
class IntentCheckQuestion {
  constructor(id=null, language=null, questions=[]) {
    this.id = id
    this.language = language
    this.questions = questions
  }

  toData() {
    let questions = []
    for(const question of this.questions){
      questions.push(question)
    }
    return {
      id: this.id,
      language: this.language,
      questions: questions
    }
  }
}

IntentCheckQuestion.fromData = function fromData(data) {
  let questions = []
  if (data.questions) {
    for (const question of data.questions) {
      questions.push(question)
    }
  }

  return new IntentCheckQuestion(
    data.id || null,
    data.language || null,
    questions
  )
}

export {
  IntentCheckResult,
  IntentCheckHistory,
  IntentCheckHistoryAdditional,
  Dataset,
  IntentCheckQuestion
}
