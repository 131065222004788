<template>
  <li>
    <div
      class="label my-1 p-1"
      @click="$emit('select-category', category)"
    >
      {{ category.name }}
    </div>
    <ul>
      <preview-keyword-category-item
        v-for="(subCategory, index) in category.subs"
        :category="subCategory"
        :key="index"
        @select-category="selectCategory"
      >
      </preview-keyword-category-item>
    </ul>
  </li>
</template>

<script>
  export default {
    props: ['category'],
    methods: {
      selectCategory(category) {
        this.$emit('select-category', category)
      }
    }
  }
</script>
