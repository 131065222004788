import { I18n } from '../util/i18n'
import {RestApi} from "../util/rest-api"
import { Synchronize, SynchronizeStatus } from '../model/synchronize'
import { KeywordCategory, Keyword } from '../model/keyword'

export default class SynchronizeController {
  constructor(apiUrl, i18n) {
    this.i18n = i18n
    this.synchronizeApi = new RestApi(apiUrl.Synchronize, Synchronize)
    this.keywordCategoryApi = new RestApi(apiUrl.KeywordCategory, KeywordCategory)
    this.allKeywordApi = new RestApi(apiUrl.AllKeyword, Keyword)
    this.synchronizeStatusMessage = null
    this.synchronizeCode = null
    this.isSynchronizing = false
    this.allKeywordCategories = []
    this.allKeywords = []
  }

  /**
   * dialogflowへcmsのデータを同期する
   */
  synchronize(csrfToken=null, isCard=false) {
    return new Promise((resolve, reject) => {
      let syncResult = {
        wasSuccessful: true,
        message: '...'
      }
      // データがdialogflowの制限に従っているか確認する
      this.isReadyToSynchronize()
        .then((result) => {
          if (result.isReady) {
            return this.startSynchronization(csrfToken, isCard)
          } else {
            syncResult.wasSuccessful = false
            syncResult.message = result.errorMessage
            resolve(syncResult)
          }
        })
        .then(() => {
          resolve(syncResult)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * 同期前にキーワードの長さなどが制限にあっているか確認
   * 現在はキーワード長しかチェックしていないが、今後追加する
   * TODO ウェルカムインテントがあるかどうかなど
   */
  isReadyToSynchronize() {
    return new Promise((resolve, reject) => {
      let result = {
        isReady: true,
        errorMessage: ''
      }
      let promise1 = this.keywordCategoryApi.list()
      let promise2 = this.allKeywordApi.list()
      Promise.all([promise1, promise2])
        .then((results) => {
          this.allKeywordCategories = results[0]
          this.allKeywords = results[1]
          // キーワードの長さが制限内か判定
          if (this.isKeywordTooLong()) {
            result.isReady = false
            result.errorMessage = this.i18n.t('keyword.keywordLengthErrorText')
          }
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * キーワードの長さがdialogflowの制限を超えているか確認する
   */
  isKeywordTooLong() {
    // TODO dialogflowの制限をconstに移動する
    let dialogflowKeywordLengthLimit = 30
    let isKeywordTooLong = false
    let keywordCategoryFullPathList = this.createKeywordCategoryFullPathList()
    for (let keyword of this.allKeywords) {
      let keywordFullName = keywordCategoryFullPathList[keyword.categoryId] + "_" + keyword.name
      if (keywordFullName.length > dialogflowKeywordLengthLimit) {
        isKeywordTooLong = true
        break
      }
    }
    return isKeywordTooLong
  }

  /**
   * Synchronizeオブジェクトを作成し、同期状況をトラックする
   */
  startSynchronization(csrfToken=null, isCard=false) {
    let options = {}
    if(isCard){
      options = {
        params:{
          skip: isCard
        }
      }
    }
    return new Promise((resolve, reject) => {
      this.synchronizeApi.create(new Synchronize, csrfToken, options)
        .then((synchronize) => {
          // ステータスのポーリング
          this.isSynchronizing = true
          this.pollingSynchronize(synchronize)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * カテゴリから親カテゴリをたどって名前を繋げたリストを作成する
   */
  createKeywordCategoryFullPathList() {
    let flat = KeywordCategory.categoriesAsFlat(this.allKeywordCategories)
    // {categoryId: category, ...}のjsonを作成
    let categoryIdToCategory = {}
    for (const [nestedLevel,category] of flat) {
      categoryIdToCategory[category.id] = category
    }
    // category -> カテゴリツリーのパス生成
    let categoryIdToPath = {}
    for (const [nestedLevel,category] of flat) {
      const categoryPath = this.resolveCategoryPath(category, categoryIdToCategory)
      categoryIdToPath[category.id] = categoryPath.map((c) => {return c.name}).join("_")
    }
    return categoryIdToPath
  }

  /**
   * 与えら得れたカテゴリのフルパスを取得する
   */
  resolveCategoryPath(category, categoryIdToCategory) {
    if (category.parentId == null) {
      return [category]
    } else {
      const parentCategory = categoryIdToCategory[category.parentId]
      return this.resolveCategoryPath(parentCategory, categoryIdToCategory).concat([category])
    }
  }

  /**
   * 同期ステータスのポーリング
   */
  pollingSynchronize(synchronize) {
    // メッセージを更新
    this.synchronizeStatusMessage = synchronize.description
    this.synchronizeCode = synchronize.code
    if ([SynchronizeStatus.FINISH, SynchronizeStatus.ERROR, SynchronizeStatus.BUSY].includes(synchronize.code)) {
      this.isSynchronizing = false
      return
    }
    // 同期ステータス取得
    this.synchronizeApi.retrieve(synchronize)
      .then((newSynchronize) => {
        // 少し待ってループ
        setTimeout(() => {
            this.pollingSynchronize(newSynchronize)
          }, 5000)
      })
  }
}
