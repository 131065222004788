<template>
  <!-- 質問表示用コンポネント -->
  <div v-show="chatItem.text">
    <div>
      <span class="message-bg-blue">
        {{ chatItem.text }}
      </span>

      <!-- 翻訳表示領域 -->
      <div
        v-if="
          chatItem.action !== 'input.welcome' &&
          UILanguage !== chatItem.languageCode
        "
        class="text-size-sm-right"
      >
        <div v-if="isTranslating" title="Translating...">
          <b-spinner
            style="width: 0.5rem; height: 0.5rem"
            v-for="variant in variants"
            :variant="variant"
            :key="variant"
            type="grow"
          />
        </div>

        <div v-else class="p-1 text-success">
          {{ translationObject[chatItem.text] }}
        </div>
      </div>
    </div>

    <!-- 質問送信時刻表示 -->
    <div class="text-right text-secondary">
      <span
        class="pointer-cursor"
        v-b-tooltip.hover.bottom.html
        :title="messageTooltipRight(chatItem)"
      >
        {{ getTimeFromDate(chatItem.insertDateTime) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "chatItem",
    "clientIdFilterEnabled",
    "translationObject",
    "isTranslating",
    "UILanguage",
  ],
  data() {
    return {
      variants: ["primary", "danger", "success"],
    };
  },
  computed: {
    messageTooltipRight() {
      // 右側メッセージ（質問）のツールチップで、ClientIDも見れるように
      return (chatItem) => {
        let html = "";
        if (this.clientIdFilterEnabled) {
          html += `ClientId: ${chatItem.clientId ?? "-"}</br>`;
        }
        return `${html}${this.getFullTime(this.chatItem.insertDateTime)}`;
      };
    },
    getFullTime() {
      return (time) => {
        return this.getTimeFromDate(time).replace("T", " ");
      };
    },
  },
  methods: {
    /**
     * TODO ??
     */
    getTimeFromDate(date) {
      return `${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;
    },
  },
};
</script>

<style scoped>
/* .message-container {
  display: flex;
  flex-direction: column;
} */

.message-bg-blue {
  padding: 5px;
  display: inline-block;
  border-radius: 8px;
}
</style>
