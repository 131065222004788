<template>
  <div>
    <b-btn
      class="mr-1"
      variant="info"
      @click="$emit('skip')">
      <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('general.continue') }}
    </b-btn>
    <b-btn
      variant="danger"
      @click="$emit('ok')">
      <i class="fas fa-times-circle"></i> {{ $t('general.abort') }}
    </b-btn>
  </div>

</template>


<script>
export default {
  props: [
  ],
  data() {
    return {
    }
  }
}


</script>
