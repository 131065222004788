<template>
  <div>
    <div class="preview-left">
      <div v-if="keywords">
        <ul style="padding: 0">
          <preview-keyword-category-item
            v-for="(category, index) in this.keywordsList"
            :category="category"
            :key="index"
            @select-category="selectCategory"
          >
          </preview-keyword-category-item>
        </ul>
      </div>
      <div v-else>
        {{ keywordErrorMessage }}
      </div>
    </div>
    <div class="preview-right">
      <div v-if="selectedKeywords" v-for="(keyword, index) in selectedKeywords" :key="index">
        <div
          class="label my-1 p-1"
          :id="`keyword-${index}`"
        >
          {{ keyword.name }}
          <b-popover :target="`keyword-${index}`" triggers="click hover">
            <b-tabs>
              <b-tab v-for="(value, key) in keyword.values" :key="key" :title="key">
                <b-table :items="value">
                </b-table>
              </b-tab>
            </b-tabs>
          </b-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['keywordData'],
      data() {
        return {
          keywords: null,
          keywordErrorMessage: null,
          keywordsList: [],
          selectedKeywords: null
        }
      },
    methods: {
      shown() {
        if (this.keywordData) {
          try {
            this.keywords = JSON.parse(this.keywordData)
          } catch (e) {
            this.keywords = null
            this.keywordErrorMessage = e
          }
          this.formattingData()
        }
      },
      hidden() {
        this.keywords = null
        this.keywordErrorMessage = null
        this.keywordsList = []
        this.selectedKeywords = null
      },
      formattingData() {
        if (this.keywords) {
          this.keywordsList = []
          this.keywords.forEach((kw) => {
            if (kw.values instanceof Array) {
              // 古いJSONファイルへの対応
              let new_values = {}
              new_values[kw.language] = kw.values
              kw.values = new_values
              delete kw.language
            }
            for (let [, value] of Object.entries(kw.values)) {
              // 類似語のリストを文字列に変換
              value.forEach(vl => vl.synonym = vl.synonym.join(' '))
            }
            /*
            　JSON文から取得したデータを
              'category_name': {
                subs: [
                  'category_name': {
                    subs: [...],
                    keywords: [...]
                  }
                ],
                keywords: [
                  keyword_object, keyword_object, ...
                ]
              }
              の構造に変更
            */
            const categories = kw.category.split('_')
            let target = this.keywordsList
            categories.forEach((ct, index) => {
              let result = this.findInList(ct, target)
              if (!result) {
                result = {name: ct, subs: [], keywords: []}
                target.push(result)
              }
              if (index === categories.length - 1 && !result.keywords.some(kkw => kkw.name === kw.name)) {
                result.keywords.push({name: kw.name, values: kw.values})
              }
              target = result.subs
            })
          })
        }
      },
      findInList(name, list) {
        return list.find(item => item.name === name)
      },
      selectCategory(category) {
        this.selectedKeywords = category.keywords
      }
    }
  }
</script>
