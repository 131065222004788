/*
 * Intent Check
 */
import Vue from 'vue'
import {UrlBuilder} from './util/url-builder'
import {API_URL} from './resource/urls'
import {IntentCheckController} from './controller/intent-check'

import IntentCheck from './components/IntentCheck.vue'
import ChatBox from './components/ChatBox.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import ModalFooter from "./components/ModalFooter.vue"
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue"
import LanguageChangeChatWindow from "./components/LanguageChangeChatWindow.vue"
import ErrorModalFooter from './components/ErrorModalFooter.vue'
import TestChat from './components/TestChat.vue'
import Pagination from './components/Pagination.vue'


export const appIntentCheck = (i18n) => {
  /* コントローラ */
  const controller = new IntentCheckController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.userType,
    window.i18nContext,
    window.requestContext.available_platform,
    window.requestContext.test_chat_platform,
    window.requestContext.intent_check_history_status
  )

  /* コンポーネント登録 */
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('intent-check', IntentCheck)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)
  Vue.component('chat-box', ChatBox)
  Vue.component('language-change-chat-window', LanguageChangeChatWindow)
  Vue.component('error-modal-footer', ErrorModalFooter)
  Vue.component('test-chat', TestChat)
  Vue.component('pagination', Pagination)

  new Vue({
    el: '#app-intent-check',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
