class SpeechContext {

  constructor(sid=null, phrase=null, output='', boost=0, languageCode=null) {
    this.sid = sid
    this.phrase = phrase
    this.output = output
    this.boost = boost
    this.languageCode = languageCode
  }

  toData() {
    return {
      sid: this.sid,
      phrase: this.phrase,
      output: this.output,
      boost: this.boost,
      language_code: this.languageCode
    }
  }
}

SpeechContext.fromData = function fromData(data) {
  return new SpeechContext(
    data.sid || null,
    data.phrase || null,
    data.output || '',
    data.boost || 0,
    data.language_code || null
  )
}

export {
  SpeechContext
}
