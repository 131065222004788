<template>
  <div class="answer-quickReplies">
    <div>{{ $t('answer.content') }}({{ $t('answer.quickRepliesTitle') }}):</div>

    <b-form-textarea
      v-model="responseMessageQuickRepliesTitle"
      class="mb-2"
      :rows="3"
      :max-rows="6"
      @input="googleCheck"
      :state="!$v.responseMessageQuickRepliesTitle.$error"
    />

    <b-form-invalid-feedback v-if="$v.responseMessageQuickRepliesTitle.$error">
      {{ $t('webhook.required') }} &
      {{
        $t('answer.maxTxtLen', {
          counter: $v.responseMessageQuickRepliesTitle.$params.maxLength.max,
        })
      }}
    </b-form-invalid-feedback>

    <div>
      {{ $t('answer.quickRepliesResponseMessage') }}({{
        $t('answer.quickRepliesUpTo')
      }}):
    </div>

    <draggable v-model="responseMessageQuickRepliesReplies" handle=".handle">
      <div
        v-for="(reply, index) in responseMessageQuickRepliesReplies"
        :key="index"
      >
        <div class="input-group mb-1" @keypress.enter.prevent="addReplyText">
          <b-form-input
            v-model="reply.text"
            @input="googleCheck"
            :state="
              !$v.responseMessageQuickRepliesReplies.$each[index].text.$error
            "
          />
          <div class="input-group-append answer-quick-replies">
            <button
              class="btn btn-sm btn-danger"
              @click.prevent="deleteReplyText(index)"
              :disabled="responseMessageQuickRepliesReplies.length == 1"
            >
              <i class="fa fa-minus"></i>
            </button>
            <button class="btn btn-sm btn-success handle">
              <i class="fas fa-arrows-alt"></i>
            </button>
          </div>
          <b-form-invalid-feedback
            v-if="
              $v.responseMessageQuickRepliesReplies.$each[index].text.$error
            "
          >
            {{ $t('webhook.required') }} &
            {{
              $t('answer.maxTxtLen', {
                counter:
                  $v.responseMessageQuickRepliesReplies.$each[index].text
                    .$params.maxLength.max,
              })
            }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </draggable>

    <div class="mb-2">
      <button
        class="btn btn-success"
        @click.prevent="addReplyText"
        :disabled="responseMessageQuickRepliesReplies.length >= 10"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { QuickRepliesData, ReplyText, TargetService } from '../../model/intent'
import draggable from 'vuedraggable'
import { illegalExp } from '../../util/illegalRegex'

export default {
  props: ['googleCheck', 'selectedTargetServices'],
  components: {
    draggable,
  },
  data() {
    return {
      answerQuickReplies: null,
      responseMessageQuickRepliesTitle: '',
      responseMessageQuickRepliesReplies: [new ReplyText()],
    }
  },
  validations() {
    return {
      responseMessageQuickRepliesTitle: {
        required,
        maxLength: maxLength(160),
      },
      responseMessageQuickRepliesReplies: {
        $each: {
          text: {
            required,
            maxLength: this.quickRepliesRepliesTextRule(),
          },
        },
      },
    }
  },
  methods: {
    addReplyText() {
      // 返信文の追加
      if (this.responseMessageQuickRepliesReplies.length < 10) {
        this.responseMessageQuickRepliesReplies.push(new ReplyText())
      }
      this.googleCheck()
    },
    deleteReplyText(index) {
      // 要素を1つ削除
      this.responseMessageQuickRepliesReplies.splice(index, 1)
      this.googleCheck()
    },
    inputValue() {
      this.answerQuickReplies = new QuickRepliesData(
        this.responseMessageQuickRepliesTitle,
        this.responseMessageQuickRepliesReplies
      )
      this.answerQuickReplies.title = this.answerQuickReplies.title
        .replace(illegalExp, '')
        .trim()
      for (let replyText of this.answerQuickReplies.replies) {
        replyText.text = replyText.text.replace(illegalExp, '').trim()
      }
      return this.answerQuickReplies
    },
    setValue(selectedResponseMessage) {
      this.responseMessageQuickRepliesTitle = selectedResponseMessage.data.title
      this.responseMessageQuickRepliesReplies =
        selectedResponseMessage.data.replies
    },
    initializeValue() {
      this.answerQuickReplies = null
      this.responseMessageQuickRepliesTitle = ''
      this.responseMessageQuickRepliesReplies = [new ReplyText()]
    },
    validationCheck() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    quickRepliesRepliesTextRule() {
      let selectedLanguage = '',
        isLineSelected = false
      const parentController = this.$root.controller
      switch (parentController.constructor.name) {
        case 'IntentEditorController':
          selectedLanguage = parentController.language
          isLineSelected = this.selectedTargetServices.includes(
            TargetService.LINE
          )
          break
        case 'ConversationHistoryController':
          // セッションごと会話履歴で使われている場合は選択中のセッションの最後のログの言語によって文字数制限を判定。
          const lastLog = parentController.selectedDateLogs.slice(-1)[0]
          selectedLanguage = lastLog.languageCode
          isLineSelected = lastLog.platform.includes('line')
          break
      }
      if (isLineSelected) {
        // LINE選択時は常に20文字が最大。
        return maxLength(20)
      }
      // LINE非選択時は言語によって変化。
      if (selectedLanguage === 'th') {
        return maxLength(50)
      } else {
        return maxLength(20)
      }
    },
  },
}
</script>

