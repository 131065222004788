<template>
  <div class="answer-carouselGoogle">
    <div>{{ $t('answer.content') }}:</div>
    <div @keypress.enter.prevent="">
      <b-form-input
        :placeholder="$t('answer.enterCarouselTitle')"
        v-model="responseMessage.cardTitle"
        class="mb-1"
        type="text">
      </b-form-input>
    </div>
    <div>{{ $t('answer.typeCard') }}</div>
    <div class="mb-1 section-body scroll">
      <b-card>
        <b-tabs v-model="indexTab">
          <b-tab v-for="(reply, index) in responseMessage.carouselButtonText"
                 :title="reply.title || 'new'"
                 :key="index">
            <div class="input-group mb-3">
              <div @keypress.enter.prevent="addCarouselButton">
                <div class="card-button-imgurl">
                  <div class="input-group mb-1">
                    <b-form-input
                      :placeholder="$t('answer.enterImageURL')"
                      @input="validationCheck"
                      v-model="reply.imgurl"
                      type="url"
                      :state="!$v.responseMessage.carouselButtonText.$each[index].imgurl.$error"
                      ref="replyImageUrl">
                    </b-form-input>
                    <div class="input-group-append">
                      <a class="btn btn-info"
                         :class="{ disabled: !reply.imgurl || $v.responseMessage.carouselButtonText.$each[index].imgurl.$error }"
                         :href="reply.imgurl" target="_blank"
                         :title="$t('answer.preView')"
                      ><span class="fas fa-eye mt-1"/></a>
                      <a class="btn btn-primary text-white" v-b-tooltip.hover
                         :title="$t('answer.browseImage')"
                         @click="loadReplyImageUrlFiles(index)">
                        <i class="far fa-images"></i>
                      </a>
                    </div>
                    <b-form-invalid-feedback v-if="$v.responseMessage.carouselButtonText.$each[index].imgurl.$error">
                      {{ $t('answer.invalidURL') }}
                    </b-form-invalid-feedback>
                  </div>
                  <file-contents ref="replyImageUrlFiles"
                                 :img-url-el="$refs.replyImageUrl"
                                 @img-url-model="reply.imgurl = $event"></file-contents>
                </div>
                <div class="card-button-title">
                  <b-form-input
                    :placeholder="$t('answer.enterImageTitle')"
                    :disabled="reply.imgurl==false"
                    class="mb-2"
                    v-model="reply.imgname"/>
                </div>
                <b-form-input
                  :placeholder="$t('answer.cardButtonTitle')"
                  @input="validationCheck"
                  class="mb-1"
                  v-model="reply.title"
                  :state="!$v.responseMessage.carouselButtonText.$each[index].title.$error"/>
                <b-form-invalid-feedback v-if="$v.responseMessage.carouselButtonText.$each[index].title.$error">
                  {{ $t('webhook.required') }}
                </b-form-invalid-feedback>
                <b-form-textarea
                  :placeholder="$t('answer.typeText')"
                  :rows="3"
                  :max-rows="6"
                  v-model="reply.text"/>
              </div>
            </div>
          </b-tab>
          <b-nav-item
            slot="tabs"
            :disabled="this.responseMessage.carouselButtonText.length == 10"
            @click.prevent="addCarouselButton"
            href="#">
              {{ "+ " + (10-this.responseMessage.carouselButtonText.length) + " / 10" }}
          </b-nav-item>
        </b-tabs>
      </b-card>
    </div>
    <div class="float-right mb-1">
      <b-btn
        class="btn btn-danger btn-sm"
        :disabled="responseMessage.carouselButtonText.length == 2"
        @click.prevent="deleteCarouselButton">
          Close tab
      </b-btn>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, url} from 'vuelidate/lib/validators'
import {CarouselGoogleData, CarouselButtonText} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  data() {
    return {
      responseMessage: {
        cardTitle:'',
        carouselButtonText:[new CarouselButtonText()]
      },
      answerCarouselGoogle:null,
      indexTab:0
    }
  },
  validations: {
    responseMessage: {
      carouselButtonText: {
        $each: {
          title: {
            required
          },
          imgurl: {
            url
          }
        }
      }
    }
  },
  methods:{
    addCarouselButton(){
      this.responseMessage.carouselButtonText.push(new CarouselButtonText())
      // カルーセルカード欄が増える度にバリデーションチェック
      this.validationCheck()
    },
    deleteCarouselButton(){
      this.responseMessage.carouselButtonText.splice(this.indexTab, 1)
      // カルーセルカード欄が減る度にバリデーションチェック
      this.validationCheck()
    },
    inputValue(){
      this.answerCarouselGoogle = new CarouselGoogleData(
        this.responseMessage.cardTitle.replace(illegalExp, "").trim(),
        this.responseMessage.carouselButtonText)
      for(let cardbutton of this.answerCarouselGoogle.cardbuttons){
        cardbutton.text = cardbutton.text.replace(illegalExp, "").trim()
        cardbutton.imgurl = cardbutton.imgurl.replace(illegalExp, "").trim()
        cardbutton.imgname = cardbutton.imgname.replace(illegalExp, "").trim()
        cardbutton.title = cardbutton.title.replace(illegalExp, "").trim()
      }
      return this.answerCarouselGoogle
    },
    setValue(selectedResponseMessage){
      this.responseMessage.cardTitle = selectedResponseMessage.data.carouseltitle
      this.responseMessage.carouselButtonText = selectedResponseMessage.data.cardbuttons
    },
    initializeValue(){
      this.responseMessage.cardTitle=''
      this.responseMessage.carouselButtonText = [new CarouselButtonText()]
      this.answerCarouselGoogle=null
    },
    validationCheck() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadReplyImageUrlFiles(index) {
      this.$refs.replyImageUrlFiles[index].loadImages()
    }
  }
}
</script>
