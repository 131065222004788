<template>
  <div>
    <div class="col-md-4 mb-2">
      <div class="input-group mb-3">
        <input type="text" class="form-control" ref="agentName" v-model="searchingAgentName" :placeholder="$t('agent.inputName')"
               @input="onChangedSearchingName" @keyup.enter="selectAgent" @keyup.up="onArrowUp" @keyup.down="onArrowDown"/>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" @click="toggleSortType">{{ sortType }}</button>
          <button class="btn btn-outline-secondary" type="button" @click="toggleSortOption">{{ sortOption }}</button>
        </div>
      </div>
    </div>
    <div class="agent">
      <div class="col-md-4 mb-2" v-for="agent in controller.displayAgents">
        <a v-bind:href="controller.url + agent.id" class="btn btn-lg btn-block"
          :class="{'btn-primary':isAgentIdEqual(agent),'btn-outline-secondary':!isAgentIdEqual(agent),'selected-agent':isAgentIdEqual(agent, controller.selectedAgent.id)}">
          <span v-if="isAgentIdEqual(agent)"><i class="fas fa-check"></i></span>
          {{ agent.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import {str_z2h} from "../util/string-z2h";
  export default {
    props:['controller'],
    data() {
      const constants = {
        SORTING_TYPE_ID:this.$t('agent.orderById'),
        SORTING_TYPE_NAME:this.$t('agent.orderByName'),
        SORTING_OPTION_DESC:this.$t('category.desc'),
        SORTING_OPTION_ASC:this.$t('category.asc'),
      }
      return {
        constants: constants,
        searchingAgentName:null,
        sortType:　constants.SORTING_TYPE_ID,
        sortOption: constants.SORTING_OPTION_ASC
      }
    },
    methods:{
      selectAgent(){
        this.controller.switchToAgent()
      },
      onArrowUp(){
        this.controller.selectAgent(-1)
      },
      onArrowDown(){
        this.controller.selectAgent(1)
      },
      isAgentIdEqual(agent, agentId=this.controller.agentId){
        return agent.id === agentId
      },
      toggleSortType(){
        this.sortType = this.sortType === this.constants.SORTING_TYPE_ID ?
          this.constants.SORTING_TYPE_NAME : this.constants.SORTING_TYPE_ID
        this.controller.toggleSort(true)
      },
      toggleSortOption(){
        this.sortOption = this.sortOption === this.constants.SORTING_OPTION_ASC ?
          this.constants.SORTING_OPTION_DESC : this.constants.SORTING_OPTION_ASC
        this.controller.toggleSort(false)
      },
      onChangedSearchingName(){
        if(this.searchingAgentName)
          this.controller.filterAgent(str_z2h(this.searchingAgentName.toLowerCase()))
        else
          this.controller.filterAgent()
      }
    },
    mounted(){
      this.$nextTick(()=>{
        this.$refs.agentName.focus()
      })
    }
  }
</script>
<style scoped>
  .selected-agent{
    border:3px solid #b9bbbe;
  }
  .agent{
    overflow-y:auto;
    max-height: 780px;
  }
  @media (max-width: 500px) {
    .agent{
      max-height: 615px;
    }
  }
</style>
