<template>
  <div v-if="controller.notifications.length > 0">
    <div class="alert alert-info alert-dismissible fade show text-center p-0"
         v-for="(notification, idx) in controller.notifications">
      <div class="text-break mr-5" v-html="notification"></div>
      <button class="close p-0"
              v-if="idx == 0"
              @click="hideNotification">
        <i class="far fa-times-circle"></i>
      </button>
    </div>
  </div>
</template>

<script>
import csrfToken from '../util/csrf-token'
export default {
  props: ['controller'],
  methods: {
    hideNotification() {
      this.controller.hideNotification(
      csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    }
  }
}
</script>
