<template>
<div class="platform-filter-area filter-area">
  <div class="select-text">
    {{ $t('history.selectPlatform') }}<span
      class="float-right pr-2 pointer-cursor"
      @click="foldPlatformArea">
      <span v-show="isFolded">
        <i class="fas fa-plus-circle"></i>
      </span>
      <span v-show="!isFolded">
        <i class="fas fa-minus-circle"></i>
      </span>
    </span>
  </div>
  <div
    class="content"
    :class="{hide: isFolded}">
    <div
      class="platform-filter">
      <span v-for="(icon, index) in icons" :key="index"
        :id="icon.name"
        class="pointer-cursor hover-shadow"
        :class="{selected: selectedPlatforms.includes(icon.name)}"
        @click="togglePlatformSelection(icon.name)">
        <b-tooltip
          :target="icon.name"
          :title="$t(icon.translation)"
          :delay="{show: 800, hide: 0}">
        </b-tooltip>
        <i class="fa-2x" :class="icon.faIcon"></i>
      </span>
    </div>
    <div class="toggle-button">
      <b-btn
        tabindex="-1"
        variant="outline-primary"
        @click="$emit('toggle-all-platform-selection')">
        {{ $t('history.toggleAll') }}
      </b-btn>
    </div>
  </div>
</div>
</template>

<script>
  export default {
  props: [
    'selectedPlatforms', 'icons'
  ],
  data() {
    return {
      isFolded: false
    }
  },
  methods: {
    togglePlatformSelection(platformName) {
      this.$emit('toggle-platform-selection', platformName)
    },
    foldPlatformArea() {
      // プラットフォーム選択エリアを閉じる
      this.isFolded = !this.isFolded
    }
  }
}
</script>
