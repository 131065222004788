<template>
  <div class="answer-link">
    <div>{{ $t('answer.cardButtons') }}</div>
    <div @keypress.enter.prevent="">
      <b-form-input
        :placeholder="$t('answer.enterUrlTitle')"
        v-model="responseMessage.linkTitle"
        @input="validationCheck"
        class="mb-1"
        type="text"
        :state="!$v.responseMessage.linkTitle.$error"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="$v.responseMessage.linkTitle.$error">
        {{ $t('webhook.required') }}
      </b-form-invalid-feedback>
      <b-form-input
        class="mb-2"
        :placeholder="$t('answer.enterURL')"
        @input="validationCheck"
        v-model="responseMessage.link"
        type="url"
        :state="!$v.responseMessage.link.$error"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="$v.responseMessage.link.$error">
        {{ $t('webhook.required') }} &
        {{ $t('answer.invalidURL') }}
      </b-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
import {required, url} from 'vuelidate/lib/validators'
import {LinkData} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

  export default {
  data() {
    return {
      responseMessage: {
        linkTitle:'',
        link:''
      },
      answerLink:null,
      required:null
    }
  },
  validations: {
    responseMessage: {
      linkTitle: {
        required
      },
      link: {
        required,
        url
      }
    }
  },
  methods:{
    inputValue(){
      this.answerLink = new LinkData(
        this.responseMessage.linkTitle.replace(illegalExp, "").trim(),
        this.responseMessage.link.replace(illegalExp, "").trim())

      return this.answerLink
    },
    setValue(selectedResponseMessage){
      this.responseMessage.linkTitle = selectedResponseMessage.data.linktitle
      this.responseMessage.link = selectedResponseMessage.data.link
    },
    initializeValue(){
      this.responseMessage.linkTitle=''
      this.responseMessage.link=''
      this.answerLink=null
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    }
  }
}
</script>
