<template>
  <div class="answer-tableCard">
    <div class="word-editor mb-2">
      <b-card no-body>
        <b-tabs
          small card
          v-model="tabIndex"
          :no-fade="true">
          <b-tab :title=" $t('answer.content') ">
            <div @keypress.enter.prevent="">
              <div class="input-group mb-1">
                <b-form-input
                  :placeholder="$t('answer.enterImageURL')"
                  v-model="responseMessage.imageUrl"
                  type="url"
                  @input="validationCheck"
                  :state="!$v.responseMessage.imageUrl.$error"
                  ref="responseMessageImageUrl"
                >
                </b-form-input>
                <div class="input-group-append">
                  <a
                    class="btn btn-info"
                    :class="{ disabled: !responseMessage.imageUrl || $v.responseMessage.imageUrl.$error }"
                    :href="responseMessage.imageUrl" target="_blank"
                    :title="$t('answer.preView')"
                  ><span class="fas fa-eye mt-1"/>
                  </a>
                  <a class="btn btn-primary text-white" v-b-tooltip.hover
                     :title="$t('answer.browseImage')"
                     @click="loadImageUrlFiles">
                    <i class="far fa-images"></i>
                  </a>
                </div>
                <b-form-invalid-feedback v-if="$v.responseMessage.imageUrl.$error">
                  {{ $t('answer.invalidURL') }}
                </b-form-invalid-feedback>
              </div>
              <file-contents ref="imageUrlFiles"
                             :img-url-el="$refs.responseMessageImageUrl"
                             @img-url-model="responseMessage.imageUrl = $event"></file-contents>
              <b-form-input
                :placeholder="$t('answer.enterImageTitle')"
                :disabled="responseMessage.imageUrl == false"
                v-model="responseMessage.imageName"
                class="mb-2"
                type="text">
              </b-form-input>
              <b-form-input
                :placeholder="$t('answer.enterTableTitle')"
                @input="validationCheck"
                v-model="responseMessage.tableTitle"
                class="mb-1"
                type="text"
                :state="!$v.responseMessage.tableTitle.$error"
              >
              </b-form-input>
              <b-form-invalid-feedback v-if="$v.responseMessage.tableTitle.$error">
                {{ $t('webhook.required') }}
              </b-form-invalid-feedback>
              <b-form-input
                :placeholder="$t('answer.enterTableSubtitle')"
                v-model="responseMessage.tableSubTitle"
                class="mb-1"
                type="text">
              </b-form-input>
            </div>
          </b-tab>
          <b-tab
            :disabled="responseMessage.tableTitle == false"
            :title=" $t('answer.table') ">
            <div>{{ this.responseMessage.tableRow.length }}行{{ this.responseMessage.tableHeader.length }}列</div>
            <div @keypress.enter.prevent="">
              <div class="section-body scroll mb-1">
                <div class="table-responsive">
                  <table class="table table-hover" style="table-layout:fixed;">
                    <!-- TableのHeader部分 -->
                    <thead>
                      <tr>
                        <th style="width:50px;"></th>
                        <th v-for="reply in responseMessage.tableHeader"
                            style="width:200px;">
                          <b-form-input
                            :placeholder="$t('answer.header')"
                            v-model="reply.header"/>
                        </th>
                      </tr>
                    </thead>
                    <!-- Tableのbody部分・行入れ替え処理 -->
                    <draggable
                      tag="tbody"
                      v-model="responseMessage.tableRow">
                      <!-- 行繰り返し -->
                      <tr v-for="(row, index) in responseMessage.tableRow">
                        <!-- 行ごとの削除ボタン -->
                        <th>
                          <button
                            class="btn btn-danger"
                            :disabled="responseMessage.tableRow.length == 1"
                            @click.prevent="deleteTableRow(index)">
                            <i class="fa fa-minus"/>
                          </button>
                        </th>
                        <!-- 列繰り返し -->
                        <td v-for="(column) in row.cells">
                          <b-form-input v-model="column.text"/>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>
              <div class="mb-2">
                <button
                  class="btn btn-success"
                  @click.prevent="addTableRow">
                  <i class="fa fa-plus"/>
                  行
                </button>
                <button
                  class="btn btn-success"
                  @click.prevent="addTableColumn">
                  <i class="fa fa-plus"/>
                  列
                </button>
                <button
                  class="btn btn-danger"
                  :disabled="responseMessage.tableHeader.length == 1"
                  @click.prevent="deleteTableColumn(index)">
                  <i class="fa fa-minus"/>
                  列
                </button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, url} from 'vuelidate/lib/validators'
import {TableData, TableHeader, TableCellText, TableColumn} from '../../model/intent'
import draggable from 'vuedraggable'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    draggable, FileContents
  },
  data() {
    return {
      responseMessage: {
        imageUrl:'',
        imageName:'',
        tableTitle:'',
        tableSubTitle:'',
        tableHeader: [],
        tableNewColumn: [],
        tableRow: []
      },
      answerTableCard:null,
      tabIndex: 0,
      required:null
    }
  },
  validations: {
    responseMessage: {
      imageUrl: {
        url
      },
      tableTitle: {
        required
      }
    }
  },
  methods:{
    addTableColumn(){
      this.responseMessage.tableHeader.push(new TableHeader())
      for(let i = 0; i < this.responseMessage.tableRow.length; i++){
        this.responseMessage.tableRow[i].cells.push(new TableCellText())
      }
    },
    deleteTableColumn(){
      this.responseMessage.tableHeader.splice(-1, 1)
      for(let i = 0; i < this.responseMessage.tableRow.length; i++){
        this.responseMessage.tableRow[i].cells.splice(-1, 1)
      }
    },
    addTableRow(){
      this.responseMessage.tableNewColumn = []
      for(let i = 0; i < this.responseMessage.tableHeader.length; i++){
        this.responseMessage.tableNewColumn.push(new TableCellText())
      }
      this.responseMessage.tableRow.push(new TableColumn(this.responseMessage.tableNewColumn))
    },
    deleteTableRow(index){
      this.responseMessage.tableRow.splice(index, 1)
    },
    inputValue(){
      this.answerTableCard = new TableData(
        this.responseMessage.imageUrl.replace(illegalExp, "").trim(),
        this.responseMessage.imageName.replace(illegalExp, "").trim(),
        this.responseMessage.tableTitle.replace(illegalExp, "").trim(),
        this.responseMessage.tableSubTitle.replace(illegalExp, "").trim(),
        this.responseMessage.tableHeader,
        this.responseMessage.tableRow)
      for(let header of this.answerTableCard.header){
        header.header = header.header.replace(illegalExp, "").trim()
      }
      for(let table of this.answerTableCard.table){
        for(let cell of table.cells){
          cell.text = cell.text.replace(illegalExp, "").trim()
        }
      }
      return this.answerTableCard
    },
    setValue(selectedResponseMessage){
      this.responseMessage.imageUrl = selectedResponseMessage.data.imgurl
      this.responseMessage.imageName = selectedResponseMessage.data.imgname
      this.responseMessage.tableTitle = selectedResponseMessage.data.title
      this.responseMessage.tableSubTitle = selectedResponseMessage.data.subtitle
      this.responseMessage.tableHeader = selectedResponseMessage.data.header
      this.responseMessage.tableRow = selectedResponseMessage.data.table
    },
    initializeValue(){
      this.responseMessage.imageUrl = ''
      this.responseMessage.imageName = ''
      this.responseMessage.tableTitle = ''
      this.responseMessage.tableSubTitle = ''
      this.responseMessage.tableHeader = [new TableHeader(), new TableHeader()]
      this.responseMessage.tableNewColumn = []
      this.responseMessage.tableRow = [new TableColumn([new TableCellText(), new TableCellText()])]
      this.answerTableCard=null
      this.tabIndex=0
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadImageUrlFiles() {
      this.$refs.imageUrlFiles.loadImages()
    }
  }
}
</script>
