import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import {UserMetaEditSchemaController} from './controller/user-meta-edit-schema-controller'

import UserMetaEditSchema from './components/UserMetaEditSchema.vue'
import ProgressModal from './components/ProgressModal.vue'
import DeleteConfirmModal from './components/DeleteConfirmModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import ModalFooter from './components/ModalFooter.vue'


export const appUserMetaEditSchema = (i18n) => {
  /* コントローラ */
  const controller = new UserMetaEditSchemaController(
    (new UrlBuilder(API_URL)).build(),
    window.i18nContext,
    window.requestContext
  )

  Vue.component('user-meta-edit-schema', UserMetaEditSchema)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('modal-footer', ModalFooter)
    
  new Vue({
    el: '#app-user-meta-edit-schema',
    i18n: i18n,
    data: {
        controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    }
  })
}
