import { DirectedGraph } from '@joint/layout-directed-graph';
import { START_NODE_ID, NODE_TYPE_QUESTION } from './const'

/**
 * エレメントの位置を整理する
 */
const layoutElements = (graph) => {
  DirectedGraph.layout(graph, {
    setLinkVertices: true,
    rankSep: 60,
    rankDir: "LR"
  });
}

/**
 * スタートのノード以外で一番大きいグループの親ノードを探す
 */
const findBiggestParent = (links, nodes) => {
  let biggestParent = null

  // 手動でnodesとlinkから最大の親を探索する
  // linksからターゲットに指定されているノードを探し対象から除外する。
  const excludedNodeIds = []
  links.forEach(link => {
    // startNodeからのリンクは除いて考える
    const sourceId = link.attributes.source.id
    if (sourceId === START_NODE_ID) return

    const targetId = link.attributes.target.id
    // linkが向かっている先のノードは先頭ではないため取り除く
    excludedNodeIds.push(targetId)
  })
  const questionNodes = nodes.filter(node =>
    !excludedNodeIds.includes(node.id)
    && node.id != START_NODE_ID && node.attributes.type === NODE_TYPE_QUESTION
  )
    .sort((a, b) => a.id > b.id ? 1 : -1)

  // 精密に最大のノードを求めることはせず、先頭になりうる質問の中で最初のノードを親ノードにする
  biggestParent = questionNodes[0]

  return biggestParent
}

export { layoutElements, findBiggestParent }
