<template>
  <div class="answer-suggestionChips">
    <div>{{ $t('answer.content') }}:</div>
    <draggable
      v-model="responseMessage.suggestionChips"
      handle=".handle">
      <div v-for="(reply, index) in responseMessage.suggestionChips">
        <div class="input-group mb-1">
          <div @keypress.enter.prevent="addSuggestionChip">
            <b-form-input
              @input="validationCheck"
              v-model="reply.text"
              :state="!$v.responseMessage.suggestionChips.$each[index].text.$error"/>
            <b-form-invalid-feedback v-if="$v.responseMessage.suggestionChips.$each[index].text.$error">
              {{ $t('webhook.required') }} &
              {{ $t('answer.maxTxtLen', {counter: $v.responseMessage.suggestionChips.$each[index].text.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </div>
          <div class="input-group-append">
            <button
              class="btn btn-danger"
              :disabled="responseMessage.suggestionChips.length == 1"
              @click.prevent="deleteSuggestionChip(index)">
              <i class="fa fa-minus"/>
            </button>
          </div>
          <span class="handle handle-btn">
            <i class="fas fa-arrows-alt"/>
          </span>
        </div>
      </div>
    </draggable>
    <div class="mb-2">
      <button
        class="btn btn-success"
        :disabled="this.responseMessage.suggestionChips.length == 8"
        @click.prevent="addSuggestionChip">
        <i class="fa fa-plus"/>
      </button>
      {{ this.responseMessage.suggestionChips.length + " / 8" }}
    </div>
  </div>
</template>

<script>
import {required, maxLength} from 'vuelidate/lib/validators'
import {SuggestionChipsData, ReplyText} from '../../model/intent'
import draggable from 'vuedraggable'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      responseMessage: {
        suggestionChips:[new ReplyText()]
      },
      answerSuggestionChips:null
    }
  },
  validations: {
    responseMessage: {
      suggestionChips: {
        $each: {
          text: {
            required,
            maxLength: maxLength(20)
          }
        }
      }
    }
  },
  methods:{
    addSuggestionChip(){
      this.responseMessage.suggestionChips.push(new ReplyText())
      // suggestionChips欄が増える度にバリデーションチェック
      this.validationCheck()
    },
    deleteSuggestionChip(index){
      this.responseMessage.suggestionChips.splice(index, 1)
      // suggestionChips欄が減る度にバリデーションチェック
      this.validationCheck()
    },
    inputValue(){
      this.answerSuggestionChips = new SuggestionChipsData(
        this.responseMessage.suggestionChips)
      for(let answerSuggestion of this.answerSuggestionChips.replies){
        answerSuggestion.text = answerSuggestion.text.replace(illegalExp, "").trim()
      }
      return this.answerSuggestionChips
    },
    setValue(selectedResponseMessage){
      this.responseMessage.suggestionChips = selectedResponseMessage.data.replies
    },
    initializeValue(){
      this.responseMessage.suggestionChips = [new ReplyText()],
      this.answerSuggestionChips=null
    },
    validationCheck() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    }
  }
}
</script>
