<template>
  <div :class="containerClass" style="border-color: #ccc;">
    <div>{{ conditionDescText }}</div>
    <div v-if="!condition.always && condition.ands.length > 0">
      <label>{{ andLabel }}</label>
      <div>
        <condition-preview v-for="(andCondition, idx) in condition.ands" :key="idx"
          :condition="andCondition"
          :andLabel="andLabel"
          :isAnd="true"
        >
        </condition-preview>
      </div>
    </div>
    <div v-if="!isAnd">{{ conditionReturnText }}</div>
  </div>
</template>
<script>
export default {
  name: "ConditionPreview",
  props: ["condition", "isAnd", "andLabel"],
  data() {
    const self = this
    return {
      i18nParse: (...args) => {
        return self.$t(...args)
      }
    }
  },
  computed: {
    containerClass() {
      return {
        "mx-1": true,
        "mb-3": !this.isAnd,
        "px-2": true,
        "py-1": true,
        "border": true
      }
    },
    conditionDescText() {
      const condition = this.condition
      return condition.getDescText(this.i18nParse)
    },
    conditionReturnText() {
      const condition = this.condition
      return condition.getReturnText(this.i18nParse)
    }
  }
}
</script>
