<template>
  <!-- カード形式の回答表示用コンポネント -->
  <div class="mb-1">
    <div class="message-bg-grey">
      <video controls :poster="chatItem.previewSrc" width="100%" height="200">
        <source :src="chatItem.src" />
      </video>
    </div>

    <chat-item-bottom-detail
      :chat-item="chatItem"
      :redirector-access-logs="redirectorAccessLogs"
    />
  </div>
</template>

<script>
export default {
  props: ["chatItem", "redirectorAccessLogs"],
};
</script>
