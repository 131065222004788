<template>
  <li>
    <div
      class="label my-1 p-1"
      :class="isSelected"
      @click="selectScenario"
      @dblclick="editScenario"
      @keyup.delete="deleteScenario"
      tabindex="-1">
      {{ scenario.name }}
    </div>
  </li>
</template>

<script>
export default {
  props: [
    'scenario',
    'selectedScenario',
    'currentDisplayedScenario',
    'userType'
  ],
  computed: {
    isSelected() {
      if (!this.scenario || !this.currentDisplayedScenario) {
        return {selected: false}
      }
      return {selected: this.scenario.id == this.currentDisplayedScenario.id}
    },
  },
  methods: {
    selectScenario() {
      this.$emit('select-scenario', this.scenario)
    },
    editScenario() {
      if (this.userType !='viewer') {
        this.$emit('edit-scenario', this.scenario)
      }
    },
    deleteScenario() {
      if (this.userType !='viewer') {
        this.$emit('delete-scenario', this.scenario)
      }
    },
  }
}
</script>

