<template>
  <b-modal
    id="operator-setting-modal"
    :title="$t('history.OperatorSetting')"
    header-class="bg-info text-light"
    no-close-on-backdrop
    scrollable
    size="lg"
    @show="selectedOperatorUserId = initialOperatorUserId"
    @ok="
      $emit('on-update-operator-setting', {
        operatorUserId: selectedOperatorUserId,
      })
    "
  >
    <div>
      <b-form-group
        label-cols="12"
        :label="$t('history.SelectOperator')"
        label-size="lg"
        label-class="font-weight-bold pt-0"
      >
        <!-- PCの表示 -->
        <b-form-group
          class="mb-2 field sp-hidden"
          :label="$t('history.Operator')"
          label-for="operator-filter"
          label-cols="4"
          content-cols="7"
          label-align="left"
        >
          <div
            :style="{
              maxHeight: '10vh',
              overflowY: 'scroll',
              border: '1px solid #ced4da',
              borderRadius: '8px',
              padding: '0 10px 0 10px',
            }"
          >
            <b-form-radio-group
              v-model="selectedOperatorUserId"
              :options="operatorOptions"
              value-field="id"
              text-field="name"
              stacked
            />
          </div>
        </b-form-group>

        <!-- SPの表示 -->
        <b-form-group
          class="mb-2 pc-hidden"
          :label="$t('history.Operator')"
          label-for="operator-filter"
          label-align="left"
        >
          <div
            :style="{
              border: '1px solid #ced4da',
              borderRadius: '8px',
              padding: '0 10px 0 10px',
            }"
          >
            <b-form-radio-group
              v-model="selectedOperatorUserId"
              :options="operatorOptions"
              value-field="id"
              text-field="name"
              stacked
            />
          </div>
        </b-form-group>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'OperatorSettingModal',
  props: ['operators', 'initialOperatorUserId'],
  data() {
    return {
      // 担当者。単一選択
      selectedOperatorUserId: null,
    }
  },
  computed: {
    operatorOptions() {
      return [{ id: null, name: this.$t('history.Unset') }, ...this.operators]
    },
  },
  methods: {},
}
</script>

<style scoped>
.field {
  margin-left: 0;
  margin-right: 0;
  justify-content: flex-end;
}
</style>
