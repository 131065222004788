<template>
  <b-modal
    id="edit-note-modal"
    :title="$t('history.editNote')"
    header-class="bg-info text-light"
    no-close-on-backdrop
    scrollable
    size="lg"
    @ok="$emit('on-submit-note', { note })"
    @show="setOriginalValue"
  >
    <div>
      <b-form-group class="mb-3">
        <b-form-textarea v-model="note" rows="8" />
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'EditNoteModal',
  props: ['originalValue'],
  data() {
    return {
      // 編集用の変数
      note: '',
    }
  },
  methods: {
    setOriginalValue() {
      this.note = this.originalValue
    },
  },
}
</script>
