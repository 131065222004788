import axios from "axios";

/**
 * RPCクライントのラッパー
 * API呼び出しの結果からモデルのインスタンスを生成します
 */
class RPC {
  constructor(endpoint, model = null) {
    this.endpoint = endpoint;
    this.model = model;
  }

  /**
   * 指定されたエンドポイントを呼び出す
   * モデルのインスタンスを返す
   */
  call(csrfToken = null, body = {}, options = {}) {
    let sendOptions = {};
    Object.assign(sendOptions, options, {
      headers: { "X-CSRFToken": csrfToken },
    });

    return new Promise((resolve, reject) => {
      axios
        .post(this.endpoint, body, sendOptions)
        .then(({ data }) => {
          if (this.model === null) {
            resolve(data);
            return;
          }

          if (data === null) {
            // データがnullならそのまま返す
            resolve(null);
          } else if (Array.isArray(data)) {
            // 配列の場合
            resolve(data.map(this.model.fromData));
          } else {
            // オブジェクト単体の場合
            resolve(this.model.fromData(data));
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }
}

export { RPC };
