<template>
  <div class="session-id-filter-area filter-area">
    <div class="select-text">
      {{$t('history.filterBySessionId')}}
      <span
        class="float-right pr-2 pointer-cursor"
        @click="foldSessionIdArea">
        <span v-show="isFolded">
          <i class="fas fa-plus-circle"></i>
        </span>
        <span v-show="!isFolded">
          <i class="fas fa-minus-circle"></i>
        </span>
      </span>
    </div>
    <div class="container content mt-2 mb-2" :class="{hide: isFolded}">
      <div class="row border-0">
        <div class="col text-center">
          <b-button @click="openSessionIdSearchModal">
            {{$t('history.searchBySessionId')}}
          </b-button>
        </div>
      </div>
    </div>
    <b-modal
      ref="searchSessionId"
      :title="$t('history.searchBySessionId')"
      header-class="bg-info text-light"
      no-close-on-backdrop
      :ok-only="true"
      :ok-title="$t('history.close')"
      ok-variant="secondary"
      scrollable
      @ok="$refs.searchSessionId.hide()">
      <div>
        <div class="content mt-2 mb-1">
          <div class="input-group">
            <b-form-input
              v-model="searchingString"
              type="search"
              :placeholder="$t('history.sessionId')"
              v-bind:class="{'is-invalid':resultsCheck && isFirst}"
              aria-describedby="inputWordCheck">
            </b-form-input>
            <div class="input-group-append">
              <b-button :disabled="!isValidSearchingString" @click="searchSessionId" variant="primary">
                <span><i class="fas fa-search"></i></span>
              </b-button>
            </div>
            <b-form-invalid-feedback id="inputWordCheck">{{ $t('history.noDateFound') }}</b-form-invalid-feedback>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-3" v-if="sessionIdIsLoad">
          <b-spinner label="Loading..." style="width: 3rem; height: 3rem;" variant="primary"></b-spinner>
        </div>
        <div v-else>
          <div v-if="sessionIdSearchResult.length">
            <h5 class="mb-2"><u>{{$t('history.searchResult')}}:</u></h5>
            <div v-for="(date, index) in sessionIdSearchResult">
              <hr v-if="index!==0">
              <p><a :href="getSessionConUrl(date)" target="_blank">{{date}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "ConversationHistorySearchFilterSession",
    props: ['sessionIdSearchResult', 'sessionIdIsLoad'],
    data() {
      return {
        isFolded: false,
        searchingString: null,
        resultsCheck: false,
        isFirst: false
      }
    },
    computed: {
      isValidSearchingString(){
        return this.searchingString && this.searchingString.match(/[\S]/)!=null
      },
    },
    methods: {
      foldSessionIdArea() {
        this.isFolded = !this.isFolded
      },
      openSessionIdSearchModal(){
        this.isFirst = false
        this.searchingString = null
        this.$emit('reset-session-id-search-result')
        this.$refs.searchSessionId.show()
      },
      searchSessionId(){
        this.searchingString = this.searchingString.trim()
        this.$emit('filter-search-session-id', this.searchingString)
        this.isFirst = true
      },
      getSessionConUrl(date){
        return '/history/sessions?session_id=' + this.searchingString + '&date=' + date
      },
    },
    watch: {
      sessionIdSearchResult: function(){
        if(this.sessionIdSearchResult.length === 0 && !this.sessionIdIsLoad){
          this.resultsCheck = true
        }else{
          this.resultsCheck = false
        }
      }
    },
  }
</script>

<style scoped>

</style>
