import { I18n } from '../util/i18n'
import axios from 'axios'
import { RestApi } from '../util/rest-api'
import { WebhookLog } from '../model/webhook-log'
import {Dataset} from "../model/intent-check";
import {TestChatController} from "./test-chat-controller";

class WebhookLogController {
  constructor(apiUrl, i18nContext, requestContext, available_platform) {
    this.apiUrl = apiUrl
    this.webhookLogUrl = apiUrl.WebhookLog
    this.logApi = new RestApi(apiUrl.WebhookLog, WebhookLog)

    this.i18n = new I18n(i18nContext)
    this.params = { page: 1 }
    this.logs = []
    this.num_page = 0
    this.session_id = ''
    this.status_code = []
    this.date = {
        from_date:requestContext.from_date,
        to_date: requestContext.to_date
      }
    this.supportedLanguages = []
    this.datasetApi = new RestApi(apiUrl.dataset, Dataset)
    this.testChatPlatform = requestContext.test_chat_platform
    this.testChatController = null
    this.availablePlatform = available_platform
  }

  ready() {
    this.loadWebhookLog()
    this.loadDataset()
  }

  isViewer() {
    return this.userType == 'viewer'
  }

  loadWebhookLog() {
    this.logs = []
    let params = ''
    if(!!this.date.from_date){
      params += '&start_date=' + this.date.from_date
    }
    if(!!this.date.to_date){
      params += '&end_date=' + this.date.to_date
    }
    if(!!this.session_id){
      params += '&session_id=' + this.session_id
    }
    if(this.status_code.length>0){
      params += '&status_code=' + this.status_code
    }
    axios.get(this.webhookLogUrl + '?page=' + this.params.page + params )
      .then((instance) => {
        this.num_page = instance.data.num_page
        this.logs = instance.data.results
      })
      .catch(error => console.log(error))
  }

  loadDataset(){
    this.datasetApi.list()
      .then((instance) => {
        this.supportedLanguages = this.supportedLanguagesArray(instance)
        this.testChatController = new TestChatController(this.apiUrl, this.supportedLanguages, this.i18n, this.availablePlatform, this.testChatPlatform)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  supportedLanguagesArray(instances){
    let result = []
    for(const instance of instances){
      result[instance.language_code] = instance.language_code
    }
    return result
  }
}

export {
  WebhookLogController
}
