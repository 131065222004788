<template>
  <div class="role">
    <div class="row">
      <div class="col-md-12">
        <div class="m-1 p-1">
          <button
            title="Add New"
            class="btn btn-primary btn-sm mb-1"
            @click="addNew">
            {{ $t('userManagement.addNewRole') }}
          </button>
          <div class="table-responsive">
            <table class="table table-hover text-center">
              <thead class="table-info">
                <tr>
                  <th>{{ $t('userManagement.roleName') }}</th>
                  <th>{{ $t('userManagement.agentNamePermission') }}</th>
                  <th>{{ $t('userManagement.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="role in controller.roles">
                  <td>{{ role.name }}</td>
                  <td>
                    <span v-for="(agent_and_per, index) in role.data">
                      {{controller.agents_dict[agent_and_per.agent_id]}} ({{agent_and_per.permission}})
                      <span v-if="role.data.length > (index+1)">, </span>
                    </span>
                  </td>
                  <td>
                    <button
                      title="Edit"
                      class="btn btn-info btn-sm" @click="editRole(role)">
                      <i class="fas fa-edit"></i>
                    </button> |
                    <button
                      title="Delete"
                      class="btn btn-danger btn-sm" @click="deleteRole(role)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--Add new role modal-->
    <b-modal
      :title="modalTitle"
      ref="rolemodal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop>
      <div>
        <form @submit.prevent="addNewOk">
          <div>
            {{ $t('userManagement.roleName') }}:
          </div>
          <b-form-input
            type="text"
            ref="focusInput"
            v-model="roleModel.name"
            maxlength="50">
          </b-form-input>
          <div>
            {{ $t('userManagement.roles') }}:
            <hr class="mt-0 mb-0">
          </div>
          <div class="row mb-1">
            <div class="col">{{ $t('userManagement.agent') }}</div>
            <div class="col">{{ $t('userManagement.permission') }}</div>
          </div>
          <draggable v-model="roleModel.data" handle=".handle">
            <div v-for="(row, index) in roleModel.data">
              <b-input-group>
                <b-form-select
                  v-model="row.agent_id"
                  :options="controller.agentOptions"
                  class="mb-1 mr-1" style="width: 35px">
                </b-form-select>
                <b-form-select
                  v-model="row.permission"
                  :options="permissions"
                  class="mb-1 mr-1" style="width: 120px">
                </b-form-select>
                <div class="input-group-append">
                  <button
                     class="btn btn-danger"
                     style="height: 38px"
                     :disabled="roleModel.data.length === 1"
                     @click.prevent="deleteRoleData(index)">
                     <i class="fa fa-minus"></i>
                  </button>
                </div>
                <span class="handle handle-btn" style="height: 38px"><i class="fas fa-arrows-alt"></i></span>
              </b-input-group>
            </div>
          </draggable>
        </form>
        <div class="mb-2">
          <button
            class="btn btn-success"
            @click="addRoleData">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <modal-footer
        slot="modal-footer"
        :okDisabled="false"
        @ok="addNewOk"
        @cancel="addNewCancel">
      </modal-footer>
    </b-modal>
    <!--Delete role confirmation modal-->
    <b-modal
      :title="$t('userManagement.deleteRole')"
      ref="deleteConfirmModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop>
      <div class="text-center">
        {{ $t('userManagement.deleteRoleAlertMsg', {roleName: delete_role.name}) }}
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="deleteRoleConfirm"
        @cancel="deleteRoleCancel">
      </modal-footer>
    </b-modal>
    <error-modal ref="errorModal" :message="message"/>
    <progress-modal ref="progressModal" :message="message"/>
  </div>
</template>

<script>
  import {Role, AccessPermissionData} from "../model/role"
  import csrfToken from '../util/csrf-token'
  import {I18n} from "../util/i18n"
  export default {
    props:['controller'],
    data(){
      return{
        roleModel:{},
        delete_role: {},
        message:'',
        modalTitle:'',
        permissions:[
          {value: 'admin', text: 'Admin'},
          {value: 'editor', text: 'Editor'},
          {value: 'viewer', text: 'Viewer'},
        ]
      }
    },
    methods:{
      addNew(){
        this.roleModel = new Role()
        this.roleModel.data = [new AccessPermissionData()]
        this.modalTitle = this.$t('userManagement.addNewRole')
        this.$refs.rolemodal.show()
      },
      addNewOk(){
        this.$refs.rolemodal.hide()
        let result, message, promise
        [result, message, promise] = this.controller.saveRole(
          csrfToken.getCsrfTokenFromCookie(document.cookie), this.roleModel)
        if (!result) {
          this.showError(this.i18n.t('feedback.error'))
        }else{
          this.showProgress(message)
          let self = this
          promise
          .then(()=>{
            this.roleModel = {}
            this.hideProgress()
          })
          .catch(function(error){
            this.roleModel = {}
            self.hideProgress()
            self.showError(error.response.data[0])
          })
        }
      },
      addNewCancel(){
        this.roleModel = {}
        this.$refs.rolemodal.hide()
      },
      addRoleData(){
        this.roleModel.data.push(new AccessPermissionData())
      },
      deleteRoleData(index){
        this.roleModel.data.splice(index, 1)
      },
      editRole(role){
        this.modalTitle = this.$t('userManagement.editRole')
        const roleObject = new Role()
        Object.assign(roleObject, role)
        this.roleModel = roleObject
        this.roleModel.data = []
        role.data.forEach(roleData => {
          const permission = new AccessPermissionData()
          Object.assign(permission, roleData)
          this.roleModel.data.push(permission)
        })
        this.$refs.rolemodal.show()
      },
      deleteRole(role){
        this.delete_role = role
        this.$refs.deleteConfirmModal.show()
      },
      deleteRoleCancel(){
        this.delete_role = {}
        this.$refs.deleteConfirmModal.hide()
      },
      deleteRoleConfirm(){
        this.$refs.deleteConfirmModal.hide()
        let result, message, promise
        [result, message, promise] = this.controller.deleteRole(
          csrfToken.getCsrfTokenFromCookie(document.cookie), this.delete_role)
        if (!result) {
          this.showError(this.i18n.t('feedback.error'))
        }else{
          this.showProgress(message)
          let self = this
          promise
          .then(()=>{
            this.delete_role = {}
            this.hideProgress()
          })
          .catch(function(error){
            self.hideProgress()
            this.delete_role = {}
            self.showError(error.response.data[0])
          })
        }
      },
      showError(message) {
        this.message = message
        this.$refs.errorModal.show()
      },
      showProgress(message) {
        this.message = message
        this.$refs.progressModal.show()
      },
      hideProgress() {
        this.$refs.progressModal.hide()
      }
    }
  }
</script>

<style scoped>

</style>
