import axios from 'axios'
import {I18n} from "../util/i18n"
import { RestApi } from '../util/rest-api'
import { IntentLabel } from '../model/intent'
import csrfToken from '../util/csrf-token'

class IntentLabelController {

  constructor(apiUrl, i18nContext, requestContext) {
    this.apiUrl = apiUrl
    this.i18n = new I18n(i18nContext)
    this.language = requestContext.languageCode
    this.intentLabelApi = new RestApi(apiUrl.intentLabel, IntentLabel)
    this.listIntentLabels = []
    this.intentLabelLoader = true
  }

  ready() {
    this.loadIntentLabel()
  }

  loadIntentLabel() {
    this.intentLabelLoader = true
    this.intentLabelApi.list().then(instance => {
      this.intentLabelLoader = false
      this.listIntentLabels = instance
    })
  }

  createIntentLabel(form) {
    const promise = this.intentLabelApi.save(
      form,
      csrfToken.getCsrfTokenFromCookie(document.cookie)
    ).then(response => {
      if(response) {
        this.loadIntentLabel()
      }
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  updateIntentLabel(form) {
    const promise = this.intentLabelApi.update(
      form,
      csrfToken.getCsrfTokenFromCookie(document.cookie)
    ).then(response => {
      if(response) {
        this.loadIntentLabel()
      }
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  deleteIntentLabel(form) {
    const promise = this.intentLabelApi.destroy(
      form,
      csrfToken.getCsrfTokenFromCookie(document.cookie)
    ).then(() => {
      this.loadIntentLabel()
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * Detect locale language
   * @returns {*}
   */
  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }
}

export {
  IntentLabelController
}
