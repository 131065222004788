<template>
  <div class="input-group">
    <v-date-picker
      v-model='searchDate' mode="range" class="flex-grow-1">
      <input
        type='text'
        class="form-control"
        name="search_date"
        slot-scope='{ inputValue, updateValue }'
        readonly="true"
        :value='inputValue'
        :placeholder="$t('history.dateRange')"
        @input='updateValue($event.target.value, { formatInput: true, hidePopover: false })'
        @change='updateValue($event.target.value, { formatInput: true, hidePopover: false })'>
    </v-date-picker>
    <span class="input-group-append">
      <button type="button" class="btn btn-outline-danger"
              :disabled="!searchDate.start && !searchDate.end"
              @click="searchDate = {}"><i class="far fa-times-circle"></i></button>
    </span>
  </div>
</template>

<script>
export default {
  props:['date'],
  data() {
    return {
      searchDate: {
        start: this.getSearchDateFromURLParameter('from_date'),
        end: this.getSearchDateFromURLParameter('to_date')
      }
    }
  },
  watch: {
    'searchDate.start': function (val, oldVal) {
      this.date.from_date = val ? this.parseDate(val) : ""
    },
    'searchDate.end': function (val, oldVal) {
      this.date.to_date = val ? this.parseDate(val) : ""
    }
  },
  methods: {
    parseDate: function (str) {
      let d = new Date(str)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      let year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    },
    getSearchDateFromURLParameter: function (key) {
      let urlParams = new URLSearchParams(window.location.search)
      let urlDate = urlParams.get(key)
      if (urlDate == null || urlDate === '') {
        return null
      }
      else {
        return new Date(urlDate)
      }
    }
  }
}
</script>
