<template>
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col">
        <b-btn class="mr-1" @click="addSpeechContext" variant="success">{{ $t('buttons.add') }}</b-btn>
        <b-btn @click="openImportSpeechContextModal" variant="primary">{{ $t('buttons.import') }}</b-btn>
      </div>
      <div class="col-sm-12 col-md-9 language-change mb-2">
        <button v-for="(language, index) in controller.supportedLanguages" :key="index" :title="$t('languageSelector.english')"
          class="btn btn-primary ml-2"
          :disabled="language === controller.language"
          @click="selectLanguage(language)"
        >
          {{ $t(`languageSelector.${
            ['zh-cn', 'zh-tw'].indexOf(language) !== -1 ?
             (language === 'zh-cn' ? 'zhCn' : 'zhTw') : language
            }`) }}
        </button>
      </div>
    </div>
    <div class="speech-context-table container-fluid px-0" style="height: calc(100vh - 284px); overflow-y: auto;">
      <div class="d-none d-lg-flex row ml-1 mr-1 sticky-top">
        <div class="col-md-4 column border p-2" v-b-popover.hover.top="$t('speechContext.phraseInfo')">
          {{ $t('speechContext.phrase') }}
        </div>
        <div class="col-md-4 column border p-2" v-b-popover.hover.top="$t('speechContext.outputInfo')">
          {{ $t('speechContext.output') }}
        </div>
        <div class="col-md-2 column border p-2" v-b-popover.hover.top="$t('speechContext.boostInfo')">
          {{ $t('speechContext.boost') }}
        </div>
        <div class="col-md-2 column border p-2"></div>
      </div>
      <div>
        <template v-for="ctx in controller.speechContexts">
          <div class="row ml-1 mr-1 border-bottom" :key="ctx.sid">
            <div class="col-md-4 border-left border-right p-2">
              <span class="d-inline-block d-lg-none">{{ $t('speechContext.phrase') }}: </span>{{ ctx.phrase }}
            </div>
            <div class="col-md-4 border-left border-right p-2">
              <span class="d-inline-block d-lg-none">{{ $t('speechContext.output') }}: </span>{{ ctx.output }}
            </div>
            <div class="col-md-2 border-left border-right p-2">
              <span class="d-inline-block d-lg-none">{{ $t('speechContext.boost') }}: </span>{{ ctx.boost }}
            </div>
            <div class="col-md-2 border-left border-right p-2">
              <b-button size="sm" class="mr-1" @click="editSpeechContext(ctx)" variant="success">
                {{ $t('buttons.edit') }}
              </b-button>
              <b-button size="sm" class="mr-1" @click="deleteSpeechContext(ctx)" variant="danger">
                {{ $t('buttons.delete') }}
              </b-button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="row justify-content-center">
      <b-pagination
        v-model="controller.currentPage"
        :total-rows="controller.count"
        per-page="100"
      ></b-pagination>
    </div>
    <b-modal
      v-model="editingSpeechContext"
      :title="$t('speechContext.editSpeechContext')"
      header-bg-variant="success"
      header-text-variant="white"
      no-close-on-backdrop
      no-close-on-esc
      @cancel="cancelEditSpeechContext"
      @ok="saveSpeechContext"
      :ok-disabled="$v.modalSpeechContextGroup.$invalid"
      :cancel-title="$t('buttons.cancel')"
    >
      <form v-if="modalSpeechContext" @submit.prevent="saveSpeechContext">
        <div>{{ $t('speechContext.phrase') }}</div>
        <b-form-input v-model="modalSpeechContext.phrase" :state="modalSpeechContext.phrase === null ? null : $v.modalSpeechContext.phrase.required"></b-form-input>
        <div>{{ $t('speechContext.output') }}</div>
        <b-form-input v-model="modalSpeechContext.output"></b-form-input>
        <div>{{ $t('speechContext.boost') }}</div>
        <b-form-input v-model="modalSpeechContext.boost" :state="$v.modalSpeechContext.boost.btw && $v.modalSpeechContext.boost.required" type="number"></b-form-input>
      </form>
    </b-modal>
    <b-modal
      v-model="deleteSpeechContextConfirm"
      :title="$t('speechContext.deleteSpeechContext')"
      header-bg-variant="danger"
      header-text-variant="white"
      no-close-on-esc
      no-close-on-backdrop
      @cancel="cancelDeleteSpeechContext"
      @ok="doDeleteSpeechContext"
      :cancel-title="$t('buttons.cancel')"
    >
      <p>{{ $t('speechContext.confirmDeleteSpeechContext') }}</p>
      <div v-if="deletedSpeechContext" class="container-fluid">
        <div class="row">
          {{ $t('speechContext.phrase') }}: {{ deletedSpeechContext.phrase }}
        </div>
        <div class="row">
          {{ $t('speechContext.output') }}: {{ deletedSpeechContext.output }}
        </div>
        <div class="row">
          {{ $t('speechContext.boost') }}: {{ deletedSpeechContext.boost }}
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="importSpeechContextModalShown"
      :title="$t('buttons.import')"
      header-bg-variant="primary"
      header-text-variant="white"
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      @cancel="closeImportSpeechContextModal"
      @ok.prevent="importSpeechContext(0)"
      :ok-disabled="!(controller.previewData && controller.previewData.length > 0)"
      :cancel-title="$t('buttons.cancel')"
    >
      <input type="file" @change="importFileChanged" accept=".xlsx"/>
      <hr>
      <speech-context-modal-detail
        v-if="controller.previewData"
        :title="$t('general.preview')"
        :data="controller.previewData"
      >
      </speech-context-modal-detail>
    </b-modal>
    <b-modal
      v-model="importConfirmModalShown"
      :title="$t('general.confirm')"
      header-bg-variant="warning"
      header-text-variant="white"
      no-close-on-esc
      no-close-on-backdrop
    >
      <p>{{ importConfirmMsg }}</p>
      <speech-context-modal-detail
        v-if="importConfirmDataList"
        :data="importConfirmDataList"
      >
      </speech-context-modal-detail>
      <template v-slot:modal-footer>
        <b-btn variant="secondary" @click="closeImportConfirmModal">{{ $t('buttons.cancel') }}</b-btn>
        <b-btn variant="warning" @click="importSpeechContext(1)">{{ $t('buttons.skip') }}</b-btn>
        <b-btn variant="danger" @click="importSpeechContext(2)">{{ $t('buttons.overwrite') }}</b-btn>
      </template>
    </b-modal>
    <b-modal
      v-model="successModalShown"
      :title="$t('validation.successful')"
      header-bg-variant="success"
      header-text-variant="white"
      ok-only
    >
      {{ successMsg }}
    </b-modal>
    <error-modal
      ref="errorModalRef"
      :slot-only="true"
    >
      <template v-slot:body>
        <p>{{ errorMsg }}</p>
        <div class="error-detail" style="max-height: 300px; overflow-y: auto;">
          <template v-for="(item, index) in errorDataList">
            <p :key="index">{{ item }}</p>
          </template>
        </div>
      </template>
    </error-modal>
    <progress-modal
      ref="progressModalRef"
      :message="progressMsg"
    ></progress-modal>
  </div>
</template>

<script>
  import csrfToken from '../util/csrf-token'
  import { cloneDeep } from 'lodash'
  import { SpeechContext } from "../model/speech-context"
  import { required, between } from 'vuelidate/lib/validators'
  import SpeechContextModalDetail from "./SpeechContextModalDetail.vue"
  export default {
    name: "SpeechContextManagement",
    components: {
      SpeechContextModalDetail
    },
    props: ['controller'],
    data () {
      return {
        modalSpeechContext: null,
        editingSpeechContext: false,
        deleteSpeechContextConfirm: false,
        deletedSpeechContext: null,
        errorMsg: null,
        errorDataList: [],
        progressMsg: null,
        fileToImport: null,
        importSpeechContextModalShown: false,
        importConfirmModalShown: false,
        importConfirmMsg: null,
        importConfirmDataList: [],
        successMsg: null,
        successModalShown: false
      }
    },
    validations: {
      modalSpeechContext: {
          phrase: {
              required
          },
          boost: {
              required,
              btw: between(0, 20)
          }
      },
      modalSpeechContextGroup: ['modalSpeechContext.phrase', 'modalSpeechContext.boost']
    },
    methods: {
      closeErrorModal() {
        this.controller.closeErrorModal()
      },
      addSpeechContext() {
        this.doEditSpeechContext(new SpeechContext())
      },
      editSpeechContext(ctx) {
        this.doEditSpeechContext(cloneDeep(ctx))
      },
      doEditSpeechContext(ctx) {
        this.modalSpeechContext = ctx
        this.editingSpeechContext = true
      },
      saveSpeechContext() {
        this.controller.saveSpeechContext(
            cloneDeep(this.modalSpeechContext),
            csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        this.modalSpeechContext = null
      },
      cancelEditSpeechContext() {
        this.modalSpeechContext = null
        this.editingSpeechContext = false
      },
      deleteSpeechContext(ctx) {
        this.deletedSpeechContext = ctx
        this.deleteSpeechContextConfirm = true
      },
      cancelDeleteSpeechContext() {
        this.deletedSpeechContext = null
        this.deleteSpeechContextConfirm = false
      },
      doDeleteSpeechContext() {
        if (this.deletedSpeechContext) {
          this.controller.deleteSpeechContext(
              [this.deletedSpeechContext.sid],
              csrfToken.getCsrfTokenFromCookie(document.cookie)
          )
        }
        this.deletedSpeechContext = null
      },
      showErrorModal(msg, dataList = []) {
        this.errorMsg = msg
        this.errorDataList = dataList
        this.$refs.errorModalRef.show()
      },
      showProgressModal(msg) {
        this.progressMsg = msg
        this.$refs.progressModalRef.show()
      },
      hideProgressModal() {
        this.progressMsg = null
        this.$refs.progressModalRef.hide()
      },
      selectLanguage(code) {
        this.controller.selectLanguage(code)
      },
      openImportSpeechContextModal() {
        this.importSpeechContextModalShown = true
      },
      closeImportSpeechContextModal() {
        this.importSpeechContextModalShown = false
        this.fileToImport = null
        this.controller.loadImportFilePreview(this.fileToImport)
      },
      importSpeechContext(saveMode) {
        this.controller.importSpeechContextFromFile(
            this.fileToImport,
            saveMode,
            csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        this.closeImportConfirmModal()
      },
      importFileChanged(e) {
        const files = e.target && e.target.files
        if (files && files.length > 0) {
          this.fileToImport = files[0]
        } else {
          this.fileToImport = null
        }
        this.controller.loadImportFilePreview(this.fileToImport, csrfToken.getCsrfTokenFromCookie(document.cookie))
      },
      showImportConfirmModal(msg, dataList = []) {
        this.importConfirmMsg = msg
        this.importConfirmDataList = dataList
        this.importConfirmModalShown = true
      },
      closeImportConfirmModal() {
        this.importConfirmMsg = null
        this.importConfirmDataList = []
        this.importConfirmModalShown = false
      },
      showSuccessModal(msg) {
        this.successMsg = msg
        this.successModalShown = true
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.controller.ready(
          {
            showErrorModal: this.showErrorModal,
            showProgressModal: this.showProgressModal,
            hideProgressModal: this.hideProgressModal,
            showImportConfirmModal: this.showImportConfirmModal,
            showSuccessModal: this.showSuccessModal
          }
        )
      })
    }
  }
</script>

<style scoped>

</style>
