<template>
  <b-modal
    ref="createScenarioModal"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-backdrop
    :title="$t('scenario.add')"
    @shown="shown"
    @hidden="onHidden">
    <div>
      {{ $t('scenario.name') }}
    </div>
    <form
      @keypress.enter.prevent="saveScenario">
      <b-form-input
        ref="scenarioNameInput"
        :state="!!scenario.name ? isValidScenarioName : null"
        v-model="scenario.name">
      </b-form-input>
    </form>
    <modal-footer
      slot="modal-footer"
      @ok="saveScenario"
      @cancel="$refs.createScenarioModal.hide()"
      :ok-disabled="!isValidScenarioName">
    </modal-footer>
  </b-modal>
</template>

<script>
import { Scenario } from '../model/context'
export default {
  props: [],
  data() {
    return {
      scenario: new Scenario(),
    }
  },
  computed: {
    isValidScenarioName() {
      // シナリオ名は日本語も使える
      return this.scenario.name.trim().match(/^[^_]+$/) != null
    },
  },
  methods: {
    show(scenario=null) {
      if (scenario == null) {
        // 値をリセットするため毎回newする
        this.scenario = new Scenario()
      } else {
        // シナリオをそのまま使うと、リストに表示されているシナリオの内容が変わってしまうため、値をコピーする
        this.scenario = new Scenario(scenario.id, scenario.name)
      }
      this.$refs.createScenarioModal.show()
    },
    saveScenario() {
      if (!this.isValidScenarioName) {
        return
      }
      this.scenario.name = this.scenario.name.trim()
      this.$emit('ok', this.scenario)
      this.$refs.createScenarioModal.hide()
    },
    shown() {
      this.$refs.scenarioNameInput.focus()
      this.$emit('shown')
    },
    onHidden() {
      this.$emit('hidden')
    },
  },
}
</script>
