<template>
  <div class="conversation-type-filter-area filter-area">
    <div class="select-text">
      {{ $t('history.filterByMessageType') }}<span
      class="float-right pr-2 pointer-cursor"
      @click="foldPane">
        <span v-show="isFolded">
          <i class="fas fa-plus-circle"></i>
        </span>
        <span v-show="!isFolded">
          <i class="fas fa-minus-circle"></i>
        </span>
      </span>
    </div>
    <div
      class="content"
      :class="{hide: isFolded}">
      <b-checkbox
        class="welcome-filter"
        v-model="isWelcomeFilterActive"
        @input="changeWelcomeFilter">
        {{ $t('history.showWelcomeOnlyMessage') }}
      </b-checkbox>
      <div class="sort-title">
        {{ $t('history.sortConfiguration') }}
      </div>
      <div class="select-sort-title">
        {{ $t('history.selectSortElement') }}
      </div>
      <b-form-group class="sort-element-area">
        <b-form-radio-group
          v-model="filterSelectedSortElement"
          @input="onSortElementSelected"
          stacked>
          <b-radio
            value="startTime">
            {{ $t('history.startTime') }}
          </b-radio>
          <b-radio
            value="endTime">
            {{ $t('history.endTime') }}
          </b-radio>
          <b-radio
            value="count">
            {{ $t('history.messageCount') }}
          </b-radio>
        </b-form-radio-group>
      </b-form-group>
      <div class="select-sort-title">
        {{ $t('history.selectSortOrder') }}
      </div>
      <b-form-group class="sort-element-area">
        <b-form-radio-group
          v-model="filterSelectedSortOrder"
          @input="onSortOrderSelected">
          <b-radio value="asc">
            {{ $t('history.asc') }}
          </b-radio>
          <b-radio value="desc">
            {{ $t('history.desc') }}
          </b-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'selectedSortElement'
  ],
  data() {
    return {
      isFolded: false,
      filterSelectedSortElement: 'endTime',
      filterSelectedSortOrder: 'desc',
      isWelcomeFilterActive: false
    }
  },
  methods: {
    foldPane() {
      this.isFolded = !this.isFolded
    },
    onSortElementSelected(value) {
      this.$emit('on-sort-element-selected', value)
    },
    onSortOrderSelected(value) {
      this.$emit('on-sort-order-selected', value)
    },
    changeWelcomeFilter(value) {
      this.$emit('on-welcome-filter-changed', value)
    }
  }
}
</script>
