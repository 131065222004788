/**
 * オペレーター対応セッションで利用可能なプラットフォームの情報
 */
export const operatorHandlingSessionPlatform = {
  line: {
    text: 'LINE',
    value: 'line'
  },
  slack: {
    text: 'SLACK',
    value: 'slack'
  },
  webchatv2: {
    text: 'WebChatV2',
    value: 'web_chat_v2'
  }
}
