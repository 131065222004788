class Rule {
  constructor(id=null, priority=0, target=null, targetParameter=null, action=null, actionParameter=null, option=null, optionProbability=null, optionLink=null, optionImage=null, optionText=null, region=null, area=null) {
    this.id = id
    this.priority = priority
    this.target = target
    this.targetParameter = targetParameter
    this.action = action
    this.actionParameter = actionParameter
    this.option = option
    this.optionProbability = optionProbability
    this.optionLink = optionLink
    this.optionImage = optionImage
    this.optionText = optionText
    this.region = region
    this.area = area
    // keep warning message(not save)
    this.warningMessage = null
  }

  toData() {
    return {
      id: this.id,
      priority: this.priority,
      target: this.target,
      target_parameter: this.targetParameter,
      action: this.action,
      action_parameter: this.actionParameter,
      option: this.option,
      option_probability: this.optionProbability,
      option_link: this.optionLink,
      option_image: this.optionImage,
      option_text: this.optionText,
      region: this.region,
      area: this.area
    }
  }

  /*
   * Warningメッセージを持っているかどうか
   */
  hasWarning() {
    return this.warningMessage !== null
  }

  /*
   * Warningメッセージの追加
   */
  addWarning(message) {
    if (this.warningMessage == null) {
      this.warningMessage = message
    } else {
      this.warningMessage += '\n' + message
    }
  }
}

Rule.fromData = function fromData(data) {
  return new Rule(
    data.id || null,
    data.priority || 0,
    data.target || null,
    data.target_parameter || null,
    data.action || null,
    data.action_parameter || null,
    data.option || null,
    data.option_probability || null,
    data.option_link || null,
    data.option_image || null,
    data.option_text || null,
    data.region || null,
    data.area || null
  )
}

const RuleTarget = Object.freeze({
  ALL: 'all',
  INTENT: 'intent'
})

const RuleAction = Object.freeze({
  NOTHING: 'nothing',
  CALL_EXTERNAL_URL: 'call_external_url',
  WEATHER_BY_ZIPCODE: 'weather_by_zipcode',
  WEATHER_BY_CITY_NAME: 'weather_by_city_name',
  WEATHER_BY_DOMESTIC_AREA: 'weather_by_domestic_area',
  FLIGHT_BY_FLIGHTNUMBER: 'flight_by_flightnumber',
  RESTAURANT_BY_ADDRESS: 'restaurant_by_address',
  RESTAURANT_BY_COORDINATE: 'restaurant_by_coordinate',
  SESAME_LOCK: 'sesame_lock',
  NATURE_REMO: 'nature_remo',
  HOROSCOPE_BY_DOB: 'horoscope_by_dob',
  IFTTT_EVENT: 'ifttt_event',
})

const RuleOption = Object.freeze({
  ON: 'on',
  OFF: 'off'
})

const RuleOptionProbability = Object.freeze({
  FIVE_PER: '5%',
  TEN_PER: '10%',
  FIFTEEN_PER: '15%',
  TWENTY_PER: '20%'
})

const RuleRegion = Object.freeze({
  HOKKAIDO: 'hokkaido',
  N_TOHOKU: 'n_tohoku',
  S_TOHOKU: 's_tohoku',
  KANTO: 'kanto',
  CHUBU_HOKURIKU: 'chubu_hokuriku',
  CHUBU_OTHERS: 'chubu_others',
  KINKI: 'kinki',
  CHUGOKU: 'chugoku',
  SHIKOKU: 'shikoku',
  N_KYUSHU: 'n_kyushu',
  S_KYUSHU: 's_kyushu',
  OKINAWA: 'okinawa'
})

const RuleArea = Object.freeze({
  WAKKANAI: '301000',
  ASAHIKAWA: '302000',
  RUMOI: '302100',
  ABASHIRI: '303000',
  KITAMI: '303100',
  MOMBETSU: '303200',
  NEMURO: '304000',
  KUSHIRO: '304100',
  OBIHIRO: '304201',
  MURORAN: '305000',
  URAKAWA: '305100',
  SAPPORO: '306000',
  IWAMIZAWA: '306100',
  KUTCHAN: '306200',
  HAKODATE: '307000',
  ESASHI: '307100',
  AOMORI: '308000',
  FUKAURA: '308010',
  HIROSAKI: '308020',
  MUTSU: '308101',
  HACHINOHE: '308201',
  AKITA: '309000',
  YOKOTE: '309100',
  TAKANOSU: '309110',
  MORIOKA: '310000',
  NINOHE: '310010',
  ICHINOSEKI: '310020',
  MIYAKO: '310101',
  OFUNATO: '310201',
  YAMAGATA: '311000',
  YONEZAWA: '311100',
  SAKATA: '311200',
  SHINJO: '311300',
  SENDAI: '312000',
  ISHINOMAKI: '312010',
  FURUKAWA: '312020',
  SHIROISHI: '312101',
  FUKUSHIMA: '313000',
  SHIRAKAWA: '313010',
  KORIYAMA: '313020',
  ONAHAMA: '313100',
  SOMA: '313110',
  WAKAMATSU: '313201',
  TAJIMA: '313211',
  MITO: '314000',
  TSUCHIURA: '314100',
  MAEBASHI: '315000',
  MINAKAMI: '315100',
  UTSUNOMIYA: '316000',
  OHTAWARA: '316100',
  SAITAMA: '317000',
  KUMAGAYA: '317100',
  CHICHIBU: '317200',
  CHIBA: '318000',
  CHOSHI: '318100',
  TATEYAMA: '318200',
  TOKYO: '319000',
  OSHIMA: '319101',
  HACHIJOJIMA: '319201',
  CHICHIJIMA: '319302',
  YOKOHAMA: '320000',
  ODAWARA: '320100',
  NIIGATA: '323000',
  TSUGAWA: '323010',
  NAGAOKA: '323100',
  YUZAWA: '323110',
  TAKADA: '323200',
  AIKAWA: '323300',
  TOYAMA: '324000',
  FUSHIKI: '324100',
  KANAZAWA: '325000',
  WAJIMA: '325100',
  FUKUI: '326000',
  ONO: '326010',
  TSURUGA: '326100',
  KOFU: '321000',
  KAWAGUCHIKO: '321100',
  NAGANO: '322000',
  MATSUMOTO: '322100',
  SUWA: '322110',
  KARUIZAWA: '322120',
  IIDA: '322200',
  SHIZUOKA: '327000',
  AJIRO: '327100',
  IROUZAKI: '327110',
  MISHIMA: '327200',
  HAMAMATSU: '327300',
  OMAEZAKI: '327310',
  GIFU: '328000',
  TAKAYAMA: '328100',
  NAGOYA: '329000',
  TOYOHASHI: '329100',
  TSU: '330000',
  YOKKAICHI: '330010',
  UENO: '330020',
  OWASE: '330100',
  OSAKA: '331000',
  KOBE: '332000',
  SUMOTO: '332010',
  HIMEJI: '332020',
  TOYOOKA: '332100',
  KYOTO: '333000',
  MAIZURU: '333100',
  OTSU: '334000',
  HIKONE: '334100',
  NARA: '335000',
  KAZEYA: '335100',
  WAKAYAMA: '336000',
  SHIONOMISAKI: '336100',
  MATSUE: '337000',
  HAMADA: '337100',
  SAIGO: '337200',
  HIROSHIMA: '338000',
  KURE: '338010',
  FUKUYAMA: '338020',
  SHOBARA: '338100',
  TOTTORI: '339000',
  YONAGO: '339100',
  OKAYAMA: '340000',
  TSUYAMA: '340100',
  SHIMONOSEKI: '345000',
  YAMAGUCHI: '345100',
  YANAI: '345200',
  HAGI: '345300',
  TAKAMATSU: '341000',
  MATSUYAMA: '342000',
  NIIHAMA: '342100',
  UWAJIMA: '342200',
  TOKUSHIMA: '343000',
  IKEDA: '343010',
  HIWASA: '343100',
  KOCHI: '344000',
  MUROTOMISAKI: '344100',
  SHIMIZU: '344200',
  FUKUOKA: '346000',
  YAHATA: '346100',
  IIZUKA: '346200',
  KURUME: '346300',
  SAGA: '347000',
  IMARI: '347100',
  NAGASAKI: '348000',
  SASEBO: '348100',
  IZUHARA: '348200',
  FUKUE: '348300',
  KUMAMOTO: '349000',
  ASOOTOHIME: '349100',
  USHIBUKA: '349200',
  HITOYOSHI: '349300',
  OITA: '350000',
  NAKATSU: '350100',
  HITA: '350200',
  SAIKI: '350300',
  MIYAZAKI: '351000',
  ABURATSU: '351010',
  NOBEOKA: '351100',
  MIYAKONOJO: '351200',
  TAKACHIHO: '351300',
  KAGOSHIMA: '352000',
  AKUNE: '352010',
  MAKURAZAKI: '352020',
  KANOYA: '352100',
  TANEGASHIMA: '352200',
  NAZE: '352301',
  OKINOERABU: '352311',
  NAHA: '353000',
  NAGO: '353100',
  KUMEJIMA: '353200',
  MINAMIDAITOJIMA: '354000',
  MIYAKOJIMA: '355000',
  ISHIGAKIJIMA: '356000',
  YONAGUNIJIMA: '356100',
})

export {
  Rule,
  RuleTarget,
  RuleAction,
  RuleOption,
  RuleOptionProbability,
  RuleRegion,
  RuleArea
}
