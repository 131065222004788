/*
 * 統計分析画面
 */
import Vue from 'vue'
import { DashboardController } from './controller/dashboard'
import Dashboard from './components/Dashboard.vue'

export const appDashboard = (i18n) => {
  /* コントローラ */
  const controller = new DashboardController(
    window.i18nContext,
    window.dashboardContext.dateRange,
    window.dashboardContext.two_week_data,
    window.dashboardContext.url
  )

  /* コンポーネント登録 */
  Vue.component('dashboard', Dashboard)

  new Vue({
    el: '#app-dashboard',
    i18n: i18n,
    data: {
      controller: controller
    },
  })
}
