<template>
  <div class="chat-window">
    <button
      id="test-chat-btn"
      class="open-chat-button"
      @mouseup="openChatBox"
      @mousedown="mousedownChatButton"
      :title="$t('general.testChatInfo')"
    >
      {{ $t("buttons.testChat") }}
    </button>

    <transition name="chat-fade">
      <div
        class="chat-box-popup"
        id="chat-box-popup"
        v-if="showChat"
        @mousedown="mousedownChatBox"
      >
        <chat-box
          :chat-items-by-platform="controller.chatItemsByPlatform"
          :request-user-say="controller.requestUserSay"
          :loadingScreen="isLoading"
          :supportedLanguages="controller.supportedLanguages"
          :chatLanguage="controller.chatLanguage"
          :hasContext="controller.hasContext"
          :defaultPlatform="controller.defaultPlatform"
          :output-contexts="controller.outputContexts"
          :parameter-object="controller.parameterObject"
          :availablePlatform="controller.availablePlatform"
          :requestErrorMsg="controller.requestErrorMsg"
          @send-request="sendChatRequest"
          @close-chat-window="openChatBox"
          @select-chat-language="selectedChatLanguage"
          @reset-contexts="resetContexts"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import csrfToken from "../util/csrf-token";
import { DragMe } from "../util/drag-me";

export default {
  props: ["controller"],
  data() {
    return {
      showChat: false,
      isLoading: false,
      isDraggingChatButton: false,
      isDraggingChatBox: false,
    };
  },
  methods: {
    openChatBox() {
      if (!this.isDraggingChatButton) {
        this.showChat = !this.showChat;
      }
      this.isDraggingChatButton = false;
      this.$emit("chat-box-state", this.showChat);
      if (!this.showChat) {
        // Chatbox is closed
        this.controller.activateKeyboardScroll(); // re-activate keyboard scroll
      } else {
        this.controller.setChatLanguage();
        this.$nextTick(function () {
          const chatBoxEl = document.getElementById("chat-box-popup");
          if (chatBoxEl) {
            DragMe(chatBoxEl, this, { ondrag: "draggingChatBox" });
          }
        });
      }
    },
    draggingChatButton() {
      this.isDraggingChatButton = true;
    },
    mousedownChatButton() {
      this.isDraggingChatButton = false;
    },
    async sendChatRequest(userSay) {
      this.isLoading = true;
      await this.controller.detectIntent(
        userSay,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      );
      this.isLoading = false;
    },
    selectedChatLanguage(lang) {
      this.controller.selectedChatLanguage(lang);
    },
    resetContexts() {
      this.isLoading = true;
      this.controller
        .deleteAllContextsSession(
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        .then(() => {
          this.isLoading = false;
        });
    },
    draggingChatBox() {
      this.isDraggingChatBox = true;
    },
    mousedownChatBox() {
      this.isDraggingChatBox = false;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      DragMe(document.getElementById("test-chat-btn"), this, {
        ondrag: "draggingChatButton",
      });
    });
  },
};
</script>
