<template>
    <div>
      <div class="text-center" v-if="loadImageState">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="row mt-1 mb-1">
        <transition name="file-content-fade">
          <div class="col-md-12 border p-2" v-if="listFileContents.length > 0">
            <div class="mb-2 text-right">
              <button type="button" class="btn btn-danger"
                      @click="closeListFiles">
                <i class="far fa-times-circle"></i> Close
              </button>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-3 mb-4" v-for="(content, index) in listFileContents">
                <img v-if="isImageFile(content.file)"
                  class="img-thumbnail" ref="fileUrl"
                  onerror="this.src='/static/assets/no-preview-image.jpg'"
                  :src="content.file"
                  @click="addImageURLOnInput(content.file, index)">
                <div v-else
                  class="img-thumbnail"
                  ref="fileUrl"
                  @click="addImageURLOnInput(content.file, index)">
                  <span class="text-no-preview">{{ $t('fileManagement.noPreview') }}</span>
                </div>
              </div>
            </div>
            <pagination :params="params"
                        :num_page="pageNumber"
                        :isModal="isModalPagination"
                        :callback="loadImages"></pagination>
          </div>
        </transition>
      </div>
    </div>
</template>

<script>
import Pagination from './../../Pagination.vue'
import { checkFileTypeImage } from '../../../util/file-type'

export default {
  name: 'FileContents',
  components: {
    Pagination
  },
  props:['imgUrlEl'],
  data() {
    return {
      isModalPagination: true,
      loadImageState: false,
      listFileContents: [],
      pageNumber: 0,
      params: { page: 1}
    }
  },
  methods: {
    loadImages() {
      this.loadImageState = true
      this.$root.controller.getFileContents(this.params).then(response => {
        this.loadImageState = false
        this.listFileContents = response.results
        this.pageNumber = response.num_page
      }).catch(error => {
        this.loadImageState = false
        console.log(error)
      })
    },
    addImageURLOnInput(url, index) {
      const fileUrlEl = this.$refs.fileUrl
      fileUrlEl.forEach(el => {
        if(el.classList.contains("selected-img")) {
          el.classList.remove("selected-img")
        }
      })
      fileUrlEl[index].classList.add("selected-img")
      this.$emit("img-url-model", url)
      if(Array.isArray(this.imgUrlEl)) {
        this.imgUrlEl.forEach(inputEl => {
          inputEl.focus()
        })
      } else {
       this.imgUrlEl.focus()
      }
    },
    closeListFiles() {
      this.listFileContents = []
    },
    isImageFile(file) {
      return checkFileTypeImage(file)
    }
  }
};
</script>

<style scoped>
  .selected-img {
    border-radius: 0;
    border-bottom: 4px solid #777;
    border-right: 2px solid #ccc;
  }
  .file-content-fade-enter-active {
    transition: all .3s ease;
  }
  .file-content-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .file-content-fade-enter, .file-content-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
</style>
