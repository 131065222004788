<template>
  <b-modal
    :title="title"
    ref="wizardModal"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-backdrop
    @keydown.native.enter="handleEnter"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
  >
    <div :class="startMessage && 'word-editor'">
      <span v-if="startMessage">
        {{ startMessage }}

        <hr />
      </span>

      <div>
        {{ bodyMessage }}
      </div>
    </div>

    <modal-footer slot="modal-footer" @ok="handleOK" @cancel="cancel" />
  </b-modal>
</template>

<script>
export default {
  props: ['title', 'startMessage', 'bodyMessage'],
  data() {
    return {}
  },
  methods: {
    show() {
      this.$refs.wizardModal.show()
    },
    cancel() {
      this.$refs.wizardModal.hide()
      this.$emit('cancel')
    },
    hide() {
      this.$refs.wizardModal.hide()
    },
    handleOK() {
      this.hide()
      this.$emit('ok')
    },
    handleEnter() {
      this.hide()
      this.$emit('key-press-enter')
    },
  },
}
</script>
