/**
 * FAQ関連モデル定義
 */

/**
 * QAモデル
 */
class QA {
  constructor(
    id = null,
    category = null,
    language_code = null,
    title = "",
    answers = "",
    active = false,
    priority = 0,
    iintent = null,
    references = ""
  ) {
    this.id = id;
    this.category = category;
    this.languageCode = language_code;
    this.title = title;
    this.answers = answers;
    this.active = active;
    this.priority = priority;
    this.intent = iintent;
    this.references = references;
  }

  toData() {
    return {
      id: this.id,
      category: this.category,
      language_code: this.languageCode,
      title: this.title,
      answers: this.answers,
      active: this.active,
      priority: this.priority,
      intent: this.intent,
      references: this.references,
    };
  }
}

QA.fromData = function fromData(data) {
  return new QA(
    data.id || null,
    data.category || null,
    data.language_code || null,
    data.title || "",
    data.answers || "",
    data.active || false,
    data.priority || 0,
    data.intent || null,
    data.references || ""
  );
};

/**
 * クライアントモデル
 */
class Client {
  constructor(
    id = null,
    cid = null,
    name = null,
    data = "",
    cssPath = "",
    style = false
  ) {
    this.id = id;
    this.cid = cid;
    this.name = name;
    this.data = data;
    this.cssPath = cssPath;
    this.style = style;
  }

  toData() {
    return {
      id: this.id,
      cid: this.cid,
      name: this.name,
      data: this.data,
      css_path: this.cssPath,
      style: this.style,
    };
  }
}

Client.fromData = function fromData(data) {
  return new Client(data.id || null);
};

export { QA, Client };
