import {I18n} from "../util/i18n";
import {RestApi} from "../util/rest-api";
import {ApiService} from "../model/api-service";
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

class ApiServiceController {
  constructor(apiUrl, userType, i18nContext) {
    this.i18n = new I18n(i18nContext)
    this.apiUrl = apiUrl
    this.userType = userType
    this.apiServiceApi = new RestApi(apiUrl.apiService, ApiService)
    this.apiServiceDeleteApi = apiUrl.apiServiceDelete
    this.apiData =[]
  }

  ready() {
    this.loadApiKey()
  }

  loadApiKey(){
    this.apiServiceApi.list()
      .then((instance) => {
        this.apiData = instance
      })
      .catch((error) => {
        console.log(error)
      })
  }

  generateNewKey(){
    const keyObj = new ApiService()
    keyObj.authentication_key = uuidv4()
    return keyObj
  }
  saveApiKey(data, csrfToken=null){
    let promise = this.apiServiceApi.save(
      data,
      csrfToken
    )
      .then((instance)=>{
        this.apiData = [instance]
      })
    return [true, this.i18n.t("general.saving"), promise]
  }

  deleteAuthKey(data, csrfToken=null) {
    const options = {
      headers: {'X-CSRFToken': csrfToken}
    }
    let promise = axios.post(this.apiServiceDeleteApi, data, options)
      .then(() => {
        this.apiData = []
      })
    return [true, this.i18n.t("general.saving"), promise]
  }

  isViewer() {
    return this.userType == 'viewer'
  }
}

export {
  ApiServiceController
}
