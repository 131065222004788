const package1 = [
  { value: '52002734', image: '/static/assets/sticker/11537_52002734.jpg'},
  { value: '52002735', image: '/static/assets/sticker/11537_52002735.jpg'},
  { value: '52002736', image: '/static/assets/sticker/11537_52002736.jpg'},
  { value: '52002737', image: '/static/assets/sticker/11537_52002737.jpg'},
  { value: '52002738', image: '/static/assets/sticker/11537_52002738.jpg'},
  { value: '52002739', image: '/static/assets/sticker/11537_52002739.jpg'},
  { value: '52002740', image: '/static/assets/sticker/11537_52002740.jpg'},
  { value: '52002741', image: '/static/assets/sticker/11537_52002741.jpg'},
  { value: '52002742', image: '/static/assets/sticker/11537_52002742.jpg'},
  { value: '52002743', image: '/static/assets/sticker/11537_52002743.jpg'},
  { value: '52002744', image: '/static/assets/sticker/11537_52002744.jpg'},
  { value: '52002745', image: '/static/assets/sticker/11537_52002745.jpg'},
  { value: '52002746', image: '/static/assets/sticker/11537_52002746.jpg'},
  { value: '52002747', image: '/static/assets/sticker/11537_52002747.jpg'},
  { value: '52002748', image: '/static/assets/sticker/11537_52002748.jpg'},
  { value: '52002749', image: '/static/assets/sticker/11537_52002749.jpg'},
  { value: '52002750', image: '/static/assets/sticker/11537_52002750.jpg'},
  { value: '52002751', image: '/static/assets/sticker/11537_52002751.jpg'},
  { value: '52002752', image: '/static/assets/sticker/11537_52002752.jpg'},
  { value: '52002753', image: '/static/assets/sticker/11537_52002753.jpg'},
  { value: '52002754', image: '/static/assets/sticker/11537_52002754.jpg'},
  { value: '52002755', image: '/static/assets/sticker/11537_52002755.jpg'},
  { value: '52002756', image: '/static/assets/sticker/11537_52002756.jpg'},
  { value: '52002757', image: '/static/assets/sticker/11537_52002757.jpg'},
  { value: '52002758', image: '/static/assets/sticker/11537_52002758.jpg'},
  { value: '52002759', image: '/static/assets/sticker/11537_52002759.jpg'},
  { value: '52002760', image: '/static/assets/sticker/11537_52002760.jpg'},
  { value: '52002761', image: '/static/assets/sticker/11537_52002761.jpg'},
  { value: '52002762', image: '/static/assets/sticker/11537_52002762.jpg'},
  { value: '52002763', image: '/static/assets/sticker/11537_52002763.jpg'},
  { value: '52002764', image: '/static/assets/sticker/11537_52002764.jpg'},
  { value: '52002765', image: '/static/assets/sticker/11537_52002765.jpg'},
  { value: '52002766', image: '/static/assets/sticker/11537_52002766.jpg'},
  { value: '52002767', image: '/static/assets/sticker/11537_52002767.jpg'},
  { value: '52002768', image: '/static/assets/sticker/11537_52002768.jpg'},
  { value: '52002769', image: '/static/assets/sticker/11537_52002769.jpg'},
  { value: '52002770', image: '/static/assets/sticker/11537_52002770.jpg'},
  { value: '52002771', image: '/static/assets/sticker/11537_52002771.jpg'},
  { value: '52002772', image: '/static/assets/sticker/11537_52002772.jpg'},
  { value: '52002773', image: '/static/assets/sticker/11537_52002773.jpg'}
]

const package2 = [
  { value: '51626494', image: '/static/assets/sticker/11538_51626494.jpg'},
  { value: '51626495', image: '/static/assets/sticker/11538_51626495.jpg'},
  { value: '51626496', image: '/static/assets/sticker/11538_51626496.jpg'},
  { value: '51626497', image: '/static/assets/sticker/11538_51626497.jpg'},
  { value: '51626498', image: '/static/assets/sticker/11538_51626498.jpg'},
  { value: '51626499', image: '/static/assets/sticker/11538_51626499.jpg'},
  { value: '51626500', image: '/static/assets/sticker/11538_51626500.jpg'},
  { value: '51626501', image: '/static/assets/sticker/11538_51626501.jpg'},
  { value: '51626502', image: '/static/assets/sticker/11538_51626502.jpg'},
  { value: '51626503', image: '/static/assets/sticker/11538_51626503.jpg'},
  { value: '51626504', image: '/static/assets/sticker/11538_51626504.jpg'},
  { value: '51626505', image: '/static/assets/sticker/11538_51626505.jpg'},
  { value: '51626506', image: '/static/assets/sticker/11538_51626506.jpg'},
  { value: '51626507', image: '/static/assets/sticker/11538_51626507.jpg'},
  { value: '51626508', image: '/static/assets/sticker/11538_51626508.jpg'},
  { value: '51626509', image: '/static/assets/sticker/11538_51626509.jpg'},
  { value: '51626510', image: '/static/assets/sticker/11538_51626510.jpg'},
  { value: '51626511', image: '/static/assets/sticker/11538_51626511.jpg'},
  { value: '51626512', image: '/static/assets/sticker/11538_51626512.jpg'},
  { value: '51626513', image: '/static/assets/sticker/11538_51626513.jpg'},
  { value: '51626514', image: '/static/assets/sticker/11538_51626514.jpg'},
  { value: '51626515', image: '/static/assets/sticker/11538_51626515.jpg'},
  { value: '51626516', image: '/static/assets/sticker/11538_51626516.jpg'},
  { value: '51626517', image: '/static/assets/sticker/11538_51626517.jpg'},
  { value: '51626518', image: '/static/assets/sticker/11538_51626518.jpg'},
  { value: '51626519', image: '/static/assets/sticker/11538_51626519.jpg'},
  { value: '51626520', image: '/static/assets/sticker/11538_51626520.jpg'},
  { value: '51626521', image: '/static/assets/sticker/11538_51626521.jpg'},
  { value: '51626522', image: '/static/assets/sticker/11538_51626522.jpg'},
  { value: '51626523', image: '/static/assets/sticker/11538_51626523.jpg'},
  { value: '51626524', image: '/static/assets/sticker/11538_51626524.jpg'},
  { value: '51626525', image: '/static/assets/sticker/11538_51626525.jpg'},
  { value: '51626526', image: '/static/assets/sticker/11538_51626526.jpg'},
  { value: '51626527', image: '/static/assets/sticker/11538_51626527.jpg'},
  { value: '51626528', image: '/static/assets/sticker/11538_51626528.jpg'},
  { value: '51626529', image: '/static/assets/sticker/11538_51626529.jpg'},
  { value: '51626530', image: '/static/assets/sticker/11538_51626530.jpg'},
  { value: '51626531', image: '/static/assets/sticker/11538_51626531.jpg'},
  { value: '51626532', image: '/static/assets/sticker/11538_51626532.jpg'},
  { value: '51626533', image: '/static/assets/sticker/11538_51626533.jpg'},
]

const package3 = [
  { value: '52114110', image: '/static/assets/sticker/11539_52114110.jpg'},
  { value: '52114111', image: '/static/assets/sticker/11539_52114111.jpg'},
  { value: '52114112', image: '/static/assets/sticker/11539_52114112.jpg'},
  { value: '52114113', image: '/static/assets/sticker/11539_52114113.jpg'},
  { value: '52114114', image: '/static/assets/sticker/11539_52114114.jpg'},
  { value: '52114115', image: '/static/assets/sticker/11539_52114115.jpg'},
  { value: '52114116', image: '/static/assets/sticker/11539_52114116.jpg'},
  { value: '52114117', image: '/static/assets/sticker/11539_52114117.jpg'},
  { value: '52114118', image: '/static/assets/sticker/11539_52114118.jpg'},
  { value: '52114119', image: '/static/assets/sticker/11539_52114119.jpg'},
  { value: '52114120', image: '/static/assets/sticker/11539_52114120.jpg'},
  { value: '52114121', image: '/static/assets/sticker/11539_52114121.jpg'},
  { value: '52114122', image: '/static/assets/sticker/11539_52114122.jpg'},
  { value: '52114123', image: '/static/assets/sticker/11539_52114123.jpg'},
  { value: '52114124', image: '/static/assets/sticker/11539_52114124.jpg'},
  { value: '52114125', image: '/static/assets/sticker/11539_52114125.jpg'},
  { value: '52114126', image: '/static/assets/sticker/11539_52114126.jpg'},
  { value: '52114127', image: '/static/assets/sticker/11539_52114127.jpg'},
  { value: '52114128', image: '/static/assets/sticker/11539_52114128.jpg'},
  { value: '52114129', image: '/static/assets/sticker/11539_52114129.jpg'},
  { value: '52114130', image: '/static/assets/sticker/11539_52114130.jpg'},
  { value: '52114131', image: '/static/assets/sticker/11539_52114131.jpg'},
  { value: '52114132', image: '/static/assets/sticker/11539_52114132.jpg'},
  { value: '52114133', image: '/static/assets/sticker/11539_52114133.jpg'},
  { value: '52114134', image: '/static/assets/sticker/11539_52114134.jpg'},
  { value: '52114135', image: '/static/assets/sticker/11539_52114135.jpg'},
  { value: '52114136', image: '/static/assets/sticker/11539_52114136.jpg'},
  { value: '52114137', image: '/static/assets/sticker/11539_52114137.jpg'},
  { value: '52114138', image: '/static/assets/sticker/11539_52114138.jpg'},
  { value: '52114139', image: '/static/assets/sticker/11539_52114139.jpg'},
  { value: '52114140', image: '/static/assets/sticker/11539_52114140.jpg'},
  { value: '52114141', image: '/static/assets/sticker/11539_52114141.jpg'},
  { value: '52114142', image: '/static/assets/sticker/11539_52114142.jpg'},
  { value: '52114143', image: '/static/assets/sticker/11539_52114143.jpg'},
  { value: '52114144', image: '/static/assets/sticker/11539_52114144.jpg'},
  { value: '52114145', image: '/static/assets/sticker/11539_52114145.jpg'},
  { value: '52114146', image: '/static/assets/sticker/11539_52114146.jpg'},
  { value: '52114147', image: '/static/assets/sticker/11539_52114147.jpg'},
  { value: '52114148', image: '/static/assets/sticker/11539_52114148.jpg'},
  { value: '52114149', image: '/static/assets/sticker/11539_52114149.jpg'},
]

export {
  package1,
  package2,
  package3
}
