<template>
  <div
    class="scenario-index"
    ref="scenarioIndex">
    <div class="scenario-cards">
      <div class="menu-bar row mt-3">
        <div class="mb-1" :class="{'col-md-4': !controller.isStaff, 'col-md-2': controller.isStaff}">
          <synchronize
            :userType="controller.userType"
            @start-synchronize="startSynchronize">
          </synchronize>
        </div>
        <div :class="{'col-md-5': multiSelecting, 'col-md-3': !multiSelecting}"
          class="mb-1" v-if="controller.isStaff">
          <b-btn
            class="btn btn-success mr-1"
            :disabled="controller.scenarios.length===0"
            :class="{'btn-danger': multiSelecting}"
            @click="toggleMultiSelect">
            <span v-show="!multiSelecting"><i class="fas fa-check"></i></span>
            <span v-show="multiSelecting"><i class="fas fa-times"></i></span>
            <span>{{ multiSelecting ? $t('buttons.cancel') : $t('scenario.copyToAgent') }}</span>
          </b-btn>
          <b-btn
            :class="[{'btn-danger': isAllSelected}, 'mr-1']"
            v-show="multiSelecting && controller.scenarios.length"
            @click="toggleAllSelect">
            <i class="fas fa-arrows-alt-h"></i>
            <span>{{ isAllSelected ? $t('buttons.clearAll') : $t('buttons.selectAll') }}</span>
          </b-btn>
          <b-btn
            class="btn btn-success"
            v-if="multiSelecting"
            :title="$t('intent.setCopyIntentToAgent')"
            @click="copyToConfirm"
            v-bind:disabled="!multiSelectedScenario.length || !multiSelecting">
            <i class="fas fa-copy"></i>
            {{ $t('buttons.copyTo') }}
          </b-btn>
        </div>
        <div class="col-md-4 mb-1" v-if="!multiSelecting">
          <b-btn
            :href="scenarioEditorUrl"
            class="scenario-editor-button mr-1"
            :disabled="!controller.selectedScenario"
            variant="info">
            <i class="fas fa-project-diagram"></i>
            {{ $t('scenario.scenarioEditor') }}
          </b-btn>
          <b-btn
            v-if="controller.userType !== 'viewer'"
            variant="primary"
            :title="$t('scenario.copyScenario')"
            :disabled="!controller.selectedScenario"
            @click="$refs.copyConfirmModal.show()">
            <i class="fas fa-copy"></i>
            <span>{{$t('buttons.copy')}}</span>
          </b-btn>
        </div>
        <div
          class="mb-1"
          :class="{'col-md-4': !controller.isStaff, 'col-md-3': controller.isStaff}"
          v-if="controller.userType !== 'viewer' && !multiSelecting">
          <add-button
            class="scenario-create-button button-text mr-1"
            :title="$t('buttons.add')"
            @click="showCreateScenarioModal">
          </add-button>
          <b-btn
            variant="primary"
            class="scenario-create-button button-text mr-1"
            :title="$t('buttons.edit')"
            :disabled="!controller.selectedScenario"
            @click="showCreateScenarioModal(controller.selectedScenario)">
            <i class="fas fa-edit"></i>
            <span>{{ $t('buttons.edit') }}</span>
          </b-btn>
          <delete-button
            class="scenario-create-button button-text"
            :title="$t('buttons.delete')"
            :disabled="controller.selectedScenario == null"
            @click="showDeleteScenarioModal">
          </delete-button>
        </div>
      </div>
      <div
        v-for="(scenarios, index) in nestedScenarios"
        class="row mt-2 pb-4">
        <div
          class="col-md-4"
          v-for="(scenario, subIndex) in scenarios">
          <div
            class="label bg-white pointer-cursor scenario-item"
            :class="{selected: controller.selectedScenario == scenario || multiSelectedScenario.some(msi=>scenario.id === msi.id)}"
            @dblclick="dblClickShowScenarioEditor"
            @click="selectScenario(scenario)">
            <div class="scenario-thumbnail-box">
              <b-img
                v-if="scenario.thumbnail"
                :src="scenario.thumbnail + '?time=' + new Date().getTime().toString()"
                class="scenario-thumbnail"
                center/>
              <div v-else
                class="w-100 h-100 d-flex justify-content-center align-items-center">
                <h3>{{ $t('fileManagement.noPreview') }}</h3>
              </div>
            </div>
            <p class="scenario-name">{{ getScenarioName(scenario.name) }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--Confirm scenario copy modal-->
    <b-modal
      :title="$t('scenario.copyScenarioConfirm')"
      ref="copyConfirmModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop>
      <div>
        {{$t('scenario.copyScenarioConfirmMsg', {scenario: this.controller.selectedScenario && this.controller.selectedScenario.name})}}
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="copyScenario"
        @cancel="$refs.copyConfirmModal.hide()">
      </modal-footer>
    </b-modal>
    <b-modal
      ref="multiOperationConfirmModal"
      :title="$t('scenario.copyScenarioConfirm')"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
      @ok="copyToAgent"
    >
      <span>{{ $t('intent.selectTargetAgent') }}:</span>
      <basic-select
        class="mb-1"
        :options="controller.agentOptions"
        :selected-option="controller.itemAgent"
        @select="selectAgent"
        :placeholder="$t('intent.selectTargetAgent')">
      </basic-select>
      <span>{{$t('scenario.selectedScenario')}}:</span>
      <ul class="intent">
        <li style="cursor: default" v-for="(sc, index) in multiSelectedScenario">
          <span>{{ sc.name }}</span>
        </li>
      </ul>
      <span>total: {{multiSelectedScenario.length}}</span>
      <modal-footer
        slot="modal-footer"
        :ok-disabled="!controller.itemAgent.value"
        @ok="copyToAgent"
        @cancel="$refs.multiOperationConfirmModal.hide()">
      </modal-footer>
    </b-modal>
    <progress-modal
      ref="progressModal"
      :message="message"
      @shown="toggleModalShown"
      @hidden="toggleModalShown">
    </progress-modal>

    <error-modal
      ref="errorModal"
      :message="message"
      :isCard="isCard"
      :cardErrorMsg="cardErrorMsg"
      :isBotBroken="isBotBroken"
      :botBrokenMessage="botBrokenMessage"
      @train="startSynchronize"
      @key-press-enter="$refs.errorModal.hide()">
    </error-modal>

    <success-modal
      ref="syncCompletedModal"
      :title="$t('synchronize.result')"
      :message="$t('synchronize.completed')"
      @key-press-enter="closeSyncCompletedModal">
    </success-modal>

    <create-scenario-modal
      ref="createScenarioModal"
      @ok="saveScenario"
      @shown="toggleModalShown"
      @hidden="toggleModalShown">
    </create-scenario-modal>

    <delete-confirm-modal
      ref="deleteScenarioModal"
      :title="$t('scenario.delete')"
      :bodyMessage="$t('scenario.deleteConfirmMessage', {scenario: this.controller.selectedScenario && this.controller.selectedScenario.name})"
      @ok="deleteScenario"
      @key-press-enter="deleteScenario"
      @cancel="$refs.deleteScenarioModal.hide()"
      @hidden="toggleModalShown">
    </delete-confirm-modal>
    <b-alert
      v-if="inputTransportResult.message"
      fade
      :show="3"
      dismissible
      :variant="inputTransportResult.type"
      @dismissed="inputResetMessage"
      class="stick-to-bottom"
    >
      {{ inputTransportResult.message }}
    </b-alert>
  </div>
</template>

<script>
import csrfToken from "../util/csrf-token";
import { SynchronizeStatus } from '../model/synchronize'

export default {
  props: ['controller'],
  data() {
    return {
      message: null,
      isModalShown: false,
      isCard: false,
      cardErrorMsg:[],
      isBotBroken:false,
      botBrokenMessage:'',
      multiSelecting: false,
      multiSelectedScenario: [],
      tempSelectedScenario: null,
      targetAgent: null,
      inputTransportResult: {
        message: null,
        type: null
      }
    }
  },
  computed: {
    nestedScenarios() {
      // カードでシナリオを表示する際、一行に3シナリオ表示したいため、ネストにする
      let nestedScenarios = []
      this.controller.scenarios.forEach((scenario, index) => {
        if (index % 3 == 0) {
          nestedScenarios.push([scenario])
        } else {
          nestedScenarios[nestedScenarios.length - 1].push(scenario)
        }
      })
      return  nestedScenarios
    },
    isSynchronizing: function () {
      return this.controller.synchronizeController.isSynchronizing
    },
    synchronizeStatusMessage: function () {
      return this.controller.synchronizeController.synchronizeStatusMessage
    },
    scenarioEditorUrl() {
      if (this.controller.selectedScenario != null) {
        return this.controller.scenarioEditorUrl + this.controller.selectedScenario.id
      }
    },
    isAllSelected() {
      return !this.controller.scenarios.some(it => this.multiSelectedScenario.findIndex(msi => it.id === msi.id) <= -1)
    },
    inputSuccessMessage() {
      return (count) => this.$t('inputCompletion.transferSucceeded') + count
    }
  },
  methods: {
    copyScenario(){
      this.$refs.copyConfirmModal.hide()
      this.showProgress(this.controller.i18n.t("general.saving"))
      this.controller.copyScenario(
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
        .then(() => {
          this.hideProgress()
        })
        .catch((error) => {
          this.hideProgress()
          this.showError(error.response.data.message)
        })
    },
    copyToConfirm () {
      this.$refs.multiOperationConfirmModal.show()
    },
    copyToAgent() {
      let scenarioIds = this.multiSelectedScenario.map(msi=>{return msi.id})
      this.$refs.multiOperationConfirmModal.hide()
      this.showProgress(this.controller.i18n.t("general.saving"))
      this.controller.copyMultipleScenario(
        csrfToken.getCsrfTokenFromCookie(document.cookie),
        scenarioIds,
        this.controller.itemAgent.value
      )
        .then(() => {
          this.hideProgress()
          this.toggleMultiSelect()
        })
        .catch((error) => {
          this.hideProgress()
          this.showError(error.response.data.message)
          this.toggleMultiSelect()
        })
    },
    toggleMultiSelect(){
      this.controller.itemAgent = { value: null, text: ''}
      this.multiSelecting = !this.multiSelecting
      if(this.multiSelecting) {
        this.startMultiSelect()
      } else {
        this.cancelMultiSelect()
      }
    },
    startMultiSelect(){
      this.tempSelectedScenario = this.controller.selectedScenario
      this.controller.selectScenario(null)
    },
    cancelMultiSelect(){
      if (this.tempSelectedScenario && this.controller.scenarios.some(msi=>msi.id === this.tempSelectedScenario.id)) {
        this.controller.selectScenario(this.tempSelectedScenario)
      }
      this.tempSelectedScenario = null
      this.multiSelectedScenario = []
    },
    toggleAllSelect() {
      const allSelected = this.isAllSelected
      this.controller.scenarios.forEach(it => {
        const intentIndex = this.multiSelectedScenario.findIndex(msi => msi.id === it.id)
        if (allSelected && intentIndex > -1) {
          this.multiSelectedScenario.splice(intentIndex, 1)
        }
        else if (!allSelected && intentIndex <= -1) {
          this.multiSelectedScenario.push(it)
        }
      })
    },
    selectAgent(agent){
      this.controller.itemAgent = agent
    },
    showProgress(message) {
      this.isCard = false
      this.isBotBroken = false
      this.botBrokenMessage = ''
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    showError(message, isCard=false) {
      if(!isCard){this.message = message}
      this.isCard = isCard
      this.$refs.errorModal.show()
    },
    toggleModalShown() {
      this.isModalShown = !this.isModalShown
      if (!this.isModalShown) {
        this.$refs.scenarioIndex.focus()
      }
    },
    showCreateScenarioModal(scenario=null) {
      this.$refs.createScenarioModal.show(scenario)
    },
    saveScenario(scenario) {
      this.showProgress(this.controller.i18n.t("general.saving"))
      this.controller.saveScenario(
        scenario,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
        .then(() => {
          // 成功なのでプログレスを隠す
          this.hideProgress()
        })
        .catch((errorMessage) => {
          this.hideProgress()
          this.showError(errorMessage)
        })
    },
    deleteScenario() {
      let result, message, promise
      [result, message, promise] = this.controller.deleteScenario(
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (result) {
        this.showProgress(message)
        promise
          .then(() => {
            this.hideProgress()
          })
          .catch((error) => {
            this.showError(error.response.data[0])
          })
      } else {
        this.hideProgress()
        this.showError('')
      }
    },
    closeSyncCompletedModal() {
      this.$refs.syncCompletedModal.hide()
    },
    startSynchronize(isCard=false) {
      this.showProgress("...")
      // 同期を開始したときにpromiseを受け取り、そのpromiseから同期が開始したか、メッセージを受け取る
      let self = this
      this.controller.startSynchronize(
        csrfToken.getCsrfTokenFromCookie(document.cookie),
        isCard
      )
        .then((result) => {
          if (result.wasSuccessful) {
            self.showProgress(result.message)
          } else {
            self.hideProgress()
            self.showError(result.message)
          }
        })
        .catch((error) => {
          self.hideProgress()
          if( 'message' in error.response.data){
            self.showError(error.response.data.message)
          }else{
            this.cardErrorMsg = error.response.data
            self.showError(this.cardErrorMsg, true)
          }
        })
    },
    selectScenario(scenario) {
      if (this.multiSelecting) {
        const index = this.multiSelectedScenario.findIndex(msi => msi.id === scenario.id)
        if (index > -1) {
          this.multiSelectedScenario.splice(index, 1)
        } else {
          this.multiSelectedScenario.push(scenario)
        }
        return
      }
      this.controller.selectScenario(scenario)
    },
    editScenario() {
      this.toggleModalShown()
      this.$refs.createScenarioModal.show(this.controller.selectedScenario)
    },
    showDeleteScenarioModal() {
      this.$refs.deleteScenarioModal.show()
    },
    getScenarioName(name) {
      if (name.length < 20) {
        return name
      } else {
        return name.slice(0,20) + '...'
      }
    },
    dblClickShowScenarioEditor(){
      window.location.href = this.controller.scenarioEditorUrl + this.controller.selectedScenario.id
    },
    checkAgentAlive() {
      const query = {
        'text': '@#$%^aduwenaf--1389371',
        'language':this.controller.getLanguageCode()
      }
      const token = csrfToken.getCsrfTokenFromCookie(document.cookie)
      this.controller.checkAgentState(query, token).then(response => {
        this.isBotBroken = false
        if(!response){
          this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
        }
      }).catch(error => {
        console.log('[intentEditor->checkAgentAlive]: ', error)
        this.isBotBroken = false
        this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
      })
    },
    async inputPredictionTransfer () {
      try {
        const result = await this.controller.inputPredictionTransfer(csrfToken.getCsrfTokenFromCookie(document.cookie))
        this.inputTransportResult.message = this.inputSuccessMessage(result.data)
        this.inputTransportResult.type = 'success'
      } catch (e) {
        this.inputTransportResult.message =  e.response.data instanceof Array ? e.response.data[0] : e.response.data.detail
        this.inputTransportResult.type = 'danger'
      }
    },
    inputResetMessage () {
      this.inputTransportResult = {
        message: null,
        type: null
      }
    }
  },
  watch: {
    'controller.selectedScenario'(){
      if (!this.controller.selectedScenario)
        return
      this.multiSelecting = false
      this.tempSelectedScenario = null
      this.cancelMultiSelect()
    },
    isSynchronizing: {
      handler: function(isSynchronizing) {
        // 同期終了時にプログレスを閉じる
        if (!isSynchronizing) {
          this.hideProgress()
        }
      }
    },
    synchronizeStatusMessage: {
      handler: function(synchronizeStatusMessage) {
        // ステータスメッセージが変更されたらmessageに同期
        if (synchronizeStatusMessage != null) {
          this.message = synchronizeStatusMessage
          if(SynchronizeStatus.ERROR == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.isBotBroken = true
            this.showError(this.message)
            this.checkAgentAlive()
          }
          else if(SynchronizeStatus.BUSY == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.showError(this.message)
          }
          else if(SynchronizeStatus.FINISH == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.$refs.syncCompletedModal.show()
            if(this.controller.auto_input_predict){
              this.inputPredictionTransfer()
            }
          }
        }
      }
    }
  }
}

</script>
<style scoped>
  .stick-to-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
  }
</style>
