/**
 * レスポンスタイプ
 */
const ResponseType = Object.freeze({
  TEXT: 0,
  CARD: 1,
  QUICK_REPLIES: 2,
  IMAGE: 3,
  CUSTOM_PAYLOAD: 4,
  VIDEO:5,
  AUDIO: 6,
  STICKER: 7,
  CARD_WECHAT: 101,
  CARD_GOOGLE: 201,
  LIST: 202,
  SUGGESTION_CHIPS: 203,
  CAROUSEL_GOOGLE: 204,
  LINK: 205,
  MEDIA_CONTENT: 206,
  TABLE_CARD: 207,
  BROWSE_CAROUSEL: 208,
  BASIC_CARD: 301, //kakaoTalkは301番から
  COMMERCE_CARD: 302,
  LIST_CARD: 303,
  QUICK_SUGGESTION: 304,
  ALEXA_CARD: 401, //alexaは401番から
  ALEXA_LIST: 402,
  CAROUSEL_OPTIONS: 501,
  CALL_INTENT: 502,
  SEND_FILE: 601,
})

const ResponseTypeGroup = Object.freeze({
  COMMON: [ResponseType.TEXT, ResponseType.CARD, ResponseType.QUICK_REPLIES, ResponseType.IMAGE, ResponseType.CUSTOM_PAYLOAD, ResponseType.CALL_INTENT],
  GOOGLE: [ResponseType.CARD_GOOGLE, ResponseType.LIST, ResponseType.SUGGESTION_CHIPS, ResponseType.CAROUSEL_GOOGLE, ResponseType.LINK, ResponseType.MEDIA_CONTENT, ResponseType.TABLE_CARD, ResponseType.BROWSE_CAROUSEL],
  KAKAO: [ResponseType.BASIC_CARD, ResponseType.COMMERCE_CARD, ResponseType.LIST_CARD, ResponseType.QUICK_SUGGESTION],
  ALEXA: [ResponseType.ALEXA_CARD, ResponseType.ALEXA_LIST],
  WECHAT: [ResponseType.CARD_WECHAT],
  OTHERS: [ResponseType.VIDEO]
})

export {
  ResponseType,
  ResponseTypeGroup
}
