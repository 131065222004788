import { ResponseType, ResponseTypeGroup } from "./responses";

const PlatformDataMap = Object.freeze({
  WEBCHAT: {
    serviceId: 1, // Backend、CMSでのID
    name: "web_chat", // Integrationでのプラットフォーム名
    title: "WebChat", // Titleで表示用のテキスト
    translation: "history.webChat", // i18n用の文字列
    faIcon: "far fa-comments", // faアイコンのclass
    response: {
      custom: false, // カスタムのプラットフォームかどうか示す
      dialogflowPlatform: "skype", // Dialogflowで利用するプラットフォーム、 概ねintegrationと一緒だが、skypeとapi_serviceが特別
      payloadKey: "skype", // custom_payloadでの識別キー
      types: ResponseTypeGroup.COMMON, // 回答タイプ
    },
    userMeta: null, // session user metaを利用する場合の各属性名、利用しない場合はnull
    analytics: {
      label: "Web Chat", // 統計分析詳細データ対応カラムのラベル名
      key: null, // Backendから来たデータの対応プラットフォームの属性名
    },
  },
  LINE: {
    serviceId: 2,
    name: "line",
    title: "LINE",
    translation: "history.line",
    faIcon: "fab fa-line",
    response: {
      custom: false,
      payloadKey: "line",
      types: ResponseTypeGroup.COMMON.concat([
        ResponseType.VIDEO,
        ResponseType.CAROUSEL_OPTIONS,
        ResponseType.STICKER,
      ]),
    },
    userMeta: {
      fieldName: "lineId", // front側の属性名
      serverFieldName: "line_id", // backendから来たデータの属性名
      name: "line",
    },
    analytics: {},
  },
  FACEBOOK: {
    serviceId: 3,
    name: "facebook",
    title: "Facebook",
    translation: "history.facebook",
    faIcon: "fab fa-facebook",
    response: {
      custom: false,
      payloadKey: "facebook",
      types: ResponseTypeGroup.COMMON.concat([ResponseType.VIDEO]),
    },
    userMeta: {
      fieldName: "facebookId",
      serverFieldName: "facebook_id",
      name: "facebook",
    },
    analytics: {},
  },
  WECHAT: {
    serviceId: 4,
    name: "wechat",
    title: "Wechat",
    translation: "history.weChat",
    faIcon: "fab fa-weixin",
    response: {
      custom: true,
      payloadKey: "wechat",
      types: ResponseTypeGroup.WECHAT.concat([
        ResponseType.TEXT,
        ResponseType.CALL_INTENT,
        ResponseType.CUSTOM_PAYLOAD,
      ]),
    },
    userMeta: null,
    analytics: { label: "We Chat" },
  },
  // SKYPE: null,
  GOOGLE: {
    serviceId: 6,
    name: "actions_on_google",
    title: "Google",
    translation: "history.googleAssistance",
    faIcon: "fab fa-google",
    response: {
      custom: false,
      payloadKey: "google",
      types: ResponseTypeGroup.GOOGLE.concat([
        ResponseType.TEXT,
        ResponseType.CALL_INTENT,
        ResponseType.CUSTOM_PAYLOAD,
      ]),
    },
    userMeta: {
      fieldName: "googleId",
      serverFieldName: "actions_on_google_id",
      name: "actions_on_google",
    },
    analytics: {},
  },
  API_SERVICE: {
    serviceId: 7,
    name: "api",
    title: "Api-Service",
    translation: "history.apiService",
    faIcon: "fa fa-cogs",
    response: {
      custom: true,
      dialogflowPlatform: "api_service",
      payloadKey: "api",
      types: ResponseTypeGroup.COMMON.concat([ResponseType.CAROUSEL_OPTIONS]),
    },
    userMeta: null,
    analytics: {
      label: "Api Service",
      key: "api",
    },
  },
  KAKAOTALK: {
    serviceId: 8,
    name: "kakaotalk",
    title: "KakaoTalk",
    translation: "history.kaTalk",
    faIcon: "fas fa-comment",
    response: {
      custom: true,
      payloadKey: "kakao",
      types: ResponseTypeGroup.KAKAO.concat([
        ResponseType.TEXT,
        ResponseType.IMAGE,
        ResponseType.CALL_INTENT,
        ResponseType.CUSTOM_PAYLOAD,
      ]),
    },
    userMeta: null,
    analytics: { label: "Kakao Talk" },
  },
  ALEXA: {
    serviceId: 9,
    name: "alexa",
    title: "Alexa",
    translation: "history.alexa",
    faIcon: "fas fa-volume-up",
    response: {
      custom: true,
      payloadKey: "alexa",
      types: ResponseTypeGroup.ALEXA.concat([
        ResponseType.TEXT,
        ResponseType.CALL_INTENT,
        ResponseType.CUSTOM_PAYLOAD,
      ]),
    },
    userMeta: null,
    analytics: {},
  },
  SLACK: {
    serviceId: 11,
    name: "slack",
    title: "Slack",
    translation: "history.slack",
    faIcon: "fab fa-slack",
    response: {
      custom: false,
      platform: "SLACK",
      payloadKey: "slack",
      types: ResponseTypeGroup.COMMON,
    },
    userMeta: {
      fieldName: "slackId",
      serverFieldName: "slack_id",
      combined: true,
      name: "slack",
    },
    analytics: {},
  },
  INCIRCLE: {
    serviceId: 12,
    name: "incircle",
    title: "InCircle",
    translation: "history.incircle",
    faIcon: "far fa-comment-dots",
    response: {
      custom: true,
      payloadKey: "incircle",
      types: [
        ResponseType.TEXT,
        ResponseType.IMAGE,
        ResponseType.CALL_INTENT,
      ],
    },
    userMeta: {
      fieldName: "incircleId",
      serverFieldName: "incircle_id",
      combined: true,
      name: "incircle",
    },
    analytics: {},
  },
  MS_TEAMS: {
    serviceId: 13,
    name: "ms_teams",
    title: "MS Teams",
    translation: "history.msTeams",
    faIcon: "fab fa-microsoft",
    response: {
      custom: true,
      payloadKey: "ms_teams",
      types: ResponseTypeGroup.COMMON,
    },
    userMeta: {
      fieldName: "msTeamsId",
      serverFieldName: "ms_teams_id",
      name: "ms_teams",
    },
    analytics: { label: "Microsoft Teams" },
  },
  CHATWORK: {
    serviceId: 14,
    name: "chatwork",
    title: "Chatwork",
    translation: "history.chatwork",
    faIcon: "fas fa-comments",
    response: {
      custom: true,
      payloadKey: "chatwork",
      types: [
        ResponseType.TEXT,
        ResponseType.CALL_INTENT,
        ResponseType.CUSTOM_PAYLOAD
      ],
    },
    userMeta: null,
    analytics: {},
  },
  WEBCHAT_V2: {
    serviceId: 15, // Backend、CMSでのID
    name: "web_chat_v2", // Integrationでのプラットフォーム名
    title: "WebChat_V2", // Titleで表示用のテキスト
    translation: "history.webChatV2", // i18n用の文字列
    faIcon: "far fa-comment-alt", // faアイコンのclass
    response: {
      custom: true, // カスタムのプラットフォームかどうか示す
      dialogflowPlatform: "web_chat_v2", // Dialogflowで利用するプラットフォーム、 概ねintegrationと一緒だが、skypeとapi_serviceが特別
      payloadKey: "web_chat_v2", // custom_payloadでの識別キー
      types: ResponseTypeGroup.COMMON.concat([
        ResponseType.CAROUSEL_OPTIONS,
        ResponseType.AUDIO,
        ResponseType.VIDEO,
      ]), // 回答タイプ
    },
    userMeta: {
      fieldName: "webChatV2Id",
      serverFieldName: "web_chat_v2_id",
      name: "web_chat_v2",
    },
    analytics: {
      label: "Web Chat V2", // 統計分析詳細データ対応カラムのラベル名
      key: null, // Backendから来たデータの対応プラットフォームの属性名
    },
  },
});

/**
 * 毎回全てのデータをロードせずに、機能ごとに必要な情報だけロードするように、情報をクラスの属性とする
 */
export default class PlatformDataResource {
  constructor(availablePlatform = null) {
    this.resource = new Map();
    // プラットフォームのキーと詳細をMapに格納。
    for (const [platform, info] of Object.entries(PlatformDataMap)) {
      this.resource.set(platform, info);
    }

    // アイコン情報
    this._platformIcons = null;

    // レスポンス送信先サービス
    this._targetServices = null;

    // 一括プラットフォーム追加での選択肢
    this._platformOptions = null;

    // 会話履歴でのプラットフォーム選択肢
    this._historyOptions = null;

    // Try it nowでのプラットフォーム選択肢
    this._chatBoxResponseOptions = null;

    // セッションごとの会話履歴、Try it nowで回答内容を表示するための情報
    this._chatResponsePlatforms = null;

    // セッションごとの会話履歴、Try it nowで回答内容を表示するためのコンポネント情報
    this._chatResponseItems = null;

    // セッションごとの会話履歴画面のコントローラーで利用するプラットフォームリスト
    this._conversationPlatformList = null;

    // 統計分析、ユーザー数詳細画面でのカラム情報
    this._analyticsPlatformColumns = null;

    // session user metaでのカラム情報
    this._userMetaPlatformsData = null;

    // 各回答タイプのプラットフォームリスト
    this._responseTypesByPlatform = null;

    // 有効なプラットフォーム
    this._availablePlatform = availablePlatform;
  }

  /**
   * resourceはキーにプラットフォーム名のキー、バリューにプラットフォームごとの詳細を持つMapオブジェクト。
   * resourceの各アイテムに引数で渡された関数を実行し結果を返す。
   */
  _mapTraversal(processFunction, results) {
    this.resource.forEach((data, platform) => {
      processFunction(data, platform, results, this._availablePlatform);
    });
    return results;
  }

  get PlatformIcons() {
    if (!this._platformIcons) {
      this._platformIcons = this._mapTraversal(this._setPlatformIcon, {});
    }
    return this._platformIcons;
  }

  _setPlatformIcon(data, platform, results) {
    results[platform] = {
      name: data.name,
      text: data.title,
      translation: data.translation,
      faIcon: data.faIcon,
    };
  }

  get TargetService() {
    if (!this._targetServices) {
      this._targetServices = this._mapTraversal(this._setTargetService, {});
    }
    return this._targetServices;
  }
  _setTargetService(data, platform, results) {
    results[platform] = data.serviceId;
  }

  get PlatformOptions() {
    if (!this._platformOptions) {
      this._platformOptions = this._mapTraversal(
        this._appendPlatformOption,
        []
      );
    }
    return this._platformOptions;
  }
  _appendPlatformOption(data, platform, results) {
    results.push({ value: data.serviceId, text: data.title });
  }

  get HistoryOptions() {
    if (!this._historyOptions) {
      this._historyOptions = this._mapTraversal(this._appendHistoryOption, [
        { value: null, translation: "history.chooseChatUi" },
      ]);
    }
    return this._historyOptions;
  }
  _appendHistoryOption(data, platform, results) {
    results.push({ value: data.name, translation: data.translation });
  }

  get ChatBoxResponseOptions() {
    if (!this._chatBoxResponseOptions) {
      this._chatBoxResponseOptions = this._mapTraversal(
        this._appendChatBoxResponseOption,
        [],
      );
    }
    return this._chatBoxResponseOptions;
  }
  _appendChatBoxResponseOption(data, platform, results, availablePlatform) {
    if (availablePlatform.includes(data.serviceId)) {
      results.push({
        value: data.response.dialogflowPlatform || data.name,
        text: data.name.toUpperCase(),
      });
    }
  }

  get ChatResponsePlatforms() {
    if (!this._chatResponsePlatforms) {
      // 存在しない場合は初期化する。
      this._chatResponsePlatforms = this._mapTraversal(
        this._setChatResponsePlatform,
        {}
      );
    }
    return this._chatResponsePlatforms;
  }

  _setChatResponsePlatform(data, platform, results) {
    results[platform] = { platforms: [data.name] };
    if (
      data.response.dialogflowPlatform &&
      !results[platform].platforms.includes(data.response.dialogflowPlatform)
    ) {
      results[platform].platforms.push(data.response.dialogflowPlatform);
    }
    if (
      data.response.payloadKey &&
      !results[platform].platforms.includes(data.response.payloadKey)
    ) {
      results[platform].platforms.push(data.response.payloadKey);
    }

    // ChatResponseコンポネント内で使用するコンポネントのタグ文字列
    results[platform].componentName = data.response.component.name;
  }

  _setChatResponseItem(data, platform, results) {
    results[data.response.component.name] = data.response.component.item;
  }

  get ConversationPlatformList() {
    if (!this._conversationPlatformList) {
      this._conversationPlatformList = this._mapTraversal(
        this._appendConversationPlatform,
        []
      );
    }
    return this._conversationPlatformList;
  }
  _appendConversationPlatform(data, platform, results) {
    results.push(data.name);
  }

  get AnalyticsPlatformColumns() {
    if (!this._analyticsPlatformColumns) {
      this._analyticsPlatformColumns = this._mapTraversal(
        this._appendAnalyticsPlatformColumn,
        []
      );
    }
    return this._analyticsPlatformColumns;
  }
  _appendAnalyticsPlatformColumn(data, platform, results) {
    results.push({
      key: data.analytics.key || data.name,
      label: data.analytics.label || data.title,
      platform: true,
      fa_icon: data.faIcon,
    });
  }

  get UserMetaPlatformsData() {
    if (!this._userMetaPlatformsData) {
      this._userMetaPlatformsData = this._mapTraversal(
        this._appendUserMetaPlatform,
        []
      );
    }
    return this._userMetaPlatformsData;
  }
  _appendUserMetaPlatform(data, platform, results) {
    if (data.userMeta) {
      results.push(data.userMeta);
    }
  }

  get ResponseTypesByPlatform() {
    if (!this._responseTypesByPlatform) {
      const results = {};
      for (const [, value] of Object.entries(ResponseType)) {
        const result = [];
        this.resource.forEach((data) => {
          if (data.response.types.indexOf(value) !== -1) {
            result.push({ value: data.serviceId, text: data.title });
          }
        });
        results[value] = result;
      }
      this._responseTypesByPlatform = results;
    }
    return this._responseTypesByPlatform;
  }
}
