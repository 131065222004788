import axios from "axios/index"
import { VIEW_URL } from '../resource/urls'
import { I18n } from '../util/i18n'
import { RestApi } from '../util/rest-api'
import { Intent } from '../model/intent'

class RedirectorController {

  constructor(apiUrl, requestContext, redirectorContext, i18nContext) {
    this.userType = requestContext.userType
    this.language = requestContext.language
    this.getRedirectUrlApi = apiUrl.getRedirectUrl
    this.allRedirectUrlsApi = apiUrl.allRedirectUrls
    this.getRelatedIntentsApi = new RestApi(apiUrl.getRedirectorRelatedIntents, Intent)
    this.textHeader = redirectorContext.redirectorHost + '/'
    this.allRedirectUrls = redirectorContext.redirect_urls
    this.i18n = new I18n(i18nContext)
    this.totalUrl = this.allRedirectUrls.length
    this.selectedIntentCategories = null
    this.accessLogUrl = 'redirector/access_log/'
    // アクセスログの日付によるフィルター用の値
    this.date = {
      from_date: '',
      to_date: ''
    }
  }

  /**
   * リダイレクターURLの取得
   */
  loadRedirectUrls () {
    let url = this.allRedirectUrlsApi
    // 日付の絞り込みが指定されている場合はurlのquery parametersに指定
    if(!!this.date.from_date && !!this.date.to_date){
      url += `?start_date=${this.date.from_date}&end_date=${this.date.to_date}`
    }
    return axios.get(
      url
    ).then((response) => {
      this.allRedirectUrls = response.data
    })
  }

  /**
   * リダイレクタURLの作成
   */
  createRedirectUrl (url, csrfToken=null) {
    let sendOptions = {}
    let options = {}
    let params = {
      url: url
    }
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.getRedirectUrlApi,
        params,
        sendOptions
      ).then(() => {
        this.loadRedirectUrls().catch(err => {
          reject(err)
        })
      }).catch(e => {
        reject(e)
      })
    })
    return promise
  }

  /**
   * Export Redirect Urls
   * Download file: CSV, Excel
   *
   * @param mimeType
   * @returns {string}
   */
  fileExport(mimeType='') {
    const url = VIEW_URL.ExportRedirectUrls
    return url.replace('<mime_type>', mimeType.toLowerCase())
  }

  getLanguageCode() {
    return this.language ? this.language : this.i18n.context.locale
  }

  /**
   * Get related intents by original url of redirector
   *
   * @param redirector
   */
  fetchIntentListInUse(redirector) {
    this.selectedIntentCategories = null
    const params = {
      "search": redirector ? redirector.original_url : ""
    }
    this.getRelatedIntentsApi.list({params}).then(categories => {
      this.selectedIntentCategories = categories
    }).catch(error => {
      console.log("Redirector [fetchIntentListInUse]: ", error)
    })
  }
}

export {
  RedirectorController
}
