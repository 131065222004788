<template>
<li>
  <div
    class="label my-1 p-1"
    :class="selected"
    :style="isCategoryVisible"
    @click="$emit('select-category', category)"
    @dblclick="userType !='viewer' ? $emit('edit-category', category) : null"
    @keyup.delete="userType !='viewer' ? $emit('delete-category', category) : null"
    tabindex="-1">
    <div v-if="!category.parentId&&subCategorieItems.length" class="ct-toggle"  @click.stop="folded=!folded" @dblclick.stop="">
      <span class="float-none pl-1" v-show="!folded"><i class="fas fa-caret-down"></i></span>
      <span class="float-none pl-1" v-show="folded"><i class="fas fa-caret-up"></i></span>
    </div>
    {{ category.name }}
    <b-badge v-if="category.total_count" variant="info">
      {{category.total_count}}
    </b-badge>
  </div>
  <transition name="fade">
    <ul v-show="folded">
        <category-item
          class="category-item"
          :class="selected"
          v-for="(category, index) in subCategorieItems"
          :key="index"
          :category="category"
          :selected-category="selectedCategory"
          :categorydesc="categorydesc"
          :currentDisplayedCategory="currentDisplayedCategory"
          :userType="userType"
          :searchCategoryWord="searchCategoryWord"
          @select-category="selectChild"
          @edit-category="editChild"
          @delete-category="deleteChild">
        </category-item>
    </ul>
  </transition>
</li>
</template>

<script>
export default {
  props: ['category', 'selectedCategory', 'currentDisplayedCategory','categorydesc','userType', 'searchCategoryWord'],
  data() {
    return {
      folded: true
    }
  },
  computed: {
    subCategorieItems(){
      if(this.categorydesc){
        return this.category.subcategories.sort(this.dynamicSort("name"))
      }
      else{
        return this.category.subcategories.sort(this.dynamicSort("-name"))
      }
    },
    selected() {
      if (!this.category || !this.currentDisplayedCategory) {
        return {selected: false}
      }
      return {selected: this.category.id == this.currentDisplayedCategory.id}
    },
    isCategoryVisible() {
      let status = {}
      if(this.searchCategoryWord) {
        const lowercaseCategoryName = this.category.name.toLowerCase()
        status = lowercaseCategoryName.includes(this.searchCategoryWord) ? {} : {display: 'none'}
      }
      return  status
    }
  },
  methods: {
    selectChild(category) {
      this.$emit('select-category', category)
    },
    editChild(category) {
      this.$emit('edit-category', category)
    },
    deleteChild(category){
      this.$emit('delete-category', category)
    },
    dynamicSort(property) {
      let sortOrder = 1;

      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }

      return function (a,b) {
          if(sortOrder == -1){
              return b[property].localeCompare(a[property]);
          }else{
              return a[property].localeCompare(b[property]);
          }
      }
    }
  }
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
