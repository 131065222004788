import Vue from 'vue'
import { RedirectorLogController } from "./controller/redirector-log"

import RedirectorLog from "./components/RedirectorLog.vue"
import moment from 'vue-moment'


export const appRedirectorLog = (i18n) => {
  Vue.use(moment)

  const controller = new RedirectorLogController(
    window.requestContext.userType,
    window.i18nContext,
    window.requestContext.logs
  )

  Vue.component('redirector-log', RedirectorLog)

  new Vue({
    el: '#app-redirector-log',
    i18n: i18n,
    data: {
      controller: controller
    }
  })
}
