<template>
   <div class="answer-quickSuggestion">
      <div>
        <div>{{ $t('answer.content') }}:</div>
        <draggable v-model="quickSuggestion" handle=".handle">
          <div v-for="(reply, index) in quickSuggestion">
              <b-input-group>
               <b-form-input v-model="reply.label" class="mb-1 mr-1"
                             @input="validationCheck"
                             :placeholder="$t('answer.cardButtonTitle')" style="width: 35px"
                             :state="!$v.quickSuggestion.$each[index].label.$error">
               </b-form-input>
               <b-form-input v-model="reply.messageText" class="mb-1 mr-1"
                             @input="validationCheck"
                             :placeholder="$t('answer.typeText')" style="width: 120px"
                             :state="!$v.quickSuggestion.$each[index].messageText.$error">
               </b-form-input>
               <div class="input-group-append">
                  <button
                     class="btn btn-danger"
                     style="height: 38px"
                     :disabled="quickSuggestion.length == 1"
                     @click.prevent="deleteReplyText(index)">
                     <i class="fa fa-minus"></i>
                  </button>
               </div>
               <span class="handle handle-btn" style="height: 38px"><i class="fas fa-arrows-alt"></i></span>
                <b-form-invalid-feedback v-if="$v.quickSuggestion.$each[index].label.$error || $v.quickSuggestion.$each[index].messageText.$error">
                  {{ $t('webhook.required') }}
                </b-form-invalid-feedback>
              </b-input-group>
          </div>
        </draggable>
        <div class="mt-2 mb-2">
          <button
            class="btn btn-success"
            :disabled="quickSuggestion.length == 10"
            @click.prevent="addReplyText">
            <i class="fa fa-plus"></i>
          </button>
          ({{ quickSuggestion.length + " / 10" }})
        </div>
      </div>
   </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import {QuickSuggestionText,QuickSuggestionData} from '../../model/intent'
import draggable from 'vuedraggable'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      answerQuickSuggestion:null,
      quickSuggestion:[new QuickSuggestionText()]
    }
  },
  validations: {
    quickSuggestion: {
      $each: {
        label: {
          required
        },
        messageText: {
          required
        }
      }
    }
  },
  methods:{
    addReplyText(){
      if (this.quickSuggestion.length < 10) {
        this.quickSuggestion.push(new QuickSuggestionText())
        this.validationCheck()
      }
    },
    deleteReplyText(index){
      this.quickSuggestion.splice(index, 1)
      this.validationCheck()
    },
    inputValue(){
      this.answerQuickSuggestion = new QuickSuggestionData(
            this.quickSuggestion,)
      for(let reply of this.answerQuickSuggestion.replies){
        reply.label = reply.label.replace(illegalExp, "").trim()
        reply.messageText = reply.messageText.replace(illegalExp, "").trim()
      }
      return this.answerQuickSuggestion
    },
    setValue(selectedResponseMessage){
      this.quickSuggestion = selectedResponseMessage.data.replies
    },
    initializeValue(){
      this.answerQuickSuggestion=null
      this.quickSuggestion=[new QuickSuggestionText()]
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    }
  }
}
</script>
