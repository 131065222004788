const maxZoomScale = 1.4
const minZoomScale = 0.2
const ANSWER_LABEL_WEBHOOK = '(WEBHOOK)'
const NODE_LABEL_EMPTY = '(EMPTY)'
const START_NODE_ID = 'startNode'
const NODE_TYPE_QUESTION = 'qad.Question'
const NODE_TYPE_ANSWER = 'qad.Answer'

export {
  maxZoomScale,
  minZoomScale,
  ANSWER_LABEL_WEBHOOK,
  NODE_LABEL_EMPTY,
  START_NODE_ID,
  NODE_TYPE_QUESTION,
  NODE_TYPE_ANSWER
}
