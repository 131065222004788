import axios from 'axios'

class InputCompletionController{
  constructor(apiUrl=null, context=null) {
    this.userSaysSchronizeApi = apiUrl.userSaysSynchronize
    this.secretKey = context.secretKey
  }

  startTransfer(csrfToken=null) {
    const options = {
      headers: {'X-CSRFToken': csrfToken}
    }
    return new Promise((resolve, reject) =>
      axios.post(this.userSaysSchronizeApi, {}, options)
        .then(res => resolve(res)).catch(err => reject(err))
      )
    }
}

export {
  InputCompletionController
}
