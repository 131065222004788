<template>
  <div>
    <div class="input-group" v-if="showInput">
      <input type="text" :class="{'is-invalid': !isTagEmpty && errorMessage}"
             class="form-control" :placeholder="tagInputPlaceHolder" v-model="newTag" @keyup.enter.prevent="appendNew">
      <div class="input-group-append">
        <button class="btn btn-success" type="button" @click="appendNew" :disabled="disabled">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div v-if="!isTagEmpty && errorMessage" class="invalid-feedback">{{ errorMessage }}</div>
    </div>
    <div class="my-1">
      <form-tag v-for="(tag, index) in tags" :key="index"
        :content="tag"
        :colorClass="colorClass"
        @remove-me="removeByIndex(index)"
      >
      </form-tag>
    </div>
  </div>
</template>

<script>
  import FormTag from './parts/FormTag.vue'
  export default {
      components: {
          'form-tag': FormTag
      },
      props: ['tags', 'colorClass', 'excludeList', 'rule', 'maxTags', 'tagMaxLength',
              'builtInMessage', 'duplicatedMessage', 'invalidMessage', 'placeholder'],
      data() {
          return {
              newTag: '',
              regexStr: this.rule || null,
              maximum: this.maxTags || 10,
              lengthLimit: this.tagMaxLength || 20,
              ErrorMessages: {
                  BUILT_IN: this.builtInMessage || 'This is a built-in tag.',
                  DUPLICATED: this.duplicatedMessage || 'Tag exists.',
                  INVALID: this.invalidMessage || 'Invalid tag name'
              },
              tagInputPlaceHolder: this.placeholder || 'Tag name'
          }
      },
      computed: {
        showInput() {
            return !(this.tags && this.tags.length >= this.maximum)
        },
        isError() {
            return (this.excludeList && this.newTag && this.excludeList.indexOf(this.newTag.toLowerCase()) > -1)
        },
        isDuplicated() {
            return (this.newTag && this.tags.indexOf(this.newTag.toLowerCase()) > -1)
        },
        isValid() {
            if (this.newTag && this.newTag.length <= this.lengthLimit) {
                if (this.regexStr) {
                    const regex = new RegExp(this.regexStr)
                    if (!regex.test(this.newTag)) {
                        return false
                    }
                }
                return true
            }
            return false
        },
        isTagEmpty() {
            return !this.newTag || this.newTag.length === 0
        },
        disabled() {
            return this.isError || this.isDuplicated || !this.isValid
        },
        errorMessage() {
            if (this.isError) {
                return this.ErrorMessages.BUILT_IN
            }
            if (this.isDuplicated) {
                return this.ErrorMessages.DUPLICATED
            }
            if (!this.isValid) {
                return this.ErrorMessages.INVALID
            }
            return null
        }
      },
      methods: {
          appendNew() {
              if (this.disabled) {
                  return
              }
              if (!this.tags) {
                  this.tags = []
              }
              this.tags.push(this.newTag)
              this.newTag = ''
          },
          removeByIndex(index) {
              this.tags.splice(index, 1)
          }
      }
  }
</script>

<style scoped>

</style>
