<template>
    <div class="analytics-charts-container">
      <div class="analytics-chart">
        <b-badge v-b-modal.bar-chart-language-selector variant="info"
          class="pointer-cursor language-change" :title="$t('analytics.clickToChangeLanguage')">
          <i class="fas fa-language"></i>
        </b-badge>
        <bar-chart
          v-if="!languageSelecting"
          :data="barChart.data"
          :options="barChart.options"
        ></bar-chart>
        <div class="d-flex h-100 align-items-center justify-content-center col-12" v-else>
          <h1>{{ $t('analytics.changingLanguage') }}</h1>
        </div>
      </div>
      <pie-chart
        v-for="(dataset, index) in pieChart"
        class="analytics-chart"
        :data="dataset.data"
        :options="dataset.options"
        :key="index"
      ></pie-chart>
      <b-modal id="bar-chart-language-selector" size="sm" hide-footer hide-header centered
        ref="barChartLanguageSelectorModal" @shown="languageSelecting=true" @hidden="languageSelecting=false"
      >
        <b-form-select :options="languageOptions" v-model="selectedLanguage" @change="languageChanged"></b-form-select>
      </b-modal>
    </div>
</template>

<script>
  import BarChart from './charts/BarChart.vue'
  import PieChart from './charts/PieChart.vue'
  import { colorFromString } from '../util/word-color'
  import { LanguageParser } from "../util/languageParser"
  export default {
    props: ['chartsData'],
    components: {BarChart, PieChart},
    data () {
      return {
          selectedLanguage: 'total',
          languageSelecting: false
      }
    },
    computed: {
      languageOptions() {
          return this.chartsData.allLanguages.map(language => ({
              value: language, text: this.$t(LanguageParser.fetchTranslationString(language))
          }))
      },
      barChart() {
        let data = null
        if (this.selectedLanguage) {
            data = this.chartsData.dailyChartData[this.selectedLanguage]
        }
        if (data) {
          const maxUser = Math.max(...data.sessions)
          const userYaxisStepSize = maxUser > 100 ? Math.ceil(maxUser/100)*10 : maxUser > 50 ? 10 : 5
          const barChartData = {
              queries: data.queries,
              sessions: data.sessions,
              unanswered: data.unanswered
          }
          return {
            data: {
              labels: this.chartsData.allDays,
              datasets: [{
                label: this.$t('analytics.query'),
                data: barChartData.queries,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(53, 173, 253, 1)',
                type: 'line',
                lineTension: 0,
                yAxisID: 'lineAxes'
              }, {
                label: this.$t('analytics.unanswered'),
                data: barChartData.unanswered,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(255, 0, 0, 0.3)',
                type: 'line',
                lineTension: 0,
                yAxisID: 'rateAxes'
              }, {
                label: this.$t('analytics.user'),
                data: barChartData.sessions,
                backgroundColor: 'rgba(255, 204, 153, 0.5)',
                yAxisID: 'barAxes'
              }]},
            options: {
              title: {
                  text: `${this.$t(LanguageParser.fetchTranslationString(this.selectedLanguage))}-${this.$t('analytics.dailyDetail')}(${this.$t('analytics.clickToChangeLanguage')})`,
                  display: true, fontSize: 16},
              responsive: true,
              maintainAspectRatio: false,
              tooltips: {
                mode: 'index',
                intersect: false
              },
              scales: {
                yAxes: [
                  {id: 'barAxes', display: true, position: 'right', ticks: {stepSize: userYaxisStepSize},
                   scaleLabel: {display: true, labelString: this.$t('analytics.user')}},
                  {id: 'lineAxes', display: true, ticks: {min: 0},
                   scaleLabel: {display: true, labelString: this.$t('analytics.query')}},
                  {id: 'rateAxes', gridLines: {display: false}, ticks: {min: 0, max: 1},
                   scaleLabel: {display: true, labelString: this.$t('analytics.unanswered')}}
                ],
                xAxes: [
                  {scaleLabel: {display: true, labelString: this.$t('analytics.date')}}
                ]
              }
            }
          }
        } else {
            return null
        }
      },
      pieChart() {
        return [
          {
            data: {
              labels: this.chartsData.sortedByLanguage.languages.map(code => this.$t(LanguageParser.fetchTranslationString(code))),
              datasets: [{
                backgroundColor: this.chartsData.sortedByLanguage.languages.map(lang => colorFromString(LanguageParser.code2lang(lang))),
                data: this.chartsData.sortedByLanguage.sessions
              }],
            },
            options: {
              title: {text: this.$t('analytics.userCountByLanguage'), display: true, fontSize: 20},
              responsive: true, maintainAspectRatio: false
            }
          },
          {
            data: {
              labels: this.chartsData.sortedByLanguage.languages.map(code => this.$t(LanguageParser.fetchTranslationString(code))),
              datasets: [{
                backgroundColor: this.chartsData.sortedByLanguage.languages.map(lang => colorFromString(LanguageParser.code2lang(lang))),
                data: this.chartsData.sortedByLanguage.queries
              }],
            },
            options: {
              title: {text: this.$t('analytics.queryCountByLanguage'), display: true, fontSize: 20},
              responsive: true, maintainAspectRatio: false
            }
          },
          {
            data: {
              labels: Object.keys(this.chartsData.platformsTotal),
              datasets: [{
                backgroundColor: Object.keys(this.chartsData.platformsTotal).map(platform => colorFromString(platform)),
                data: Object.values(this.chartsData.platformsTotal)
              }],
            },
            options: {
              title: {text: this.$t('analytics.userCountByPlatform'), display: true, fontSize: 20},
              responsive: true, maintainAspectRatio: false
            }
          }
        ]
      }
    },
    methods: {
        languageChanged() {
            this.$refs.barChartLanguageSelectorModal.hide()
        }
    }
  }
</script>

<style scoped>

</style>
