<template>
  <div>
    <!--Filter options start-->
    <div class="row mb-2">
      <div class="col-md-3">
        <b-form-input v-model="sessionId" type="text" :placeholder="$t('redirector.enterSession')" />
      </div>
    </div>
    <!--Filter option End-->
    <!-- Column -->
    <div class="d-flex w-100 justify-content-between">
      <h5>{{ $t('redirector.accessLog') }}</h5>
      <p>
        <span class="badge badge-light">{{ $t('redirector.shortID') }}:
          <mark class="text-muted">{{ controller.logs.short_id }}</mark>
        </span>
        <span class="badge badge-light">
          {{ $t('redirector.originalUrl') }}:
          <a class="text-muted font-italic" target="_blank"
             v-bind:href="controller.logs.original_url">
            {{ controller.logs.original_url }}
          </a>
        </span>
      </p>
    </div>
    <div class="row ml-1 mr-1">
      <div class="col-md-1 column border p-2">
        #
      </div>
      <div class="col-md-3 column border p-2">
        {{ $t('redirector.time') }}
      </div>
      <div class="col-md-4 column border p-2">
        {{ $t('redirector.sessionID') }}
      </div>
      <div class="col-md-2 column border p-2">
        {{ $t('redirector.language') }}
      </div>
      <div class="col-md-2 column border p-2">
        {{ $t('redirector.platform') }}
      </div>
    </div>
    <div v-for="(item, index) in filteredList" :key="index">
      <div class="row ml-1 mr-1 border-bottom border-right">
        <div class="col-md-1 p-2 border-left">
          {{(currentPage*perPage - perPage)+index+1}}
        </div>
        <div class="col-md-3 p-2 border-left">
          {{ item.created_at|moment('YYYY-MM-DD HH:mm') }}
        </div>
        <div class="col-md-4 p-2 border-left">
          <a v-bind:href="goToConversationHistory(item.session_id)"
             v-if="item.extras.length > 0" target="_blank">
            {{ item.session_id }}
          </a>
          <span v-else>{{ item.session_id }}</span>
        </div>
        <div class="col-md-2 p-2 border-left">
          <span v-for="(extra, extraIndex) in item.extras" :key="extraIndex"
                v-if="extra.language_code.length > 0"
                class="badge badge-info pointer-cursor mr-1">
            {{extra.language_code}}
          </span>
        </div>
        <div class="col-md-2 p-2 border-left">
          <span v-for="(extra, extraIndex) in uniqueListByPlatform(item.extras)" :key="extraIndex"
                v-if="item.extras.length > 0"
                :id="((currentPage*perPage - perPage)+index+1)+'-'+extraIndex"
                class="badge badge-dark mr-1">
            <b-tooltip v-if="getIconByPlatform(extra)"
              :target="((currentPage*perPage - perPage)+index+1)+'-'+extraIndex"
              :title="$t(getIconByPlatform(extra).translation)"
              :delay="{show: 800, hide: 0}">
            </b-tooltip>
            <i class="fa-2x" :class="getIconByPlatform(extra).faIcon"></i>
          </span>
        </div>
      </div>
    </div>
    <b-container fluid class="bv-example-row mt-3">
      <b-row>
        <b-col md="4" offset-md="5">
          <b-pagination
            v-model="currentPage"
            :total-rows="controller.total"
            :per-page="perPage"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import {I18n} from "../util/i18n"
  import PlatformDataResource from "../resource/platforms"

  export default {
    props: ['controller'],
    data () {
      return {
        sessionId:'',
        currentPage: 1,
        perPage: 25,
        i18n: new I18n(window.i18nContext),
        platformIcons: null
      }
    },
    created() {
      const platformResource = new PlatformDataResource()
      this.platformIcons = platformResource.PlatformIcons
    },
    computed: {
      filteredList(){
        return this.controller.logs.access_logs
          .filter( log => {
            if(log.session_id ===null) {
              log.session_id = '_'
              return log.session_id
            } else{
              return log.session_id.toLowerCase().includes(this.sessionId.toLowerCase())
            }
          })
          .splice((this.currentPage*this.perPage - this.perPage),this.perPage)
      }
    },
    methods: {
      goToConversationHistory(sessionID) {
        const currentUrl = location.href
        const regex = RegExp('redirector.+')
        const destination = `history/sessions?session_id=${sessionID}`
        return currentUrl.match(regex) ? currentUrl.replace(regex, destination) : `javascript:void(0)`
      },
      getIconByPlatform(platformName) {
        for(const key in this.platformIcons) {
          if(this.platformIcons.hasOwnProperty(key)){
            const platformInfo = this.platformIcons[key]
            if(platformInfo.name === platformName){
              return platformInfo
            }
          }
        }
        return {}
      },
      uniqueListByPlatform(element) {
        return element.map(item => item.platform).filter((platform, index, arr) => arr.indexOf(platform) === index)
      }
    }
  }
</script>
<style scoped>
  .column {
    background-color: #bee5eb;
    font-weight: bold
  }
</style>
