import {I18n} from "../util/i18n"
import {RestApi} from "../util/rest-api"
import {VIEW_URL} from '../resource/urls'
import axios from "axios"
import {IntentCheckHistory, Dataset, IntentCheckHistoryAdditional, IntentCheckQuestion} from "../model/intent-check"
import {TestChatController} from "./test-chat-controller"

class IntentCheckController {
  constructor(apiUrl, userType, i18nContext, available_platform, test_chat_platform, intent_check_history_status) {
    this.i18n = new I18n(i18nContext)
    this.apiUrl = apiUrl
    this.userType = userType
    this.intentCheckHistoryData = []
    this.IntentCheckHistoryFilterData = []
    this.intentCheckHistoryApi = new RestApi(apiUrl.intentCheckHistory, IntentCheckHistory)
    this.intentCheckHistoryAdditionalApi = new RestApi(apiUrl.intentCheckHistory, IntentCheckHistoryAdditional)
    this.intentCheckQuestionApi = new RestApi(apiUrl.intentCheckQuestion, IntentCheckQuestion)
    this.datasetApi = new RestApi(apiUrl.dataset, Dataset)
    this.selectedLanguage = null
    this.selectedLanguageFilter = null
    this.selectedLanguageFilterStatus = false
    this.progress = true
    this.supportedLanguages=null
    this.languages = [{
      value: null,
      text: "---"
    }]
    this.histories = {
      default: [{
        value: null,
        text: "---"
      }]
    }
    // 検証結果比較のために検証履歴を選ぶモーダルに使用。1つめに選択した履歴に応じて生成される2つ目の履歴候補を保持する。
    this.preHistories = {
      default: [{
        value: null,
        text: "---"
      }]
    }
    this.testChatController = null
    this.availablePlatform = available_platform
    this.testChatPlatform = test_chat_platform
    this.intentCheckHistoryLoader = false
    this.params = {page: 1}
    this.num_page = 0
    this.indexCounter = 0
    this.selectedHistoryStatusFilter = null
    this.historyStatusOptions = this.loadHistoryStatusOptions(intent_check_history_status)
  }

  ready() {
    this.loadDataset()
    setInterval(() => {
      if(this.progress && !this.intentCheckHistoryLoader){
        this.loadIntentCheckHistory()
      }
    },5000)
  }

  loadIntentCheckHistory(){
    const params = {
      'page': this.params.page
    }
    if(this.selectedLanguageFilter) {
      params["language_code"] = this.selectedLanguageFilter.language_code
    }
    if(this.selectedHistoryStatusFilter) {
      params["status"] = this.selectedHistoryStatusFilter
    }
    const request = this.intentCheckHistoryAdditionalApi.endpoint
    axios.get(request, { params })
      .then(response => {
        const data = response.data
        this.intentCheckHistoryLoader = false
        this.intentCheckHistoryData = data.results
        this.params.page = data.page
        this.num_page = data.num_page
        this.indexCounter = ((data.page - 1) * data.page_size)
        this.histories = this.bindValidationDataForComparing(data.results)
        if(data.results.filter(history => history.status === 'In progress...').length === 0){
          this.progress = false
        }
      })
      .catch(error => {
        this.intentCheckHistoryLoader = false
        console.log(error)
      })
  }

  loadDataset(){
    this.datasetApi.list()
      .then((instance) => {
        this.languages = this.createData(instance)
        this.supportedLanguages = this.supportedLanguagesArray(instance)
        this.chatLanguage = instance[0].language_code
        this.testChatController = new TestChatController(this.apiUrl, this.supportedLanguages, this.i18n, this.availablePlatform, this.testChatPlatform)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  supportedLanguagesArray(instances){
    let result = {}
    for(const instance of instances){
      result[instance.language_code] = {
        code: instance.language_code
      }
    }
    return result
  }

  createData(instances) {
    let result = [{
      value: null,
      text: "---"
    }]
    for (const instance of instances) {
      result.push({
        value: instance,
        text: this.getLanguageNameFromCode(instance.language_code)
      })
    }
    return result
  }

  /**
   * language codeから言語名を返す
   */
  getLanguageNameFromCode(languageCode) {
    var languageName = languageCode
    switch (languageCode) {
      case 'ja':
        languageName = this.i18n.t('languageSelector.japanese')
        break
      case 'en':
        languageName = this.i18n.t('languageSelector.english')
        break
      case 'ko':
        languageName = this.i18n.t('languageSelector.korean')
        break
      case 'zh-cn':
        languageName = this.i18n.t('languageSelector.chineseSimplified')
        break
      case 'zh-tw':
        languageName = this.i18n.t('languageSelector.chineseTraditional')
        break
      case 'th':
        languageName = this.i18n.t('languageSelector.thai')
        break
      case 'ru':
        languageName = this.i18n.t('languageSelector.russian')
        break
      case 'vi':
        languageName = this.i18n.t('languageSelector.vietnamese')
        break
      case 'id':
        languageName = this.i18n.t('languageSelector.indonesian')
        break
      case 'uk':
        languageName = this.i18n.t('languageSelector.ukrainian')
        break
      case 'ne':
        languageName = this.i18n.t('languageSelector.nepali')
        break
      case 'bn':
        languageName = this.i18n.t('languageSelector.bengali')
        break
      case 'fr':
        languageName = this.i18n.t('languageSelector.french')
        break
      case 'es':
        languageName = this.i18n.t('languageSelector.spanish')
        break
      case 'mn':
        languageName = this.i18n.t('languageSelector.mongolian')
        break
      case 'my':
        languageName = this.i18n.t('languageSelector.burmese')
        break
      case 'pt':
        languageName = this.i18n.t('languageSelector.portuguese')
        break
      case 'pt-br':
        languageName = this.i18n.t('languageSelector.portugueseBrazil')
        break
      case 'si':
        languageName = this.i18n.t('languageSelector.sinhala')
        break
    }
    return languageName
  }

  /**
  * Make details url
  */
  makeDetailUrl(id){
    return VIEW_URL.ValidationDetail.replace('<pk>', id)
  }
  /**
  * Make download url
  */
  makeDownloadUrl(id, format){
    return VIEW_URL.ValidationDownload.replace('<id>', id).replace('<format>', format)
  }

  saveIntentCheckHistory(data, csrfToken=null){
    let promise = this.intentCheckHistoryApi.save(
      data,
      csrfToken
    )
      .then(()=>{
        this.progress = true
        this.loadIntentCheckHistory()
      })
    return [true, this.i18n.t("general.saving"), promise]
  }

  deleteIntentCheckHistory(data, csrfToken=null){
    let promise = this.intentCheckHistoryApi.destroy(
        data,
        csrfToken
      ).then(() => {
        this.loadIntentCheckHistory()
      })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   *
   */
  omitSelectedHistory(data){
    // 1つ目に選択した検証履歴以外の同じ言語の履歴をフィルター
    const checkHistories = this.intentCheckHistoryData.filter(history => history.id != data.id && history.language == data.language)
    this.preHistories = this.bindValidationDataForComparing(checkHistories)
  }

  /**
   * 検証履歴比較モーダルでOKを押したら検証結果比較用のページを開く
   */
  compareOk(currentHistory, prevHistory){
    let url = VIEW_URL.ValidationCompare.replace('<pk1>', currentHistory.id)
    url = url.replace('<pk2>', prevHistory.id)
    window.location.href = url
  }

  /**
   * ユーザータイプのチェック
   */
  isViewer() {
    return this.userType == 'viewer'
  }

  /**
   * 検証履歴を言語ごとにまとめる
   */
  bindValidationDataForComparing(checkHistories) {
    let result = {}
    // 無選択状態用の選択肢を生成
    result["default"] = [{
      value: null,
      text: "---"
    }]
    for (const checkHistory of checkHistories) {
      if(!result[checkHistory.language]) {
        // resultに対象の言語がなければ配列をセットする
        result[checkHistory.language] = []
      }

      // 該当の言語の配列に履歴データを追加
      result[checkHistory.language].push({
        value: checkHistory,
        text: checkHistory.name
      })
    }
    return result
  }

  /**
   * Save list of custom questions
   *
   * @param form
   * @param csrfToken
   * @returns {boolean|*[]}
   */
  onSubmitTestForm(form, csrfToken=null){
    if(form){
      let promise = this.intentCheckQuestionApi.save(form, csrfToken)
          .then(() => {
            this.progress = true
            this.loadIntentCheckHistory()
          })
      return [true, this.i18n.t("general.saving"), promise]
    } else {
      return false
    }
  }

  /**
   * Get list of custom questions
   *
   * @param query
   * @returns {any}
   */
  onShowTestForm(query){
    const request = this.intentCheckQuestionApi.endpoint
    return query ? new Promise((resolve, reject) => {
      axios.get(request, {
        params: {
          language: query.language_code
        }
      }).then(response => {
        resolve(response.data)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
    }) : []
  }

  /**
   * Bind the list of Status
   *
   * @param status
   * @returns {[{text: string, value: null}]}
   */
  loadHistoryStatusOptions(status) {
    const options = [{
      value: null,
      text: "---"
    }]
    if(status) {
      status.forEach(s => {
        options.push({
          value: s,
          text: s
        })
      })
    }
    return options
  }
}

export {
  IntentCheckController
}
