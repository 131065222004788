
import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import { FeedbackHistoryController } from './controller/feedback-history-controller'

import FeedbackHistory from './components/FeekbackHistory.vue'
import Pagination from './components/Pagination.vue'


export const appFeedbackHistory = (i18n) => {
  /* コントローラ */
  const controller = new FeedbackHistoryController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext
  )

  /* コンポーネント登録 */
  Vue.component('feedback-history', FeedbackHistory)
  Vue.component('pagination', Pagination)

  new Vue({
    el: '#app-feedback-history',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}

