<template>
  <b-modal
    :title="title"
    ref="modal"
    header-class="bg-success text-light"
    body-class="text-success"
    no-close-on-esc
    no-close-on-backdrop
    ok-only
    @ok="$emit('ok')"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
    @keypress.native.enter="$emit('key-press-enter')">
    <div class="text-center">
      <p>{{ message }}</p>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: [
    'title',
    'message',
  ],
  methods: {
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
  }
}


</script>

