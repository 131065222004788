<template>
<div>
<div class="row mt-1">
  <synchronize
    class="col-sm-3 mb-1"
    @start-synchronize="doSynchronization"
    :userType="controller.userType">
  </synchronize>
  <language-change
    class="col-sm-4 mb-1"
    :supported-languages="controller.supportedLanguages"
    :language="controller.language">
    <p class="text-right"></p>
  </language-change>
  <mode-change-keyword
    class="col-sm-3 mb-1"
    :supported-languages="controller.supportedLanguages"
    :language="controller.language">
  </mode-change-keyword>
</div>

<div
  class="row"
  @keyup.up="onArrowKeyUp($event)"
  @keyup.down="onArrowKeyDown($event)"
  @keyup.right="onArrowKeyRight($event)"
  @keyup.left="onArrowKeyLeft($event)">
  <intent-category
    class="page-category col-md-3 mb-0"
    ref="intentCategory"
    :categories="controller.categories.filter(ct=>!ct.scenarioId)"
    :selected-category="controller.selectedCategory"
    :category-model="controller.categoryModel"
    :onlyAlphanumeric="false"
    :isAllSelected="controller.isAllSelected"
    :currentDisplayedCategory="controller.currentDisplayedCategory"
    :categorydesc="controller.categorydesc"
    :userType="controller.userType"
    :excelExportUrl="controller.excelExportUrl"
    @intent-category-order="intentCategoryOrder"
    @select-category="selectCategory"
    @save-category="saveCategory"
    @delete-category="deleteCategory"
    @prebuilt-preview="prebuiltPreview"
    @arrowKey-flag="setArrowKeyFlag"
    @modalPopupCheck-flag="setModalPopupCheckFlag"
    @import-intents-from-excel="importIntentsFromExcel">
  </intent-category>

  <intent
    class="col-md-4 mb-0"
    ref="intent"
    :categoryJoin="categoryJoinData"
    :intents="controller.intents.filter(it=>!it.scenarioId)"
    :allIntents="controller.allIntents"
    :selected-category="controller.selectedCategory"
    :selected-intent="controller.selectedIntent"
    :category-model="controller.categoryModel"
    :categories="controller.categories.filter(ct=>!ct.scenarioId)"
    :desc="controller.desc"
    :isStaff="controller.isStaff"
    :userType="controller.userType"
    :allowWebhook="controller.allowWebhook"
    :intentCategoryPath="controller.intentCategoryPath"
    :feedbackIntentDict="controller.feedbackIntentDict"
    :agentOptions="controller.agentOptions"
    @intent-order="intentOrder"
    @copy-to-agent="copyToAgent"
    @delete-multiple-intent="deleteMultiIntent"
    @download-json="downloadJson"
    @import-json="importJson"
    @select-intent="selectIntent"
    @save-intent="saveIntent"
    @delete-intent="deleteIntent"
    @arrowKey-flag="setArrowKeyFlag"
    @modalPopupCheck-flag="setModalPopupCheckFlag"
    @show-feedback-modal="showFeedbackModal"
    @update-label-multiple-intent="updateLabelMultiIntent">
  </intent>

  <intent-detail
    class="col-md-5 mb-0"
    :categories="controller.categories.filter(ct=>!ct.scenarioId)"
    :intent="controller.selectedIntent"
    :intentDetail="controller.selectedIntentDetail"
    :language="controller.language"
    :apiUrl="controller.apiUrl"
    :selectedUserSay="controller.selectedUserSay"
    :selectedResponseMessage="controller.selectedResponseMessage"
    :questionSearchResult="controller.questionSearchResult"
    :userType="controller.userType"
    :supportedLanguages="controller.supportedLanguages"
    :selectedLanguageIntentDetail="controller.selectedLanguageIntentDetail"
    :intentDetailKeywordList="controller.intentDetailKeywordList"
    :intentDetailKeywordListBySearch="controller.intentDetailKeywordListBySearch"
    :keywordFullNameById="controller.keywordFullNameById"
    :availablePlatform="controller.available_platform"
    :defaultPlatform="controller.default_platform"
    :keywordBoundCount="controller.keywordBoundCount"
    ref="questionModal"
    @load-intent-keyword-dep-list="loadIntentDetailKeyword"
    @load-intent-detail-keyword="loadIntentDetailKeywordById"
    @create-keyword-name="createKeywordName"
    @save-user-say="saveUserSay"
    @cancel-question-modal=""
    @select-user-say="selectUserSay"
    @delete-user-say="deleteUserSay"
    @delete-multiple-user-say="deleteMultipleUserSay"
    @save-all-user-says="saveAllUserSays"
    @save-response-message="saveResponseMessage"
    @save-all-response-message="saveAllResponseMessage"
    @select-response-message="selectResponseMessage"
    @delete-response-message="deleteResponseMessage"
    @save-keyword="saveKeyword"
    @save-keyword-category="saveKeywordCategory"
    @load-keyword-categories="loadKeywordCategories"
    @save-keyword-value="saveKeywordValue"
    @search-question="searchQuestion"
    @update-searched-question="updateSearchedQuestion"
    @reset-search-result="resetSearchResult"
    @arrowKey-flag="setArrowKeyFlag"
    @save-multiple-user-says="saveMultipleUserSays"
    @started-linking="OnStartLinking"
    @modalPopupCheck-flag="setModalPopupCheckFlag"
    @copy-user-says="copyUserSays"
    @changed-compared-language="onChangedComparedLanguage"
    @select-platform-service="selectPlatformService">
  </intent-detail>
  <test-chat
    :controller="controller.testChatController"
    @chat-box-state="checkChatboxState"
  ></test-chat>

  <b-modal
    :title="$t('intent.importIntent')"
    ref="importModal"
    header-class="bg-info text-light"
    @hidden="resetPreview"
    no-close-on-esc
    no-close-on-backdrop>
    <div>
      <b-form-file
        v-model="importFile"
        :state="Boolean(importFile)"
        accept=".json"
        :placeholder="$t('fileManagement.chooseFile')">
      </b-form-file>
      <div class="mt-3">
        <span>{{ $t('fileManagement.selectedFile') }}: {{ importFile && importFile.name }}</span>
        <b-btn v-b-modal.preview-modal class="show-compare mb-1" style="float:right" @click="importFilePreview" :disabled="!importFile">
          <span>{{ $t('general.preview') }}</span>
        </b-btn>
      </div>
    </div>
    <modal-footer
      slot="modal-footer"
      @ok="importJsonOk"
      :okDisabled="!importFile"
      @cancel="$refs.importModal.hide()">
    </modal-footer>
  </b-modal>

  <b-modal
    :title="$t('errors.errorTitle')"
    ref="forcingImportConfirm"
    header-class="bg-danger text-light"
    body-class="text-danger"
    no-close-on-esc
    no-close-on-backdrop>
    <div>
      <div v-for="(value, key) in duplicateIntents">
        <span>{{ key }}:</span><br>
        <span>{{ value.languages }}</span>
      </div>
      <p style="overflow-wrap: break-word">has been registered</p>
    </div>
    <div slot="modal-footer">
      <b-btn
        variant="primary"
        @click="reImport(2)">
        overwrite
      </b-btn>
      <b-btn
        variant="primary"
        @click="reImport(1)">
        skip
      </b-btn>
      <b-btn @click="$refs.forcingImportConfirm.hide()">
        cancel
      </b-btn>
    </div>
  </b-modal>

  <success-modal
    ref="syncCompletedModal"
    :title="$t('synchronize.result')"
    :message="$t('synchronize.completed')"
    @key-press-enter="closeSyncCompletedModal">
  </success-modal>
  <success-modal
    ref="copyToAgentSuccessModal"
    :title="$t('synchronize.result')"
    :message="$t('intent.copyToAgentSuccess')"
    @key-press-enter="$refs.copyToAgentSuccessModal.hide()">
  </success-modal>

  <b-modal
    :title="$t('synchronize.result')"
    ref="importConfirmModal"
    header-class="bg-success text-light"
    body-class="text-success"
    @keydown.native.enter="importComplete"
    no-close-on-esc
    no-close-on-backdrop
    ok-only>
    <div class="text-center">
      <!--TODO translation -->
      <p>Completed</p>
    </div>
  </b-modal>

  <b-modal
    id="preview-modal"
    :title="$t('general.preview')"
    class="preview-modal"
    size="lg"
    @shown="$refs.prebuiltPreviewIntent.shown()"
    @hidden="$refs.prebuiltPreviewIntent.hidden()"
    ok-only
  >
    <previewer
      ref="prebuiltPreviewIntent"
      :intentData="intentData"
      :keywordData="keywordData"
      :preIntent="preIntent"
      :preKeyword="preKeyword"
    >
    </previewer>
  </b-modal>

  <error-modal
    ref="errorModal"
    :message="message"
    :isCard="isCard"
    :cardErrorMsg="cardErrorMsg"
    :isBotBroken="isBotBroken"
    :botBrokenMessage="botBrokenMessage"
    @train="doSynchronization">
  </error-modal>
  <progress-modal
    ref="progressModal"
    :message="message">
  </progress-modal>
</div>
  <!-- フィードバックを全てのインテントにおいて有効にするかを選択するモーダル-->
  <feedback-configuration-modal
    ref="feedbackModal"
    @ok="toggleAllFeedback"
    @cancel-feedback-modal="cancelFeedbackModal"
    @toggle-is-modal-shown="setModalPopupCheckFlag">
  </feedback-configuration-modal>

  <b-modal
    :title="$t('intent.agentWarning')"
    ref="hasChangedAgentModal"
    header-class="bg-danger text-light"
    body-class="text-danger"
    @keydown.native.enter="hasChangedAgentReloadWindow"
    @ok="hasChangedAgentReloadWindow"
    no-close-on-esc
    no-close-on-backdrop
    ok-only>
    <div class="text-center">
      <p>
        <i class="fas fa-exclamation-triangle"></i>
        {{$t('intent.agentWarningMessage')}}
      </p>
    </div>
  </b-modal>

  <!--Import intents from excel modal start-->
  <b-modal
    :title="$t('category.importIntentsFromExcel')"
    ref="ImportIntentsFromExcelModal"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-esc
    no-close-on-backdrop>
    <b-form-file
      v-model="intentExcelFile"
      :state="Boolean(intentExcelFile)"
      :placeholder="$t('category.chooseOrDropFileHere')"
      :drop-placeholder="$t('category.dropFileHere')"
    ></b-form-file>
    <div slot="modal-footer">
      <b-btn
        variant="primary"
        :disabled="!intentExcelFile"
        @click="importIntentsFromExcelPreview">
        {{ $t('fileManagement.upload') }}
      </b-btn>
      <b-btn
        variant="secondary"
        @click="importIntentsFromExcelCancel">
        {{ $t('buttons.cancel') }}
      </b-btn>
    </div>
  </b-modal>
  <!--Import intents from excel modal end-->

  <!--excel import preview modal start-->
  <b-modal
    ref="importexcelpreviewmodal"
    :title="$t('general.preview')"
    class="preview-modal"
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div>
      <excel-preview :excelData="excelPreview"/>
    </div>
    <modal-footer
      slot="modal-footer"
      @ok="importIntentsFromExcelOk"
      :okDisabled="!intentExcelFile"
      @cancel="importIntentsFromExcelPreviewCancel">
    </modal-footer>
  </b-modal>
  <!--excel import preview modal end-->

  <!--excel import error modal start-->
  <b-modal
    ref="importExcelErrorModal"
    :title="$t('errors.errorTitle')"
    header-class="bg-danger text-light"
    body-class="text-danger"
    no-close-on-backdrop
    ok-only
    scrollable
    centered>
    <div v-html="importExcelErrorMessage"></div>
  </b-modal>
  <!--excel import error modal end-->
  <b-alert
    v-if="inputTransportResult.message"
    fade
    :show="3"
    dismissible
    :variant="inputTransportResult.type"
    @dismissed="inputResetMessage"
    class="stick-to-bottom"
  >
    {{ inputTransportResult.message }}
  </b-alert>

</div>
</template>

<script>
import csrfToken from '../util/csrf-token'
import { I18n } from "../util/i18n"
import { IntentCategory } from '../model/intent'
import { SynchronizeStatus } from '../model/synchronize'
import {DragMe} from '../util/drag-me'

export default {
  props: ['controller'],
  data() {
    return {
      message: '',
      errorMsg:'',
      arrowKeyFlag:0,
      importFile: null,
      modalPopupCheckFlag:false,
      compareIntentModalButtonId: "compare-intent-modal-button",
      importData:null,
      intentsWithSameName:null,
      intentData: null,
      preIntent: null,
      keywordData: null,
      preKeyword: null,
      duplicateIntents:null,
      reImportType: 0,
      selectedPreCategory: null,
      agentId: '',
      isCard:false,
      cardErrorMsg: [],
      intentExcelFile: null,
      excelPreview: null,
      i18n: new I18n(window.i18nContext),
      isChatboxOpen: false,
      isBotBroken:false,
      botBrokenMessage:'',
      importExcelErrorMessage: '',
      inputTransportResult: {
        message: null,
        type: null
      }
    }
  },
  computed: {
    isSynchronizing: function () {
      return this.controller.synchronizeController.isSynchronizing
    },
    synchronizeStatusMessage: function () {
      return this.controller.synchronizeController.synchronizeStatusMessage
    },
    categoryJoinData: function(){
      return this.createCategoryPathIndex(this.controller.categories)
    },
    inputSuccessMessage() {
      return (count) => this.$t('inputCompletion.transferSucceeded') + count
    }
  },
  updated(){
    if(this.modalPopupCheckFlag !== true){
      this.$nextTick(()=>{
        // DOMの変化を監視する
        if(this.arrowKeyFlag === 1)
          this.$refs.intentCategory.$refs.focusOn.focus()
        else if(this.arrowKeyFlag === 2)
          this.$refs.intent.$refs.focusOn.focus()
        // 詳細での矢印の実装はしばらく止めたので、予想外のバグが出ないようにコメントアウト
        // else if(this.arrowKeyFlag === 3)
        //   this.$refs.questionModal.$refs.userSayFocusOn.focus()
        // else if(this.arrowKeyFlag === 4)
        //   this.$refs.questionModal.$refs.responseMessageFocusOn.focus()
      })
    }
  },
  methods: {
    async inputPredictionTransfer () {
      try {
        const result = await this.controller.inputPredictionTransfer(csrfToken.getCsrfTokenFromCookie(document.cookie))
        this.inputTransportResult.message = this.inputSuccessMessage(result.data)
        this.inputTransportResult.type = 'success'
      } catch (e) {
        this.inputTransportResult.message =  e.response.data instanceof Array ? e.response.data[0] : e.response.data.detail
        this.inputTransportResult.type = 'danger'
      }
    },
    inputResetMessage () {
      this.inputTransportResult = {
        message: null,
        type: null
      }
    },
    createKeywordName(){
      this.controller.keywordPathById()
    },
    // Export json file
    async downloadJson(id){
      window.location.href= this.controller.makeDownloadUrl(id)
    },
    //Import Intents
    importJson(){
      this.$refs.importModal.show()
    },
    importFilePreview() {
      let reader = new FileReader()
      let self = this
      this.preIntent = this.importFile
      reader.onload = (evt) => {
        self.intentData = evt.target.result
      }
      reader.readAsBinaryString(this.importFile)
    },
    prebuiltPreview(category) {
      try {
        if (category.value.data instanceof Array && category.value.data.length > 0) {
          this.intentData = JSON.stringify(category.value.data)
          this.preIntent = category.text
        }
        if (this.controller.prebuiltKeywords instanceof Array && this.controller.prebuiltKeywords.length > 0) {
          this.keywordData = JSON.stringify(this.controller.prebuiltKeywords)
          this.preKeyword = !!this.keywordData
        }
      } catch (e) {
        this.intentData = null
        this.preIntent = null
        this.keywordData = null
        this.preKeyword = null
      }
    },
    resetPreview() {
      this.intentData = null
      this.preIntent = null
      this.keywordData = null
      this.preKeyword = null
      this.controller.resetPreview()
    },
    importJsonOk(){
      let reader = new FileReader()
      let this2 = this
      reader.onload = function(evt){
        this2.importData = evt.target.result
        let result, message, promise
        [result, message, promise, this2.reImportType] = this2.controller.importJson(evt.target.result, 0, this2.duplicateIntents, csrfToken.getCsrfTokenFromCookie(document.cookie))
        if (!result) {
          this2.showError(message)
        } else {
          this2.showProgress(message)
          let this3 = this2
          promise
            .then(() => {
              this2.hideProgress()
              this2.$refs.importConfirmModal.show()
            })
            .catch(function (error) {
              this3.duplicateIntents = error.response.data
              this3.$refs.forcingImportConfirm.show()
              this3.hideProgress()
            })
            .catch(function (error) {
              this2.showError("illegal json file")
              this3.hideProgress()
            })
        }
      }
      reader.readAsBinaryString(this.importFile)
      this.$refs.importModal.hide()
    },
    reImport(importMode){
      let result, message, promise
      if (this.reImportType === 1) {
        [result, message, promise, this.reImportType] = this.controller.importJson(this.importData, importMode, this.duplicateIntents, csrfToken.getCsrfTokenFromCookie(document.cookie))
      }
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let this2 = this
        promise.then(()=>{
          this2.hideProgress()
          this2.$refs.importConfirmModal.show()
          this.duplicateIntents = null
          this.selectedPreCategory = null
          this.importData = null
        }).catch(function (error) {
          this2.showError(error.response.data[0])
          this2.hideProgress()
        })
      }
      this.$refs.forcingImportConfirm.hide()
    },
    importComplete(){
      this.$refs.importConfirmModal.hide()
    },
    intentCategoryOrder(name){
      if (name == "-name") {
        this.controller.categorydesc=false
      }
      else {
        this.controller.categorydesc=true
      }
      this.controller.categories.sort(this.dynamicSort(name))
    },
    intentOrder(name){
      if (name == "-name") {
        this.controller.desc=false
      }
      else {
        this.controller.desc=true
      }
      this.controller.intents.sort(this.dynamicSort(name))
    },
    dynamicSort(property) {
      let sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          if(sortOrder == -1){
              return b[property].localeCompare(a[property]);
          }else{
              return a[property].localeCompare(b[property]);
          }
      }
    },
    // メソッド側でthisをcontrollerにするため、methods経由で呼ぶ
    selectCategory(category) {
      this.controller.selectCategory(category)
    },
    saveCategory(category) {
      let result, message, promise
      [result, message, promise] = this.controller.saveCategory(
        category,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          this.$refs.intentCategory.selectChild(this.controller.selectedCategory)
        })
          .catch(function(error){
            self.showError(error.response.data[0])
            self.hideProgress()
          })
      }
    },
    deleteCategory(category) {
      let result, message, promise
      [result, message, promise] = this.controller.deleteCategory(
        category,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          this.$refs.intentCategory.selectChild(this.controller.selectedCategory)
        })
        .catch(function(error){
            self.hideProgress()
            self.showError(error.response.data[0])
          })
      }
    },
    setArrowKeyFlag(arrowKeyFlag){
      this.arrowKeyFlag = arrowKeyFlag
    },
    setModalPopupCheckFlag(modalPopupCheckFlag){
      this.modalPopupCheckFlag = modalPopupCheckFlag
    },
    onArrowKeyUp(event){
      if(this.isChatboxOpen || this.modalPopupCheckFlag) {
        event.preventDefault()
        return false
      } else {
        if(this.arrowKeyFlag === 1){
          if(this.controller.isAllSelected)
            return null
          this.$refs.intentCategory.onArrowUp(this.controller.selectedCategory)
        }
        else if(this.arrowKeyFlag === 2){
          this.$refs.intent.onArrowUp(this.controller.selectedIntent)
        }
        else if(this.arrowKeyFlag === 3){
          this.$refs.questionModal.onArrowUpUserSay(this.controller.selectedUserSay)
        }
        else if(this.arrowKeyFlag === 4){
          this.$refs.questionModal.onArrowUpResponseMessage(this.controller.selectedResponseMessage)
        }
      }
    },
    onArrowKeyDown(event){
      if(this.isChatboxOpen || this.modalPopupCheckFlag) {
        event.preventDefault()
        return false
      } else {
        if(this.arrowKeyFlag === 1){
          if(this.controller.isAllSelected){
            if(this.controller.categories.filter(ct=>!ct.scenarioId).length > 0){
              this.$refs.intentCategory.selectChild(this.controller.categories.filter(ct=>!ct.scenarioId)[0])
              return null
            }
            else
              return null
          }
          else
            this.$refs.intentCategory.onArrowDown(this.controller.selectedCategory)
        }
        else if(this.arrowKeyFlag === 2){
          this.$refs.intent.onArrowDown(this.controller.selectedIntent)
        }
        else if(this.arrowKeyFlag === 3){
          this.$refs.questionModal.onArrowDownUserSay(this.controller.selectedUserSay)
        }
        else if(this.arrowKeyFlag === 4){
          this.$refs.questionModal.onArrowDownResponseMessage(this.controller.selectedResponseMessage)
        }
      }
    },
    onArrowKeyRight(event){
      if(this.isChatboxOpen || this.modalPopupCheckFlag) {
        event.preventDefault()
        return false
      } else {
        if(this.arrowKeyFlag === 1){
          if(typeof  this.controller.intents.filter(it=>!it.scenarioId)[0] !== 'object'){return}
          this.$refs.intent.onArrowRight(this.controller.intents.filter(it=>!it.scenarioId)[0])
          this.arrowKeyFlag = 2
        }
        else if(this.arrowKeyFlag === 2){
          if(typeof this.controller.selectedIntentDetail.data.userSays[0] !== 'object'){return}
          this.$refs.questionModal.onArrowRightUserSay(this.controller.selectedIntentDetail.data.userSays[0])
          this.arrowKeyFlag = 3
        }
      }
    },
    onArrowKeyLeft(event){
      if(this.isChatboxOpen || this.modalPopupCheckFlag) {
        event.preventDefault()
        return false
      } else {
        if(this.arrowKeyFlag === 3){
          this.$refs.intent.onArrowLeft(this.controller.selectedIntent)
          this.arrowKeyFlag = 2
        }
        else if(this.arrowKeyFlag === 2){
          this.$refs.intentCategory.onArrowLeft(this.controller.selectedCategory)
          this.arrowKeyFlag = 1
        }
      }
    },
    resolveCategoryPath(category, categoryIdToCategory) {
      if (category.parentId == null) {
        return [category]
      } else {
        const parentCategory = categoryIdToCategory[category.parentId]
        return this.resolveCategoryPath(parentCategory, categoryIdToCategory).concat([category])
      }
    },
    createCategoryPathIndex(categories) {
      let flat = IntentCategory.categoriesAsFlat(categories)
      // categoryId -> categoryを作成
      let categoryIdToCategory = {}
      for (const [nestedLevel,category] of flat) {
        categoryIdToCategory[category.id] = category
      }
      // category -> カテゴリツリーのパス生成
      let categoryIdToPath = {}
      for (const [nestedLevel,category] of flat) {
        const categoryPath = this.resolveCategoryPath(category, categoryIdToCategory)
        categoryIdToPath[category.id] = categoryPath.map((c) => {return c.name}).join("_")
      }
      return categoryIdToPath
    },
    selectIntent(intent) {
      this.controller.selectIntent(intent)
    },
    showProgress(message) {
      this.isCard = false
      this.isBotBroken = false
      this.botBrokenMessage = ''
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    showError(message, isCard=false) {
      if(!isCard){this.message = message}
      this.isCard = isCard
      this.$refs.errorModal.show()
    },
    saveIntent(intent) {
      let result, message, promise
      [result, message, promise] = this.controller.saveIntent(
        intent,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise
          .then(()=>{
            this.hideProgress()
            if(this.controller.selectedIntent)
              this.$refs.intent.selected(this.controller.selectedIntent)
            else
              this.$refs.intentCategory.selectChild(this.controller.selectedCategory)
          })
          .catch(function(error){
            self.showError(error.response.data[0])
            self.hideProgress()
          })
      }
    },
    deleteIntent(intent) {
      let result, message, promise
      [result, message, promise] = this.controller.deleteIntent(
        intent,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(()=>{
          this.hideProgress()
          if(this.controller.selectedIntent)
            this.$refs.intent.selected(this.controller.selectedIntent)
          else
            this.$refs.intentCategory.selectChild(this.controller.selectedCategory)
        })
          .catch(function(error){
            self.hideProgress()
            self.showError(error.response.data[0])
          })
      }
    },
    loadKeywordCategories() {
      this.controller.loadKeywordCategories()
    },
    saveKeywordCategory(category) {
      let result, message, promise
      [result, message, promise] = this.controller.saveKeywordCategory(
        category,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let this2 = this
        promise.then(
          function(instance){
            // instanceのidを用いて、新規作成のkeywordCategoryを特定する
            category.id = instance.id
            // モーダルを開く前に、ロードを完成させて、作成したcategoryを選択状態にする
            if(this2.$refs.questionModal.wordEditor){
              this2.$refs.questionModal.wordEditor.loadKeywordCategories().then(()=>{
                this2.$refs.questionModal.wordEditor.selectKeywordCategory(category)
                this2.hideProgress()
                this2.$refs.questionModal.show()
              })
            }
          })
          .catch(function (error) {
            this2.showError(error.response.data[0])
            this2.hideProgress()
          })
      }
    },
    saveUserSay(userSay) {
      let result, message, promise
      [result, message, promise] = this.controller.saveUserSay(
        userSay,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(this.hideProgress)
      }
    },
    updateSearchedQuestion(intentDetails) {
      let result, message, promises
      [result, message, promises] = this.controller.updateSearchedUserSay(
        intentDetails,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let outsideThis = this
        Promise.all(promises)
          .then(function() {
            if (outsideThis.controller.selectedIntent != null) {
              outsideThis.controller.loadIntentDetail()
            }
            outsideThis.hideProgress()
          })
      }
    },
    saveResponseMessage(responseMessage) {
      let result, message, promise
      [result, message, promise] = this.controller.saveResponseMessage(
        responseMessage,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(this.hideProgress)
      }
    },
    saveAllUserSays(userSays) {
        this.controller.saveAllUserSays(
        userSays,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    saveAllResponseMessage(responseMessage){
      this.controller.saveAllResponseMessage(
        responseMessage,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    selectUserSay(userSay) {
      this.controller.selectUserSay(userSay)
    },
    deleteUserSay() {
      let result, message, promise
      [result, message, promise] = this.controller.deleteUserSay(
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(this.hideProgress)
      }
    },
    deleteMultipleUserSay(userSays) {
      let result, message, promise
      [result, message, promise] = this.controller.deleteMultipleUserSay(
        userSays,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(this.hideProgress)
          .catch((error) => {
            this.controller.loadIntentDetail()
            this.showError(error.response.data[0])
            this.hideProgress()
          })
      }
    },
    selectResponseMessage(responseMessage) {
      this.controller.selectResponseMessage(responseMessage)
    },
    deleteResponseMessage() {
      let result, message, promise
      [result, message, promise] = this.controller.deleteResponseMessage(
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(this.hideProgress)
      }
    },
    saveKeyword(keyword) {
      let result, message, promise
      [result, message, promise] = this.controller.saveKeyword(
        keyword,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let this2=this
        promise.then(
          function(instance){
            // 新規作成のkeywordは常に最後に追加するようになっているので、idを用いて特定する必要がありません。
            // モーダルを開く前に、ロードして、最後の一つのkeywordを選択状態にする
            if(this2.$refs.questionModal.wordEditor){
              this2.$refs.questionModal.wordEditor.loadKeywords().then(()=>{
                this2.$refs.questionModal.wordEditor.selectKeyword(instance)
                this2.hideProgress()
                this2.$refs.questionModal.show()
              })
            }
          })
      }
    },
    saveKeywordValue(keywordValue){
      this.controller.saveKeywordValue(keywordValue,csrfToken.getCsrfTokenFromCookie(document.cookie))
    },
    loadIntentDetailKeyword(){
      this.controller.loadIntentDetailKeyword()
    },
    loadIntentDetailKeywordById(id){
      this.controller.loadIntentDetailKeywordById(id)
    },
    searchQuestion(searchString, intentCategory) {
      this.controller.searchQuestion(searchString, intentCategory)
    },
    resetSearchResult() {
      this.controller.questionSearchResult = null
    },
    OnStartLinking(message) {
      this.showProgress(message)
    },
    closeSyncCompletedModal(){
      this.$refs.syncCompletedModal.hide()
    },
    saveMultipleUserSays(userSayText) {
      let result, message, promise
      [result, message, promise] = this.controller.saveMultipleUserSays(
        userSayText,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(this.hideProgress)
      }
    },
    onChangedComparedLanguage(language_code) {
      this.controller.loadSelectedLanguageUserSays(language_code)
    },
    selectPlatformService(selectedPlatform,platformFlag){
      let result, message, promise
      [result, message, promise] = this.controller.allIntentDetailPlatformUpdate(
        selectedPlatform,
        platformFlag,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },

    // Import intents from excel file start here
    importIntentsFromExcel(){
      this.$refs.ImportIntentsFromExcelModal.show()
    },
    importIntentsFromExcelCancel(){
      this.intentExcelFile = null
      this.$refs.ImportIntentsFromExcelModal.hide()
    },
    importIntentsFromExcelPreview(){
      let regex = /.*\.(xlsx|xls)$/
      if(regex.test(this.intentExcelFile.name.toLowerCase())) {
        this.$refs.ImportIntentsFromExcelModal.hide()
        let formData = new FormData()
        formData.append('file', this.intentExcelFile)
        let result, message, promise
        [result, message, promise] = this.controller.importIntentsFromExcelPreview(
          formData,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result){
          this.showError('Error Occurred!')
        }else{
          this.showProgress(message)
          let self = this
          promise.then(instance => {
            self.excelPreview = JSON.parse(instance.data)
            self.hideProgress()
            this.$refs.importexcelpreviewmodal.show()
          })
            .catch(error => {
              this.intentExcelFile = null
              self.hideProgress()
              self.showError(error.response.data[0])
            })
        }
      }else{
        alert(this.i18n.t('category.selectValidExcelFile'))
      }
    },
    importIntentsFromExcelOk(){
      this.excelPreview = null
      this.$refs.importexcelpreviewmodal.hide()
      this.importExcelErrorMessage = ""
      this.$refs.importExcelErrorModal.hide()
      let formData = new FormData()
      formData.append('file', this.intentExcelFile)
      let result, message, promise
      [result, message, promise] = this.controller.importIntentsFromExcelSave(
        formData,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result){
        this.showError('Error Occurred!')
      }else {
        this.showProgress(message)
        let self = this
        promise.then(instance => {
          self.hideProgress()
          this.intentExcelFile = null
          this.controller.ready()
        })
        .catch(error => {
          this.intentExcelFile = null
          self.hideProgress()
          if(error.response.status === 400) {
            const res_msg_arr = error.response.data[0].split("=")
            if(res_msg_arr.length > 0) {
              const res_msg = res_msg_arr[1].split(",")[0]
              self.importExcelErrorMessage = res_msg.replace(/'/g, "")
              self.$refs.importExcelErrorModal.show()
            }
          } else {
            self.showError(error.response.data[0])
          }
        })
      }
    },
    importIntentsFromExcelPreviewCancel(){
      this.intentExcelFile = null
      this.excelPreview = null
      this.$refs.importexcelpreviewmodal.hide()
    },
    copyUserSays(intentDetail) {
      this.controller.copyUserSays(intentDetail)
    },
    toggleAllFeedback(configureFeedbackForm) {
      this.showProgress('...')
      this.controller.toggleAllFeedback(
        configureFeedbackForm,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
        .then(() => {
          this.hideProgress()
        })
        .catch((error) => {
          this.hideProgress()
          this.showError(error.response.data[0])
        })
      this.$refs.feedbackModal.hide()
    },
    showFeedbackModal() {
      this.$refs.feedbackModal.show()
    },
    cancelFeedbackModal() {
      this.$refs.feedbackModal.hide()
    },
    copyToAgent(intentIds, targetAgent) {
      let result, message, promise
      [result, message, promise] = this.controller.copyToAgent(
        intentIds,
        targetAgent,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      let this2 = this
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(() => {
          this.hideProgress()
          this.$refs.copyToAgentSuccessModal.show()
        })
          .catch((error) => {
            this2.hideProgress()
            this2.showError(error.response.data[0])
          })
      }
    },
    deleteMultiIntent(intentIds){
      let result, message, promise
      [result, message, promise] = this.controller.deleteMultiIntent(
        intentIds,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      let this2 = this
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(() => {
          this.hideProgress()
        })
          .catch((error) => {
            this2.hideProgress()
            this2.showError(error.response.data)
          })
      }
    },
    doSynchronization(isCard=false) {
      this.showProgress("...")
      // 同期を開始したときにpromiseを受け取り、そのpromiseから同期が開始したか、メッセージを受け取る
      let self = this
      this.controller.startSynchronize(
        csrfToken.getCsrfTokenFromCookie(document.cookie),
        isCard
      )
        .then((result) => {
          if (result.wasSuccessful) {
            self.showProgress(result.message)
          } else {
            self.hideProgress()
            self.showError(result.message)
          }
        })
        .catch((error) => {
          self.hideProgress()
          if( 'message' in error.response.data){
            self.showError(error.response.data.message)
          }else{
            this.cardErrorMsg = error.response.data
            self.showError(this.cardErrorMsg, true)
          }
        })
    },
    checkAgentHasChanged() {
      const browserTabState = !window.document.hidden
      if(browserTabState){
        this.controller.hasChangedAgent(this.agentId).then(response => {
          const session_agent_id = response.data.agent_id
          if(Number.isInteger(session_agent_id)){
            this.agentId = session_agent_id
          } else {
            this.$refs.hasChangedAgentModal.show()
          }
        })
      }
    },
    hasChangedAgentReloadWindow(){
      this.$refs.hasChangedAgentModal.hide()
      window.location.reload()
    },
    checkChatboxState(status) {
      this.isChatboxOpen = status
    },
    checkAgentAlive() {
      const query = {
        'text': '@#$%^aduwenaf--1389371',
        'language':this.controller.getLanguageCode()
      }
      const token = csrfToken.getCsrfTokenFromCookie(document.cookie)
      this.controller.checkAgentState(query, token).then(response => {
        this.isBotBroken = false
        if(!response){
          this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
        }
      }).catch(error => {
        console.log('[intentEditor->checkAgentAlive]: ', error)
        this.isBotBroken = false
        this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
      })
    },
    updateLabelMultiIntent(form){
      let result, message, promise
      [result, message, promise] = this.controller.updateLabelMultiIntent(
        form,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      let this2 = this
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        promise.then(() => {
          this.hideProgress()
        })
          .catch((error) => {
            this2.hideProgress()
            this2.showError(error.response.data)
          })
      }
    }
  },
  mounted: function () {
    const self = this
    const delay = 60*1000 // 1 minute
    this.$nextTick(function () {
      self.checkAgentHasChanged()
      setInterval(self.checkAgentHasChanged, delay)
    })
  },
  watch: {
    isSynchronizing: {
      handler: function(isSynchronizing) {
        // 同期終了時にプログレスを閉じる
        if (!isSynchronizing) {
          this.hideProgress()
        }
      }
    },
    synchronizeStatusMessage: {
      handler: function(synchronizeStatusMessage) {
        // ステータスメッセージが変更されたらmessageに同期
        if (synchronizeStatusMessage != null) {
          this.message = synchronizeStatusMessage
          if(SynchronizeStatus.ERROR == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.isBotBroken = true
            this.showError(this.message)
            this.checkAgentAlive()
          }
          else if(SynchronizeStatus.BUSY == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.showError(this.message)
          }
          else if(SynchronizeStatus.FINISH == this.controller.synchronizeController.synchronizeCode){
            this.hideProgress()
            this.$refs.syncCompletedModal.show()
            if(this.controller.auto_input_predict){
              this.inputPredictionTransfer()
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
  .stick-to-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
  }
</style>
