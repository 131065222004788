<template>
  <!-- フィードバックを全てのインテントにおいて有効にするかを選択するモーダル-->
  <b-modal
    ref="modal"
    :title="$t('intent.configureFeedback')"
    no-close-on-backdrop
    header-class="bg-info text-light"
    body-class="text-info"
    @shown="$emit('toggle-is-modal-shown', true)"
    @hidden="$emit('toggle-is-modal-shown', false)">
    <div>
      <b-form-group>
        <b-form-radio-group
          v-model="configureFeedbackForm.activateAllFeedback"
          name='feedback-radio'>
          <b-form-radio :value="true">
            {{ $t('intent.enableAllFeedback') }}
          </b-form-radio>
          <div v-if="configureFeedbackForm.activateAllFeedback" class="scenario-intent-switch">
            <b-form-checkbox v-model="configureFeedbackForm.excludeScenarioIntent" name="exclude-scenario-intent">
              {{ $t('intent.excludeScenarioIntent') }}
            </b-form-checkbox>
          </div>
          <b-form-radio :value="false">
            {{ $t('intent.disableAllFeedback') }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <modal-footer
      slot="modal-footer"
      @ok="$emit('ok', configureFeedbackForm)"
      @cancel="$emit('cancel-feedback-modal')">
    </modal-footer>
  </b-modal>
</template>


<script>
export default {
  props: [],
  data() {
    return {
      configureFeedbackForm: {
        activateAllFeedback: null,
        excludeScenarioIntent: false
      }
    }
  },
  methods: {
    show() {
      this.$refs.modal.show()
      this.configureFeedbackForm.activateAllFeedback = null
      this.configureFeedbackForm.excludeScenarioIntent = false
    },
    hide() {
      this.$refs.modal.hide()
    }
  }
}
</script>

<style scoped>
.scenario-intent-switch {
  margin-left: 25px;
}
</style>
