<template>
  <div class="page-redirector">
    <div class="row mb-2">
      <div class="col-md-6">
        <div class="input-group">
          <input type="text" class="form-control" ref="inputUrl" v-model="inputUrl" :placeholder="$t('redirector.inputUrl')" />
          <div class="input-group-append" v-if="controller.userType !== 'viewer'">
            <button class="btn btn-outline-secondary"
                    type="button"
                    @click="createRedirectUrl"
                    :disabled="!urlValid"
            >{{ $t('redirector.create') }}</button>
            <button class="btn btn-outline-secondary" type="button" @click="clearInput">{{ $t('buttons.clear') }}</button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <b-button-group>
          <b-button variant="outline-secondary"
                    @click="exportCSV">
            <i class="fas fa-file-csv"></i>
            {{ $t('redirector.exportCSV') }}
          </b-button>
          <b-button variant="outline-secondary"
                    @click="exportExcel">
            <i class="fas fa-file-excel"></i>
            {{ $t('redirector.exportExcel') }}
          </b-button>
        </b-button-group>
      </div>
    </div>
    <h5>{{ $t('redirector.searchOptions') }}</h5>
    <form @submit.prevent="" @keypress.enter.prevent="" class="mb-2 ml-1">
      <div class="row">
        <div class="col-md-3 pt-1">
          <history-calendar :date="controller.date"></history-calendar>
        </div>
        <div class="col-md-3 pt-1">
          <button
            @click="search"
            class="btn btn-success">
            {{ $t('history.search') }}
          </button>
        </div>
      </div>
    </form>
    <!-- Data Table -->
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr class="table-info">
            <th scope="col" class="table-head head-page-title">{{ $t('redirector.pageTitle') }}</th>
            <th scope="col" class="table-head original-url">{{ $t('redirector.originalUrl') }}</th>
            <th scope="col" class="table-head redirect-url">{{ $t('redirector.redirectUrl') }}</th>
            <th scope="col" class="table-head access-log" @click="sortColumnByLogCount">
              <div class="d-flex justify-content-between align-items-center">
                {{ $t('redirector.accessLog') }}
                <i id="sortIcon-accessLog" class="fas fa-sort"></i>
              </div>
            </th>
            <th scope="col" class="table-head use-intent">{{ $t('keyword.intentListInUse') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(url, index) in filteredUrlList" :key="index">
            <td class="page-title" @click="showFullPageTitle($event.target)">{{url.page_title}}</td>
            <td class="original-url">
              <div class="d-flex justify-content-between align-items-center">
                <a :href="url.original_url" target="_blank" class="text-break mr-1">{{ url.original_url }}</a>
                <button v-b-tooltip.hover
                  :title="$t('buttons.copyTo')"
                  class="btn btn-info btn-sm"
                  @click="copyRedirectUrl(url.original_url)">
                  <i class="fas fa-copy"></i>
                </button>
              </div>
            </td>
            <td class="redirect-url">
              <div class="d-flex justify-content-between align-items-center">
                <p class="text-break mr-1">{{ controller.textHeader  }}{{ url.short_id }}</p>
                <button v-b-tooltip.hover
                  :title="$t('buttons.copyTo')"
                  class="btn btn-info btn-sm"
                  @click="copyRedirectUrl(controller.textHeader + url.short_id)">
                  <i class="fas fa-copy"></i>
                </button>
              </div>
            </td>
            <td class="access-log text-center">
              <a class="btn btn-info btn-sm"
                  :href="accessLogDetail(url.short_id)"
                  :title="$t('redirector.goToAccessLogDetail')">
                {{ $t('redirector.accessLogDetail') }}
                <span class="badge badge-light">{{ url.count }}</span>
              </a>
            </td>
            <td class="use-intent text-center">
                <button v-b-tooltip.hover
                  :title="$t('keyword.intentListInUse')"
                  class="btn btn-info btn-sm"
                  @click="openIntentListModal(url)">
                  <i class="fas fa-list"></i>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END Data Table -->
    <b-container fluid class="bv-example-row mt-3">
      <b-row>
        <b-col md="4" offset-md="5">
          <b-pagination
            v-model="currentPage"
            :total-rows="countRows"
            :per-page="perPageItems"/>
        </b-col>
      </b-row>
    </b-container>
    <error-modal
      ref="errorModalRef"
      :slot-only="true"
      @ok="closeErrorModal"
    >
      <template v-slot:body>
        {{ errorMsg }}
      </template>
    </error-modal>
    <intent-list-inuse-modal
      :language="controller.getLanguageCode()"
      :selectedIntentCategories="controller.selectedIntentCategories"
      ref="intentListInUseModal">
    </intent-list-inuse-modal>
  </div>
</template>

<script>
  import copyToClipboard from '../util/copy-url';
  import csrfToken from '../util/csrf-token';
  import {I18n} from "../util/i18n";

  export default {
    props: ['controller'],
    data () {
      return {
        inputUrl: '',
        currentPage: 1,
        perPageItems: 50,
        countRows: 0,
        sortOrder: '',
        i18n: new I18n(window.i18nContext),
        errorMsg: null,
        fullPageTitle: false
      }
    },
    computed: {
      filteredUrlList(){
        const listUrls = this.fetchListUrls()
        // count total of the result
        this.countRows = listUrls.length
        // set the current page when
        // the total result is lower than the items per page
        const pages = Math.ceil(this.countRows / this.perPageItems)
        if(pages < this.currentPage){
          this.currentPage = 1
        }
        // indexing for pagination to show the current result
        return listUrls.splice(((this.currentPage * this.perPageItems) - this.perPageItems), this.perPageItems)
      },
      urlValid () {
          return (/http[s]?:\/\/(?:[^\\s])+/g).test(this.inputUrl) || (/^tel:\+*[0-9]+(-[0-9]+)*$/.test(this.inputUrl))
      }
    },
    methods:{
      search(){
        this.controller.loadRedirectUrls()
      },
      accessLogDetail(short_id){
        return this.controller.accessLogUrl+short_id
      },
      copyRedirectUrl (text) {
        copyToClipboard(text)
        alert(this.i18n.t('redirector.UrlCopied'))
      },
      createRedirectUrl() {
        if (this.controller.allRedirectUrls.some(data => data.original_url === this.inputUrl)) {
            return
        }
        let promise = this.controller.createRedirectUrl(
          this.inputUrl,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        promise.then((data) => {
          console.log(data)
        }).catch(err => {
            const responseData = err.response.data
            this.showErrorModal(responseData.detail)
        })
      },
      clearInput() {
        this.inputUrl = ''
        this.currentPage = 1
        this.fetchListUrls()
      },
      openPage(url) {
        window.open(url)
      },
      fetchListUrls() {
        const inputEl = this.inputUrl.toLowerCase()
        return this.controller.allRedirectUrls.filter( data => {
          const url = data.original_url.toLowerCase()
          return inputEl ? url.includes(inputEl) : url
        })
      },
      exportCSV() {
        window.location.href = this.controller.fileExport('CSV')
      },
      exportExcel() {
        window.location.href = this.controller.fileExport('Excel')
      },
      sortColumnByLogCount() {
        const sortIconEl = document.getElementById('sortIcon-accessLog')
        if(sortIconEl.classList.contains('fa-sort')) {
          this.sortOrder = 'desc'
        } else if(sortIconEl.classList.contains('fa-sort-up')) {
          this.sortOrder = 'desc'
        } else {
          this.sortOrder = 'asc'
        }
        return this.controller.allRedirectUrls.sort((a, b) => {
          let comparison = ''
          sortIconEl.classList.remove('fa-sort')
          if(this.sortOrder === 'desc') {
            sortIconEl.classList.add('fa-sort-down')
            comparison = (a.count - b.count)* -1
          } else {
            sortIconEl.classList.add('fa-sort-up')
            comparison = a.count - b.count
          }
          return comparison
        })
      },
      closeErrorModal() {
          this.errorMsg = null
          this.$refs.errorModalRef.hide()
      },
      showErrorModal(msg) {
          this.errorMsg = msg
          this.$refs.errorModalRef.show()
      },
      openIntentListModal(redirector) {
        this.controller.fetchIntentListInUse(redirector)
        this.$refs.intentListInUseModal.show()
      },
      showFullPageTitle(element) {
        if (this.fullPageTitle) {
          element.style.overflow = "hidden"
          element.style.textOverflow = "ellipsis"
          element.style.overflowWrap = ""
          element.style.wordBreak = ""
          this.fullPageTitle = false
        } else {
          element.style.overflow = ""
          element.style.textOverflow = ""
          element.style.overflowWrap = "break-word"
          element.style.wordBreak = "break-all"
          this.fullPageTitle = true
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.inputUrl.focus()
      })
    }
  }
</script>
<style scoped>
  .column {
    background-color: #bee5eb;
    font-weight: bold
  }
  .wrap-overflow {
    overflow-wrap: break-word;
  }
</style>
