<template>
  <div>
      <div class="preview-left">
        <div v-if="this.intents">
          <ul style="padding: 0">
            <modal-intent-item
              :intent="intent"
              v-for="(intent, index) in intents"
              @show-detail="showDetail"
              :key="index"
            >
            </modal-intent-item>
          </ul>
        </div>
        <div v-else>
          {{ intentErrorMessage }}
        </div>
      </div>
      <div class="preview-right">
        <b-tabs v-if="selectedIntent">
          <b-tab v-for="(value, key) in selectedIntent.details" :title="key" :key="key">
            <div v-if="value.userSays.length > 0">
              {{ $t('question.title') }}:
              <ul style="padding: 0">
                <li v-for="(userSay, index) in value.userSays" :key="index">
                  <div
                    class="label my-1 p-1"
                  >
                    {{ userSay.text }}
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="value.responseMessages.length > 0">
              {{ $t('answer.title') }}:
              <ul style="padding: 0">
                <li v-for="(responseMessage, index) in value.responseMessages" :key="index">
                  <div
                    class="label my-1 p-1"
                  >
                    <span> {{(responseMessage && responseMessage.data && typeof responseMessage.data.typeDisplay === 'function') ? responseMessage.data.typeDisplay():null }}
                    </span>{{ responseMessage.data.display() }}
                  </div>
                </li>
              </ul>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
</template>

<script>
  import { ResponseMessage } from '../model/intent'
    export default {
      props: ['intentData'],
      data() {
        return {
          intents: null,
          intentErrorMessage: null,
          selectedIntent: null
        }
      },
      methods: {
        shown() {
          if (this.intentData) {
            try {
              // json文であるかどうかチェック
              this.intents = JSON.parse(this.intentData)
            } catch (e) {
              this.intents = null
              this.intentErrorMessage = e
            }
          }
        },
        hidden(){
          this.intents = null
          this.intentErrorMessage = null
          this.selectedIntent = null
        },
        showDetail(intent) {
          let language = null
          for (let [key, value] of Object.entries(intent.details)) {
            if (key === 'userSays' || key === 'responseMessages') {
              // 古いJSONファイルで取得したデータを新しい構造に変換
              if (!language)
                language = this.formattingData(key, value)
              else
                this.formattingData(key, value)
            }
            else {
              for (let [key1, value1] of Object.entries(value)) {
                this.formattingData(key1, value1)
              }
            }
          }
          if (language) {
            intent.details[language] = Object.assign({}, intent.details)
          }
          delete intent.details.userSays
          delete intent.details.responseMessages
          this.selectedIntent = intent
        },
        formattingData(key, value) {
          let language = null
          if (key === 'userSays' && value) {
            value.forEach((us) => {
              if (us && !language)
                language = us.language
              us.text = this.fullText(us.data)
            })
          }
          else if (key === 'responseMessages') {
            value.forEach((rm) => {
              // display、typeDisplayを利用したいので、文字列からオブジェクトに変換
              const new_rm = ResponseMessage.fromData(rm)
              rm.data = new_rm.data
              if (new_rm && !language) {
                language = new_rm.language
              }
            })
          }
          return language
        },
        fullText(data) {
          return data.map(wd => wd.text).join('')
        }
      }
    }
</script>
