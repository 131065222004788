import app from "./factory";
import { dia, ui, linkTools } from "@joint/plus";
import {
  maxZoomScale,
  minZoomScale,
  NODE_TYPE_ANSWER,
  NODE_TYPE_QUESTION,
  START_NODE_ID,
} from "./const";
import { layoutElements, findBiggestParent } from "./utils";

/**
 * jointjsのペーパーの初期化処理
 */
class PaperInitializer {
  constructor(graph) {
    this.graph = graph;
    this.paper = null;
    this.paperScroller = null;
  }
  /**
   * paper, paperScrollerの設定
   */
  initialize() {
    this.paper = new dia.Paper({
      width: 100,
      height: 150,
      model: this.graph,
      background: { color: "white" },
      // リンクを引っ張るときに自動でひっつく距離
      snapLinks: { radius: 75 },
      interactive: {
        vertexAdd: false,
        arrowheadMove: false,
      },
      // ノード間以外にリンクを引けないようにする
      // https://resources.jointjs.com/docs/jointjs/v4.0/joint.html#dia.Paper.prototype.options.linkPinning
      linkPinning: false,
      multiLinks: false,
      async: true,
      defaultLink: app.Factory.createLink(),
      // リンクの先端がノードのポートの中心部に乗っからないようにする
      defaultConnectionPoint: {
        // デフォルトではポートの中心部にリンクの先端がきてしまうためbboxを指定する。
        name: "bbox",
      },
      /**
       * リンク接続が正しいかどうかを判定する
       */
      validateConnection: (
        cellViewS,
        // S = source
        magnetS,
        cellViewT,
        // T = target
        magnetT,
        end,
        linkView
      ) => {
        // Prevent linking from input ports.
        if (magnetS && magnetS.getAttribute("port-group") === "in")
          return false;

        // Prevent linking from output ports to input ports within one element.
        if (cellViewS === cellViewT) return false;

        // つなげようとしているノード間に既にリンクが存在している場合はリンクをかけられないようにする
        if (this.existsLink(cellViewS, cellViewT)) return false;

        // Prevent linking to input ports.
        const isTargetInPort =
          magnetT && magnetT.getAttribute("port-group") === "in";

        // answerからquestionへのリンクのみ許可
        const isValidNodeCombination =
          cellViewS.model.get("type") === "qad.Answer" &&
          cellViewT.model.get("type") === "qad.Question";

        return isTargetInPort && isValidNodeCombination;
      },
    });

    this.paperScroller = new ui.PaperScroller({
      paper: this.paper,
      background: {
        color: "white",
      },
      cursor: "grab",
      autoResizePaper: true,
    });

    document.getElementById("paper").appendChild(this.paperScroller.el);

    return [this.paper, this.paperScroller];
  }

  /**
   * paperのコールバック関数の設定を行う。
   * paperに依存するselectionに依存しているため、initialize()とは分けての処理をする
   */
  setPaperCallbacks(selection, vm, nodes) {
    this.paper.on({
      /**
       * マニュアルでリンクが接続されたあとのコールバック
       */
      "link:connect": (linkView, evt, connectedToView, magnetElement, type) => {
        // TODO createLinkでリンクを作成した場合に呼ばれないか確認
        const sourceIntentId = linkView.sourceView.model.attributes.intentId;
        const targetIntentId = linkView.targetView.model.attributes.intentId;
        // もし先頭のnodeに新しいnodeがくっついたのであれば、startをつなぎ直す
        const biggestQuestionNode = findBiggestParent(
          this.graph.getLinks(),
          nodes
        );
        this.biggestParent = biggestQuestionNode;
        const startLink = this.graph
          .getLinks()
          .find((link) => link.attributes.source.id == START_NODE_ID);
        if (startLink) startLink.target(this.biggestParent);
        vm.$refs.scenarioDiagram.onLinkConnected(
          sourceIntentId,
          targetIntentId
        );
        layoutElements(this.graph);
      },
      /**
       * ペーパー上でクリックがあった場合のコールバック
       */
      "link:pointerdown": (linkView, evt) => {
        // リンクをかける途中にツールがでないようにする
        if (!linkView.targetView) return;
        // リンクがスタートリンクだった場合はツールを出さない
        if (linkView.model.attributes.source.id.indexOf("node_a") < 0) return;

        // 一度すべてのリンクツールを削除してから新しくリンクツールを作成する
        this.paper.removeTools();

        const toolsView = new dia.ToolsView({
          name: "basic-tools",
          tools: [
            new linkTools.Boundary(),
            new linkTools.Remove({
              offset: 20,
              distance: -40,
              action: (evt, linkView, toolView) => {
                linkView.model.remove();
                vm.onLinkRemoved(
                  linkView.sourceView.model.attributes.intentId,
                  linkView.targetView.model.attributes.intentId
                );
              },
            }),
          ],
        });
        linkView.addTools(toolsView);
      },
      /**
       * 空白をクリック時、選択時ツールを除去
       */
      "blank:pointerdown": (evt, x, y) => {
        this.paperScroller.startPanning(evt, x, y);
      },
      /**
       * マウスホイールによるズーム倍率の操作を許可
       */
      "blank:mousewheel": (evt, x, y, delta) => this.mousewheelZoom(evt, delta),
      "cell:mousewheel": (cellView, evt, x, y, delta) =>
        this.mousewheelZoom(evt, delta),
      /**
       * 要素をクリックが離れた時の処理
       * @param {*} elementView
       */
      "element:pointerup": (elementView) => {
        // ノードが選択されたとき、リンクの選択を解除しておく
        this.paper.removeTools();
        selection.collection.reset([elementView.model]);

        // スタートノードがクリックされた場合
        if (elementView.model.id === START_NODE_ID) {
          // スタートノードへのクリックだった場合、リンクがなければ質問作成のモーダルを呼び出し
          const linksOfStartNode = this.graph
            .getLinks()
            .filter((link) => link.attributes.source.id === START_NODE_ID);
          // スタートノードにリンクがなければ、質問作成のモーダルを呼び出す
          if (linksOfStartNode.length === 0) {
            vm.controller.isAddingNewIntent = true;
            vm.$refs.intentDetail.addUserSay();
          }
          return;
        }

        // 選択されたノードのインテントを選択状態にしておく
        vm.$refs.scenarioDiagram.selectIntent(
          elementView.model.attributes.intentId,
          elementView.model.attributes.type
        );

        let toolsView;
        if (
          elementView.model.attributes.type == NODE_TYPE_QUESTION &&
          !vm.wizard
        ) {
          toolsView = new dia.ToolsView({
            name: "basic-tools",
            tools: [
              app.Factory.createAddButton("96%", "#AAD6EC", NODE_TYPE_QUESTION),
              app.Factory.createDeleteButton("96%", vm, NODE_TYPE_QUESTION),
            ],
          });
        } else if (elementView.model.attributes.type == NODE_TYPE_ANSWER) {
          toolsView = new dia.ToolsView({
            name: "basic-tools",
            tools: [
              app.Factory.createAddButton("90%", "#edabab", NODE_TYPE_ANSWER),
            ],
          });
          if (!vm.wizard) {
            // ウィザード中でなければ削除ボタンを表示
            toolsView.options.tools.push(
              app.Factory.createDeleteButton("90%", vm, NODE_TYPE_ANSWER)
            );
          }
        }

        // 選択時ツールを対象のエレメントに追加
        elementView.addTools(toolsView);
      },
    });
  }

  /**
   * リンクをかける際、既にそのルートにリンクがかかっているかを判定
   */
  existsLink(source, target) {
    const links = this.graph.getLinks();
    return links.find(
      (link) =>
        link.attributes.source.id == source.model.id &&
        link.attributes.target.id == target.model.id
    );
  }

  /**
   * マウスホイール時のズーム倍率操作処理
   */
  mousewheelZoom(evt, delta) {
    evt.preventDefault();
    const speed = delta * 0.05;
    const origin = this.paperScroller.getVisibleArea().center();
    this.paperScroller.zoom(speed, {
      ox: origin.x,
      oy: origin.y,
      max: maxZoomScale,
      min: minZoomScale,
    });
  }
}

export { PaperInitializer };
