import { detectStringType } from './detect-string-type'


export const isDateStringList = (data) => {
  if (!Array.isArray(data)) {
    return false
  }

  return data.every((part) => detectStringType(part) === 'date')
}

export const isDatetimeString = (data) => {
  return detectStringType(data) === 'datetime'
}
