import PlatformDataResource from "../resource/platforms"

// param combined: Session IDはintegrationで組み合わせるプラットフォームかどうかを示す
const platformDataResource = new PlatformDataResource()
const userMetaPlatformsData = platformDataResource.UserMetaPlatformsData

class SessionUserMeta {
  constructor(data, origin=null) {
    this.id = data.id
    this.fullName = data.full_name || ''
    this.email = data.email || ''
    this.profileUrl = data.profile_url || ''
    this.userId = data.user_id
    this.tel = data.tel || ''

    this._freeDataText = ''
    this.freeData = data.free_data || null
    if (this.freeData) {
      this._freeDataText = JSON.stringify(this.freeData, null, 2)
    }

    userMetaPlatformsData.forEach(platform => {
      this[platform.fieldName] = data[platform.serverFieldName]
    })
    this.origin = origin

    this._sessionFields = userMetaPlatformsData.map(platform => platform.fieldName)
    this._editableFields = ['fullName', 'email', 'profileUrl', 'userId', 'tel'].concat(this._sessionFields)
  }

  static fromData(data) {
    return new SessionUserMeta(
      data, Object.assign({}, data)
    )
  }

  toData() {
    const result = {
      id: this.id,
      full_name: this.fullName,
      email: this.email,
      profile_url: this.profileUrl,
      user_id: this.userId,
      tel: this.tel,
      free_data: this.freeData
    }
    userMetaPlatformsData.forEach(platform => {
      result[platform.serverFieldName] = this[platform.fieldName]
    })
    return result
  }

  get validSessionFields() {
    return this._sessionFields.filter(field => this[field] != null && this[field].length > 0)
  }

  get displayedFreeData() {
    if (!this.freeData) { return [] }

    return Object.keys(this.freeData).map(k => {
      return { name: k, value: this.freeData[k] }
    })
  }

  reset() {
      if (!this.origin) {
        return false
      }
      this.id = this.origin.id
      this.fullName = this.origin.full_name
      this.email = this.origin.email
      this.profileUrl = this.origin.profile_url
      this.userId = this.origin.user_id
      this.tel = this.origin.tel
      userMetaPlatformsData.forEach(platform => {
        this[platform.fieldName] = this.origin[platform.serverFieldName]
      })
      return true
  }

  get formData() {
    return this._editableFields.map(field => {
      let validator = null
      switch (field) {
        case 'fullName':
          validator = !!this.fullName
          break
        case 'email':
          validator = !this.email || (/^.+@.+\..+/g).test(this.email)
          break
        case 'profileUrl':
          validator = !this.profileUrl || (/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g).test(this.profileUrl)
          break
        case 'tel':
          validator = (!this.tel && this.tel !== 0) || (/^[0-9-]+$/g).test(this.tel)
          break
        default:
          validator = true
      }
      return {
        fieldName: field,
        validator: validator
      }
    })
  }

  get freeDataText() {
    return this._freeDataText
  }

  set freeDataText(val) {
    this._freeDataText = val
  }

  get isFreeDataValid() {
    try {
      if (this._freeDataText?.trim() === '') {
        this.freeData = null
        return true
      }
      this.freeData = JSON.parse(this._freeDataText)
      return true
    } catch (e) {
      return false
    }
  }

  get isValid() {
    return !this.formData.some(field => !field.validator) && this.isFreeDataValid
  }

  static get supportedPlatforms() {
    return userMetaPlatformsData.map(platform => platform.name)
  }

  static get combinedSessionPlatforms() {
    return userMetaPlatformsData.filter(platform => platform.combined).map(platform => platform.name)
  }
}

class UserMetaColumn {
  constructor(id = null, key = null, description = null, ordering = null) {
    this.id = id
    this.key = key
    this.description = description
    this.ordering = ordering
  }

  toData() {
    return {
      id: this.id,
      key: this.key,
      description: this.description,
      ordering: this.ordering
    }
  }
}

UserMetaColumn.fromData = function fromData(data) {
  return new UserMetaColumn(
    data.id || null,
    data.key || null,
    data.description || null,
    data.ordering || null
  )
}

export {
  SessionUserMeta,
  UserMetaColumn,
  userMetaPlatformsData
}
