<template>
  <div class="chat-window">
    <div class="chat-header">
      <strong>{{ $t("buttons.testChat") }}</strong>
      <span
        class="close-button float-right pointer-cursor"
        @click="closeChatWindow"
      >
        <b-badge variant="danger"><i class="fas fa-times"></i></b-badge>
      </span>
    </div>

    <language-change-chat-window
      class="text-center mt-1"
      :supportedLanguages="supportedLanguages"
      :language="chatLanguage"
      @select-chat-language="selectedChatLanguage"
    />

    <div class="input-group input-group-control">
      <b-form-input
        :placeholder="$t('testChat.tryItNow')"
        v-model="userSay"
        ref="TryItNow"
        @keypress.native.enter="sendRequest"
        class="form-control-custom"
        type="text"
        :state="isValidUserSay"
        :title="!isValidUserSay && userSay.length > 0 && $t('intent.testChatUserSayErrorMsg')"
      />

      <div class="input-group-append">
        <b-button
          class="input-group-text pointer-cursor"
          @click="sendRequest"
          :disabled="!isValidUserSay"
        >
          <i class="fa fa-paper-plane plane-color"></i>
        </b-button>
      </div>
    </div>

    <hr class="m-0" />

    <div class="input-group input-group-control">
      <b-form-input
        :placeholder="$t('testChat.eventPlaceholder')"
        v-model="eventName"
        @keypress.native.enter="sendEvent"
        class="form-control-custom"
        type="text"
      />

      <div class="input-group-append">
        <span class="input-group-text pointer-cursor" @click="sendEvent">
          <i class="fa fa-paper-plane plane-color"></i>
        </span>
        <span class="pointer-cursor btn btn-primary" @click="sendWelcomeEvent">
          {{ $t("testChat.welcomeEvent") }}
        </span>
      </div>
    </div>

    <hr />

    <div class="d-flex justify-content-center mt-5" v-if="loadingScreen">
      <b-spinner
        style="width: 5rem; height: 5rem"
        label="Loading..."
        variant="primary"
      />
    </div>

    <div class="chat-content" v-else-if="requestUserSay !== null">
      <div>
        <div class="response-header">USER SAYS</div>
        <div>{{ requestUserSay }}</div>
      </div>

      <hr />

      <div>
        <div class="response-header">
          <b-form-select
            v-model="selectedPlatform"
            :options="responseOption"
            class="form-control-custom option-width"
          />
        </div>

        <div
          v-if="requestErrorMsg"
          class="alert alert-dismissable alert-danger"
        >
          <i class="fas fa-exclamation-circle"></i>
          {{ requestErrorMsg }}
        </div>

        <div v-if="!!chatItemsByPlatform">
          <div
            v-for="(chatItem, chatItemIdx) in chatItemsByPlatform[
              formattedSelectedPlatform
            ]"
            :key="`chat-item-${chatItemIdx}`"
            class="chat-items-container"
          >
            <!-- テキストやカードなどの回答表示領域 -->
            <div v-if="chatItem.logType === 'text'">
              <div v-if="chatItem.text" class="mb-1 message-container">
                <div class="message-bg-grey" style="white-space: pre-line">
                  <span>{{ chatItem.text }}</span>
                </div>
              </div>
            </div>

            <div v-else-if="chatItem.logType === 'quick_replies'">
              <div class="message-bg-grey">
                <div class="card-header">
                  <div>
                    {{ chatItem.text }}
                  </div>
                </div>

                <div class="card-button-container">
                  <div
                    v-for="(option, idx) in chatItem.options"
                    :key="idx"
                    class="ml-2"
                  >
                    <div class="d-flex align-items-center">
                      <div>
                        {{ option }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="chatItem.logType === 'card'">
              <div class="message-bg-grey">
                <div class="card-header">
                  <div>
                    {{ chatItem.title }}
                  </div>

                  <div class="card-subtitle">
                    {{ chatItem.subtitle }}
                  </div>
                </div>

                <b-img
                  v-if="chatItem.imageUrl"
                  thumbnail
                  fluid
                  :src="chatItem.imageUrl"
                  alt="Image"
                />

                <div class="card-button-container">
                  <div
                    v-for="(button, idx) in chatItem.buttons"
                    :key="idx"
                    class="ml-2"
                  >
                    <div class="d-flex align-items-center">
                      <div>
                        {{ button.text }}
                      </div>
                      <a
                        v-if="button.url"
                        :href="button.url"
                        target="_blank"
                        class="text-dark"
                      >
                        <i class="fas fa-external-link-alt ml-3"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="chatItem.logType === 'image'">
              <div class="message-bg-grey">
                <b-img thumbnail fluid :src="chatItem.url" alt="Image" />
              </div>
            </div>

            <div v-else-if="chatItem.logType === 'audio'">
              <div class="message-bg-grey">
                <audio controls style="width: 100%; max-height: 200px">
                  <source :src="chatItem.src" />
                </audio>
              </div>
            </div>

            <div v-else-if="chatItem.logType === 'video'">
              <div class="message-bg-grey">
                <video
                  controls
                  :poster="chatItem.previewSrc"
                  width="100%"
                  height="200"
                >
                  <source :src="chatItem.src" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div v-if="outputContexts">
        <div class="response-header">
          Contexts
          <b-button
            class="btn btn-danger btn-xs float-right"
            @click="resetContexts()"
          >
            RESET CONTEXTS
          </b-button>
        </div>

        <div class="row ml-1">
          <div
            v-for="(context, index) in outputContexts"
            :key="index"
            class="mr-1 box pointer-cursor"
            :class="{ box1: index === 0 }"
            title="Active contexts"
          >
            {{ getContextName(context.name) }}
          </div>
        </div>
        <hr />
      </div>

      <div>
        <div class="response-header">intent</div>
        <div
          v-if="
            chatItemsByPlatform &&
            chatItemsByPlatform[formattedSelectedPlatform] &&
            chatItemsByPlatform[formattedSelectedPlatform].length > 0
          "
        >
          <a
            v-bind:href="
              goToIntentDetail(
                chatItemsByPlatform[formattedSelectedPlatform][0].intentName
              )
            "
          >
            {{ chatItemsByPlatform[formattedSelectedPlatform][0].intentName }}
          </a>
        </div>
      </div>

      <hr />

      <!-- action情報表示領域 -->
      <div>
        <div class="response-header">action</div>
        <div>
          {{
            chatItemsByPlatform &&
            chatItemsByPlatform[formattedSelectedPlatform] &&
            chatItemsByPlatform[formattedSelectedPlatform].length > 0
              ? chatItemsByPlatform[formattedSelectedPlatform][0].action ||
                "Not available"
              : "Not available"
          }}
        </div>
      </div>

      <!-- usersayに設定したパラメーター情報表示領域 -->
      <div class="row mt-1">
        <div class="col-6 response-header">parameter</div>
        <div class="col-6 response-header">value</div>
        <template
          v-for="([key, value], index) in Object.entries(parameterObject)"
        >
          <div class="col-6">{{ key }}</div>
          <div class="col-6">{{ value }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PlatformDataResource from "../resource/platforms";

export default {
  props: [
    "chatItemsByPlatform",
    "loadingScreen",
    "supportedLanguages",
    "chatLanguage",
    "hasContext",
    "defaultPlatform",
    // リクエストで送信された質問文
    "requestUserSay",
    "parameterObject",
    "outputContexts",
    "availablePlatform",
    "requestErrorMsg",
  ],
  data() {
    return {
      userSay: "",
      eventName: "",
      userSays: {},
      responseOption: [{ value: null, text: "OTHER" }],
      selectedPlatform: this.defaultPlatform,
    };
  },
  created() {
    const platformResource = new PlatformDataResource(this.availablePlatform);
    this.responseOption = this.responseOption.concat(platformResource.ChatBoxResponseOptions);
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.TryItNow.focus();
    });
  },
  methods: {
    closeChatWindow() {
      this.$emit("close-chat-window");
    },
    selectedChatLanguage(language) {
      this.$emit("select-chat-language", language);
    },
    sendWelcomeEvent() {
      this.userSays.text = "";
      this.userSays.language = this.chatLanguage;
      this.userSays.event = "Welcome";
      this.$emit("send-request", this.userSays);
    },
    sendEvent() {
      if (this.eventName.trim().length >= 1) {
        this.userSays.text = "";
        this.userSays.language = this.chatLanguage;
        this.userSays.event = this.eventName;
        this.$emit("send-request", this.userSays);
        this.eventName = "";
      } else {
        this.eventName = "";
      }
    },
    sendRequest() {
      if (this.userSay.trim().length >= 1) {
        this.userSays.text = this.userSay;
        this.userSays.language = this.chatLanguage;
        this.userSays.event = "";
        this.$emit("send-request", this.userSays);
        this.userSay = "";
      } else {
        this.userSay = "";
        this.$refs.TryItNow.focus();
      }
    },
    postbackCall(text) {
      this.userSay = text;
      this.sendRequest();
    },
    goToIntentDetail(intentName) {
      if (intentName) {
        const currentUrl = location.href;
        const regex = RegExp("agent.+");
        const destination = `agent/${this.chatLanguage}/intent?intent_name=${intentName}`;
        return currentUrl.match(regex)
          ? currentUrl.replace(regex, destination)
          : destination;
      }
      return "#";
    },
    resetContexts() {
      this.$emit("reset-contexts");
    },
    getContextName(name) {
      const context = name.split("/");
      return context[context.length - 1];
    },
  },
  computed: {
    formattedSelectedPlatform() {
      if (this.selectedPlatform === "skype") {
        return "web_chat";
      }
      return this.selectedPlatform;
    },
    isValidUserSay() {
      return this.userSay.length > 0 && this.userSay.length <= 256;
    },
  },
};
</script>

<style scoped>
.box {
  padding: 5px;
  border: 1px solid green;
  text-align: center;
}
.box1 {
  padding: 5px;
  border: 1px solid #f0832a;
  text-align: center;
}
.chat-header:hover {
  cursor: move;
}
.chat-items-container {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.message-bg-grey {
  background-color: #ccc;
  max-width: 100%;
  padding: 0.2rem;
  border-radius: 8px;
}
</style>
