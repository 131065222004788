import { I18n } from '../util/i18n'
import csrfToken from '../util/csrf-token'
import { RestApi } from '../util/rest-api'
import { Scenario, ScenarioIntentCategory } from '../model/context'
import {
  Context,
  Intent,
  IntentCategory,
  IntentContext,
  IntentData,
  IntentDetail,
  IntentDetailKeyword,
  UserSay,
  Word
} from '../model/intent'
import { Keyword, KeywordCategory, KeywordValue } from '../model/keyword'
import SynchronizeController from './synchronize-controller'
import { VIEW_URL } from '../resource/urls'
import { v4 as uuidv4 } from 'uuid'
import copyToClipboard from '../util/copy-url'
import axios from 'axios/index'
import { TestChatController } from './test-chat-controller'
import { NODE_TYPE_QUESTION, NODE_TYPE_ANSWER } from '../util/rappid/const'
import app from '../util/rappid/app'

export default class ScenarioEditorController {
  constructor(
    apiUrl,
    userType,
    i18nContext,
    scenarioId,
    supportedLanguages = [],
    language = null,
    intentName = null,
    available_platform,
    default_platform,
    auto_input_predict,
    test_chat_platform
  ) {
    // 自動的に入力補完用サーバーにデータを送信するかどうか
    this.autoInputPredict = auto_input_predict
    this.availablePlatform = available_platform
    this.default_platform = default_platform
    this.apiUrl = apiUrl
    this.i18n = new I18n(i18nContext)
    this.userType = userType
    this.language = language
    this.supportedLanguages = supportedLanguages
    this.scenarioId = scenarioId
    this.fullIntentName = intentName
    this.scenario = null
    this.keywordCategoryApi = new RestApi(
      apiUrl.KeywordCategory,
      KeywordCategory
    )
    this.keywordApi = new RestApi(apiUrl.Keyword, Keyword)
    this.keywordValueApi = new RestApi(apiUrl.KeywordValue, KeywordValue)
    this.scenarioApi = new RestApi(apiUrl.Scenario, Scenario)
    this.contextApi = new RestApi(apiUrl.Context, Context)
    this.intentContextApi = new RestApi(apiUrl.IntentContext, IntentContext)
    this.intentApi = new RestApi(apiUrl.Intent, Intent)
    this.intentCategoryApi = new RestApi(apiUrl.IntentCategory, IntentCategory)
    this.intentDetailApi = new RestApi(apiUrl.IntentDetail, IntentDetail)
    this.intentDetailSearchApi = new RestApi(
      apiUrl.IntentDetailSearch,
      IntentDetail
    )
    this.allIntentApi = new RestApi(apiUrl.AllIntent, Intent)
    this.scenarioIntentCategoryApi = new RestApi(
      apiUrl.ScenarioIntentCategory,
      ScenarioIntentCategory
    )
    this.intentDetailKeywordApi = new RestApi(
      apiUrl.intentDetailKeyword,
      IntentDetailKeyword
    )
    this.allKeywordApi = new RestApi(apiUrl.AllKeyword, Keyword)
    this.scenarioIndexUrl = VIEW_URL.ScenarioIndex
    this.scenarioEditorBaseUrl = VIEW_URL.ScenarioEditor
    this.synchronizeController = new SynchronizeController(apiUrl, this.i18n)
    this.userSaysSchronizeApi = apiUrl.userSaysSynchronize
    this.scenarioDiagramId = 'scenario-diagram'
    this.isAddingNewIntent = false
    this.newResponse = false
    this.scenarios = []
    this.contexts = []
    this.baseContext = null
    this.intentContexts = []
    this.intentCategories = []
    this.selectedIntentCategory = null
    // 質問一括検索用
    this.allIntents = []
    this.intents = []
    this.intentOptions = [{ value: null, text: '---' }]
    this.intentDetails = []
    this.selectedIntent = null
    this.selectedIntentDetail = null
    this.selectedUserSay = null
    this.selectedResponseMessage = null
    this.questionSearchResult = null
    this.selectedLanguageIntentDetal = null
    this.vm = null
    this.scenarioIntentCategoryId = null
    this.scenarioIntentName = ''
    this.copiedMessage = ''
    this.selectedLanguageIntentDetail = null
    this.intentDetailKeywordList = []
    this.keywordFullNameById = {}
    this.intentDetailKeywordListBySearch = []
    this.testChatController = new TestChatController(
      apiUrl,
      supportedLanguages,
      new I18n(i18nContext),
      available_platform,
      test_chat_platform
    )
    this.TryItNowApi = apiUrl.TryItNow
    this.keywordBoundCount = 0
    this.selectedIntentContext = null
  }

  /**
   * Load all intent category
   */
  async ready() {
    this.intentCategories = await this.intentCategoryApi.list()
  }

  /**
   * diagramの作成
   */
  async showDiagram(csrfToken = null) {
    await this.loadScenario()
    const promise1 = this.loadScenarioIntentData(csrfToken)
    const promise2 = this.loadScenarioContextData(csrfToken)
    await Promise.all([promise1, promise2])

    window.appView = new app.AppView({ vm: this.vm })

    // 会話履歴から飛んできた場合の処理
    const splitNames = this.fullIntentName.split('_')
    const intentName = splitNames[splitNames.length - 1]
    const intents = this.intents.filter(intent => intent.name === intentName)
    if (intents.length) this.selectIntent(intents[0].id)
  }

  /**
   * シナリオに関連するインテントカテゴリ、インテント、インテント詳細の読み込み
   */
  async loadScenarioIntentData(csrfToken = null) {
    // インテントカテゴリ読み込み
    await this.loadScenarioIntentCategory()
    // インテント読み込み
    this.intents = await this.intentApi.list({
      params: {
        category_id: this.scenarioIntentCategoryId
      }
    })
    // インテント詳細読み込み
    await this.loadIntentDetails(csrfToken)
  }

  /**
   * シナリオに関連するコンテキスト、インテントコンテキストの読み込み
   */
  async loadScenarioContextData(csrfToken = null) {
    await this.loadContexts()
    await this.loadIntentContexts(csrfToken)
  }

  /**
   * シナリオ情報のロード
   */
  async loadScenario() {
    this.scenario = await this.scenarioApi.retrieve(
      new Scenario(this.scenarioId)
    )
  }

  /**
   * シナリオに紐付いたインテントカテゴリの抽出
   */
  async loadScenarioIntentCategory() {
    const instances = await this.scenarioIntentCategoryApi.list({
      params: {
        scenario_id: this.scenario.id
      }
    })
    this.scenarioIntentCategoryId = instances[0].intentCategory.id
    await this.loadIntentCategory(this.scenarioIntentCategoryId)
  }

  /**
   * シナリオのインテントカテゴリをロードする
   */
  async loadIntentCategory(intentCategoryId) {
    const instance = await this.intentCategoryApi.retrieve({
      id: intentCategoryId
    })
    this.scenarioIntentCategory = instance
  }

  /**
   * インテント詳細のロード
   */
  async loadIntentDetails(csrfToken = null) {
    const params = {
      intent_ids: JSON.stringify(this.intents.map(intent => intent.id))
    }
    const sendOptions = {}
    const options = {}
    Object.assign(sendOptions, options, {
      headers: { 'X-CSRFToken': csrfToken }
    })
    // api呼び出し
    const response = await axios.post(
      this.apiUrl.IntentDetails,
      params,
      sendOptions
    )

    this.intentDetails = response.data.intent_detail.map(intentDetail =>
      IntentDetail.fromData(intentDetail)
    )
  }

  /**
   * 選択したインテント詳細ロード
   */
  async loadSelectedIntentDetail(nodeType = null) {
    // インテント詳細が選択されていない場合は何も行わない
    if (!this.selectedIntentDetail) return

    this.keywordBoundCount = 0

    const instances = await this.intentDetailApi.list({
      params: {
        intent_id: this.selectedIntent.id,
        language: this.language
      }
    })
    // データを取得できた場合はリストに1件だけ入っているので先頭の要素を使う
    this.selectedIntentDetail = instances[0]
    if (nodeType == NODE_TYPE_QUESTION) {
      this.selectedUserSay = this.selectedIntentDetail.data.userSays[0]
      this.selectedResponseMessage = null
    } else if (nodeType == NODE_TYPE_ANSWER) {
      this.selectedResponseMessage = this.selectedIntentDetail.data.responseMessages.slice(
        -1
      )[0]
      this.selectedUserSay = null
    }

    await this.loadIntentDetailKeyword()
    await this.loadTotalKeywordBoundForIntent()
  }

  /**
   * load intent detail and keyword dependency
   */
  async loadIntentDetailKeyword() {
    if (!this.selectedIntentDetail) {
      // インテント未選択状態のときはリストを空にしておく
      this.intentDetailKeywordList = []
      return
    }
    const instance = await this.intentDetailKeywordApi.list({
      params: {
        intent_detail_id: this.selectedIntentDetail.id
      }
    })
    this.intentDetailKeywordList = instance
  }

  async loadIntentDetailKeywordById(id) {
    this.intentDetailKeywordList = await this.intentDetailKeywordApi.list({
      params: {
        keyword_id: id
      }
    })
  }

  /**
   * Load intent detail and keyword dependency according to search string
   */
  loadIntentDetailKeywordBySearch(id) {
    this.intentDetailKeywordApi
      .list({
        params: {
          keyword_id: id
        }
      })
      .then(instance => {
        this.intentDetailKeywordListBySearch = instance
      })
  }

  /**
   * make keyword full name by id
   */
  async keywordPathById() {
    const instance = await this.keywordCategoryApi.list()
    const category = this.createCategoryPathIndex(instance)
    const keywords = await this.allKeywordApi.list()
    for (const keyword of keywords) {
      this.keywordFullNameById[keyword.id] =
        '@' + category[keyword.categoryId] + '_' + keyword.name
    }
  }

  /**
   * Save keyword Category here
   */
  saveKeywordCategory(category, csrfToken = null) {
    let promise = this.keywordCategoryApi.save(category, csrfToken)
    return [true, this.i18n.t('general.saving'), promise]
  }

  /**
   * キーワードを保存
   */
  async saveKeyword(keyword, csrfToken = null) {
    try {
      return await this.keywordApi.save(keyword, csrfToken, {
        params: {
          category_id: keyword.categoryId
        }
      })
    } catch (err) {
      console.error(err)
      throw err.response.data
    }
  }

  /**
   * 存在しない場合はキーワード値を保存
   */
  saveKeywordValue(keywordValue, csrfToken = null) {
    this.keywordValueApi
      .list({
        params: {
          keyword_id: keywordValue.keywordId,
          language: this.language
        }
      })
      .then(instances => {
        let isSynonym = false
        for (let instance of instances) {
          if (instance.synonym) {
            if (instance.synonym.indexOf(keywordValue.value) >= 0) {
              isSynonym = true
              break
            }
          }
        }
        if (!isSynonym) {
          this.keywordValueApi.save(keywordValue, csrfToken, {
            params: {
              keyword_id: keywordValue.keywordId,
              language: this.language
            }
          })
        }
      })
  }

  /**
   * 他言語のuserSaysを参照する機能でロードするときに呼び出し
   */
  loadSelectedLanguageUserSays(language_code) {
    if (!language_code) {
      this.selectedLanguageIntentDetail = null
      return
    }
    this.intentDetailApi
      .list({
        params: {
          intent_id: this.selectedIntent.id,
          language: language_code
        }
      })
      .then(instances => {
        if (instances.length == 0) {
          this.selectedLanguageIntentDetail = new IntentDetail()
        } else {
          this.selectedLanguageIntentDetail = instances[0]
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  /**
   * 質問の多言語比較時にコピーする
   */
  copyUserSays(intentDetail) {
    let userSaysTexts = intentDetail.data.userSays
      .map(userSay => {
        return userSay.text
      })
      .join('\n')
    copyToClipboard(userSaysTexts)
    let message = this.i18n.t('question.copiedUserSays')
    alert(message)
  }

  /**
   * シナリオに属する全コンテキストの読み込み
   */
  async loadContexts() {
    this.contexts = await this.contextApi.list({
      params: { scenario_id: this.scenarioId }
    })
    this.baseContext = this.contexts.find(
      context => context.parentContext == null
    )
  }

  /**
   * 渡されたcontextに関連するIntentContextの読み込み
   */
  async loadIntentContexts(csrfToken = null) {
    const params = {
      context_ids: JSON.stringify(this.contexts.map(context => context.id))
    }
    const sendOptions = {}
    const options = {}
    Object.assign(sendOptions, options, {
      headers: { 'X-CSRFToken': csrfToken }
    })
    const response = await axios.post(
      this.apiUrl.IntentContexts,
      params,
      sendOptions
    )
    this.intentContexts = response.data.intent_context.map(intentContext => {
      intentContext.context.parent_context = this.contexts.find(
        context => context.id == intentContext.context.parent_context
      )
      return IntentContext.fromData(intentContext)
    })
  }

  /**
   * Find Intent Category Name
   */
  resolveCategoryPath(category, categoryIdToCategory) {
    if (category.parentId == null) {
      return [category]
    } else {
      const parentCategory = categoryIdToCategory[category.parentId]
      return this.resolveCategoryPath(
        parentCategory,
        categoryIdToCategory
      ).concat([category])
    }
  }

  /**
   * カテゴリ名のインデックス生成
   */
  createCategoryPathIndex(categories) {
    let flat = KeywordCategory.categoriesAsFlat(categories)
    // categoryId -> categoryを作成
    let categoryIdToCategory = {}
    for (const [nestedLevel, category] of flat) {
      categoryIdToCategory[category.id] = category
    }
    // category -> カテゴリツリーのパス生成
    let categoryIdToPath = {}
    for (const [nestedLevel, category] of flat) {
      const categoryPath = this.resolveCategoryPath(
        category,
        categoryIdToCategory
      )
      categoryIdToPath[category.id] = categoryPath
        .map(c => {
          return c.name
        })
        .join('_')
    }
    return categoryIdToPath
  }

  /**
   * 選択中のnodeのデータを全言語に渡って削除
   * 質問の削除であれば、選択中のインテントの全言語の全ての質問を削除
   * @param {string} csrfToken
   * @param {string} deleteNodeType 削除対象のノードのタイプ。qad.Question or qad.Answer
   */
  async deleteNode(csrfToken = null, deleteNodeType) {
    const sendOptions = {}
    const options = {}
    Object.assign(sendOptions, options, {
      headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' }
    })
    const params = {
      intent_id: this.selectedIntent.id,
      delete_node_type: deleteNodeType
    }
    const response = await axios.post(
      this.apiUrl.DeleteScenarioNode,
      params,
      sendOptions
    )

    if (response.data.intent_details.length == 0) {
      // インテントの削除まで行われた場合、インテントに関連するデータを取り除く
      this.intents = this.intents.filter(
        intent => intent.id != this.selectedIntent.id
      )
      this.intentDetails = this.intentDetails.filter(
        intentDetail => intentDetail.intentId != this.selectedIntent.id
      )
    } else {
      // インテントは削除されていないので、インテント詳細のアップデートのみ行う
      this.intentDetails = this.intentDetails
        .map(intentDetail => {
          if (intentDetail.intentId != this.selectedIntent.id)
            return intentDetail

          // 保存されたインテント詳細に一致するものは古いものと置き換える
          const updatedIntentDetail = response.data.intent_details.find(
            _intentDetail => _intentDetail.id == intentDetail.id
          )
          return updatedIntentDetail
            ? IntentDetail.fromData(updatedIntentDetail)
            : null
        })
        .filter(i => i)
    }

    window.appView.deleteNode(this.selectedIntent.id, deleteNodeType)

    this.resetSelections()
  }

  /**
   * synchronizeControllerを呼び出してdialogflowへの同期を開始する
   */
  async startSynchronize(csrfToken = null, isCard) {
    return await this.synchronizeController.synchronize(csrfToken, isCard)
  }

  /**
   * rappid側からVueコンポネントにアクセスするためにvmを渡す。
   */
  setVM(vm) {
    this.vm = vm
  }

  /**
   * インテント選択状態の操作
   * @param {number | null} selectedIntentId 22883node_
   * @param {string | null} nodeType qad.Question or qad.Answer
   */
  async selectIntent(selectedIntentId, nodeType = null) {
    // nullが渡された場合は全ての選択を解除
    if (!selectedIntentId) return this.resetSelections()

    // ノードIDからインテントIDを抽出
    this.selectedIntent = this.intents.find(
      intent => intent.id == selectedIntentId
    )
    // Set context settings
    this.selectedIntentContext = this.intentContexts.filter(
      ic => ic.intentId === selectedIntentId && !ic.isInput
    )

    // 選択されたインテントIDを持つ現在の言語のインテント詳細を選択状態にする。
    // 質問・回答ともにない場合はダミーのインテント詳細を選択
    const selectedIntentDetail = this.intentDetails.find(
      intentDetail =>
        intentDetail.intentId == selectedIntentId &&
        (intentDetail.data.userSays.some(
          userSay => userSay.language === this.language
        ) ||
          intentDetail.data.responseMessages.some(
            responseMessage => responseMessage.language === this.language
          ))
    )
    if (selectedIntentDetail) {
      // インテント詳細を選択状態にし、詳細情報をサーバーから取得
      this.selectedIntentDetail = selectedIntentDetail
      await this.loadSelectedIntentDetail(nodeType)
    } else {
      // ダミーのインテント詳細を選択
      this.selectedIntentDetail = this.intentDetails.find(
        intentDetail =>
          intentDetail.intentId == selectedIntentId && !intentDetail.id
      )
    }
  }

  /**
   * インテント詳細を更新
   * @param {} intentDetail
   * @param {string} updatedType qad.Question or qad.Answer
   */
  async updateIntentDetail(intentDetail, updatedNodeType, csrfToken = null) {
    try {
      const options = {
        params: {
          intent_id: intentDetail.intentId,
          language: this.language
        }
      }
      // インテント詳細更新API呼び出し
      const savedIntentDetail = await this.intentDetailApi.save(
        intentDetail,
        csrfToken,
        options
      )

      // 連続で編集すると古いままになるためuserSayの選択をnullにする。
      this.selectedUserSay = null
      // responseMessageも同じ問題が存在するので、同じく選択をnullにする
      this.selectedResponseMessage = null
      // 更新を行ったインテントを選択状態にする
      // this.selectIntent(savedIntentDetail.intentId)

      // 更新を行ったインテント詳細を選択状態にする
      this.selectedIntentDetail = savedIntentDetail
      // intentDetailsの内容を更新
      this.intentDetails = this.intentDetails.map(_intentDetail =>
        _intentDetail.id == savedIntentDetail.id
          ? savedIntentDetail
          : _intentDetail
      )
      if (updatedNodeType == NODE_TYPE_QUESTION) {
        window.appView.updateQuestionNode(savedIntentDetail)
      } else {
        // 回答の新規追加である場合
        window.appView.updateAnswerNode(savedIntentDetail)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  /**
   * UserSayを選択
   */
  selectUserSay(userSay) {
    this.selectedUserSay = userSay
  }

  /**
   * set form node false for prevent to create new intent
   */
  cancelQuestionModal() {
    this.isAddingNewIntent = false
  }

  /**
   * UserSayElement保存
   */
  async saveUserSay(userSay, csrfToken = null) {
    // userSayのidがなければ生成して追加
    userSay.id = userSay.id || uuidv4()
    // 言語が設定されていなければ現在の言語を設定。
    userSay.language = userSay.language || this.language

    if (this.isAddingNewIntent) return this.createNewIntent(userSay, csrfToken)

    // インテントが選択されていない場合は何もしない
    if (!this.selectedIntent) return

    // インテントは既にある場合の処理
    // 質問を追加の場合のみインテントに質問を追加（更新の場合は追加しない）
    if (
      !this.selectedIntentDetail.data.userSays.find(
        _userSay => _userSay == userSay
      )
    ) {
      this.selectedIntentDetail.data.addUserSay(userSay)
    }

    // Intentはあるが質問データがない場合、ダミーのインテント詳細に質問を追加
    this.selectedIntentDetail.id
      ? // 既存のインテント詳細の更新
        await this.updateIntentDetail(
          this.selectedIntentDetail,
          NODE_TYPE_QUESTION,
          csrfToken
        )
      : // ダミーのインテント詳細に質問を追加する処理。
        await this.createIntentDetail(csrfToken, NODE_TYPE_QUESTION)
  }

  /**
   * インテント詳細の作成API呼び出し
   * @param {string} nodeType qad.Answer or qad.Question
   */
  // TODO this.selectedIntentDetailを使わないようにする
  async createIntentDetail(csrfToken, nodeType) {
    const options = {
      params: {
        intent_id: this.selectedIntentDetail.intentId,
        language: this.language
      }
    }
    this.selectedIntentDetail = await this.intentDetailApi.save(
      this.selectedIntentDetail,
      csrfToken,
      options
    )
    nodeType == NODE_TYPE_QUESTION
      ? window.appView.updateQuestionNode(this.selectedIntentDetail)
      : window.appView.updateAnswerNode(this.selectedIntentDetail)
  }

  /**
   * インテント、インテント詳細の新規作成
   */
  async createNewIntent(userSay, csrfToken) {
    // インテント詳細の追加ボタンからの質問の追加、または回答ノードのプラスボタンからの質問の追加の場合の処理。
    // インテント自体の作成とインテント詳細の保存処理を行う
    // 保存用の新規のインテント詳細を生成
    const newIntentDetail = new IntentDetail()
    newIntentDetail.data = new IntentData()
    // 新規作成の質問を新規のインテント詳細に追加
    newIntentDetail.data.addUserSay(userSay)

    // paramsにサーバーサイドで処理するための情報付加
    const sendOptions = {}
    const options = {}
    Object.assign(sendOptions, options, {
      headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' }
    })
    const params = {
      intent_category_id: this.scenarioIntentCategoryId,
      language: this.language,
      intent_detail: newIntentDetail.toData(),
      from_intent_id: (this.selectedIntent || {}).id
    }
    // intent, intentDetail, intentContextをサーバーサイドで作成
    const response = await axios.post(
      this.apiUrl.ScenarioIntent,
      params,
      sendOptions
    )

    const intent = Intent.fromData(response.data.intent)
    const intentDetail = IntentDetail.fromData(response.data.intent_detail)
    if (params.from_intent_id) {
      // fromのインテントがある場合はinputIntentContextも受け取る
      const inputIntentContext = IntentContext.fromData(
        response.data.input_intent_context
      )
      this.intentContexts.push(inputIntentContext)
    }
    const outputBaseIntentContext = IntentContext.fromData(
      response.data.output_base_intent_context
    )
    const outputTransitionIntentContext = IntentContext.fromData(
      response.data.output_transition_intent_context
    )
    this.intents.push(intent)
    this.intentDetails.push(intentDetail)
    this.intentContexts.push(outputBaseIntentContext)
    this.intentContexts.push(outputTransitionIntentContext)
    // 保存したインテントとインテント詳細を選択中にする
    this.selectedIntentDetail = intentDetail
    this.selectedUserSay = null
    this.selectedResponseMessage = null

    // 回答から質問を生やすフラグをfalseにしておく
    this.isAddingNewIntent = false

    // 作成した質問を図に反映
    window.appView.addQuestionNode(intentDetail, (this.selectedIntent || {}).id)

    this.selectedIntent = intent
  }

  /**
   * 質問入力フォームの入力値を改行で分割したものを返す
   * @param {string} userSayText
   * @returns userSayテキストの配列
   */
  getTextsByNewLine(userSayText) {
    // 改行でデータをstring -> Arrayに変換
    let textArray = userSayText.split(/\r\n|\r|\n/)
    // 空行を除去
    textArray = textArray.filter(item => item !== '')
    return textArray
  }

  /**
   * 複数の質問文をintentDetailに追加する。
   * @param {string[]} textArray
   * @param {IntentDetail} intentDetail
   * @returns {IntentDetail}
   */
  addUserSaysToIntentDetail(textArray, intentDetail) {
    return textArray.reduce((output, text) => {
      // テキストをuserSayにしてintentDetailに追加
      let userSay = new UserSay()
      userSay.data = [new Word(text)]
      userSay.id = uuidv4()
      userSay.language = this.language
      output.data.addUserSay(userSay)
      return output
    }, intentDetail)
  }

  /**
   * 質問一括登録
   */
  async saveMultipleUserSays(userSayText) {
    // 質問の配列を取得
    const textArray = this.getTextsByNewLine(userSayText)

    if (this.isAddingNewIntent) {
      // 新規でインテントを追加する場合
      const newIntentDetail = this.addUserSaysToIntentDetail(
        textArray,
        new IntentDetail()
      )

      // paramsにサーバーサイドで処理するための情報付加
      const params = {
        intent_category_id: this.scenarioIntentCategoryId,
        language: this.language,
        intent_detail: newIntentDetail.toData(),
        /** どのインテントから生やすかの判定用に選択中のインテントIDを渡す */
        from_intent_id: (this.selectedIntent || {}).id
      }
      // ヘッダーの準備
      let sendOptions = {}
      let options = {}
      Object.assign(sendOptions, options, {
        headers: {
          'X-CSRFToken': csrfToken.getCsrfTokenFromCookie(document.cookie)
        }
      })
      // APIコール
      const response = await axios.post(
        this.apiUrl.ScenarioIntent,
        params,
        sendOptions
      )
      const intent = Intent.fromData(response.data.intent)
      const intentDetail = IntentDetail.fromData(response.data.intent_detail)
      if (params.from_intent_id) {
        // fromのインテントがある場合はinputIntentContextも受け取る
        const inputIntentContext = IntentContext.fromData(
          response.data.input_intent_context
        )
        this.intentContexts.push(inputIntentContext)
      }
      const outputBaseIntentContext = IntentContext.fromData(
        response.data.output_base_intent_context
      )
      const outputTransitionIntentContext = IntentContext.fromData(
        response.data.output_transition_intent_context
      )
      this.intents.push(intent)
      this.intentDetails.push(intentDetail)
      this.intentContexts.push(outputBaseIntentContext)
      this.intentContexts.push(outputTransitionIntentContext)

      // 作成した質問を図に反映
      window.appView.addQuestionNode(
        intentDetail,
        (this.selectedIntent || {}).id
      )

      // 追加した質問のインテントを選択状態にする。
      this.selectIntent(intentDetail.intentId)
      this.selectedIntentDetail = intentDetail

      this.isAddingNewIntent = false
      this.selectedUserSay = null
      this.selectedResponseMessage = null
    } else {
      // 既存のインテントに質問を追加する場合
      this.selectedIntentDetail = this.addUserSaysToIntentDetail(
        textArray,
        this.selectedIntentDetail
      )

      await this.updateIntentDetail(
        this.selectedIntentDetail,
        NODE_TYPE_QUESTION,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    }
  }

  /**
   * UserSayを削除
   */
  async deleteUserSay(csrfToken) {
    // 削除対象の質問の順番を取得
    const userSayIndex = this.selectedIntentDetail.data.userSays.indexOf(
      this.selectedUserSay
    )
    // 対象のUserSayを削除
    this.selectedIntentDetail.data.userSays.splice(userSayIndex, 1)

    if (this.selectedIntentDetail.data.userSays.length >= 1) {
      return await this.updateIntentDetail(
        this.selectedIntentDetail,
        NODE_TYPE_QUESTION,
        csrfToken
      )
    }

    // userSaysが0になった場合、他の言語の質問がなければ該当のインテントのインプットインテントコンテキストを削除する
    const existsOtherLanguageQuestion = this.intentDetails.find(
      intentDetail =>
        intentDetail.intentId == this.selectedIntentDetail.intentId &&
        intentDetail.data.userSays.length >= 1 &&
        intentDetail.id != this.selectedIntentDetail.id
    )
    if (!existsOtherLanguageQuestion) {
      await this.deleteNode(csrfToken, NODE_TYPE_QUESTION)
    } else {
      await this.updateIntentDetail(
        this.selectedIntentDetail,
        NODE_TYPE_QUESTION,
        csrfToken
      )
    }
  }

  /**
   * ResponseMessageを選択
   */
  selectResponseMessage(responseMessage) {
    this.selectedResponseMessage = responseMessage
  }

  /**
   * ResponseMessage保存
   * ノード自体の追加、ノードはあるので回答のみの追加追加、ノードも回答もあるので回答を更新の3通りを処理
   */
  async saveResponseMessage(responseMessage, csrfToken = null) {
    // 保存しようとしている回答が選択中のインテント詳細にあり、responseMessageのidがあれば更新処理を行う
    if (
      this.selectedIntentDetail.data.responseMessages.find(
        rm => rm == responseMessage
      ) &&
      responseMessage.id
    )
      return await this.updateIntentDetail(
        this.selectedIntentDetail,
        NODE_TYPE_ANSWER,
        csrfToken
      )

    // 既存の回答がない場合、言語やインテント詳細の状態によって追加・更新の処理を行う
    // 保存する回答にidなどの情報を付加する
    responseMessage = this.addResponseMessageInfo(responseMessage)

    this.selectedIntentDetail.data.addResponseMessage(responseMessage)
    this.selectedIntentDetail.id
      ? // 既存のノードへの回答追加
        // 選択中のインテント詳細の言語が追加する回答の言語と同じ場合、回答を追加して終了
        await this.updateIntentDetail(
          this.selectedIntentDetail,
          NODE_TYPE_ANSWER,
          csrfToken
        )
      : // ノード自体は存在するが現在の言語にはインテント詳細が存在しない場合、インテント詳細の保存処理を行う
        await this.createIntentDetail(csrfToken)
  }

  /**
   * responseMessageを保存するために必要な情報をセットする
   */
  addResponseMessageInfo(responseMessage) {
    responseMessage.id = uuidv4()
    responseMessage.language = this.language
    return responseMessage
  }

  /**
   * ResponseMessageを削除
   */
  async deleteResponseMessage(csrfToken) {
    const responseMessageIndex = this.selectedIntentDetail.data.responseMessages.indexOf(
      this.selectedResponseMessage
    )
    // 対象のUserSayを削除
    this.selectedIntentDetail.data.responseMessages.splice(
      responseMessageIndex,
      1
    )
    this.selectedResponseMessage = null
    await this.updateIntentDetail(
      this.selectedIntentDetail,
      NODE_TYPE_ANSWER,
      csrfToken
    )
  }

  /**
   * 質問の部分一致検索を呼び出す
   */
  searchQuestion(searchString, intentCategory) {
    //　既存のcategoriesとDBから取得したallIntentsを利用する
    let allCategories = this.allCategoriesToArray([], this.intentCategories)
    let categoryId = null
    if (intentCategory) {
      categoryId = intentCategory.id
    }
    this.allIntentApi
      .list()
      .then(allIntents => {
        this.allIntents = allIntents
        return this.intentDetailSearchApi.list({
          params: {
            language: this.language,
            search_string: JSON.stringify(searchString),
            category_id: categoryId
          }
        })
      })
      .then(intentDetails => {
        this.questionSearchResult = intentDetails.map(intentDetail => {
          let intent = this.allIntents.filter(
            it => it.id === intentDetail.intentId
          )[0]
          let names = []
          names.push(intent.name)
          names = this.allCategoryNamesToArray(
            names,
            allCategories,
            intent.categoryId
          )
          return {
            intentDetailId: intentDetail.id,
            intentDetail: intentDetail,
            title: names.reverse().join(' >> ')
          }
        })
        // Find meta name using searchString
        let meta = null
        let flag = false
        for (let intentDetail of intentDetails) {
          if ('userSays' in intentDetail.data) {
            for (let userSay of intentDetail.data['userSays']) {
              for (let word of userSay.data) {
                if (word.text == searchString[0]) {
                  meta = word.meta
                  flag = true
                  break
                }
              }
            }
          }
          if (flag) {
            break
          }
        }
        //  find keyword_id using meta name
        let keyword_id = null
        for (let index in this.keywordFullNameById) {
          if (this.keywordFullNameById[index] === meta) {
            keyword_id = index
            break
          }
        }
        // load searchString intentDetail and keyword dependency
        if (keyword_id !== null) {
          this.loadIntentDetailKeywordBySearch(keyword_id)
        } else {
          this.intentDetailKeywordListBySearch = []
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  /**
   * カテゴリをflatにする
   */
  allCategoriesToArray(allCategories, categories) {
    categories.forEach(category => {
      allCategories.push({
        id: category.id,
        name: category.name,
        parentId: category.parentId
      })
      if (category.subcategories)
        this.allCategoriesToArray(allCategories, category.subcategories)
    })
    return allCategories
  }

  /**
   * サブカテゴリも含めた全カテゴリの名前をリストにする
   */
  allCategoryNamesToArray(names, allCategories, categoryId) {
    let category = allCategories.filter(ct => ct.id === categoryId)[0]
    names.push(category.name)
    if (category.parentId)
      names = this.allCategoryNamesToArray(
        names,
        allCategories,
        category.parentId
      )
    return names
  }

  /**
   * 渡されたintentDetailsをapiで保存する
   */
  updateSearchedUserSay(intentDetails, csrfToken = null) {
    let promises = []
    intentDetails.forEach((intentDetail, index) => {
      let promise = new Promise(resolve => {
        this.intentDetailApi
          .save(intentDetail, csrfToken, {
            params: {
              intent_id: intentDetail.intentId,
              language: this.language
            }
          })
          .then(instance => {
            Object.assign(intentDetail, instance)
            resolve()
          })
      })
      promises.push(promise)
    })

    return [true, this.i18n.t('general.saving'), promises]
  }

  /**
   * Save All ResponseMessage
   */
  async saveAllResponseMessage(responseMessages, csrfToken = null) {
    this.selectedIntentDetail.data.responseMessages = responseMessages
    await this.updateIntentDetail(
      this.selectedIntentDetail,
      NODE_TYPE_ANSWER,
      csrfToken
    )
  }

  /**
   * diagramでドラッグドロップでlinkが追加されたとき、
   * 遷移用のcontextを作り、intentContextをin用とout用に2つ作る
   */
  async onLinkConnected(sourceIntentId, targetIntentId, csrfToken = null) {
    try {
      // ヘッダーの準備
      const sendOptions = {}
      const options = {}
      Object.assign(sendOptions, options, {
        headers: { 'X-CSRFToken': csrfToken }
      })
      const params = {
        source_intent_id: sourceIntentId,
        target_intent_id: targetIntentId
      }
      const response = await axios.post(
        this.apiUrl.ScenarioLinkConnected,
        params,
        sendOptions
      )
      const intentContext = IntentContext.fromData(response.data.intent_context)
      this.intentContexts.push(intentContext)
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  /**
   * シナリオ図でリンクが削除されたときに、inputのintentContextを削除
   */
  async onLinkRemoved(sourceIntentId, targetIntentId, csrfToken = null) {
    // 遷移用のアウトプットインテントコンテキストを割り出す
    const outputIntentContextIds = this.intentContexts
      .filter(
        intentContext =>
          !intentContext.isInput &&
          intentContext.intentId == sourceIntentId &&
          intentContext.context.parentContext
      )
      .map(i => i.context.id)
    // 上記インテントコンテキストのコンテキストを持つインプットコンテキストを探す
    const inputIntentContext = this.intentContexts.find(
      intentContext =>
        intentContext.isInput &&
        outputIntentContextIds.includes(intentContext.context.id) &&
        intentContext.intentId == targetIntentId
    )

    await this.intentContextApi.destroy(inputIntentContext, csrfToken)

    // 削除されたintentContextを取り除いておく
    this.intentContexts = this.intentContexts.filter(
      ic => ic.id != inputIntentContext.id
    )
  }

  /**
   *  シナリオのintentのwebhoook有効・無効化
   */
  async updateWebhookEnabled(isEnabled, csrfToken = null) {
    try {
      // 保存失敗時用に
      this.selectedIntent.enableWebhook = isEnabled
      await this.intentApi.save(this.selectedIntent, csrfToken)
      // update the diagram
      window.appView.updateAnswerNode(this.selectedIntentDetail)
    } catch (err) {
      // 失敗したら、元の状態に戻しておく
      console.error(err)
      this.selectedIntent.enableWebhook = !isEnabled
    }
  }

  /**
   * クリップボードにインテント名をカテゴリ名含めてコピー
   */
  copyIntentPath() {
    // 選択中のintent名をカテゴリ名を含めて取得
    // カテゴリは全てScenarioIntentCategoryの名前であるので、それと結合する
    const intentName =
      this.scenarioIntentCategory.name + '_' + this.selectedIntent.name
    copyToClipboard(intentName)
    alert(this.i18n.t('scenario.copied'))
  }

  /**
   * シナリオ図のサムネイルを保存する
   */
  saveThumbnail(imageData, csrfToken = null) {
    this.scenario.thumbnail = imageData
    this.scenarioApi.update(this.scenario, csrfToken)
  }

  /**
   * インテントのフィードバックを有効・無効にする
   */
  updateIsFeedbackEnabled(csrfToken = null) {
    this.intentApi.save(this.selectedIntent, csrfToken).catch(error => {
      console.error(error)
      this.selectedIntent.isFeedbackActive = !this.selectedIntent
        .isFeedbackActive
    })
  }

  /**
   * インテントのredirectorを有効・無効にする
   */
  updateDisableRedirector(csrfToken = null) {
    this.intentApi.save(this.selectedIntent, csrfToken)
      .catch(error => {
        console.error(error)
        this.selectedIntent.disableRedirector = !this.selectedIntent
          .disableRedirector
      })
  }

  updateEvents(events, csrfToken = null) {
    this.intentApi.save(this.selectedIntent, csrfToken).catch(error => {
      console.error(error)
      this.selectedIntent.events = events
    })
  }

  /**
   * Get the name of alias available in the intents.
   */
  fetchListAliases() {
    const listAliases = []
    this.intentDetails.forEach(intentDetail => {
      intentDetail.data.userSays.forEach(userSay => {
        userSay.data.forEach(data => {
          if (data.alias) listAliases.push(data.alias)
        })
      })
    })
    return listAliases.sort()
  }

  /**
   * Update lifespan of the context in the DB
   *
   * @param context
   * @param csrfToken
   * @returns {Promise<unknown>}
   */
  setLifeSpan(context, csrfToken) {
    const sendOptions = {}
    const options = {}
    const params = {
      context: context
    }
    Object.assign(sendOptions, options, {
      headers: { 'X-CSRFToken': csrfToken }
    })
    return new Promise((resolve, reject) => {
      axios
        .post(this.apiUrl.IntentContextLifespan, params, sendOptions)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Detect locale language
   * @returns {*}
   */
  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }

  /**
   * Detect Intent for checking agent state
   * @param param
   * @param csrfToken
   * @returns {Promise<unknown>}
   */
  checkAgentState(param, csrfToken = '') {
    let sendOptions = {}
    let options = {}
    let params = {
      user_say: param.text,
      language: param.language
    }
    Object.assign(sendOptions, options, {
      headers: { 'X-CSRFToken': csrfToken }
    })
    return new Promise((resolve, reject) => {
      axios
        .post(this.TryItNowApi, params, sendOptions)
        .then(instance => {
          let result = false
          const response = JSON.parse(instance.data)
          if ('intent' in response) {
            result = true
          }
          resolve(result)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Update platform for all Intents
   *
   * @param selectedPlatform
   * @param platformFlag
   * @param csrfToken
   * @returns {(boolean|*|Promise<unknown>)[]}
   */
  async allIntentDetailPlatformUpdate(
    selectedPlatform,
    platformFlag,
    csrfToken = null
  ) {
    const sendOptions = {}
    const options = {}
    const data = JSON.stringify({
      selected_platform: selectedPlatform,
      platform_flag: platformFlag,
      language: this.language
    })
    const params = { data }
    Object.assign(sendOptions, options, {
      headers: { 'X-CSRFToken': csrfToken }
    })
    await axios.post(this.apiUrl.selectedPlatform, params, sendOptions)
    await this.loadSelectedIntentDetail()
  }

  /**
   * Get list of files through pagination
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  getFileContents(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.apiUrl.ListFileContents, {
          params: {
            page: params.page
          }
        })
        .then(function(response) {
          // handle success
          resolve(response.data)
        })
        .catch(function(error) {
          // handle error
          reject(error)
          console.error(error)
        })
    })
  }

  /**
   * count total keyword bound for a intent
   */
  async loadTotalKeywordBoundForIntent() {
    const instance = await axios.get(this.apiUrl.keywordBoundCount, {
      params: { intent_id: this.selectedIntent.id }
    })
    this.keywordBoundCount = instance.data.total_keyword_bound
  }

  /**
   *  シナリオのintentのwebhoookSlotFilling有効・無効化
   */
  updateWebhookSlotFillingEnabled(isEnabled, csrfToken = null) {
    // 保存失敗時用に
    this.selectedIntent.enableWebhookForSlotFilling = isEnabled
    this.intentApi
      .save(this.selectedIntent, csrfToken)
      .then(() => {
        // update the diagram
        window.appView.updateAnswerNode(this.selectedIntentDetail)
      })
      .catch(error => {
        // 失敗したら、元の状態に戻しておく
        console.error(error)
        this.selectedIntent.enableWebhookForSlotFilling = !isEnabled
      })
  }

  /**
   * InputCompletionサーバーへのデータ転送処理
   * @param {string} csrfToken
   * @return
   */
  async transferInputCompletion(csrfToken = null) {
    try {
      const options = {
        headers: { 'X-CSRFToken': csrfToken }
      }
      const response = await axios.post(this.userSaysSchronizeApi, {}, options)
      return response
    } catch (error) {
      throw error
    }
  }

  /**
   * インテントやインテント詳細などの選択をリセットする
   */
  resetSelections() {
    this.selectedIntent = null
    this.selectedIntentDetail = null
    this.selectedUserSay = null
    this.selectedIntentDetail = null
    this.isAddingNewIntent = false
  }

  /**
   * シナリオのappViewのexportImage関数を呼び出す
   */
  exportImage() {
    window.appView.exportImage()
  }

  /**
   * Save All UserSays
   */
  saveAllUserSays(userSays, csrfToken = null) {
    if (!this.selectedIntentDetail) {
      return false
    }
    this.selectedIntentDetail.data.userSays = userSays
    return this.updateIntentDetail(
      this.selectedIntentDetail,
      NODE_TYPE_QUESTION,
      csrfToken
    )
  }
}
