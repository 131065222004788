import { I18n } from "../util/i18n"

const i18n = new I18n(window.i18nContext)

export const LANGUAGE_LABELS = {
  ja: i18n.t("languageSelector.japanese"),
  en: i18n.t("languageSelector.english"),
  "zh-cn": i18n.t("languageSelector.chineseSimplified"),
  "zh-tw": i18n.t("languageSelector.chineseTraditional"),
  ko: i18n.t("languageSelector.korean"),
  th: i18n.t("languageSelector.thai"),
  ru: i18n.t("languageSelector.russian"),
  vi: i18n.t("languageSelector.vietnamese"),
  id: i18n.t("languageSelector.indonesian"),
  uk: i18n.t("languageSelector.ukrainian"),
  ne: i18n.t("languageSelector.nepali"),
  bn: i18n.t("languageSelector.bengali"),
  fr: i18n.t("languageSelector.french"),
  es: i18n.t("languageSelector.spanish"),
  mn: i18n.t("languageSelector.mongolian"),
  my: i18n.t("languageSelector.burmese"),
  pt: i18n.t("languageSelector.portuguese"),
  "pt-br": i18n.t("languageSelector.portugueseBrazil"),
  si: i18n.t("languageSelector.sinhala"),
}
