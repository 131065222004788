<template>
  <div class="search-filter-area">
    <div>
      <history-calendar
        class="calendar mt-1"
        :selectedDate="selectedDate"
        :allDate="allDate"
        :insertDate="modified_insertDate"
        @month-selected="onMonthSelected"
        @date-selected="onDateSelected">
      </history-calendar>
    </div>
    <conversation-history-search-filter-platform
      :selectedPlatforms="selectedPlatforms"
      :icons="platforms_icon"
      @toggle-platform-selection="togglePlatformSelection"
      @toggle-all-platform-selection="$emit('toggle-all-platform-selection')">
    </conversation-history-search-filter-platform>
    <conversation-history-language-type-filter
      :supported_languages="supported_languages"
      @language-type-selection="languageTypeSelection">
    </conversation-history-language-type-filter>
    <conversation-history-message-type-filter
      :selectedSortElement="selectedSortElement"
      @on-sort-element-selected="onSortElementSelected"
      @on-sort-order-selected="onSortOrderSelected"
      @on-welcome-filter-changed="onWelcomeFilterChanged">
    </conversation-history-message-type-filter>
    <conversation-history-search-filter-session
      :sessionIdSearchResult="sessionIdSearchResult"
      :sessionIdIsLoad="sessionIdIsLoad"
      @reset-session-id-search-result="$emit('reset-session-id-search-result')"
      @filter-search-session-id="filterSearchSessionId"
    >
    </conversation-history-search-filter-session>
  </div>

</template>

<script>
export default {
  props: [
    'selectedDate',
    'selectedPlatforms',
    'selectedSortElement',
    'allDate',
    'insertDate',
    'platforms_icon',
    'supported_languages',
    'sessionIdSearchResult',
    'sessionIdIsLoad',
  ],
  data() {
    return {
      modified_insertDate:this.existInsertDate()
    }
  },
  methods: {
    existInsertDate(){
      if(this.insertDate !== 'None'){ // 会話履歴でsession idリンクを押した場合
        this.modified_insertDate =  this.modifyDateType(this.insertDate)
        return this.modified_insertDate
      }else{ // 会話履歴でsession idリンクを押さずにモード切り替えした場合
        this.modified_insertDate =  this.modifyDateType(this.selectedDate)
        return this.modified_insertDate
      }
    },
    modifyDateType(date){
      let d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      let year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      let temp_insertDate = {year: year, month: parseInt(month), day: parseInt(day)}
      return temp_insertDate
    },
    onMonthSelected(year, month){
      this.$emit('month-selected', year, month)
    },
    onDateSelected(year, month, day) {
      this.$emit('date-selected', year, month, day)
    },
    togglePlatformSelection(platformName) {
      this.$emit('toggle-platform-selection', platformName)
    },
    onSortElementSelected(value) {
      this.$emit('on-sort-element-selected', value)
    },
    onSortOrderSelected(value) {
      this.$emit('on-sort-order-selected', value)
    },
    onWelcomeFilterChanged(value) {
      this.$emit('on-welcome-filter-changed', value)
    },
    languageTypeSelection(language){
      this.$emit('language-type-selection', language)
    },
    filterSearchSessionId(value){
      this.$emit('filter-search-session-id', value)
    }
  }
}
</script>
