<template>
  <div>
    <!-- Generate New Auth_key-->
    <div class="row m-1" v-if="!isViewer">
      <button
        class="btn btn-success"
        @click="GenerateKey"
        v-bind:disabled="controller.apiData.length>=1">
        <i class="fas fa-plus-circle"></i>
        {{ $t('apiService.generateKey') }}
      </button>
    </div>
      <!-- Column -->
    <div class="row ml-1 mr-1">
      <div class="col-md-4 column border p-2">
        {{ $t('apiService.authenticationKey') }}
      </div>
      <div class="col-md-1 column border p-2" v-if="!isViewer">
        &nbsp;
      </div>
    </div>

    <div v-for="(data, index) in controller.apiData" :key="index">
      <div class="row ml-1 mr-1">
        <div class="col-md-4 border p-2">
          {{data.authentication_key}}
        </div>
        <div class="col-md-1 border p-2" v-if="!isViewer">
          <button v-b-tooltip.hover
            :title="$t('buttons.copyTo')"
            class="btn btn-info mr-1"
            @click="copyAuthenticationKey(data.authentication_key)">
            <i class="fas fa-copy"></i>
          </button>
          <button v-b-tooltip.hover
            :title="$t('buttons.delete')"
            class="btn btn-danger"
            @click="deleteKeyConfirm(data)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <b-modal
      ref="deleteKeyConfirmModal"
      :title="$t('apiService.deleteConfirmTitle')"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop
      @keydown.native.enter="deleteKeyOk">
      <div v-if="modalKey">
        {{ $t('apiService.deleteConfirmMessage', {authenticationKey: modalKey.authentication_key}) }}
      </div>
      <modal-footer
        @ok="deleteKeyOk"
        @cancel="$refs.deleteKeyConfirmModal.hide()"
        slot='modal-footer'>
      </modal-footer>
    </b-modal>

    <error-modal
      ref="errorModal"
      :message="message">
    </error-modal>

    <progress-modal
      ref="progressModal"
      :message="message">
    </progress-modal>
  </div>
</template>

<script>
  import copyToClipboard from '../util/copy-url'
  import csrfToken from '../util/csrf-token'
  import {ApiService} from "../model/api-service"
  import {I18n} from '../util/i18n'

  export default {
    props: ['controller'],
    data() {
      return {
        message: '',
        errorMsg:'',
        modalTitle: '',
        modalKey:new ApiService(),
        i18n: new I18n(window.i18nContext),
      }
    },
    computed:{
      isViewer() {
        return this.controller.isViewer()
      }
    },
    methods:{
      showProgress(message) {
        this.message = message
        this.$refs.progressModal.show()
      },
      hideProgress() {
        this.$refs.progressModal.hide()
      },
      showError(message) {
        this.message = message
        this.$refs.errorModal.show()
      },
      GenerateKey(){
        this.modalKey = this.controller.generateNewKey()
        let result, message, promise
        [result, message, promise] = this.controller.saveApiKey(
          this.modalKey,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result) {
          this.showError(message)
        } else {
          this.showProgress(message)
          promise.then(this.hideProgress)
          promise.catch((error) => {
            this.hideProgress()
            this.showError(error.response.data)
          })
        }
      },
      deleteKeyConfirm(data){
        this.modalKey = data
        this.$refs.deleteKeyConfirmModal.show()
      },
      deleteKeyOk(){
        let result, message, promise
        [result, message, promise] = this.controller.deleteAuthKey(
          this.modalKey,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result) {
          this.showError(message)
        } else {
          this.showProgress(message)
          promise.then(this.hideProgress)
          promise.catch((error) => {
            this.hideProgress()
            this.showError(error.response.data)
          })
        }
        this.$refs.deleteKeyConfirmModal.hide()
      },
      copyAuthenticationKey(authenticationKey) {
        copyToClipboard(authenticationKey)
        alert(this.i18n.t('apiService.authenticationKeyCopyMessage'))
      }
    }
  }
</script>
