<template>
  <b-modal
    :title="$t('question.compareModalTitle')"
    ref="modal"
    @keypress.native.enter="$refs.modal.hide()"
    @ok="$emit('cancel')"
    @hide="onHide"
    :no-close-on-backdrop="true"
    :ok-only="true"
    size="lg">
    <div class="compare-intent-modal">
      <multilanguage-user-say
        ref="multilanguageUserSay"
        class="multilanguage-user-say"
        :intentDetail="intentDetail"
        :supportedLanguages="supportedLanguages"
        :currentLanguage="currentLanguage"
        :selectedLanguageIntentDetail="selectedLanguageIntentDetail"
        @copy-user-says="copyUserSays"
        @changed-compared-language="onChangedComparedLanguage">
      </multilanguage-user-say>
      <div class="current-language-area">
        <div class="intent-detail-header">
          {{ $t('question.currentQuestionsTitle')}}
        </div>
        <ul class="scroll">
          <li
            v-for="(userSay, index) in userSaysFilteredLanguage">
            <div class="label my-1 p-1">
              <span
                v-for="(word, index) in userSay.data"
                :class="{'keywordSelect': word.entitySelected}"
                :style="{background:word.color}">
                <span>{{ word.text }}</span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: [
    'intentDetail',
    'userSaysFilteredLanguage',
    'supportedLanguages',
    'currentLanguage',
    'selectedLanguageIntentDetail',
  ],
  data() {
    return {
    }
  },
  methods: {
    onClose () {
      this.popoverShow = false;
    },
    onHide() {
      // モーダルを閉じる時は必ず選択済みの比較言語をnullにする
      this.$refs.multilanguageUserSay.selectLanguage(null)
    },
    show() {
      this.$refs.modal.show()
    },
    onChangedComparedLanguage(language_code) {
      // controllerで選択した言語の質問を読み込む
      this.$emit("changed-compared-language", language_code)
    },
    copyUserSays(intentDetail) {
      // モーダルを閉じないとdocumentにフォーカスをやるのが面倒なのでここで閉じる
      this.$refs.modal.hide()
      this.$emit('copy-user-says', intentDetail)
    },
  },
}
</script>
