import { I18n } from "../util/i18n";
import { RPC } from "../util/rpc";
import csrfToken from "../util/csrf-token";

/**
 * FAQデータセット管理画面のコントローラ
 */
export class FAQDatasetManagementController {
  constructor(apiUrl, i18nContext, datasets) {
    this.i18n = new I18n(i18nContext);

    // API呼び出しクラスのインスタンス化
    this.updateFAQDatasetApi = new RPC(apiUrl.updateFAQDataset);

    this.datasets = datasets;

    this.selectedDataset = null;
  }

  ready() {}

  /**
   * データセット更新API呼び出し
   */
  async updateDataset({
    /** 有効無効 */
    active,
    /** 優先度 */
    priority,
  }) {
    // データセットの更新API呼び出し
    const dataset = await this.updateFAQDatasetApi.call(
      csrfToken.getCsrfTokenFromCookie(document.cookie),
      {
        ...this.selectedDataset,
        active,
        priority,
      }
    );

    // データセット一覧の更新
    this.datasets = this.datasets.map((d) => {
      if (d.id === dataset.id) {
        return dataset;
      }
      return d;
    });
  }

  /**
   * データセット選択処理
   */
  onSelectDataset(dataset) {
    this.selectedDataset = dataset;
  }
}
