<template>
  <b-modal
    ref="modal"
    :title="$t('keyword.intentListInUse')"
    header-class="bg-info text-light"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer>
    <div class="list-group" v-if="selectedIntentCategories">
      <a class="list-group-item list-group-item-action" target="_blank"
         v-for="(intent, index) in selectedIntentCategories"
         v-bind:href="showSelectedIntent(intent)"
         v-if="intent" :key="index">
        <div class="d-flex w-100 justify-content-between">
          <h6 class="mb-1 mr-1">{{getIntentName(intent)}}</h6>
          <div class="text-muted">
            <span class="mr-1" v-b-popover.hover.top="getRedirectorTitle(intent)"
                  :class="getRedirectorColor(intent)">
              <i class="fas fa-directions"></i>
            </span>
            <span class="ml-1" v-b-popover.hover.top="'go to intent page'">
              <i class="fas fa-external-link-alt"></i>
            </span>
          </div>
        </div>
        <small class="text-muted">{{getIntentCategory(intent)}}</small>
      </a>
    </div>
    <div class="d-flex align-items-center" v-else>
      <strong class="text-muted">Loading...</strong>
      <div class="spinner-grow text-info ml-auto" role="status" aria-hidden="true"></div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['language', 'selectedIntentCategories'],
  methods: {
    show() {
      this.$refs.modal.show()
    },
    showSelectedIntent(intent) {
      if(this.selectedIntentCategories !== null){
        let intentName = intent.name
        const currentUrl = location.href
        const regex = RegExp('agent.+')
        const destination = `agent/${this.language}/intent?intent_name=${intentName}`
        return currentUrl.match(regex) ? currentUrl.replace(regex, destination) : destination
      }
    },
    getIntentCategory(intent) {
      const names = intent.name.split('_')
      names.pop() // avoid the last name for parent category.
      return names.join(' >> ')
    },
    getIntentName(intent) {
      return intent.name.split('_').pop()
    },
    getRedirectorTitle(intent) {
      return intent.disableRedirector ? this.$t('redirector.disabledRedirector') : this.$t('redirector.enabledRedirector')
    },
    getRedirectorColor(intent) {
      return intent.disableRedirector ? "text-danger" : "text-success"
    }
  }
}
</script>
