import {I18n} from '../util/i18n'
import {RestApi} from '../util/rest-api';
import {Role} from '../model/role';

class DashboardRoleManageController {
  constructor(url, i18nContext, requestContext) {
    this.i18n = new I18n(i18nContext)
    this.userType = requestContext.userType
    this.listAgents = requestContext.listAgents
    this.tenantId = requestContext.tenant_id
    this.apiUrl = url.DashboardRole
    this.roleApi = new RestApi(url.DashboardRole, Role)
    this.listRoles = []
    this.agents_dict = {}
  }

  ready() {
    for(const agent of this.listAgents){
      this.agents_dict[agent.value] = agent.text
    }
    this.getRoleByTenant()
  }
  getRoleByTenant(){
    this.roleApi.list({
        params: {
          tenant: this.tenantId
        }
      })
      .then(instance => {
        this.listRoles = instance
      })
  }
  createRole(context, csrfToken) {
    let promise = this.roleApi.save(context, csrfToken,
      {
        params: {
          tenant: this.tenantId
        }
      }
    )
    return [true, this.i18n.t("general.saving"), promise]
  }
  destroyRole(context, csrfToken){
    let promise = this.roleApi.destroy(context, csrfToken,
      {
        params: {
          tenant: this.tenantId
        }
      }
    ).then(() => {
        this.getRoleByTenant()
      })
    return [true, this.i18n.t("general.saving"), promise]
  }
}

export {
  DashboardRoleManageController,
}
