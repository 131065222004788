<template>
  <div class="answer-card">
        <div>
          {{ $t('answer.content') }}({{ $t('answer.typeCard') }}):
        </div>
        <div @keypress.enter.prevent="">
          <div class="input-group mb-1">
            <b-form-input
              :placeholder="$t('answer.enterImageURL')"
              v-model="responseMessageCardUrl"
              @input="inputValue"
              type="url"
              maxlength="1000"
              :state="!$v.responseMessageCardUrl.$error"
              ref="responseMessageCardUrl">
            </b-form-input>
            <div class="input-group-append">
              <a
                class="btn btn-info"
                :class="{ disabled: !responseMessageCardUrl || $v.responseMessageCardUrl.$error }"
                :href="responseMessageCardUrl" target="_blank"
                :title="$t('answer.preView')"
              ><span class="fas fa-eye mt-1"/>
              </a>
              <a class="btn btn-primary text-white" v-b-tooltip.hover
                 :title="$t('answer.browseImage')"
                 @click="loadCardUrlFiles">
                <i class="far fa-images"></i>
              </a>
            </div>
            <b-form-invalid-feedback v-if="$v.responseMessageCardUrl.$error">
              {{ $t('answer.invalidURL') }}
            </b-form-invalid-feedback>
          </div>
          <file-contents ref="cardUrlFiles"
                         :img-url-el="$refs.responseMessageCardUrl"
                         @img-url-model="responseMessageCardUrl = $event"></file-contents>
          <b-form-group id="card-title" :label="$t('answer.enterCardTitle')" label-for="card-title">
            <b-form-input v-model="responseMessageCardTitle"
                          class="mb-1"
                          type="text"
                          :placeholder="$t('answer.enterCardTitle')"
                          @input="inputValue"
                          :state="!$v.responseMessageCardTitle.$error">
            </b-form-input>
            <b-form-invalid-feedback v-if="$v.responseMessageCardTitle.$error">
              {{ $t('answer.maxTxtLen', {counter: $v.responseMessageCardTitle.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="card-subtitle" :label="$t('answer.enterCardSubtitle')" label-for="card-subtitle">
            <b-form-input
              :placeholder="$t('answer.enterCardSubtitle')"
              v-model="responseMessageCardSubTitle"
              @input="inputValue"
              class="mb-1"
              type="text"
              :state="!$v.responseMessageCardSubTitle.$error">
            </b-form-input>
            <b-form-invalid-feedback v-if="$v.responseMessageCardSubTitle.$error">
              {{ $t('answer.maxTxtLen', {counter: $v.responseMessageCardSubTitle.$params.maxLength.max}) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div>{{ $t('answer.cardButtons') }}</div>
        <draggable v-model="responseMessageCardButtonText" handle=".handle" @input="inputValue">
          <div v-for="(reply, index) in responseMessageCardButtonText">
            <div class="input-group mb-1"
                 @keypress.enter.prevent="addCardButton">
              <b-form-input
                v-model="reply.text"
                @input="validationCheck()"
                maxlength="20"
                :state="!$v.responseMessageCardButtonText.$each[index].text.$error"
                :placeholder="$t('answer.cardButtonTitle')"/>
              <b-form-input
                v-model="reply.postback"
                maxlength="1000"
                :placeholder="$t('answer.cardButtonPostback')"/>
                <div class="input-group-append answer-card">
                  <button
                    class="btn btn-sm btn-danger"
                    :disabled="responseMessageCardButtonText.length == 1"
                    @click.prevent="deleteReplyCardButtons(index)">
                    <i class="fa fa-minus"></i>
                  </button>
                  <button class="btn btn-sm btn-success handle">
                    <i class="fas fa-arrows-alt"></i>
                  </button>
              </div>
              <b-form-invalid-feedback v-if="$v.responseMessageCardButtonText.$each[index].text.$error">
                {{ $t('webhook.required') }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </draggable>
        <div class="mb-2">
          <button
            class="btn btn-success"
            @click.prevent="addCardButton"
            :disabled="responseMessageCardButtonText.length >= 3"
            >
            <i class="fa fa-plus"></i>
          </button>
        </div>
    </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import {required, maxLength, url} from 'vuelidate/lib/validators'
import {CardData,CardButtonText, TargetService} from '../../model/intent'
import draggable from 'vuedraggable'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    draggable, FileContents
  },
  props:['modalSize', 'selectedTargetServices'],
  data() {
    return {
      answerCard:null,
      responseMessageCardUrl:'',
      responseMessageCardTitle:'',
      responseMessageCardSubTitle:'',
      responseMessageCardButtonText:[new CardButtonText()]
    }
  },
  validations: {
    responseMessageCardUrl: {
      url
    },
    responseMessageCardTitle: {
      required,
      maxLength: maxLength(40)
    },
    responseMessageCardSubTitle: {
      required,
      maxLength: maxLength(60)
    },
    responseMessageCardButtonText: {
      $each: {
        text: {
          required
        }
      }
    }
  },
  methods:{
    addCardButton(){
      if (this.responseMessageCardButtonText.length < 3) {
        this.responseMessageCardButtonText.push(new CardButtonText())
      }
      this.validationCheck()
    },
    deleteReplyCardButtons(index){
      this.responseMessageCardButtonText.splice(index, 1)
      this.validationCheck()
    },
    inputValue(){
      this.answerCard = new CardData(
            this.responseMessageCardUrl,
            this.responseMessageCardTitle,
            this.responseMessageCardSubTitle,
            this.responseMessageCardButtonText)
      this.validationCheck()
      this.answerCard.title = this.answerCard.title.replace(illegalExp, "").trim()
      this.answerCard.subtitle = this.answerCard.subtitle.replace(illegalExp, "").trim()
      this.answerCard.url = this.answerCard.url.replace(illegalExp, "").trim()
      for(let button of this.answerCard.cardbuttons){
        button.text = button.text.replace(illegalExp, "").trim()
        button.postback = button.postback.replace(illegalExp, "").trim()
      }
      return this.answerCard
    },
    setValue(selectedResponseMessage){
      this.responseMessageCardUrl = selectedResponseMessage.data.url
      this.responseMessageCardTitle = selectedResponseMessage.data.title
      this.responseMessageCardSubTitle = selectedResponseMessage.data.subtitle
      this.responseMessageCardButtonText = selectedResponseMessage.data.cardbuttons
    },
    initializeValue(){
      this.answerCard=null,
      this.responseMessageCardUrl='',
      this.responseMessageCardTitle='',
      this.responseMessageCardSubTitle='',
      this.responseMessageCardButtonText=[new CardButtonText()]
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    loadCardUrlFiles() {
      this.$refs.cardUrlFiles.loadImages()
    }
  }
}
</script>
