<template>
  <div class="m-1 p-1">
    <h6 class="border-bottom pb-1">
      <span class="analytics-card-button-group form-submit" v-if="isTotalTab">
        <button
          class="btn btn-info mt-1 btn-block"
          v-b-modal.export-modal
          @click.prevent=""
        >
          {{ $t('intent.export') }}
        </button>
        <b-modal
          id="export-modal"
          ref="exportModal"
          :title="$t('intent.export')"
          static
        >
          <b-form-select
            name="export_type"
            :options="exportTypeOptions"
            value="1"
          ></b-form-select>
          <input type="hidden" name="month" :value="selected_month" />
          <div slot="modal-footer" class="w-100">
            <div class="float-right">
              <!-- formがvueコンポネントの更に上にあるのでそちらをsubmitしてダウンロードを行う -->
              <button
                type="submit"
                name="btn_export"
                class="btn btn-primary mt-1"
                :title="$t('analytics.csvExport')"
              >
                {{ $t('analytics.csvExport') }}
              </button>
              <button
                type="submit"
                name="btn_excel_export"
                class="btn btn-info mt-1"
                :title="$t('analytics.excelExport')"
              >
                {{ $t('analytics.excelExport') }}
              </button>
              <b-button @click="$refs.exportModal.hide()"> Close </b-button>
            </div>
          </div>
        </b-modal>
      </span>
      <span class="analytics-card-button-group" v-if="!isSP">
        <span
          class="btn pointer-cursor mt-1 mr-1"
          :class="tabClass(0)"
          @click="tabActivate(0)"
          >{{ $t('analytics.aggregation') }}</span
        >
        <span
          class="btn pointer-cursor mt-1"
          :class="tabClass(1)"
          @click="tabActivate(1)"
          >{{ $t('analytics.usersDetail') }}</span
        >
      </span>
    </h6>
    <div>
      <div class="analytics-table">
        <div class="sticky-top">
          <div class="row ml-1 mr-1">
            <template v-for="(header, index) in columns">
              <div
                class="col column border p-2"
                :key="index"
                v-if="!isSP && header.platform"
                v-b-tooltip="header.label"
              >
                <span v-if="header.fa_icon">
                  <i :class="header.fa_icon"></i>
                </span>
                <span>
                  <!-- TODO: カスタマイズアイコン表示 -->
                </span>
              </div>
              <div class="col column border p-2" :key="index" v-else>
                {{ header.label }}
                <a
                  v-if="header.key === 'session_total'"
                  href="https://docs.obotai.com/analytics-user.html"
                  target="_blank"
                  v-b-popover.hover.top="$t('analytics.aboutNumberOfUsers')"
                >
                  <i class="fas fa-info-circle"></i>
                </a>
              </div>
            </template>
          </div>
          <analytics-detail-row
            :redirectUri="redirectUri"
            :record="details.total[langFilter]"
            :isTotal="true"
            :columns="columns"
            :isSP="isSP"
            :platforms="all_platforms"
          >
          </analytics-detail-row>
        </div>
        <analytics-detail-row
          v-for="(record, day) in details.monthly"
          :key="day"
          :redirectUri="redirectUri"
          :record="record[langFilter]"
          :isTotal="false"
          :columns="columns"
          :date="day"
          :isSP="isSP"
          :platforms="all_platforms"
        >
        </analytics-detail-row>
      </div>
    </div>
  </div>
</template>

<script>
import getParamFromUrl from '../util/get-param-from-url'

export default {
  props: [
    'redirectUri',
    'details',
    'selectedLanguage',
    'isSP',
    'isTotalTab',
    'selected_month',
    'all_platforms',
  ],
  data() {
    return {
      activeTab: 0,
      allColumns: {
        date: [{ key: 'date', label: this.$t('analytics.date') }],
        common: [
          { key: 'session_total', label: this.$t('analytics.user') },
          { key: 'query_total', label: this.$t('analytics.query') },
          { key: 'answered_rate', label: this.$t('analytics.answered') },
          { key: 'unanswered_rate', label: this.$t('analytics.unanswered') },
        ],
        actions: [{ key: 'actions', label: '' }],
      },
      exportTypeOptions: [
        { value: '1', text: this.$t('analytics.dailyDetail') },
        { value: '2', text: this.$t('analytics.aggregationReport') },
      ],
    }
  },
  mounted() {
    this.$nextTick((_) => {
      const detail_tab = getParamFromUrl('detail_tab')
      if (!!detail_tab) {
        this.activeTab = 1
      }
    })
  },
  computed: {
    langFilter() {
      return this.selectedLanguage
    },
    tabClass() {
      return (index) => {
        return {
          'btn-primary': this.activeTab === index,
          'btn-outline-primary': this.activeTab !== index,
        }
      }
    },
    columns() {
      let results = this.allColumns.date
      if (this.isSP) {
        results = results.concat(this.allColumns.common)
        results = results.concat(this.allColumns.actions)
      } else {
        if (this.activeTab === 0) {
          results = results.concat(this.allColumns.common)
        } else {
          results = results.concat(this.all_platforms)
        }
      }
      return results
    },
  },
  methods: {
    tabActivate(index) {
      this.activeTab = index
      this.$emit('detail_tab_select', index)
    },
  },
}
</script>
<style scoped>
</style>
