<template>
  <div v-if="!isViewer">
    <!-- Show Validation Detail -->
    <div class="row">
      <div class="col-md-2 pt-3">
        <b-form-input v-model="category" @input="currentPage = 1" type="text" :placeholder="$t('validation.categoryPlaceholder')" />
      </div>
      <div class="col-md-2 pt-3">
        <b-form-input v-model="intent" @input="currentPage = 1" type="text" :placeholder="$t('validation.intentPlaceholder')" />
      </div>
      <div class="col-md-2 pt-3">
        <b-form-select v-model="selected" :options="options" @change="currentPage = 1" />
      </div>
      <div class="col-md-3 pt-3">
        <button @click="csvDownload" class="btn btn-primary">
          <i class="fas fa-download"></i>
          {{ $t('validation.csvExport') }}
        </button>
        <button @click="excelDownload" class="btn btn-info">
          <i class="fas fa-download"></i>
          {{ $t('validation.excelExport') }}
        </button>
      </div>
    </div>
      <!-- Column -->
    <div class="row ml-1 mr-1 mt-3">
      <div class="col-md-12">
        <div class="d-flex justify-content-between mb-1">
          <h5>{{ $t('validation.validationResult') }}</h5>
          <div class="btn-group" v-if="controller.intentCheckResultSummary">
            <b-button variant="light">{{controller.intentCheckResultSummary.success}}
              <b-badge variant="success"><i class="fas fa-check"></i></b-badge></b-button>
            <b-button variant="light">{{controller.intentCheckResultSummary.failure}}
              <b-badge variant="danger"><i class="fas fa-times"></i></b-badge></b-button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-detail">
            <thead class="table-info">
              <tr>
                <th class="cell-detail-lg">#</th>
                <th class="cell-detail-lg">{{ $t('validation.category') }}</th>
                <th class="cell-detail-lg">{{ $t('validation.intent') }}</th>
                <th class="cell-detail-lg">{{ $t('validation.question') }}</th>
                <th class="cell-detail-lg">{{ $t('validation.judgedIntent') }}</th>
                <th class="cell-detail-md">{{ $t('validation.result') }}</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in filteredList" :key="index">
                  <td >{{(currentPage*perPage - perPage) + (index+1)}}</td>
                  <td >{{item.category}}</td>
                  <td >{{item.intent}}</td>
                  <td >{{item.user_say}}</td>
                  <td >{{item.response_intent}}</td>
                  <td v-if="item.result === 'True'" v-b-tooltip.hover :title="getResultTooltip(item.result)">
                    <font-awesome-icon icon="check-circle" class="text-success" />
                  </td>
                  <td v-else-if="item.result === 'False'" v-b-tooltip.hover :title="getResultTooltip(item.result)">
                    <font-awesome-icon icon="times-circle" class="text-danger" />
                  </td>
                  <td v-else-if="item.result === 'Suspend'" v-b-tooltip.hover :title="getResultTooltip(item.result)">
                    <font-awesome-icon icon="stop-circle" class="text-danger" />
                  </td>
                  <td v-else>{{ item.result }}</td>
                </tr>
            </tbody>
          </table>
        </div>
        <b-container fluid class="bv-example-row">
          <b-row>
            <b-col md="4" offset-md="5">
              <b-pagination
                v-model="currentPage"
                :total-rows="controller.total"
                :per-page="perPage" />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: ['controller'],
    data() {
      return {
        perPage: 50,
        currentPage: 1,
        category:'',
        intent:'',
        selected: '',
        options: [
          { value: '', text: this.controller.i18n.t('validation.selectResult') },
          { value: 'In progress...', text: this.controller.i18n.t('validation.waiting') },
          { value: 'True', text: this.controller.i18n.t('validation.successful') },
          { value: 'False', text: this.controller.i18n.t('validation.failed') },
          { value: 'Suspend', text: this.controller.i18n.t('validation.suspend') }
        ]
      }
    },
    computed:{
      filteredList(){
        let filteredResult = this.controller.validationResult
          .filter(result => {
            const category = result.category ? result.category.toLowerCase() : ""
            return category.includes(this.category.toLowerCase())
          })
          .filter(result => {
            const intent = result.intent ? result.intent.toLowerCase() : ""
            return intent.includes(this.intent.toLowerCase())
          })
          .filter(data => {
            const result = data.result ? data.result.toLowerCase() : ""
            return result.includes(this.selected.toLowerCase())
          })
        this.controller.total = filteredResult.length
        if(this.controller.total > this.perPage) {
          filteredResult = filteredResult.splice((this.currentPage*this.perPage - this.perPage),this.perPage)
        }

        return filteredResult
      },
      isViewer() {
        return this.controller.isViewer()
      }
    },
    methods:{
      excelDownload(){
        window.location.href = this.controller.makeDownloadUrl('excel')
      },
      csvDownload(){
        window.location.href = this.controller.makeDownloadUrl('csv')
      },
      getResultTooltip(param) {
        let output = ''
        switch (param) {
          case "True":
            output = 'Succeeded'
            break
          case "False":
            output = 'Failed'
            break
          case "Suspend":
            output = 'Suspended'
            break
        }

        return output
      }
    },
    mounted: function () {
      this.controller.loadIntentCheckResultSummary()
      this.$nextTick(()=>{
        this.controller.autoReload()
      })
    }
  }
</script>
