import Vue from 'vue'
import { UrlBuilder } from './util/url-builder'
import { API_URL } from './resource/urls'
import SystemNotificationController from './controller/system-notification-controller'

import SystemNotification from './components/SystemNotification.vue'


export const appHome = (i18n) => {
  /* コントローラ */
  const controller = new SystemNotificationController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.notifications,
    window.i18nContext,
  )

  Vue.component('system-notification', SystemNotification)

  new Vue({
    el: '#app-home',
    i18n: i18n,
    data: {
      controller: controller
    }
  })
}
