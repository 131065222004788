/*
 * 統計分析画面
 */
import Vue from 'vue'
import { AnalyticsController } from './controller/analytics'

import AnalyticsLanguage from './components/AnalyticsLanguage.vue'
import AnalyticsDetails from './components/AnalyticsDetails.vue'
import AnalyticsViewer from './components/AnalyticsViewer.vue'
import ChartsViewer from './components/Charts.vue'
import AnalyticsFeedback from './components/AnalyticsFeedback.vue'
import AnalyticsDetailRow from './components/AnalyticsDetailRow.vue'


export const appAnalytics = (i18n) => {
  /* コントローラ */
  const controller = new AnalyticsController(
    window.requestContext.language,
    window.requestContext.supportedLanguages,
    window.i18nContext,
    window.analyticsContext,
  )

  /* コンポーネント登録 */
  Vue.component('analytics-language', AnalyticsLanguage)
  Vue.component('analytics-details', AnalyticsDetails)
  Vue.component('analytics-viewer', AnalyticsViewer)
  Vue.component('charts-viewer', ChartsViewer)
  Vue.component('analytics-feedback', AnalyticsFeedback)
  Vue.component('analytics-detail-row', AnalyticsDetailRow)

  new Vue({
    el: '#app-analytics',
    i18n: i18n,
    data: {
      controller: controller
    }
  })
}
