<template>
  <div>
    <a href="/dashboard/tenant-agent-manage" class="btn btn-info mr-2">
      <i class="fas fa-arrow-left"></i>
      Back
    </a>
    <button title="Add New" class="btn btn-success"
            @click="openAddRoleModal">
      <i class="fas fa-user-tag"></i> Add New Role
    </button>
    <!--Add new role modal-->
    <b-modal
      :title="modalTitle"
      ref="rolemodal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop>
      <div>
        <form @submit.prevent="formRoleSubmit">
          <div>
            Role Name:
          </div>
          <b-form-input
            type="text"
            ref="focusInput"
            v-model="formRole.name"
            :state="!$v.formRole.name.$error">
          </b-form-input>
          <div class="mt-2">Rules: </div>
          <draggable v-model="formRole.data" handle=".handle">
            <div v-for="(row, index) in formRole.data">
              <b-input-group>
                <b-form-select
                  v-model="row.agent_id"
                  :options="agentOptions"
                  class="mb-1 mr-1"
                  :state="!$v.formRole.data.$each[index].agent_id.$error">
                </b-form-select>
                <b-form-select
                  v-model="row.permission"
                  :options="permissions"
                  class="mb-1 mr-1" style="width: 120px"
                  :state="!$v.formRole.data.$each[index].permission.$error">
                </b-form-select>
                <div class="input-group-append">
                  <button
                    class="btn btn-danger"
                    style="height: 38px"
                    :disabled="formRole.data.length === 1"
                    @click.prevent="removeRoleData(index)">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
                <button class="handle btn btn-success ml-1" style="height: 38px" type="button">
                  <i class="fas fa-arrows-alt"></i>
                </button>
              </b-input-group>
            </div>
          </draggable>
        </form>
        <div class="mb-2">
          <button
            class="btn btn-success"
            @click.prevent="addRoleData">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <modal-footer
        slot="modal-footer"
        :okDisabled="false"
        @ok="formRoleSubmit"
        @cancel="formRoleCancel">
      </modal-footer>
    </b-modal>
    <error-modal ref="errorModal" :message="message"/>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import {Role, AccessPermissionData} from '../model/role'
import csrfToken from '../util/csrf-token'

export default {
  props: ['controller'],
  data: function() {
    return {
      agentOptions: this.controller.listAgents,
      message:'',
      modalTitle: '',
      formRole: {
        name: '',
        data: [],
      },
      permissions:[
        {value: 'admin', text: 'Admin'},
        {value: 'editor', text: 'Editor'},
        {value: 'viewer', text: 'Viewer'},
      ]
    }
  },
  validations: {
    formRole: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      data: {
        $each: {
          agent_id: {
            required
          },
          permission: {
            required
          }
        }
      }
    }
  },
  methods: {
    openAddRoleModal() {
      this.modalTitle = 'Add New Role'
      this.formRole = new Role()
      this.formRole.data = [new AccessPermissionData()]
      this.$refs.rolemodal.show()
    },
    addRoleData() {
      this.formRole.data.push(new AccessPermissionData())
    },
    removeRoleData(index) {
      this.formRole.data.splice(index, 1)
    },
    formRoleSubmit() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      if(!validation.$invalid){
        this.$refs.rolemodal.hide()
        let result, message, promise
        [result, message, promise] = this.controller.createRole(
          this.formRole,
          csrfToken.getCsrfTokenFromCookie(document.cookie))
          if(!result) {
            this.showError(this.i18n.t('feedback.error'))
          } else {
          let self = this
            promise.then(response => {
              if(response){
                window.location.reload()
              }
            })
            .catch(function(error){
              this.formRole = {}
              self.showError(error.response.data[0])
            })
          }
      }
    },
    formRoleCancel() {
      this.formRole = {}
      this.$refs.rolemodal.hide()
    },
    showError(message) {
      this.message = message
      this.$refs.errorModal.show()
    }
  }
}
</script>

<style scoped>
</style>
