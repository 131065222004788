<template>
  <div
    ref="scenarioEditor"
    class="scenario-editor">
    <div class="row mt-1 mb-2">
      <synchronize
        class="col-sm-2"
        :userType="controller.userType"
        @start-synchronize="startSynchronize">
      </synchronize>
      <div class="col-sm-3 title-area">
        <b v-b-popover.hover.top="controller.scenario ? controller.scenario.name : null">
          {{ $t('scenario.selectedScenario') }}：
          {{controller.scenario ? truncateString(controller.scenario.name, 12) : null}}
        </b>
      </div>
      <language-change-scenario
        class="col-sm-4"
        :supportedLanguages="controller.supportedLanguages"
        :baseUrl="controller.scenarioEditorBaseUrl"
        :selectedLanguage="controller.language"
        :i18n="controller.i18n"
        :scenarioId="controller.scenario ? controller.scenario.id : null">
      </language-change-scenario>
      <div
        class="col-sm-2"
        v-if="controller.userType !== 'viewer'">
        <div class="btn-group float-right mr-3">
          <b-btn
            v-b-popover.hover.top="$t('scenario.deleteIntent')"
            variant="danger"
            :disabled="isDeleteButtonDisabled"
            @click="showDeleteNodeModal">
            <i class="fas fa-trash-alt"></i>
          </b-btn>
          <b-btn
            v-b-popover.hover.top="$t('scenario.reload')"
            variant="primary"
            :disabled="!controller.language || wizard"
            @click="layoutDiagram">
            <i class="fas fa-sync-alt"></i>
          </b-btn>
          <b-btn
            v-b-popover.hover.top="'Alias'"
            variant="secondary"
            :disabled="!controller.language || wizard"
            @click="showAliasModal">
            <i class="fas fa-clone"></i>
          </b-btn>
          <b-btn
            v-b-popover.hover.top="$t('scenario.exportImage')"
            variant="secondary"
            :disabled="!controller.language || isExportingImage"
            @click="exportImage">
            <i class="fas fa-image"></i>
            <b-spinner
              v-if="isExportingImage"
              small
              label="Spinning">
            </b-spinner>
          </b-btn>
        </div>
      </div>
      <div class="col-sm-1">
        <mode-change-scenario
          class="float-right button-text"
          :title="$t('scenario.scenarioList')"
          :url="controller.scenarioIndexUrl"
          :buttonText="$t('scenario.scenarioList')">
        </mode-change-scenario>
      </div>
    </div>

    <div v-if="controller.language">
      <div class="row">
        <div class="col-sm-3 side-menu-background">
          <intent-detail
            :apiUrl="controller.apiUrl"
            :availablePlatform="controller.availablePlatform"
            :categories="controller.intentCategories"
            :defaultPlatform="controller.default_platform"
            :intent="controller.selectedIntent"
            :intentDetail="controller.selectedIntentDetail"
            :intentDetailKeywordList="controller.intentDetailKeywordList"
            :intentDetailKeywordListBySearch="controller.intentDetailKeywordListBySearch"
            :keywordBoundCount="controller.keywordBoundCount"
            :keywordFullNameById="controller.keywordFullNameById"
            :language="controller.language"
            :questionSearchResult="controller.questionSearchResult"
            :selectedLanguageIntentDetail="controller.selectedLanguageIntentDetail"
            :selectedResponseMessage="controller.selectedResponseMessage"
            :selectedUserSay="controller.selectedUserSay"
            :supportedLanguages="controller.supportedLanguages"
            :userType="controller.userType"
            :wizard="wizard"
            :wizard3="wizard3"
            @cancel-question-modal="cancelQuestionModal"
            @changed-compared-language="onChangedComparedLanguage"
            @copy-user-says="copyUserSays"
            @create-keyword-name="createKeywordName"
            @delete-response-message="deleteResponseMessage"
            @delete-user-say="deleteUserSay"
            @load-intent-detail-keyword="loadIntentDetailKeywordById"
            @load-intent-keyword-dep-list="loadIntentDetailKeyword"
            @load-keyword-categories="loadKeywordCategories"
            @modalPopupCheck-flag="toggleModalShown"
            @reset-search-result="resetSearchResult"
            @save-all-response-message="saveAllResponseMessage"
            @save-keyword-category="saveKeywordCategory"
            @save-keyword-value="saveKeywordValue"
            @save-keyword="saveKeyword"
            @save-multiple-user-says="saveMultipleUserSays"
            @save-response-message="saveResponseMessage"
            @save-user-say="saveUserSay"
            @save-all-user-says="saveAllUserSays"
            @search-question="searchQuestion"
            @select-platform-service="selectPlatformService"
            @select-response-message="selectResponseMessage"
            @select-user-say="selectUserSay"
            @started-linking="OnStartLinking"
            @update-searched-question="updateSearchedQuestion"
            class="ml-2 mr-2"
            ref="intentDetail"
          />

          <div class="bottom-area">
            <div class="d-flex justify-content-end">
              <span v-b-popover.hover.top="$t('scenario.responseRuleMessage')">
                <i class="fas fa-info-circle"></i>
              </span>
            </div>
            <div v-if="controller.selectedIntent">
              <b-form-checkbox
                v-model="controller.selectedIntent.enableWebhook"
                @change="updateWebhookEnabled">
                {{ controller.i18n.t('intent.enableWebhook') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-model="controller.selectedIntent.enableWebhookForSlotFilling"
                @change="updateWebhookSlotFillingEnabled">
                {{ $t('intent.enableSlotFillingWebhook') }}
              </b-form-checkbox>

              <div>
                <b-btn
                  variant="outline-secondary"
                  size="sm"
                  @click="copyIntentPath">
                  <i class="fas fa-copy"></i>
                </b-btn>
                {{ $t('scenario.copyIntent') }}
              </div>

              <b-form-checkbox
                v-model="controller.selectedIntent.isFeedbackActive"
                @input="updateIsFeedbackEnabled">
                {{ controller.i18n.t('intent.feedback') }}
              </b-form-checkbox>

              <b-form-checkbox
                v-model="controller.selectedIntent.disableRedirector"
                @input="updateDisableRedirector">
                {{ controller.i18n.t('intent.disableRedirector') }}
              </b-form-checkbox>

              <div class="mt-2">
                <label>{{ $t('intent.titleShort') + 'ID : ' }}</label>
                <span style="color: gray;">{{ controller.selectedIntent.intentId }}</span>
              </div>

              <div class="d-flex bd-highlight mb-1 ml-1">
                <div @click="modalIntentUsingEvents = !modalIntentUsingEvents"
                  class="pointer-cursor bd-highlight">
                  <span class="float-none" v-show="!modalIntentUsingEvents"><i class="fas fa-caret-down"></i></span>
                  <span class="float-none" v-show="modalIntentUsingEvents"><i class="fas fa-caret-up"></i></span>
                  {{ $t('intent.events') }}
                </div>
                <div class="ml-auto bd-highlight">
                  <b-button
                    class="btn btn-success btn-xs"
                    v-b-tooltip.hover
                    :title="$t('scenario.saveEvent')"
                    :disabled="!modalIntentUsingEvents"
                    @click="updateEvents(controller.selectedIntent.events)">
                    <i class="fas fa-save"></i>
                  </b-button>
                </div>
              </div>

              <b-collapse id="intent-events-block" v-model="modalIntentUsingEvents">
                <div>
                  <form-tags
                    :tags="controller.selectedIntent.events"
                    :excludeList="excludeEvents"
                    :rule="/^[a-zA-Z0-9-_]+$/g"
                    :duplicated-message="$t('intent.eventDuplicated')"
                    :built-in-message="$t('intent.eventBuiltIn')"
                    :invalid-message="$t('intent.eventInvalid')"
                    :placeholder="$t('intent.eventName')"
                  >
                  </form-tags>
                </div>
              </b-collapse>

              <span class="context section">
                <div
                  class="intent-detail-header pointer-cursor"
                  v-b-toggle.context_collapse
                  @click="foldedContext = !foldedContext">
                  <span class="float-none pl-1" v-show="foldedContext">
                    <i class="fas fa-caret-up"></i>
                  </span>
                  <span class="float-none pl-1" v-show="!foldedContext">
                    <i class="fas fa-caret-down"></i>
                  </span>
                  {{ $t('scenario.contextSetting') }}
                </div>
                <b-collapse id="context_collapse">
                  <label class="mr-1">
                    Contexts :
                    <span v-b-popover.hover.top="$t('context.titleTooltipMessage')">
                      <i class="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <b-button size="sm" variant="success" @click="openContextSettingModal">Update</b-button>
                  <!-- コンテキストの情報 -->
                  <div
                    class="input-group input-group-sm mb-3"
                    v-for="(intentContext, idx) in controller.selectedIntentContext"
                    :key="idx">
                    <!--タイトル：インテント名-->
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend col-sm-12 pl-0 bg-light border">
                        <!-- ベースコンテキストの表示 -->
                        <span class="input-group-text">
                          <!-- ベースの場合は「ベース」、違う場合は遷移用と表示 -->
                          <b>{{ getIntentContextName(intentContext) }}</b>
                        </span>
                      </div>
                    </div>
                    <!--コンテキストID-->
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend col-sm-7 pl-0 bg-light border">
                        <span class="input-group-text">{{ $t('scenario.contextId') }}</span>
                      </div>
                      <div class="input-group-prepend col-sm-5 pl-0 border">
                        <span class="input-group-text">{{intentContext.context.id}}</span>
                      </div>
                    </div>
                    <!--ライフスパン-->
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend col-sm-7 pl-0 bg-light border overflow-auto">
                        <div class="input-group-text">{{ $t('scenario.lifeSpan') }}</div>
                      </div>
                      <div class="input-group-prepend col-sm-5 pl-0 border">
                        <span class="input-group-text">{{intentContext.lifespan}}</span>
                      </div>
                    </div>
                  </div>

                  <b-alert
                    class="fixed-top"
                    dismissible
                    :show="dismissCountDown"
                    :variant="intentContextsAlertVariant"
                    @dismissed="dismissCountDown=0"
                    @dismiss-count-down="countDownChanged">
                    <p>{{ intentContextsAlertMessage }}</p>
                  </b-alert>
                </b-collapse>
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-9 diagram-background">
          <scenario-diagram
            :scenarioDiagramId="controller.scenarioDiagramId"
            @click="showAddNodeModal"
            @edit-node="editNode"
            @load-intent-detail-keyword="loadIntentDetailKeyword"
            @on-finish-export-image="onFinishExportImage"
            @on-link-connected="onLinkConnected"
            @on-link-removed="onLinkRemoved"
            @save-thumbnail="saveThumbnail"
            @selected-intent="selectIntent"
            @show-wizard-2="showWizard2Modal"
            @wizard-start="showWizardStartModal"
            ref="scenarioDiagram" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class='h-75 mt-5'>
        <div class="p-3 m-3 text-center alert-info">
          <span>{{ $t('scenario.selectLanguage') }}</span>
        </div>
      </div>
    </div>

    <test-chat
      v-if="controller.language"
      :controller="controller.testChatController"
    />

    <progress-modal
      ref="progressModal"
      :message="message"
      @shown="toggleModalShown"
      @hidden="toggleModalShown" />

    <delete-confirm-modal
      ref="deleteNodeModal"
      :title="$t('intent.deleteTitle')"
      :bodyMessage="$t('scenario.deleteNodeMessage', {intent: this.controller.selectedNode})"
      @ok="deleteNode"
      @shown="toggleModalShown"
      @hidden="toggleModalShown"
      @key-press-enter="deleteNode" />

    <error-modal
      ref="errorModal"
      :message="message"
      :isCard="isCard"
      :cardErrorMsg="cardErrorMsg"
      :isBotBroken="isBotBroken"
      :botBrokenMessage="botBrokenMessage"
      @train="startSynchronize"
      @key-press-enter="$refs.errorModal.hide()" />

    <success-modal
      ref="syncCompletedModal"
      :title="$t('synchronize.result')"
      :message="$t('synchronize.completed')"
      @key-press-enter="closeSyncCompletedModal" />

    <wizard-modal
      ref="wizardModal"
      :title="$t('scenario.wizardTitle')"
      :startMessage="wizardStartMessage"
      :bodyMessage="wizardMessage"
      @cancel="finishWizardModal"
      @key-press-enter="$refs.wizardModal.hide()"
    />

    <b-modal
      ref="alias-modal"
      header-bg-variant="info"
      header-text-variant="white"
      :title="$t('scenario.listAliasTitle')"
      hide-footer
      scrollable>
      <div class="alert alert-warning" v-if="listAliases.length <= 0">
        <i class="fas fa-exclamation-circle"></i> {{$t('scenario.listAliasNotFound')}}
      </div>

      <div class="d-block">
        <ul class="list-group"
            v-if="listAliases.length > 0">
          <li class="list-group-item"
              v-for="(alias, index) in listAliases"
              :key=index>
            {{index+1}}. {{alias}}
          </li>
        </ul>
      </div>
    </b-modal>

    <b-alert
      v-if="inputTransportResult.message"
      fade
      :show="3"
      dismissible
      :variant="inputTransportResult.type"
      @dismissed="inputResetMessage"
      class="stick-to-bottom"
    >
      {{ inputTransportResult.message }}
    </b-alert>


    <!-- START Context Setting Modal-->
    <b-modal ref="context-setting-modal"
      header-bg-variant="info"
      header-text-variant="white"
      :title="$t('scenario.contextSetting')"
      scrollable hide-footer>
      <div class="context-setting-modal p-0 m-0">
        <form ref="contextSettingForm" @submit.stop.prevent="handleContextSettingFormSubmit">
          <!-- コンテキストの情報 -->
          <div class="input-group input-group-sm mb-3"
            v-for="(contextForm, idx) in $v.contextSettingForm.$each.$iter" :key="idx">
            <!--タイトル：インテント名-->
            <div class="input-group input-group-sm">
              <div class="input-group-prepend col-sm-12 pl-0 bg-light border">
                <!-- ベースコンテキストの表示 -->
                <span class="input-group-text">
                  <!-- ベースの場合は「ベース」、違う場合は遷移用と表示 -->
                  <b>{{ getIntentContextName(contextForm.$model) }}</b>
                </span>
              </div>
            </div>
            <!--コンテキストID-->
            <div class="input-group input-group-sm">
              <div class="input-group-prepend col-sm-7 pl-0 bg-light border">
                <span class="input-group-text">{{ $t('scenario.contextId') }}</span>
              </div>
              <div class="input-group-prepend col-sm-5 pl-0 border">
                <span class="input-group-text">{{contextForm.$model.context.id}}</span>
              </div>
            </div>
            <!--ライフスパン-->
            <div class="input-group input-group-sm">
              <div class="input-group-prepend col-sm-7 pl-0 bg-light border overflow-auto">
                <div class="input-group-text">{{ $t('scenario.lifeSpan') }}</div>
              </div>
              <b-form-input type="number"
                            v-model.number="contextForm.inputLifeSpan.$model"
                            :state="!contextForm.inputLifeSpan.$error"></b-form-input>
            </div>
          </div>
          <div slot="modal-footer" class="w-100">
            <b-button variant="info"
                      class="float-right"
                      type="submit"
                      :disabled="$v.contextSettingForm.$invalid">
              OK
            </b-button>
          </div>
        </form>
      </div>
    </b-modal><!-- /.END Context Setting Modal-->
  </div>
</template>

<script>
import {numeric, minLength} from 'vuelidate/lib/validators'
import csrfToken from "../util/csrf-token";
import { SynchronizeStatus } from '../model/synchronize'

export default {
  props: ['controller'],
  data() {
    return {
      message: null,
      isModalShown: false,
      enabledWebhook: false,
      isCard: false,
      cardErrorMsg:[],
      dismissSecs: 5,
      dismissCountDown: 0,
      intentContextsAlertVariant: 'dark',
      intentContextsAlertMessage: '',
      listAliases: [],
      foldedContext:false,
      modalIntentUsingEvents: false,
      excludeEvents: [
        'fallback', 'welcome', 'feedback',
        'feedback_worst', 'feedback_bad', 'feedback_normal',
        'feedback_good', 'feedback_best'
      ],
      isBotBroken: false,
      botBrokenMessage:'',
      /** ウィザード中かのフラグ */
      wizard: true,
      /**
       * ウィザードのステップ3にいるかのフラグ。
       * 3にいる場合は質問を追加させたいので回答ボタンをdisableするためにこの変数を使用
       */
      wizard3: false,
      wizardStartMessage: null,
      wizardMessage: null,
      isExportingImage: false,
      inputTransportResult: {
        message: null,
        type: null
      },
      deleteNodeType: null,
      contextSettingForm: []
    }
  },
  mounted: async function() {
    this.controller.setVM(this)

    if (!this.controller.language) {
      // 言語が選択されていない場合、シナリオを読み込んで終了
      return await this.controller.loadScenario()
    }

    // 言語が選択されている場合
    this.showProgress('...')
    try {
      await this.controller.showDiagram(csrfToken.getCsrfTokenFromCookie(document.cookie))
      this.showWizardStartModal()
    } catch (err) {
      console.error(err)
      this.showError(err.response.data[0])
    }
    this.hideProgress()
  },
  validations: {
    contextSettingForm: {
      $each: {
        inputLifeSpan: {
          numeric,
          minLength: minLength(1)
        }
      }
    }
  },
  computed: {
    isSynchronizing: function () {
      return this.controller.synchronizeController.isSynchronizing
    },
    synchronizeStatusMessage: function () {
      return this.controller.synchronizeController.synchronizeStatusMessage
    },
    isDeleteButtonDisabled() {
      return !this.controller.selectedIntent ||  this.controller.intents.length < 1 || this.wizard
    },
    inputSuccessMessage() {
      return (count) => this.$t('inputCompletion.transferSucceeded') + count
    }
  },
  methods: {
    async loadIntentDetailKeyword(){
      await this.controller.loadIntentDetailKeyword()
    },
    async loadIntentDetailKeywordById(id){
      await this.controller.loadIntentDetailKeywordById(id)
    },
    async createKeywordName(){
      await this.controller.keywordPathById()
    },
    /**
     * diagramをデフォルトの配置になるように調整
     */
    layoutDiagram(){
      window.appView.onClickLayoutButton()
    },
    /**
     * @param {number} intentId e.g. 28883
     * @param {string} nodeType qad.Question or qad.Answer
     */
    selectIntent(intentId, nodeType=null) {
      this.controller.selectIntent(intentId, nodeType)
      this.modalIntentUsingEvents = this.controller.selectedIntent
        && this.controller.selectedIntent.events
        && this.controller.selectedIntent.events.length > 0
    },
    showProgress(message) {
      this.isCard = false
      this.isBotBroken = false
      this.botBrokenMessage = ''
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    showError(message=this.controller.i18n.t('errors.errorTitle'), isCard=false) {
      if(!isCard){this.message = message}
      this.isCard = isCard
      this.$refs.errorModal.show()
    },
    /**
     * @param {string} deleteNodeType qad.Question or qad.Answer
     */
    showDeleteNodeModal() {
      // 削除対象のノードのタイプを記録しておく
      this.deleteNodeType = this.controller.selectedUserSay ? 'qad.Question' : 'qad.Answer'
      this.$refs.deleteNodeModal.show()
    },
    /**
     * ステップ1のウィザードを表示
     */
    showWizardStartModal(){
      if(!this.wizard) {
        // ウィザード中以外に呼ばれた場合は何もしない
        return
      }

      // ステップ1のウィザードを表示
      this.wizardStartMessage = this.controller.i18n.t('scenario.wizardStart')
      this.wizardMessage = this.controller.i18n.t('scenario.wizardFirst')
      this.$refs.wizardModal.show()
    },
    /**
     * ウィザードのモーダルを表示。
     */
    showWizard2Modal(){
      this.wizardStartMessage = null
      this.wizardMessage = this.controller.i18n.t('scenario.wizardSecond')
      this.$refs.wizardModal.show()
    },
    /**
     * ウィザードが終了したときの処理を行う
     */
    finishWizardModal() {
      this.$refs.wizardModal.hide()
      this.wizardStartMessage = null
      this.wizard = false
      this.wizard3 = false
      this.wizardMessage = this.controller.i18n.t('scenario.wizardFinish')
      this.$refs.wizardModal.hide()
    },
    /**
     * ノード自体の削除
     * TODO 質問と回答で分ける
     */
    async deleteNode() {
      this.showProgress(this.controller.i18n.t('general.saving'))
      try {
        await this.controller.deleteNode(
          csrfToken.getCsrfTokenFromCookie(document.cookie),
          this.deleteNodeType
        )
      } catch (err) {
        console.error(err)
        this.showError(err.response.data[0])
      } finally {
        this.hideProgress()
        // 削除対象のノードタイプをリセット
        this.deleteNodeType = null
      }
    },
    /**
     * モーダルの表示状態を切り替え
     */
    toggleModalShown() {
      this.isModalShown = !this.isModalShown
      if (!this.isModalShown) {
        this.$refs.scenarioEditor.focus()
      }
    },
    async startSynchronize(isCard=false) {
      try {
        this.showProgress("...")
        // 同期を開始したときにpromiseを受け取り、そのpromiseから同期が開始したか、メッセージを受け取る
        const result = await this.controller.startSynchronize(
          csrfToken.getCsrfTokenFromCookie(document.cookie),
          isCard
        )
        if (result.wasSuccessful) {
          this.showProgress(result.message)
        } else {
          this.showError(result.message)
        }
      } catch (error) {
        if( 'message' in error.response.data){
          this.showError(this.controller.i18n.t('errors.errorTitle'))
        }else{
          this.cardErrorMsg = error.response.data
          this.showError(this.cardErrorMsg, true)
        }
      }
    },
    closeSyncCompletedModal() {
      this.$refs.syncCompletedModal.hide()
    },
    cancelQuestionModal(){
      this.controller.cancelQuestionModal()
    },
    /**
     * 質問の保存処理
     */
    async saveUserSay(userSay) {
      this.showProgress(this.controller.i18n.t("general.saving"))
      try {
        await this.controller.saveUserSay(
          userSay,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )

        // ウィザードの処理
        if (this.wizard) {
          if(this.wizardMessage.includes('STEP1')) {
            // step1にいる場合は2を表示する
            this.showWizard2Modal()
          } else if (this.wizardMessage.includes('STEP4')) {
            // 最後のウィザード処理終了後
            this.wizard = false
            this.wizard3 = false
            this.wizardMessage = this.controller.i18n.t('scenario.wizardComplete')
            this.$refs.wizardModal.show()
          } else if (this.wizardMessage.includes('STEP3')) {
            // step3で質問を保存した場合はstep4のウィザードを表示
            this.wizardMessage = this.controller.i18n.t('scenario.wizardFourth')
            this.$refs.wizardModal.show()
          }
        }
      } catch (err) {
        console.error(err)
        this.showError()
      }
      this.hideProgress()
    },
    selectUserSay(userSay) {
      this.controller.selectUserSay(userSay)
    },
    /**
     * 質問削除処理
     */
    async deleteUserSay() {
      this.showProgress(this.controller.i18n.t("general.saving"))
      try {
        await this.controller.deleteUserSay(csrfToken.getCsrfTokenFromCookie(document.cookie))
      } catch (err) {
        console.error(err)
        this.showError(error.response.data[0])
      }
      this.hideProgress()
    },
    /**
     * 回答がsubmitされた時にintent-detailコンポネント経由で発火されるイベントのコールバック
     */
    async saveResponseMessage(responseMessage) {
      try {
        // 保存中のプログレスの表示
        this.showProgress(this.controller.i18n.t("general.saving"))
        // 回答の保存処理を行う
        await this.controller.saveResponseMessage(
          responseMessage,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )

        // wizard中の場合、次のウィザードを表示する
        if(this.wizard){
          this.wizard3 = true
          this.wizardMessage = this.controller.i18n.t('scenario.wizardThird')
          this.$refs.wizardModal.show()
        }
      } catch (error) {
        console.error(error)
        // プログレスは非表示にする
        this.showError(error.response.data[0])
      }

      // プログレスを非表示にする
      this.hideProgress()
    },
    saveAllResponseMessage(responseMessage){
      this.controller.saveAllResponseMessage(
        responseMessage,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    selectResponseMessage(responseMessage) {
      this.controller.selectResponseMessage(responseMessage)
    },
    /**
     * 回答のレコードを1つ削除
     */
    async deleteResponseMessage() {
      this.showProgress(this.controller.i18n.t("general.saving"))
      try {
        await this.controller.deleteResponseMessage(csrfToken.getCsrfTokenFromCookie(document.cookie))
      } catch (err) {
        console.error(err)
        this.showError(error.response.data[0])
      } finally {
        this.hideProgress()
      }
    },
    async saveKeyword(keyword) {
      try {
        this.showProgress('...')
        const instance = await this.controller.saveKeyword(
          keyword,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        // 新規作成のkeywordは常に最後に追加するようになっているので、idを用いて特定する必要がありません。
        // モーダルを開く前に、ロードして、最後の一つのkeywordを選択状態にする
        if (this.$refs.intentDetail.wordEditor) {
          await this.$refs.intentDetail.wordEditor.loadKeywords()
          this.$refs.intentDetail.wordEditor.selectKeyword(instance)
          this.$refs.intentDetail.show()
        }
      } catch (err) {
        console.error(err)
        if (err.name) {
          this.showError(err.name[0])
        }
      } finally {
        this.hideProgress()
      }
    },
    saveKeywordCategory(category) {
      let result, message, promise
      [result, message, promise] = this.controller.saveKeywordCategory(
        category,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let this2 = this
        promise.then(
          (instance) => {
            // instanceのidを用いて、新規作成のkeywordCategoryを特定する
            category.id = instance.id
            // モーダルを開く前に、ロードを完成させて、作成したcategoryを選択状態にする
            if(this2.$refs.intentDetail.wordEditor){
              this2.$refs.intentDetail.wordEditor.loadKeywordCategories().then(()=>{
                this2.$refs.intentDetail.wordEditor.selectKeywordCategory(category)
                this2.hideProgress()
                this2.$refs.intentDetail.show()
              })
            }
          })
          .catch((error) => {
            this2.errorMsg=error.response.data[0]
            this2.$refs.Error.show()
            this2.hideProgress()
          })
      }
    },
    loadKeywordCategories() {
      this.controller.loadKeywordCategories()
    },
    saveKeywordValue(keywordValue){
      this.controller.saveKeywordValue(keywordValue,csrfToken.getCsrfTokenFromCookie(document.cookie))
    },
    searchQuestion(searchString, intentCategory) {
      this.controller.searchQuestion(searchString, intentCategory)
    },
    resetSearchResult() {
      this.controller.questionSearchResult = null
    },
    OnStartLinking(message) {
      this.showProgress(message)
    },
    updateSearchedQuestion(intentDetails) {
      let result, message, promises
      [result, message, promises] = this.controller.updateSearchedUserSay(
        intentDetails,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let outsideThis = this
        Promise.all(promises)
          .then(function() {
            if (outsideThis.controller.selectedIntent != null) {
              outsideThis.controller.loadSelectedIntentDetail()
            }
            outsideThis.hideProgress()
          })
      }
    },
    toggleModalShown(isModalShown){
      this.isModalShown = isModalShown
    },
    async saveMultipleUserSays(userSayText) {
      this.showProgress('...')
      try {
        await this.controller.saveMultipleUserSays(userSayText)
      } catch (e) {
        console.error(e)
        this.showError(this.controller.i18n.t('errors.errorTitle'))
      }
      this.hideProgress()
    },
    editNode() {
      if (this.controller.selectedResponseMessage == null) {
        this.$refs.intentDetail.addResponse()
      } else {
        this.$refs.intentDetail.editResponseMessage()
      }
    },
    showAddNodeModal() {
      if (~this.controller.selectedNode.indexOf('node_q')) {
        this.$refs.intentDetail.addResponse()
      } else if(~this.controller.selectedNode.indexOf('node_a')) {
        this.controller.selectIntent(null)
        this.$refs.intentDetail.addUserSay()
      }
    },
    /**
     * リンクが接続されたときの処理
     * 例：新規の質問を追加したため、その前の回答との間のリンクがプログラムにより作成される。
     * コンテキストとインテントコンテキストを保存する。
     */
    async onLinkConnected(sourceIntentId, targetIntentId) {
      try {
        this.showProgress('...')

        await this.controller.onLinkConnected(
          sourceIntentId,
          targetIntentId,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
      } catch (err) {
        this.showError(this.controller.i18n.t('errors.errorTitle'))
      } finally {
        this.hideProgress()
      }
    },
    async onLinkRemoved(sourceId, targetId) {
      try {
        this.showProgress(this.controller.i18n.t('general.saving'))
        await this.controller.onLinkRemoved(
          sourceId,
          targetId,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
      } catch (err) {
        console.error(err)
        this.showError()
      }
      this.hideProgress()
    },
    async updateWebhookEnabled(isEnabled) {
      await this.controller.updateWebhookEnabled(
        isEnabled,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    copyIntentPath() {
      this.controller.copyIntentPath()
    },
    onChangedComparedLanguage(language_code) {
      this.controller.loadSelectedLanguageUserSays(language_code)
    },
    copyUserSays(intentDetail) {
      // 質問の多言語比較時にコピーする
      this.controller.copyUserSays(intentDetail)
    },
    saveThumbnail(imageData) {
      this.controller.saveThumbnail(
        imageData,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    updateIsFeedbackEnabled() {
      this.controller.updateIsFeedbackEnabled(csrfToken.getCsrfTokenFromCookie(document.cookie))
    },
    updateDisableRedirector() {
      this.controller.updateDisableRedirector(csrfToken.getCsrfTokenFromCookie(document.cookie))
    },
    updateEvents(events){
      this.controller.updateEvents(events, csrfToken.getCsrfTokenFromCookie(document.cookie))
    },
    updateLifeSpan(intentContext) {
      if(this.$v.contextSettingForm.$invalid) {
        this.intentContextsAlertVariant = 'info'
        this.intentContextsAlertMessage = 'Only integer number is allowed to update.'
        this.showintentContextsAlert()
        return false
      }
      this.controller.setLifeSpan(
          intentContext,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
      ).then(response => {
        if(response){
          this.intentContextsAlertVariant = 'success'
          this.intentContextsAlertMessage = 'Updated successfully.'
          this.controller.selectedIntentContext = this.contextSettingForm.map(ic => {
            ic.lifespan = ic.inputLifeSpan
            return ic
          })
        } else {
          this.intentContextsAlertVariant = 'warning'
          this.intentContextsAlertMessage = 'Sorry! failed to update, Please try again later.'
        }
        this.showintentContextsAlert()
      }).catch(error => {
        this.intentContextsAlertVariant = 'danger'
        this.intentContextsAlertMessage = 'Sorry! something went wrong, Please try again later.'
        this.showintentContextsAlert()
      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showintentContextsAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    showAliasModal() {
      this.listAliases = this.controller.fetchListAliases()
      this.$refs['alias-modal'].show()
    },
    checkAgentAlive() {
      const query = {
        'text': '@#$%^aduwenaf--1389371',
        'language':this.controller.getLanguageCode()
      }
      const token = csrfToken.getCsrfTokenFromCookie(document.cookie)
      this.controller.checkAgentState(query, token).then(response => {
        this.isBotBroken = false
        if(!response){
          this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
        }
      }).catch(error => {
        this.isBotBroken = false
        this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
      })
    },
    exportImage() {
      this.isExportingImage = true
      setTimeout(() => {
        this.controller.exportImage()
      }, 1000)
    },
    onFinishExportImage() {
      this.isExportingImage = false
    },
    async selectPlatformService(selectedPlatform,platformFlag){
      await this.controller.allIntentDetailPlatformUpdate(
        selectedPlatform,
        platformFlag,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    /**
     * 入力補完サーバーへのデータ転送処理
     */
    async transferInputCompletion () {
      // 入力補完がOnでなければ何もしない
      if(!this.controller.autoInputPredict) return

      try {
        const result = await this.controller.transferInputCompletion(csrfToken.getCsrfTokenFromCookie(document.cookie))
        this.inputTransportResult.message = this.inputSuccessMessage(result.data)
        this.inputTransportResult.type = 'success'
      } catch (e) {
        this.inputTransportResult.message =  e.response.data instanceof Array ? e.response.data[0] : e.response.data.detail
        this.inputTransportResult.type = 'danger'
      }
    },
    inputResetMessage () {
      this.inputTransportResult = {
        message: null,
        type: null
      }
    },
    truncateString(str, num) {
      return str.length <= num ? str : str.slice(0, num) + '...'
    },
    updateWebhookSlotFillingEnabled(status) {
      this.controller.updateWebhookSlotFillingEnabled(
        status,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    },
    openContextSettingModal() {
      this.$refs['context-setting-modal'].show()
      this.$nextTick(() => {
        this.loadIntentContextForm()
        this.$v.$touch()
      })
    },
    loadIntentContextForm() {
      const intentContext = this.controller.selectedIntentContext
      this.contextSettingForm = intentContext.map(ic => {
        ic.inputLifeSpan = ic.lifespan
        return ic
      })
    },
    getIntentContextName(param) {
      let name = this.$t('scenario.transition')
      if(param.context.id === this.controller.baseContext.id) {
        name = this.$t('scenario.base')
      }
      return name
    },
    handleContextSettingFormSubmit() {
      this.updateLifeSpan(this.contextSettingForm)
      this.$nextTick(() => {
        this.$refs['context-setting-modal'].hide()
      })
    },
    saveAllUserSays(userSays) {
      if(!userSays) {
        return false
      }
      this.controller.saveAllUserSays(
        userSays,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      )
    }
  },
  watch: {
    isSynchronizing: {
      // 同期終了時にプログレスを閉じる
      handler: function(isSynchronizing) {
        if (!isSynchronizing) this.hideProgress()
      }
    },
    synchronizeStatusMessage: {
      handler: function(synchronizeStatusMessage) {
        if (!synchronizeStatusMessage) return

        // ステータスメッセージが変更されたらmessageに同期
        this.message = synchronizeStatusMessage
        if(SynchronizeStatus.ERROR == this.controller.synchronizeController.synchronizeCode){
          this.hideProgress()
          this.isBotBroken = true
          this.showError(this.message)
          this.checkAgentAlive()
        } else if(SynchronizeStatus.BUSY == this.controller.synchronizeController.synchronizeCode){
          this.hideProgress()
          this.showError(this.message)
        } else if(SynchronizeStatus.FINISH == this.controller.synchronizeController.synchronizeCode){
          // 学習成功モーダルの表示
          this.$refs.syncCompletedModal.show()
          // 入力補完データの送信処理
          this.transferInputCompletion()
        }
      }
    }
  }
}
</script>
<style scoped>
  .stick-to-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
  }
</style>
