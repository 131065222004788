<template>
<div>
  <button
    class="btn btn-primary"
    @click="startSynchronize"
    v-if="userType !== 'viewer'">
    <i class="fas fa-robot"></i>
    {{ $t('synchronize.button') }}
  </button>
</div>
</template>

<script>
export default {
  props: ['userType'],
  methods: {
    startSynchronize(){
      this.$emit('start-synchronize')
    }
  }
}
</script>
