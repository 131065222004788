<template>
  <div>
    <b-btn
      class="mr-1"
      variant="info"
      :disabled="okDisabled"
      @click="$emit('ok')">
      {{ okTitle || "OK" }}
    </b-btn>
    <b-btn
      variant="secondary"
      @click="$emit('cancel')">
      {{ cancelTitle || $t('buttons.cancel') }}
    </b-btn>
  </div>

</template>


<script>
export default {
  props: [
    "okDisabled",
    "okTitle",
    "cancelTitle",
  ],
  data() {
    return {
    }
  }
}


</script>
