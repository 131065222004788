import {Keyword, KeywordCategory, KeywordValue} from '../model/keyword'
import {RestApi} from '../util/rest-api'
import {I18n} from '../util/i18n'
import {VIEW_URL} from '../resource/urls'
import axios from "axios/index"
import SynchronizeController from './synchronize-controller'
import cookies from '../util/get-intent-keyword-from-cookie'
import {Intent, IntentCategory} from "../model/intent";
import {TestChatController} from "./test-chat-controller";

class KeywordEditorController {
  constructor(apiUrl, language, supportedLanguages, userType, i18nContext, excelExportUrl,
              auto_input_predict, test_chat_platform, available_platform) {
    this.i18n = new I18n(i18nContext)
    this.auto_input_predict = auto_input_predict
    this.categoryModel = KeywordCategory
    this.userType = userType
    this.excelExportUrl = excelExportUrl
    this.importURL = apiUrl.importKeyword
    this.keywordCategoryApi = new RestApi(apiUrl.KeywordCategory, KeywordCategory)
    this.keywordApi = new RestApi(apiUrl.Keyword, Keyword)
    this.allKeywordApi = new RestApi(apiUrl.AllKeyword, Keyword)
    this.keywordValueApi = new RestApi(apiUrl.KeywordValue, KeywordValue)
    this.keywordSearchApi = new RestApi(apiUrl.KeywordSearch, KeywordValue)
    this.TryItNowApi = apiUrl.TryItNow
    this.language = language
    this.supportedLanguages = supportedLanguages  // 切替可能な言語一覧
    this.synchronizeController = new SynchronizeController(apiUrl, this.i18n)
    this.intentApi = new RestApi(apiUrl.Intent, Intent)
    this.intentCategoryApi = new RestApi(apiUrl.IntentCategory, IntentCategory)
    this.userSaysSchronizeApi = apiUrl.userSaysSynchronize

    this.categories = []
    this.allCategoriesFlat = []
    this.isAllSelected = true
    this.selectedCategory = null  // 選択中のカテゴリ
    this.currentDisplayedCategory = null
    this.keywords = []
    this.allKeyword = []
    this.selectedKeyword = null  // 選択中のキーワード
    this.keywordValues = []
    this.selectedKeywordValue = null  // 選択中のキーワード値
    this.isSynchronizing = false
    this.desc = true
    this.categorydesc = true
    this.selectedIntentCategories = null
    this.searchKeywordValuesResult = []
    this.availablePlatform = available_platform
    this.testChatController = new TestChatController(apiUrl, supportedLanguages, new I18n(i18nContext), available_platform, test_chat_platform)
    this.TryItNowApi = apiUrl.TryItNow
  }

  ready() {
    this.loadCategories()
      .then(()=>{this.loadKeywords()})
  }

  /**
   * Use for transfer input prediction
   * @param csrfToken
   * @returns {Promise<any>}
   */

  inputPredictionTransfer(csrfToken=null) {
    const options = {
      headers: {'X-CSRFToken': csrfToken}
    }
    return new Promise((resolve, reject) =>
      axios.post(this.userSaysSchronizeApi, {}, options)
        .then(res => resolve(res)).catch(err => reject(err))
      )
  }

  /**
  * Export keyword
  */
  makeDownloadUrl(id){
    return VIEW_URL.ExportKeyword.replace('<categoryId>', id)
  }
  /**
  * Import Json
  */
  importJson(data, csrfToken=null){
    let sendOptions = {}
    let options={}
    let this2=this
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    let params = {'data': data}
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.importURL,
        params,
        sendOptions
      )
        .then(()=>{
          this2.ready()
          resolve()
        })
    })
    return [true, this.i18n.t("general.saving"), promise]
  }
  /*
   * Sorting
   */
  dynamicSort(property) {
      let sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          if(sortOrder == -1){
              return b[property].localeCompare(a[property]);
          }else{
              return a[property].localeCompare(b[property]);
          }
      }
    }

  /*
   * カテゴリ編集時の選択カテゴリ検索
   */
  cnt_category_id(array1, array2) {
    for (var item of array1){
      if(array2.id == item.id){
        var result = item;
        return result;
      }
      else if(item.subcategories.length != 0){
        var result2 = this.cnt_category_id(item.subcategories, array2)
        if(result2 != null){
          return result2;
        }
      }
    }
  }

  /*
   * 子カテゴリ追加時の選択カテゴリ検索
   */
  cnt_subcategory_id(array1, array2) {
    for (var item of array1){
      if(array2.parentId == item.id){
        var result = item.subcategories[item.subcategories.length - 1];
        return result;
      }
      else if(item.subcategories.length != 0){
        var result2 = this.cnt_subcategory_id(item.subcategories, array2)
        if(result2 != null){
          return result2;
        }
      }
    }
  }

  getAllCategoriesFlat(categories) {
    let i = 0;
    while (i < categories.length) {
      this.allCategoriesFlat.push(categories[i])
      this.getAllCategoriesFlat(categories[i].subcategories)
      i ++
    }
  }

  getCategoryById(categoryId) {
    this.allCategoriesFlat = []
    this.getAllCategoriesFlat(this.categories)

    let mask = this.allCategoriesFlat.map(item => item.id === categoryId)
    let selectedCategory = this.allCategoriesFlat.filter((item, i) => mask[i])[0]

    return selectedCategory
  }

  /**
   * カテゴリをflatにする
   */
  allCategoriesToArray(allCategories, categories){
    categories.forEach((category)=>{
      allCategories.push({id: category.id, name: category.name, parentId: category.parentId})
      if(category.subcategories)
        this.allCategoriesToArray(allCategories, category.subcategories)
    })
    return allCategories
  }

  /**
   * サブカテゴリも含めた全カテゴリの名前をリストにする
   */
  allCategoryNamesToArray(names, allCategories, categoryId){
    let category = allCategories.filter(ct => ct.id === categoryId)[0]
    if(category){
      names.push(category.name)
      if(category.parentId)
        names = this.allCategoryNamesToArray(names, allCategories, category.parentId)
    }
    return names
  }
  /*
   * カテゴリ一覧取得
   */
  loadCategories() {
    return new Promise((resolve, reject)=>{
      this.keywordCategoryApi.list()
      .then((instances) => {
        // 初回アクセス時と削除操作時をはじく
        if (this.selectedCategory != null) {
          // 子カテゴリ
          if(this.selectedCategory.parentId != null){
            // 編集
            if (this.selectedCategory.id != null) {
              this.selectedCategory = this.cnt_category_id(instances, this.selectedCategory)
            }
            // 追加
            else {
              this.selectedCategory = this.cnt_subcategory_id(instances, this.selectedCategory)
              // キーワードおよびキーワード値一覧を更新
              this.loadKeywords()
            }
          }
          // 親カテゴリ
          else {
            // 編集
            if (this.categories.length == instances.length) {
              this.selectedCategory = this.cnt_category_id(instances, this.selectedCategory)
            }
            // 追加
            else {
              this.selectedCategory = instances[instances.length - 1]
              // キーワードおよびキーワード値一覧を更新
              this.loadKeywords()
            }
          }
          // 画面に表示される選択済みカテゴリの更新
          this.currentDisplayedCategory = this.selectedCategory
        }
        this.categories = instances
        resolve()
      })
      .catch((error) => {
        console.log(error)
      })
    })
  }

  /**
   * キーワード一覧取得
   */
  loadKeywords() {
    // キーワード選択解除
    this.selectedKeyword = null
    // キーワード値選択解除
    this.selectedKeywordValue = null
    // キーワード値一覧の初期化
    this.keywordValues = []

    this.desc=true

    let api = this.currentDisplayedCategory ? this.keywordApi : this.allKeywordApi
    let api2 = this.allKeywordApi
    // 選択されているカテゴリがallかどうか
    let params = this.currentDisplayedCategory ? {category_id: this.selectedCategory.id} : {}
    let params2={}
    api.list({params: params})
      .then((instances) => {
        this.keywords = instances
        if(!!cookies.getKeywordIdFromCookie(document.cookie)){
          let obj = this.keywords.filter(keyword =>
            keyword.id==cookies.getKeywordIdFromCookie(document.cookie))
          if(obj.length){
            this.keywords = this.keywords.filter(keyword =>
              keyword.categoryId==obj[0].categoryId)
            this.selectKeyword(obj[0])
          }
          else{
            document.cookie = "keywordId="+null+"; path=/"
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
    api2.list({params: params2})
      .then((instances) => {
        this.allKeyword = instances
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /*
   * キーワード値一覧取得
   */
  loadKeywordValues() {
    this.keywordValueApi.list({
      params: {
        keyword_id: this.selectedKeyword.id,
        language: this.language
      }
    })
      .then((instances) => {
        // 初回アクセス時と削除操作時をはじく
        if(this.selectedKeywordValue != null){
          // 追加・編集
          for (let keywordValue of instances) {
            // 追加・編集したキーワード値idを検索
            if (this.selectedKeywordValue.id == keywordValue.id) {
              this.selectedKeywordValue = keywordValue
              break;
            }
          }
        }
        this.keywordValues = instances
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /*
   * キーワードカテゴリを選択
   */
  selectCategory(category) {
    // 選択中なら何もしない
    if (this.selectedCategory == category) {
      return
    }

    // 選択済み表示をするカテゴリを更新
    this.currentDisplayedCategory = category
    // カテゴリがnullであればAllが選択されている状態
    this.isAllSelected = !category
    // カテゴリを選択
    this.selectedCategory = category
    document.cookie = "keywordId="+null+"; path=/"
    // 選択したカテゴリのキーワード一覧を読み込む
    this.loadKeywords()
  }

  /*
   * キーワードカテゴリを保存（追加・編集時）
   */
  saveCategory(category, csrfToken=null) {
    let promise = this.keywordCategoryApi.save(
      category,
      csrfToken
    )
    .then(() => {
      // 保存したキーワードカテゴリを選択
      this.selectCategory(category)
      // キーワードカテゴリ一覧を更新
      this.loadCategories()
    })
    this.isAllSelected = false
    return [true, this.i18n.t("general.saving"), promise]
  }

  /*
   * キーワードカテゴリを削除
   */
  deleteCategory(category, csrfToken=null) {
    let tempAllCategoriesFlat = this.allCategoriesFlat
    this.allCategoriesFlat = []
    this.getAllCategoriesFlat(this.categories)
    let categoriesFlat = this.allCategoriesFlat
    this.allCategoriesFlat = tempAllCategoriesFlat
    let index = categoriesFlat.indexOf(category)
    index = index-1
    let promise = this.keywordCategoryApi.destroy(
      category,
      csrfToken,
      {
        params: {
          id: category.id
        }
      }
    )
    .then(() => {
      // キーワード値選択解除
      this.selectedKeywordValue = null
      // キーワード値一覧の初期化
      this.keywordValues = []
      // キーワード選択解除
      this.selectedKeyword = null
      // キーワード一覧の初期化
      this.keywords = []
      // キーワードカテゴリ一覧を更新
      this.loadCategories()
      if(index >= 0){
        this.selectedCategory = categoriesFlat[index]
      }
      else{
        this.selectedCategory = null
      }
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /*
   * キーワードを選択
   */
  selectKeyword(keyword) {
    // 選択中なら何もしない
    if (this.selectedKeyword == keyword) {
      return
    }

    this.selectedCategory = this.getCategoryById(keyword.categoryId)
    this.isAllSelected = false
    this.currentDisplayedCategory = this.selectedCategory
    // キーワード値選択解除
    this.selectedKeywordValue = null
    // キーワードを選択
    this.selectedKeyword = keyword
    document.cookie = "keywordId="+keyword.id+"; path=/"
    // 選択したキーワードに登録されてるキーワード値の読み込み
    this.loadKeywordValues()
  }

  /**
   * キーワードを保存（追加・編集時）
   */
  saveKeyword(keyword, csrfToken=null) {
    this.selectedCategory = this.getCategoryById(keyword.categoryId)
    if (!this.isAllSelected) {
      this.currentDisplayedCategory = this.selectedCategory
    }

    let promise = this.keywordApi.save(
      keyword,
      csrfToken,
      {
        params: {
          category_id: keyword.categoryId
        }
      }
    )
    .then((instance) => {
      // 新規の場合
      if (keyword.id==null) {
        // InstanceDetailへのアクセスのためidだけ先にコピー
        keyword.id = instance.id
        // キーワード値の選択解除
        this.selectedKeywordValue = null
        // 一覧に追加
        this.keywords.push(keyword)
        // duplicationチェックのために追加しておく。
        this.allKeyword.push(keyword)
      }
      // 編集の場合
      else {
        this.allKeyword = this.allKeyword.map(element => {
          if (element.id == keyword.id) {
            // カテゴリが変わったことを反映し、duplicationチェック時に活用
            element = keyword
          }
          return element
        })
      }
      Object.assign(keyword, instance)
      // キーワード一覧で初期化する前に保存
      let keywordValue = this.selectedKeywordValue
      // キーワード一覧を更新
      this.loadKeywords()
      // キーワードを選択状態にする
      this.selectKeyword(keyword)
      // キーワード値を選択状態にする
      this.selectKeywordValue(keywordValue)
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /*
   * キーワードを削除
   */
  deleteKeyword(keyword, csrfToken=null) {
    let promise = this.keywordApi.destroy(
      keyword,
      csrfToken,
      {
        params: {
          id: keyword.id
        }
      }
    )
    .then(() => {
      document.cookie = "keywordId="+null+"; path=/"
      // キーワード一覧更新
      this.loadKeywords()
      this.selectedKeyword = null
      if(this.keywords.length > 0) {
        const currentKeywords = this.keywords.filter(kw => {
          return kw.id !== keyword.id && kw.categoryId === keyword.categoryId
        })
        if(currentKeywords.length > 0) {
          this.selectedKeyword = currentKeywords.shift()
          // update keyword values
          this.loadKeywordValues()
        }
      }
    })
    this.selectedCategory = this.currentDisplayedCategory
    return [true, this.i18n.t("general.saving"), promise]
  }

  /*
   * キーワード値を選択
   */
  selectKeywordValue(keywordValue) {
    // 選択中なら何もしない
    if (this.selectedKeywordValue == keywordValue) {
      return
    }
    this.selectedKeywordValue = keywordValue
  }

  /*
   * キーワード値を保存（追加・編集時）
   */
  saveKeywordValue(keywordValue, csrfToken=null) {
    let promise = this.keywordValueApi.save(
      keywordValue,
      csrfToken,
      {
        params: {
          keyword_id: keywordValue.keywordId,
          language: this.language
        }
      }
    )
    .then((instance) => {
      if (this.selectedKeywordValue != null) {
        Object.assign(this.selectedKeywordValue, instance)
      }
      // 保存したキーワード値を選択
      this.selectedKeywordValue = instance
      // キーワード値一覧更新
      this.loadKeywordValues()
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /*
   * キーワード値を削除
   */
  deleteKeywordValue(keywordValue, csrfToken=null) {
    let promise = this.keywordValueApi.destroy(
      keywordValue,
      csrfToken,
      {
        params: {
          keyword_id: keywordValue.keywordId
        }
      }
    )
    .then(() => {
      // 一番目のキーワード値を選択
      if(this.keywordValues.length > 0){
        if(this.keywordValues[0].id === keywordValue.id)
          this.selectedKeywordValue = this.keywordValues[1]
        else
          this.selectedKeywordValue = this.keywordValues[0]
      }
      else
        this.selectedKeywordValue = null
      // キーワード値一覧更新
      this.loadKeywordValues()
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * synchronizeControllerを呼び出してdialogflowへの同期を開始する
   */
  startSynchronize(csrfToken=null, isCard) {
    let synchResult = this.synchronizeController.synchronize(csrfToken, isCard)
    return synchResult
  }

  /**
   * 紐づけているインテントデータを取得する
   */
  showIntentListInUse(selectedKeyword) {
    this.selectedIntentCategories = null
    this.intentApi.list({
      params: {
        selected_keyword_id: selectedKeyword.id,
        language: this.language
      }
    })
      .then((categories) => {
        this.selectedIntentCategories = categories
      })
  }
  searchKeywords(searchString){
    //データベースから取り出さなくて、既存のcategoriesとallIntentsを利用する
    const allCategories = this.allCategoriesToArray([], this.categories)
    this.keywordSearchApi.list({
      params: {
        language: this.language,
        search_string: JSON.stringify(searchString)
      }
    }).then(response => {
      this.searchKeywordValuesResult = response.map(searchedKeyword => {
        // get the category tree view by keyword id
        this.allKeyword.filter(keyword => {
          if(keyword.id === searchedKeyword.keywordId) {
            let names = []
            names.push(keyword.name)
            names = this.allCategoryNamesToArray(names, allCategories, keyword.categoryId)
            searchedKeyword['title'] =  names.reverse().join(' >> ')
          }
        })
        searchedKeyword['synonym'] = searchedKeyword.synonym.join(', ')
        return searchedKeyword
      })
    })
  }

  /**
   * Detect locale language
   * @returns {*}
   */
  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }

  /**
   * Detect Intent for checking agent state
   * @param param
   * @param csrfToken
   * @returns {Promise<unknown>}
   */
  checkAgentState(param, csrfToken='') {
    let sendOptions = {}
    let options = {}
    let params = {
      user_say: param.text,
      language: param.language
    }
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    return new Promise((resolve, reject) => {
      axios.post(
        this.TryItNowApi,
        params,
        sendOptions
      ).then((instance) => {
        let result = false
        const response = JSON.parse(instance.data)
        if("intent" in response) {
          result = true
        }
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export {
  KeywordEditorController
}
