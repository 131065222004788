export const detectStringType = (str) => {
  if (!isNaN(str)) {
    return 'number'
  } else if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(str)) {  // %Y-%m-%d -> date
    return 'date'
  } else if (/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(str)) {  // %H:%M:%S -> time
    return 'time'
  } else if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(str)) { // %Y-%m-%dT%H:%M:%S -> datetime
    return 'datetime'
  } else {
    try {
      const obj = JSON.parse(str)
      if (Array.isArray(obj)) {
        return 'array'
      }
    } catch (e) {
      // do nothing
    }
  }
  return 'string'
}
