<template>
<div class="mode-change-keyword">
  <a :href="supportedLanguages[language].keywordUrl">
    <button
      :title="$t('intent.goToKeywordPage')"
      class="btn btn-success">
      <i class="fas fa-exchange-alt"></i>
      {{ $t('keyword.title') }}
    </button>
  </a>
</div>
</template>

<script>
export default {
  props: ['supportedLanguages', 'language']
}
</script>
