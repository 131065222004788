import Vue from "vue";
import { UrlBuilder } from "./util/url-builder";
import { API_URL } from "./resource/urls";
import ConversationHistoryController from "./controller/conversation-history-controller";

import ProgressModal from "./components/ProgressModal.vue";
import ErrorModal from "./components/ErrorModal.vue";
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue";
import SuccessModal from "./components/SuccessModal.vue";
import ModalFooter from "./components/ModalFooter.vue";
import ConversationHistory from "./components/ConversationHistory.vue";
import ConversationHistorySearchFilter from "./components/ConversationHistorySearchFilter.vue";
import ConversationHistorySessionList from "./components/ConversationHistorySessionList.vue";
import ConversationHistoryLog from "./components/ConversationHistoryLog.vue";
import ConversationHistoryInformation from "./components/ConversationHistoryInformation.vue";
import ConversationHistoryCalendar from "./components/ConversationHistoryCalendar.vue";
import ConversationHistorySessionFilter from "./components/ConversationHistorySearchFilterSession.vue";
import ConversationHistorySearchFilterPlatform from "./components/ConversationHistorySearchFilterPlatform.vue";
import ConversationHistoryMessageTypeFilter from "./components/ConversationHistoryMessageTypeFilter.vue";
import ConversationHistoryLanguageTypeFilter from "./components/ConversationHistoryLanguageTypeFilter.vue";
import PlatformIcon from "./components/PlatformIcon.vue";
import ErrorModalFooter from "./components/ErrorModalFooter.vue";
import SendMessageToUser from "./components/SendMessageToUser.vue";
import ChatItemUserSay from "./components/chatItems/ChatItemUserSay.vue";
import ChatItemFileUserSay from "./components/chatItems/ChatItemFileUserSay.vue";
import ChatItemLocation from "./components/chatItems/ChatItemLocation.vue";
import ChatItemText from "./components/chatItems/ChatItemText.vue";
import ChatItemCard from "./components/chatItems/ChatItemCard.vue";
import ChatItemQuickReplies from "./components/chatItems/ChatItemQuickReplies.vue";
import ChatItemImage from "./components/chatItems/ChatItemImage.vue";
import ChatItemAudio from "./components/chatItems/ChatItemAudio.vue";
import ChatItemVideo from "./components/chatItems/ChatItemVideo.vue";
import ChatItemBottomDetail from "./components/chatItems/ChatItemBottomDetail.vue";
import VCalendar from "v-calendar";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import { BasicSelect } from "vue-search-select";


export const appSessionConversation = (i18n) => {
  Vue.use(VCalendar);
  Vue.use(VueMoment, {
    moment,
  });

  /* コントローラ */
  const controller = new ConversationHistoryController(
    new UrlBuilder(API_URL).build(),
    window.i18nContext,
    window.requestContext
  );

  Vue.component("progress-modal", ProgressModal);
  Vue.component("error-modal", ErrorModal);
  Vue.component("basic-select", BasicSelect);
  Vue.component("delete-confirm-modal", DeleteConfirmModal);
  Vue.component("success-modal", SuccessModal);
  Vue.component("modal-footer", ModalFooter);
  Vue.component("conversation-history", ConversationHistory);
  Vue.component(
    "conversation-history-search-filter",
    ConversationHistorySearchFilter
  );
  Vue.component(
    "conversation-history-session-list",
    ConversationHistorySessionList
  );
  Vue.component("conversation-history-log", ConversationHistoryLog);
  Vue.component(
    "conversation-history-information",
    ConversationHistoryInformation
  );
  Vue.component("history-calendar", ConversationHistoryCalendar);
  Vue.component(
    "conversation-history-search-filter-platform",
    ConversationHistorySearchFilterPlatform
  );
  Vue.component(
    "conversation-history-message-type-filter",
    ConversationHistoryMessageTypeFilter
  );
  Vue.component(
    "conversation-history-language-type-filter",
    ConversationHistoryLanguageTypeFilter
  );
  Vue.component(
    "conversation-history-search-filter-session",
    ConversationHistorySessionFilter
  );
  Vue.component("platform-icon", PlatformIcon);
  Vue.component("error-modal-footer", ErrorModalFooter);
  Vue.component("send-message-to-user", SendMessageToUser);
  Vue.component("chat-item-user-say", ChatItemUserSay);
  Vue.component("chat-item-file-user-say", ChatItemFileUserSay);
  Vue.component("chat-item-location", ChatItemLocation);
  Vue.component("chat-item-text", ChatItemText);
  Vue.component("chat-item-card", ChatItemCard);
  Vue.component("chat-item-quick-replies", ChatItemQuickReplies);
  Vue.component("chat-item-image", ChatItemImage);
  Vue.component("chat-item-video", ChatItemVideo);
  Vue.component("chat-item-audio", ChatItemAudio);
  Vue.component("chat-item-bottom-detail", ChatItemBottomDetail);
  Vue.directive("focus", {
    inserted: function (el) {
      // 要素にフォーカスを当てる
      el.focus();
    },
  });

  new Vue({
    el: "#app-session-conversation",
    data: {
      controller: controller,
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready();
      });
    },
    i18n: i18n,
  });
}
