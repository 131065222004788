<template>
  <div class="answer-cardGoogle">
    <div>
      {{ $t('answer.content') }}({{ $t('answer.typeCard') }}):
    </div>
    <div @keypress.enter.prevent="">
      <div class="input-group mb-1">
        <b-form-input
          :placeholder="$t('answer.enterImageURL')"
          @input="validationCheck"
          v-model="responseMessage.imageUrl"
          type="url"
          :state="!$v.responseMessage.imageUrl.$error"
          ref="responseMessageImageUrl"
        >
        </b-form-input>
        <div class="input-group-append">
          <a
            class="btn btn-info"
            :class="{ disabled: $v.responseMessage.imageUrl.$error }"
            :href="responseMessage.imageUrl" target="_blank"
            :title="$t('answer.preView')"
          ><span class="fas fa-eye mt-1"/>
          </a>
          <a class="btn btn-primary text-white" v-b-tooltip.hover
             :title="$t('answer.browseImage')"
             @click="loadImageUrlFiles">
            <i class="far fa-images"></i>
          </a>
        </div>
        <b-form-invalid-feedback v-if="$v.responseMessage.imageUrl.$error">
          {{ $t('webhook.required') }} &
          {{ $t('answer.invalidURL') }}
        </b-form-invalid-feedback>
      </div>
      <file-contents ref="imageUrlFiles"
                     :img-url-el="$refs.responseMessageImageUrl"
                     @img-url-model="responseMessage.imageUrl = $event"></file-contents>
      <b-form-input
        :placeholder="$t('answer.enterImageTitle')"
        :disabled="$v.responseMessage.imageUrl.$invalid"
        v-model="responseMessage.imageName"
        class="mb-1"
        type="text">
      </b-form-input>
      <b-form-input
        :placeholder="$t('answer.enterCardTitle')"
        @input="validationCheck"
        v-model="responseMessage.cardTitle"
        class="mb-1"
        type="text"
        :state="!$v.responseMessage.cardTitle.$error"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="$v.responseMessage.cardTitle.$error">
        {{ $t('webhook.required') }}
      </b-form-invalid-feedback>
      <b-form-input
        :placeholder="$t('answer.enterCardSubtitle')"
        v-model="responseMessage.cardSubTitle"
        class="mb-2"
        type="text">
      </b-form-input>
      <b-form-textarea
        :placeholder="$t('answer.typeText')"
        v-model="responseMessage.googleText"
        class="mb-2"
        :rows="3"
        :max-rows="6">
      </b-form-textarea>
      <b-form-input
        :placeholder="$t('answer.enterUrlTitle')"
        @input="validationCheck"
        v-model="responseMessage.linkTitle"
        class="mb-1"
        type="text">
      </b-form-input>
      <b-form-input
        :placeholder="$t('answer.enterURL')"
        :disabled="responseMessage.linkTitle==false"
        @input="validationCheck"
        v-model="responseMessage.link"
        type="url"
        class="mb-1"
        :state="!$v.responseMessage.link.$error"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="$v.responseMessage.link.$error">
        {{ $t('answer.invalidURL') }}
      </b-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
import FileContents from './partial/FileContents.vue'
import { required, url } from 'vuelidate/lib/validators'
import {CardGoogleData} from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'

export default {
  components: {
    FileContents
  },
  data() {
    return {
      responseMessage: {
        imageUrl:'',
        imageName:'',
        cardTitle:'',
        cardSubTitle:'',
        googleText:'',
        linkTitle:'',
        link:''
      },
      answerCardGoogle:null,
      required:null
    }
  },
  validations: {
    responseMessage: {
      imageUrl: {
        required,
        url
      },
      cardTitle:{
        required
      },
      link: {
        url
      }
    }
  },
  methods:{
    inputValue(){
      this.answerCardGoogle = new CardGoogleData(
        this.responseMessage.imageUrl.replace(illegalExp, "").trim(),
        this.responseMessage.imageName.replace(illegalExp, "").trim(),
        this.responseMessage.cardTitle.replace(illegalExp, "").trim(),
        this.responseMessage.cardSubTitle.replace(illegalExp, "").trim(),
        this.responseMessage.googleText.replace(illegalExp, "").trim(),
        this.responseMessage.linkTitle.replace(illegalExp, "").trim(),
        this.responseMessage.link.replace(illegalExp, "").trim())
      return this.answerCardGoogle
    },
    setValue(selectedResponseMessage){
      this.responseMessage.imageUrl = selectedResponseMessage.data.url
      this.responseMessage.imageName = selectedResponseMessage.data.imgname
      this.responseMessage.cardTitle = selectedResponseMessage.data.title
      this.responseMessage.cardSubTitle = selectedResponseMessage.data.subtitle
      this.responseMessage.googleText = selectedResponseMessage.data.text
      this.responseMessage.linkTitle = selectedResponseMessage.data.linktitle
      this.responseMessage.link = selectedResponseMessage.data.link
    },
    initializeValue(){
      this.responseMessage.imageUrl='',
      this.responseMessage.imageName='',
      this.responseMessage.cardTitle='',
      this.responseMessage.cardSubTitle='',
      this.responseMessage.googleText='',
      this.responseMessage.linkTitle='',
      this.responseMessage.link='',
      this.answerCardGoogle=null
      this.required=false
    },
    validationCheck(){
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.required = !validation.$invalid // Declare to the parent
      this.$emit('is-valid', this.required)
    },
    loadImageUrlFiles() {
      this.$refs.imageUrlFiles.loadImages()
    }
  }
}
</script>

