<template>
  <b-modal
    :title="title"
    ref="deleteConfirmModal"
    header-class="bg-danger text-light"
    body-class="text-dark"
    ok-variant="danger"
    no-close-on-backdrop
    @keydown.native.enter="handleEnter"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')">
    <div>
      <p class="mb-0" :class="{ 'font-weight-bold': bodySubMessage }">{{ bodyMessage }}</p>
      <div v-for="message in bodySubMessage" v-if="bodySubMessage">
        <small class="text-danger">※{{message}}</small>
      </div>
    </div>
    <modal-footer
      slot="modal-footer"
      :ok-title="$t('general.continue')"
      :cancel-title="$t('general.abort')"
      @ok="handleOK"
      @cancel="cancel">
    </modal-footer>
  </b-modal>

</template>

<script>
export default {
  props: [
    'title',
    'bodyMessage',
    'bodySubMessage',
  ],
  data() {
    return {
    }
  },
  methods: {
    show() {
      this.$refs.deleteConfirmModal.show()
    },
    cancel() {
      this.$refs.deleteConfirmModal.hide()
      this.$emit('cancel')
    },
    hide() {
      this.$refs.deleteConfirmModal.hide()
    },
    handleOK() {
      this.hide()
      this.$emit('ok')
    },
    handleEnter() {
      this.hide()
      this.$emit('key-press-enter')
    }
  },
}
</script>
