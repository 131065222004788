<template>
  <!-- クイックリプライ形式の回答表示用コンポネント -->
  <div class="mb-1">
    <div class="message-bg-grey">
      <div class="card-header">
        <div>
          {{ chatItem.text }}

          <!-- 翻訳表示領域 -->
          <div v-if="translationObject" class="p-1 text-success text-size-sm-right">
            {{ translationObject[chatItem.text] ?? "" }}
          </div>
        </div>
      </div>

      <div class="card-button-container">
        <div v-for="(option, idx) in chatItem.options" :key="idx" class="ml-2">
          <div class="d-flex align-items-center">
            <div>
              {{ option }}

              <!-- 翻訳表示領域 -->
              <div v-if="translationObject" class="p-1 text-success text-size-sm-right">
                {{ translationObject[option] ?? "" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <chat-item-bottom-detail
      :chat-item="chatItem"
      :redirector-access-logs="redirectorAccessLogs"
    />
  </div>
</template>

<script>
export default {
  props: ["chatItem", "redirectorAccessLogs", "translationObject"],
};
</script>
