import { I18n } from '../util/i18n'
import { str_z2h } from "../util/string-z2h"

class AgentSwitchController {
  constructor(language, i18nContext, agentsContext) {
    this.language = language
    this.i18n = new I18n(i18nContext)
    this.agentId = agentsContext.agent_id
    this.allAgents = agentsContext.agents
    this.orderingOption = 1
    this.orderingType = "id"
    this.selectedAgent = null
    this.displayAgents = this.agentsSort(this.allAgents)
    this.url = agentsContext.url + '?agent_id='
    document.cookie = "intentId="+null+"; path=/"
    document.cookie = "keywordId="+null+"; path=/"
  }

  toggleSort(togglingSortType){
    if(togglingSortType){
      this.orderingType = this.orderingType === "id" ? "name" : "id"
    }
    else{
      this.orderingOption = this.orderingOption * (-1)
    }
    this.displayAgents = this.agentsSort(this.displayAgents)
  }

  agentsSort(agents, orderingType=this.orderingType, orderingOption=this.orderingOption){
    let sortedAgents = agents.sort(this.sortMethod(orderingType, orderingOption))
    if(sortedAgents.length){
      this.selectedAgent = sortedAgents[0]
    }
    else{
      this.selectedAgent = null
    }
    return sortedAgents
  }

  sortMethod(orderingType, orderingOption){
    switch (orderingType) {
      case 'id':
        return function(agent1,agent2){
          if(agent1.id < agent2.id){
            return orderingOption*(-1)
          }
          if(agent1.id > agent2.id){
            return (orderingOption)*(1)
          }
          return 0
        }
      case 'name':
        return function(agent1,agent2){
          if(agent1.name < agent2.name){
            return orderingOption*(-1)
          }
          if(agent1.name > agent2.name){
            return (orderingOption)*(1)
          }
          return 0
        }
    }
  }

  filterAgent(searchingName=null) {
    if (searchingName) {
      this.displayAgents = this.agentsSort(this.allAgents.filter(agent =>
        str_z2h(agent.name.toLowerCase()).includes(searchingName)
      ))
    }
    else{
      if (this.displayAgents.length !== this.allAgents.length) {
        this.displayAgents = this.agentsSort(this.allAgents)
      }
    }
  }

  selectAgent(option){
    if([1, -1].indexOf(option) > -1 && this.displayAgents.length){
      let index = this.displayAgents.findIndex(agent=>
        this.selectedAgent.id === agent.id
      )
      if(index + option >= 0 && index + option < this.displayAgents.length){
        this.selectedAgent = this.displayAgents[index + option]
      }
    }
  }
  switchToAgent(){
    if(this.selectedAgent){
      location.href = this.url + this.selectedAgent.id
    }
  }
}

export {
  AgentSwitchController
}
