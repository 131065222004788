<template>
  <div class="p-1">
    <b-card no-body>
      <b-tabs pills card @input="onSelectTab">
        <!-- 言語単位でタブを分ける -->
        <b-tab
          v-for="(item, index) in items"
          :key="index"
          :title="$t(languageString(item.language))"
          lazy
        >
          <!-- フィルター領域 -->
          <div class="input-group float-right mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ $t("analytics.filter") }}</span>
            </div>
            <b-form-input
              v-model="filter"
              type="search"
              :placeholder="$t('analytics.search')"
            >
            </b-form-input>
            <div class="input-group-append">
              <b-button :disabled="!filter" @click="filter = ''">
                {{ $t("analytics.clear") }}
              </b-button>
            </div>
          </div>
          <!-- テーブル領域 -->
          <b-table
            responsive
            hover
            small
            striped
            bordered="bordered"
            headVariant="dark"
            :items="item.items"
            :fields="fields"
            :filter="filter"
            :filter-function="filterByIntentName"
            :per-page="perPage"
            :current-page="currentPage"
            @filtered="onFiltered"
          >
            <template slot="name" slot-scope="data">
              <!-- 言語が選択されているときのみインテント名のクリックでインテント画面に飛ばす -->
              <div v-if="index === 0">
                <span>{{ data.value }}</span>
              </div>
              <div v-else>
                <a
                  :href="goToIntentDetail(data.value, item.language)"
                  target="_blank"
                >
                  {{ data.value }}
                </a>
              </div>
            </template>
          </b-table>
          <!-- テーブルフッダー領域 -->
          <div class="row">
            <div class="col-auto mr-auto">
              <div class="row">
                <div class="col-auto mr-auto">
                  {{ $t("analytics.perPage") }}
                </div>
                <div class="ml-auto">
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { LanguageParser } from "../util/languageParser";
export default {
  props: ["items", "language"],
  data() {
    const intentName = this.$t("analytics.intent");
    const bestFeedback = this.$t("analytics.bestFeedback");
    const goodFeedback = this.$t("analytics.goodFeedback");
    const normalFeedback = this.$t("analytics.normalFeedback");
    const badFeedback = this.$t("analytics.badFeedback");
    const worstFeedback = this.$t("analytics.worstFeedback");
    const freeTextFeedback = this.$t("analytics.freeTextFeedback");
    return {
      filter: null,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      totalRows: 0,
      fields: [
        {
          label: intentName,
          key: "name",
          sortable: true
        },
        {
          label: bestFeedback,
          key: "best",
          sortable: true
        },
        {
          label: goodFeedback,
          key: "good",
          sortable: true
        },
        {
          label: normalFeedback,
          key: "normal",
          sortable: true
        },
        {
          label: badFeedback,
          key: "bad",
          sortable: true
        },
        {
          label: worstFeedback,
          key: "worst",
          sortable: true
        },
        {
          label: freeTextFeedback,
          key: "freetext",
          sortable: true
        }
      ]
    };
  },
  mounted() {
    // ロード時は全言語のデータをトータル行数に設定。
    this.totalRows = this.items[0].items.length;
  },
  computed: {
    // 言語コードを言語名称へ変換
    code2lang() {
      return code => {
        return LanguageParser.code2lang(code);
      };
    },
    // 言語コードから言語の正式名称の翻訳キーを取得
    languageString() {
      return code => {
        const langCode = this.code2lang(code);
        if (langCode) {
          return `languageSelector.${langCode}`;
        } else if (code === "total") {
          return "analytics.all_language";
        }
      };
    }
  },
  methods: {
    /**
     * 言語のタブ選択時、表示中のテーブルのトータルの行数を計算し直す。
     */
    onSelectTab(tabIndex) {
      this.totalRows = this.items[tabIndex].items.length;
    },
    /**
     * 会話名での検索が走ったときの処理。
     * totalRowsの更新。
     * 現在のページを1に更新。
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // 現在のページを1に更新。
      this.currentPage = 1;
    },
    /**
     * 選択されたインテントをインテントページで表示する。
     */
    goToIntentDetail(intentName, language_code) {
      if (intentName) {
        if (language_code === "zh_cn") {
          language_code = "zh-cn";
        } else if (language_code === "zh_tw") {
          language_code = "zh-tw";
        } else if (language_code === "pt_br") {
          language_code = "pt-br";
        }
        const currentUrl = location.href;
        const regex = RegExp("agent.+");
        const destination = `agent/${language_code}/intent?intent_name=${intentName}`;
        return currentUrl.match(regex)
          ? currentUrl.replace(regex, destination)
          : destination;
      }
      return "javascript:void(0)";
    },
    /**
     * インテント名のみでの絞り込みをするためにカスタム関数を利用
     */
    filterByIntentName(row, filter) {
      return row.name.includes(filter);
    }
  }
};
</script>

<style scoped>
.input-group {
  width: 300px;
}
</style>
