/*
 * Chat会話履歴画面
 */
import Vue from "vue";
import { UrlBuilder } from "./util/url-builder";
import { API_URL } from "./resource/urls";
import { HistoryManagementController } from "./controller/history-management";

import HistoryCalendar from "./components/HistoryCalendar.vue";
import HistoryLog from "./components/HistoryLog.vue";
import Pagination from "./components/Pagination.vue";
import VCalendar from "v-calendar";
import VueMoment from "vue-moment";
import moment from "moment-timezone";


export const appHistory = (i18n) => {
  Vue.use(VCalendar);
  Vue.use(VueMoment, {
    moment,
  });

  /* コントローラ */
  const controller = new HistoryManagementController(
    new UrlBuilder(API_URL).build(),
    window.i18nContext,
    window.requestContext
  );

  /* コンポーネント登録 */
  Vue.component("history-calendar", HistoryCalendar);
  Vue.component("history-log", HistoryLog);
  Vue.component("pagination", Pagination);

  new Vue({
    el: "#app-history",
    data: {
      controller: controller,
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready();
      });
    },
    i18n: i18n,
  });
}
