<template>
  <div class="feedback">
    <div class="form">
      <form @submit.prevent="" class="mb-2">
        <div class="row">
          <div class="col-md-3 mt-1">
            <b-form-select v-model="controller.feedback_result" :options="result_options"></b-form-select>
          </div>
          <div class="col-md-3 mt-1">
            <b-form-select v-model="controller.language" :options="controller.supported_languages"></b-form-select>
          </div>
          <div class="col-md-3 mt-1">
            <button  @click="searchFilter" class="btn btn-success">{{ $t('history.search') }}</button>
          </div>
          <div class="col-md-3 mt-1">
            <div class="float-right">
              <a class="btn btn-primary mr-1" :href="csvExport()">{{ $t('history.csvBtn') }}</a>
              <a class="btn btn-info" :href="excelExport()">{{ $t('history.excelBtn') }}</a>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-if="!controller.isLoading">
      <b-table
        responsive
        striped
        :items="controller.feedbackHistories"
        :fields="fields"
        tbody-tr-class="text-center"
        thead-tr-class="text-center"
      >
        <template slot="displayName" slot-scope="row">
          <a :href="goIntentPage(row.item.displayName, row.item.languageCode)" target="_blank">
            {{ row.item.displayName }}
          </a>
        </template>
        <template slot="sessionId" slot-scope="row">
          <a :href="goConversationHistoryPage(row.item.sessionId, row.item.logId)" target="_blank">
            {{ row.item.sessionId }}
          </a>
        </template>
      </b-table>

      <div class="row mt-1">
        <div class="col">
          <pagination
            :params="controller.params"
            :num_page="controller.num_page"
            :callback="searchFilter"
          />
        </div>
      </div>
    </div>
    <div class="w-100 text-center" v-else>
      <b-spinner style="width: 5rem; height: 5rem;" label="Loading..." variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
  export default {
      props: ['controller'],
      data() {
          return {
              perPage: 20,
              fields: [
                  { key: 'datetime', label: this.$t('history.date'), sortable: true },
                  { key: 'feedbackResult', label: this.$t('history.result'), sortable: true },
                  { key: 'languageCode', label: this.$t('history.language'), sortable: true },
                  { key: 'freeText', label: this.$t('history.freeComments')},
                  { key: 'displayName', label: this.$t('history.conName') },
                  { key: 'sessionId', label: this.$t('history.sessionId') },
                  { key: 'score', label: this.$t('history.score') },
                  { key: 'magnitude', label: this.$t('history.magnitude') }
              ],
              result_options:[
                {value: null, text: this.$t('feedback.selectResult')},
                {value: '4', text: this.$t('feedback.best')},
                {value: '3', text: this.$t('feedback.good')},
                {value: '2', text: this.$t('feedback.normal')},
                {value: '1', text: this.$t('feedback.bad')},
                {value: '0', text: this.$t('feedback.worst')}
              ]
          }
      },
      methods: {
        goIntentPage(intent_name, language_code){
          return '/agent/' + language_code + '/intent?intent_name=' + intent_name
        },
        goConversationHistoryPage(session_id, log_id){
          return '/history/sessions?session_id='+ session_id + '&log_id=' + log_id
        },
        searchFilter(){
          this.controller.fetchHistoriesData()
        },
        csvExport(){
          return '/history/feedback/export?export_type=csv'
        },
        excelExport(){
          return '/history/feedback/export?export_type=excel'
        }
      }
  }
</script>

