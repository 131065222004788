<template>
    <b-modal
      class="select-platform-service-modal"
      ref="modal"
      :title="$t('intentDetail.selectPlatformService')"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop>
      <form>
        <div>
        <b-form-select
          class="mb-2"
          v-model="selectedPlatform"
          :options="platformOptions">
        </b-form-select>
        <b-form-radio-group v-model="platformFlag">
          <b-radio value="ADD">
            {{$t('intentDetail.addPlatformService')}}
          </b-radio>
          <b-radio value="REMOVE">
            {{$t('intentDetail.removePlatformService')}}
          </b-radio>
        </b-form-radio-group>
        <div style="font-size: small">{{$t('intentDetail.removePlatformInfo')}}</div>
      </div>
      </form>
      <div slot="modal-footer">
        <b-btn
          variant="primary"
          @click="selectPlatformService">
          OK
        </b-btn>
        <b-btn @click="$refs.modal.hide()">
          {{ $t('buttons.cancel') }}
        </b-btn>
      </div>
    </b-modal>
</template>

<script>
  import PlatformDataResource from "../resource/platforms"

  export default {
  props:['availablePlatform'],
  data() {
    return {
      selectedPlatform: 1,
      platformOptions: [],
      platformFlag: 'ADD'
    }
  },
  mounted: function(){
    const platformResource = new PlatformDataResource()
    this.platformOptions = platformResource.PlatformOptions.filter(item => this.availablePlatform.includes(item.value))
  },
  methods: {
    show() {
      this.selectedPlatform = 1
      this.platformFlag = 'ADD'
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
    selectPlatformService(){
      this.$refs.modal.hide()
      this.$emit('select-platform-service', this.selectedPlatform, this.platformFlag)
    },
  }
}
</script>

