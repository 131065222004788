<template>
<div class="intent-detail-answer">
  <b-modal
    :title="modalTitle"
    ref="answerModal"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-esc
    no-close-on-backdrop
    :size="modalSize"
    @hidden="$emit('modal-hidden', false)">
    <form v-bind:class="{'form-user-input': !!modalSize}"
      @submit.prevent="editResponseMessageOk">
      <div>
        {{ $t('answer.typeOfAnswer') }}:
        <b-btn class="show-compare mb-1" @click="toggleResponseCompare" style="float:right"
               v-b-tooltip.hover.left="$t('answer.showOtherLanguagesInput')">
          <span><i class="fas fa-language"></i></span>
        </b-btn>
      </div>
      <div @keypress.enter.prevent="">
        <b-form-select
          v-model="modalResponseTypeSelected"
          @input="OnAnswerTypeSelected"
          :disabled="isEdit"
          class="mb-2">
          <optgroup v-for="(group, name) in modalRegisteredAnswersType" :label="name">
            <option v-for="option in group" :value="option.value" :disabled="responseTypesByPlatform[option.value].length === 0">
              {{ option.text }}
            </option>
          </optgroup>
        </b-form-select>
      </div>

      <div v-show="isResponseTypeText"><answer-text ref="answerText" @is-valid="isValid" :googleCheck="checkValid"/></div>
      <div v-show="isResponseTypeCard"><answer-card ref="answerCard" @is-valid="isValid" :selected-target-services="modalSelectedTargetServices" :modalSize="modalSize"/></div>
      <div v-show="isResponseTypeCardWechat"><answer-cardWechat ref="answerCardWechat" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeCardGoogle"><answer-cardGoogle ref="answerCardGoogle" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeList"><answer-list ref="answerList" @is-valid="isValid" :modalSize="modalSize"/></div>
      <div v-show="isResponseTypeSuggestionChips"><answer-suggestionChips ref="answerSuggestionChips" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeCarouselGoogle"><answer-carouselGoogle ref="answerCarouselGoogle" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeBrowseCarousel"><answer-browseCarousel ref="answerBrowseCarousel" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeLink"><answer-link ref="answerLink" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeMediaContent"><answer-mediaContent ref="answerMediaContent" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeTableCard"><answer-tableCard ref="answerTableCard" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeQuickReplies"><answer-quickReplies ref="answerQuickReplies" @is-valid="isValid" :googleCheck="checkValid" :selected-target-services="modalSelectedTargetServices"/></div>
      <div v-show="isResponseTypeImage"><answer-image ref="answerImage" @is-valid="isValid" :selectedTargetServices="modalSelectedTargetServices"/></div>
      <div v-show="isResponseTypeVideo"><answer-video ref="answerVideo" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeCustom"><answer-customPayload ref="answerCustomPayload" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeBasicCard"><answer-basicCard ref="answerBasicCard" :selectedResponseMessage="selectedResponseMessage" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeCommerceCard"><answer-commerceCard ref="answerCommerceCard" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeListCard"><answer-listCard ref="answerListCard" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeQuickSuggestion"><answer-quickSuggestion ref="answerQuickSuggestion" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeAlexaCard"><answer-alexaCard ref="answerAlexaCard" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeAlexaList"><answer-alexaList ref="answerAlexaList" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeCarouselOption"><answer-carousel-option ref="answerCarouselOption" @is-valid="isValid"/></div>
      <div v-show="isResponseTypeAudio"><answer-audio ref="answerAudio" @is-valid="isValid" /></div>
      <div v-show="isResponseTypeSticker"><answer-sticker ref="answerSticker" @is-valid="isValid" /></div>
      <div v-show="isResponseTypeCallIntent"><answer-call-intent ref="answerCallIntent" @is-valid="isValid" /></div>

      <div @keypress.enter.prevent="">
        <b-form-group :label="$t('answer.servicesUseThisAnswer')">
          <b-form-checkbox-group class="target-services-checkbox"
            name="targetServices"
            v-model="modalSelectedTargetServices"
            @input="checkValid"
            :options="modalTargetServicesOptions">
          </b-form-checkbox-group>
        </b-form-group>
      </div>

      <div v-show="isResponseTypeText && modalTargetServicesOptions.find(targetService => targetService.text === 'WebChat_V2')">
        <b-form-group label="テキストフォーマット(WebChat V2):">
          <b-form-radio-group v-model="textFormat">
            <b-form-radio value="plain">Plain</b-form-radio>
            <b-form-radio value="markdown">Markdown</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
    </form>

    <compare-response-message
      ref="compareResponseMessage"
      :showCompare="!!modalSize"
      :currentLanguage="currentLanguage"
      :supportedLanguages="supportedLanguages"
      :modalResponseTypeSelected="modalResponseTypeSelected"
      :selectedLanguageIntentDetail="selectedLanguageIntentDetail"
      :isResponseTypeText="isResponseTypeText"
      :isResponseTypeCard="isResponseTypeCard"
      :isResponseTypeCardWechat="isResponseTypeCardWechat"
      :isResponseTypeCardGoogle="isResponseTypeCardGoogle"
      :isResponseTypeList="isResponseTypeList"
      :isResponseTypeSuggestionChips="isResponseTypeSuggestionChips"
      :isResponseTypeCarouselGoogle="isResponseTypeCarouselGoogle"
      :isResponseTypeBrowseCarousel="isResponseTypeBrowseCarousel"
      :isResponseTypeLink="isResponseTypeLink"
      :isResponseTypeMediaContent="isResponseTypeMediaContent"
      :isResponseTypeTableCard="isResponseTypeTableCard"
      :isResponseTypeQuickReplies="isResponseTypeQuickReplies"
      :isResponseTypeImage="isResponseTypeImage"
      :isResponseTypeVideo="isResponseTypeVideo"
      :isResponseTypeCustom="isResponseTypeCustom"
      :isResponseTypeBasicCard="isResponseTypeBasicCard"
      :isResponseTypeCommerceCard="isResponseTypeCommerceCard"
      :isResponseTypeListCard="isResponseTypeListCard"
      :isResponseTypeQuickSuggestion="isResponseTypeQuickSuggestion"
      :isResponseTypeAlexaCard="isResponseTypeAlexaCard"
      :isResponseTypeAlexaList="isResponseTypeAlexaList"
      :isResponseTypeCarouselOption="isResponseTypeCarouselOption"
      :isResponseTypeAudio="isResponseTypeAudio"
      :isResponseTypeSticker="isResponseTypeSticker"
      :isResponseTypeCallIntent="isResponseTypeCallIntent"
      @load-compared-messages="onChangedComparedLanguage"
      @copy-message="copyMessage"
      >
    </compare-response-message>

    <modal-footer
      slot="modal-footer"
      :okDisabled="modalSelectedTargetServices.length == 0 || required == false"
      @ok="editResponseMessageOk"
      @cancel="editResponseMessageCancel">
    </modal-footer>
  </b-modal>

  <error-modal
    ref="existsQuickRepliesModal"
    :message="$t('answer.existsQuickReplies')">
  </error-modal>

  <b-modal
    :title="$t('answer.existDefaultTextRelatedGoogle')"
    ref="existDefaultTextRelatedGoogleModal"
    header-class="bg-danger text-light"
    body-class="text-danger"
    no-close-on-esc
    no-close-on-backdrop
    ok-only
    centered
    @keypress.native.enter="existTextRelatedGoogleModalClose"
    @ok="existTextRelatedGoogleModalClose">
    {{$t('answer.existDefaultTextRelatedGoogleMessage')}}
  </b-modal>

  <b-modal
    :title="$t('answer.existDefaultTextRelatedGoogle')"
    ref="checkNumberOfResponseModal"
    header-class="bg-danger text-light"
    body-class="text-danger"
    centered
    no-close-on-esc
    no-close-on-backdrop
    ok-only
    ok-title="Reset"
    @hidden="checkNumberOfResponseModalHide"
    @keypress.native.enter="checkNumberOfResponseModalHide"
    @ok="checkNumberOfResponseModalHide">
    {{cardWarningMessage}}
  </b-modal>
</div>
</template>

<script>
import { ResponseMessage, TargetService } from '../model/intent'
import PlatformDataResource from '../resource/platforms'
import { arrayRemoveItems } from "../util/array-remove-item"
import { ResponseType } from "../resource/responses"
import {I18n} from "../util/i18n";
import draggable from 'vuedraggable'
import AnswerText from './IntentDetailAnswerKind/AnswerText.vue'
import AnswerCard from './IntentDetailAnswerKind/AnswerCard.vue'
import AnswerImage from './IntentDetailAnswerKind/AnswerImage.vue'
import AnswerVideo from './IntentDetailAnswerKind/AnswerVideo.vue'
import AnswerQuickReplies from './IntentDetailAnswerKind/AnswerQuickReplies.vue'
import AnswerCustomPayload from './IntentDetailAnswerKind/AnswerCustomPayload.vue'
import AnswerCardWechat from './IntentDetailAnswerKind/AnswerCardWechat.vue'
import AnswerCardGoogle from './IntentDetailAnswerKind/AnswerCardGoogle.vue'
import AnswerList from './IntentDetailAnswerKind/AnswerList.vue'
import AnswerSuggestionChips from './IntentDetailAnswerKind/AnswerSuggestionChips.vue'
import AnswerCarouselGoogle from './IntentDetailAnswerKind/AnswerCarouselGoogle.vue'
import AnswerBrowseCarousel from './IntentDetailAnswerKind/AnswerBrowseCarousel.vue'
import AnswerLink from './IntentDetailAnswerKind/AnswerLink.vue'
import AnswerMediaContent from './IntentDetailAnswerKind/AnswerMediaContent.vue'
import AnswerTableCard from './IntentDetailAnswerKind/AnswerTableCard.vue'
import AnswerBasicCard from './IntentDetailAnswerKind/AnswerKakaoBasicCard.vue'
import AnswerCommerceCard from './IntentDetailAnswerKind/AnswerKakaoCommerceCard.vue'
import AnswerListCard from './IntentDetailAnswerKind/AnswerKakaoListCard.vue'
import AnswerQuickSuggestion from './IntentDetailAnswerKind/AnswerKakaoQuickSuggestion.vue'
import AnswerAlexaCard from './IntentDetailAnswerKind/AnswerAlexaCard.vue'
import AnswerAlexaList from './IntentDetailAnswerKind/AnswerAlexaList.vue'
import AnswerCarouselOption from './IntentDetailAnswerKind/AnswerCarouselOption.vue'
import AnswerAudio from './IntentDetailAnswerKind/AnswerAudio.vue'
import AnswerSticker from './IntentDetailAnswerKind/AnswerSticker.vue'
import AnswerCallIntent from './IntentDetailAnswerKind/AnswerCallIntent.vue'

import { cloneDeep } from 'lodash'
export default {
  props: ["registeredAnswers", "supportedLanguages", "currentLanguage", 'selectedLanguageIntentDetail',
    "selectedResponseMessage", "availablePlatform", "defaultPlatform"],
  components: {
    draggable,
    'answer-text': AnswerText,
    'answer-card': AnswerCard,
    'answer-image':AnswerImage,
    'answer-video':AnswerVideo,
    'answer-quickReplies':AnswerQuickReplies,
    'answer-customPayload':AnswerCustomPayload,
    'answer-cardWechat':AnswerCardWechat,
    'answer-cardGoogle':AnswerCardGoogle,
    'answer-list':AnswerList,
    'answer-suggestionChips':AnswerSuggestionChips,
    'answer-carouselGoogle':AnswerCarouselGoogle,
    'answer-browseCarousel':AnswerBrowseCarousel,
    'answer-link':AnswerLink,
    'answer-mediaContent':AnswerMediaContent,
    'answer-tableCard':AnswerTableCard,
    'answer-basicCard': AnswerBasicCard,
    'answer-commerceCard': AnswerCommerceCard,
    'answer-listCard': AnswerListCard,
    'answer-quickSuggestion': AnswerQuickSuggestion,
    'answer-alexaCard': AnswerAlexaCard,
    'answer-alexaList': AnswerAlexaList,
    'answer-carousel-option': AnswerCarouselOption,
    'answer-audio': AnswerAudio,
    'answer-sticker': AnswerSticker,
    'answer-call-intent': AnswerCallIntent
  },
  data() {
    return {
      modalTitle: '',
      modalResponseMessage: null,
      modalResponseTypeSelected: ResponseType.TEXT,
      modalSelectedTargetServices: [],
      i18n: new I18n(window.i18nContext),
      isEdit: null,
      required: null,
      modalSize:null,
      responseTypesByPlatform: {},
      cardWarningMessage: '',
      textFormat: 'plain',
    }
  },
  created: function(){
    const platformResource = new PlatformDataResource()
    let all_platform = cloneDeep(platformResource.ResponseTypesByPlatform)
    for(let key in all_platform){
      this.responseTypesByPlatform[key] = all_platform[key].filter(item => this.availablePlatform.includes(item.value))
    }
  },
  computed: {
    isResponseTypeText() {
      return this.modalResponseTypeSelected == ResponseType.TEXT
    },
    isResponseTypeQuickReplies() {
      return this.modalResponseTypeSelected == ResponseType.QUICK_REPLIES
    },
    isResponseTypeCustom() {
      return this.modalResponseTypeSelected == ResponseType.CUSTOM_PAYLOAD
    },
    isResponseTypeCard(){
      return this.modalResponseTypeSelected == ResponseType.CARD
    },
    isResponseTypeCardWechat(){
      return this.modalResponseTypeSelected == ResponseType.CARD_WECHAT
    },
    isResponseTypeCardGoogle(){
      return this.modalResponseTypeSelected == ResponseType.CARD_GOOGLE
    },
    isResponseTypeList(){
      return this.modalResponseTypeSelected == ResponseType.LIST
    },
    isResponseTypeSuggestionChips(){
      return this.modalResponseTypeSelected == ResponseType.SUGGESTION_CHIPS
    },
    isResponseTypeCarouselGoogle(){
      return this.modalResponseTypeSelected == ResponseType.CAROUSEL_GOOGLE
    },
    isResponseTypeBrowseCarousel(){
      return this.modalResponseTypeSelected == ResponseType.BROWSE_CAROUSEL
    },
    isResponseTypeLink(){
      return this.modalResponseTypeSelected == ResponseType.LINK
    },
    isResponseTypeMediaContent(){
      return this.modalResponseTypeSelected == ResponseType.MEDIA_CONTENT
    },
    isResponseTypeTableCard(){
      return this.modalResponseTypeSelected == ResponseType.TABLE_CARD
    },
    isResponseTypeImage(){
      return this.modalResponseTypeSelected == ResponseType.IMAGE
    },
    isResponseTypeVideo(){
      return this.modalResponseTypeSelected == ResponseType.VIDEO
    },
    isResponseTypeBasicCard(){
      return this.modalResponseTypeSelected == ResponseType.BASIC_CARD
    },
    isResponseTypeCommerceCard(){
      return this.modalResponseTypeSelected == ResponseType.COMMERCE_CARD
    },
    isResponseTypeListCard(){
      return this.modalResponseTypeSelected == ResponseType.LIST_CARD
    },
    isResponseTypeQuickSuggestion(){
      return this.modalResponseTypeSelected == ResponseType.QUICK_SUGGESTION
    },
    isResponseTypeAlexaCard(){
      return this.modalResponseTypeSelected == ResponseType.ALEXA_CARD
    },
    isResponseTypeAlexaList(){
      return this.modalResponseTypeSelected == ResponseType.ALEXA_LIST
    },
    isResponseTypeCarouselOption(){
      return this.modalResponseTypeSelected === ResponseType.CAROUSEL_OPTIONS
    },
    isResponseTypeAudio() {
      return this.modalResponseTypeSelected === ResponseType.AUDIO
    },
    isResponseTypeSticker(){
      return this.modalResponseTypeSelected === ResponseType.STICKER
    },
    isResponseTypeCallIntent() {
      return this.modalResponseTypeSelected === ResponseType.CALL_INTENT
    },
    modalTargetServicesOptions(){
      let options = this.responseTypesByPlatform[this.modalResponseTypeSelected]
      // response typeが存在しないサービスに登録されてしまうのを防ぐ
      switch (this.modalResponseTypeSelected) {
        case ResponseType.TEXT:
          // WebChat, LINE, Facebook, WeChat, Google, Api-service, Kakaotalk, Alexa
          // 登録済回答レスポンスにTextがあるかどうか
          let google_index_txt = this.registeredAnswers.filter(item=>{
            return (item.type == ResponseType.TEXT)
          })
          // googleにチェックが入ってるかどうか
          let google_list_txt = google_index_txt.filter(item=>{
            return (item.targetServices.indexOf(TargetService.GOOGLE) > -1)
          })
          // googleにチェックが入ったTEXTレスポンスが２つ登録済の時は一覧からgoogleを削除
          if(google_list_txt.length > 1){
            // 新規追加時
            if(!this.isEdit){
              options = arrayRemoveItems(options, [{value: TargetService.GOOGLE}], 'value')
            }
            else if (this.selectedResponseMessage && this.selectedResponseMessage.targetServices.indexOf(TargetService.GOOGLE) === -1) {
                // 編集時Googleが選択されていないとサービス一覧からgoogleを除外
                options = arrayRemoveItems(options, [{value: TargetService.GOOGLE}], 'value')
            }
          }
          break
        case ResponseType.CUSTOM_PAYLOAD:
          for(let index in this.registeredAnswers){
            // 登録済回答レスポンスにCarouselCardまたはBrowseCarouselがあるとき
            if(this.registeredAnswers[index].type == ResponseType.CAROUSEL_GOOGLE || this.registeredAnswers[index].type == ResponseType.BROWSE_CAROUSEL){
              // サービス一覧からgoogleを除外
              options = arrayRemoveItems(options, [{value: TargetService.GOOGLE}], 'value')
            }
          }
          // 登録済回答レスポンスにCustomPayloadがあるかどうか
          let google_index = this.registeredAnswers.filter(item=>{
            return (item.type == ResponseType.CUSTOM_PAYLOAD)
          })
          // googleにチェックが入ってるかどうか
          let google_list = google_index.filter(item=>{
            return (item.targetServices.indexOf(TargetService.GOOGLE) > -1)
          })
          if(google_list.length > 0){
            // 新規追加時
            if(!this.isEdit){
              options = arrayRemoveItems(options, [{value: TargetService.GOOGLE}], 'value')
            }
            else if (this.selectedResponseMessage && this.selectedResponseMessage.targetServices.indexOf(TargetService.GOOGLE) === -1) {
                // 編集時Googleが選択されていないとサービス一覧からgoogleを除外
                options = arrayRemoveItems(options, [{value: TargetService.GOOGLE}], 'value')
            }
          }
          break
      }
      return options
    },
    modalRegisteredAnswersType(){
      let options = {
        Default:[
          { value: ResponseType.TEXT, text: 'TEXT' },
          { value: ResponseType.CARD, text: 'CARD' },
          { value: ResponseType.IMAGE, text: 'IMAGE' },
          { value: ResponseType.VIDEO, text: 'VIDEO' },
          { value: ResponseType.AUDIO, text: 'AUDIO' },
          { value: ResponseType.QUICK_REPLIES, text: 'QUICK_REPLIES' },
          { value: ResponseType.CALL_INTENT, text: 'CALL_INTENT' },
          { value: ResponseType.CUSTOM_PAYLOAD, text: 'CUSTOM_PAYLOAD' }
        ],
        LINE:[
          { value: ResponseType.STICKER, text: 'STICKER'}
        ],
        CAROUSEL_BUTTONS:[
          { value: ResponseType.CAROUSEL_OPTIONS, text: 'CAROUSEL_OPTIONS'}
        ],
        We_chat:[
          { value: ResponseType.CARD_WECHAT, text: 'CARD_WECHAT' }
        ],
        Actions_on_Google:[
          { value: ResponseType.CARD_GOOGLE, text: 'CARD_GOOGLE' },
          { value: ResponseType.LIST, text: 'LIST' },
          { value: ResponseType.SUGGESTION_CHIPS, text: 'SUGGESTION_CHIPS' },
          { value: ResponseType.CAROUSEL_GOOGLE, text: 'CAROUSEL_GOOGLE' },
          { value: ResponseType.BROWSE_CAROUSEL, text: 'BROWSE_CAROUSEL' },
          { value: ResponseType.LINK, text: 'LINK' },
          { value: ResponseType.MEDIA_CONTENT, text: 'MEDIA_CONTENT' },
          { value: ResponseType.TABLE_CARD, text: 'TABLE_CARD' },
        ],
        Kakao_Talk:[
          { value: ResponseType.BASIC_CARD, text: 'BASIC_CARD'},
          { value: ResponseType.COMMERCE_CARD, text: 'COMMERCE_CARD'},
          { value: ResponseType.LIST_CARD, text: 'LIST_CARD'},
          { value: ResponseType.QUICK_SUGGESTION, text:'QUICK_SUGGESTION'}
        ],
        Alexa:[
          { value: ResponseType.ALEXA_CARD, text: 'ALEXA_CARD'},
          { value: ResponseType.ALEXA_LIST, text: 'ALEXA_LIST'}
        ]
      }

      if(this.isEdit) {
        // return full list for selected option.
        return options
      }

      for(let index in this.registeredAnswers){
        switch (this.registeredAnswers[index].type) {
          case ResponseType.CARD_GOOGLE:
            // Suggestion chips, Link, Custom payload
            options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
              return (item.value == ResponseType.SUGGESTION_CHIPS || item.value == ResponseType.LINK)
            })
            break
          case ResponseType.LIST:
            // Suggestion chips, Link, Custom payload, Table Card
            options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
              return (item.value == ResponseType.SUGGESTION_CHIPS || item.value == ResponseType.LINK || item.value == ResponseType.TABLE_CARD)
            })
            break
          case ResponseType.SUGGESTION_CHIPS:
            // Basic Card, List, Carousel card, Browse Carousel, Link, Media Content, Custom payload, Table Card
            options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
              return (item.value != ResponseType.SUGGESTION_CHIPS)
            })
            break
          case ResponseType.CAROUSEL_GOOGLE:
            // Suggestion chips
            options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
              return (item.value == ResponseType.SUGGESTION_CHIPS)
            })
            break
          case ResponseType.BROWSE_CAROUSEL:
            // Suggestion chips
            options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
              return (item.value == ResponseType.SUGGESTION_CHIPS)
            })
            break
          case ResponseType.LINK:
            // Basic Card, List, Suggestion chips, Media Content, Custom payload, Table Card
            options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
              return (item.value != ResponseType.CAROUSEL_GOOGLE && item.value != ResponseType.BROWSE_CAROUSEL && item.value != ResponseType.LINK)
            })
            break
          case ResponseType.MEDIA_CONTENT:
            // Suggestion chips, Link, Custom payload
            options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
              return (item.value == ResponseType.SUGGESTION_CHIPS || item.value == ResponseType.LINK)
            })
            break
          case ResponseType.TABLE_CARD:
            // Suggestion chips, Link, Custom payload
            options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
              return (item.value == ResponseType.SUGGESTION_CHIPS || item.value == ResponseType.LINK)
            })
            break
          case ResponseType.CUSTOM_PAYLOAD:
            for(let service_index in this.registeredAnswers[index].targetServices){
              switch (this.registeredAnswers[index].targetServices[service_index]) {
                // Custom payloadのgoogleにチェックがあるとき
                case TargetService.GOOGLE:
                  // Basic Card, List, Suggestion chips, Link, Media Content, Table Card
                  options['Actions_on_Google'] = options['Actions_on_Google'].filter(item=>{
                    return (item.value != ResponseType.CAROUSEL_GOOGLE && item.value != ResponseType.BROWSE_CAROUSEL)
                  })
                  break
              }
            }
            break
        }
      }
      return options
    }
  },
  methods: {
    OnAnswerTypeSelected() {
      // reset the field when the type of the answer is changed
      // for avoiding unexpected bugs
      if(!this.isEdit) {
        this.modalSelectedTargetServices = this.responseTypesByPlatform[this.modalResponseTypeSelected].filter(item =>
            this.defaultPlatform.includes(item.value)).map(item => {return item.value})
      }
      // レスポンスタイプ変更の度にバリデーションチェックを行う
      this.checkValid()
    },
    addResponse() {
      this.modalTitle = this.i18n.t('answer.add')
      this.modalResponseMessage = new ResponseMessage
      this.modalResponseTypeSelected = ResponseType.TEXT
      this.modalSelectedTargetServices = this.defaultPlatform
      this.isEdit = false
      this.required = true
      this.textFormat = 'plain'
      this.$refs.answerModal.show()
      this.$nextTick(() => this.initChildComponentVal())
    },
    initChildComponentVal() {
      this.$refs.answerText.isLineSelected = this.modalSelectedTargetServices.includes(TargetService.LINE)
      this.$refs.answerText.initializeValue()
      this.$refs.answerCard.initializeValue()
      this.$refs.answerCardWechat.initializeValue()
      this.$refs.answerLink.initializeValue()
      this.$refs.answerCardGoogle.initializeValue()
      this.$refs.answerList.initializeValue()
      this.$refs.answerSuggestionChips.initializeValue()
      this.$refs.answerCarouselGoogle.initializeValue()
      this.$refs.answerBrowseCarousel.initializeValue()
      this.$refs.answerCustomPayload.initializeValue()
      this.$refs.answerQuickReplies.initializeValue()
      this.$refs.answerMediaContent.initializeValue()
      this.$refs.answerTableCard.initializeValue()
      this.$refs.answerImage.initializeValue()
      this.$refs.answerVideo.initializeValue()
      this.$refs.answerBasicCard.initializeValue()
      this.$refs.answerCommerceCard.initializeValue()
      this.$refs.answerListCard.initializeValue()
      this.$refs.answerQuickSuggestion.initializeValue()
      this.$refs.answerAlexaCard.initializeValue()
      this.$refs.answerAlexaList.initializeValue()
      this.$refs.answerCarouselOption.initializeValue()
      this.$refs.answerAudio.initializeValue()
      this.$refs.answerSticker.initializeValue()
      this.$refs.answerCallIntent.initializeValue()
    },
    editResponseMessage(selectedResponseMessage){
      this.modalTitle = this.i18n.t('answer.editTitle')
      this.modalResponseMessage = selectedResponseMessage
      this.modalResponseTypeSelected = this.modalResponseMessage.type
      this.isEdit = true
      this.textFormat = selectedResponseMessage.textFormat || 'plain'
      this.modalSelectedTargetServices = this.modalResponseMessage.targetServices
      this.$refs.answerModal.show()
      this.$nextTick(() => this.modalSetValue(selectedResponseMessage))
    },
    modalSetValue(selectedResponseMessage){
      switch (selectedResponseMessage.type) {
        case ResponseType.TEXT:
          this.$refs.answerText.isLineSelected = this.modalSelectedTargetServices.includes(TargetService.LINE)
          this.$refs.answerText.setValue(selectedResponseMessage)
          break
        case ResponseType.CARD:
            this.$refs.answerCard.setValue(selectedResponseMessage)
          break
        case ResponseType.CARD_WECHAT:
          this.$refs.answerCardWechat.setValue(selectedResponseMessage)
          break
        case ResponseType.CARD_GOOGLE:
          this.$refs.answerCardGoogle.setValue(selectedResponseMessage)
          break
        case ResponseType.LIST:
          this.$refs.answerList.setValue(selectedResponseMessage)
          break
        case ResponseType.SUGGESTION_CHIPS:
          this.$refs.answerSuggestionChips.setValue(selectedResponseMessage)
          break
        case ResponseType.CAROUSEL_GOOGLE:
          this.$refs.answerCarouselGoogle.setValue(selectedResponseMessage)
          break
        case ResponseType.BROWSE_CAROUSEL:
          this.$refs.answerBrowseCarousel.setValue(selectedResponseMessage)
          break
        case ResponseType.LINK:
          this.$refs.answerLink.setValue(selectedResponseMessage)
          break
        case ResponseType.MEDIA_CONTENT:
          this.$refs.answerMediaContent.setValue(selectedResponseMessage)
          break
        case ResponseType.TABLE_CARD:
          this.$refs.answerTableCard.setValue(selectedResponseMessage)
          break
        case ResponseType.QUICK_REPLIES:
          this.$refs.answerQuickReplies.setValue(selectedResponseMessage)
          break
        case ResponseType.IMAGE:
          this.$refs.answerImage.setValue(selectedResponseMessage)
          break
        case ResponseType.VIDEO:
          this.$refs.answerVideo.setValue(selectedResponseMessage)
          break
        case ResponseType.CUSTOM_PAYLOAD:
          this.modalTargetServicesOptions
          this.$refs.answerCustomPayload.setValue(selectedResponseMessage)
          break
        case ResponseType.BASIC_CARD:
          this.$refs.answerBasicCard.setValue(selectedResponseMessage)
          break
        case ResponseType.COMMERCE_CARD:
          this.$refs.answerCommerceCard.setValue(selectedResponseMessage)
          break
        case ResponseType.LIST_CARD:
          this.$refs.answerListCard.setValue(selectedResponseMessage)
          break
        case ResponseType.QUICK_SUGGESTION:
          this.$refs.answerQuickSuggestion.setValue(selectedResponseMessage)
          break
        case ResponseType.ALEXA_CARD:
          this.$refs.answerAlexaCard.setValue(selectedResponseMessage)
          break
        case ResponseType.ALEXA_LIST:
          this.$refs.answerAlexaList.setValue(selectedResponseMessage)
          break
        case ResponseType.CAROUSEL_OPTIONS:
          this.$refs.answerCarouselOption.setValue(selectedResponseMessage)
          break
        case ResponseType.AUDIO:
          this.$refs.answerAudio.setValue(selectedResponseMessage)
          break
        case ResponseType.STICKER:
          this.$refs.answerSticker.setValue(selectedResponseMessage)
          break
        case ResponseType.CALL_INTENT:
          this.$refs.answerCallIntent.setValue(selectedResponseMessage)
          break
        default:
          break
      }
    },
    editResponseMessageOk() {
      // 応答の種類
      this.modalResponseMessage.type = this.modalResponseTypeSelected
      switch (this.modalResponseTypeSelected) {
        case ResponseType.TEXT:
          this.modalResponseMessage.data = this.$refs.answerText.inputValue()
          this.modalResponseMessage.textFormat = this.textFormat
          break
        case ResponseType.CARD:
          this.modalResponseMessage.data = this.$refs.answerCard.inputValue()
          break
        case ResponseType.CARD_WECHAT:
          this.modalResponseMessage.data = this.$refs.answerCardWechat.inputValue()
          break
        case ResponseType.CARD_GOOGLE:
          this.modalResponseMessage.data = this.$refs.answerCardGoogle.inputValue()
          break
        case ResponseType.LIST:
          this.modalResponseMessage.data = this.$refs.answerList.inputValue()
          break
        case ResponseType.SUGGESTION_CHIPS:
          this.modalResponseMessage.data = this.$refs.answerSuggestionChips.inputValue()
          break
        case ResponseType.CAROUSEL_GOOGLE:
          this.modalResponseMessage.data = this.$refs.answerCarouselGoogle.inputValue()
          break
        case ResponseType.BROWSE_CAROUSEL:
          this.modalResponseMessage.data = this.$refs.answerBrowseCarousel.inputValue()
          break
        case ResponseType.LINK:
          this.modalResponseMessage.data = this.$refs.answerLink.inputValue()
          break
        case ResponseType.MEDIA_CONTENT:
          this.modalResponseMessage.data = this.$refs.answerMediaContent.inputValue()
          break
        case ResponseType.TABLE_CARD:
          this.modalResponseMessage.data = this.$refs.answerTableCard.inputValue()
          break
        case ResponseType.QUICK_REPLIES:
          // 既にquick repliesが同intent内に登録されている場合、2つ以上のquick repliesはdialogflowに同期できないためエラーを出す
          let existsQuickReplies = this.registeredAnswers.some(answer => answer.type == ResponseType.QUICK_REPLIES)
          if (existsQuickReplies) {
            if (!this.modalResponseMessage.id) {
              // 新規追加の場合
              // 編集の場合はanswerのtypeを変えられないのでチェックは不要
              this.$refs.existsQuickRepliesModal.show()
              return
            }
          }
          this.modalResponseMessage.data = this.$refs.answerQuickReplies.inputValue()
          break
        case ResponseType.IMAGE:
          this.modalResponseMessage.data = this.$refs.answerImage.inputValue()
          break
        case ResponseType.VIDEO:
          this.modalResponseMessage.data = this.$refs.answerVideo.inputValue()
          break
        case ResponseType.CUSTOM_PAYLOAD:
          this.modalResponseMessage.data = this.$refs.answerCustomPayload.inputValue()
          break
        case ResponseType.BASIC_CARD:
          this.modalResponseMessage.data = this.$refs.answerBasicCard.inputValue()
          break
        case ResponseType.COMMERCE_CARD:
          this.modalResponseMessage.data = this.$refs.answerCommerceCard.inputValue()
          break
        case ResponseType.LIST_CARD:
          this.modalResponseMessage.data = this.$refs.answerListCard.inputValue()
          break
        case ResponseType.QUICK_SUGGESTION:
          this.modalResponseMessage.data = this.$refs.answerQuickSuggestion.inputValue()
          break
        case ResponseType.ALEXA_CARD:
          this.modalResponseMessage.data = this.$refs.answerAlexaCard.inputValue()
          break
        case ResponseType.ALEXA_LIST:
          this.modalResponseMessage.data = this.$refs.answerAlexaList.inputValue()
          break
        case ResponseType.CAROUSEL_OPTIONS:
          this.modalResponseMessage.data = this.$refs.answerCarouselOption.inputValue()
          break
        case ResponseType.AUDIO:
          this.modalResponseMessage.data = this.$refs.answerAudio.inputValue()
          break
        case ResponseType.STICKER:
          this.modalResponseMessage.data = this.$refs.answerSticker.inputValue()
          break
        case ResponseType.CALL_INTENT:
          this.modalResponseMessage.data = this.$refs.answerCallIntent.inputValue()
          break
        default:
          break
      }
      // 対象のサービス
      if (this.textFormat === "markdown") {
        // テキストがMarkdownの時は、WebChat_V2のみが選択される
        this.modalResponseMessage.targetServices = [TargetService.WEBCHAT_V2]
      } else {
        this.modalResponseMessage.targetServices = this.modalSelectedTargetServices
      }
      this.$emit('save-response-message', this.modalResponseMessage)
      console.debug(this.modalResponseMessage)
      this.$refs.answerModal.hide()
    },
    editResponseMessageCancel() {
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag', this.isModalShown)
      this.$refs.answerModal.hide()
      this.modalSize = null
      this.$refs.compareResponseMessage.init()
    },
    toggleResponseCompare(){
      this.modalSize = this.modalSize ? null : "lg"
    },
    onChangedComparedLanguage(selectedLanguage){
      this.$emit("changed-compared-language", selectedLanguage)
    },
    copyMessage(selectedComparedMessage) {
      this.modalSetValue(selectedComparedMessage)
    },
    isValid(valid){
      this.required = valid
    },
    checkValid() {
      // 必須項目確認
      /*
        if文の意図がよくわからないので、コメントアウトだけで残す
      */
      // if (this.modalSelectedTargetServices.indexOf(6) >= 0) {
      //   // 対象のサービスにGoogleを含むとき
      switch (this.modalResponseTypeSelected) {
        // 各レスポンスタイプでバリデーションチェックを行う
        case ResponseType.TEXT:
          this.$refs.answerText.isLineSelected = this.modalSelectedTargetServices.includes(TargetService.LINE)
          this.$refs.answerText.validationCheck()
          break
        case ResponseType.CARD:
          this.checkNumberOfCardResponse()
          this.$refs.answerCard.validationCheck()
          break
        case ResponseType.CARD_WECHAT:
          this.$refs.answerCardWechat.validationCheck()
          break
        case ResponseType.CUSTOM_PAYLOAD:
          this.$refs.answerCustomPayload.validationCheck()
          // custom payloadを利用する場合、一つのプラットフォームしか選べないように
          // 他のタイプでプラットフォームを選択してからcustom payloadに切り替える可能性もあるので
          // どんな場合においても、二つ以上のプラットフォームが選択された時、最後に選択された項目に指す
          if (this.modalSelectedTargetServices.length >= 2) {
            this.modalSelectedTargetServices = [this.modalSelectedTargetServices[this.modalSelectedTargetServices.length - 1]]
          }
          break
        case ResponseType.CARD_GOOGLE:
          this.existTextRelatedGoogle()
          this.$refs.answerCardGoogle.validationCheck()
          break
        case ResponseType.LIST:
          this.existTextRelatedGoogle()
          this.$refs.answerList.validationCheck()
          break
        case ResponseType.SUGGESTION_CHIPS:
          this.existTextRelatedGoogle()
          this.$refs.answerSuggestionChips.validationCheck()
          break
        case ResponseType.CAROUSEL_GOOGLE:
          this.existTextRelatedGoogle()
          this.$refs.answerCarouselGoogle.validationCheck()
          break
        case ResponseType.BROWSE_CAROUSEL:
          this.existTextRelatedGoogle()
          this.$refs.answerBrowseCarousel.validationCheck()
          break
        case ResponseType.LINK:
          this.existTextRelatedGoogle()
          this.$refs.answerLink.validationCheck()
          break
        case ResponseType.MEDIA_CONTENT:
          this.existTextRelatedGoogle()
          this.$refs.answerMediaContent.validationCheck()
          break
        case ResponseType.TABLE_CARD:
          this.existTextRelatedGoogle()
          this.$refs.answerTableCard.validationCheck()
          break
        case ResponseType.QUICK_REPLIES:
          this.$refs.answerQuickReplies.validationCheck()
          break
        case ResponseType.IMAGE:
          this.$refs.answerImage.validationCheck()
          break
        case ResponseType.VIDEO:
          if(this.modalSelectedTargetServices.includes(2)){
            this.$refs.answerVideo.isLineSelected = true
          }
          else{
            this.$refs.answerVideo.isLineSelected = false
          }
          this.$refs.answerVideo.validationCheck()
          break
      //     default:
      //       break
      //   }
      // }else if(this.modalSelectedTargetServices.indexOf(8) >= 0){
      //   //kakaotalk 必須項目チェック
      //   switch (this.modalResponseTypeSelected) {
        case ResponseType.BASIC_CARD:
          // 現状全てoptional
          this.$refs.answerBasicCard.validationCheck()
          break
        case ResponseType.COMMERCE_CARD:
          this.$refs.answerCommerceCard.validationCheck()
          break
        case ResponseType.LIST_CARD:
          this.$refs.answerListCard.validationCheck()
          break
        case ResponseType.QUICK_SUGGESTION:
          this.$refs.answerQuickSuggestion.validationCheck()
          break
        case ResponseType.ALEXA_CARD:
          this.$refs.answerAlexaCard.validationCheck()
          break
        case ResponseType.ALEXA_LIST:
          this.$refs.answerAlexaList.validationCheck()
          break
        case ResponseType.CAROUSEL_OPTIONS:
          this.$refs.answerCarouselOption.validationCheck()
          break
        case ResponseType.AUDIO:
          this.$refs.answerAudio.validationCheck()
          break
        case ResponseType.STICKER:
          this.$refs.answerSticker.validationCheck()
          break
        case ResponseType.CALL_INTENT:
          this.$refs.answerCallIntent.validationCheck()
          break
        default:
          break
      }
      // }else{
      //   // 対象のサービスにGoogleを含まないとき
      //   this.required = true
      // }
      // Restricted Line so that more than 5 responses cannot be registered
      this.checkNumberOfLineResponse()
    },
    // google系レスポンスを追加する時、text responseがあるかどうかチェック(google系レスポンスにはtext responseが必須)
    existTextRelatedGoogle(){
      let existTEXT = this.registeredAnswers.some(answer => answer.type === ResponseType.TEXT && answer.isTargetGoogle === true)
      if(existTEXT === false){
        this.$refs.existDefaultTextRelatedGoogleModal.show()
      }
    },
    existTextRelatedGoogleModalClose(){
      this.addResponse()
      this.$refs.existDefaultTextRelatedGoogleModal.hide()
    },
    // カードレスポンスが10個以上登録できないように制限
    checkNumberOfCardResponse(){
      const otherCardLimit = 10
      const v2cardLimit = 20
      const webchatCardLimit = 20
      const cardList = this.registeredAnswers.filter(answer => answer.type === ResponseType.CARD)
      let webchatCard = cardList.filter(card => card.targetServices.includes(TargetService.WEBCHAT)).length
      let lineCard = cardList.filter(card => card.targetServices.includes(TargetService.LINE)).length
      let fbCard = cardList.filter(card => card.targetServices.includes(TargetService.FACEBOOK)).length
      let apiCard = cardList.filter(card => card.targetServices.includes(TargetService.API_SERVICE)).length
      let slackCard = cardList.filter(card => card.targetServices.includes(TargetService.SLACK)).length
      let msTeamsCard = cardList.filter(card => card.targetServices.includes(TargetService.MS_TEAMS)).length
      let v2Card = cardList.filter(card => card.targetServices.includes(TargetService.WEBCHAT_V2)).length
      if(this.isEdit) {
        webchatCard = cardList.filter(card => {
          if(card.id === this.selectedResponseMessage.id) {
            return this.modalSelectedTargetServices.includes(TargetService.WEBCHAT)
          }
          return card.targetServices.includes(TargetService.WEBCHAT)
        }).length
        lineCard = cardList.filter(card => {
          if(card.id === this.selectedResponseMessage.id) {
            return this.modalSelectedTargetServices.includes(TargetService.LINE)
          }
          return card.targetServices.includes(TargetService.LINE)
        }).length
        fbCard = cardList.filter(card => {
          if(card.id === this.selectedResponseMessage.id) {
            return this.modalSelectedTargetServices.includes(TargetService.FACEBOOK)
          }
          return card.targetServices.includes(TargetService.FACEBOOK)
        }).length
        apiCard = cardList.filter(card => {
          if(card.id === this.selectedResponseMessage.id) {
            return this.modalSelectedTargetServices.includes(TargetService.API_SERVICE)
          }
          return card.targetServices.includes(TargetService.API_SERVICE)
        }).length
        slackCard = cardList.filter(card => {
          if(card.id === this.selectedResponseMessage.id) {
            return this.modalSelectedTargetServices.includes(TargetService.SLACK)
          }
          return card.targetServices.includes(TargetService.SLACK)
        }).length
        msTeamsCard = cardList.filter(card => {
          if(card.id === this.selectedResponseMessage.id) {
            return this.modalSelectedTargetServices.includes(TargetService.MS_TEAMS)
          }
          return card.targetServices.includes(TargetService.MS_TEAMS)
        }).length
        v2Card = cardList.filter(card => {
          if(card.id === this.selectedResponseMessage.id) {
            return this.modalSelectedTargetServices.includes(TargetService.WEBCHAT_V2)
          }
          return card.targetServices.includes(TargetService.WEBCHAT_V2)
        }).length
      } else {
        if(this.modalSelectedTargetServices.includes(TargetService.WEBCHAT)) {
          webchatCard = webchatCard + 1
        }
        if(this.modalSelectedTargetServices.includes(TargetService.LINE)) {
          lineCard = lineCard + 1
        }
        if(this.modalSelectedTargetServices.includes(TargetService.FACEBOOK)) {
          fbCard = fbCard + 1
        }
        if(this.modalSelectedTargetServices.includes(TargetService.API_SERVICE)) {
          apiCard = apiCard + 1
        }
        if(this.modalSelectedTargetServices.includes(TargetService.SLACK)) {
          slackCard = slackCard + 1
        }
        if(this.modalSelectedTargetServices.includes(TargetService.MS_TEAMS)) {
          msTeamsCard = msTeamsCard + 1
        }
        if(this.modalSelectedTargetServices.includes(TargetService.WEBCHAT_V2)) {
          v2Card = v2Card + 1
        }
      }
      if(webchatCard > webchatCardLimit){
        this.cardWarningMessage = this.$t('answer.checkNumberOfCardResponseMessage')
        this.$refs.checkNumberOfResponseModal.show()
      }else if(fbCard > otherCardLimit){
        this.cardWarningMessage = this.$t('answer.checkNumberOfCardResponseMessage')
        this.$refs.checkNumberOfResponseModal.show()
      }else if(apiCard > otherCardLimit){
        this.cardWarningMessage = this.$t('answer.checkNumberOfCardResponseMessage')
        this.$refs.checkNumberOfResponseModal.show()
      }else if(slackCard > otherCardLimit){
        this.cardWarningMessage = this.$t('answer.checkNumberOfCardResponseMessage')
        this.$refs.checkNumberOfResponseModal.show()
      }else if(msTeamsCard > otherCardLimit){
        this.cardWarningMessage = this.$t('answer.checkNumberOfCardResponseMessage')
        this.$refs.checkNumberOfResponseModal.show()
      }else if(v2Card > v2cardLimit){
        this.cardWarningMessage = this.$t('answer.checkNumberOfCardResponseMessage')
        this.$refs.checkNumberOfResponseModal.show()
      }
      else if(lineCard > otherCardLimit){
        this.cardWarningMessage = this.$t('answer.checkNumberOfCardResponseMessage')
        this.$refs.checkNumberOfResponseModal.show()
      }
    },
    checkNumberOfResponseModalHide() {
      // reset the selected target service
      this.modalSelectedTargetServices = []
      if(this.isEdit) {
        // undo the selected target service
        this.modalSelectedTargetServices = this.selectedResponseMessage.targetServices
      }
      this.$refs.checkNumberOfResponseModal.hide()
    },
    checkNumberOfLineResponse() {
      const lineAnswerLimit = 5
      let lineOther = 0, lineCard = 0
      let lineAnswers = this.registeredAnswers.filter(ans => ans.targetServices.includes(TargetService.LINE))
      if(this.isEdit) {
        lineAnswers = this.registeredAnswers.filter(txt => {
          if(txt.id === this.selectedResponseMessage.id) {
            return this.modalSelectedTargetServices.includes(TargetService.LINE)
          }
          return txt.targetServices.includes(TargetService.LINE)
        })
      } else {
        if(this.modalSelectedTargetServices.includes(TargetService.LINE)) {
          if(ResponseType.CARD === this.modalResponseTypeSelected) {
            lineCard = lineCard + 1
          } else {
            lineOther = lineOther + 1
          }
        }
      }
      if(lineAnswers.length > lineAnswerLimit) {
        let countAnsObj = 1
        lineAnswers.forEach((ans, idx) => {
          let prevAns = idx > 0 ? lineAnswers[idx-1] : null
          if(prevAns) {
            if(prevAns.type !== ans.type || parseInt(ans.type) !== parseInt(ResponseType.CARD)) {
              countAnsObj++
            }
          }
        })
        lineOther = lineOther + lineAnswers.filter(ans => ans.type !== ResponseType.CARD).length
        lineCard = lineCard + lineAnswers.filter(ans => ans.type === ResponseType.CARD).length
        // 10cards = 1 message object
        const lineAnswerCount = lineOther + Math.ceil(lineCard/10)
        if(lineAnswerCount > lineAnswerLimit || countAnsObj > lineAnswerLimit){
          this.cardWarningMessage = this.$t('answer.checkNumberOfLineResponseMessage')
          this.$refs.checkNumberOfResponseModal.show()
        }
      }
    }
  }
}
</script>
