/*
 * Intent Check Detail
 */
import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {IntentCheckDetailController} from './controller/intent-check-detail'

import IntentCheckDetail from './components/IntentCheckDetail.vue'


export const appIntentCheckDetail = (i18n) => {
  library.add(faUserSecret)
  Vue.config.productionTip = false

  /* コントローラ */
  const validationResult = window.validationResultContext
  const controller = new IntentCheckDetailController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.userType,
    window.requestContext.history_id,
    validationResult,
    window.i18nContext
  )
  
  /* コンポーネント登録 */
  Vue.component('intent-check-detail', IntentCheckDetail)
  Vue.component('font-awesome-icon', FontAwesomeIcon)

  new Vue({
    el: '#app-intent-check-detail',
    data: {
      controller: controller
    },
    i18n: i18n
  })
}
