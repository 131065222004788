/*
 * Api-Service
 */
import Vue from 'vue'
import {UrlBuilder} from './util/url-builder'
import {API_URL} from './resource/urls'
import {ApiServiceController} from './controller/api-service'

import ApiService from './components/ApiService.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import ModalFooter from './components/ModalFooter.vue'
import ErrorModalFooter from './components/ErrorModalFooter.vue'


export const appApiService = (i18n) => {
  /* コントローラ */
  const controller = new ApiServiceController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.userType,
    window.i18nContext
  )
  
  /* コンポーネント登録 */
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('api-service', ApiService)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('error-modal-footer', ErrorModalFooter)

  new Vue({
    el: '#app-api-service',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
